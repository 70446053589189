import { Text, Tooltip, TooltipProps } from "@chakra-ui/react"

type Props = TooltipProps

/* Don't pass a tooltipLabel value to hide the tooltip 
  Check Chakra UI Tooltip docs for tooltip placement values */
const CustomTooltip = ({
  children,
  label = "",
  placement = "top",
  hasArrow = true,
  maxW = "50",
  gutter = 8,
  isOpen,
}: Props) => {
  return (
    <Tooltip
      label={<Text textAlign="center">{label}</Text>}
      isDisabled={!label}
      hasArrow={hasArrow}
      placement={placement}
      arrowSize={12}
      gutter={gutter}
      p="2"
      aria-label="tooltip"
      bgColor="gray.900"
      maxW={maxW}
      isOpen={label && isOpen}
    >
      {children}
    </Tooltip>
  )
}

export default CustomTooltip
