/* tslint:disable */
/* eslint-disable */
/**
 * Product Suggest
 * Provides search-as-you-type functionality for search fields. Data returned by this API includes top product, article, spare parts tables and categories results as well as commonly used searches.<br><br><strong>Links</strong><ul><li><a href=\"https://geberit-projects.atlassian.net/wiki/spaces/SMA/pages/3690135726\" target=\"_new\">Introduction to Product Search Services</a></li><li><a href=\"https://geberit-projects.atlassian.net/wiki/spaces/SMA/pages/3650617345\" target=\"_new\">Additional Information about Suggest Front-end Integration</a></li><li><a href=\"https://geberit-projects.atlassian.net/wiki/spaces/SMA/pages/3691642943\" target=\"_new\">Suggest Example Requests (Postman)</a></li></ul>
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export * from "./api"
export * from "./configuration"
