import {
  GeneratedObjModelsApi as ObjModelsApi,
  Configuration as ObjModelsConfig,
} from "../../api-clients/3d-object-models-api"
import {
  ArticleAvailabilityApi,
  Configuration as AvailabilityConfig,
  ProductAvailabilityApi,
  SparePartsTableAvailabilityApi,
} from "../../api-clients/availability-api"
import { ProductApi as BimApi, Configuration as BimConfig } from "../../api-clients/bim-product-data-service-v1"
import { ListApi, Configuration as MyListConfig } from "../../api-clients/mylist-api"
import {
  ProductPDFApi as PdfPdpApi,
  Configuration as PdfPdpConfig,
  CatalogPDFApi as PdfRequestApi,
  Configuration as PdfRequestConfig,
  SparePartsPDFApi as PdfSpdpApi,
  Configuration as PdfSpdpConfig,
} from "../../api-clients/pdf-request-api"
import { PriceApi, Configuration as PriceConfig } from "../../api-clients/price-api"
import {
  ArticleApi,
  Configuration as ArticleConfig,
  CategoryApi,
  Configuration as CategoryConfig,
  ProductApi,
  Configuration as ProductConfig,
  SparePartsApi,
  Configuration as SparePartsConfig,
  TenderTextApi,
  Configuration as TenderTextConfig,
} from "../../api-clients/product-api-v1"
import { FredhopperQueryApi as SearchApi, Configuration as SearchConfig } from "../../api-clients/search-middleware"
import { DefaultApi as ServiceApi, Configuration as ServiceConfig } from "../../api-clients/services-api-v1"
import {
  ProductSuggestApi as SuggestionApi,
  Configuration as SuggestionConfig,
} from "../../api-clients/suggest-openapi"

export const ApiService = () => {
  const availabilityConfig = new AvailabilityConfig({
    basePath: `${process.env.API_AVAILABILITY_ENDPOINT}`,
    baseOptions: {
      headers: {
        "Cache-Control": "no-cache",
        "Ocp-Apim-Subscription-Key": `${process.env.API_AVAILABILITY_KEY}`,
      },
    },
  })

  const productConfig = new ProductConfig({
    basePath: `${process.env.API_PRODUCT_V1_ENDPOINT}`,
    baseOptions: {
      headers: {
        "Cache-Control": "no-cache",
        "Ocp-Apim-Subscription-Key": `${process.env.API_PRODUCT_V1_KEY}`,
      },
    },
  })

  const sparePartsConfig = new SparePartsConfig({
    basePath: `${process.env.API_PRODUCT_V1_ENDPOINT}`,
    baseOptions: {
      headers: {
        "Cache-Control": "no-cache",
        "Ocp-Apim-Subscription-Key": `${process.env.API_PRODUCT_V1_KEY}`,
      },
    },
  })

  const articleConfig = new ArticleConfig({
    basePath: `${process.env.API_PRODUCT_V1_ENDPOINT}`,
    baseOptions: {
      headers: {
        "Cache-Control": "no-cache",
        "Ocp-Apim-Subscription-Key": `${process.env.API_PRODUCT_V1_KEY}`,
      },
    },
  })

  const categoryConfig = new CategoryConfig({
    basePath: `${process.env.API_PRODUCT_V1_ENDPOINT}`,
    baseOptions: {
      headers: {
        "Cache-Control": "no-cache",
        "Ocp-Apim-Subscription-Key": `${process.env.API_PRODUCT_V1_KEY}`,
      },
    },
  })

  const tenderTextConfig = new TenderTextConfig({
    basePath: `${process.env.API_PRODUCT_V1_ENDPOINT}`,
    baseOptions: {
      headers: {
        "Cache-Control": "no-cache",
        "Ocp-Apim-Subscription-Key": `${process.env.API_PRODUCT_V1_KEY}`,
      },
    },
  })

  const searchConfig = new SearchConfig({
    basePath: `${process.env.API_SEARCH_ENDPOINT}`,
    baseOptions: {
      headers: {
        "Ocp-Apim-Subscription-Key": `${process.env.API_SEARCH_KEY}`,
      },
    },
  })

  const suggestionConfig = new SuggestionConfig({
    basePath: `${process.env.API_SUGGEST_ENDPOINT}`,
    baseOptions: {
      headers: {
        "Ocp-Apim-Subscription-Key": `${process.env.API_SUGGEST_KEY}`,
      },
    },
  })

  const serviceConfig = new ServiceConfig({
    basePath: `${process.env.API_PRDOUCT_SERVICES_ENDPOINT}`,
    baseOptions: {
      headers: {
        "Cache-Control": "no-cache",
        "Ocp-Apim-Subscription-Key": `${process.env.API_PRDOUCT_SERVICES_KEY}`,
      },
    },
  })

  const pdfPdpConfig = new PdfPdpConfig({
    basePath: `${process.env.API_PDF_REQUEST_ENDPOINT}`,
    baseOptions: {
      headers: {
        "Cache-Control": "no-cache",
        "Ocp-Apim-Subscription-Key": `${process.env.API_PDF_REQUEST_KEY}`,
      },
    },
  })

  const pdfSpdpConfig = new PdfSpdpConfig({
    basePath: `${process.env.API_PDF_REQUEST_ENDPOINT}`,
    baseOptions: {
      headers: {
        "Cache-Control": "no-cache",
        "Ocp-Apim-Subscription-Key": `${process.env.API_PDF_REQUEST_KEY}`,
      },
    },
  })

  const pdfRequestConfig = new PdfRequestConfig({
    basePath: `${process.env.API_PDF_REQUEST_ENDPOINT}`,
    baseOptions: {
      headers: {
        "Cache-Control": "no-cache",
        "Ocp-Apim-Subscription-Key": `${process.env.API_PDF_REQUEST_KEY}`,
      },
    },
  })

  const objectModelsConfig = new ObjModelsConfig({
    basePath: `${process.env.API_3D_OBJECT_MODELS_ENDPOINT}`,
    baseOptions: {
      headers: {
        "Cache-Control": "no-cache",
        "Ocp-Apim-Subscription-Key": `${process.env.API_3D_OBJECT_MODELS_KEY}`,
      },
    },
  })

  const priceConfig = new PriceConfig({
    basePath: `${process.env.API_PRICE_ENDPOINT}`,
    baseOptions: {
      headers: {
        "Cache-Control": "no-cache",
        "Ocp-Apim-Subscription-Key": `${process.env.API_PRICE_KEY}`,
      },
    },
  })

  const myListConfig = new MyListConfig({
    basePath: `${process.env.API_MYLIST_ENDPOINT}`,
    baseOptions: {
      headers: {
        "Cache-Control": "no-cache",
        "Ocp-Apim-Subscription-Key": `${process.env.API_MYLIST_KEY}`,
      },
    },
  })

  const bimConfig = new BimConfig({
    basePath: `${process.env.API_BIM_ENDPOINT}`,
    baseOptions: {
      headers: {
        "Cache-Control": "no-cache",
        "Ocp-Apim-Subscription-Key": `${process.env.API_BIM_KEY}`,
      },
    },
  })

  return {
    productAvailabilityApi: new ProductAvailabilityApi(availabilityConfig),
    articleAvailabilityApi: new ArticleAvailabilityApi(availabilityConfig),
    productApi: new ProductApi(productConfig),
    sparePartsApi: new SparePartsApi(sparePartsConfig),
    sparePartsAvailabilityApi: new SparePartsTableAvailabilityApi(availabilityConfig),
    articleApi: new ArticleApi(articleConfig),
    categoryApi: new CategoryApi(categoryConfig),
    tenderTextApi: new TenderTextApi(tenderTextConfig),
    searchApi: new SearchApi(searchConfig),
    serviceApi: new ServiceApi(serviceConfig),
    pdfPdpApi: new PdfPdpApi(pdfPdpConfig),
    pdfSpdpApi: new PdfSpdpApi(pdfSpdpConfig),
    pdfRequestApi: new PdfRequestApi(pdfRequestConfig),
    suggestionApi: new SuggestionApi(suggestionConfig),
    objModelsApi: new ObjModelsApi(objectModelsConfig),
    priceApi: new PriceApi(priceConfig),
    myListApi: new ListApi(myListConfig),
    bimApi: new BimApi(bimConfig),
  }
}
