import { ComponentStyleConfig } from "@chakra-ui/react"

const Radio: ComponentStyleConfig = {
  variants: {
    branded: {
      container: {
        "--switch-track-width": "2.75rem",
      },
      thumb: {
        boxSize: "6",
        mt: "-0.5",
        ml: "-0.5",
        boxShadow: "0 1px 2px 0 rgba(0,0,0,0.36)",
      },
      track: {
        p: "0",
        bg: "gray.350",
        width: "12",
        height: "5",
        _focusVisible: {
          boxShadow: "none",
        },
        _checked: {
          bg: "brand.500",
        },
      },
    },
  },
}

export default Radio
