/* tslint:disable */
/* eslint-disable */
/**
 * Product API
 * The Product API provides various endpoints to obtain product, article, spare part, category, tender text, and shopping list data.  Most endpoints return data according to your enabled and defined availability user group and API scope header. In case you don\'t receive the data you expect, [contact us](mailto:developer@geberit.com) so we can determine and sort out your current permissions scope.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export * from "./api"
export * from "./configuration"
