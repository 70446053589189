const fontSizes = {
  "2xs": "0.75rem",
  xs: "0.8125rem",
  sm: "0.875rem",
  md: "1rem",
  lg: "1.125rem",
  xl: "1.25rem",
  "2xl": "1.375rem",
  "3xl": "1.5rem",
  "4xl": "1.625rem",
  "5xl": "1.75rem",
  "6xl": "2rem",
  "7xl": "2.25rem",
  "8xl": "2.625rem",
  "9xl": "2.875rem",
}

export default fontSizes
