const lineHeights = {
  normal: "normal",
  none: 1,
  shorter: 1.25,
  short: 1.375,
  base: 1.5,
  tall: 1.625,
  taller: "2",
  "3": "0.75rem",
  "4": "1rem",
  "5": "1.25rem",
  "5.5": "1.375rem",
  "6": "1.5rem",
  "7": "1.75rem",
  "7.5": "1.875rem",
  "8": "2rem",
  "9": "2.25rem",
  "10": "2.5rem",
  "11": "2.75rem",
  "12": "3.75rem",
}
export default lineHeights
