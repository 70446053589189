import { Box, Breadcrumb, BreadcrumbItem } from "@chakra-ui/react"
import { NUMBER_OF_VISIBLE_CRUMBS } from "../../../common/constants/search-constants"
import { BreadcrumbItemType } from "../../../common/types/global"
import { ChakraNextLinkButton } from "../../shared/ChakraNextLinkButton"
import { CChevronRightFlat } from "../../shared/Icons"
import HomepageCrumb from "./HomepageCrumb"
import LastCrumb from "./LastCrumb"
import MobileCrumb from "./MobileCrumb"
import ShowHiddenMenu from "./ShowHiddenMenu/ShowHiddenMenu"

type Props = {
  crumbs: BreadcrumbItemType[]
  visibleCrumbs?: number
}

const Breadcrumbs = ({ crumbs, visibleCrumbs = NUMBER_OF_VISIBLE_CRUMBS }: Props) => {
  const lastCrumb = crumbs?.at(-1)
  const numberOfVisibleCrumbs = crumbs?.length - 1 - visibleCrumbs
  const startInd = numberOfVisibleCrumbs > 0 ? numberOfVisibleCrumbs : 0
  const clickableCrumbs = crumbs?.length >= 2 ? crumbs?.slice(startInd, -1) : []
  const hiddenCrumbs = startInd > 0 ? crumbs?.slice(0, startInd) : []
  const mobileCrumb = clickableCrumbs?.at(-1)

  return (
    <>
      <Box display={{ base: "none", sm: "flex" }}>
        <Breadcrumb
          sx={{ ol: { display: "flex" } }}
          separator={<CChevronRightFlat boxSize="4" color="gray.500" />}
          mb={{ base: "4", sm: "6", md: "10" }}
        >
          <BreadcrumbItem>
            <HomepageCrumb />
          </BreadcrumbItem>
          {hiddenCrumbs.length && (
            <BreadcrumbItem>
              <ShowHiddenMenu menuOptions={hiddenCrumbs} />
            </BreadcrumbItem>
          )}
          {clickableCrumbs?.map((crumb) => (
            <BreadcrumbItem key={crumb?.href} onClick={crumb.clickHandler}>
              <ChakraNextLinkButton
                href={crumb?.href ?? "#"}
                label={crumb?.name}
                px="1.5"
                width="max-content"
                color="black"
              />
            </BreadcrumbItem>
          ))}
          {lastCrumb && <LastCrumb label={lastCrumb?.name} />}
        </Breadcrumb>
      </Box>

      <Box display={{ base: "block", sm: "none" }}>
        {mobileCrumb ? <MobileCrumb mobileCrumb={mobileCrumb} mb="4" /> : <HomepageCrumb mb="4" />}
      </Box>
    </>
  )
}

Breadcrumbs.displayName = "Breadcrumbs"

export default Breadcrumbs
