import { atom } from "recoil"
import { recoilPersist } from "recoil-persist"
import { gigyaAuthCookie } from "../constants/browser-storage"
import { AccountInfoStateType } from "../types/accountInfoState"
import { cookieStorage } from "./custom-storage"

/* Persist login information to cookie so it can be accessed on the server */
const { persistAtom } = recoilPersist({
  key: gigyaAuthCookie.KEY,
  storage: cookieStorage,
})

export const gigyaIsReadyState = atom<boolean>({
  key: "gigyaIsReady",
  default: false,
})

export const gigyaIsEnabledState = atom<boolean>({
  key: "gigyaIsEnabled",
  default: true,
})

export const accountInfoState = atom<AccountInfoStateType>({
  key: "accountInfo",
  default: {
    firstName: "",
    lastName: "",
    email: "",
    country: "",
    settings: null,
    userId: "",
    loginAt: null,
  },
  effects_UNSTABLE: [persistAtom],
})
