import { Box, Button, ModalBody, ModalFooter } from "@chakra-ui/react"
import { useBrandedTranslation } from "../../../common/hooks/useBrandedTranslation"
import { CustomModalPropTypes } from "../../../common/types/useModal"
import { CustomHelpIcon } from "../../shared/Icons"
import RichTextLabel from "../../shared/RichTextLabel"

type Props = {
  Modal: (arg0: CustomModalPropTypes) => JSX.Element
  onClose: () => void
  handleConfirm: () => void
}

const EndOnboardingModal = ({ Modal, onClose, handleConfirm }: Props) => {
  const { t } = useBrandedTranslation()

  return (
    <Modal modalContentStyleProps={{ mx: { base: "4", sm: "0" } }} customOnClose={onClose}>
      <ModalBody py="0" px={{ base: "4", sm: "8" }}>
        <Box pt="8">
          <RichTextLabel
            i18nKey={t("tutorial-end_rtf", { helpIcon: CustomHelpIcon })}
            styleProps={{
              sx: {
                h3: {
                  pb: "4",
                  pr: "5",
                  fontWeight: "bold",
                  fontSize: "xl",
                },
                p: {
                  fontSize: "sm",
                },
                svg: {
                  display: "inline",
                  maxH: "20px",
                  maxW: "20px",
                },
              },
            }}
          />
        </Box>
      </ModalBody>

      <ModalFooter px={{ base: "4", sm: "8" }} py={{ base: "10", sm: "8" }} pt={{ base: "6", sm: "8" }} gap="6">
        <Button
          variant="secondary"
          border="1px solid"
          color="black"
          onClick={handleConfirm}
          w={{ base: "100%", sm: "auto" }}
        >
          {t("mcat-back")}
        </Button>
        <Button variant="primary" onClick={onClose} w={{ base: "100%", sm: "auto" }}>
          {t("search-filters-close")}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default EndOnboardingModal
