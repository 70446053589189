import domainsConfig from "../../../domains.config"
import { LanguageMenuItemType } from "../types/domain"

export const extractCountryId = (locale: string) => locale.split("-").pop().toLocaleLowerCase()

export const extractCountryCode = (locale: string) => locale.split("-").pop().toLocaleUpperCase()

export const extractLanguage = (locale: string) => locale.split("-").shift().toLocaleLowerCase()

export const getFHLocaleFormat = (locale: string) => locale.replace(/-/g, "_")

export const getAvailableLanguages = (currentLocale: string, allLocales: string[]): LanguageMenuItemType[] => {
  const currentCountryCode = extractCountryCode(currentLocale)

  // Filter out placeholder-locales then return available languages for the current locale
  return allLocales
    .filter((locale) => !locale.startsWith(domainsConfig.localePlaceholder))
    .reduce((lngs: LanguageMenuItemType[], locale: string) => {
      if (locale.includes(currentCountryCode)) {
        const languageCode = extractLanguage(locale)
        const displayLanguage = new Intl.DisplayNames(locale, { type: "language" }).of(languageCode).split(" ")[0]
        lngs.push(<LanguageMenuItemType>{ locale, languageCode, displayLanguage })
      }
      return lngs
    }, [])
}
