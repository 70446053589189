/* tslint:disable */
/* eslint-disable */
/**
 * Product availability
 * Use the Availability API to check the availability of products, articles, categories, and spare parts tables. You can also specify an [assortment](/glossary), brand, and availability group to customize your availability check.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "./configuration"
// Some imports not used depending on template conditions
// @ts-ignore
import type { AxiosInstance, AxiosRequestConfig } from "axios"
import globalAxios from "axios"

export const BASE_PATH = "https://availability-api.stg.iv2.mbp.geberit.com".replace(/\/+$/, "")

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ",",
  ssv: " ",
  tsv: "\t",
  pipes: "|",
}

/**
 *
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
  url: string
  options: AxiosRequestConfig
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration | undefined

  constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected axios: AxiosInstance = globalAxios
  ) {
    if (configuration) {
      this.configuration = configuration
      this.basePath = configuration.basePath || this.basePath
    }
  }
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  constructor(public field: string, msg?: string) {
    super(msg)
    this.name = "RequiredError"
  }
}
