const Heading = {
  sizes: {
    "4xl": {
      fontSize: ["5xl", "7xl", "8xl"],
      lineHeight: [1.28, 1.22, 1.23],
      fontWeight: "bold",
      textTransform: "uppercase",
    },
    "3xl": {
      fontSize: ["4xl", "5xl", "6xl"],
      lineHeight: [1.23, 1.28, 1.25],
      fontWeight: "medium",
      textTransform: "uppercase",
    },
    "2xl": {
      fontSize: ["3xl", "4xl", "5xl"],
      lineHeight: [1.33, 1.23, 1.28],
      fontWeight: "medium",
    },
    xl: {
      fontSize: ["2xl", "3xl", "4xl"],
      lineHeight: [1.27, 1.33, 1.23],
      fontWeight: "bold",
    },
    lg: {
      fontSize: ["xl", "2xl", "3xl"],
      lineHeight: [1.4, 1.27, 1.33],
      fontWeight: "bold",
    },
    md: {
      fontSize: ["lg", "xl", "xl"],
      lineHeight: [1.33, 1.4, 1.4],
      fontWeight: "bold",
    },
    sm: {
      fontSize: "lg",
      lineHeight: 1.33,
      fontWeight: "regular",
    },
    xs: { fontSize: "md", lineHeight: 1.5 },
  },
}
export default Heading
