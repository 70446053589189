import { getCookie, setCookie } from "cookies-next"
import { AUTH_TTL } from "../constants/constants"

/* Custom cookie storage wrapper to pass to recoil-persist */
export const cookieStorage = {
  setItem: (key: string, value: string) => {
    setCookie(key, value, { maxAge: AUTH_TTL })
  },
  getItem: (key: string) => {
    const cookie = getCookie(key)
    return cookie ? String(cookie) : null
  },
}
