import { ComponentStyleConfig } from "@chakra-ui/react"

const Container: ComponentStyleConfig = {
  variants: {
    main: {
      maxW: "container.xl",
      px: ["4", "6"],
    },
  },
}

export default Container
