import AccountDTO from "../types/gigya/account"

/* Async Gigya getAccountInfo */
export const getAccountInfo = () => {
  return new Promise<AccountDTO>((resolve) => {
    window.gigya.accounts.getAccountInfo({
      callback: (data: AccountDTO) => {
        resolve(data)
      },
    })
  })
}

/* Async Gigya logout */
export const logout = () => {
  return new Promise<unknown>((resolve) => {
    window.gigya.accounts.logout({
      callback: (data: unknown) => {
        resolve(data)
      },
    })
  })
}
