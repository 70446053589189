/* tslint:disable */
/* eslint-disable */
/**
 * BIM Product Data Service
 * An API for Geberit BIM Product Data which delivers the product specific information which will be used in Geberit BIM Plug-in. It is used to query the category structure, products under certain category, product specific information json, versions of products, uri to download a specific product.
 *
 * The version of the OpenAPI document: v1
 * Contact: info@ekkodale.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { AxiosInstance, AxiosPromise, AxiosRequestConfig } from "axios"
import globalAxios from "axios"
import type { Configuration } from "./configuration"
// Some imports not used depending on template conditions
// @ts-ignore
import type { RequestArgs } from "./base"
import {
  assertParamExists,
  createRequestFunction,
  DUMMY_BASE_URL,
  serializeDataIfNeeded,
  setApiKeyToObject,
  setSearchParams,
  toPathString,
} from "./common"
// @ts-ignore
import { BaseAPI, BASE_PATH } from "./base"

/**
 * Apps
 * @export
 * @interface Apps
 */
export interface Apps {
  /**
   * Gets or Sets List
   * @type {Array<AppsList>}
   * @memberof Apps
   */
  list?: Array<AppsList> | null
}
/**
 * AppsImages
 * @export
 * @interface AppsImages
 */
export interface AppsImages {
  /**
   * Gets or Sets Key
   * @type {string}
   * @memberof AppsImages
   */
  key?: string | null
  /**
   * Gets or Sets ThumbnailSmall
   * @type {string}
   * @memberof AppsImages
   */
  thumbnailSmall?: string | null
  /**
   * Gets or Sets Thumbnail
   * @type {string}
   * @memberof AppsImages
   */
  thumbnail?: string | null
  /**
   * Gets or Sets Small
   * @type {string}
   * @memberof AppsImages
   */
  small?: string | null
  /**
   * Gets or Sets Medium
   * @type {string}
   * @memberof AppsImages
   */
  medium?: string | null
  /**
   * Gets or Sets Large
   * @type {string}
   * @memberof AppsImages
   */
  large?: string | null
  /**
   * Gets or Sets Original
   * @type {string}
   * @memberof AppsImages
   */
  original?: string | null
  /**
   * Gets or Sets Orientation
   * @type {string}
   * @memberof AppsImages
   */
  orientation?: string | null
  /**
   * Gets or Sets Type
   * @type {string}
   * @memberof AppsImages
   */
  type?: string | null
  /**
   * Gets or Sets Eps
   * @type {string}
   * @memberof AppsImages
   */
  eps?: string | null
}
/**
 * AppsList
 * @export
 * @interface AppsList
 */
export interface AppsList {
  /**
   * Gets or Sets Name
   * @type {string}
   * @memberof AppsList
   */
  name?: string | null
  /**
   * Gets or Sets Description
   * @type {string}
   * @memberof AppsList
   */
  description?: string | null
  /**
   * Gets or Sets Stores
   * @type {Array<StoresInner>}
   * @memberof AppsList
   */
  stores?: Array<StoresInner> | null
  /**
   * Gets or Sets Images
   * @type {Array<AppsImages>}
   * @memberof AppsList
   */
  images?: Array<AppsImages> | null
}
/**
 * BimAssetsDefinition
 * @export
 * @interface BimAssetsDefinition
 */
export interface BimAssetsDefinition {
  /**
   * Gets or Sets List
   * @type {Array<BimAssetsDefinitionList>}
   * @memberof BimAssetsDefinition
   */
  list?: Array<BimAssetsDefinitionList> | null
}
/**
 * BimAssetsDefinitionList
 * @export
 * @interface BimAssetsDefinitionList
 */
export interface BimAssetsDefinitionList {
  /**
   *
   * @type {BimAssetsDefinitionListTypeEnum}
   * @memberof BimAssetsDefinitionList
   */
  type?: BimAssetsDefinitionListTypeEnum
  /**
   * Gets or Sets Key
   * @type {string}
   * @memberof BimAssetsDefinitionList
   */
  key?: string | null
  /**
   * Gets or Sets Orientation
   * @type {string}
   * @memberof BimAssetsDefinitionList
   */
  orientation?: string | null
  /**
   * Gets or Sets ThumbnailSmall
   * @type {string}
   * @memberof BimAssetsDefinitionList
   */
  thumbnailSmall?: string | null
  /**
   * Gets or Sets Thumbnail
   * @type {string}
   * @memberof BimAssetsDefinitionList
   */
  thumbnail?: string | null
  /**
   * Gets or Sets Small
   * @type {string}
   * @memberof BimAssetsDefinitionList
   */
  small?: string | null
  /**
   * Gets or Sets Medium
   * @type {string}
   * @memberof BimAssetsDefinitionList
   */
  medium?: string | null
  /**
   * Gets or Sets Large
   * @type {string}
   * @memberof BimAssetsDefinitionList
   */
  large?: string | null
  /**
   * Gets or Sets Original
   * @type {string}
   * @memberof BimAssetsDefinitionList
   */
  original?: string | null
}

/**
 * Defines Type
 * @export
 * @enum {string}
 */

export const BimAssetsDefinitionListTypeEnum = {
  BimSchemaDrawing: "BIMSchemaDrawing",
  RevitFamily: "RevitFamily",
} as const

export type BimAssetsDefinitionListTypeEnum =
  (typeof BimAssetsDefinitionListTypeEnum)[keyof typeof BimAssetsDefinitionListTypeEnum]

/**
 * CategoryResponseDefinition
 * @export
 * @interface CategoryResponseDefinition
 */
export interface CategoryResponseDefinition {
  /**
   *
   * @type {CategoryResponseDefinitionCategoryTypeEnum}
   * @memberof CategoryResponseDefinition
   */
  categoryType?: CategoryResponseDefinitionCategoryTypeEnum
  /**
   * Gets or Sets Id
   * @type {string}
   * @memberof CategoryResponseDefinition
   */
  id?: string | null
  /**
   * Gets or Sets Name
   * @type {string}
   * @memberof CategoryResponseDefinition
   */
  name?: string | null
  /**
   * Gets or Sets Slogan
   * @type {string}
   * @memberof CategoryResponseDefinition
   */
  slogan?: string | null
  /**
   * Gets or Sets Description
   * @type {string}
   * @memberof CategoryResponseDefinition
   */
  description?: string | null
  /**
   * Gets or Sets HasProducts
   * @type {boolean}
   * @memberof CategoryResponseDefinition
   */
  hasProducts?: boolean | null
  /**
   * Gets or Sets HasSpareParts
   * @type {boolean}
   * @memberof CategoryResponseDefinition
   */
  hasSpareParts?: boolean | null
  /**
   * Gets or Sets Images
   * @type {Array<CategoryResponseDefinitionImages>}
   * @memberof CategoryResponseDefinition
   */
  images?: Array<CategoryResponseDefinitionImages> | null
  /**
   * Gets or Sets SubCategories
   * @type {Array<CategoryResponseDefinition>}
   * @memberof CategoryResponseDefinition
   */
  subCategories?: Array<CategoryResponseDefinition> | null
  /**
   * Gets or Sets Overviews
   * @type {Array<CategoryResponseDefinitionOverviews>}
   * @memberof CategoryResponseDefinition
   */
  overviews?: Array<CategoryResponseDefinitionOverviews> | null
}

/**
 * Defines CategoryType
 * @export
 * @enum {string}
 */

export const CategoryResponseDefinitionCategoryTypeEnum = {
  System: "system",
  Series: "series",
} as const

export type CategoryResponseDefinitionCategoryTypeEnum =
  (typeof CategoryResponseDefinitionCategoryTypeEnum)[keyof typeof CategoryResponseDefinitionCategoryTypeEnum]

/**
 * CategoryResponseDefinitionImages
 * @export
 * @interface CategoryResponseDefinitionImages
 */
export interface CategoryResponseDefinitionImages {
  /**
   *
   * @type {CategoryResponseDefinitionImagesOrientationEnum}
   * @memberof CategoryResponseDefinitionImages
   */
  orientation?: CategoryResponseDefinitionImagesOrientationEnum
  /**
   * Gets or Sets Key
   * @type {string}
   * @memberof CategoryResponseDefinitionImages
   */
  key?: string | null
  /**
   * Gets or Sets Thumbnail
   * @type {string}
   * @memberof CategoryResponseDefinitionImages
   */
  thumbnail?: string | null
  /**
   * Gets or Sets Small
   * @type {string}
   * @memberof CategoryResponseDefinitionImages
   */
  small?: string | null
  /**
   * Gets or Sets Medium
   * @type {string}
   * @memberof CategoryResponseDefinitionImages
   */
  medium?: string | null
  /**
   * Gets or Sets Large
   * @type {string}
   * @memberof CategoryResponseDefinitionImages
   */
  large?: string | null
}

/**
 * Defines Orientation
 * @export
 * @enum {string}
 */

export const CategoryResponseDefinitionImagesOrientationEnum = {
  PreviewImage: "PreviewImage",
  AmbienceImage: "AmbienceImage",
} as const

export type CategoryResponseDefinitionImagesOrientationEnum =
  (typeof CategoryResponseDefinitionImagesOrientationEnum)[keyof typeof CategoryResponseDefinitionImagesOrientationEnum]

/**
 * CategoryResponseDefinitionOverviews
 * @export
 * @interface CategoryResponseDefinitionOverviews
 */
export interface CategoryResponseDefinitionOverviews {
  /**
   * Gets or Sets Name
   * @type {string}
   * @memberof CategoryResponseDefinitionOverviews
   */
  name?: string | null
  /**
   * Gets or Sets Url
   * @type {string}
   * @memberof CategoryResponseDefinitionOverviews
   */
  url?: string | null
}
/**
 * Gallery
 * @export
 * @interface Gallery
 */
export interface Gallery {
  /**
   * Gets or Sets List
   * @type {Array<SparePartSearchSparePartOverviewImage>}
   * @memberof Gallery
   */
  list?: Array<SparePartSearchSparePartOverviewImage> | null
}
/**
 * Defines Indicator
 * @export
 * @enum {string}
 */

export const Indicator = {
  New: "NEW",
  Phaseout: "PHASEOUT",
} as const

export type Indicator = (typeof Indicator)[keyof typeof Indicator]

/**
 *
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
  [key: string]: any

  /**
   *
   * @type {string}
   * @memberof ProblemDetails
   */
  type?: string | null
  /**
   *
   * @type {string}
   * @memberof ProblemDetails
   */
  title?: string | null
  /**
   *
   * @type {number}
   * @memberof ProblemDetails
   */
  status?: number | null
  /**
   *
   * @type {string}
   * @memberof ProblemDetails
   */
  detail?: string | null
  /**
   *
   * @type {string}
   * @memberof ProblemDetails
   */
  instance?: string | null
}
/**
 * ProductDefinition
 * @export
 * @interface ProductDefinition
 */
export interface ProductDefinition {
  /**
   * Gets or Sets ProductId
   * @type {string}
   * @memberof ProductDefinition
   */
  productId?: string | null
  /**
   * Gets or Sets Key
   * @type {string}
   * @memberof ProductDefinition
   */
  key?: string | null
  /**
   * Gets or Sets Name
   * @type {string}
   * @memberof ProductDefinition
   */
  name?: string | null
  /**
   * Gets or Sets Description
   * @type {string}
   * @memberof ProductDefinition
   */
  description?: string | null
  /**
   * Gets or Sets BrandName
   * @type {string}
   * @memberof ProductDefinition
   */
  brandName?: string | null
  /**
   * Gets or Sets Series
   * @type {string}
   * @memberof ProductDefinition
   */
  series?: string | null
  /**
   * Gets or Sets ProductBrand
   * @type {string}
   * @memberof ProductDefinition
   */
  productBrand?: string | null
  /**
   * Gets or Sets Type
   * @type {string}
   * @memberof ProductDefinition
   */
  type?: string | null
  /**
   * Gets or Sets System
   * @type {string}
   * @memberof ProductDefinition
   */
  system?: string | null
  /**
   *
   * @type {Gallery}
   * @memberof ProductDefinition
   */
  gallery?: Gallery
  /**
   *
   * @type {ProductDefinitionVariant}
   * @memberof ProductDefinition
   */
  variant?: ProductDefinitionVariant
  /**
   *
   * @type {ProductStateModel}
   * @memberof ProductDefinition
   */
  state?: ProductStateModel
  /**
   *
   * @type {ProductDefinitionArticles}
   * @memberof ProductDefinition
   */
  articles?: ProductDefinitionArticles
  /**
   *
   * @type {ProductDefinitionTechnicalDataList}
   * @memberof ProductDefinition
   */
  technicalDataList?: ProductDefinitionTechnicalDataList
  /**
   *
   * @type {ProductDefinitionAdditionalInformation}
   * @memberof ProductDefinition
   */
  additionalInformation?: ProductDefinitionAdditionalInformation
  /**
   *
   * @type {ProductDefinitionScopeOfDelivery}
   * @memberof ProductDefinition
   */
  scopeOfDelivery?: ProductDefinitionScopeOfDelivery
  /**
   *
   * @type {ProductDefinitionCharacteristics}
   * @memberof ProductDefinition
   */
  characteristics?: ProductDefinitionCharacteristics
  /**
   *
   * @type {ProductDefinitionApplicationPurposes}
   * @memberof ProductDefinition
   */
  applicationPurposes?: ProductDefinitionApplicationPurposes
  /**
   *
   * @type {ProductDefinitionAccessories}
   * @memberof ProductDefinition
   */
  accessories?: ProductDefinitionAccessories
  /**
   *
   * @type {ProductDefinitionAccessories}
   * @memberof ProductDefinition
   */
  canBeCombinedWith?: ProductDefinitionAccessories
  /**
   *
   * @type {ProductDefinitionSpareParts}
   * @memberof ProductDefinition
   */
  spareParts?: ProductDefinitionSpareParts
  /**
   *
   * @type {ProductDefinitionDocuments}
   * @memberof ProductDefinition
   */
  documents?: ProductDefinitionDocuments
  /**
   *
   * @type {ProductDefinitionVideos}
   * @memberof ProductDefinition
   */
  videos?: ProductDefinitionVideos
  /**
   *
   * @type {Apps}
   * @memberof ProductDefinition
   */
  apps?: Apps
  /**
   *
   * @type {ProductDefinitionAccessoriesOverviews}
   * @memberof ProductDefinition
   */
  accessoriesOverviews?: ProductDefinitionAccessoriesOverviews
  /**
   *
   * @type {ProductDefinitionAccessoriesOverviews}
   * @memberof ProductDefinition
   */
  intendedUseOverviews?: ProductDefinitionAccessoriesOverviews
  /**
   * Gets or Sets Categories
   * @type {Array<SparePartSearchCategories>}
   * @memberof ProductDefinition
   */
  categories?: Array<SparePartSearchCategories> | null
  /**
   *
   * @type {BimAssetsDefinition}
   * @memberof ProductDefinition
   */
  bimAssets?: BimAssetsDefinition
  /**
   *
   * @type {ProductDefinitionBimProperties}
   * @memberof ProductDefinition
   */
  bimProperties?: ProductDefinitionBimProperties
}
/**
 * ProductDefinitionAccessories
 * @export
 * @interface ProductDefinitionAccessories
 */
export interface ProductDefinitionAccessories {
  /**
   * Gets or Sets List
   * @type {Array<ProductTileModel>}
   * @memberof ProductDefinitionAccessories
   */
  list?: Array<ProductTileModel> | null
}
/**
 * ProductDefinitionAccessoriesOverviews
 * @export
 * @interface ProductDefinitionAccessoriesOverviews
 */
export interface ProductDefinitionAccessoriesOverviews {
  /**
   * Gets or Sets List
   * @type {Array<CategoryResponseDefinitionOverviews>}
   * @memberof ProductDefinitionAccessoriesOverviews
   */
  list?: Array<CategoryResponseDefinitionOverviews> | null
}
/**
 * ProductDefinitionAdditionalInformation
 * @export
 * @interface ProductDefinitionAdditionalInformation
 */
export interface ProductDefinitionAdditionalInformation {
  /**
   * Gets or Sets List
   * @type {Array<ProductDefinitionAdditionalInformationList>}
   * @memberof ProductDefinitionAdditionalInformation
   */
  list?: Array<ProductDefinitionAdditionalInformationList> | null
}
/**
 * ProductDefinitionAdditionalInformationList
 * @export
 * @interface ProductDefinitionAdditionalInformationList
 */
export interface ProductDefinitionAdditionalInformationList {
  /**
   * Gets or Sets Items
   * @type {Array<string>}
   * @memberof ProductDefinitionAdditionalInformationList
   */
  items?: Array<string> | null
}
/**
 * ProductDefinitionApplicationPurposes
 * @export
 * @interface ProductDefinitionApplicationPurposes
 */
export interface ProductDefinitionApplicationPurposes {
  /**
   * Gets or Sets List
   * @type {Array<ProductDefinitionApplicationPurposesList>}
   * @memberof ProductDefinitionApplicationPurposes
   */
  list?: Array<ProductDefinitionApplicationPurposesList> | null
}
/**
 * ProductDefinitionApplicationPurposesList
 * @export
 * @interface ProductDefinitionApplicationPurposesList
 */
export interface ProductDefinitionApplicationPurposesList {
  /**
   * Gets or Sets Name
   * @type {string}
   * @memberof ProductDefinitionApplicationPurposesList
   */
  name?: string | null
  /**
   * Gets or Sets Url
   * @type {string}
   * @memberof ProductDefinitionApplicationPurposesList
   */
  url?: string | null
  /**
   * Gets or Sets Sequence
   * @type {number}
   * @memberof ProductDefinitionApplicationPurposesList
   */
  sequence?: number | null
}
/**
 * ProductDefinitionArticles
 * @export
 * @interface ProductDefinitionArticles
 */
export interface ProductDefinitionArticles {
  /**
   * Gets or Sets ArticlesList
   * @type {Array<ProductDefinitionArticlesArticlesList>}
   * @memberof ProductDefinitionArticles
   */
  articlesList?: Array<ProductDefinitionArticlesArticlesList> | null
  /**
   * Gets or Sets AttributesList
   * @type {Array<SparePartSearchCategories>}
   * @memberof ProductDefinitionArticles
   */
  attributesList?: Array<SparePartSearchCategories> | null
  /**
   * Gets or Sets ArticlesListSize
   * @type {number}
   * @memberof ProductDefinitionArticles
   */
  articlesListSize?: number | null
}
/**
 * ProductDefinitionArticlesArticlesList
 * @export
 * @interface ProductDefinitionArticlesArticlesList
 */
export interface ProductDefinitionArticlesArticlesList {
  /**
   * Gets or Sets Key
   * @type {string}
   * @memberof ProductDefinitionArticlesArticlesList
   */
  key?: string | null
  /**
   * Gets or Sets ArticleId
   * @type {string}
   * @memberof ProductDefinitionArticlesArticlesList
   */
  articleId?: string | null
  /**
   * Gets or Sets Description
   * @type {string}
   * @memberof ProductDefinitionArticlesArticlesList
   */
  description?: string | null
  /**
   * Gets or Sets EanCode
   * @type {string}
   * @memberof ProductDefinitionArticlesArticlesList
   */
  eanCode?: string | null
  /**
   *
   * @type {ProductDefinitionArticlesGallery}
   * @memberof ProductDefinitionArticlesArticlesList
   */
  gallery?: ProductDefinitionArticlesGallery
  /**
   *
   * @type {ProductDefinitionArticlesCadDrawings}
   * @memberof ProductDefinitionArticlesArticlesList
   */
  cadDrawings?: ProductDefinitionArticlesCadDrawings
  /**
   * Gets or Sets AttributeValues
   * @type {Array<ProductDefinitionArticlesAttributeValues>}
   * @memberof ProductDefinitionArticlesArticlesList
   */
  attributeValues?: Array<ProductDefinitionArticlesAttributeValues> | null
  /**
   *
   * @type {ProductStateModel}
   * @memberof ProductDefinitionArticlesArticlesList
   */
  state?: ProductStateModel
}
/**
 * ProductDefinitionArticlesAttributeValues
 * @export
 * @interface ProductDefinitionArticlesAttributeValues
 */
export interface ProductDefinitionArticlesAttributeValues {
  /**
   * Gets or Sets Key
   * @type {string}
   * @memberof ProductDefinitionArticlesAttributeValues
   */
  key?: string | null
  /**
   * Gets or Sets Value
   * @type {string}
   * @memberof ProductDefinitionArticlesAttributeValues
   */
  value?: string | null
  /**
   * Gets or Sets RawValue
   * @type {string}
   * @memberof ProductDefinitionArticlesAttributeValues
   */
  rawValue?: string | null
  /**
   * Gets or Sets Unit
   * @type {Array<ProductDefinitionArticlesUnit>}
   * @memberof ProductDefinitionArticlesAttributeValues
   */
  unit?: Array<ProductDefinitionArticlesUnit> | null
}
/**
 * ProductDefinitionArticlesCadDrawings
 * @export
 * @interface ProductDefinitionArticlesCadDrawings
 */
export interface ProductDefinitionArticlesCadDrawings {
  /**
   * Gets or Sets List
   * @type {Array<ProductDefinitionArticlesCadDrawingsList>}
   * @memberof ProductDefinitionArticlesCadDrawings
   */
  list?: Array<ProductDefinitionArticlesCadDrawingsList> | null
}
/**
 * ProductDefinitionArticlesCadDrawingsList
 * @export
 * @interface ProductDefinitionArticlesCadDrawingsList
 */
export interface ProductDefinitionArticlesCadDrawingsList {
  /**
   * Gets or Sets Name
   * @type {string}
   * @memberof ProductDefinitionArticlesCadDrawingsList
   */
  name?: string | null
  /**
   * Gets or Sets Url
   * @type {string}
   * @memberof ProductDefinitionArticlesCadDrawingsList
   */
  url?: string | null
  /**
   * Gets or Sets ContentType
   * @type {string}
   * @memberof ProductDefinitionArticlesCadDrawingsList
   */
  contentType?: string | null
}
/**
 * ProductDefinitionArticlesGallery
 * @export
 * @interface ProductDefinitionArticlesGallery
 */
export interface ProductDefinitionArticlesGallery {
  /**
   * Gets or Sets List
   * @type {Array<SparePartSearchSparePartOverviewImage>}
   * @memberof ProductDefinitionArticlesGallery
   */
  list?: Array<SparePartSearchSparePartOverviewImage> | null
}
/**
 * ProductDefinitionArticlesUnit
 * @export
 * @interface ProductDefinitionArticlesUnit
 */
export interface ProductDefinitionArticlesUnit {
  /**
   * Gets or Sets Encoding
   * @type {string}
   * @memberof ProductDefinitionArticlesUnit
   */
  encoding?: string | null
  /**
   * Gets or Sets Value
   * @type {string}
   * @memberof ProductDefinitionArticlesUnit
   */
  value?: string | null
}
/**
 * ProductDefinitionBimProperties
 * @export
 * @interface ProductDefinitionBimProperties
 */
export interface ProductDefinitionBimProperties {
  /**
   * Gets or Sets ObjBased
   * @type {boolean}
   * @memberof ProductDefinitionBimProperties
   */
  objBased?: boolean | null
  /**
   * Gets or Sets RevitFamilyFaceBasedUri
   * @type {string}
   * @memberof ProductDefinitionBimProperties
   */
  revitFamilyFaceBasedUri?: string | null
}
/**
 * ProductDefinitionCharacteristics
 * @export
 * @interface ProductDefinitionCharacteristics
 */
export interface ProductDefinitionCharacteristics {
  /**
   * Gets or Sets List
   * @type {Array<ProductDefinitionCharacteristicsList>}
   * @memberof ProductDefinitionCharacteristics
   */
  list?: Array<ProductDefinitionCharacteristicsList> | null
}
/**
 * ProductDefinitionCharacteristicsList
 * @export
 * @interface ProductDefinitionCharacteristicsList
 */
export interface ProductDefinitionCharacteristicsList {
  /**
   * Gets or Sets Name
   * @type {string}
   * @memberof ProductDefinitionCharacteristicsList
   */
  name?: string | null
  /**
   * Gets or Sets Key
   * @type {string}
   * @memberof ProductDefinitionCharacteristicsList
   */
  key?: string | null
  /**
   * Gets or Sets Sequence
   * @type {number}
   * @memberof ProductDefinitionCharacteristicsList
   */
  sequence?: number | null
}
/**
 * ProductDefinitionDocuments
 * @export
 * @interface ProductDefinitionDocuments
 */
export interface ProductDefinitionDocuments {
  /**
   * Gets or Sets List
   * @type {Array<ProductDefinitionDocumentsList>}
   * @memberof ProductDefinitionDocuments
   */
  list?: Array<ProductDefinitionDocumentsList> | null
}
/**
 * ProductDefinitionDocumentsList
 * @export
 * @interface ProductDefinitionDocumentsList
 */
export interface ProductDefinitionDocumentsList {
  /**
   * Gets or Sets Description
   * @type {string}
   * @memberof ProductDefinitionDocumentsList
   */
  description?: string | null
  /**
   * Gets or Sets ContentType
   * @type {string}
   * @memberof ProductDefinitionDocumentsList
   */
  contentType?: string | null
  /**
   * Gets or Sets Url
   * @type {string}
   * @memberof ProductDefinitionDocumentsList
   */
  url?: string | null
  /**
   * Gets or Sets Languages
   * @type {Array<string>}
   * @memberof ProductDefinitionDocumentsList
   */
  languages?: Array<string> | null
}
/**
 * ProductDefinitionScopeOfDelivery
 * @export
 * @interface ProductDefinitionScopeOfDelivery
 */
export interface ProductDefinitionScopeOfDelivery {
  /**
   * Gets or Sets Included
   * @type {Array<ProductDefinitionScopeOfDeliveryIncluded>}
   * @memberof ProductDefinitionScopeOfDelivery
   */
  included?: Array<ProductDefinitionScopeOfDeliveryIncluded> | null
  /**
   * Gets or Sets NotIncluded
   * @type {Array<any>}
   * @memberof ProductDefinitionScopeOfDelivery
   */
  notIncluded?: Array<any> | null
  /**
   * Gets or Sets HasScopeOfDelivery
   * @type {boolean}
   * @memberof ProductDefinitionScopeOfDelivery
   */
  hasScopeOfDelivery?: boolean | null
}
/**
 * ProductDefinitionScopeOfDeliveryIncluded
 * @export
 * @interface ProductDefinitionScopeOfDeliveryIncluded
 */
export interface ProductDefinitionScopeOfDeliveryIncluded {
  /**
   * Gets or Sets Name
   * @type {string}
   * @memberof ProductDefinitionScopeOfDeliveryIncluded
   */
  name?: string | null
  /**
   * Gets or Sets Sequence
   * @type {number}
   * @memberof ProductDefinitionScopeOfDeliveryIncluded
   */
  sequence?: number | null
}
/**
 * ProductDefinitionSpareParts
 * @export
 * @interface ProductDefinitionSpareParts
 */
export interface ProductDefinitionSpareParts {
  /**
   * Gets or Sets List
   * @type {Array<ProductDefinitionSparePartsList>}
   * @memberof ProductDefinitionSpareParts
   */
  list?: Array<ProductDefinitionSparePartsList> | null
}
/**
 * ProductDefinitionSparePartsList
 * @export
 * @interface ProductDefinitionSparePartsList
 */
export interface ProductDefinitionSparePartsList {
  /**
   * Gets or Sets ProductId
   * @type {string}
   * @memberof ProductDefinitionSparePartsList
   */
  productId?: string | null
  /**
   * Gets or Sets Name
   * @type {string}
   * @memberof ProductDefinitionSparePartsList
   */
  name?: string | null
  /**
   * Gets or Sets Url
   * @type {string}
   * @memberof ProductDefinitionSparePartsList
   */
  url?: string | null
  /**
   * Gets or Sets ManufactureFrom
   * @type {number}
   * @memberof ProductDefinitionSparePartsList
   */
  manufactureFrom?: number | null
  /**
   *
   * @type {SparePartSearchSparePartOverviewImage}
   * @memberof ProductDefinitionSparePartsList
   */
  image?: SparePartSearchSparePartOverviewImage
  /**
   *
   * @type {ProductDefinitionSparePartsSparePartArticles}
   * @memberof ProductDefinitionSparePartsList
   */
  sparePartArticles?: ProductDefinitionSparePartsSparePartArticles
}
/**
 * ProductDefinitionSparePartsSparePartArticles
 * @export
 * @interface ProductDefinitionSparePartsSparePartArticles
 */
export interface ProductDefinitionSparePartsSparePartArticles {
  /**
   * Gets or Sets SparePartArticlesList
   * @type {Array<ProductDefinitionSparePartsSparePartArticlesSparePartArticlesList>}
   * @memberof ProductDefinitionSparePartsSparePartArticles
   */
  sparePartArticlesList?: Array<ProductDefinitionSparePartsSparePartArticlesSparePartArticlesList> | null
}
/**
 * ProductDefinitionSparePartsSparePartArticlesSparePartArticlesList
 * @export
 * @interface ProductDefinitionSparePartsSparePartArticlesSparePartArticlesList
 */
export interface ProductDefinitionSparePartsSparePartArticlesSparePartArticlesList {
  /**
   *
   * @type {ProductDefinitionSparePartsSparePartArticlesSparePartArticlesListTypeEnum}
   * @memberof ProductDefinitionSparePartsSparePartArticlesSparePartArticlesList
   */
  type?: ProductDefinitionSparePartsSparePartArticlesSparePartArticlesListTypeEnum
  /**
   * Gets or Sets Key
   * @type {string}
   * @memberof ProductDefinitionSparePartsSparePartArticlesSparePartArticlesList
   */
  key?: string | null
  /**
   * Gets or Sets Position
   * @type {string}
   * @memberof ProductDefinitionSparePartsSparePartArticlesSparePartArticlesList
   */
  position?: string | null
  /**
   * Gets or Sets Name
   * @type {string}
   * @memberof ProductDefinitionSparePartsSparePartArticlesSparePartArticlesList
   */
  name?: string | null
}

/**
 * Defines Type
 * @export
 * @enum {string}
 */

export const ProductDefinitionSparePartsSparePartArticlesSparePartArticlesListTypeEnum = {
  Art: "ART",
  Spt: "SPT",
} as const

export type ProductDefinitionSparePartsSparePartArticlesSparePartArticlesListTypeEnum =
  (typeof ProductDefinitionSparePartsSparePartArticlesSparePartArticlesListTypeEnum)[keyof typeof ProductDefinitionSparePartsSparePartArticlesSparePartArticlesListTypeEnum]

/**
 * ProductDefinitionTechnicalDataList
 * @export
 * @interface ProductDefinitionTechnicalDataList
 */
export interface ProductDefinitionTechnicalDataList {
  /**
   * Gets or Sets List
   * @type {Array<ProductDefinitionTechnicalDataListList>}
   * @memberof ProductDefinitionTechnicalDataList
   */
  list?: Array<ProductDefinitionTechnicalDataListList> | null
}
/**
 * ProductDefinitionTechnicalDataListList
 * @export
 * @interface ProductDefinitionTechnicalDataListList
 */
export interface ProductDefinitionTechnicalDataListList {
  /**
   * Gets or Sets Key
   * @type {string}
   * @memberof ProductDefinitionTechnicalDataListList
   */
  key?: string | null
  /**
   * Gets or Sets Name
   * @type {string}
   * @memberof ProductDefinitionTechnicalDataListList
   */
  name?: string | null
  /**
   * Gets or Sets Value
   * @type {string}
   * @memberof ProductDefinitionTechnicalDataListList
   */
  value?: string | null
  /**
   * Gets or Sets Sequence
   * @type {number}
   * @memberof ProductDefinitionTechnicalDataListList
   */
  sequence?: number | null
}
/**
 * ProductDefinitionVariant
 * @export
 * @interface ProductDefinitionVariant
 */
export interface ProductDefinitionVariant {
  /**
   * Gets or Sets Name
   * @type {string}
   * @memberof ProductDefinitionVariant
   */
  name?: string | null
  /**
   * Gets or Sets Sku
   * @type {string}
   * @memberof ProductDefinitionVariant
   */
  sku?: string | null
  /**
   * Gets or Sets Url
   * @type {string}
   * @memberof ProductDefinitionVariant
   */
  url?: string | null
}
/**
 * ProductDefinitionVideos
 * @export
 * @interface ProductDefinitionVideos
 */
export interface ProductDefinitionVideos {
  /**
   * Gets or Sets List
   * @type {Array<ProductDefinitionVideosList>}
   * @memberof ProductDefinitionVideos
   */
  list?: Array<ProductDefinitionVideosList> | null
}
/**
 * ProductDefinitionVideosList
 * @export
 * @interface ProductDefinitionVideosList
 */
export interface ProductDefinitionVideosList {
  /**
   * Gets or Sets YoutubeID
   * @type {string}
   * @memberof ProductDefinitionVideosList
   */
  youtubeID?: string | null
}
/**
 *
 * @export
 * @interface ProductInCacheRequest
 */
export interface ProductInCacheRequest {
  /**
   *
   * @type {string}
   * @memberof ProductInCacheRequest
   */
  productKey?: string | null
  /**
   *
   * @type {string}
   * @memberof ProductInCacheRequest
   */
  cultureCode?: string | null
  /**
   *
   * @type {string}
   * @memberof ProductInCacheRequest
   */
  revitVersion?: string | null
}
/**
 * ProductStateModel
 * @export
 * @interface ProductStateModel
 */
export interface ProductStateModel {
  /**
   *
   * @type {Indicator}
   * @memberof ProductStateModel
   */
  indicator?: Indicator
  /**
   * Gets or Sets BimRelevant
   * @type {boolean}
   * @memberof ProductStateModel
   */
  bimRelevant?: boolean | null
  /**
   * Gets or Sets BimReady
   * @type {boolean}
   * @memberof ProductStateModel
   */
  bimReady?: boolean | null
  /**
   * Gets or Sets From
   * @type {string}
   * @memberof ProductStateModel
   */
  from?: string | null
  /**
   * Gets or Sets ReplacementFor
   * @type {string}
   * @memberof ProductStateModel
   */
  replacementFor?: string | null
  /**
   * Gets or Sets ReplacedBy
   * @type {string}
   * @memberof ProductStateModel
   */
  replacedBy?: string | null
  /**
   * Gets or Sets Buyable
   * @type {boolean}
   * @memberof ProductStateModel
   */
  buyable?: boolean | null
}

/**
 * ProductTileModel
 * @export
 * @interface ProductTileModel
 */
export interface ProductTileModel {
  /**
   * Gets or Sets Key
   * @type {string}
   * @memberof ProductTileModel
   */
  key?: string | null
  /**
   * Gets or Sets Name
   * @type {string}
   * @memberof ProductTileModel
   */
  name?: string | null
  /**
   * Gets or Sets NumberArticles
   * @type {number}
   * @memberof ProductTileModel
   */
  numberArticles?: number | null
  /**
   *
   * @type {ProductStateModel}
   * @memberof ProductTileModel
   */
  state?: ProductStateModel
  /**
   *
   * @type {Gallery}
   * @memberof ProductTileModel
   */
  gallery?: Gallery
}
/**
 *
 * @export
 * @interface ProductVersionInCache
 */
export interface ProductVersionInCache {
  /**
   *
   * @type {string}
   * @memberof ProductVersionInCache
   */
  productKey?: string | null
  /**
   *
   * @type {string}
   * @memberof ProductVersionInCache
   */
  cultureCode?: string | null
  /**
   *
   * @type {string}
   * @memberof ProductVersionInCache
   */
  pimVersion?: string | null
}
/**
 * SparePartSearchCategories
 * @export
 * @interface SparePartSearchCategories
 */
export interface SparePartSearchCategories {
  /**
   * Gets or Sets Key
   * @type {string}
   * @memberof SparePartSearchCategories
   */
  key?: string | null
  /**
   * Gets or Sets Name
   * @type {string}
   * @memberof SparePartSearchCategories
   */
  name?: string | null
}
/**
 * SparePartSearchSparePartOverviewImage
 * @export
 * @interface SparePartSearchSparePartOverviewImage
 */
export interface SparePartSearchSparePartOverviewImage {
  /**
   * Gets or Sets Type
   * @type {string}
   * @memberof SparePartSearchSparePartOverviewImage
   */
  type?: string | null
  /**
   * Gets or Sets Key
   * @type {string}
   * @memberof SparePartSearchSparePartOverviewImage
   */
  key?: string | null
  /**
   * Gets or Sets Orientation
   * @type {string}
   * @memberof SparePartSearchSparePartOverviewImage
   */
  orientation?: string | null
  /**
   * Gets or Sets ThumbnailSmall
   * @type {string}
   * @memberof SparePartSearchSparePartOverviewImage
   */
  thumbnailSmall?: string | null
  /**
   * Gets or Sets Thumbnail
   * @type {string}
   * @memberof SparePartSearchSparePartOverviewImage
   */
  thumbnail?: string | null
  /**
   * Gets or Sets Small
   * @type {string}
   * @memberof SparePartSearchSparePartOverviewImage
   */
  small?: string | null
  /**
   * Gets or Sets Medium
   * @type {string}
   * @memberof SparePartSearchSparePartOverviewImage
   */
  medium?: string | null
  /**
   * Gets or Sets Large
   * @type {string}
   * @memberof SparePartSearchSparePartOverviewImage
   */
  large?: string | null
  /**
   * Gets or Sets Original
   * @type {string}
   * @memberof SparePartSearchSparePartOverviewImage
   */
  original?: string | null
}
/**
 * StoresInner
 * @export
 * @interface StoresInner
 */
export interface StoresInner {
  /**
   * Gets or Sets Url
   * @type {string}
   * @memberof StoresInner
   */
  url?: string | null
  /**
   * Gets or Sets Name
   * @type {string}
   * @memberof StoresInner
   */
  name?: string | null
}

/**
 * ArticleApi - axios parameter creator
 * @export
 */
export const ArticleApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get the list of the product IDs for selected locale and article number.
     * @summary Get the product IDs for desired locale and article number.
     * @param {string} locale Parameter to specify for which region/country you want to retrieve data.              The locale not only possibly affects the language of the data you get, but also the available products,              articles, and spare parts.
     * @param {string} articleNumber Article number.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductsAssignedToArticle: async (
      locale: string,
      articleNumber: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getProductsAssignedToArticle", "locale", locale)
      // verify required parameter 'articleNumber' is not null or undefined
      assertParamExists("getProductsAssignedToArticle", "articleNumber", articleNumber)
      const localVarPath = `/api/bimcontent/{locale}/articles/{articleNumber}/products`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"articleNumber"}}`, encodeURIComponent(String(articleNumber)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ArticleApi - functional programming interface
 * @export
 */
export const ArticleApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ArticleApiAxiosParamCreator(configuration)
  return {
    /**
     * Get the list of the product IDs for selected locale and article number.
     * @summary Get the product IDs for desired locale and article number.
     * @param {string} locale Parameter to specify for which region/country you want to retrieve data.              The locale not only possibly affects the language of the data you get, but also the available products,              articles, and spare parts.
     * @param {string} articleNumber Article number.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProductsAssignedToArticle(
      locale: string,
      articleNumber: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProductsAssignedToArticle(
        locale,
        articleNumber,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * ArticleApi - factory interface
 * @export
 */
export const ArticleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ArticleApiFp(configuration)
  return {
    /**
     * Get the list of the product IDs for selected locale and article number.
     * @summary Get the product IDs for desired locale and article number.
     * @param {string} locale Parameter to specify for which region/country you want to retrieve data.              The locale not only possibly affects the language of the data you get, but also the available products,              articles, and spare parts.
     * @param {string} articleNumber Article number.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductsAssignedToArticle(locale: string, articleNumber: string, options?: any): AxiosPromise<Array<string>> {
      return localVarFp
        .getProductsAssignedToArticle(locale, articleNumber, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * ArticleApi - object-oriented interface
 * @export
 * @class ArticleApi
 * @extends {BaseAPI}
 */
export class ArticleApi extends BaseAPI {
  /**
   * Get the list of the product IDs for selected locale and article number.
   * @summary Get the product IDs for desired locale and article number.
   * @param {string} locale Parameter to specify for which region/country you want to retrieve data.              The locale not only possibly affects the language of the data you get, but also the available products,              articles, and spare parts.
   * @param {string} articleNumber Article number.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArticleApi
   */
  public getProductsAssignedToArticle(locale: string, articleNumber: string, options?: AxiosRequestConfig) {
    return ArticleApiFp(this.configuration)
      .getProductsAssignedToArticle(locale, articleNumber, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * CategoryApi - axios parameter creator
 * @export
 */
export const CategoryApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get the category tree for a selected locale where products are categorized in order.
     * @summary Get whole category structure.
     * @param {string} locale Parameter to specify for which region/country you want to retrieve data.              The locale not only possibly affects the language of the data you get, but also the available products,              articles, and spare parts.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCategories: async (locale: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getCategories", "locale", locale)
      const localVarPath = `/api/bimcontent/{locale}/categories`.replace(
        `{${"locale"}}`,
        encodeURIComponent(String(locale))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get the list of product information under a certain category by selected locale and category ID.
     * @summary Get list of product information for desired locale and category ID.
     * @param {string} locale Parameter to specify for which region/country you want to retrieve data.              The locale not only possibly affects the language of the data you get, but also the available products,              articles, and spare parts.               See https://developer.geberit.com/locales
     * @param {string} categoryKey Product category key, like \&quot;CH3_101513\&quot;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductsByCategory: async (
      locale: string,
      categoryKey: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getProductsByCategory", "locale", locale)
      // verify required parameter 'categoryKey' is not null or undefined
      assertParamExists("getProductsByCategory", "categoryKey", categoryKey)
      const localVarPath = `/api/bimcontent/{locale}/categories/{categoryKey}/products`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"categoryKey"}}`, encodeURIComponent(String(categoryKey)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * CategoryApi - functional programming interface
 * @export
 */
export const CategoryApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CategoryApiAxiosParamCreator(configuration)
  return {
    /**
     * Get the category tree for a selected locale where products are categorized in order.
     * @summary Get whole category structure.
     * @param {string} locale Parameter to specify for which region/country you want to retrieve data.              The locale not only possibly affects the language of the data you get, but also the available products,              articles, and spare parts.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCategories(
      locale: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryResponseDefinition>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCategories(locale, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get the list of product information under a certain category by selected locale and category ID.
     * @summary Get list of product information for desired locale and category ID.
     * @param {string} locale Parameter to specify for which region/country you want to retrieve data.              The locale not only possibly affects the language of the data you get, but also the available products,              articles, and spare parts.               See https://developer.geberit.com/locales
     * @param {string} categoryKey Product category key, like \&quot;CH3_101513\&quot;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProductsByCategory(
      locale: string,
      categoryKey: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductDefinition>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProductsByCategory(locale, categoryKey, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * CategoryApi - factory interface
 * @export
 */
export const CategoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = CategoryApiFp(configuration)
  return {
    /**
     * Get the category tree for a selected locale where products are categorized in order.
     * @summary Get whole category structure.
     * @param {string} locale Parameter to specify for which region/country you want to retrieve data.              The locale not only possibly affects the language of the data you get, but also the available products,              articles, and spare parts.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCategories(locale: string, options?: any): AxiosPromise<CategoryResponseDefinition> {
      return localVarFp.getCategories(locale, options).then((request) => request(axios, basePath))
    },
    /**
     * Get the list of product information under a certain category by selected locale and category ID.
     * @summary Get list of product information for desired locale and category ID.
     * @param {string} locale Parameter to specify for which region/country you want to retrieve data.              The locale not only possibly affects the language of the data you get, but also the available products,              articles, and spare parts.               See https://developer.geberit.com/locales
     * @param {string} categoryKey Product category key, like \&quot;CH3_101513\&quot;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductsByCategory(locale: string, categoryKey: string, options?: any): AxiosPromise<Array<ProductDefinition>> {
      return localVarFp.getProductsByCategory(locale, categoryKey, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * CategoryApi - object-oriented interface
 * @export
 * @class CategoryApi
 * @extends {BaseAPI}
 */
export class CategoryApi extends BaseAPI {
  /**
   * Get the category tree for a selected locale where products are categorized in order.
   * @summary Get whole category structure.
   * @param {string} locale Parameter to specify for which region/country you want to retrieve data.              The locale not only possibly affects the language of the data you get, but also the available products,              articles, and spare parts.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoryApi
   */
  public getCategories(locale: string, options?: AxiosRequestConfig) {
    return CategoryApiFp(this.configuration)
      .getCategories(locale, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get the list of product information under a certain category by selected locale and category ID.
   * @summary Get list of product information for desired locale and category ID.
   * @param {string} locale Parameter to specify for which region/country you want to retrieve data.              The locale not only possibly affects the language of the data you get, but also the available products,              articles, and spare parts.               See https://developer.geberit.com/locales
   * @param {string} categoryKey Product category key, like \&quot;CH3_101513\&quot;.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoryApi
   */
  public getProductsByCategory(locale: string, categoryKey: string, options?: AxiosRequestConfig) {
    return CategoryApiFp(this.configuration)
      .getProductsByCategory(locale, categoryKey, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * ProductApi - axios parameter creator
 * @export
 */
export const ProductApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get the download link for the product file by selected locale, pim version, revit version and file extension.
     * @summary Get the download link for the item.
     * @param {string} locale Parameter to specify for which region/country you want to retrieve data.              The locale not only possibly affects the language of the data you get, but also the available products,              articles, and spare parts.               See https://developer.geberit.com/locales
     * @param {string} productDefinitionKey Product identification.
     * @param {string} pimVersion Version.
     * @param {string} revitVersion Revit version (year only).
     * @param {string} fileExtension File format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDownloadUri: async (
      locale: string,
      productDefinitionKey: string,
      pimVersion: string,
      revitVersion: string,
      fileExtension: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getDownloadUri", "locale", locale)
      // verify required parameter 'productDefinitionKey' is not null or undefined
      assertParamExists("getDownloadUri", "productDefinitionKey", productDefinitionKey)
      // verify required parameter 'pimVersion' is not null or undefined
      assertParamExists("getDownloadUri", "pimVersion", pimVersion)
      // verify required parameter 'revitVersion' is not null or undefined
      assertParamExists("getDownloadUri", "revitVersion", revitVersion)
      // verify required parameter 'fileExtension' is not null or undefined
      assertParamExists("getDownloadUri", "fileExtension", fileExtension)
      const localVarPath = `/api/bimcontent/{locale}/products/{productDefinitionKey}/versions/{pimVersion}/uri`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"productDefinitionKey"}}`, encodeURIComponent(String(productDefinitionKey)))
        .replace(`{${"pimVersion"}}`, encodeURIComponent(String(pimVersion)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      if (revitVersion !== undefined) {
        localVarQueryParameter["revitVersion"] = revitVersion
      }

      if (fileExtension !== undefined) {
        localVarQueryParameter["fileExtension"] = fileExtension
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get the download link for the face based product file by selected locale, pim version, revit version and file extension.  <br />  Face based product is a special type of BIM content designed for Revit.  <br />
     * @summary Get the download link for the face based item.
     * @param {string} locale Parameter to specify for which region/country you want to retrieve data.              The locale not only possibly affects the language of the data you get, but also the available products,              articles, and spare parts.               See https://developer.geberit.com/locales
     * @param {string} productDefinitionKey Product identification.
     * @param {string} pimVersion Version.
     * @param {string} revitVersion Revit version (year only).
     * @param {string} fileExtension File format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDownloadUriFaceBased: async (
      locale: string,
      productDefinitionKey: string,
      pimVersion: string,
      revitVersion: string,
      fileExtension: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getDownloadUriFaceBased", "locale", locale)
      // verify required parameter 'productDefinitionKey' is not null or undefined
      assertParamExists("getDownloadUriFaceBased", "productDefinitionKey", productDefinitionKey)
      // verify required parameter 'pimVersion' is not null or undefined
      assertParamExists("getDownloadUriFaceBased", "pimVersion", pimVersion)
      // verify required parameter 'revitVersion' is not null or undefined
      assertParamExists("getDownloadUriFaceBased", "revitVersion", revitVersion)
      // verify required parameter 'fileExtension' is not null or undefined
      assertParamExists("getDownloadUriFaceBased", "fileExtension", fileExtension)
      const localVarPath =
        `/api/bimcontent/{locale}/products/{productDefinitionKey}/versions/{pimVersion}/uri/facedbased`
          .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
          .replace(`{${"productDefinitionKey"}}`, encodeURIComponent(String(productDefinitionKey)))
          .replace(`{${"pimVersion"}}`, encodeURIComponent(String(pimVersion)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      if (revitVersion !== undefined) {
        localVarQueryParameter["revitVersion"] = revitVersion
      }

      if (fileExtension !== undefined) {
        localVarQueryParameter["fileExtension"] = fileExtension
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get the product information json by selected locale and product ID which holds product specific data.
     * @summary Get the product information for desired locale and product ID.
     * @param {string} locale Parameter to specify for which region/country you want to retrieve data.              The locale not only possibly affects the language of the data you get, but also the available products,              articles, and spare parts.
     * @param {string} productDefinitionKey Product identification.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProduct: async (
      locale: string,
      productDefinitionKey: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getProduct", "locale", locale)
      // verify required parameter 'productDefinitionKey' is not null or undefined
      assertParamExists("getProduct", "productDefinitionKey", productDefinitionKey)
      const localVarPath = `/api/bimcontent/{locale}/products/{productDefinitionKey}`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"productDefinitionKey"}}`, encodeURIComponent(String(productDefinitionKey)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get the list of product information under a certain category by selected locale and category ID.
     * @summary Get list of product information for desired locale and category ID.
     * @param {string} locale Parameter to specify for which region/country you want to retrieve data.              The locale not only possibly affects the language of the data you get, but also the available products,              articles, and spare parts.               See https://developer.geberit.com/locales
     * @param {string} categoryKey Product category key, like \&quot;CH3_101513\&quot;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductsByCategory: async (
      locale: string,
      categoryKey: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getProductsByCategory", "locale", locale)
      // verify required parameter 'categoryKey' is not null or undefined
      assertParamExists("getProductsByCategory", "categoryKey", categoryKey)
      const localVarPath = `/api/bimcontent/{locale}/categories/{categoryKey}/products`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"categoryKey"}}`, encodeURIComponent(String(categoryKey)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Search products either by their article ID or product ID and get a list of string containing matched product Ids.
     * @summary Find products either by their article ID or product ID.
     * @param {string} locale Parameter to specify for which region/country you want to retrieve data.              The locale not only possibly affects the language of the data you get, but also the available products,              articles, and spare parts.               See https://developer.geberit.com/locales
     * @param {string} articleDefinitionKeyOrProductName Some text that will be searched as an article ID, then if nothing is found, within the product name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductsByText: async (
      locale: string,
      articleDefinitionKeyOrProductName: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getProductsByText", "locale", locale)
      // verify required parameter 'articleDefinitionKeyOrProductName' is not null or undefined
      assertParamExists("getProductsByText", "articleDefinitionKeyOrProductName", articleDefinitionKeyOrProductName)
      const localVarPath = `/api/bimcontent/{locale}/products`.replace(
        `{${"locale"}}`,
        encodeURIComponent(String(locale))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      if (articleDefinitionKeyOrProductName !== undefined) {
        localVarQueryParameter["articleDefinitionKeyOrProductName"] = articleDefinitionKeyOrProductName
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get the product\'s revit file download link by selected locale and product ID.
     * @summary Get the download link for a product file by locale and product ID.
     * @param {string} productDefinitionKey Product identification.
     * @param {string} locale Parameter to specify for which region/country you want to retrieve data.              The locale not only possibly affects the language of the data you get, but also the available products,              articles, and spare parts.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPublishedProductUri: async (
      productDefinitionKey: string,
      locale: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'productDefinitionKey' is not null or undefined
      assertParamExists("getPublishedProductUri", "productDefinitionKey", productDefinitionKey)
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getPublishedProductUri", "locale", locale)
      const localVarPath = `/api/bimcontent/{locale}/products/{productDefinitionKey}/uri`
        .replace(`{${"productDefinitionKey"}}`, encodeURIComponent(String(productDefinitionKey)))
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get the list of published versions of the products which contain the product Id, locale and version information.
     * @summary Get the latest published versions of the products.
     * @param {Array<ProductInCacheRequest>} [productInCacheRequest] List of product with the version information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPublishedVersions: async (
      productInCacheRequest?: Array<ProductInCacheRequest>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/bimcontent/products/publishedversions`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      localVarHeaderParameter["Content-Type"] = "application/json; v=1.0"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(productInCacheRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Verify if a product is a system family or not, which is a special type of BIM content for Revit.
     * @summary Indicate if the product is a system family.
     * @param {string} productDefinitionKey Product identification.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    isSystemFamily: async (productDefinitionKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'productDefinitionKey' is not null or undefined
      assertParamExists("isSystemFamily", "productDefinitionKey", productDefinitionKey)
      const localVarPath = `/api/bimcontent/products/{productDefinitionKey}/issystemfamily`.replace(
        `{${"productDefinitionKey"}}`,
        encodeURIComponent(String(productDefinitionKey))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ProductApi - functional programming interface
 * @export
 */
export const ProductApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ProductApiAxiosParamCreator(configuration)
  return {
    /**
     * Get the download link for the product file by selected locale, pim version, revit version and file extension.
     * @summary Get the download link for the item.
     * @param {string} locale Parameter to specify for which region/country you want to retrieve data.              The locale not only possibly affects the language of the data you get, but also the available products,              articles, and spare parts.               See https://developer.geberit.com/locales
     * @param {string} productDefinitionKey Product identification.
     * @param {string} pimVersion Version.
     * @param {string} revitVersion Revit version (year only).
     * @param {string} fileExtension File format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDownloadUri(
      locale: string,
      productDefinitionKey: string,
      pimVersion: string,
      revitVersion: string,
      fileExtension: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDownloadUri(
        locale,
        productDefinitionKey,
        pimVersion,
        revitVersion,
        fileExtension,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get the download link for the face based product file by selected locale, pim version, revit version and file extension.  <br />  Face based product is a special type of BIM content designed for Revit.  <br />
     * @summary Get the download link for the face based item.
     * @param {string} locale Parameter to specify for which region/country you want to retrieve data.              The locale not only possibly affects the language of the data you get, but also the available products,              articles, and spare parts.               See https://developer.geberit.com/locales
     * @param {string} productDefinitionKey Product identification.
     * @param {string} pimVersion Version.
     * @param {string} revitVersion Revit version (year only).
     * @param {string} fileExtension File format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDownloadUriFaceBased(
      locale: string,
      productDefinitionKey: string,
      pimVersion: string,
      revitVersion: string,
      fileExtension: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDownloadUriFaceBased(
        locale,
        productDefinitionKey,
        pimVersion,
        revitVersion,
        fileExtension,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get the product information json by selected locale and product ID which holds product specific data.
     * @summary Get the product information for desired locale and product ID.
     * @param {string} locale Parameter to specify for which region/country you want to retrieve data.              The locale not only possibly affects the language of the data you get, but also the available products,              articles, and spare parts.
     * @param {string} productDefinitionKey Product identification.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProduct(
      locale: string,
      productDefinitionKey: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDefinition>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProduct(locale, productDefinitionKey, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get the list of product information under a certain category by selected locale and category ID.
     * @summary Get list of product information for desired locale and category ID.
     * @param {string} locale Parameter to specify for which region/country you want to retrieve data.              The locale not only possibly affects the language of the data you get, but also the available products,              articles, and spare parts.               See https://developer.geberit.com/locales
     * @param {string} categoryKey Product category key, like \&quot;CH3_101513\&quot;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProductsByCategory(
      locale: string,
      categoryKey: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductDefinition>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProductsByCategory(locale, categoryKey, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Search products either by their article ID or product ID and get a list of string containing matched product Ids.
     * @summary Find products either by their article ID or product ID.
     * @param {string} locale Parameter to specify for which region/country you want to retrieve data.              The locale not only possibly affects the language of the data you get, but also the available products,              articles, and spare parts.               See https://developer.geberit.com/locales
     * @param {string} articleDefinitionKeyOrProductName Some text that will be searched as an article ID, then if nothing is found, within the product name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProductsByText(
      locale: string,
      articleDefinitionKeyOrProductName: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProductsByText(
        locale,
        articleDefinitionKeyOrProductName,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get the product\'s revit file download link by selected locale and product ID.
     * @summary Get the download link for a product file by locale and product ID.
     * @param {string} productDefinitionKey Product identification.
     * @param {string} locale Parameter to specify for which region/country you want to retrieve data.              The locale not only possibly affects the language of the data you get, but also the available products,              articles, and spare parts.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPublishedProductUri(
      productDefinitionKey: string,
      locale: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPublishedProductUri(
        productDefinitionKey,
        locale,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get the list of published versions of the products which contain the product Id, locale and version information.
     * @summary Get the latest published versions of the products.
     * @param {Array<ProductInCacheRequest>} [productInCacheRequest] List of product with the version information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPublishedVersions(
      productInCacheRequest?: Array<ProductInCacheRequest>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductVersionInCache>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPublishedVersions(productInCacheRequest, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Verify if a product is a system family or not, which is a special type of BIM content for Revit.
     * @summary Indicate if the product is a system family.
     * @param {string} productDefinitionKey Product identification.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async isSystemFamily(
      productDefinitionKey: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.isSystemFamily(productDefinitionKey, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * ProductApi - factory interface
 * @export
 */
export const ProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ProductApiFp(configuration)
  return {
    /**
     * Get the download link for the product file by selected locale, pim version, revit version and file extension.
     * @summary Get the download link for the item.
     * @param {string} locale Parameter to specify for which region/country you want to retrieve data.              The locale not only possibly affects the language of the data you get, but also the available products,              articles, and spare parts.               See https://developer.geberit.com/locales
     * @param {string} productDefinitionKey Product identification.
     * @param {string} pimVersion Version.
     * @param {string} revitVersion Revit version (year only).
     * @param {string} fileExtension File format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDownloadUri(
      locale: string,
      productDefinitionKey: string,
      pimVersion: string,
      revitVersion: string,
      fileExtension: string,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .getDownloadUri(locale, productDefinitionKey, pimVersion, revitVersion, fileExtension, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Get the download link for the face based product file by selected locale, pim version, revit version and file extension.  <br />  Face based product is a special type of BIM content designed for Revit.  <br />
     * @summary Get the download link for the face based item.
     * @param {string} locale Parameter to specify for which region/country you want to retrieve data.              The locale not only possibly affects the language of the data you get, but also the available products,              articles, and spare parts.               See https://developer.geberit.com/locales
     * @param {string} productDefinitionKey Product identification.
     * @param {string} pimVersion Version.
     * @param {string} revitVersion Revit version (year only).
     * @param {string} fileExtension File format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDownloadUriFaceBased(
      locale: string,
      productDefinitionKey: string,
      pimVersion: string,
      revitVersion: string,
      fileExtension: string,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .getDownloadUriFaceBased(locale, productDefinitionKey, pimVersion, revitVersion, fileExtension, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Get the product information json by selected locale and product ID which holds product specific data.
     * @summary Get the product information for desired locale and product ID.
     * @param {string} locale Parameter to specify for which region/country you want to retrieve data.              The locale not only possibly affects the language of the data you get, but also the available products,              articles, and spare parts.
     * @param {string} productDefinitionKey Product identification.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProduct(locale: string, productDefinitionKey: string, options?: any): AxiosPromise<ProductDefinition> {
      return localVarFp.getProduct(locale, productDefinitionKey, options).then((request) => request(axios, basePath))
    },
    /**
     * Get the list of product information under a certain category by selected locale and category ID.
     * @summary Get list of product information for desired locale and category ID.
     * @param {string} locale Parameter to specify for which region/country you want to retrieve data.              The locale not only possibly affects the language of the data you get, but also the available products,              articles, and spare parts.               See https://developer.geberit.com/locales
     * @param {string} categoryKey Product category key, like \&quot;CH3_101513\&quot;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductsByCategory(locale: string, categoryKey: string, options?: any): AxiosPromise<Array<ProductDefinition>> {
      return localVarFp.getProductsByCategory(locale, categoryKey, options).then((request) => request(axios, basePath))
    },
    /**
     * Search products either by their article ID or product ID and get a list of string containing matched product Ids.
     * @summary Find products either by their article ID or product ID.
     * @param {string} locale Parameter to specify for which region/country you want to retrieve data.              The locale not only possibly affects the language of the data you get, but also the available products,              articles, and spare parts.               See https://developer.geberit.com/locales
     * @param {string} articleDefinitionKeyOrProductName Some text that will be searched as an article ID, then if nothing is found, within the product name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductsByText(
      locale: string,
      articleDefinitionKeyOrProductName: string,
      options?: any
    ): AxiosPromise<Array<string>> {
      return localVarFp
        .getProductsByText(locale, articleDefinitionKeyOrProductName, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Get the product\'s revit file download link by selected locale and product ID.
     * @summary Get the download link for a product file by locale and product ID.
     * @param {string} productDefinitionKey Product identification.
     * @param {string} locale Parameter to specify for which region/country you want to retrieve data.              The locale not only possibly affects the language of the data you get, but also the available products,              articles, and spare parts.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPublishedProductUri(productDefinitionKey: string, locale: string, options?: any): AxiosPromise<string> {
      return localVarFp
        .getPublishedProductUri(productDefinitionKey, locale, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Get the list of published versions of the products which contain the product Id, locale and version information.
     * @summary Get the latest published versions of the products.
     * @param {Array<ProductInCacheRequest>} [productInCacheRequest] List of product with the version information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPublishedVersions(
      productInCacheRequest?: Array<ProductInCacheRequest>,
      options?: any
    ): AxiosPromise<Array<ProductVersionInCache>> {
      return localVarFp.getPublishedVersions(productInCacheRequest, options).then((request) => request(axios, basePath))
    },
    /**
     * Verify if a product is a system family or not, which is a special type of BIM content for Revit.
     * @summary Indicate if the product is a system family.
     * @param {string} productDefinitionKey Product identification.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    isSystemFamily(productDefinitionKey: string, options?: any): AxiosPromise<boolean> {
      return localVarFp.isSystemFamily(productDefinitionKey, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * ProductApi - object-oriented interface
 * @export
 * @class ProductApi
 * @extends {BaseAPI}
 */
export class ProductApi extends BaseAPI {
  /**
   * Get the download link for the product file by selected locale, pim version, revit version and file extension.
   * @summary Get the download link for the item.
   * @param {string} locale Parameter to specify for which region/country you want to retrieve data.              The locale not only possibly affects the language of the data you get, but also the available products,              articles, and spare parts.               See https://developer.geberit.com/locales
   * @param {string} productDefinitionKey Product identification.
   * @param {string} pimVersion Version.
   * @param {string} revitVersion Revit version (year only).
   * @param {string} fileExtension File format
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductApi
   */
  public getDownloadUri(
    locale: string,
    productDefinitionKey: string,
    pimVersion: string,
    revitVersion: string,
    fileExtension: string,
    options?: AxiosRequestConfig
  ) {
    return ProductApiFp(this.configuration)
      .getDownloadUri(locale, productDefinitionKey, pimVersion, revitVersion, fileExtension, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get the download link for the face based product file by selected locale, pim version, revit version and file extension.  <br />  Face based product is a special type of BIM content designed for Revit.  <br />
   * @summary Get the download link for the face based item.
   * @param {string} locale Parameter to specify for which region/country you want to retrieve data.              The locale not only possibly affects the language of the data you get, but also the available products,              articles, and spare parts.               See https://developer.geberit.com/locales
   * @param {string} productDefinitionKey Product identification.
   * @param {string} pimVersion Version.
   * @param {string} revitVersion Revit version (year only).
   * @param {string} fileExtension File format
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductApi
   */
  public getDownloadUriFaceBased(
    locale: string,
    productDefinitionKey: string,
    pimVersion: string,
    revitVersion: string,
    fileExtension: string,
    options?: AxiosRequestConfig
  ) {
    return ProductApiFp(this.configuration)
      .getDownloadUriFaceBased(locale, productDefinitionKey, pimVersion, revitVersion, fileExtension, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get the product information json by selected locale and product ID which holds product specific data.
   * @summary Get the product information for desired locale and product ID.
   * @param {string} locale Parameter to specify for which region/country you want to retrieve data.              The locale not only possibly affects the language of the data you get, but also the available products,              articles, and spare parts.
   * @param {string} productDefinitionKey Product identification.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductApi
   */
  public getProduct(locale: string, productDefinitionKey: string, options?: AxiosRequestConfig) {
    return ProductApiFp(this.configuration)
      .getProduct(locale, productDefinitionKey, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get the list of product information under a certain category by selected locale and category ID.
   * @summary Get list of product information for desired locale and category ID.
   * @param {string} locale Parameter to specify for which region/country you want to retrieve data.              The locale not only possibly affects the language of the data you get, but also the available products,              articles, and spare parts.               See https://developer.geberit.com/locales
   * @param {string} categoryKey Product category key, like \&quot;CH3_101513\&quot;.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductApi
   */
  public getProductsByCategory(locale: string, categoryKey: string, options?: AxiosRequestConfig) {
    return ProductApiFp(this.configuration)
      .getProductsByCategory(locale, categoryKey, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Search products either by their article ID or product ID and get a list of string containing matched product Ids.
   * @summary Find products either by their article ID or product ID.
   * @param {string} locale Parameter to specify for which region/country you want to retrieve data.              The locale not only possibly affects the language of the data you get, but also the available products,              articles, and spare parts.               See https://developer.geberit.com/locales
   * @param {string} articleDefinitionKeyOrProductName Some text that will be searched as an article ID, then if nothing is found, within the product name.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductApi
   */
  public getProductsByText(locale: string, articleDefinitionKeyOrProductName: string, options?: AxiosRequestConfig) {
    return ProductApiFp(this.configuration)
      .getProductsByText(locale, articleDefinitionKeyOrProductName, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get the product\'s revit file download link by selected locale and product ID.
   * @summary Get the download link for a product file by locale and product ID.
   * @param {string} productDefinitionKey Product identification.
   * @param {string} locale Parameter to specify for which region/country you want to retrieve data.              The locale not only possibly affects the language of the data you get, but also the available products,              articles, and spare parts.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductApi
   */
  public getPublishedProductUri(productDefinitionKey: string, locale: string, options?: AxiosRequestConfig) {
    return ProductApiFp(this.configuration)
      .getPublishedProductUri(productDefinitionKey, locale, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get the list of published versions of the products which contain the product Id, locale and version information.
   * @summary Get the latest published versions of the products.
   * @param {Array<ProductInCacheRequest>} [productInCacheRequest] List of product with the version information.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductApi
   */
  public getPublishedVersions(productInCacheRequest?: Array<ProductInCacheRequest>, options?: AxiosRequestConfig) {
    return ProductApiFp(this.configuration)
      .getPublishedVersions(productInCacheRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Verify if a product is a system family or not, which is a special type of BIM content for Revit.
   * @summary Indicate if the product is a system family.
   * @param {string} productDefinitionKey Product identification.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductApi
   */
  public isSystemFamily(productDefinitionKey: string, options?: AxiosRequestConfig) {
    return ProductApiFp(this.configuration)
      .isSystemFamily(productDefinitionKey, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * VersionApi - axios parameter creator
 * @export
 */
export const VersionApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * PIM is Product Information Management Software.<br />  PIM version is the version of some product\'s data in the PIM software.
     * @summary Get the highest (PIM) version of the product model for a given revit version.
     * @param {string} locale Parameter to specify for which region/country you want to retrieve data.              The locale not only possibly affects the language of the data you get, but also the available products,              articles, and spare parts.               See https://developer.geberit.com/locales
     * @param {string} productDefinitionKey Product identification.
     * @param {string} revitVersion The version of Revit (the year only).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPublishedVersion: async (
      locale: string,
      productDefinitionKey: string,
      revitVersion: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getPublishedVersion", "locale", locale)
      // verify required parameter 'productDefinitionKey' is not null or undefined
      assertParamExists("getPublishedVersion", "productDefinitionKey", productDefinitionKey)
      // verify required parameter 'revitVersion' is not null or undefined
      assertParamExists("getPublishedVersion", "revitVersion", revitVersion)
      const localVarPath = `/api/bimcontent/{locale}/products/{productDefinitionKey}/publishedversion`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"productDefinitionKey"}}`, encodeURIComponent(String(productDefinitionKey)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      if (revitVersion !== undefined) {
        localVarQueryParameter["revitVersion"] = revitVersion
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * VersionApi - functional programming interface
 * @export
 */
export const VersionApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = VersionApiAxiosParamCreator(configuration)
  return {
    /**
     * PIM is Product Information Management Software.<br />  PIM version is the version of some product\'s data in the PIM software.
     * @summary Get the highest (PIM) version of the product model for a given revit version.
     * @param {string} locale Parameter to specify for which region/country you want to retrieve data.              The locale not only possibly affects the language of the data you get, but also the available products,              articles, and spare parts.               See https://developer.geberit.com/locales
     * @param {string} productDefinitionKey Product identification.
     * @param {string} revitVersion The version of Revit (the year only).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPublishedVersion(
      locale: string,
      productDefinitionKey: string,
      revitVersion: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPublishedVersion(
        locale,
        productDefinitionKey,
        revitVersion,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * VersionApi - factory interface
 * @export
 */
export const VersionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = VersionApiFp(configuration)
  return {
    /**
     * PIM is Product Information Management Software.<br />  PIM version is the version of some product\'s data in the PIM software.
     * @summary Get the highest (PIM) version of the product model for a given revit version.
     * @param {string} locale Parameter to specify for which region/country you want to retrieve data.              The locale not only possibly affects the language of the data you get, but also the available products,              articles, and spare parts.               See https://developer.geberit.com/locales
     * @param {string} productDefinitionKey Product identification.
     * @param {string} revitVersion The version of Revit (the year only).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPublishedVersion(
      locale: string,
      productDefinitionKey: string,
      revitVersion: string,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .getPublishedVersion(locale, productDefinitionKey, revitVersion, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * VersionApi - object-oriented interface
 * @export
 * @class VersionApi
 * @extends {BaseAPI}
 */
export class VersionApi extends BaseAPI {
  /**
   * PIM is Product Information Management Software.<br />  PIM version is the version of some product\'s data in the PIM software.
   * @summary Get the highest (PIM) version of the product model for a given revit version.
   * @param {string} locale Parameter to specify for which region/country you want to retrieve data.              The locale not only possibly affects the language of the data you get, but also the available products,              articles, and spare parts.               See https://developer.geberit.com/locales
   * @param {string} productDefinitionKey Product identification.
   * @param {string} revitVersion The version of Revit (the year only).
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VersionApi
   */
  public getPublishedVersion(
    locale: string,
    productDefinitionKey: string,
    revitVersion: string,
    options?: AxiosRequestConfig
  ) {
    return VersionApiFp(this.configuration)
      .getPublishedVersion(locale, productDefinitionKey, revitVersion, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
