import { Box, Collapse, Flex, Text } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { Category } from "../../../../../api-clients/product-api-v1"
import { pages } from "../../../../../common/constants/constants"
import { useBrandedTranslation } from "../../../../../common/hooks/useBrandedTranslation"
import {
  getPictoImage,
  hasProductsInTree,
  hasSparePartsInTree,
  someHavePictos,
} from "../../../../../common/utils/category-utils"
import BackButton from "../../../../shared/BackButton"
import CustomImage from "../../../../shared/CustomImage"
import { CChevronRight } from "../../../../shared/Icons"
import NavigationButtonLink from "../../../../shared/NavigationButtonLink"
import NavigationMenuButton from "../../../../shared/NavigationMenuButton"
import FindSparePartsBtnLink from "../FindSparePartsBtnLink"

type Props = {
  isActive: boolean
  handleCloseMenu: () => void
  systemMenuData: Category
}

const MobileSystemMenu = ({ isActive, handleCloseMenu, systemMenuData }: Props) => {
  const { t } = useBrandedTranslation()
  const categoriesData = systemMenuData
  const [categoriesTree, setCategoriesTree] = useState<Category[]>([])

  const isLevelTwoActive = categoriesTree?.length > 1
  const isLevelThreeActive = categoriesTree?.length > 2
  const isLevelFourActive = categoriesTree?.length > 3
  const lastCategoryInTree = categoriesTree.at(-1)

  useEffect(() => {
    if (categoriesData) setCategoriesTree(new Array(categoriesData))
  }, [categoriesData, isActive])

  const handleMenuClick = (newBranch: Category) => {
    setCategoriesTree([...categoriesTree, newBranch])
  }

  const handleBackButtonClick = () => {
    setCategoriesTree(categoriesTree.slice(0, -1))
  }

  return (
    <Collapse in={isActive} animateOpacity unmountOnExit>
      <Flex flexDirection="column">
        {isLevelTwoActive && <BackButton onClick={handleBackButtonClick} text={t("mcat-back")} textAlign="start" />}

        {categoriesTree?.length === 1 && (
          <Text my="2" color="gray.700" fontSize="sm" paddingY="1.5" borderBottom="1px solid" borderColor="gray.300">
            {t("nav-assortment")}
          </Text>
        )}

        {isLevelTwoActive && (
          <Box borderBottom="1px solid" borderColor="gray.400" mb="2" mt="2">
            <NavigationButtonLink
              width="100%"
              label={lastCategoryInTree?.name}
              href={`/${pages.SYSTEMS}/${lastCategoryInTree?.id}/${categoriesTree?.length > 3 ? pages.PRODUCTS : ""}`}
              onClick={handleCloseMenu}
            />
          </Box>
        )}

        <Flex flexDirection="column">
          {lastCategoryInTree?.subCategories?.map((subCategory: Category) => {
            const categoryPicto = getPictoImage(subCategory?.images)
            const someCategoriesHavePictos = someHavePictos(lastCategoryInTree?.subCategories)
            const shouldShowPicto = someCategoriesHavePictos && categoriesTree?.length === 2
            const hasLargePadding = someCategoriesHavePictos && !categoryPicto?.thumbnail

            /* Hide Level 2 and Level 3 categories which have only spare parts */
            if (isLevelThreeActive && !hasProductsInTree(subCategory)) return null

            return subCategory.subCategories ? (
              <NavigationMenuButton
                key={subCategory.id}
                label={subCategory.name}
                onClick={() => handleMenuClick(subCategory)}
                minH={shouldShowPicto ? "14" : "11"}
                pl={hasLargePadding ? "14" : "2"}
                leftIcon={
                  shouldShowPicto &&
                  !hasLargePadding && (
                    <CustomImage
                      src={categoryPicto?.thumbnail}
                      alt="Category image as a picto"
                      w="10"
                      h="10"
                      fallback={<Box w="10" />}
                    />
                  )
                }
                rightIcon={subCategory.subCategories && <CChevronRight />}
              />
            ) : (
              <NavigationButtonLink
                key={subCategory.id}
                href={`/${pages.SYSTEMS}/${subCategory.id}/${pages.PRODUCTS}`}
                label={subCategory.name}
                onClick={handleCloseMenu}
                minH={shouldShowPicto ? "14" : "11"}
                pl={hasLargePadding ? "14" : "2"}
                leftIcon={
                  shouldShowPicto &&
                  !hasLargePadding && (
                    <CustomImage
                      src={categoryPicto?.thumbnail}
                      alt="Category image as a picto"
                      w="10"
                      h="10"
                      fallback={<Box w="10" />}
                    />
                  )
                }
                rightIcon={subCategory.subCategories && <CChevronRight />}
              />
            )
          })}
        </Flex>

        {isLevelFourActive && hasSparePartsInTree(lastCategoryInTree) && (
          <FindSparePartsBtnLink
            href={`/${pages.SYSTEMS}/${lastCategoryInTree?.id}/${pages.SPARE_PARTS}`}
            onClick={handleCloseMenu}
          />
        )}
      </Flex>
    </Collapse>
  )
}

export default MobileSystemMenu
