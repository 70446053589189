/* Contains custom default configuration values for global axios instance */

import Agent, { HttpsAgent } from "agentkeepalive"
import axios from "axios"

// HTTP agent
const keepAliveAgent = new Agent({
  maxSockets: 128, // or 128 / number of CPU cores if the pod is running on multiple cores
  maxFreeSockets: 16, // can be more if needed faster initial response time, but it cost resources to keep sockets open
  timeout: 241000, // active socket keepAlive for 4 minutes and 1 second, synced with Load Balancer timeout of 4 minutes
  freeSocketTimeout: 60000, // free socket keepAlive for 60 seconds
})

// HTTPS agent
const httpsKeepAliveAgent = new HttpsAgent({
  maxSockets: 128, // or 128 / number of CPU cores if the pod is running on multiple cores
  maxFreeSockets: 16, // can be more if needed faster initial response time, but it cost resources to keep sockets open
  timeout: 241000, // active socket keepAlive for 4 minutes and 1 second, synced with Load Balancer timeout of 4 minutes
  freeSocketTimeout: 60000, // free socket keepAlive for 60 seconds
})

// Add custom keepAlive agents to default global instance
axios.defaults.httpAgent = keepAliveAgent
axios.defaults.httpsAgent = httpsKeepAliveAgent
