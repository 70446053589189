const brandColors = {
  geberit: {
    50: "#376EB41A", // Geberit Main Blue with 10% opacity
    500: "#376EB4", // CL17 Geberit Main Blue
    700: "#0A5A96", // CL18 Dark Geberit Blue
    800: "#004673", // CL19 Darkest Geberit Blue
  },
  porsgrund: {
    50: "#26513B1A", // Nordics Hero Pine Green with 10% opacity
    500: "#26513B", // CL17 Nordics Hero Pine Green
    700: "#4B7155", // CL18 Nordics Spring Leaves
    800: "#6C906E", // CL19 Nordics Laurel Green
  },
  ido: {
    50: "#26513B1A", // Nordics Hero Pine Green with 10% opacity
    500: "#26513B", // CL17 Nordics Hero Pine Green
    700: "#4B7155", // CL18 Nordics Spring Leaves
    800: "#6C906E", // CL19 Nordics Laurel Green
  },
  ifo: {
    50: "#26513B1A", // Nordics Hero Pine Green with 10% opacity
    500: "#26513B", // CL17 Nordics Hero Pine Green
    700: "#4B7155", // CL18 Nordics Spring Leaves
    800: "#6C906E", // CL19 Nordics Laurel Green
  },
  kolo: {
    50: "#1E818A1A", // primary Kolo color with 10% opacity
    500: "#1E818A", // CL17 Kolo Teal
    700: "#0A636B", // CL18 Kolo Ocean
    800: "#0E4B53", // CL19 Kolo Rain Forest
  },
  twyford: {
    50: "#245C4E1A", // primary Twyford color with 10% opacity
    500: "#245C4E", // CL17 Twyford P626
    700: "#4E7F71", // CL18 Twyford P625
    800: "#779E91", // CL19 Twyford P624
  },
}

export default brandColors
