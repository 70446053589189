export const suggestDefaults = {
  DEFAULT_SEARCH_TERM: "defaultsearch",
  MIN_TERM_LENGTH: 2,
  SUGGEST_QUERY_TIMEOUT: 200,
  REPLACEMENT_INFO_DELIMITER: ";;",
} as const

export const suggestSections = {
  TERMS: "0terms",
  PRODUCTS: "1products",
  ARTICLES: "2articleproducts",
  SPARE_PARTS: "3spareparttables",
  CATEGORIES: "4categories",
} as const

export const searchField = {
  PLACEHOLDER: "search-field-placeholder",
  ID: "main-search-field",
  FORM_ID: `form-main-search-field`,
} as const
