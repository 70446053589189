import Input from "./input"

const Textarea = {
  baseStyle: Input.baseStyle,
  sizes: {
    lg: Input.sizes.lg.field,
    md: Input.sizes.md.field,
    sm: Input.sizes.sm.field,
    xs: Input.sizes.xs.field,
  },
  variants: {
    filled: Input.variants.filled.field,
    outline: Input.variants.outline.field,
    form: Input.variants.form.field,
  },
}

export default Textarea
