const Input = {
  baseStyle: {
    borderRadius: "none",
  },
  sizes: {
    lg: {
      field: {
        borderRadius: "none",
      },
    },
    md: {
      field: {
        borderRadius: "none",
      },
    },
    sm: {
      field: {
        borderRadius: "none",
      },
    },
    xs: {
      field: {
        borderRadius: "none",
      },
    },
  },
  variants: {
    filled: {
      field: {
        _focusVisible: {
          borderColor: "brand.500",
        },
      },
    },
    outline: {
      field: {
        _focusVisible: {
          borderColor: "brand.500",
          boxShadow: `0 0 0 1px var(--chakra-colors-brand-500)`,
        },
      },
    },
    form: {
      field: {
        cursor: "pointer",
        border: "1px solid",
        borderColor: "gray.400",
        _hover: { borderColor: "gray.800" },
        _disabled: {
          border: "0px solid",
          cursor: "default",
          color: "gray.400",
          opacity: 1,
        },
        _focusVisible: {
          borderColor: "black",
          _hover: { borderColor: "black" },
        },
        _invalid: {
          borderColor: "error",
          _hover: { borderColor: "error" },
        },
      },
    },
  },
}

export default Input
