import { keyframes } from "@chakra-ui/react"

export const fadeIn = keyframes({
  "0%": {
    opacity: 0,
  },
  "50%": {
    opacity: 0.5,
  },
  "100%": {
    opacity: 1,
  },
})

export const leanFadeInLeft = keyframes({
  "0%": {
    transform: "translateX(-2%)",
    opacity: 0,
  },
  "50%": {
    transform: "translateX(-1%)",
    opacity: 0.5,
  },
  "100%": {
    transform: "translateX(0%)",
    opacity: 1,
  },
})

export const leanInTop = keyframes({
  "0%": {
    transform: "translateY(-10%)",
  },
  "50%": {
    transform: "translateY(-5%)",
  },
  "100%": {
    transform: "translateY(0%)",
  },
})
