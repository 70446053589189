/* tslint:disable */
/* eslint-disable */
/**
 * Product Suggest
 * Provides search-as-you-type functionality for search fields. Data returned by this API includes top product, article, spare parts tables and categories results as well as commonly used searches.<br><br><strong>Links</strong><ul><li><a href=\"https://geberit-projects.atlassian.net/wiki/spaces/SMA/pages/3690135726\" target=\"_new\">Introduction to Product Search Services</a></li><li><a href=\"https://geberit-projects.atlassian.net/wiki/spaces/SMA/pages/3650617345\" target=\"_new\">Additional Information about Suggest Front-end Integration</a></li><li><a href=\"https://geberit-projects.atlassian.net/wiki/spaces/SMA/pages/3691642943\" target=\"_new\">Suggest Example Requests (Postman)</a></li></ul>
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "./configuration"
// Some imports not used depending on template conditions
// @ts-ignore
import type { AxiosInstance, AxiosRequestConfig } from "axios"
import globalAxios from "axios"

export const BASE_PATH = "http://api.stg.mbp.geberit.com/product-search-fh/suggest/v1".replace(/\/+$/, "")

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ",",
  ssv: " ",
  tsv: "\t",
  pipes: "|",
}

/**
 *
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
  url: string
  options: AxiosRequestConfig
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration | undefined

  constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected axios: AxiosInstance = globalAxios
  ) {
    if (configuration) {
      this.configuration = configuration
      this.basePath = configuration.basePath || this.basePath
    }
  }
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  constructor(public field: string, msg?: string) {
    super(msg)
    this.name = "RequiredError"
  }
}
