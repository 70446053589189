import { Button, ButtonProps, Text } from "@chakra-ui/react"
import { forwardRef } from "react"
import CustomLink from "./CustomLink"
import { CChevronLeft } from "./Icons"

type Props = ButtonProps & {
  text: string
  href?: string
  noOfLines?: number
}

/* This is a button with a back arrow on the left. If passed href then it renders a link, otherwise it renders a regular button */
const BackButton = ({ text, href, noOfLines, ...rest }: Props) => {
  /* Extract the button component from JSX so it can be conditionally wrapped in a NextLink.
    Wrap it in forwardRef so the href attribute is passed down from the NextLink */
  const BackArrowButton = forwardRef((props, href) => (
    <Button
      as={href ? "a" : "button"}
      variant="secondary"
      w="fit-content"
      color="black"
      pl="2"
      textAlign="left"
      leftIcon={<CChevronLeft />}
      aria-label={`Go to ${text}`}
      alignItems="center"
      {...props}
    >
      <Text as="span" noOfLines={noOfLines}>
        {text}
      </Text>
    </Button>
  ))

  BackArrowButton.displayName = "BackArrowButton"

  return href ? (
    <CustomLink href={href}>
      <BackArrowButton {...rest} />
    </CustomLink>
  ) : (
    <BackArrowButton {...rest} />
  )
}

export default BackButton
