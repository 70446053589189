import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import { useMemo } from "react"
import { brands } from "../constants/constants"

export const useBrandedTranslation = () => {
  const { query } = useRouter()
  const brand = query?.brand as string
  // We have to map the brand "geberit" to "main" for the label api
  // Hopefully this is being renamed soon so we can drop this mapping
  const mappedBrand = useMemo(() => (brand === brands.main.value ? "main" : brand), [brand])
  // load the global i18n object
  const { i18n } = useTranslation()

  // create a memoized version of T for use in components with lots of re-renders (e.g. tables)
  const memoizedT = useMemo(() => i18n.getFixedT(null, mappedBrand), [i18n, mappedBrand])

  // return a t() that is bound to the namespace (brand)
  const t = i18n.getFixedT(null, mappedBrand)

  return { t, i18n, memoizedT }
}
