import xss from "xss"

type SanitizableInput = string | SanitizableObject | SanitizableInput[]

interface SanitizableObject {
  [key: string]: SanitizableInput
}

const sanitizeAndXss = (text: string) => {
  const removeAllHtmlTags = text.replace(/<\/?[^>]+(>|$)/g, "")
  const removeAllXSSRelatedVurnabilities = xss(removeAllHtmlTags)
  const stringifyResult = String(removeAllXSSRelatedVurnabilities)
  return stringifyResult
}

function antiXss(input: SanitizableInput): SanitizableInput {
  if (typeof input === "string") {
    return sanitizeAndXss(input)
  }

  if (typeof input === "object" && input !== null) {
    if (Array.isArray(input)) {
      return input.map(antiXss)
    }

    const sanitizedObj: SanitizableObject = {}
    for (const key in input) {
      if (input.hasOwnProperty(key)) {
        sanitizedObj[key] = antiXss(input[key])
      }
    }
    return sanitizedObj
  }

  // If input is not a string or object, return it as is (optional)
  return input
}

export default antiXss
