const shadows = {
  sm: "0 1px 2px 0 rgba(0,0,0,0.36)",
  md: "0 2px 6px 0 rgba(0,0,0,0.26)",
  lg: "0 3px 12px 0 rgba(0,0,0,0.20)",
  xl: "0 4px 16px 0 rgba(0,0,0,0.26)",
  xlNoTop: "#fff 0 -3px, 0 4px 16px 0 rgba(0,0,0,0.26)",
  lgInsetTopOnly: "inset 0 9px 12px -7px rgba(0,0,0,0.20)",
  tableBorderShadow: "inset -2px 0px 0px -1px rgba(217,217,217,1)",
  scrollingTableLeft: "inset 11px 0 6px -11px rgba(153,153,153,1)",
  scrollingTableRight: "inset -11px 0 6px -11px rgba(153,153,153,1)",
}

export default shadows
