import NextLink, { LinkProps } from "next/link"

type Props = LinkProps & {
  children: React.ReactNode
}

/* Wrapper for next/link component in which prefetching is disabled by default */
const CustomLink = (props: Props) => {
  return (
    <NextLink prefetch={props.prefetch ?? false} legacyBehavior passHref {...props}>
      {props.children}
    </NextLink>
  )
}

export default CustomLink
