import { SpanStatusCode, trace } from "@opentelemetry/api"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function traceException(properties: { [key: string]: any }) {
  const tracer = trace.getTracer("default")
  const span = tracer.startSpan(properties.type || EXCEPTION_TYPES.OC_ERROR)
  Object.keys(properties).forEach((key) => {
    span.setAttribute(key, properties[key])
  })

  span.recordException({
    code: SpanStatusCode.ERROR,
    name: properties.type || EXCEPTION_TYPES.OC_ERROR,
    message: properties.message || "An error occurred",
    stack: properties.stacktrace || "",
  })
  span.setStatus({
    code: SpanStatusCode.ERROR,
    message: properties.message || "An error occurred",
  })
  span.setAttribute("error", true)
  span.end()
}

export const EXCEPTION_TYPES = {
  OC_ERROR: "OC Error",
  OC_ERROR_WARNING: "OC Error Warning",
  OC_ERROR_EXCEPTION: "OC Error Exception",
  OC_ERROR_INFO: "OC Error Info",
}
