import { Box, ButtonGroup, Container, Flex } from "@chakra-ui/react"
import dynamic from "next/dynamic"
import UserOnboardingWrapper from "../../UserOnboarding/UserOnboardingWrapper"
import CompanyWebsiteLink from "./CompanyWebsiteLink"
import LanguagesMenu from "./LanguagesMenu"
import MyListsButton from "./MyListsButton"

/* LoginButton depends on Gigya data, that's why it needs to be client-side rendered only */
const LoginButton = dynamic(() => import("./LoginButton"), { ssr: false })

export const HeaderTop = () => {
  return (
    <Box bg="gray.50" width="100%">
      <Container variant="main">
        <Flex align="center" justify="space-between" h="10">
          <LanguagesMenu />
          <Flex>
            <ButtonGroup spacing="2" mr="6">
              <UserOnboardingWrapper />

              <LoginButton />

              {/* Wrap MyListButton in a Box to work around a Popover.js warning.
              Add id for onboarding hightlight. */}
              <Box id="my-lists-menu-button">
                <MyListsButton />
              </Box>
            </ButtonGroup>

            <CompanyWebsiteLink />
          </Flex>
        </Flex>
      </Container>
    </Box>
  )
}
