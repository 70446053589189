import { Image, ImageProps } from "@chakra-ui/react"

type Props = ImageProps

const CustomImage = ({ src, alt, ...rest }: Props) => {
  const fallbackSrc = "/images/placeholder-image.svg"

  return (
    <Image
      src={src || fallbackSrc}
      alt={alt}
      fallbackSrc={fallbackSrc}
      fallbackStrategy="onError"
      onError={(event: React.SyntheticEvent<HTMLImageElement, ErrorEvent>) => {
        const target = event.target as HTMLImageElement
        target.src = fallbackSrc
      }}
      {...rest}
    />
  )
}

export default CustomImage
