/* tslint:disable */
/* eslint-disable */
/**
 * Product availability
 * Use the Availability API to check the availability of products, articles, categories, and spare parts tables. You can also specify an [assortment](/glossary), brand, and availability group to customize your availability check.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export * from "./api"
export * from "./configuration"
