import { AncestorCategory, Category, CategoryModel, ImageModel } from "../../api-clients/product-api-v1"
import { brands, categoryLevels, pages, seriesLevels } from "../constants/constants"
import { searchDefaults } from "../constants/search-constants"
import { BreadcrumbItemType } from "../types/global"

type StrippedCategory = {
  id: string | undefined
  name: string | undefined
  hasProducts: boolean | undefined
  hasSpareParts: boolean | undefined
  subCategories?: StrippedCategory[] | undefined
  images?: Array<{ orientation: string | undefined; thumbnail: string | undefined }> | undefined
}

const CATEGORY_ICON_ORIENTATION = "CategoryIcon"

/* Checks if the current category or a category below it in the tree contains any products */
export const hasProductsInTree = (category: Category) => {
  if (category?.hasProducts) return true
  return !!category?.subCategories?.some((cat: Category) => hasProductsInTree(cat))
}

/* Checks if the current category or a category below it in the tree contains any spare parts */
export const hasSparePartsInTree = (category: Category) => {
  if (category?.hasSpareParts) return true
  return !!category?.subCategories?.some((cat: Category) => hasSparePartsInTree(cat))
}

export const formatCategoryImages = (images: ImageModel[]) => {
  return images
    .filter((image) => image.orientation !== "PreviewImage")
    .sort((a, b) => a.sequence - b.sequence)
    .slice(0, 8)
}

export const findCategoryPath = (rootCategory: Category, categoryId: string) => {
  const buildPath = (
    subCategories: Array<Category>,
    id: string,
    currentPath: Array<Partial<Category>>
  ): Array<Partial<Category>> | undefined => {
    let path: Array<Partial<Category>>
    subCategories.some((sc) => {
      if (sc.id === id) {
        path = [...currentPath, { name: sc.name, id: sc.id }]
        return true
      } else if (sc.subCategories) {
        const foundPath = buildPath(sc.subCategories, id, [...currentPath, { name: sc.name, id: sc.id }])
        if (foundPath) {
          path = foundPath
          return true
        }
      }
    })

    return path
  }

  return buildPath(rootCategory.subCategories, categoryId, [])
}

export const getCategoryDataById = (categories: Category[], categoryId: string) => {
  const findCategory = (currentCategories: Category[], categoryId: string) => {
    let result: Category

    currentCategories.some((category: Category) => {
      if (category.id === categoryId) {
        result = category
        return true
      } else if (category.subCategories) {
        const foundResult = findCategory(category.subCategories, categoryId)
        if (foundResult) {
          result = foundResult
          return true
        }
      }
    })

    return result
  }

  return findCategory(categories, categoryId)
}

export const getVirtualSparePartsTile = (id: string, name: string): Category => {
  return {
    id,
    name,
    hasProducts: false,
    hasSpareParts: true,
  }
}

export const getSystemsBreadcrumbs = (
  lastCrumbName: string,
  productType: "products" | "spare-parts",
  ancestors: AncestorCategory[]
): BreadcrumbItemType[] => {
  const breadcrumbs: BreadcrumbItemType[] = []

  /* Add all ancestors breadcumbs, first filter out the system category */
  if (!!ancestors?.length) {
    ancestors
      .filter((anc) => anc.id !== searchDefaults.SYSTEM_CTP)
      .forEach((anc) => {
        /* If it is not MAC or CH1 levels category, then the breadcrumb should point to the lister pages
        either for "products" or for "spare-parts" */
        const isLowerLevel = !anc.id.includes(categoryLevels.MASTER) && !anc.id.includes(categoryLevels.ONE)
        const crumbHref = isLowerLevel ? `/${pages.SYSTEMS}/${anc.id}/${productType}` : `/${pages.SYSTEMS}/${anc.id}`
        breadcrumbs.push({ name: anc.name, href: crumbHref })
      })
  }

  /* Add the last inactive breadcrumb for the current category */
  breadcrumbs.push({ name: lastCrumbName })

  return breadcrumbs
}

export const getSeriesBreadcrumbs = (
  lastCrumbName: string,
  brand: string,
  seriesMacTitle: string,
  ancestors: AncestorCategory[]
): BreadcrumbItemType[] => {
  const breadcrumbs: BreadcrumbItemType[] = []

  /* First add the main series category breadcrumb */
  breadcrumbs.push({ name: seriesMacTitle, href: `/${pages.SERIES}` })

  /* Add all ancestors breadcumbs, first filter out the system category */
  if (!!ancestors?.length) {
    ancestors
      .filter((anc) => anc.id !== searchDefaults.SERIE_CTP && anc.id !== brands[brand].seriesRootCategory)
      .forEach((anc) => {
        const crumbHref = `/${pages.SERIES}/${anc.id}`
        breadcrumbs.push({ name: anc.name, href: crumbHref })
      })
  }

  /* Add the last inactive breadcrumb for the current category */
  breadcrumbs.push({ name: lastCrumbName })

  return breadcrumbs
}

export const isCategorySystem = (categoryId: string) => {
  const categoryCode = getCategoryCode(categoryId ?? "")
  const systemCategoryCodes = Object.values(categoryLevels) as string[]

  return systemCategoryCodes.includes(categoryCode)
}

export const isCategorySerie = (serieId: string) => {
  const categoryCode = getCategoryCode(serieId ?? "")
  const serieCategoryCodes = Object.values(seriesLevels) as string[]

  return serieCategoryCodes.includes(categoryCode)
}

export const isMainSeriesCategory = (categoryId: string) => getCategoryCode(categoryId ?? "") === seriesLevels.ONE

export const getCategoryCode = (categoryId: string) => categoryId.split("_")[0]?.toUpperCase()

/* Extract category id from a given string, for example url */
export const extractCategoryId = (str: string) => {
  /* Match two capital letters followed by a number between 1 and 3
    followed by an underscore, followed by 4 to 8 numbers,
    for example SC1_452444 or CH2_100001 */
  const regex = new RegExp(/([A-Z]{2}[1-3])_\d{4,8}/)
  const match = str.match(regex)

  return match ? match[0] : null
}

/* Check the referer url and get the category id from it if it exists */
export const getRefererCategoryId = (productCategories: CategoryModel[], refererUrl: string) => {
  /* Guard clause if no valid referer is passed */
  if (!refererUrl || !productCategories) return null

  /* Check the referer url to determine if the product is visited from a category */
  const refererCategoryId = extractCategoryId(refererUrl)

  /* Get the category id code, either CH or SC, to determine the type of category. */
  const refererCategoryTreeCode = refererCategoryId ? refererCategoryId.slice(0, 2) : null

  /* If the referer matches the exact category id, get this category.
    Otherwise get the first category which matches the type, either system or series. */
  const crumbsCategory =
    productCategories.find((cat) => refererUrl.includes(cat.key)) ??
    productCategories.find((cat) => cat.key.includes(refererCategoryTreeCode))

  /* If a suitable category is found, return its id, otherwise return non-existant value which is null. */
  return crumbsCategory?.key ?? null
}

export const getPictoImage = (images: ImageModel[]) =>
  images?.find((catImg) => catImg?.orientation === CATEGORY_ICON_ORIENTATION) ?? null

export const someHavePictos = (categories: Category[]) =>
  categories.some((category) => !!getPictoImage(category?.images))

const getStrippedCategory = (category: Category) => {
  const { id, name, subCategories, images, hasProducts, hasSpareParts } = category
  const strippedCategory: StrippedCategory = {
    id,
    name,
    hasProducts,
    hasSpareParts,
    subCategories: subCategories?.map((subCategory) => getStrippedCategory(subCategory)),
  }
  const strippedImages = images
    ?.filter(({ orientation }) => orientation === CATEGORY_ICON_ORIENTATION)
    .map(({ orientation, thumbnail }) => ({
      orientation,
      thumbnail,
    }))
  if (strippedImages && strippedImages?.length > 0) {
    strippedCategory.images = strippedImages
  }
  return strippedCategory
}

export const getStrippedSystemMenuData = (systemMenuData: Category) => {
  if (!systemMenuData) return null
  return getStrippedCategory(systemMenuData)
}
