import { Box, Container, Flex, Link, Stack, Text } from "@chakra-ui/react"
import { useBrandedTranslation } from "../../../common/hooks/useBrandedTranslation"
import { showUcSecondLayer } from "../../../common/utils/cookie-solution-utils"
import RichTextLabel from "../../shared/RichTextLabel"

export const Footer = () => {
  const { t } = useBrandedTranslation()

  return (
    <Box as="footer">
      <Box borderTop="1px" borderColor="gray.300" mt="16">
        <Container variant="main" pb={["16", "8", "24"]} pt="16">
          <Box mb={{ base: "13", md: "9" }}>
            <RichTextLabel styleProps={{ fontSize: "sm" }} i18nKey={t("your-current-region_rtf")} />
          </Box>

          <Flex
            fontSize="sm"
            direction={{ base: "column-reverse", sm: "column-reverse", md: "row" }}
            justify={{ md: "end" }}
            gap={{ base: "6", sm: "4" }}
          >
            <Text color="gray.800">
              &copy; {new Date().getFullYear()} {t("footer-copyrightName")}
            </Text>

            <Stack fontWeight="medium" direction={{ base: "column", sm: "row" }} spacing={{ base: "6", sm: "4" }}>
              <Link maxWidth="fit-content" href={t("footer-links-link[0]-url")}>
                {t("footer-links-link[0]-name")}
              </Link>
              <Link maxWidth="fit-content" href={t("footer-links-link[1]-url")}>
                {t("footer-links-link[1]-name")}
              </Link>
              <Link maxWidth="fit-content" href={t("footer-links-link[2]-url")}>
                {t("footer-links-link[2]-name")}
              </Link>
              <Link
                maxWidth="fit-content"
                fontWeight="medium"
                as="button"
                onClick={showUcSecondLayer}
                alignSelf="flex-start"
              >
                {t("footer-links-cookie")}
              </Link>
              <Link maxWidth="fit-content" href={t("footer-links-link[3]-url")}>
                {t("footer-links-link[3]-name")}
              </Link>
            </Stack>
          </Flex>
        </Container>
      </Box>
    </Box>
  )
}
