const zIndices = {
  hide: -1,
  auto: "auto",
  base: 0,
  tileContent: 1,
  magnifyImage: 5,
  docked: 10,
  breadcrumbs: 100,
  dropdown: 1000,
  anchorNavigation: 1050,
  sticky: 1100,
  banner: 1200,
  overlay: 1300,
  modal: 1400,
  popover: 1500,
  skipLink: 1600,
  toast: 1700,
  tooltip: 1800,
}

export default zIndices
