/* tslint:disable */
/* eslint-disable */
/**
 * Product API
 * The Product API provides various endpoints to obtain product, article, spare part, category, tender text, and shopping list data.  Most endpoints return data according to your enabled and defined availability user group and API scope header. In case you don\'t receive the data you expect, [contact us](mailto:developer@geberit.com) so we can determine and sort out your current permissions scope.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { AxiosInstance, AxiosPromise, AxiosRequestConfig } from "axios"
import globalAxios from "axios"
import type { Configuration } from "./configuration"
// Some imports not used depending on template conditions
// @ts-ignore
import type { RequestArgs } from "./base"
import {
  assertParamExists,
  createRequestFunction,
  DUMMY_BASE_URL,
  serializeDataIfNeeded,
  setSearchParams,
  toPathString,
} from "./common"
// @ts-ignore
import { BaseAPI, BASE_PATH } from "./base"

/**
 * Contains a list of additional information linked to a product.
 * @export
 * @interface AdditionalInformationListModel
 */
export interface AdditionalInformationListModel {
  /**
   * An `array` of `AdditionalInformationModel` objects. Contains text-based information about a product.
   * @type {Array<AdditionalInformationModel>}
   * @memberof AdditionalInformationListModel
   */
  list?: Array<AdditionalInformationModel>
}
/**
 * Contains text-based information.
 * @export
 * @interface AdditionalInformationModel
 */
export interface AdditionalInformationModel {
  /**
   * An `array` of text items containing additional information about a product.
   * @type {Array<string>}
   * @memberof AdditionalInformationModel
   */
  items?: Array<string>
  /**
   * Deprecated.
   * @type {string}
   * @memberof AdditionalInformationModel
   * @deprecated
   */
  replaceStatus?: AdditionalInformationModelReplaceStatusEnum
}

export const AdditionalInformationModelReplaceStatusEnum = {
  By: "REPLACED_BY",
  For: "REPLACED_FOR",
} as const

export type AdditionalInformationModelReplaceStatusEnum =
  typeof AdditionalInformationModelReplaceStatusEnum[keyof typeof AdditionalInformationModelReplaceStatusEnum]

/**
 * Information about an ancestor of a category. For a full representation of the category  i.e. the corresponding `CategoryModel`, it can be requested by one of it\'s id\'s from the Category endpoint
 * @export
 * @interface AncestorCategory
 */
export interface AncestorCategory {
  /**
   * The internal id. Environment dependent
   * @type {string}
   * @memberof AncestorCategory
   */
  uid?: string
  /**
   * The unique identifier of the category.
   * @type {string}
   * @memberof AncestorCategory
   */
  id?: string
  /**
   * the name of the category in the requested assortment
   * @type {string}
   * @memberof AncestorCategory
   */
  name?: string
}
/**
 * Contains information about an app, such as its name and description texts.
 * @export
 * @interface AppModel
 */
export interface AppModel {
  /**
   * The mobile app\'s name.
   * @type {string}
   * @memberof AppModel
   */
  name?: string
  /**
   * The marketing copy describing the mobile app.
   * @type {string}
   * @memberof AppModel
   */
  description?: string
  /**
   * An `array` of `AppStore` models. Contains information about linked app stores.
   * @type {Array<AppStore>}
   * @memberof AppModel
   */
  stores?: Array<AppStore>
  /**
   * An `array` of `ProductImageModel` models. Contains images linked to the app.
   * @type {Array<ProductImageModel>}
   * @memberof AppModel
   */
  images?: Array<ProductImageModel>
}
/**
 * Contains information about an app in the App Store, such as the name of the store and the app URL in the store.
 * @export
 * @interface AppStore
 */
export interface AppStore {
  /**
   * The app\'s URL in an app store.
   * @type {string}
   * @memberof AppStore
   */
  url?: string
  /**
   * The name of the app store. Valid values are `googlePlay` and `itunes`.
   * @type {string}
   * @memberof AppStore
   */
  name?: AppStoreNameEnum
}

export const AppStoreNameEnum = {
  Itunes: "itunes",
  GooglePlay: "googlePlay",
} as const

export type AppStoreNameEnum = typeof AppStoreNameEnum[keyof typeof AppStoreNameEnum]

/**
 * Contains a list of linked mobile apps.
 * @export
 * @interface AppsListModel
 */
export interface AppsListModel {
  /**
   * An `array` of `AppModel` objects. Contains information about an app.
   * @type {Array<AppModel>}
   * @memberof AppsListModel
   */
  list?: Array<AppModel>
}
/**
 * Contains lists of articles and article attributes.
 * @export
 * @interface ArticleListModel
 */
export interface ArticleListModel {
  /**
   * An `array` of `ArticleModel` objects. Contains information about a product\'s articles.
   * @type {Array<ArticleModel>}
   * @memberof ArticleListModel
   */
  articlesList?: Array<ArticleModel>
  /**
   * An `array` of `AttributeModel` objects. Contains information about the characteristics and attributes of a product\'s articles.
   * @type {Array<AttributeModel>}
   * @memberof ArticleListModel
   */
  attributesList?: Array<AttributeModel>
  /**
   * The number of articles in `articlesList` for your API request.
   * @type {number}
   * @memberof ArticleListModel
   */
  articlesListSize?: number
}
/**
 * Contains information about an article and related objects such as documents, images, attributes, and more.
 * @export
 * @interface ArticleModel
 */
export interface ArticleModel {
  /**
   * The article number (also called SKU).
   * @type {string}
   * @memberof ArticleModel
   */
  key?: string
  /**
   * A number used for sorting the articles in the list.
   * @type {string}
   * @memberof ArticleModel
   */
  articleId?: string
  /**
   * Geberit\'s internal identifier of this article.
   * @type {string}
   * @memberof ArticleModel
   */
  idInternal?: string
  /**
   * The article\'s [European Article Number](https://de.wikipedia.org/wiki/European_Article_Number) (EAN) code.
   * @type {string}
   * @memberof ArticleModel
   */
  eanCode?: string
  /**
   * The article\'s description.
   * @type {string}
   * @memberof ArticleModel
   */
  description?: string
  /**
   *
   * @type {ProductGalleryModel}
   * @memberof ArticleModel
   */
  gallery?: ProductGalleryModel
  /**
   *
   * @type {SimpleAssetListModel}
   * @memberof ArticleModel
   */
  cadDrawings?: SimpleAssetListModel
  /**
   * An `array` of `AttributeValueModel` objects. Contains various attributes and technical data of the article.
   * @type {Array<AttributeValueModel>}
   * @memberof ArticleModel
   */
  attributeValues?: Array<AttributeValueModel>
  /**
   * An `array` of `EtimValueModel` objects. Contains [ETIM](https://en.wikipedia.org/wiki/ETIM_(standard)) values linked to the article.
   * @type {Array<EtimValueModel>}
   * @memberof ArticleModel
   */
  etimValues?: Array<EtimValueModel>
  /**
   *
   * @type {ArticleStateModel}
   * @memberof ArticleModel
   */
  state?: ArticleStateModel
  /**
   * The tender text ID of the article\'s associated product. Only available for locale `de-DE`.
   * @type {string}
   * @memberof ArticleModel
   */
  tenderTextProductId?: string
  /**
   *
   * @type {ProductTileListModel}
   * @memberof ArticleModel
   */
  accessories?: ProductTileListModel
}
/**
 * Contains information about the availability and state of a product or article. This can include its availability and BIM capability, whether it is available in the webshop, build to order, and whether it replaces or is replaced by another article.
 * @export
 * @interface ArticleStateModel
 */
export interface ArticleStateModel {
  /**
   * The date from which a product or article is available. This property is present only if the `indicator` of the same object is `NEW`.
   * @type {string}
   * @memberof ArticleStateModel
   */
  from?: string
  /**
   * The current status of the product or article, if one is assigned. It can be either `NEW` or `PHASEOUT`.
   * @type {string}
   * @memberof ArticleStateModel
   */
  indicator?: ArticleStateModelIndicatorEnum
  /**
   * Indicates whether the product or article is relevant for BIM use.
   * @type {boolean}
   * @memberof ArticleStateModel
   */
  bimRelevant?: boolean
  /**
   * Indicates whether the product or article can already be used for BIM.
   * @type {boolean}
   * @memberof ArticleStateModel
   */
  bimReady?: boolean
  /**
   * The number of the article that this article replaces.
   * @type {string}
   * @memberof ArticleStateModel
   */
  replacementFor?: string
  /**
   * The number of the article that replaces this article.
   * @type {string}
   * @memberof ArticleStateModel
   */
  replacedBy?: string
  /**
   * Indicates whether the product or article can be purchased in the Geberit webshop.
   * @type {boolean}
   * @memberof ArticleStateModel
   */
  buyable?: boolean
  /**
   * Indicates whether the article is manufactured only after receipt of a confirmed order.
   * @type {boolean}
   * @memberof ArticleStateModel
   */
  orderRelated?: boolean
  /**
   * The date an article phases out. This property is only present if the `indicator` of the same object is `PHASEOUT`.
   * @type {string}
   * @memberof ArticleStateModel
   */
  phaseOutInMarketAsOf?: string
  /**
   * The date an article launches but is not yet available for sale.
   * @type {string}
   * @memberof ArticleStateModel
   */
  passiveInMarketAsOf?: string
  /**
   * The date on which an article launches.
   * @type {string}
   * @memberof ArticleStateModel
   */
  marketLaunchDate?: string
  /**
   * The ID of the assortment status. Possible values and their meaning:  - `00`: new but not yet actively on sale  - `01`: in assortment  - `05`: phasing out  - `07`: no longer available
   * @type {string}
   * @memberof ArticleStateModel
   */
  assortmentStatus?: ArticleStateModelAssortmentStatusEnum
}

export const ArticleStateModelIndicatorEnum = {
  New: "NEW",
  Phaseout: "PHASEOUT",
} as const

export type ArticleStateModelIndicatorEnum =
  typeof ArticleStateModelIndicatorEnum[keyof typeof ArticleStateModelIndicatorEnum]
export const ArticleStateModelAssortmentStatusEnum = {
  _00: "00",
  _01: "01",
  _05: "05",
  _07: "07",
} as const

export type ArticleStateModelAssortmentStatusEnum =
  typeof ArticleStateModelAssortmentStatusEnum[keyof typeof ArticleStateModelAssortmentStatusEnum]

/**
 * Contains attributes (without assigned values) that are linked to an object, such as an article or a spare part.
 * @export
 * @interface AttributeModel
 */
export interface AttributeModel {
  /**
   * The identifier of the attribute.
   * @type {string}
   * @memberof AttributeModel
   */
  key?: string
  /**
   * The name of the attribute.
   * @type {string}
   * @memberof AttributeModel
   */
  name?: string
  /**
   * An `array` of attribute classification information.
   * @type {Array<string>}
   * @memberof AttributeModel
   */
  groups?: Array<string>
}
/**
 * Contains attributes and their assigned values that are linked to an object, such as an article or a spare part.
 * @export
 * @interface AttributeValueModel
 */
export interface AttributeValueModel {
  /**
   * The identifier of the attribute.
   * @type {string}
   * @memberof AttributeValueModel
   */
  key?: string
  /**
   * The display text of the attribute, which can also contain the assigned unit of measurement.
   * @type {string}
   * @memberof AttributeValueModel
   */
  value?: string
  /**
   * The raw value of the attribute (without units of measurement).
   * @type {string}
   * @memberof AttributeValueModel
   */
  rawValue?: string
  /**
   * An `array` of raw values of the attribute (without units of measurement) if there is more than one value.
   * @type {Array<string>}
   * @memberof AttributeValueModel
   */
  rawValues?: Array<string>
  /**
   * An `array` of `UnitModel` objects. Contains information about attribute units of measurement.
   * @type {Array<UnitModel>}
   * @memberof AttributeValueModel
   */
  unit?: Array<UnitModel>
}
/**
 * Contains a list of linked BIM assets.
 * @export
 * @interface BimAssetsModel
 */
export interface BimAssetsModel {
  /**
   * An `array` of `ProductImageModel` objects. Contains information about a BIM asset.
   * @type {Array<ProductImageModel>}
   * @memberof BimAssetsModel
   */
  list?: Array<ProductImageModel>
}
/**
 *
 * @export
 * @interface Category
 */
export interface Category {
  /**
   * The unique identifier of the category.
   * @type {string}
   * @memberof Category
   */
  id?: string
  /**
   * The unique identifier of the category used for internal service-to-service communication.
   * @type {string}
   * @memberof Category
   */
  uuid?: string
  /**
   * The display name of the category.
   * @type {string}
   * @memberof Category
   */
  name?: string
  /**
   * The marketing slogan of the category.
   * @type {string}
   * @memberof Category
   */
  slogan?: string
  /**
   * The description of the category.
   * @type {string}
   * @memberof Category
   */
  description?: string
  /**
   * The type of the category. Can either be `system` or `series`.
   * @type {string}
   * @memberof Category
   */
  categoryType?: CategoryCategoryTypeEnum
  /**
   * An `array` of `ImageModel` objects. Contains images linked to the category.
   * @type {Array<ImageModel>}
   * @memberof Category
   */
  images?: Array<ImageModel>
  /**
   * An `array` of `CategoryModel` objects. Contains information about the category\'s children. Subcategories can in turn contain their own subcategories. This nesting then forms a (category) tree.
   * @type {Array<CategoryModel>}
   * @memberof Category
   */
  subCategories?: Array<CategoryModel>
  /**
   * An `array` of `SimpleAssetModel` objects. Contains information about downloadable overview documents linked to the category.
   * @type {Array<SimpleAssetModel>}
   * @memberof Category
   */
  overviews?: Array<SimpleAssetModel>
  /**
   * Indicates whether the category has linked products.
   * @type {boolean}
   * @memberof Category
   */
  hasProducts?: boolean
  /**
   * Indicates whether the category has linked spare parts.
   * @type {boolean}
   * @memberof Category
   */
  hasSpareParts?: boolean
  /**
   * The proposed order of the category in relation to the other categories.
   * @type {string}
   * @memberof Category
   */
  orderHint?: string
  /**
   * An array of the ancestors of the category. Ordering of ancestors in the array denotes the lineage. Direct parent is last, oldest ancestor is first (index 0).
   * @type {Array<AncestorCategory>}
   * @memberof Category
   */
  ancestors?: Array<AncestorCategory>
}

export const CategoryCategoryTypeEnum = {
  System: "system",
  Series: "series",
} as const

export type CategoryCategoryTypeEnum = typeof CategoryCategoryTypeEnum[keyof typeof CategoryCategoryTypeEnum]

/**
 * Legacy Model. Contains a category tree that starts with the request\'s specified category ID and ends with the last descendants of this category.
 * @export
 * @interface CategoryInfoModel
 */
export interface CategoryInfoModel {
  /**
   * The name of the root category of this category tree, i.e. the category you specify in your request.
   * @type {string}
   * @memberof CategoryInfoModel
   */
  title?: string
  /**
   * An `array` of `ChapterModel` objects. Contains the category tree starting with the given category\'s children and ending with its last descendants.
   * @type {Array<ChapterModel>}
   * @memberof CategoryInfoModel
   */
  chapters?: Array<ChapterModel>
}
/**
 * Contains basic information about a category, such as its unique identifier and name.
 * @export
 * @interface CategoryModel
 */
export interface CategoryModel {
  /**
   * The unique identifier of the category.
   * @type {string}
   * @memberof CategoryModel
   */
  key?: string
  /**
   * The category\'s display name.
   * @type {string}
   * @memberof CategoryModel
   */
  name?: string
}
/**
 * Legacy Model. Contains a category tree starting from the given category\'s children and ending with its last descendants.
 * @export
 * @interface ChapterModel
 */
export interface ChapterModel {
  /**
   * The name of the category.
   * @type {string}
   * @memberof ChapterModel
   */
  name?: string
  /**
   * The link to the category without domain name.
   * @type {string}
   * @memberof ChapterModel
   */
  link?: string
  /**
   * An `array` of `ChapterModel` objects. Contains subcategories that are part of the requested category tree.
   * @type {Array<ChapterModel>}
   * @memberof ChapterModel
   */
  links?: Array<ChapterModel>
}
/**
 * Contains product data, such as name and variants.
 * @export
 * @interface Current
 */
export interface Current {
  /**
   * The product\'s name.
   * @type {string}
   * @memberof Current
   */
  name?: string
  /**
   * An `array` of `Variant` objects. Contains variants (articles) linked to the product.
   * @type {Array<Variant>}
   * @memberof Current
   */
  variants?: Array<Variant>
  /**
   * An `array` of `AttributeModel` objects. Contains attributes linked to the product.
   * @type {Array<AttributeModel>}
   * @memberof Current
   */
  attributeDefinitionList?: Array<AttributeModel>
}
/**
 * Contains a product\'s delivery information.
 * @export
 * @interface DeliveryInfoListModel
 */
export interface DeliveryInfoListModel {
  /**
   * An `array` of `LabelWithSequenceModel` objects. Contains information about what is included in the product\'s delivery scope.
   * @type {Array<LabelWithSequenceModel>}
   * @memberof DeliveryInfoListModel
   */
  included?: Array<LabelWithSequenceModel>
  /**
   * An `array` of `LabelWithSequenceModel` objects. Contains information about what is *not* included with a product and must be ordered separately.
   * @type {Array<LabelWithSequenceModel>}
   * @memberof DeliveryInfoListModel
   */
  notIncluded?: Array<LabelWithSequenceModel>
  /**
   * Indicates whether a product has delivery information assigned to it.
   * @type {boolean}
   * @memberof DeliveryInfoListModel
   */
  hasScopeOfDelivery?: boolean
}
/**
 * Contains a list of documents linked to the products.
 * @export
 * @interface DocumentListModel
 */
export interface DocumentListModel {
  /**
   * An `array` of `DocumentModel` objects. Contains information about a document.
   * @type {Array<DocumentModel>}
   * @memberof DocumentListModel
   */
  list?: Array<DocumentModel>
}
/**
 * Contains information about a document such as language, document type, and download URL.
 * @export
 * @interface DocumentModel
 */
export interface DocumentModel {
  /**
   * The document\'s description or title.
   * @type {string}
   * @memberof DocumentModel
   */
  description?: string
  /**
   * Additional text-based information about the document.
   * @type {string}
   * @memberof DocumentModel
   */
  explanation?: string
  /**
   * The type of the document, such as TBE (User Manual), TIA (Maintenance Manual), etc.
   * @type {string}
   * @memberof DocumentModel
   */
  contentType?: string
  /**
   * The document\'s download URL.
   * @type {string}
   * @memberof DocumentModel
   */
  url?: string
  /**
   * An `array` of languages in which this document is available. You receive the languages in their internationally known [two-digit codes](https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes), such as DE or NL.
   * @type {Set<string>}
   * @memberof DocumentModel
   */
  languages?: Set<string>
  /**
   * The relevant EN (European Standard) code.
   * @type {string}
   * @memberof DocumentModel
   */
  enNumber?: string
}
/**
 * Contains information about an error.
 * @export
 * @interface ErrorMessage
 */
export interface ErrorMessage {
  /**
   * The HTTP status code returned by the service.
   * @type {number}
   * @memberof ErrorMessage
   */
  statusCode?: number
  /**
   * A brief, human-readable description of the error.
   * @type {string}
   * @memberof ErrorMessage
   */
  message?: string
  /**
   * Additional information about the error.
   * @type {Array<Error>}
   * @memberof ErrorMessage
   */
  errors?: Array<Error>
}
/**
 * Contains [ETIM](https://www.etim-international.com/) Features data of an article\'s ETIM Class (EC). Each EC has a defined set of allowed ETIM Features.
 * @export
 * @interface EtimFeatureModel
 */
export interface EtimFeatureModel {
  /**
   * The identifier of the ETIM feature as defined in the [ETIM classification](https://prod.etim-international.com/Class/) standard.
   * @type {string}
   * @memberof EtimFeatureModel
   */
  name?: string
  /**
   * An `array` of values for the given ETIM identifier.
   * @type {Array<string>}
   * @memberof EtimFeatureModel
   */
  values?: Array<string>
  /**
   * The ETIM feature\'s unit of measurement, which is a code defined by the ETIM standard.
   * @type {string}
   * @memberof EtimFeatureModel
   */
  unit?: string
  /**
   * Noteworthy details about the ETIM Feature.
   * @type {string}
   * @memberof EtimFeatureModel
   */
  details?: string
}
/**
 * Contains [ETIM](https://www.etim-international.com/) classification information for an article.
 * @export
 * @interface EtimValueModel
 */
export interface EtimValueModel {
  /**
   * Noteworthy details about the ETIM Class of the article.
   * @type {string}
   * @memberof EtimValueModel
   */
  details?: string
  /**
   * An `array` of ETIM versions.
   * @type {Array<string>}
   * @memberof EtimValueModel
   */
  version?: Array<string>
  /**
   * The ETIM Class of the article.
   * @type {string}
   * @memberof EtimValueModel
   */
  classification?: string
  /**
   * An `array` of `EtimFeatureModel` objects. Contains information about the set of allowed ETIM Features defined in the article\'s ETIM Class.
   * @type {Array<EtimFeatureModel>}
   * @memberof EtimValueModel
   */
  features?: Array<EtimFeatureModel>
}
/**
 *
 * @export
 * @interface FacetResult
 */
export interface FacetResult {
  /**
   *
   * @type {string}
   * @memberof FacetResult
   */
  type: string
}
/**
 * Contains information about images and URLs to different image sizes.
 * @export
 * @interface ImageModel
 */
export interface ImageModel {
  /**
   * The unique identifier of the image.
   * @type {string}
   * @memberof ImageModel
   */
  key?: string
  /**
   * The URL to the small thumbnail version of the image.
   * @type {string}
   * @memberof ImageModel
   */
  thumbnailSmall?: string
  /**
   * The URL to the thumbnail version of the image.
   * @type {string}
   * @memberof ImageModel
   */
  thumbnail?: string
  /**
   * The URL to the small version of the image.
   * @type {string}
   * @memberof ImageModel
   */
  small?: string
  /**
   * The URL to the medium version of the image.
   * @type {string}
   * @memberof ImageModel
   */
  medium?: string
  /**
   * The URL to the large version of the image.
   * @type {string}
   * @memberof ImageModel
   */
  large?: string
  /**
   * The URL to the image in original size.
   * @type {string}
   * @memberof ImageModel
   */
  original?: string
  /**
   * The image content\'s displayed orientation and the image type, such as `SideViewJPG`.
   * @type {string}
   * @memberof ImageModel
   */
  orientation?: string
  /**
   * The proposed order of the image in relation to the other images.
   * @type {number}
   * @memberof ImageModel
   */
  sequence?: number
}
/**
 * Contains a list of attributes and text.
 * @export
 * @interface LabelWithSequenceListModel
 */
export interface LabelWithSequenceListModel {
  /**
   * An `array` of `LabelWithSequenceModel` objects. Contains additional information about an object, such as information about a product\'s characteristics and intended purpose.
   * @type {Array<LabelWithSequenceModel>}
   * @memberof LabelWithSequenceListModel
   */
  list?: Array<LabelWithSequenceModel>
}
/**
 * Contains attributes and their display text.
 * @export
 * @interface LabelWithSequenceModel
 */
export interface LabelWithSequenceModel {
  /**
   * The description or title text.
   * @type {string}
   * @memberof LabelWithSequenceModel
   */
  name?: string
  /**
   * The unique identifier of the respective attribute.
   * @type {string}
   * @memberof LabelWithSequenceModel
   */
  key?: string
  /**
   * The proposed order of the attribute in relation to the other attributes of the same object.
   * @type {number}
   * @memberof LabelWithSequenceModel
   */
  sequence?: number
  /**
   *
   * @type {number}
   * @memberof LabelWithSequenceModel
   */
  sequenceTender?: number
}
/**
 * Contains product data.
 * @export
 * @interface MasterData
 */
export interface MasterData {
  /**
   *
   * @type {Current}
   * @memberof MasterData
   */
  current?: Current
}
/**
 * Contains detail information about an error.
 * @export
 * @interface ModelError
 */
export interface ModelError {
  /**
   * Custom error code
   * @type {string}
   * @memberof ModelError
   */
  code?: string
  /**
   * Custom error message
   * @type {string}
   * @memberof ModelError
   */
  message?: string
}
/**
 *
 * @export
 * @interface MultiValueMapStringString
 */
export interface MultiValueMapStringString {
  [key: string]: Array<string> | any

  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof MultiValueMapStringString
   */
  all?: { [key: string]: string }
  /**
   *
   * @type {boolean}
   * @memberof MultiValueMapStringString
   */
  empty?: boolean
}
/**
 * Contains a collection of product search results matching the search query and the corresponding pagination data.
 * @export
 * @interface PagedSearchProduct
 */
export interface PagedSearchProduct {
  /**
   *
   * @type {number}
   * @memberof PagedSearchProduct
   */
  offset?: number
  /**
   *
   * @type {number}
   * @memberof PagedSearchProduct
   */
  limit?: number
  /**
   *
   * @type {number}
   * @memberof PagedSearchProduct
   */
  total?: number
  /**
   *
   * @type {Array<ProductModel>}
   * @memberof PagedSearchProduct
   */
  results?: Array<ProductModel>
  /**
   *
   * @type {number}
   * @memberof PagedSearchProduct
   */
  count?: number
  /**
   *
   * @type {number}
   * @memberof PagedSearchProduct
   */
  pageIndex?: number
  /**
   *
   * @type {number}
   * @memberof PagedSearchProduct
   */
  totalPages?: number
  /**
   *
   * @type {boolean}
   * @memberof PagedSearchProduct
   */
  first?: boolean
  /**
   *
   * @type {boolean}
   * @memberof PagedSearchProduct
   */
  last?: boolean
  /**
   *
   * @type {{ [key: string]: FacetResult; }}
   * @memberof PagedSearchProduct
   */
  facets?: { [key: string]: FacetResult }
}
/**
 *
 * @export
 * @interface PagedSearchSpareParts
 */
export interface PagedSearchSpareParts {
  /**
   *
   * @type {number}
   * @memberof PagedSearchSpareParts
   */
  offset?: number
  /**
   *
   * @type {number}
   * @memberof PagedSearchSpareParts
   */
  limit?: number
  /**
   *
   * @type {number}
   * @memberof PagedSearchSpareParts
   */
  total?: number
  /**
   *
   * @type {Array<SparePartProductModel>}
   * @memberof PagedSearchSpareParts
   */
  results?: Array<SparePartProductModel>
  /**
   *
   * @type {number}
   * @memberof PagedSearchSpareParts
   */
  count?: number
  /**
   *
   * @type {number}
   * @memberof PagedSearchSpareParts
   */
  pageIndex?: number
  /**
   *
   * @type {number}
   * @memberof PagedSearchSpareParts
   */
  totalPages?: number
  /**
   *
   * @type {boolean}
   * @memberof PagedSearchSpareParts
   */
  first?: boolean
  /**
   *
   * @type {boolean}
   * @memberof PagedSearchSpareParts
   */
  last?: boolean
  /**
   *
   * @type {{ [key: string]: FacetResult; }}
   * @memberof PagedSearchSpareParts
   */
  facets?: { [key: string]: FacetResult }
}
/**
 * Contains product information to display in a user\'s \"My list\" (also called shopping list or wish list) in the online catalog.
 * @export
 * @interface Product
 */
export interface Product {
  /**
   * The unique identifier of the product used for internal service-to-service communication.
   * @type {string}
   * @memberof Product
   */
  id?: string
  /**
   * The unique identifier of the product (formerly called key).
   * @type {string}
   * @memberof Product
   */
  key?: string
  /**
   *
   * @type {MasterData}
   * @memberof Product
   */
  masterData?: MasterData
}
/**
 * Contains information about a gallery linked to a product or article and its images.
 * @export
 * @interface ProductGalleryModel
 */
export interface ProductGalleryModel {
  /**
   * The name of the gallery.
   * @type {string}
   * @memberof ProductGalleryModel
   */
  name?: string
  /**
   * The description of the gallery.
   * @type {string}
   * @memberof ProductGalleryModel
   */
  description?: string
  /**
   * An `array` of `ProductImageModel` objects. Contains images linked to this gallery.
   * @type {Array<ProductImageModel>}
   * @memberof ProductGalleryModel
   */
  list?: Array<ProductImageModel>
}
/**
 * Contains information about images and URLs to different image sizes.
 * @export
 * @interface ProductImageModel
 */
export interface ProductImageModel {
  /**
   * The unique identifier of the image.
   * @type {string}
   * @memberof ProductImageModel
   */
  key?: string
  /**
   * The URL to the small thumbnail version of the image.
   * @type {string}
   * @memberof ProductImageModel
   */
  thumbnailSmall?: string
  /**
   * The URL to the thumbnail version of the image.
   * @type {string}
   * @memberof ProductImageModel
   */
  thumbnail?: string
  /**
   * The URL to the small version of the image.
   * @type {string}
   * @memberof ProductImageModel
   */
  small?: string
  /**
   * The URL to the medium version of the image.
   * @type {string}
   * @memberof ProductImageModel
   */
  medium?: string
  /**
   * The URL to the large version of the image.
   * @type {string}
   * @memberof ProductImageModel
   */
  large?: string
  /**
   * The URL to the image in original size.
   * @type {string}
   * @memberof ProductImageModel
   */
  original?: string
  /**
   * The image content\'s displayed orientation and the image type, such as `SideViewJPG`.
   * @type {string}
   * @memberof ProductImageModel
   */
  orientation?: string
  /**
   * The proposed order of the image in relation to the other images.
   * @type {number}
   * @memberof ProductImageModel
   */
  sequence?: number
  /**
   * The image\'s type.
   * @type {string}
   * @memberof ProductImageModel
   */
  type?: string
  /**
   * The URL to the EPS (Encapsulated Postscript) version of the image.
   * @type {string}
   * @memberof ProductImageModel
   */
  eps?: string
}
/**
 * Contains information about a product and related objects such as documents, images, articles, spare parts, attributes, and more.
 * @export
 * @interface ProductModel
 */
export interface ProductModel {
  /**
   * The unique identifier of the product used for internal service-to-service communication.
   * @type {string}
   * @memberof ProductModel
   */
  productId?: string
  /**
   * The unique identifier of the product.
   * @type {string}
   * @memberof ProductModel
   */
  key?: string
  /**
   * The product\'s name.
   * @type {string}
   * @memberof ProductModel
   */
  name?: string
  /**
   * The product\'s description.
   * @type {string}
   * @memberof ProductModel
   */
  description?: string
  /**
   * The product\'s brand.
   * @type {string}
   * @memberof ProductModel
   */
  brandName?: string
  /**
   * The series to which the product belongs.
   * @type {string}
   * @memberof ProductModel
   */
  series?: string
  /**
   * The product line to which the product belongs.
   * @type {string}
   * @memberof ProductModel
   */
  productBrand?: string
  /**
   * The product type.
   * @type {string}
   * @memberof ProductModel
   */
  type?: string
  /**
   * The system to which the product belongs.
   * @type {string}
   * @memberof ProductModel
   */
  system?: string
  /**
   * The HEX value of the associated [Bluetooth Low Energy](https://en.wikipedia.org/wiki/Bluetooth_Low_Energy) (BLE) device.
   * @type {string}
   * @memberof ProductModel
   */
  bleDevice?: string
  /**
   *
   * @type {ProductGalleryModel}
   * @memberof ProductModel
   */
  gallery?: ProductGalleryModel
  /**
   *
   * @type {ProductVariantModel}
   * @memberof ProductModel
   */
  variant?: ProductVariantModel
  /**
   *
   * @type {ProductStateModel}
   * @memberof ProductModel
   */
  state?: ProductStateModel
  /**
   *
   * @type {ArticleListModel}
   * @memberof ProductModel
   */
  articles?: ArticleListModel
  /**
   *
   * @type {TechnicalAttributesListModel}
   * @memberof ProductModel
   */
  technicalDataList?: TechnicalAttributesListModel
  /**
   *
   * @type {AdditionalInformationListModel}
   * @memberof ProductModel
   */
  additionalInformation?: AdditionalInformationListModel
  /**
   *
   * @type {DeliveryInfoListModel}
   * @memberof ProductModel
   */
  scopeOfDelivery?: DeliveryInfoListModel
  /**
   *
   * @type {LabelWithSequenceListModel}
   * @memberof ProductModel
   */
  characteristics?: LabelWithSequenceListModel
  /**
   *
   * @type {LabelWithSequenceListModel}
   * @memberof ProductModel
   */
  applicationPurposes?: LabelWithSequenceListModel
  /**
   *
   * @type {ProductTileListModel}
   * @memberof ProductModel
   */
  accessories?: ProductTileListModel
  /**
   *
   * @type {ProductTileListModel}
   * @memberof ProductModel
   */
  canBeCombinedWith?: ProductTileListModel
  /**
   *
   * @type {SparePartOverviewListModel}
   * @memberof ProductModel
   */
  spareParts?: SparePartOverviewListModel
  /**
   *
   * @type {DocumentListModel}
   * @memberof ProductModel
   */
  documents?: DocumentListModel
  /**
   *
   * @type {VideoListModel}
   * @memberof ProductModel
   */
  videos?: VideoListModel
  /**
   *
   * @type {AppsListModel}
   * @memberof ProductModel
   */
  apps?: AppsListModel
  /**
   *
   * @type {SimpleAssetListModel}
   * @memberof ProductModel
   */
  accessoriesOverviews?: SimpleAssetListModel
  /**
   *
   * @type {SimpleAssetListModel}
   * @memberof ProductModel
   */
  intendedUseOverviews?: SimpleAssetListModel
  /**
   * An `array` of `CategoryModel` objects. Contains basic information about the product\'s parent category.
   * @type {Set<CategoryModel>}
   * @memberof ProductModel
   */
  categories?: Set<CategoryModel>
  /**
   *
   * @type {BimAssetsModel}
   * @memberof ProductModel
   */
  bimAssets?: BimAssetsModel
}
/**
 * Contains information about the availability and state of a product or article. This can include its availability and BIM capability, whether it is available in the webshop, build to order, and whether it replaces or is replaced by another article.
 * @export
 * @interface ProductStateModel
 */
export interface ProductStateModel {
  /**
   * The date from which a product or article is available. This property is present only if the `indicator` of the same object is `NEW`.
   * @type {string}
   * @memberof ProductStateModel
   */
  from?: string
  /**
   * The current status of the product or article, if one is assigned. It can be either `NEW` or `PHASEOUT`.
   * @type {string}
   * @memberof ProductStateModel
   */
  indicator?: ProductStateModelIndicatorEnum
  /**
   * Indicates whether the product or article is relevant for BIM use.
   * @type {boolean}
   * @memberof ProductStateModel
   */
  bimRelevant?: boolean
  /**
   * Indicates whether the product or article can already be used for BIM.
   * @type {boolean}
   * @memberof ProductStateModel
   */
  bimReady?: boolean
  /**
   * The number of the article that this article replaces.
   * @type {string}
   * @memberof ProductStateModel
   */
  replacementFor?: string
  /**
   * The number of the article that replaces this article.
   * @type {string}
   * @memberof ProductStateModel
   */
  replacedBy?: string
  /**
   * Indicates whether the product or article can be purchased in the Geberit webshop.
   * @type {boolean}
   * @memberof ProductStateModel
   */
  buyable?: boolean
  /**
   * Indicates whether the article is manufactured only after receipt of a confirmed order.
   * @type {boolean}
   * @memberof ProductStateModel
   */
  orderRelated?: boolean
}

export const ProductStateModelIndicatorEnum = {
  New: "NEW",
  Phaseout: "PHASEOUT",
} as const

export type ProductStateModelIndicatorEnum =
  typeof ProductStateModelIndicatorEnum[keyof typeof ProductStateModelIndicatorEnum]

/**
 * Contains a list of linked products.
 * @export
 * @interface ProductTileListModel
 */
export interface ProductTileListModel {
  /**
   * An `array` of `ProductTileModel` objects. Contains information about linked products to be displayed as product tiles in the online catalog.
   * @type {Array<ProductTileModel>}
   * @memberof ProductTileListModel
   */
  list?: Array<ProductTileModel>
}
/**
 * Contains basic information about objects linked to a product, such as accessories. For example, you can populate the accessories tiles on a product page in the onlince catalog with this data.
 * @export
 * @interface ProductTileModel
 */
export interface ProductTileModel {
  /**
   * The unique identifier of the product.
   * @type {string}
   * @memberof ProductTileModel
   */
  key?: string
  /**
   * The product\'s name.
   * @type {string}
   * @memberof ProductTileModel
   */
  name?: string
  /**
   * The number of articles linked to this product.
   * @type {number}
   * @memberof ProductTileModel
   */
  numberArticles?: number
  /**
   *
   * @type {ProductStateModel}
   * @memberof ProductTileModel
   */
  state?: ProductStateModel
  /**
   *
   * @type {ProductGalleryModel}
   * @memberof ProductTileModel
   */
  gallery?: ProductGalleryModel
  /**
   * The article number of the first article linked to the product.
   * @type {string}
   * @memberof ProductTileModel
   */
  sku?: string
  /**
   * The series to which the product belongs.
   * @type {string}
   * @memberof ProductTileModel
   */
  seriesName?: string
}
/**
 * Contains a product variant\'s basic information. It\'s likely that this object is empty, so look in the `articles` object of a product to get article data.
 * @export
 * @interface ProductVariantModel
 */
export interface ProductVariantModel {
  /**
   * The product variant\'s name.
   * @type {string}
   * @memberof ProductVariantModel
   */
  name?: string
  /**
   * The article number (also called SKU) of the product variant.
   * @type {string}
   * @memberof ProductVariantModel
   */
  sku?: string
  /**
   * The URL to the product variant\'s detail page.
   * @type {string}
   * @memberof ProductVariantModel
   */
  url?: string
  /**
   * The URL to the product variant\'s image.
   * @type {string}
   * @memberof ProductVariantModel
   */
  image?: string
  /**
   * The current price of the product variant.
   * @type {string}
   * @memberof ProductVariantModel
   */
  price?: string
  /**
   * The previous price of the product variant.
   * @type {string}
   * @memberof ProductVariantModel
   */
  priceOld?: string
}
/**
 * An `array` of `RawAttribute` objects. Contains attributes linked to the article.
 * @export
 * @interface RawAttribute
 */
export interface RawAttribute {
  /**
   * The name of the attribute.
   * @type {string}
   * @memberof RawAttribute
   */
  name?: string
  /**
   * The raw value of the attribute.
   * @type {object}
   * @memberof RawAttribute
   */
  value?: object
}
/**
 * Contains an article\'s remarks to include in a tender document.
 * @export
 * @interface RemarkData
 */
export interface RemarkData {
  /**
   * The ID of the remark.
   * @type {string}
   * @memberof RemarkData
   */
  id?: string
  /**
   * The short text of the remark in plain text.
   * @type {string}
   * @memberof RemarkData
   */
  outlineText?: string
  /**
   * The long text of the remark in plain text.
   * @type {string}
   * @memberof RemarkData
   */
  detailText?: string
}
/**
 * Contains a list of linked remarks.
 * @export
 * @interface RemarksListData
 */
export interface RemarksListData {
  /**
   * An `array` of `RemarkData` objects. Contains information about an article\'s remarks to include in a tender document.
   * @type {Array<RemarkData>}
   * @memberof RemarksListData
   */
  list?: Array<RemarkData>
}
/**
 * Contains information about a \"My list\" (also called shopping list or wish list) in the online catalog.
 * @export
 * @interface ShoppingListDefinition
 */
export interface ShoppingListDefinition {
  /**
   * The unique identifier of the list. Please note that these IDs are environment-dependent, i.e. you can\'t use the ID of a list created in the development environment in production.
   * @type {string}
   * @memberof ShoppingListDefinition
   */
  id?: string
  /**
   * The list\'s name as defined by the list\'s creator.
   * @type {string}
   * @memberof ShoppingListDefinition
   */
  name?: string
  /**
   * An `array` of `ShoppingListProductModel` objects. Contains basic information about products linked to the list.
   * @type {Array<ShoppingListProductModel>}
   * @memberof ShoppingListDefinition
   */
  textLineItems?: Array<ShoppingListProductModel>
}
/**
 * Contains a list of product information for \"My list\" (also called shopping list or wish list).
 * @export
 * @interface ShoppingListDetailGraphQL
 */
export interface ShoppingListDetailGraphQL {
  /**
   * An `array` of `Product` objects. Contains product information linked to the list.
   * @type {Array<Product>}
   * @memberof ShoppingListDetailGraphQL
   */
  results?: Array<Product>
}
/**
 * Contains product IDs for which to retrieve shopping list data.
 * @export
 * @interface ShoppingListDetailRequest
 */
export interface ShoppingListDetailRequest {
  /**
   * An `array` of product IDs.
   * @type {Set<string>}
   * @memberof ShoppingListDetailRequest
   */
  productCodes?: Set<string>
}
/**
 * Contains product information to display in a \"My list\" (also called shopping list or wish list) in the online catalog.
 * @export
 * @interface ShoppingListProductModel
 */
export interface ShoppingListProductModel {
  /**
   * The unique identifier of the product.
   * @type {string}
   * @memberof ShoppingListProductModel
   */
  productCode?: string
  /**
   * The URL of the product thumbnail to display in the list.
   * @type {string}
   * @memberof ShoppingListProductModel
   */
  mainImageUrl?: string
  /**
   * An `object` containing the list\'s product articles and their quantity. The object\'s keys are a combination of the product ID and article number, e.g., `PRO_170102_146.210.11.1`, and the values are the quantity of the respective article in the list.
   * @type {{ [key: string]: number; }}
   * @memberof ShoppingListProductModel
   */
  quantities?: { [key: string]: number }
  /**
   * The order of the product in relation to the other products in the list.
   * @type {number}
   * @memberof ShoppingListProductModel
   */
  orderHint?: number
}
/**
 * Contains a list of linked assets.
 * @export
 * @interface SimpleAssetListModel
 */
export interface SimpleAssetListModel {
  /**
   * An `array` of `SimpleAssetModel` objects. Contains information about an asset.
   * @type {Array<SimpleAssetModel>}
   * @memberof SimpleAssetListModel
   */
  list?: Array<SimpleAssetModel>
}
/**
 * Contains information about an asset, such as its name and URL.
 * @export
 * @interface SimpleAssetModel
 */
export interface SimpleAssetModel {
  /**
   * The name of the asset.
   * @type {string}
   * @memberof SimpleAssetModel
   */
  name?: string
  /**
   * The URL of the asset.
   * @type {string}
   * @memberof SimpleAssetModel
   */
  url?: string
}
/**
 * Contains basic information about a spare part article, such as article number and availability. For example, you can use the data to pupulate the spare parts table in the online catalog.
 * @export
 * @interface SparePartArticleModel
 */
export interface SparePartArticleModel {
  /**
   * The article number (also called SKU).
   * @type {string}
   * @memberof SparePartArticleModel
   */
  key?: string
  /**
   * Geberit\'s internal identifier of this article.
   * @type {string}
   * @memberof SparePartArticleModel
   */
  idInternal?: string
  /**
   *
   * @type {SparePartArticleStateModel}
   * @memberof SparePartArticleModel
   */
  state?: SparePartArticleStateModel
  /**
   * The article type.
   * @type {string}
   * @memberof SparePartArticleModel
   */
  type?: string
  /**
   * The proposed order of the article in relation to the other articles.
   * @type {string}
   * @memberof SparePartArticleModel
   */
  position?: string
  /**
   * The article\'s name or short description.
   * @type {string}
   * @memberof SparePartArticleModel
   */
  name?: string
  /**
   * Any noteworthy remarks on the article.
   * @type {string}
   * @memberof SparePartArticleModel
   */
  remarks?: string
  /**
   * An `array` of `AttributeValueModel` objects. Contains attribute values linked to the spare part article.
   * @type {Array<AttributeValueModel>}
   * @memberof SparePartArticleModel
   */
  attributeValues?: Array<AttributeValueModel>
}
/**
 * Contains basic information about the state and availability of the articles and main articles of a spare part.
 * @export
 * @interface SparePartArticleStateModel
 */
export interface SparePartArticleStateModel {
  /**
   * Indicates whether the article is currently available.
   * @type {boolean}
   * @memberof SparePartArticleStateModel
   */
  available?: boolean
  /**
   * The number of the article that replaces this article.
   * @type {string}
   * @memberof SparePartArticleStateModel
   */
  replacedBy?: string
  /**
   * The number of the article that this article replaces.
   * @type {string}
   * @memberof SparePartArticleStateModel
   */
  replacementFor?: string
}
/**
 * Contains a list of linked spare part articles.
 * @export
 * @interface SparePartArticlesListModel
 */
export interface SparePartArticlesListModel {
  /**
   * An `array` of `SparePartArticleModel` objects. Contains information about spare part articles.
   * @type {Array<SparePartArticleModel>}
   * @memberof SparePartArticlesListModel
   */
  sparePartArticlesList?: Array<SparePartArticleModel>
  /**
   * An `array` of `AttributeModel` objects. Contains information about the characteristics and attributes of spare part articles.
   * @type {Array<AttributeModel>}
   * @memberof SparePartArticlesListModel
   */
  attributesList?: Array<AttributeModel>
}
/**
 * Contains characteristics linked to a spare part, such as attribute names and values.
 * @export
 * @interface SparePartCharacteristicModel
 */
export interface SparePartCharacteristicModel {
  /**
   * The name of the spare part characteristic.
   * @type {string}
   * @memberof SparePartCharacteristicModel
   */
  name?: string
  /**
   * The display value of the spare part characteristic, which can also contain units of measurement.
   * @type {string}
   * @memberof SparePartCharacteristicModel
   */
  value?: string
}
/**
 * Contains a list of characteristics linked to a spare part.
 * @export
 * @interface SparePartCharacteristicsListModel
 */
export interface SparePartCharacteristicsListModel {
  /**
   * An `array` of `SparePartCharacteristicModel` objects. Contains information about the spare part\'s characteristics.
   * @type {Array<SparePartCharacteristicModel>}
   * @memberof SparePartCharacteristicsListModel
   */
  list?: Array<SparePartCharacteristicModel>
}
/**
 * Contains basic information about an article to which a spare part is assigned, such as article number and availability.
 * @export
 * @interface SparePartMainArticleModel
 */
export interface SparePartMainArticleModel {
  /**
   * The article number (also called SKU).
   * @type {string}
   * @memberof SparePartMainArticleModel
   */
  key?: string
  /**
   * Geberit\'s internal identifier of this article.
   * @type {string}
   * @memberof SparePartMainArticleModel
   */
  idInternal?: string
  /**
   *
   * @type {SparePartArticleStateModel}
   * @memberof SparePartMainArticleModel
   */
  state?: SparePartArticleStateModel
  /**
   * The article\'s URL.
   * @type {string}
   * @memberof SparePartMainArticleModel
   */
  url?: string
}
/**
 * Contains a list of articles to which a spare part is linked.
 * @export
 * @interface SparePartMainArticlesListModel
 */
export interface SparePartMainArticlesListModel {
  /**
   * An `array` of `SparePartMainArticleModel` objects. Contains information about the spare part\'s main articles.
   * @type {Array<SparePartMainArticleModel>}
   * @memberof SparePartMainArticlesListModel
   */
  list?: Array<SparePartMainArticleModel>
}
/**
 * Contains a list of linked spare parts.
 * @export
 * @interface SparePartOverviewListModel
 */
export interface SparePartOverviewListModel {
  /**
   * An `array` of `SparePartOverviewModel` objects. Contains basic information about linked spare parts.
   * @type {Array<SparePartOverviewModel>}
   * @memberof SparePartOverviewListModel
   */
  list?: Array<SparePartOverviewModel>
}
/**
 * Contains overview information about a spare part, such as its unique identifiers, name, manufacturing period and more.
 * @export
 * @interface SparePartOverviewModel
 */
export interface SparePartOverviewModel {
  /**
   * The unique identifier of the spare part used for internal service-to-service communication.
   * @type {string}
   * @memberof SparePartOverviewModel
   */
  productId?: string
  /**
   * The unique identifier of the spare part.
   * @type {string}
   * @memberof SparePartOverviewModel
   */
  key?: string
  /**
   * The spare part\'s name.
   * @type {string}
   * @memberof SparePartOverviewModel
   */
  name?: string
  /**
   * The spare part\'s brand.
   * @type {string}
   * @memberof SparePartOverviewModel
   */
  brandNames?: string
  /**
   * The series to which the spare part belongs.
   * @type {string}
   * @memberof SparePartOverviewModel
   */
  series?: string
  /**
   * The spare part type.
   * @type {string}
   * @memberof SparePartOverviewModel
   */
  types?: string
  /**
   * The starting year of production of the spare part.
   * @type {number}
   * @memberof SparePartOverviewModel
   */
  manufactureFrom?: number
  /**
   * The ending year of production of the spare part.
   * @type {number}
   * @memberof SparePartOverviewModel
   */
  manufactureTo?: number
  /**
   *
   * @type {ProductImageModel}
   * @memberof SparePartOverviewModel
   */
  image?: ProductImageModel
  /**
   *
   * @type {SparePartArticlesListModel}
   * @memberof SparePartOverviewModel
   */
  sparePartArticles?: SparePartArticlesListModel
}
/**
 * Contains information about a spare part and related objects such as documents, images, articles, attributes, and more.
 * @export
 * @interface SparePartProductModel
 */
export interface SparePartProductModel {
  /**
   *
   * @type {SparePartOverviewModel}
   * @memberof SparePartProductModel
   */
  sparePartOverview?: SparePartOverviewModel
  /**
   * An `array` with additional information about the spare part.
   * @type {Array<string>}
   * @memberof SparePartProductModel
   */
  additionalInformation?: Array<string>
  /**
   *
   * @type {SparePartCharacteristicsListModel}
   * @memberof SparePartProductModel
   */
  characteristics?: SparePartCharacteristicsListModel
  /**
   *
   * @type {SparePartMainArticlesListModel}
   * @memberof SparePartProductModel
   */
  mainArticles?: SparePartMainArticlesListModel
  /**
   *
   * @type {SparePartOverviewListModel}
   * @memberof SparePartProductModel
   */
  relatedSpareParts?: SparePartOverviewListModel
  /**
   * An `array` of `CategoryModel` objects. Contains basic information about the spare part`s parent category.
   * @type {Set<CategoryModel>}
   * @memberof SparePartProductModel
   */
  categories?: Set<CategoryModel>
}
/**
 * Contains a list of technical attributes linked to a product.
 * @export
 * @interface TechnicalAttributesListModel
 */
export interface TechnicalAttributesListModel {
  /**
   * An `array` of `TechnicalAttributesModel` objects. Contains a product\'s technical attributes and details.
   * @type {Array<TechnicalAttributesModel>}
   * @memberof TechnicalAttributesListModel
   */
  list?: Array<TechnicalAttributesModel>
}
/**
 * Contains technical attributes, values and units.
 * @export
 * @interface TechnicalAttributesModel
 */
export interface TechnicalAttributesModel {
  /**
   * The identifier of the attribute.
   * @type {string}
   * @memberof TechnicalAttributesModel
   */
  key?: string
  /**
   * The name of the attribute.
   * @type {string}
   * @memberof TechnicalAttributesModel
   */
  name?: string
  /**
   * The display text of the attribute, which can also contain the assigned unit.
   * @type {string}
   * @memberof TechnicalAttributesModel
   */
  value?: string
  /**
   * The raw value of the attribute (without units).
   * @type {string}
   * @memberof TechnicalAttributesModel
   */
  rawValue?: string
  /**
   * An `array` of raw values of the attribute (without units) if there is more than one value.
   * @type {Array<string>}
   * @memberof TechnicalAttributesModel
   */
  rawValues?: Array<string>
  /**
   * The proposed order of the attribute in relation to the other attributes.
   * @type {number}
   * @memberof TechnicalAttributesModel
   */
  sequence?: number
  /**
   * An `array` of attribute classification information.
   * @type {Array<string>}
   * @memberof TechnicalAttributesModel
   */
  groups?: Array<string>
  /**
   * An `array` of `UnitModel` objects. Contains information about attribute units.
   * @type {Array<UnitModel>}
   * @memberof TechnicalAttributesModel
   */
  unit?: Array<UnitModel>
}
/**
 * Contains article data to include in tender documents.
 * @export
 * @interface TenderTextProductData
 */
export interface TenderTextProductData {
  /**
   * The short text of an article in plain text.
   * @type {string}
   * @memberof TenderTextProductData
   */
  outlineText?: string
  /**
   * The long text of an article in plain text.
   * @type {string}
   * @memberof TenderTextProductData
   */
  detailText?: string
  /**
   *
   * @type {RemarksListData}
   * @memberof TenderTextProductData
   */
  remarks?: RemarksListData
}
/**
 * Contains information about the unit of measurement of an attribute\'s value.
 * @export
 * @interface UnitModel
 */
export interface UnitModel {
  /**
   * The unit of measurement\'s encoding standard.
   * @type {string}
   * @memberof UnitModel
   */
  encoding?: string
  /**
   * The name of the unit of measurement.
   * @type {string}
   * @memberof UnitModel
   */
  value?: string
}
/**
 * Contains information about product variants (articles).
 * @export
 * @interface Variant
 */
export interface Variant {
  /**
   * The internal identifier of this article.
   * @type {string}
   * @memberof Variant
   */
  sku?: string
  /**
   * An `array` of `RawAttribute` objects. Contains attributes linked to the article.
   * @type {Array<RawAttribute>}
   * @memberof Variant
   */
  attributesRaw?: Array<RawAttribute>
  /**
   * An `array` of `AttributeValueModel` objects. Contains attribute values linked to the article.
   * @type {Array<AttributeValueModel>}
   * @memberof Variant
   */
  attributeList?: Array<AttributeValueModel>
}
/**
 * Contains a list of linked videos.
 * @export
 * @interface VideoListModel
 */
export interface VideoListModel {
  /**
   * An `array` of `VideoModel` objects. Contains information about a video.
   * @type {Array<VideoModel>}
   * @memberof VideoListModel
   */
  list?: Array<VideoModel>
}
/**
 * Contains a video\'s information, such as the Youtube ID.
 * @export
 * @interface VideoModel
 */
export interface VideoModel {
  /**
   * The video\'s Youtube ID.
   * @type {string}
   * @memberof VideoModel
   */
  youtubeID?: string
}

/**
 * ArticleApi - axios parameter creator
 * @export
 */
export const ArticleApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Specify an article number ([SKU](/glossary)) and locale to retrieve article data and parent product data. This includes various assets, other articles, spare parts, accessories, product attributes, and more. To limit the amount of data retrieved, you can use the optional query parameter `responseSize`.
     * @summary Get a product by article number.
     * @param {string} locale The locale of the article and its parent product. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
     * @param {string} sku The article number (also called [SKU](/glossary)) whose parent product you want to retrieve.
     * @param {'full' | 'fullSpareParts' | 'list' | 'search' | 'fullBIM' | 'fullDCL' | 'fullETIM' | 'fullETIM7' | 'fullETIM8'} [responseSize] Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductByArticle: async (
      locale: string,
      sku: string,
      responseSize?:
        | "full"
        | "fullSpareParts"
        | "list"
        | "search"
        | "fullBIM"
        | "fullDCL"
        | "fullETIM"
        | "fullETIM7"
        | "fullETIM8",
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getProductByArticle", "locale", locale)
      // verify required parameter 'sku' is not null or undefined
      assertParamExists("getProductByArticle", "sku", sku)
      const localVarPath = `/{locale}/articles/{sku}`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"sku"}}`, encodeURIComponent(String(sku)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (responseSize !== undefined) {
        localVarQueryParameter["responseSize"] = responseSize
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify an article number ([SKU](/glossary)), locale, and brand to retrieve article data and parent product data. This includes various assets, other articles, spare parts, accessories, product attributes, and more. To limit the amount of data retrieved, you can use the optional query parameter `responseSize`.
     * @summary Get a product by article number and brand.
     * @param {string} locale The locale of the article and its parent product. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
     * @param {string} brand The brand of the article and its parent product. The Product API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {string} sku The article number (also called [SKU](/glossary)) whose parent product you want to retrieve.
     * @param {'full' | 'fullSpareParts' | 'list' | 'search' | 'fullBIM' | 'fullDCL' | 'fullETIM' | 'fullETIM7' | 'fullETIM8'} [responseSize] Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductByArticleBrand: async (
      locale: string,
      brand: string,
      sku: string,
      responseSize?:
        | "full"
        | "fullSpareParts"
        | "list"
        | "search"
        | "fullBIM"
        | "fullDCL"
        | "fullETIM"
        | "fullETIM7"
        | "fullETIM8",
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getProductByArticleBrand", "locale", locale)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("getProductByArticleBrand", "brand", brand)
      // verify required parameter 'sku' is not null or undefined
      assertParamExists("getProductByArticleBrand", "sku", sku)
      const localVarPath = `/{locale}/{brand}/articles/{sku}`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
        .replace(`{${"sku"}}`, encodeURIComponent(String(sku)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (responseSize !== undefined) {
        localVarQueryParameter["responseSize"] = responseSize
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ArticleApi - functional programming interface
 * @export
 */
export const ArticleApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ArticleApiAxiosParamCreator(configuration)
  return {
    /**
     * Specify an article number ([SKU](/glossary)) and locale to retrieve article data and parent product data. This includes various assets, other articles, spare parts, accessories, product attributes, and more. To limit the amount of data retrieved, you can use the optional query parameter `responseSize`.
     * @summary Get a product by article number.
     * @param {string} locale The locale of the article and its parent product. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
     * @param {string} sku The article number (also called [SKU](/glossary)) whose parent product you want to retrieve.
     * @param {'full' | 'fullSpareParts' | 'list' | 'search' | 'fullBIM' | 'fullDCL' | 'fullETIM' | 'fullETIM7' | 'fullETIM8'} [responseSize] Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProductByArticle(
      locale: string,
      sku: string,
      responseSize?:
        | "full"
        | "fullSpareParts"
        | "list"
        | "search"
        | "fullBIM"
        | "fullDCL"
        | "fullETIM"
        | "fullETIM7"
        | "fullETIM8",
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProductByArticle(locale, sku, responseSize, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify an article number ([SKU](/glossary)), locale, and brand to retrieve article data and parent product data. This includes various assets, other articles, spare parts, accessories, product attributes, and more. To limit the amount of data retrieved, you can use the optional query parameter `responseSize`.
     * @summary Get a product by article number and brand.
     * @param {string} locale The locale of the article and its parent product. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
     * @param {string} brand The brand of the article and its parent product. The Product API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {string} sku The article number (also called [SKU](/glossary)) whose parent product you want to retrieve.
     * @param {'full' | 'fullSpareParts' | 'list' | 'search' | 'fullBIM' | 'fullDCL' | 'fullETIM' | 'fullETIM7' | 'fullETIM8'} [responseSize] Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProductByArticleBrand(
      locale: string,
      brand: string,
      sku: string,
      responseSize?:
        | "full"
        | "fullSpareParts"
        | "list"
        | "search"
        | "fullBIM"
        | "fullDCL"
        | "fullETIM"
        | "fullETIM7"
        | "fullETIM8",
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProductByArticleBrand(
        locale,
        brand,
        sku,
        responseSize,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * ArticleApi - factory interface
 * @export
 */
export const ArticleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ArticleApiFp(configuration)
  return {
    /**
     * Specify an article number ([SKU](/glossary)) and locale to retrieve article data and parent product data. This includes various assets, other articles, spare parts, accessories, product attributes, and more. To limit the amount of data retrieved, you can use the optional query parameter `responseSize`.
     * @summary Get a product by article number.
     * @param {ArticleApiGetProductByArticleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductByArticle(
      requestParameters: ArticleApiGetProductByArticleRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<ProductModel> {
      return localVarFp
        .getProductByArticle(requestParameters.locale, requestParameters.sku, requestParameters.responseSize, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify an article number ([SKU](/glossary)), locale, and brand to retrieve article data and parent product data. This includes various assets, other articles, spare parts, accessories, product attributes, and more. To limit the amount of data retrieved, you can use the optional query parameter `responseSize`.
     * @summary Get a product by article number and brand.
     * @param {ArticleApiGetProductByArticleBrandRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductByArticleBrand(
      requestParameters: ArticleApiGetProductByArticleBrandRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<ProductModel> {
      return localVarFp
        .getProductByArticleBrand(
          requestParameters.locale,
          requestParameters.brand,
          requestParameters.sku,
          requestParameters.responseSize,
          options
        )
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for getProductByArticle operation in ArticleApi.
 * @export
 * @interface ArticleApiGetProductByArticleRequest
 */
export interface ArticleApiGetProductByArticleRequest {
  /**
   * The locale of the article and its parent product. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
   * @type {string}
   * @memberof ArticleApiGetProductByArticle
   */
  readonly locale: string

  /**
   * The article number (also called [SKU](/glossary)) whose parent product you want to retrieve.
   * @type {string}
   * @memberof ArticleApiGetProductByArticle
   */
  readonly sku: string

  /**
   * Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
   * @type {'full' | 'fullSpareParts' | 'list' | 'search' | 'fullBIM' | 'fullDCL' | 'fullETIM' | 'fullETIM7' | 'fullETIM8'}
   * @memberof ArticleApiGetProductByArticle
   */
  readonly responseSize?:
    | "full"
    | "fullSpareParts"
    | "list"
    | "search"
    | "fullBIM"
    | "fullDCL"
    | "fullETIM"
    | "fullETIM7"
    | "fullETIM8"
}

/**
 * Request parameters for getProductByArticleBrand operation in ArticleApi.
 * @export
 * @interface ArticleApiGetProductByArticleBrandRequest
 */
export interface ArticleApiGetProductByArticleBrandRequest {
  /**
   * The locale of the article and its parent product. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
   * @type {string}
   * @memberof ArticleApiGetProductByArticleBrand
   */
  readonly locale: string

  /**
   * The brand of the article and its parent product. The Product API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
   * @type {string}
   * @memberof ArticleApiGetProductByArticleBrand
   */
  readonly brand: string

  /**
   * The article number (also called [SKU](/glossary)) whose parent product you want to retrieve.
   * @type {string}
   * @memberof ArticleApiGetProductByArticleBrand
   */
  readonly sku: string

  /**
   * Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
   * @type {'full' | 'fullSpareParts' | 'list' | 'search' | 'fullBIM' | 'fullDCL' | 'fullETIM' | 'fullETIM7' | 'fullETIM8'}
   * @memberof ArticleApiGetProductByArticleBrand
   */
  readonly responseSize?:
    | "full"
    | "fullSpareParts"
    | "list"
    | "search"
    | "fullBIM"
    | "fullDCL"
    | "fullETIM"
    | "fullETIM7"
    | "fullETIM8"
}

/**
 * ArticleApi - object-oriented interface
 * @export
 * @class ArticleApi
 * @extends {BaseAPI}
 */
export class ArticleApi extends BaseAPI {
  /**
   * Specify an article number ([SKU](/glossary)) and locale to retrieve article data and parent product data. This includes various assets, other articles, spare parts, accessories, product attributes, and more. To limit the amount of data retrieved, you can use the optional query parameter `responseSize`.
   * @summary Get a product by article number.
   * @param {ArticleApiGetProductByArticleRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArticleApi
   */
  public getProductByArticle(requestParameters: ArticleApiGetProductByArticleRequest, options?: AxiosRequestConfig) {
    return ArticleApiFp(this.configuration)
      .getProductByArticle(requestParameters.locale, requestParameters.sku, requestParameters.responseSize, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify an article number ([SKU](/glossary)), locale, and brand to retrieve article data and parent product data. This includes various assets, other articles, spare parts, accessories, product attributes, and more. To limit the amount of data retrieved, you can use the optional query parameter `responseSize`.
   * @summary Get a product by article number and brand.
   * @param {ArticleApiGetProductByArticleBrandRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArticleApi
   */
  public getProductByArticleBrand(
    requestParameters: ArticleApiGetProductByArticleBrandRequest,
    options?: AxiosRequestConfig
  ) {
    return ArticleApiFp(this.configuration)
      .getProductByArticleBrand(
        requestParameters.locale,
        requestParameters.brand,
        requestParameters.sku,
        requestParameters.responseSize,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * CategoryApi - axios parameter creator
 * @export
 */
export const CategoryApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Specify a category ID and locale to retrieve category data, such as images and subcategories.  Use the `depth` parameter to specify the desired nesting depth of the subcategories. For example, you can retrieve category trees extending from the defined category to its last descendant or to a specific depth.  Additionally, you can use the `contentFilter` parameter to display all categories or only those that have linked products (default) or spare parts.
     * @summary Get a category or category tree.
     * @param {string} locale The category tree\&#39;s locale. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
     * @param {string} [rootId] The category ID from which you want to build and retrieve the category tree. If you don\&#39;t specify a category ID, the endpoint returns the category tree starting from the current root category(ies).
     * @param {number} [depth] The depth to which you want to build and retrieve the category tree. - &#x60;0&#x60; returns no child categories  - &#x60;1&#x60; returns the first level of child categories  - &#x60;2&#x60; returns the first and second level of child categories  - and so on   By default, the endpoint returns the category tree to its maximum depth.
     * @param {'products' | 'spareparts' | 'all'} [contentFilter] A filter parameter to limit the categories you get. There are three values available:  - &#x60;products&#x60; (default): returns only categories with products or with at least one subcategory that has linked products  - &#x60;spareparts&#x60;: returns only categories with spare parts or with at least one subcategory that has spare parts  - &#x60;all&#x60;: returns all categories   Note that categories with subcategories are usually not linked to products or spare parts. Thus, this filter only applies to categories with directly linked products or spare parts. However, a category is not included in the API response if the applied filter culls all subcategories.  The default value is &#x60;products&#x60;.
     * @param {'full' | 'fullSpareParts' | 'list' | 'search' | 'fullBIM' | 'fullDCL' | 'fullETIM' | 'fullETIM7' | 'fullETIM8'} [responseSize] Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findCategories: async (
      locale: string,
      rootId?: string,
      depth?: number,
      contentFilter?: "products" | "spareparts" | "all",
      responseSize?:
        | "full"
        | "fullSpareParts"
        | "list"
        | "search"
        | "fullBIM"
        | "fullDCL"
        | "fullETIM"
        | "fullETIM7"
        | "fullETIM8",
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("findCategories", "locale", locale)
      const localVarPath = `/{locale}/categories`.replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (rootId !== undefined) {
        localVarQueryParameter["rootId"] = rootId
      }

      if (depth !== undefined) {
        localVarQueryParameter["depth"] = depth
      }

      if (contentFilter !== undefined) {
        localVarQueryParameter["contentFilter"] = contentFilter
      }

      if (responseSize !== undefined) {
        localVarQueryParameter["responseSize"] = responseSize
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify a category ID, locale, and brand to retrieve branded category data, such as images and subcategories.  Use the `depth` parameter to specify the desired nesting depth of the subcategories. For example, you can retrieve category trees extending from the defined category to its last descendant or to a specific depth.  Additionally, you can use the `contentFilter` parameter to display all categories or only those that have linked products (default) or spare parts.
     * @summary Get a branded category or category tree.
     * @param {string} locale The category tree\&#39;s locale. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
     * @param {string} brand The category tree\&#39;s branding. The Product API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {string} [rootId] The category ID from which you want to build and retrieve the category tree. If you don\&#39;t specify a category ID, the endpoint returns the category tree starting from the current root category(ies).
     * @param {number} [depth] The depth to which you want to build and retrieve the category tree. - &#x60;0&#x60; returns no child categories  - &#x60;1&#x60; returns the first level of child categories  - &#x60;2&#x60; returns the first and second level of child categories  - and so on   By default, the endpoint returns the category tree to its maximum depth.
     * @param {'products' | 'spareparts' | 'all'} [contentFilter] A filter parameter to limit the categories you get. There are three values available:  - &#x60;products&#x60; (default): returns only categories with products or with at least one subcategory that has linked products  - &#x60;spareparts&#x60;: returns only categories with spare parts or with at least one subcategory that has spare parts  - &#x60;all&#x60;: returns all categories   Note that categories with subcategories are usually not linked to products or spare parts. Thus, this filter only applies to categories with directly linked products or spare parts. However, a category is not included in the API response if the applied filter culls all subcategories.  The default value is &#x60;products&#x60;.
     * @param {'full' | 'fullSpareParts' | 'list' | 'search' | 'fullBIM' | 'fullDCL' | 'fullETIM' | 'fullETIM7' | 'fullETIM8'} [responseSizeData] Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findCategoriesByBrand: async (
      locale: string,
      brand: string,
      rootId?: string,
      depth?: number,
      contentFilter?: "products" | "spareparts" | "all",
      responseSizeData?:
        | "full"
        | "fullSpareParts"
        | "list"
        | "search"
        | "fullBIM"
        | "fullDCL"
        | "fullETIM"
        | "fullETIM7"
        | "fullETIM8",
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("findCategoriesByBrand", "locale", locale)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("findCategoriesByBrand", "brand", brand)
      const localVarPath = `/{locale}/{brand}/categories`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (rootId !== undefined) {
        localVarQueryParameter["rootId"] = rootId
      }

      if (depth !== undefined) {
        localVarQueryParameter["depth"] = depth
      }

      if (contentFilter !== undefined) {
        localVarQueryParameter["contentFilter"] = contentFilter
      }

      if (responseSizeData !== undefined) {
        localVarQueryParameter["responseSizeData"] = responseSizeData
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * CategoryApi - functional programming interface
 * @export
 */
export const CategoryApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CategoryApiAxiosParamCreator(configuration)
  return {
    /**
     * Specify a category ID and locale to retrieve category data, such as images and subcategories.  Use the `depth` parameter to specify the desired nesting depth of the subcategories. For example, you can retrieve category trees extending from the defined category to its last descendant or to a specific depth.  Additionally, you can use the `contentFilter` parameter to display all categories or only those that have linked products (default) or spare parts.
     * @summary Get a category or category tree.
     * @param {string} locale The category tree\&#39;s locale. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
     * @param {string} [rootId] The category ID from which you want to build and retrieve the category tree. If you don\&#39;t specify a category ID, the endpoint returns the category tree starting from the current root category(ies).
     * @param {number} [depth] The depth to which you want to build and retrieve the category tree. - &#x60;0&#x60; returns no child categories  - &#x60;1&#x60; returns the first level of child categories  - &#x60;2&#x60; returns the first and second level of child categories  - and so on   By default, the endpoint returns the category tree to its maximum depth.
     * @param {'products' | 'spareparts' | 'all'} [contentFilter] A filter parameter to limit the categories you get. There are three values available:  - &#x60;products&#x60; (default): returns only categories with products or with at least one subcategory that has linked products  - &#x60;spareparts&#x60;: returns only categories with spare parts or with at least one subcategory that has spare parts  - &#x60;all&#x60;: returns all categories   Note that categories with subcategories are usually not linked to products or spare parts. Thus, this filter only applies to categories with directly linked products or spare parts. However, a category is not included in the API response if the applied filter culls all subcategories.  The default value is &#x60;products&#x60;.
     * @param {'full' | 'fullSpareParts' | 'list' | 'search' | 'fullBIM' | 'fullDCL' | 'fullETIM' | 'fullETIM7' | 'fullETIM8'} [responseSize] Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findCategories(
      locale: string,
      rootId?: string,
      depth?: number,
      contentFilter?: "products" | "spareparts" | "all",
      responseSize?:
        | "full"
        | "fullSpareParts"
        | "list"
        | "search"
        | "fullBIM"
        | "fullDCL"
        | "fullETIM"
        | "fullETIM7"
        | "fullETIM8",
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findCategories(
        locale,
        rootId,
        depth,
        contentFilter,
        responseSize,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify a category ID, locale, and brand to retrieve branded category data, such as images and subcategories.  Use the `depth` parameter to specify the desired nesting depth of the subcategories. For example, you can retrieve category trees extending from the defined category to its last descendant or to a specific depth.  Additionally, you can use the `contentFilter` parameter to display all categories or only those that have linked products (default) or spare parts.
     * @summary Get a branded category or category tree.
     * @param {string} locale The category tree\&#39;s locale. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
     * @param {string} brand The category tree\&#39;s branding. The Product API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {string} [rootId] The category ID from which you want to build and retrieve the category tree. If you don\&#39;t specify a category ID, the endpoint returns the category tree starting from the current root category(ies).
     * @param {number} [depth] The depth to which you want to build and retrieve the category tree. - &#x60;0&#x60; returns no child categories  - &#x60;1&#x60; returns the first level of child categories  - &#x60;2&#x60; returns the first and second level of child categories  - and so on   By default, the endpoint returns the category tree to its maximum depth.
     * @param {'products' | 'spareparts' | 'all'} [contentFilter] A filter parameter to limit the categories you get. There are three values available:  - &#x60;products&#x60; (default): returns only categories with products or with at least one subcategory that has linked products  - &#x60;spareparts&#x60;: returns only categories with spare parts or with at least one subcategory that has spare parts  - &#x60;all&#x60;: returns all categories   Note that categories with subcategories are usually not linked to products or spare parts. Thus, this filter only applies to categories with directly linked products or spare parts. However, a category is not included in the API response if the applied filter culls all subcategories.  The default value is &#x60;products&#x60;.
     * @param {'full' | 'fullSpareParts' | 'list' | 'search' | 'fullBIM' | 'fullDCL' | 'fullETIM' | 'fullETIM7' | 'fullETIM8'} [responseSizeData] Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findCategoriesByBrand(
      locale: string,
      brand: string,
      rootId?: string,
      depth?: number,
      contentFilter?: "products" | "spareparts" | "all",
      responseSizeData?:
        | "full"
        | "fullSpareParts"
        | "list"
        | "search"
        | "fullBIM"
        | "fullDCL"
        | "fullETIM"
        | "fullETIM7"
        | "fullETIM8",
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findCategoriesByBrand(
        locale,
        brand,
        rootId,
        depth,
        contentFilter,
        responseSizeData,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * CategoryApi - factory interface
 * @export
 */
export const CategoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = CategoryApiFp(configuration)
  return {
    /**
     * Specify a category ID and locale to retrieve category data, such as images and subcategories.  Use the `depth` parameter to specify the desired nesting depth of the subcategories. For example, you can retrieve category trees extending from the defined category to its last descendant or to a specific depth.  Additionally, you can use the `contentFilter` parameter to display all categories or only those that have linked products (default) or spare parts.
     * @summary Get a category or category tree.
     * @param {CategoryApiFindCategoriesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findCategories(
      requestParameters: CategoryApiFindCategoriesRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<Category> {
      return localVarFp
        .findCategories(
          requestParameters.locale,
          requestParameters.rootId,
          requestParameters.depth,
          requestParameters.contentFilter,
          requestParameters.responseSize,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify a category ID, locale, and brand to retrieve branded category data, such as images and subcategories.  Use the `depth` parameter to specify the desired nesting depth of the subcategories. For example, you can retrieve category trees extending from the defined category to its last descendant or to a specific depth.  Additionally, you can use the `contentFilter` parameter to display all categories or only those that have linked products (default) or spare parts.
     * @summary Get a branded category or category tree.
     * @param {CategoryApiFindCategoriesByBrandRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findCategoriesByBrand(
      requestParameters: CategoryApiFindCategoriesByBrandRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<Category> {
      return localVarFp
        .findCategoriesByBrand(
          requestParameters.locale,
          requestParameters.brand,
          requestParameters.rootId,
          requestParameters.depth,
          requestParameters.contentFilter,
          requestParameters.responseSizeData,
          options
        )
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for findCategories operation in CategoryApi.
 * @export
 * @interface CategoryApiFindCategoriesRequest
 */
export interface CategoryApiFindCategoriesRequest {
  /**
   * The category tree\&#39;s locale. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
   * @type {string}
   * @memberof CategoryApiFindCategories
   */
  readonly locale: string

  /**
   * The category ID from which you want to build and retrieve the category tree. If you don\&#39;t specify a category ID, the endpoint returns the category tree starting from the current root category(ies).
   * @type {string}
   * @memberof CategoryApiFindCategories
   */
  readonly rootId?: string

  /**
   * The depth to which you want to build and retrieve the category tree. - &#x60;0&#x60; returns no child categories  - &#x60;1&#x60; returns the first level of child categories  - &#x60;2&#x60; returns the first and second level of child categories  - and so on   By default, the endpoint returns the category tree to its maximum depth.
   * @type {number}
   * @memberof CategoryApiFindCategories
   */
  readonly depth?: number

  /**
   * A filter parameter to limit the categories you get. There are three values available:  - &#x60;products&#x60; (default): returns only categories with products or with at least one subcategory that has linked products  - &#x60;spareparts&#x60;: returns only categories with spare parts or with at least one subcategory that has spare parts  - &#x60;all&#x60;: returns all categories   Note that categories with subcategories are usually not linked to products or spare parts. Thus, this filter only applies to categories with directly linked products or spare parts. However, a category is not included in the API response if the applied filter culls all subcategories.  The default value is &#x60;products&#x60;.
   * @type {'products' | 'spareparts' | 'all'}
   * @memberof CategoryApiFindCategories
   */
  readonly contentFilter?: "products" | "spareparts" | "all"

  /**
   * Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
   * @type {'full' | 'fullSpareParts' | 'list' | 'search' | 'fullBIM' | 'fullDCL' | 'fullETIM' | 'fullETIM7' | 'fullETIM8'}
   * @memberof CategoryApiFindCategories
   */
  readonly responseSize?:
    | "full"
    | "fullSpareParts"
    | "list"
    | "search"
    | "fullBIM"
    | "fullDCL"
    | "fullETIM"
    | "fullETIM7"
    | "fullETIM8"
}

/**
 * Request parameters for findCategoriesByBrand operation in CategoryApi.
 * @export
 * @interface CategoryApiFindCategoriesByBrandRequest
 */
export interface CategoryApiFindCategoriesByBrandRequest {
  /**
   * The category tree\&#39;s locale. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
   * @type {string}
   * @memberof CategoryApiFindCategoriesByBrand
   */
  readonly locale: string

  /**
   * The category tree\&#39;s branding. The Product API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
   * @type {string}
   * @memberof CategoryApiFindCategoriesByBrand
   */
  readonly brand: string

  /**
   * The category ID from which you want to build and retrieve the category tree. If you don\&#39;t specify a category ID, the endpoint returns the category tree starting from the current root category(ies).
   * @type {string}
   * @memberof CategoryApiFindCategoriesByBrand
   */
  readonly rootId?: string

  /**
   * The depth to which you want to build and retrieve the category tree. - &#x60;0&#x60; returns no child categories  - &#x60;1&#x60; returns the first level of child categories  - &#x60;2&#x60; returns the first and second level of child categories  - and so on   By default, the endpoint returns the category tree to its maximum depth.
   * @type {number}
   * @memberof CategoryApiFindCategoriesByBrand
   */
  readonly depth?: number

  /**
   * A filter parameter to limit the categories you get. There are three values available:  - &#x60;products&#x60; (default): returns only categories with products or with at least one subcategory that has linked products  - &#x60;spareparts&#x60;: returns only categories with spare parts or with at least one subcategory that has spare parts  - &#x60;all&#x60;: returns all categories   Note that categories with subcategories are usually not linked to products or spare parts. Thus, this filter only applies to categories with directly linked products or spare parts. However, a category is not included in the API response if the applied filter culls all subcategories.  The default value is &#x60;products&#x60;.
   * @type {'products' | 'spareparts' | 'all'}
   * @memberof CategoryApiFindCategoriesByBrand
   */
  readonly contentFilter?: "products" | "spareparts" | "all"

  /**
   * Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
   * @type {'full' | 'fullSpareParts' | 'list' | 'search' | 'fullBIM' | 'fullDCL' | 'fullETIM' | 'fullETIM7' | 'fullETIM8'}
   * @memberof CategoryApiFindCategoriesByBrand
   */
  readonly responseSizeData?:
    | "full"
    | "fullSpareParts"
    | "list"
    | "search"
    | "fullBIM"
    | "fullDCL"
    | "fullETIM"
    | "fullETIM7"
    | "fullETIM8"
}

/**
 * CategoryApi - object-oriented interface
 * @export
 * @class CategoryApi
 * @extends {BaseAPI}
 */
export class CategoryApi extends BaseAPI {
  /**
   * Specify a category ID and locale to retrieve category data, such as images and subcategories.  Use the `depth` parameter to specify the desired nesting depth of the subcategories. For example, you can retrieve category trees extending from the defined category to its last descendant or to a specific depth.  Additionally, you can use the `contentFilter` parameter to display all categories or only those that have linked products (default) or spare parts.
   * @summary Get a category or category tree.
   * @param {CategoryApiFindCategoriesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoryApi
   */
  public findCategories(requestParameters: CategoryApiFindCategoriesRequest, options?: AxiosRequestConfig) {
    return CategoryApiFp(this.configuration)
      .findCategories(
        requestParameters.locale,
        requestParameters.rootId,
        requestParameters.depth,
        requestParameters.contentFilter,
        requestParameters.responseSize,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify a category ID, locale, and brand to retrieve branded category data, such as images and subcategories.  Use the `depth` parameter to specify the desired nesting depth of the subcategories. For example, you can retrieve category trees extending from the defined category to its last descendant or to a specific depth.  Additionally, you can use the `contentFilter` parameter to display all categories or only those that have linked products (default) or spare parts.
   * @summary Get a branded category or category tree.
   * @param {CategoryApiFindCategoriesByBrandRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoryApi
   */
  public findCategoriesByBrand(
    requestParameters: CategoryApiFindCategoriesByBrandRequest,
    options?: AxiosRequestConfig
  ) {
    return CategoryApiFp(this.configuration)
      .findCategoriesByBrand(
        requestParameters.locale,
        requestParameters.brand,
        requestParameters.rootId,
        requestParameters.depth,
        requestParameters.contentFilter,
        requestParameters.responseSizeData,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * CategoryLegacyApi - axios parameter creator
 * @export
 */
export const CategoryLegacyApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Specify a category ID and locale to retrieve a basic category tree, starting with the given category and ending with its last descendant.   > **Note:** This is a legacy endpoint of the Product API V0. If you are looking for category data and do not need to rely on this endpoint, use this API\'s category endpoints instead.
     * @summary Get category info.
     * @param {string} locale The category\&#39;s locale. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
     * @param {string} categoryIdentifier The category ID from which to build the category tree.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findCategoryById: async (
      locale: string,
      categoryIdentifier: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("findCategoryById", "locale", locale)
      // verify required parameter 'categoryIdentifier' is not null or undefined
      assertParamExists("findCategoryById", "categoryIdentifier", categoryIdentifier)
      const localVarPath = `/v0/{locale}/categories/{categoryIdentifier}`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"categoryIdentifier"}}`, encodeURIComponent(String(categoryIdentifier)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * CategoryLegacyApi - functional programming interface
 * @export
 */
export const CategoryLegacyApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CategoryLegacyApiAxiosParamCreator(configuration)
  return {
    /**
     * Specify a category ID and locale to retrieve a basic category tree, starting with the given category and ending with its last descendant.   > **Note:** This is a legacy endpoint of the Product API V0. If you are looking for category data and do not need to rely on this endpoint, use this API\'s category endpoints instead.
     * @summary Get category info.
     * @param {string} locale The category\&#39;s locale. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
     * @param {string} categoryIdentifier The category ID from which to build the category tree.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findCategoryById(
      locale: string,
      categoryIdentifier: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryInfoModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findCategoryById(locale, categoryIdentifier, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * CategoryLegacyApi - factory interface
 * @export
 */
export const CategoryLegacyApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = CategoryLegacyApiFp(configuration)
  return {
    /**
     * Specify a category ID and locale to retrieve a basic category tree, starting with the given category and ending with its last descendant.   > **Note:** This is a legacy endpoint of the Product API V0. If you are looking for category data and do not need to rely on this endpoint, use this API\'s category endpoints instead.
     * @summary Get category info.
     * @param {CategoryLegacyApiFindCategoryByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findCategoryById(
      requestParameters: CategoryLegacyApiFindCategoryByIdRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<CategoryInfoModel> {
      return localVarFp
        .findCategoryById(requestParameters.locale, requestParameters.categoryIdentifier, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for findCategoryById operation in CategoryLegacyApi.
 * @export
 * @interface CategoryLegacyApiFindCategoryByIdRequest
 */
export interface CategoryLegacyApiFindCategoryByIdRequest {
  /**
   * The category\&#39;s locale. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
   * @type {string}
   * @memberof CategoryLegacyApiFindCategoryById
   */
  readonly locale: string

  /**
   * The category ID from which to build the category tree.
   * @type {string}
   * @memberof CategoryLegacyApiFindCategoryById
   */
  readonly categoryIdentifier: string
}

/**
 * CategoryLegacyApi - object-oriented interface
 * @export
 * @class CategoryLegacyApi
 * @extends {BaseAPI}
 */
export class CategoryLegacyApi extends BaseAPI {
  /**
   * Specify a category ID and locale to retrieve a basic category tree, starting with the given category and ending with its last descendant.   > **Note:** This is a legacy endpoint of the Product API V0. If you are looking for category data and do not need to rely on this endpoint, use this API\'s category endpoints instead.
   * @summary Get category info.
   * @param {CategoryLegacyApiFindCategoryByIdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoryLegacyApi
   */
  public findCategoryById(requestParameters: CategoryLegacyApiFindCategoryByIdRequest, options?: AxiosRequestConfig) {
    return CategoryLegacyApiFp(this.configuration)
      .findCategoryById(requestParameters.locale, requestParameters.categoryIdentifier, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * ProductApi - axios parameter creator
 * @export
 */
export const ProductApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Use this endpoint to search for products using [CTP\'s Product Projection Search](https://docs.commercetools.com/api/projects/products-search). You can use either the `POST` or the `GET` method for this endpoint and define various filters and facets to fine-tune search results.
     * @summary Search products.
     * @param {string} locale The locale of the product. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
     * @param {Array<string>} [bleDevice] A comma-separated list of HEX values of the [Bluetooth Low Energy](https://en.wikipedia.org/wiki/Bluetooth_Low_Energy) (BLE) devices of the products you want to search for.
     * @param {Array<string>} [eanCode] A comma-separated list of EAN codes ([European Article Number](https://en.wikipedia.org/wiki/International_Article_Number)) whose parent products you want to search for.
     * @param {string} [text] The text to search for. For example, entered by a user in a website\&#39;s search field.
     * @param {string} [fuzzy] Boolean - Indicate whether to apply fuzzy search to the provided text. See the [official documentation](https://dev.commercetools.com/http-api-projects-products-search.html#fuzzy-search) to learn more. The default value is &#x60;false&#x60;.
     * @param {string} [fuzzyLevel] Integer - Set the desired fuzzy level when fuzzy search is enabled.  By default, CTP calculates and applies a value dynamically. The value you specify must not be higher than the one CTP wants to apply. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#fuzzy-search) to learn more.
     * @param {string} [filter] The filters you want to apply to the search results after facets are calculated. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#filters) to learn which filters are available and how to use them.
     * @param {string} [filterQuery] The filters you want to apply to the search results before facets are calculated. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#filters) to learn which filters are available and how to use them.
     * @param {string} [filterFacets] The filters you want to apply to all facets calculations. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#filters) to learn which filters are available and how to use them.
     * @param {string} [facets] Specify facets to retrieve a &#x60;facets&#x60; object along with your search results that contains data for use in faceted navigation. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#facets) to learn more about facets and how to use them.
     * @param {string} [sort] The sort order in which you want to retrieve the search results. By default, the results are sorted by relevance. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#sorting) to learn how to to achieve your desired sorting.
     * @param {string} [limit] The number of search results you want to retrieve, used for pagination. CTP allows values between 1 and 500 and sets the default value to 20. To avoid performance issues, we recommend setting the value no higher than 30.  For more information, see the [official documentation](https://docs.commercetools.com/api/general-concepts#paging) about paging.
     * @param {string} [offset] The number of search results to skip, used for pagination. CTP allows a maximum offset of 10000. The default value is 0.  For more information, see the [official documentation](https://docs.commercetools.com/api/general-concepts#paging) about paging.
     * @param {'full' | 'fullSpareParts' | 'list' | 'search' | 'fullBIM' | 'fullDCL' | 'fullETIM' | 'fullETIM7' | 'fullETIM8'} [responseSize] Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
     * @param {string} [includeArticleAccessories] Indicate whether you want the response to include article accessories. The default value is &#x60;false&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findProducts: async (
      locale: string,
      bleDevice?: Array<string>,
      eanCode?: Array<string>,
      text?: string,
      fuzzy?: string,
      fuzzyLevel?: string,
      filter?: string,
      filterQuery?: string,
      filterFacets?: string,
      facets?: string,
      sort?: string,
      limit?: string,
      offset?: string,
      responseSize?:
        | "full"
        | "fullSpareParts"
        | "list"
        | "search"
        | "fullBIM"
        | "fullDCL"
        | "fullETIM"
        | "fullETIM7"
        | "fullETIM8",
      includeArticleAccessories?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("findProducts", "locale", locale)
      const localVarPath = `/{locale}/products/search`.replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (bleDevice) {
        localVarQueryParameter["bleDevice"] = bleDevice
      }

      if (eanCode) {
        localVarQueryParameter["eanCode"] = eanCode
      }

      if (text !== undefined) {
        localVarQueryParameter["text"] = text
      }

      if (fuzzy !== undefined) {
        localVarQueryParameter["fuzzy"] = fuzzy
      }

      if (fuzzyLevel !== undefined) {
        localVarQueryParameter["fuzzyLevel"] = fuzzyLevel
      }

      if (filter !== undefined) {
        localVarQueryParameter["filter"] = filter
      }

      if (filterQuery !== undefined) {
        localVarQueryParameter["filter.query"] = filterQuery
      }

      if (filterFacets !== undefined) {
        localVarQueryParameter["filter.facets"] = filterFacets
      }

      if (facets !== undefined) {
        localVarQueryParameter["facets"] = facets
      }

      if (sort !== undefined) {
        localVarQueryParameter["sort"] = sort
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset
      }

      if (responseSize !== undefined) {
        localVarQueryParameter["responseSize"] = responseSize
      }

      if (includeArticleAccessories !== undefined) {
        localVarQueryParameter["includeArticleAccessories"] = includeArticleAccessories
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Use this endpoint to search for branded products using [CTP\'s Product Projection Search](https://docs.commercetools.com/api/projects/products-search). You can use either the `POST` or the `GET` method for this endpoint and define various filters and facets to fine-tune search results.
     * @summary Search branded products.
     * @param {string} locale The locale of the product. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
     * @param {string} brand The brand of the product. The Product API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {Array<string>} [bleDevice] A comma-separated list of HEX values of the [Bluetooth Low Energy](https://en.wikipedia.org/wiki/Bluetooth_Low_Energy) (BLE) devices of the products you want to search for.
     * @param {Array<string>} [eanCode] A comma-separated list of EAN codes ([European Article Number](https://en.wikipedia.org/wiki/International_Article_Number)) whose parent products you want to search for.
     * @param {string} [text] The text to search for. For example, entered by a user in a website\&#39;s search field.
     * @param {string} [fuzzy] Boolean - Indicate whether to apply fuzzy search to the provided text. See the [official documentation](https://dev.commercetools.com/http-api-projects-products-search.html#fuzzy-search) to learn more. The default value is &#x60;false&#x60;.
     * @param {string} [fuzzyLevel] Integer - Set the desired fuzzy level when fuzzy search is enabled.  By default, CTP calculates and applies a value dynamically. The value you specify must not be higher than the one CTP wants to apply. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#fuzzy-search) to learn more.
     * @param {string} [filter] The filters you want to apply to the search results after facets are calculated. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#filters) to learn which filters are available and how to use them.
     * @param {string} [filterQuery] The filters you want to apply to the search results before facets are calculated. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#filters) to learn which filters are available and how to use them.
     * @param {string} [filterFacets] The filters you want to apply to all facets calculations. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#filters) to learn which filters are available and how to use them.
     * @param {string} [facets] Specify facets to retrieve a &#x60;facets&#x60; object along with your search results that contains data for use in faceted navigation. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#facets) to learn more about facets and how to use them.
     * @param {string} [sort] The sort order in which you want to retrieve the search results. By default, the results are sorted by relevance. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#sorting) to learn how to to achieve your desired sorting.
     * @param {string} [limit] The number of search results you want to retrieve, used for pagination. CTP allows values between 1 and 500 and sets the default value to 20. To avoid performance issues, we recommend setting the value no higher than 30.  For more information, see the [official documentation](https://docs.commercetools.com/api/general-concepts#paging) about paging.
     * @param {string} [offset] The number of search results to skip, used for pagination. CTP allows a maximum offset of 10000. The default value is 0.  For more information, see the [official documentation](https://docs.commercetools.com/api/general-concepts#paging) about paging.
     * @param {'full' | 'fullSpareParts' | 'list' | 'search' | 'fullBIM' | 'fullDCL' | 'fullETIM' | 'fullETIM7' | 'fullETIM8'} [responseSize] Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
     * @param {string} [includeArticleAccessories] Indicate whether you want the response to include article accessories. The default value is &#x60;false&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findProductsByBrand: async (
      locale: string,
      brand: string,
      bleDevice?: Array<string>,
      eanCode?: Array<string>,
      text?: string,
      fuzzy?: string,
      fuzzyLevel?: string,
      filter?: string,
      filterQuery?: string,
      filterFacets?: string,
      facets?: string,
      sort?: string,
      limit?: string,
      offset?: string,
      responseSize?:
        | "full"
        | "fullSpareParts"
        | "list"
        | "search"
        | "fullBIM"
        | "fullDCL"
        | "fullETIM"
        | "fullETIM7"
        | "fullETIM8",
      includeArticleAccessories?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("findProductsByBrand", "locale", locale)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("findProductsByBrand", "brand", brand)
      const localVarPath = `/{locale}/{brand}/products/search`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (bleDevice) {
        localVarQueryParameter["bleDevice"] = bleDevice
      }

      if (eanCode) {
        localVarQueryParameter["eanCode"] = eanCode
      }

      if (text !== undefined) {
        localVarQueryParameter["text"] = text
      }

      if (fuzzy !== undefined) {
        localVarQueryParameter["fuzzy"] = fuzzy
      }

      if (fuzzyLevel !== undefined) {
        localVarQueryParameter["fuzzyLevel"] = fuzzyLevel
      }

      if (filter !== undefined) {
        localVarQueryParameter["filter"] = filter
      }

      if (filterQuery !== undefined) {
        localVarQueryParameter["filter.query"] = filterQuery
      }

      if (filterFacets !== undefined) {
        localVarQueryParameter["filter.facets"] = filterFacets
      }

      if (facets !== undefined) {
        localVarQueryParameter["facets"] = facets
      }

      if (sort !== undefined) {
        localVarQueryParameter["sort"] = sort
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset
      }

      if (responseSize !== undefined) {
        localVarQueryParameter["responseSize"] = responseSize
      }

      if (includeArticleAccessories !== undefined) {
        localVarQueryParameter["includeArticleAccessories"] = includeArticleAccessories
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Use this endpoint to search for branded products using [CTP\'s Product Projection Search](https://docs.commercetools.com/api/projects/products-search). You can use either the `POST` or the `GET` method for this endpoint and define various filters and facets to fine-tune search results.
     * @summary Search branded products. (POST)
     * @param {string} locale The locale of the product. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
     * @param {string} brand The brand of the product. The Product API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {'full' | 'fullSpareParts' | 'list' | 'search' | 'fullBIM' | 'fullDCL' | 'fullETIM' | 'fullETIM7' | 'fullETIM8'} [responseSize] Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
     * @param {string} [includeArticleAccessories] Indicate whether you want the response to include article accessories. The default value is &#x60;false&#x60;.
     * @param {boolean} [empty]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findProductsByBrandPost: async (
      locale: string,
      brand: string,
      responseSize?:
        | "full"
        | "fullSpareParts"
        | "list"
        | "search"
        | "fullBIM"
        | "fullDCL"
        | "fullETIM"
        | "fullETIM7"
        | "fullETIM8",
      includeArticleAccessories?: string,
      empty?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("findProductsByBrandPost", "locale", locale)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("findProductsByBrandPost", "brand", brand)
      const localVarPath = `/{locale}/{brand}/products/search`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new URLSearchParams()

      if (responseSize !== undefined) {
        localVarQueryParameter["responseSize"] = responseSize
      }

      if (includeArticleAccessories !== undefined) {
        localVarQueryParameter["includeArticleAccessories"] = includeArticleAccessories
      }

      if (empty !== undefined) {
        localVarFormParams.set("empty", empty as any)
      }

      localVarHeaderParameter["Content-Type"] = "application/x-www-form-urlencoded"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = localVarFormParams.toString()

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Use this endpoint to search for products using [CTP\'s Product Projection Search](https://docs.commercetools.com/api/projects/products-search). You can use either the `POST` or the `GET` method for this endpoint and define various filters and facets to fine-tune search results.
     * @summary Search products. (POST)
     * @param {string} locale The locale of the product. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
     * @param {'full' | 'fullSpareParts' | 'list' | 'search' | 'fullBIM' | 'fullDCL' | 'fullETIM' | 'fullETIM7' | 'fullETIM8'} [responseSize] Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
     * @param {string} [includeArticleAccessories] Indicate whether you want the response to include article accessories. The default value is &#x60;false&#x60;.
     * @param {boolean} [empty]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findProductsPost: async (
      locale: string,
      responseSize?:
        | "full"
        | "fullSpareParts"
        | "list"
        | "search"
        | "fullBIM"
        | "fullDCL"
        | "fullETIM"
        | "fullETIM7"
        | "fullETIM8",
      includeArticleAccessories?: string,
      empty?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("findProductsPost", "locale", locale)
      const localVarPath = `/{locale}/products/search`.replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new URLSearchParams()

      if (responseSize !== undefined) {
        localVarQueryParameter["responseSize"] = responseSize
      }

      if (includeArticleAccessories !== undefined) {
        localVarQueryParameter["includeArticleAccessories"] = includeArticleAccessories
      }

      if (empty !== undefined) {
        localVarFormParams.set("empty", empty as any)
      }

      localVarHeaderParameter["Content-Type"] = "application/x-www-form-urlencoded"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = localVarFormParams.toString()

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify a product ID and locale to retrieve product data. This includes various linked assets, documents, articles, spare parts, accessories, attributes, and much more. To limit the amount of data retrieved, you can use the optional query parameter `responseSize`.
     * @summary Get a product by product ID.
     * @param {string} locale The locale of the product. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
     * @param {string} key The unique ID for the product (formerly called key).
     * @param {'full' | 'fullSpareParts' | 'list' | 'search' | 'fullBIM' | 'fullDCL' | 'fullETIM' | 'fullETIM7' | 'fullETIM8'} [responseSize] Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductByKey: async (
      locale: string,
      key: string,
      responseSize?:
        | "full"
        | "fullSpareParts"
        | "list"
        | "search"
        | "fullBIM"
        | "fullDCL"
        | "fullETIM"
        | "fullETIM7"
        | "fullETIM8",
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getProductByKey", "locale", locale)
      // verify required parameter 'key' is not null or undefined
      assertParamExists("getProductByKey", "key", key)
      const localVarPath = `/{locale}/products/{key}`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"key"}}`, encodeURIComponent(String(key)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (responseSize !== undefined) {
        localVarQueryParameter["responseSize"] = responseSize
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify a product ID, locale, and brand to retrieve product data. This includes various linked assets, documents, articles, spare parts, accessories, attributes, and much more. To limit the amount of data retrieved, you can use the optional query parameter `responseSize`.
     * @summary Get a product by product ID and brand.
     * @param {string} locale The locale of the product. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
     * @param {string} brand The brand of the product. The Product API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {string} key The unique ID for the product (formerly called key).
     * @param {'full' | 'fullSpareParts' | 'list' | 'search' | 'fullBIM' | 'fullDCL' | 'fullETIM' | 'fullETIM7' | 'fullETIM8'} [responseSize] Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductByKeyAndBrand: async (
      locale: string,
      brand: string,
      key: string,
      responseSize?:
        | "full"
        | "fullSpareParts"
        | "list"
        | "search"
        | "fullBIM"
        | "fullDCL"
        | "fullETIM"
        | "fullETIM7"
        | "fullETIM8",
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getProductByKeyAndBrand", "locale", locale)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("getProductByKeyAndBrand", "brand", brand)
      // verify required parameter 'key' is not null or undefined
      assertParamExists("getProductByKeyAndBrand", "key", key)
      const localVarPath = `/{locale}/{brand}/products/{key}`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
        .replace(`{${"key"}}`, encodeURIComponent(String(key)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (responseSize !== undefined) {
        localVarQueryParameter["responseSize"] = responseSize
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ProductApi - functional programming interface
 * @export
 */
export const ProductApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ProductApiAxiosParamCreator(configuration)
  return {
    /**
     * Use this endpoint to search for products using [CTP\'s Product Projection Search](https://docs.commercetools.com/api/projects/products-search). You can use either the `POST` or the `GET` method for this endpoint and define various filters and facets to fine-tune search results.
     * @summary Search products.
     * @param {string} locale The locale of the product. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
     * @param {Array<string>} [bleDevice] A comma-separated list of HEX values of the [Bluetooth Low Energy](https://en.wikipedia.org/wiki/Bluetooth_Low_Energy) (BLE) devices of the products you want to search for.
     * @param {Array<string>} [eanCode] A comma-separated list of EAN codes ([European Article Number](https://en.wikipedia.org/wiki/International_Article_Number)) whose parent products you want to search for.
     * @param {string} [text] The text to search for. For example, entered by a user in a website\&#39;s search field.
     * @param {string} [fuzzy] Boolean - Indicate whether to apply fuzzy search to the provided text. See the [official documentation](https://dev.commercetools.com/http-api-projects-products-search.html#fuzzy-search) to learn more. The default value is &#x60;false&#x60;.
     * @param {string} [fuzzyLevel] Integer - Set the desired fuzzy level when fuzzy search is enabled.  By default, CTP calculates and applies a value dynamically. The value you specify must not be higher than the one CTP wants to apply. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#fuzzy-search) to learn more.
     * @param {string} [filter] The filters you want to apply to the search results after facets are calculated. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#filters) to learn which filters are available and how to use them.
     * @param {string} [filterQuery] The filters you want to apply to the search results before facets are calculated. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#filters) to learn which filters are available and how to use them.
     * @param {string} [filterFacets] The filters you want to apply to all facets calculations. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#filters) to learn which filters are available and how to use them.
     * @param {string} [facets] Specify facets to retrieve a &#x60;facets&#x60; object along with your search results that contains data for use in faceted navigation. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#facets) to learn more about facets and how to use them.
     * @param {string} [sort] The sort order in which you want to retrieve the search results. By default, the results are sorted by relevance. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#sorting) to learn how to to achieve your desired sorting.
     * @param {string} [limit] The number of search results you want to retrieve, used for pagination. CTP allows values between 1 and 500 and sets the default value to 20. To avoid performance issues, we recommend setting the value no higher than 30.  For more information, see the [official documentation](https://docs.commercetools.com/api/general-concepts#paging) about paging.
     * @param {string} [offset] The number of search results to skip, used for pagination. CTP allows a maximum offset of 10000. The default value is 0.  For more information, see the [official documentation](https://docs.commercetools.com/api/general-concepts#paging) about paging.
     * @param {'full' | 'fullSpareParts' | 'list' | 'search' | 'fullBIM' | 'fullDCL' | 'fullETIM' | 'fullETIM7' | 'fullETIM8'} [responseSize] Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
     * @param {string} [includeArticleAccessories] Indicate whether you want the response to include article accessories. The default value is &#x60;false&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findProducts(
      locale: string,
      bleDevice?: Array<string>,
      eanCode?: Array<string>,
      text?: string,
      fuzzy?: string,
      fuzzyLevel?: string,
      filter?: string,
      filterQuery?: string,
      filterFacets?: string,
      facets?: string,
      sort?: string,
      limit?: string,
      offset?: string,
      responseSize?:
        | "full"
        | "fullSpareParts"
        | "list"
        | "search"
        | "fullBIM"
        | "fullDCL"
        | "fullETIM"
        | "fullETIM7"
        | "fullETIM8",
      includeArticleAccessories?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedSearchProduct>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findProducts(
        locale,
        bleDevice,
        eanCode,
        text,
        fuzzy,
        fuzzyLevel,
        filter,
        filterQuery,
        filterFacets,
        facets,
        sort,
        limit,
        offset,
        responseSize,
        includeArticleAccessories,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Use this endpoint to search for branded products using [CTP\'s Product Projection Search](https://docs.commercetools.com/api/projects/products-search). You can use either the `POST` or the `GET` method for this endpoint and define various filters and facets to fine-tune search results.
     * @summary Search branded products.
     * @param {string} locale The locale of the product. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
     * @param {string} brand The brand of the product. The Product API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {Array<string>} [bleDevice] A comma-separated list of HEX values of the [Bluetooth Low Energy](https://en.wikipedia.org/wiki/Bluetooth_Low_Energy) (BLE) devices of the products you want to search for.
     * @param {Array<string>} [eanCode] A comma-separated list of EAN codes ([European Article Number](https://en.wikipedia.org/wiki/International_Article_Number)) whose parent products you want to search for.
     * @param {string} [text] The text to search for. For example, entered by a user in a website\&#39;s search field.
     * @param {string} [fuzzy] Boolean - Indicate whether to apply fuzzy search to the provided text. See the [official documentation](https://dev.commercetools.com/http-api-projects-products-search.html#fuzzy-search) to learn more. The default value is &#x60;false&#x60;.
     * @param {string} [fuzzyLevel] Integer - Set the desired fuzzy level when fuzzy search is enabled.  By default, CTP calculates and applies a value dynamically. The value you specify must not be higher than the one CTP wants to apply. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#fuzzy-search) to learn more.
     * @param {string} [filter] The filters you want to apply to the search results after facets are calculated. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#filters) to learn which filters are available and how to use them.
     * @param {string} [filterQuery] The filters you want to apply to the search results before facets are calculated. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#filters) to learn which filters are available and how to use them.
     * @param {string} [filterFacets] The filters you want to apply to all facets calculations. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#filters) to learn which filters are available and how to use them.
     * @param {string} [facets] Specify facets to retrieve a &#x60;facets&#x60; object along with your search results that contains data for use in faceted navigation. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#facets) to learn more about facets and how to use them.
     * @param {string} [sort] The sort order in which you want to retrieve the search results. By default, the results are sorted by relevance. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#sorting) to learn how to to achieve your desired sorting.
     * @param {string} [limit] The number of search results you want to retrieve, used for pagination. CTP allows values between 1 and 500 and sets the default value to 20. To avoid performance issues, we recommend setting the value no higher than 30.  For more information, see the [official documentation](https://docs.commercetools.com/api/general-concepts#paging) about paging.
     * @param {string} [offset] The number of search results to skip, used for pagination. CTP allows a maximum offset of 10000. The default value is 0.  For more information, see the [official documentation](https://docs.commercetools.com/api/general-concepts#paging) about paging.
     * @param {'full' | 'fullSpareParts' | 'list' | 'search' | 'fullBIM' | 'fullDCL' | 'fullETIM' | 'fullETIM7' | 'fullETIM8'} [responseSize] Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
     * @param {string} [includeArticleAccessories] Indicate whether you want the response to include article accessories. The default value is &#x60;false&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findProductsByBrand(
      locale: string,
      brand: string,
      bleDevice?: Array<string>,
      eanCode?: Array<string>,
      text?: string,
      fuzzy?: string,
      fuzzyLevel?: string,
      filter?: string,
      filterQuery?: string,
      filterFacets?: string,
      facets?: string,
      sort?: string,
      limit?: string,
      offset?: string,
      responseSize?:
        | "full"
        | "fullSpareParts"
        | "list"
        | "search"
        | "fullBIM"
        | "fullDCL"
        | "fullETIM"
        | "fullETIM7"
        | "fullETIM8",
      includeArticleAccessories?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedSearchProduct>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findProductsByBrand(
        locale,
        brand,
        bleDevice,
        eanCode,
        text,
        fuzzy,
        fuzzyLevel,
        filter,
        filterQuery,
        filterFacets,
        facets,
        sort,
        limit,
        offset,
        responseSize,
        includeArticleAccessories,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Use this endpoint to search for branded products using [CTP\'s Product Projection Search](https://docs.commercetools.com/api/projects/products-search). You can use either the `POST` or the `GET` method for this endpoint and define various filters and facets to fine-tune search results.
     * @summary Search branded products. (POST)
     * @param {string} locale The locale of the product. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
     * @param {string} brand The brand of the product. The Product API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {'full' | 'fullSpareParts' | 'list' | 'search' | 'fullBIM' | 'fullDCL' | 'fullETIM' | 'fullETIM7' | 'fullETIM8'} [responseSize] Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
     * @param {string} [includeArticleAccessories] Indicate whether you want the response to include article accessories. The default value is &#x60;false&#x60;.
     * @param {boolean} [empty]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findProductsByBrandPost(
      locale: string,
      brand: string,
      responseSize?:
        | "full"
        | "fullSpareParts"
        | "list"
        | "search"
        | "fullBIM"
        | "fullDCL"
        | "fullETIM"
        | "fullETIM7"
        | "fullETIM8",
      includeArticleAccessories?: string,
      empty?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedSearchProduct>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findProductsByBrandPost(
        locale,
        brand,
        responseSize,
        includeArticleAccessories,
        empty,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Use this endpoint to search for products using [CTP\'s Product Projection Search](https://docs.commercetools.com/api/projects/products-search). You can use either the `POST` or the `GET` method for this endpoint and define various filters and facets to fine-tune search results.
     * @summary Search products. (POST)
     * @param {string} locale The locale of the product. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
     * @param {'full' | 'fullSpareParts' | 'list' | 'search' | 'fullBIM' | 'fullDCL' | 'fullETIM' | 'fullETIM7' | 'fullETIM8'} [responseSize] Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
     * @param {string} [includeArticleAccessories] Indicate whether you want the response to include article accessories. The default value is &#x60;false&#x60;.
     * @param {boolean} [empty]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findProductsPost(
      locale: string,
      responseSize?:
        | "full"
        | "fullSpareParts"
        | "list"
        | "search"
        | "fullBIM"
        | "fullDCL"
        | "fullETIM"
        | "fullETIM7"
        | "fullETIM8",
      includeArticleAccessories?: string,
      empty?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedSearchProduct>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findProductsPost(
        locale,
        responseSize,
        includeArticleAccessories,
        empty,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify a product ID and locale to retrieve product data. This includes various linked assets, documents, articles, spare parts, accessories, attributes, and much more. To limit the amount of data retrieved, you can use the optional query parameter `responseSize`.
     * @summary Get a product by product ID.
     * @param {string} locale The locale of the product. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
     * @param {string} key The unique ID for the product (formerly called key).
     * @param {'full' | 'fullSpareParts' | 'list' | 'search' | 'fullBIM' | 'fullDCL' | 'fullETIM' | 'fullETIM7' | 'fullETIM8'} [responseSize] Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProductByKey(
      locale: string,
      key: string,
      responseSize?:
        | "full"
        | "fullSpareParts"
        | "list"
        | "search"
        | "fullBIM"
        | "fullDCL"
        | "fullETIM"
        | "fullETIM7"
        | "fullETIM8",
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProductByKey(locale, key, responseSize, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify a product ID, locale, and brand to retrieve product data. This includes various linked assets, documents, articles, spare parts, accessories, attributes, and much more. To limit the amount of data retrieved, you can use the optional query parameter `responseSize`.
     * @summary Get a product by product ID and brand.
     * @param {string} locale The locale of the product. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
     * @param {string} brand The brand of the product. The Product API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {string} key The unique ID for the product (formerly called key).
     * @param {'full' | 'fullSpareParts' | 'list' | 'search' | 'fullBIM' | 'fullDCL' | 'fullETIM' | 'fullETIM7' | 'fullETIM8'} [responseSize] Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProductByKeyAndBrand(
      locale: string,
      brand: string,
      key: string,
      responseSize?:
        | "full"
        | "fullSpareParts"
        | "list"
        | "search"
        | "fullBIM"
        | "fullDCL"
        | "fullETIM"
        | "fullETIM7"
        | "fullETIM8",
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProductByKeyAndBrand(
        locale,
        brand,
        key,
        responseSize,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * ProductApi - factory interface
 * @export
 */
export const ProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ProductApiFp(configuration)
  return {
    /**
     * Use this endpoint to search for products using [CTP\'s Product Projection Search](https://docs.commercetools.com/api/projects/products-search). You can use either the `POST` or the `GET` method for this endpoint and define various filters and facets to fine-tune search results.
     * @summary Search products.
     * @param {ProductApiFindProductsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findProducts(
      requestParameters: ProductApiFindProductsRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<PagedSearchProduct> {
      return localVarFp
        .findProducts(
          requestParameters.locale,
          requestParameters.bleDevice,
          requestParameters.eanCode,
          requestParameters.text,
          requestParameters.fuzzy,
          requestParameters.fuzzyLevel,
          requestParameters.filter,
          requestParameters.filterQuery,
          requestParameters.filterFacets,
          requestParameters.facets,
          requestParameters.sort,
          requestParameters.limit,
          requestParameters.offset,
          requestParameters.responseSize,
          requestParameters.includeArticleAccessories,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Use this endpoint to search for branded products using [CTP\'s Product Projection Search](https://docs.commercetools.com/api/projects/products-search). You can use either the `POST` or the `GET` method for this endpoint and define various filters and facets to fine-tune search results.
     * @summary Search branded products.
     * @param {ProductApiFindProductsByBrandRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findProductsByBrand(
      requestParameters: ProductApiFindProductsByBrandRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<PagedSearchProduct> {
      return localVarFp
        .findProductsByBrand(
          requestParameters.locale,
          requestParameters.brand,
          requestParameters.bleDevice,
          requestParameters.eanCode,
          requestParameters.text,
          requestParameters.fuzzy,
          requestParameters.fuzzyLevel,
          requestParameters.filter,
          requestParameters.filterQuery,
          requestParameters.filterFacets,
          requestParameters.facets,
          requestParameters.sort,
          requestParameters.limit,
          requestParameters.offset,
          requestParameters.responseSize,
          requestParameters.includeArticleAccessories,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Use this endpoint to search for branded products using [CTP\'s Product Projection Search](https://docs.commercetools.com/api/projects/products-search). You can use either the `POST` or the `GET` method for this endpoint and define various filters and facets to fine-tune search results.
     * @summary Search branded products. (POST)
     * @param {ProductApiFindProductsByBrandPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findProductsByBrandPost(
      requestParameters: ProductApiFindProductsByBrandPostRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<PagedSearchProduct> {
      return localVarFp
        .findProductsByBrandPost(
          requestParameters.locale,
          requestParameters.brand,
          requestParameters.responseSize,
          requestParameters.includeArticleAccessories,
          requestParameters.empty,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Use this endpoint to search for products using [CTP\'s Product Projection Search](https://docs.commercetools.com/api/projects/products-search). You can use either the `POST` or the `GET` method for this endpoint and define various filters and facets to fine-tune search results.
     * @summary Search products. (POST)
     * @param {ProductApiFindProductsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findProductsPost(
      requestParameters: ProductApiFindProductsPostRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<PagedSearchProduct> {
      return localVarFp
        .findProductsPost(
          requestParameters.locale,
          requestParameters.responseSize,
          requestParameters.includeArticleAccessories,
          requestParameters.empty,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify a product ID and locale to retrieve product data. This includes various linked assets, documents, articles, spare parts, accessories, attributes, and much more. To limit the amount of data retrieved, you can use the optional query parameter `responseSize`.
     * @summary Get a product by product ID.
     * @param {ProductApiGetProductByKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductByKey(
      requestParameters: ProductApiGetProductByKeyRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<ProductModel> {
      return localVarFp
        .getProductByKey(requestParameters.locale, requestParameters.key, requestParameters.responseSize, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify a product ID, locale, and brand to retrieve product data. This includes various linked assets, documents, articles, spare parts, accessories, attributes, and much more. To limit the amount of data retrieved, you can use the optional query parameter `responseSize`.
     * @summary Get a product by product ID and brand.
     * @param {ProductApiGetProductByKeyAndBrandRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductByKeyAndBrand(
      requestParameters: ProductApiGetProductByKeyAndBrandRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<ProductModel> {
      return localVarFp
        .getProductByKeyAndBrand(
          requestParameters.locale,
          requestParameters.brand,
          requestParameters.key,
          requestParameters.responseSize,
          options
        )
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for findProducts operation in ProductApi.
 * @export
 * @interface ProductApiFindProductsRequest
 */
export interface ProductApiFindProductsRequest {
  /**
   * The locale of the product. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
   * @type {string}
   * @memberof ProductApiFindProducts
   */
  readonly locale: string

  /**
   * A comma-separated list of HEX values of the [Bluetooth Low Energy](https://en.wikipedia.org/wiki/Bluetooth_Low_Energy) (BLE) devices of the products you want to search for.
   * @type {Array<string>}
   * @memberof ProductApiFindProducts
   */
  readonly bleDevice?: Array<string>

  /**
   * A comma-separated list of EAN codes ([European Article Number](https://en.wikipedia.org/wiki/International_Article_Number)) whose parent products you want to search for.
   * @type {Array<string>}
   * @memberof ProductApiFindProducts
   */
  readonly eanCode?: Array<string>

  /**
   * The text to search for. For example, entered by a user in a website\&#39;s search field.
   * @type {string}
   * @memberof ProductApiFindProducts
   */
  readonly text?: string

  /**
   * Boolean - Indicate whether to apply fuzzy search to the provided text. See the [official documentation](https://dev.commercetools.com/http-api-projects-products-search.html#fuzzy-search) to learn more. The default value is &#x60;false&#x60;.
   * @type {string}
   * @memberof ProductApiFindProducts
   */
  readonly fuzzy?: string

  /**
   * Integer - Set the desired fuzzy level when fuzzy search is enabled.  By default, CTP calculates and applies a value dynamically. The value you specify must not be higher than the one CTP wants to apply. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#fuzzy-search) to learn more.
   * @type {string}
   * @memberof ProductApiFindProducts
   */
  readonly fuzzyLevel?: string

  /**
   * The filters you want to apply to the search results after facets are calculated. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#filters) to learn which filters are available and how to use them.
   * @type {string}
   * @memberof ProductApiFindProducts
   */
  readonly filter?: string

  /**
   * The filters you want to apply to the search results before facets are calculated. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#filters) to learn which filters are available and how to use them.
   * @type {string}
   * @memberof ProductApiFindProducts
   */
  readonly filterQuery?: string

  /**
   * The filters you want to apply to all facets calculations. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#filters) to learn which filters are available and how to use them.
   * @type {string}
   * @memberof ProductApiFindProducts
   */
  readonly filterFacets?: string

  /**
   * Specify facets to retrieve a &#x60;facets&#x60; object along with your search results that contains data for use in faceted navigation. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#facets) to learn more about facets and how to use them.
   * @type {string}
   * @memberof ProductApiFindProducts
   */
  readonly facets?: string

  /**
   * The sort order in which you want to retrieve the search results. By default, the results are sorted by relevance. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#sorting) to learn how to to achieve your desired sorting.
   * @type {string}
   * @memberof ProductApiFindProducts
   */
  readonly sort?: string

  /**
   * The number of search results you want to retrieve, used for pagination. CTP allows values between 1 and 500 and sets the default value to 20. To avoid performance issues, we recommend setting the value no higher than 30.  For more information, see the [official documentation](https://docs.commercetools.com/api/general-concepts#paging) about paging.
   * @type {string}
   * @memberof ProductApiFindProducts
   */
  readonly limit?: string

  /**
   * The number of search results to skip, used for pagination. CTP allows a maximum offset of 10000. The default value is 0.  For more information, see the [official documentation](https://docs.commercetools.com/api/general-concepts#paging) about paging.
   * @type {string}
   * @memberof ProductApiFindProducts
   */
  readonly offset?: string

  /**
   * Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
   * @type {'full' | 'fullSpareParts' | 'list' | 'search' | 'fullBIM' | 'fullDCL' | 'fullETIM' | 'fullETIM7' | 'fullETIM8'}
   * @memberof ProductApiFindProducts
   */
  readonly responseSize?:
    | "full"
    | "fullSpareParts"
    | "list"
    | "search"
    | "fullBIM"
    | "fullDCL"
    | "fullETIM"
    | "fullETIM7"
    | "fullETIM8"

  /**
   * Indicate whether you want the response to include article accessories. The default value is &#x60;false&#x60;.
   * @type {string}
   * @memberof ProductApiFindProducts
   */
  readonly includeArticleAccessories?: string
}

/**
 * Request parameters for findProductsByBrand operation in ProductApi.
 * @export
 * @interface ProductApiFindProductsByBrandRequest
 */
export interface ProductApiFindProductsByBrandRequest {
  /**
   * The locale of the product. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
   * @type {string}
   * @memberof ProductApiFindProductsByBrand
   */
  readonly locale: string

  /**
   * The brand of the product. The Product API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
   * @type {string}
   * @memberof ProductApiFindProductsByBrand
   */
  readonly brand: string

  /**
   * A comma-separated list of HEX values of the [Bluetooth Low Energy](https://en.wikipedia.org/wiki/Bluetooth_Low_Energy) (BLE) devices of the products you want to search for.
   * @type {Array<string>}
   * @memberof ProductApiFindProductsByBrand
   */
  readonly bleDevice?: Array<string>

  /**
   * A comma-separated list of EAN codes ([European Article Number](https://en.wikipedia.org/wiki/International_Article_Number)) whose parent products you want to search for.
   * @type {Array<string>}
   * @memberof ProductApiFindProductsByBrand
   */
  readonly eanCode?: Array<string>

  /**
   * The text to search for. For example, entered by a user in a website\&#39;s search field.
   * @type {string}
   * @memberof ProductApiFindProductsByBrand
   */
  readonly text?: string

  /**
   * Boolean - Indicate whether to apply fuzzy search to the provided text. See the [official documentation](https://dev.commercetools.com/http-api-projects-products-search.html#fuzzy-search) to learn more. The default value is &#x60;false&#x60;.
   * @type {string}
   * @memberof ProductApiFindProductsByBrand
   */
  readonly fuzzy?: string

  /**
   * Integer - Set the desired fuzzy level when fuzzy search is enabled.  By default, CTP calculates and applies a value dynamically. The value you specify must not be higher than the one CTP wants to apply. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#fuzzy-search) to learn more.
   * @type {string}
   * @memberof ProductApiFindProductsByBrand
   */
  readonly fuzzyLevel?: string

  /**
   * The filters you want to apply to the search results after facets are calculated. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#filters) to learn which filters are available and how to use them.
   * @type {string}
   * @memberof ProductApiFindProductsByBrand
   */
  readonly filter?: string

  /**
   * The filters you want to apply to the search results before facets are calculated. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#filters) to learn which filters are available and how to use them.
   * @type {string}
   * @memberof ProductApiFindProductsByBrand
   */
  readonly filterQuery?: string

  /**
   * The filters you want to apply to all facets calculations. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#filters) to learn which filters are available and how to use them.
   * @type {string}
   * @memberof ProductApiFindProductsByBrand
   */
  readonly filterFacets?: string

  /**
   * Specify facets to retrieve a &#x60;facets&#x60; object along with your search results that contains data for use in faceted navigation. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#facets) to learn more about facets and how to use them.
   * @type {string}
   * @memberof ProductApiFindProductsByBrand
   */
  readonly facets?: string

  /**
   * The sort order in which you want to retrieve the search results. By default, the results are sorted by relevance. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#sorting) to learn how to to achieve your desired sorting.
   * @type {string}
   * @memberof ProductApiFindProductsByBrand
   */
  readonly sort?: string

  /**
   * The number of search results you want to retrieve, used for pagination. CTP allows values between 1 and 500 and sets the default value to 20. To avoid performance issues, we recommend setting the value no higher than 30.  For more information, see the [official documentation](https://docs.commercetools.com/api/general-concepts#paging) about paging.
   * @type {string}
   * @memberof ProductApiFindProductsByBrand
   */
  readonly limit?: string

  /**
   * The number of search results to skip, used for pagination. CTP allows a maximum offset of 10000. The default value is 0.  For more information, see the [official documentation](https://docs.commercetools.com/api/general-concepts#paging) about paging.
   * @type {string}
   * @memberof ProductApiFindProductsByBrand
   */
  readonly offset?: string

  /**
   * Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
   * @type {'full' | 'fullSpareParts' | 'list' | 'search' | 'fullBIM' | 'fullDCL' | 'fullETIM' | 'fullETIM7' | 'fullETIM8'}
   * @memberof ProductApiFindProductsByBrand
   */
  readonly responseSize?:
    | "full"
    | "fullSpareParts"
    | "list"
    | "search"
    | "fullBIM"
    | "fullDCL"
    | "fullETIM"
    | "fullETIM7"
    | "fullETIM8"

  /**
   * Indicate whether you want the response to include article accessories. The default value is &#x60;false&#x60;.
   * @type {string}
   * @memberof ProductApiFindProductsByBrand
   */
  readonly includeArticleAccessories?: string
}

/**
 * Request parameters for findProductsByBrandPost operation in ProductApi.
 * @export
 * @interface ProductApiFindProductsByBrandPostRequest
 */
export interface ProductApiFindProductsByBrandPostRequest {
  /**
   * The locale of the product. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
   * @type {string}
   * @memberof ProductApiFindProductsByBrandPost
   */
  readonly locale: string

  /**
   * The brand of the product. The Product API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
   * @type {string}
   * @memberof ProductApiFindProductsByBrandPost
   */
  readonly brand: string

  /**
   * Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
   * @type {'full' | 'fullSpareParts' | 'list' | 'search' | 'fullBIM' | 'fullDCL' | 'fullETIM' | 'fullETIM7' | 'fullETIM8'}
   * @memberof ProductApiFindProductsByBrandPost
   */
  readonly responseSize?:
    | "full"
    | "fullSpareParts"
    | "list"
    | "search"
    | "fullBIM"
    | "fullDCL"
    | "fullETIM"
    | "fullETIM7"
    | "fullETIM8"

  /**
   * Indicate whether you want the response to include article accessories. The default value is &#x60;false&#x60;.
   * @type {string}
   * @memberof ProductApiFindProductsByBrandPost
   */
  readonly includeArticleAccessories?: string

  /**
   *
   * @type {boolean}
   * @memberof ProductApiFindProductsByBrandPost
   */
  readonly empty?: boolean
}

/**
 * Request parameters for findProductsPost operation in ProductApi.
 * @export
 * @interface ProductApiFindProductsPostRequest
 */
export interface ProductApiFindProductsPostRequest {
  /**
   * The locale of the product. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
   * @type {string}
   * @memberof ProductApiFindProductsPost
   */
  readonly locale: string

  /**
   * Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
   * @type {'full' | 'fullSpareParts' | 'list' | 'search' | 'fullBIM' | 'fullDCL' | 'fullETIM' | 'fullETIM7' | 'fullETIM8'}
   * @memberof ProductApiFindProductsPost
   */
  readonly responseSize?:
    | "full"
    | "fullSpareParts"
    | "list"
    | "search"
    | "fullBIM"
    | "fullDCL"
    | "fullETIM"
    | "fullETIM7"
    | "fullETIM8"

  /**
   * Indicate whether you want the response to include article accessories. The default value is &#x60;false&#x60;.
   * @type {string}
   * @memberof ProductApiFindProductsPost
   */
  readonly includeArticleAccessories?: string

  /**
   *
   * @type {boolean}
   * @memberof ProductApiFindProductsPost
   */
  readonly empty?: boolean
}

/**
 * Request parameters for getProductByKey operation in ProductApi.
 * @export
 * @interface ProductApiGetProductByKeyRequest
 */
export interface ProductApiGetProductByKeyRequest {
  /**
   * The locale of the product. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
   * @type {string}
   * @memberof ProductApiGetProductByKey
   */
  readonly locale: string

  /**
   * The unique ID for the product (formerly called key).
   * @type {string}
   * @memberof ProductApiGetProductByKey
   */
  readonly key: string

  /**
   * Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
   * @type {'full' | 'fullSpareParts' | 'list' | 'search' | 'fullBIM' | 'fullDCL' | 'fullETIM' | 'fullETIM7' | 'fullETIM8'}
   * @memberof ProductApiGetProductByKey
   */
  readonly responseSize?:
    | "full"
    | "fullSpareParts"
    | "list"
    | "search"
    | "fullBIM"
    | "fullDCL"
    | "fullETIM"
    | "fullETIM7"
    | "fullETIM8"
}

/**
 * Request parameters for getProductByKeyAndBrand operation in ProductApi.
 * @export
 * @interface ProductApiGetProductByKeyAndBrandRequest
 */
export interface ProductApiGetProductByKeyAndBrandRequest {
  /**
   * The locale of the product. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
   * @type {string}
   * @memberof ProductApiGetProductByKeyAndBrand
   */
  readonly locale: string

  /**
   * The brand of the product. The Product API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
   * @type {string}
   * @memberof ProductApiGetProductByKeyAndBrand
   */
  readonly brand: string

  /**
   * The unique ID for the product (formerly called key).
   * @type {string}
   * @memberof ProductApiGetProductByKeyAndBrand
   */
  readonly key: string

  /**
   * Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
   * @type {'full' | 'fullSpareParts' | 'list' | 'search' | 'fullBIM' | 'fullDCL' | 'fullETIM' | 'fullETIM7' | 'fullETIM8'}
   * @memberof ProductApiGetProductByKeyAndBrand
   */
  readonly responseSize?:
    | "full"
    | "fullSpareParts"
    | "list"
    | "search"
    | "fullBIM"
    | "fullDCL"
    | "fullETIM"
    | "fullETIM7"
    | "fullETIM8"
}

/**
 * ProductApi - object-oriented interface
 * @export
 * @class ProductApi
 * @extends {BaseAPI}
 */
export class ProductApi extends BaseAPI {
  /**
   * Use this endpoint to search for products using [CTP\'s Product Projection Search](https://docs.commercetools.com/api/projects/products-search). You can use either the `POST` or the `GET` method for this endpoint and define various filters and facets to fine-tune search results.
   * @summary Search products.
   * @param {ProductApiFindProductsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductApi
   */
  public findProducts(requestParameters: ProductApiFindProductsRequest, options?: AxiosRequestConfig) {
    return ProductApiFp(this.configuration)
      .findProducts(
        requestParameters.locale,
        requestParameters.bleDevice,
        requestParameters.eanCode,
        requestParameters.text,
        requestParameters.fuzzy,
        requestParameters.fuzzyLevel,
        requestParameters.filter,
        requestParameters.filterQuery,
        requestParameters.filterFacets,
        requestParameters.facets,
        requestParameters.sort,
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.responseSize,
        requestParameters.includeArticleAccessories,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Use this endpoint to search for branded products using [CTP\'s Product Projection Search](https://docs.commercetools.com/api/projects/products-search). You can use either the `POST` or the `GET` method for this endpoint and define various filters and facets to fine-tune search results.
   * @summary Search branded products.
   * @param {ProductApiFindProductsByBrandRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductApi
   */
  public findProductsByBrand(requestParameters: ProductApiFindProductsByBrandRequest, options?: AxiosRequestConfig) {
    return ProductApiFp(this.configuration)
      .findProductsByBrand(
        requestParameters.locale,
        requestParameters.brand,
        requestParameters.bleDevice,
        requestParameters.eanCode,
        requestParameters.text,
        requestParameters.fuzzy,
        requestParameters.fuzzyLevel,
        requestParameters.filter,
        requestParameters.filterQuery,
        requestParameters.filterFacets,
        requestParameters.facets,
        requestParameters.sort,
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.responseSize,
        requestParameters.includeArticleAccessories,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Use this endpoint to search for branded products using [CTP\'s Product Projection Search](https://docs.commercetools.com/api/projects/products-search). You can use either the `POST` or the `GET` method for this endpoint and define various filters and facets to fine-tune search results.
   * @summary Search branded products. (POST)
   * @param {ProductApiFindProductsByBrandPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductApi
   */
  public findProductsByBrandPost(
    requestParameters: ProductApiFindProductsByBrandPostRequest,
    options?: AxiosRequestConfig
  ) {
    return ProductApiFp(this.configuration)
      .findProductsByBrandPost(
        requestParameters.locale,
        requestParameters.brand,
        requestParameters.responseSize,
        requestParameters.includeArticleAccessories,
        requestParameters.empty,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Use this endpoint to search for products using [CTP\'s Product Projection Search](https://docs.commercetools.com/api/projects/products-search). You can use either the `POST` or the `GET` method for this endpoint and define various filters and facets to fine-tune search results.
   * @summary Search products. (POST)
   * @param {ProductApiFindProductsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductApi
   */
  public findProductsPost(requestParameters: ProductApiFindProductsPostRequest, options?: AxiosRequestConfig) {
    return ProductApiFp(this.configuration)
      .findProductsPost(
        requestParameters.locale,
        requestParameters.responseSize,
        requestParameters.includeArticleAccessories,
        requestParameters.empty,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify a product ID and locale to retrieve product data. This includes various linked assets, documents, articles, spare parts, accessories, attributes, and much more. To limit the amount of data retrieved, you can use the optional query parameter `responseSize`.
   * @summary Get a product by product ID.
   * @param {ProductApiGetProductByKeyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductApi
   */
  public getProductByKey(requestParameters: ProductApiGetProductByKeyRequest, options?: AxiosRequestConfig) {
    return ProductApiFp(this.configuration)
      .getProductByKey(requestParameters.locale, requestParameters.key, requestParameters.responseSize, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify a product ID, locale, and brand to retrieve product data. This includes various linked assets, documents, articles, spare parts, accessories, attributes, and much more. To limit the amount of data retrieved, you can use the optional query parameter `responseSize`.
   * @summary Get a product by product ID and brand.
   * @param {ProductApiGetProductByKeyAndBrandRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductApi
   */
  public getProductByKeyAndBrand(
    requestParameters: ProductApiGetProductByKeyAndBrandRequest,
    options?: AxiosRequestConfig
  ) {
    return ProductApiFp(this.configuration)
      .getProductByKeyAndBrand(
        requestParameters.locale,
        requestParameters.brand,
        requestParameters.key,
        requestParameters.responseSize,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * ShoppingListApi - axios parameter creator
 * @export
 */
export const ShoppingListApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Specify a shopping list\'s ID and locale to retrieve the list\'s data. This includes the shopping list\'s name and basic information about the products linked to it.
     * @summary Get a shopping list.
     * @param {string} locale The locale of the shopping list. Usually this is the locale of the online catalog in which a user created the shopping list. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
     * @param {string} id The unique identifier for the shopping list.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findShoppingList: async (locale: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("findShoppingList", "locale", locale)
      // verify required parameter 'id' is not null or undefined
      assertParamExists("findShoppingList", "id", id)
      const localVarPath = `/{locale}/shopping-lists/{id}`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify a locale and list of product IDs to retrieve product data for shopping lists in the online catalog. Note that this endpoint returns data in CTP-defined schemas, which means that the response looks different than what you may be used to from our other services.
     * @summary Get product data for shopping lists.
     * @param {string} locale The locale of the product. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
     * @param {ShoppingListDetailRequest} shoppingListDetailRequest Provide a JSON object containing a list of product IDs to retrieve the relevant product data to display in a user\&#39;s shopping list.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findShoppingListGraphQL: async (
      locale: string,
      shoppingListDetailRequest: ShoppingListDetailRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("findShoppingListGraphQL", "locale", locale)
      // verify required parameter 'shoppingListDetailRequest' is not null or undefined
      assertParamExists("findShoppingListGraphQL", "shoppingListDetailRequest", shoppingListDetailRequest)
      const localVarPath = `/{locale}/graphql/shopping-list-details`.replace(
        `{${"locale"}}`,
        encodeURIComponent(String(locale))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(
        shoppingListDetailRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify a locale and list of product IDs to obtain product data and information about product attributes for shopping lists in the online catalog. Note that this endpoint returns data in CTP-defined schemas, which means that the response looks different than what you may be used to from our other services.
     * @summary Get product data to display in a user\'s shopping list. In addition, this endpoint sends you the attribute names to display in the table header of the shopping list in the online catalog.
     * @param {string} locale The locale of the product. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
     * @param {ShoppingListDetailRequest} shoppingListDetailRequest Provide a JSON object containing a list of product IDs to retrieve the relevant product data to display in a user\&#39;s shopping list.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findShoppingListGraphQLWithHeaders: async (
      locale: string,
      shoppingListDetailRequest: ShoppingListDetailRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("findShoppingListGraphQLWithHeaders", "locale", locale)
      // verify required parameter 'shoppingListDetailRequest' is not null or undefined
      assertParamExists("findShoppingListGraphQLWithHeaders", "shoppingListDetailRequest", shoppingListDetailRequest)
      const localVarPath = `/{locale}/graphql/shopping-list-details-with-headers`.replace(
        `{${"locale"}}`,
        encodeURIComponent(String(locale))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(
        shoppingListDetailRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ShoppingListApi - functional programming interface
 * @export
 */
export const ShoppingListApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ShoppingListApiAxiosParamCreator(configuration)
  return {
    /**
     * Specify a shopping list\'s ID and locale to retrieve the list\'s data. This includes the shopping list\'s name and basic information about the products linked to it.
     * @summary Get a shopping list.
     * @param {string} locale The locale of the shopping list. Usually this is the locale of the online catalog in which a user created the shopping list. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
     * @param {string} id The unique identifier for the shopping list.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findShoppingList(
      locale: string,
      id: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShoppingListDefinition>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findShoppingList(locale, id, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify a locale and list of product IDs to retrieve product data for shopping lists in the online catalog. Note that this endpoint returns data in CTP-defined schemas, which means that the response looks different than what you may be used to from our other services.
     * @summary Get product data for shopping lists.
     * @param {string} locale The locale of the product. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
     * @param {ShoppingListDetailRequest} shoppingListDetailRequest Provide a JSON object containing a list of product IDs to retrieve the relevant product data to display in a user\&#39;s shopping list.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findShoppingListGraphQL(
      locale: string,
      shoppingListDetailRequest: ShoppingListDetailRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShoppingListDetailGraphQL>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findShoppingListGraphQL(
        locale,
        shoppingListDetailRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify a locale and list of product IDs to obtain product data and information about product attributes for shopping lists in the online catalog. Note that this endpoint returns data in CTP-defined schemas, which means that the response looks different than what you may be used to from our other services.
     * @summary Get product data to display in a user\'s shopping list. In addition, this endpoint sends you the attribute names to display in the table header of the shopping list in the online catalog.
     * @param {string} locale The locale of the product. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
     * @param {ShoppingListDetailRequest} shoppingListDetailRequest Provide a JSON object containing a list of product IDs to retrieve the relevant product data to display in a user\&#39;s shopping list.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findShoppingListGraphQLWithHeaders(
      locale: string,
      shoppingListDetailRequest: ShoppingListDetailRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShoppingListDetailGraphQL>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findShoppingListGraphQLWithHeaders(
        locale,
        shoppingListDetailRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * ShoppingListApi - factory interface
 * @export
 */
export const ShoppingListApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ShoppingListApiFp(configuration)
  return {
    /**
     * Specify a shopping list\'s ID and locale to retrieve the list\'s data. This includes the shopping list\'s name and basic information about the products linked to it.
     * @summary Get a shopping list.
     * @param {ShoppingListApiFindShoppingListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findShoppingList(
      requestParameters: ShoppingListApiFindShoppingListRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<ShoppingListDefinition> {
      return localVarFp
        .findShoppingList(requestParameters.locale, requestParameters.id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify a locale and list of product IDs to retrieve product data for shopping lists in the online catalog. Note that this endpoint returns data in CTP-defined schemas, which means that the response looks different than what you may be used to from our other services.
     * @summary Get product data for shopping lists.
     * @param {ShoppingListApiFindShoppingListGraphQLRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findShoppingListGraphQL(
      requestParameters: ShoppingListApiFindShoppingListGraphQLRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<ShoppingListDetailGraphQL> {
      return localVarFp
        .findShoppingListGraphQL(requestParameters.locale, requestParameters.shoppingListDetailRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify a locale and list of product IDs to obtain product data and information about product attributes for shopping lists in the online catalog. Note that this endpoint returns data in CTP-defined schemas, which means that the response looks different than what you may be used to from our other services.
     * @summary Get product data to display in a user\'s shopping list. In addition, this endpoint sends you the attribute names to display in the table header of the shopping list in the online catalog.
     * @param {ShoppingListApiFindShoppingListGraphQLWithHeadersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findShoppingListGraphQLWithHeaders(
      requestParameters: ShoppingListApiFindShoppingListGraphQLWithHeadersRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<ShoppingListDetailGraphQL> {
      return localVarFp
        .findShoppingListGraphQLWithHeaders(
          requestParameters.locale,
          requestParameters.shoppingListDetailRequest,
          options
        )
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for findShoppingList operation in ShoppingListApi.
 * @export
 * @interface ShoppingListApiFindShoppingListRequest
 */
export interface ShoppingListApiFindShoppingListRequest {
  /**
   * The locale of the shopping list. Usually this is the locale of the online catalog in which a user created the shopping list. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
   * @type {string}
   * @memberof ShoppingListApiFindShoppingList
   */
  readonly locale: string

  /**
   * The unique identifier for the shopping list.
   * @type {string}
   * @memberof ShoppingListApiFindShoppingList
   */
  readonly id: string
}

/**
 * Request parameters for findShoppingListGraphQL operation in ShoppingListApi.
 * @export
 * @interface ShoppingListApiFindShoppingListGraphQLRequest
 */
export interface ShoppingListApiFindShoppingListGraphQLRequest {
  /**
   * The locale of the product. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
   * @type {string}
   * @memberof ShoppingListApiFindShoppingListGraphQL
   */
  readonly locale: string

  /**
   * Provide a JSON object containing a list of product IDs to retrieve the relevant product data to display in a user\&#39;s shopping list.
   * @type {ShoppingListDetailRequest}
   * @memberof ShoppingListApiFindShoppingListGraphQL
   */
  readonly shoppingListDetailRequest: ShoppingListDetailRequest
}

/**
 * Request parameters for findShoppingListGraphQLWithHeaders operation in ShoppingListApi.
 * @export
 * @interface ShoppingListApiFindShoppingListGraphQLWithHeadersRequest
 */
export interface ShoppingListApiFindShoppingListGraphQLWithHeadersRequest {
  /**
   * The locale of the product. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
   * @type {string}
   * @memberof ShoppingListApiFindShoppingListGraphQLWithHeaders
   */
  readonly locale: string

  /**
   * Provide a JSON object containing a list of product IDs to retrieve the relevant product data to display in a user\&#39;s shopping list.
   * @type {ShoppingListDetailRequest}
   * @memberof ShoppingListApiFindShoppingListGraphQLWithHeaders
   */
  readonly shoppingListDetailRequest: ShoppingListDetailRequest
}

/**
 * ShoppingListApi - object-oriented interface
 * @export
 * @class ShoppingListApi
 * @extends {BaseAPI}
 */
export class ShoppingListApi extends BaseAPI {
  /**
   * Specify a shopping list\'s ID and locale to retrieve the list\'s data. This includes the shopping list\'s name and basic information about the products linked to it.
   * @summary Get a shopping list.
   * @param {ShoppingListApiFindShoppingListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ShoppingListApi
   */
  public findShoppingList(requestParameters: ShoppingListApiFindShoppingListRequest, options?: AxiosRequestConfig) {
    return ShoppingListApiFp(this.configuration)
      .findShoppingList(requestParameters.locale, requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify a locale and list of product IDs to retrieve product data for shopping lists in the online catalog. Note that this endpoint returns data in CTP-defined schemas, which means that the response looks different than what you may be used to from our other services.
   * @summary Get product data for shopping lists.
   * @param {ShoppingListApiFindShoppingListGraphQLRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ShoppingListApi
   */
  public findShoppingListGraphQL(
    requestParameters: ShoppingListApiFindShoppingListGraphQLRequest,
    options?: AxiosRequestConfig
  ) {
    return ShoppingListApiFp(this.configuration)
      .findShoppingListGraphQL(requestParameters.locale, requestParameters.shoppingListDetailRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify a locale and list of product IDs to obtain product data and information about product attributes for shopping lists in the online catalog. Note that this endpoint returns data in CTP-defined schemas, which means that the response looks different than what you may be used to from our other services.
   * @summary Get product data to display in a user\'s shopping list. In addition, this endpoint sends you the attribute names to display in the table header of the shopping list in the online catalog.
   * @param {ShoppingListApiFindShoppingListGraphQLWithHeadersRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ShoppingListApi
   */
  public findShoppingListGraphQLWithHeaders(
    requestParameters: ShoppingListApiFindShoppingListGraphQLWithHeadersRequest,
    options?: AxiosRequestConfig
  ) {
    return ShoppingListApiFp(this.configuration)
      .findShoppingListGraphQLWithHeaders(
        requestParameters.locale,
        requestParameters.shoppingListDetailRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * SparePartsApi - axios parameter creator
 * @export
 */
export const SparePartsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Use this endpoint to search for spare parts using [CTP\'s Product Projection Search](https://docs.commercetools.com/api/projects/products-search). You can use either the `POST` or the `GET` method for this endpoint and define various filters and facets to fine-tune search results.
     * @summary Search spare parts.
     * @param {string} locale The locale of the spare part. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
     * @param {string} [responseSize] Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
     * @param {string} [text] The text to search for. For example, entered by a user in a website\&#39;s search field.
     * @param {string} [fuzzy] Boolean - Indicate whether to apply fuzzy search to the provided text. See the [official documentation](https://dev.commercetools.com/http-api-projects-products-search.html#fuzzy-search) to learn more. The default value is &#x60;false&#x60;.
     * @param {string} [fuzzyLevel] Integer - Set the desired fuzzy level when fuzzy search is enabled.  By default, CTP calculates and applies a value dynamically. The value you specify must not be higher than the one CTP wants to apply. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#fuzzy-search) to learn more.
     * @param {string} [filter] The filters you want to apply to the search results after facets are calculated. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#filters) to learn which filters are available and how to use them.
     * @param {string} [filterQuery] The filters you want to apply to the search results before facets are calculated. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#filters) to learn which filters are available and how to use them.
     * @param {string} [filterFacets] The filters you want to apply to all facets calculations. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#filters) to learn which filters are available and how to use them.
     * @param {string} [facets] Specify facets to retrieve a &#x60;facets&#x60; object along with your search results that contains data for use in faceted navigation. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#facets) to learn more about facets and how to use them.
     * @param {string} [sort] The sort order in which you want to retrieve the search results. By default, the results are sorted by relevance. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#sorting) to learn how to to achieve your desired sorting.
     * @param {string} [limit] The number of search results you want to retrieve, used for pagination. CTP allows values between 1 and 500 and sets the default value to 20. To avoid performance issues, we recommend setting the value no higher than 30.  For more information, see the [official documentation](https://docs.commercetools.com/api/general-concepts#paging) about paging.
     * @param {string} [offset] The number of search results to skip, used for pagination. CTP allows a maximum offset of 10000. The default value is 0.  For more information, see the [official documentation](https://docs.commercetools.com/api/general-concepts#paging) about paging.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findSpareParts: async (
      locale: string,
      responseSize?: string,
      text?: string,
      fuzzy?: string,
      fuzzyLevel?: string,
      filter?: string,
      filterQuery?: string,
      filterFacets?: string,
      facets?: string,
      sort?: string,
      limit?: string,
      offset?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("findSpareParts", "locale", locale)
      const localVarPath = `/{locale}/spare-parts/search`.replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (responseSize !== undefined) {
        localVarQueryParameter["responseSize"] = responseSize
      }

      if (text !== undefined) {
        localVarQueryParameter["text"] = text
      }

      if (fuzzy !== undefined) {
        localVarQueryParameter["fuzzy"] = fuzzy
      }

      if (fuzzyLevel !== undefined) {
        localVarQueryParameter["fuzzyLevel"] = fuzzyLevel
      }

      if (filter !== undefined) {
        localVarQueryParameter["filter"] = filter
      }

      if (filterQuery !== undefined) {
        localVarQueryParameter["filter.query"] = filterQuery
      }

      if (filterFacets !== undefined) {
        localVarQueryParameter["filter.facets"] = filterFacets
      }

      if (facets !== undefined) {
        localVarQueryParameter["facets"] = facets
      }

      if (sort !== undefined) {
        localVarQueryParameter["sort"] = sort
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Use this endpoint to search for branded spare parts using [CTP\'s Product Projection Search](https://docs.commercetools.com/api/projects/products-search). You can use either the `POST` or the `GET` method for this endpoint and define various filters and facets to fine-tune search results.
     * @summary Search branded spare parts.
     * @param {string} locale The locale of the spare part. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
     * @param {string} brand The brand of the spare part. The Product API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {MultiValueMapStringString} paramMap
     * @param {string} [text] The text to search for. For example, entered by a user in a website\&#39;s search field.
     * @param {string} [fuzzy] Boolean - Indicate whether to apply fuzzy search to the provided text. See the [official documentation](https://dev.commercetools.com/http-api-projects-products-search.html#fuzzy-search) to learn more. The default value is &#x60;false&#x60;.
     * @param {string} [fuzzyLevel] Integer - Set the desired fuzzy level when fuzzy search is enabled.  By default, CTP calculates and applies a value dynamically. The value you specify must not be higher than the one CTP wants to apply. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#fuzzy-search) to learn more.
     * @param {string} [filter] The filters you want to apply to the search results after facets are calculated. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#filters) to learn which filters are available and how to use them.
     * @param {string} [filterQuery] The filters you want to apply to the search results before facets are calculated. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#filters) to learn which filters are available and how to use them.
     * @param {string} [filterFacets] The filters you want to apply to all facets calculations. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#filters) to learn which filters are available and how to use them.
     * @param {string} [facets] Specify facets to retrieve a &#x60;facets&#x60; object along with your search results that contains data for use in faceted navigation. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#facets) to learn more about facets and how to use them.
     * @param {string} [sort] The sort order in which you want to retrieve the search results. By default, the results are sorted by relevance. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#sorting) to learn how to to achieve your desired sorting.
     * @param {string} [limit] The number of search results you want to retrieve, used for pagination. CTP allows values between 1 and 500 and sets the default value to 20. To avoid performance issues, we recommend setting the value no higher than 30.  For more information, see the [official documentation](https://docs.commercetools.com/api/general-concepts#paging) about paging.
     * @param {string} [offset] The number of search results to skip, used for pagination. CTP allows a maximum offset of 10000. The default value is 0.  For more information, see the [official documentation](https://docs.commercetools.com/api/general-concepts#paging) about paging.
     * @param {'full' | 'fullSpareParts' | 'list' | 'search' | 'fullBIM' | 'fullDCL' | 'fullETIM' | 'fullETIM7' | 'fullETIM8'} [responseSize] Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findSparePartsByBrand: async (
      locale: string,
      brand: string,
      paramMap: MultiValueMapStringString,
      text?: string,
      fuzzy?: string,
      fuzzyLevel?: string,
      filter?: string,
      filterQuery?: string,
      filterFacets?: string,
      facets?: string,
      sort?: string,
      limit?: string,
      offset?: string,
      responseSize?:
        | "full"
        | "fullSpareParts"
        | "list"
        | "search"
        | "fullBIM"
        | "fullDCL"
        | "fullETIM"
        | "fullETIM7"
        | "fullETIM8",
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("findSparePartsByBrand", "locale", locale)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("findSparePartsByBrand", "brand", brand)
      // verify required parameter 'paramMap' is not null or undefined
      assertParamExists("findSparePartsByBrand", "paramMap", paramMap)
      const localVarPath = `/{locale}/{brand}/spare-parts/search`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (text !== undefined) {
        localVarQueryParameter["text"] = text
      }

      if (fuzzy !== undefined) {
        localVarQueryParameter["fuzzy"] = fuzzy
      }

      if (fuzzyLevel !== undefined) {
        localVarQueryParameter["fuzzyLevel"] = fuzzyLevel
      }

      if (filter !== undefined) {
        localVarQueryParameter["filter"] = filter
      }

      if (filterQuery !== undefined) {
        localVarQueryParameter["filter.query"] = filterQuery
      }

      if (filterFacets !== undefined) {
        localVarQueryParameter["filter.facets"] = filterFacets
      }

      if (facets !== undefined) {
        localVarQueryParameter["facets"] = facets
      }

      if (sort !== undefined) {
        localVarQueryParameter["sort"] = sort
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset
      }

      if (responseSize !== undefined) {
        localVarQueryParameter["responseSize"] = responseSize
      }

      if (paramMap !== undefined) {
        localVarQueryParameter["paramMap"] = paramMap
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Use this endpoint to search for branded spare parts using [CTP\'s Product Projection Search](https://docs.commercetools.com/api/projects/products-search). You can use either the `POST` or the `GET` method for this endpoint and define various filters and facets to fine-tune search results.
     * @summary Search branded spare parts. (POST)
     * @param {string} locale The locale of the spare part. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
     * @param {string} brand The brand of the spare part. The Product API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {'full' | 'fullSpareParts' | 'list' | 'search' | 'fullBIM' | 'fullDCL' | 'fullETIM' | 'fullETIM7' | 'fullETIM8'} [responseSize] Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
     * @param {boolean} [empty]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findSparePartsByBrandPost: async (
      locale: string,
      brand: string,
      responseSize?:
        | "full"
        | "fullSpareParts"
        | "list"
        | "search"
        | "fullBIM"
        | "fullDCL"
        | "fullETIM"
        | "fullETIM7"
        | "fullETIM8",
      empty?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("findSparePartsByBrandPost", "locale", locale)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("findSparePartsByBrandPost", "brand", brand)
      const localVarPath = `/{locale}/{brand}/spare-parts/search`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new URLSearchParams()

      if (responseSize !== undefined) {
        localVarQueryParameter["responseSize"] = responseSize
      }

      if (empty !== undefined) {
        localVarFormParams.set("empty", empty as any)
      }

      localVarHeaderParameter["Content-Type"] = "application/x-www-form-urlencoded"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = localVarFormParams.toString()

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Use this endpoint to search for spare parts using [CTP\'s Product Projection Search](https://docs.commercetools.com/api/projects/products-search). You can use either `POST` or `GET` method for this endpoint and define various filters and facets to fine-tune search results.
     * @summary Search spare parts. (POST)
     * @param {string} locale The locale of the spare part. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
     * @param {'full' | 'fullSpareParts' | 'list' | 'search' | 'fullBIM' | 'fullDCL' | 'fullETIM' | 'fullETIM7' | 'fullETIM8'} [responseSize] Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
     * @param {boolean} [empty]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findSparePartsPost: async (
      locale: string,
      responseSize?:
        | "full"
        | "fullSpareParts"
        | "list"
        | "search"
        | "fullBIM"
        | "fullDCL"
        | "fullETIM"
        | "fullETIM7"
        | "fullETIM8",
      empty?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("findSparePartsPost", "locale", locale)
      const localVarPath = `/{locale}/spare-parts/search`.replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new URLSearchParams()

      if (responseSize !== undefined) {
        localVarQueryParameter["responseSize"] = responseSize
      }

      if (empty !== undefined) {
        localVarFormParams.set("empty", empty as any)
      }

      localVarHeaderParameter["Content-Type"] = "application/x-www-form-urlencoded"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = localVarFormParams.toString()

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify a spare part ID and locale to retrieve spare part data. This includes various linked assets, documents, articles, attributes, and much more. To limit the amount of data retrieved, you can use the optional query parameter `responseSize`.
     * @summary Get a spare part by ID.
     * @param {string} locale The locale of the spare part. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
     * @param {string} key The unique ID for the spare part (formerly called key).
     * @param {'full' | 'fullSpareParts' | 'list' | 'search' | 'fullBIM' | 'fullDCL' | 'fullETIM' | 'fullETIM7' | 'fullETIM8'} [responseSize] Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSparePartByKey: async (
      locale: string,
      key: string,
      responseSize?:
        | "full"
        | "fullSpareParts"
        | "list"
        | "search"
        | "fullBIM"
        | "fullDCL"
        | "fullETIM"
        | "fullETIM7"
        | "fullETIM8",
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getSparePartByKey", "locale", locale)
      // verify required parameter 'key' is not null or undefined
      assertParamExists("getSparePartByKey", "key", key)
      const localVarPath = `/{locale}/spare-parts/{key}`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"key"}}`, encodeURIComponent(String(key)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (responseSize !== undefined) {
        localVarQueryParameter["responseSize"] = responseSize
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify a spare part ID, locale, and brand to retrieve spare part data. This includes various linked assets, documents, articles, attributes, and much more. To limit the amount of data retrieved, you can use the optional query parameter `responseSize`.
     * @summary Get a spare part by ID and brand.
     * @param {string} locale The locale of the spare part. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
     * @param {string} brand The brand of the spare part. The Product API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {string} key The unique ID for the spare part (formerly called key).
     * @param {'full' | 'fullSpareParts' | 'list' | 'search' | 'fullBIM' | 'fullDCL' | 'fullETIM' | 'fullETIM7' | 'fullETIM8'} [responseSize] Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSparePartsByKeyAndBrand: async (
      locale: string,
      brand: string,
      key: string,
      responseSize?:
        | "full"
        | "fullSpareParts"
        | "list"
        | "search"
        | "fullBIM"
        | "fullDCL"
        | "fullETIM"
        | "fullETIM7"
        | "fullETIM8",
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getSparePartsByKeyAndBrand", "locale", locale)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("getSparePartsByKeyAndBrand", "brand", brand)
      // verify required parameter 'key' is not null or undefined
      assertParamExists("getSparePartsByKeyAndBrand", "key", key)
      const localVarPath = `/{locale}/{brand}/spare-parts/{key}`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
        .replace(`{${"key"}}`, encodeURIComponent(String(key)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (responseSize !== undefined) {
        localVarQueryParameter["responseSize"] = responseSize
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * SparePartsApi - functional programming interface
 * @export
 */
export const SparePartsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SparePartsApiAxiosParamCreator(configuration)
  return {
    /**
     * Use this endpoint to search for spare parts using [CTP\'s Product Projection Search](https://docs.commercetools.com/api/projects/products-search). You can use either the `POST` or the `GET` method for this endpoint and define various filters and facets to fine-tune search results.
     * @summary Search spare parts.
     * @param {string} locale The locale of the spare part. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
     * @param {string} [responseSize] Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
     * @param {string} [text] The text to search for. For example, entered by a user in a website\&#39;s search field.
     * @param {string} [fuzzy] Boolean - Indicate whether to apply fuzzy search to the provided text. See the [official documentation](https://dev.commercetools.com/http-api-projects-products-search.html#fuzzy-search) to learn more. The default value is &#x60;false&#x60;.
     * @param {string} [fuzzyLevel] Integer - Set the desired fuzzy level when fuzzy search is enabled.  By default, CTP calculates and applies a value dynamically. The value you specify must not be higher than the one CTP wants to apply. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#fuzzy-search) to learn more.
     * @param {string} [filter] The filters you want to apply to the search results after facets are calculated. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#filters) to learn which filters are available and how to use them.
     * @param {string} [filterQuery] The filters you want to apply to the search results before facets are calculated. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#filters) to learn which filters are available and how to use them.
     * @param {string} [filterFacets] The filters you want to apply to all facets calculations. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#filters) to learn which filters are available and how to use them.
     * @param {string} [facets] Specify facets to retrieve a &#x60;facets&#x60; object along with your search results that contains data for use in faceted navigation. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#facets) to learn more about facets and how to use them.
     * @param {string} [sort] The sort order in which you want to retrieve the search results. By default, the results are sorted by relevance. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#sorting) to learn how to to achieve your desired sorting.
     * @param {string} [limit] The number of search results you want to retrieve, used for pagination. CTP allows values between 1 and 500 and sets the default value to 20. To avoid performance issues, we recommend setting the value no higher than 30.  For more information, see the [official documentation](https://docs.commercetools.com/api/general-concepts#paging) about paging.
     * @param {string} [offset] The number of search results to skip, used for pagination. CTP allows a maximum offset of 10000. The default value is 0.  For more information, see the [official documentation](https://docs.commercetools.com/api/general-concepts#paging) about paging.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findSpareParts(
      locale: string,
      responseSize?: string,
      text?: string,
      fuzzy?: string,
      fuzzyLevel?: string,
      filter?: string,
      filterQuery?: string,
      filterFacets?: string,
      facets?: string,
      sort?: string,
      limit?: string,
      offset?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedSearchSpareParts>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findSpareParts(
        locale,
        responseSize,
        text,
        fuzzy,
        fuzzyLevel,
        filter,
        filterQuery,
        filterFacets,
        facets,
        sort,
        limit,
        offset,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Use this endpoint to search for branded spare parts using [CTP\'s Product Projection Search](https://docs.commercetools.com/api/projects/products-search). You can use either the `POST` or the `GET` method for this endpoint and define various filters and facets to fine-tune search results.
     * @summary Search branded spare parts.
     * @param {string} locale The locale of the spare part. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
     * @param {string} brand The brand of the spare part. The Product API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {MultiValueMapStringString} paramMap
     * @param {string} [text] The text to search for. For example, entered by a user in a website\&#39;s search field.
     * @param {string} [fuzzy] Boolean - Indicate whether to apply fuzzy search to the provided text. See the [official documentation](https://dev.commercetools.com/http-api-projects-products-search.html#fuzzy-search) to learn more. The default value is &#x60;false&#x60;.
     * @param {string} [fuzzyLevel] Integer - Set the desired fuzzy level when fuzzy search is enabled.  By default, CTP calculates and applies a value dynamically. The value you specify must not be higher than the one CTP wants to apply. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#fuzzy-search) to learn more.
     * @param {string} [filter] The filters you want to apply to the search results after facets are calculated. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#filters) to learn which filters are available and how to use them.
     * @param {string} [filterQuery] The filters you want to apply to the search results before facets are calculated. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#filters) to learn which filters are available and how to use them.
     * @param {string} [filterFacets] The filters you want to apply to all facets calculations. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#filters) to learn which filters are available and how to use them.
     * @param {string} [facets] Specify facets to retrieve a &#x60;facets&#x60; object along with your search results that contains data for use in faceted navigation. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#facets) to learn more about facets and how to use them.
     * @param {string} [sort] The sort order in which you want to retrieve the search results. By default, the results are sorted by relevance. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#sorting) to learn how to to achieve your desired sorting.
     * @param {string} [limit] The number of search results you want to retrieve, used for pagination. CTP allows values between 1 and 500 and sets the default value to 20. To avoid performance issues, we recommend setting the value no higher than 30.  For more information, see the [official documentation](https://docs.commercetools.com/api/general-concepts#paging) about paging.
     * @param {string} [offset] The number of search results to skip, used for pagination. CTP allows a maximum offset of 10000. The default value is 0.  For more information, see the [official documentation](https://docs.commercetools.com/api/general-concepts#paging) about paging.
     * @param {'full' | 'fullSpareParts' | 'list' | 'search' | 'fullBIM' | 'fullDCL' | 'fullETIM' | 'fullETIM7' | 'fullETIM8'} [responseSize] Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findSparePartsByBrand(
      locale: string,
      brand: string,
      paramMap: MultiValueMapStringString,
      text?: string,
      fuzzy?: string,
      fuzzyLevel?: string,
      filter?: string,
      filterQuery?: string,
      filterFacets?: string,
      facets?: string,
      sort?: string,
      limit?: string,
      offset?: string,
      responseSize?:
        | "full"
        | "fullSpareParts"
        | "list"
        | "search"
        | "fullBIM"
        | "fullDCL"
        | "fullETIM"
        | "fullETIM7"
        | "fullETIM8",
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedSearchSpareParts>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findSparePartsByBrand(
        locale,
        brand,
        paramMap,
        text,
        fuzzy,
        fuzzyLevel,
        filter,
        filterQuery,
        filterFacets,
        facets,
        sort,
        limit,
        offset,
        responseSize,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Use this endpoint to search for branded spare parts using [CTP\'s Product Projection Search](https://docs.commercetools.com/api/projects/products-search). You can use either the `POST` or the `GET` method for this endpoint and define various filters and facets to fine-tune search results.
     * @summary Search branded spare parts. (POST)
     * @param {string} locale The locale of the spare part. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
     * @param {string} brand The brand of the spare part. The Product API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {'full' | 'fullSpareParts' | 'list' | 'search' | 'fullBIM' | 'fullDCL' | 'fullETIM' | 'fullETIM7' | 'fullETIM8'} [responseSize] Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
     * @param {boolean} [empty]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findSparePartsByBrandPost(
      locale: string,
      brand: string,
      responseSize?:
        | "full"
        | "fullSpareParts"
        | "list"
        | "search"
        | "fullBIM"
        | "fullDCL"
        | "fullETIM"
        | "fullETIM7"
        | "fullETIM8",
      empty?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedSearchSpareParts>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findSparePartsByBrandPost(
        locale,
        brand,
        responseSize,
        empty,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Use this endpoint to search for spare parts using [CTP\'s Product Projection Search](https://docs.commercetools.com/api/projects/products-search). You can use either `POST` or `GET` method for this endpoint and define various filters and facets to fine-tune search results.
     * @summary Search spare parts. (POST)
     * @param {string} locale The locale of the spare part. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
     * @param {'full' | 'fullSpareParts' | 'list' | 'search' | 'fullBIM' | 'fullDCL' | 'fullETIM' | 'fullETIM7' | 'fullETIM8'} [responseSize] Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
     * @param {boolean} [empty]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findSparePartsPost(
      locale: string,
      responseSize?:
        | "full"
        | "fullSpareParts"
        | "list"
        | "search"
        | "fullBIM"
        | "fullDCL"
        | "fullETIM"
        | "fullETIM7"
        | "fullETIM8",
      empty?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedSearchSpareParts>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findSparePartsPost(locale, responseSize, empty, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify a spare part ID and locale to retrieve spare part data. This includes various linked assets, documents, articles, attributes, and much more. To limit the amount of data retrieved, you can use the optional query parameter `responseSize`.
     * @summary Get a spare part by ID.
     * @param {string} locale The locale of the spare part. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
     * @param {string} key The unique ID for the spare part (formerly called key).
     * @param {'full' | 'fullSpareParts' | 'list' | 'search' | 'fullBIM' | 'fullDCL' | 'fullETIM' | 'fullETIM7' | 'fullETIM8'} [responseSize] Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSparePartByKey(
      locale: string,
      key: string,
      responseSize?:
        | "full"
        | "fullSpareParts"
        | "list"
        | "search"
        | "fullBIM"
        | "fullDCL"
        | "fullETIM"
        | "fullETIM7"
        | "fullETIM8",
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SparePartProductModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSparePartByKey(locale, key, responseSize, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify a spare part ID, locale, and brand to retrieve spare part data. This includes various linked assets, documents, articles, attributes, and much more. To limit the amount of data retrieved, you can use the optional query parameter `responseSize`.
     * @summary Get a spare part by ID and brand.
     * @param {string} locale The locale of the spare part. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
     * @param {string} brand The brand of the spare part. The Product API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {string} key The unique ID for the spare part (formerly called key).
     * @param {'full' | 'fullSpareParts' | 'list' | 'search' | 'fullBIM' | 'fullDCL' | 'fullETIM' | 'fullETIM7' | 'fullETIM8'} [responseSize] Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSparePartsByKeyAndBrand(
      locale: string,
      brand: string,
      key: string,
      responseSize?:
        | "full"
        | "fullSpareParts"
        | "list"
        | "search"
        | "fullBIM"
        | "fullDCL"
        | "fullETIM"
        | "fullETIM7"
        | "fullETIM8",
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SparePartProductModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSparePartsByKeyAndBrand(
        locale,
        brand,
        key,
        responseSize,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * SparePartsApi - factory interface
 * @export
 */
export const SparePartsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = SparePartsApiFp(configuration)
  return {
    /**
     * Use this endpoint to search for spare parts using [CTP\'s Product Projection Search](https://docs.commercetools.com/api/projects/products-search). You can use either the `POST` or the `GET` method for this endpoint and define various filters and facets to fine-tune search results.
     * @summary Search spare parts.
     * @param {SparePartsApiFindSparePartsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findSpareParts(
      requestParameters: SparePartsApiFindSparePartsRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<PagedSearchSpareParts> {
      return localVarFp
        .findSpareParts(
          requestParameters.locale,
          requestParameters.responseSize,
          requestParameters.text,
          requestParameters.fuzzy,
          requestParameters.fuzzyLevel,
          requestParameters.filter,
          requestParameters.filterQuery,
          requestParameters.filterFacets,
          requestParameters.facets,
          requestParameters.sort,
          requestParameters.limit,
          requestParameters.offset,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Use this endpoint to search for branded spare parts using [CTP\'s Product Projection Search](https://docs.commercetools.com/api/projects/products-search). You can use either the `POST` or the `GET` method for this endpoint and define various filters and facets to fine-tune search results.
     * @summary Search branded spare parts.
     * @param {SparePartsApiFindSparePartsByBrandRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findSparePartsByBrand(
      requestParameters: SparePartsApiFindSparePartsByBrandRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<PagedSearchSpareParts> {
      return localVarFp
        .findSparePartsByBrand(
          requestParameters.locale,
          requestParameters.brand,
          requestParameters.paramMap,
          requestParameters.text,
          requestParameters.fuzzy,
          requestParameters.fuzzyLevel,
          requestParameters.filter,
          requestParameters.filterQuery,
          requestParameters.filterFacets,
          requestParameters.facets,
          requestParameters.sort,
          requestParameters.limit,
          requestParameters.offset,
          requestParameters.responseSize,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Use this endpoint to search for branded spare parts using [CTP\'s Product Projection Search](https://docs.commercetools.com/api/projects/products-search). You can use either the `POST` or the `GET` method for this endpoint and define various filters and facets to fine-tune search results.
     * @summary Search branded spare parts. (POST)
     * @param {SparePartsApiFindSparePartsByBrandPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findSparePartsByBrandPost(
      requestParameters: SparePartsApiFindSparePartsByBrandPostRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<PagedSearchSpareParts> {
      return localVarFp
        .findSparePartsByBrandPost(
          requestParameters.locale,
          requestParameters.brand,
          requestParameters.responseSize,
          requestParameters.empty,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Use this endpoint to search for spare parts using [CTP\'s Product Projection Search](https://docs.commercetools.com/api/projects/products-search). You can use either `POST` or `GET` method for this endpoint and define various filters and facets to fine-tune search results.
     * @summary Search spare parts. (POST)
     * @param {SparePartsApiFindSparePartsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findSparePartsPost(
      requestParameters: SparePartsApiFindSparePartsPostRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<PagedSearchSpareParts> {
      return localVarFp
        .findSparePartsPost(requestParameters.locale, requestParameters.responseSize, requestParameters.empty, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify a spare part ID and locale to retrieve spare part data. This includes various linked assets, documents, articles, attributes, and much more. To limit the amount of data retrieved, you can use the optional query parameter `responseSize`.
     * @summary Get a spare part by ID.
     * @param {SparePartsApiGetSparePartByKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSparePartByKey(
      requestParameters: SparePartsApiGetSparePartByKeyRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<SparePartProductModel> {
      return localVarFp
        .getSparePartByKey(requestParameters.locale, requestParameters.key, requestParameters.responseSize, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify a spare part ID, locale, and brand to retrieve spare part data. This includes various linked assets, documents, articles, attributes, and much more. To limit the amount of data retrieved, you can use the optional query parameter `responseSize`.
     * @summary Get a spare part by ID and brand.
     * @param {SparePartsApiGetSparePartsByKeyAndBrandRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSparePartsByKeyAndBrand(
      requestParameters: SparePartsApiGetSparePartsByKeyAndBrandRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<SparePartProductModel> {
      return localVarFp
        .getSparePartsByKeyAndBrand(
          requestParameters.locale,
          requestParameters.brand,
          requestParameters.key,
          requestParameters.responseSize,
          options
        )
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for findSpareParts operation in SparePartsApi.
 * @export
 * @interface SparePartsApiFindSparePartsRequest
 */
export interface SparePartsApiFindSparePartsRequest {
  /**
   * The locale of the spare part. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
   * @type {string}
   * @memberof SparePartsApiFindSpareParts
   */
  readonly locale: string

  /**
   * Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
   * @type {string}
   * @memberof SparePartsApiFindSpareParts
   */
  readonly responseSize?: string

  /**
   * The text to search for. For example, entered by a user in a website\&#39;s search field.
   * @type {string}
   * @memberof SparePartsApiFindSpareParts
   */
  readonly text?: string

  /**
   * Boolean - Indicate whether to apply fuzzy search to the provided text. See the [official documentation](https://dev.commercetools.com/http-api-projects-products-search.html#fuzzy-search) to learn more. The default value is &#x60;false&#x60;.
   * @type {string}
   * @memberof SparePartsApiFindSpareParts
   */
  readonly fuzzy?: string

  /**
   * Integer - Set the desired fuzzy level when fuzzy search is enabled.  By default, CTP calculates and applies a value dynamically. The value you specify must not be higher than the one CTP wants to apply. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#fuzzy-search) to learn more.
   * @type {string}
   * @memberof SparePartsApiFindSpareParts
   */
  readonly fuzzyLevel?: string

  /**
   * The filters you want to apply to the search results after facets are calculated. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#filters) to learn which filters are available and how to use them.
   * @type {string}
   * @memberof SparePartsApiFindSpareParts
   */
  readonly filter?: string

  /**
   * The filters you want to apply to the search results before facets are calculated. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#filters) to learn which filters are available and how to use them.
   * @type {string}
   * @memberof SparePartsApiFindSpareParts
   */
  readonly filterQuery?: string

  /**
   * The filters you want to apply to all facets calculations. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#filters) to learn which filters are available and how to use them.
   * @type {string}
   * @memberof SparePartsApiFindSpareParts
   */
  readonly filterFacets?: string

  /**
   * Specify facets to retrieve a &#x60;facets&#x60; object along with your search results that contains data for use in faceted navigation. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#facets) to learn more about facets and how to use them.
   * @type {string}
   * @memberof SparePartsApiFindSpareParts
   */
  readonly facets?: string

  /**
   * The sort order in which you want to retrieve the search results. By default, the results are sorted by relevance. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#sorting) to learn how to to achieve your desired sorting.
   * @type {string}
   * @memberof SparePartsApiFindSpareParts
   */
  readonly sort?: string

  /**
   * The number of search results you want to retrieve, used for pagination. CTP allows values between 1 and 500 and sets the default value to 20. To avoid performance issues, we recommend setting the value no higher than 30.  For more information, see the [official documentation](https://docs.commercetools.com/api/general-concepts#paging) about paging.
   * @type {string}
   * @memberof SparePartsApiFindSpareParts
   */
  readonly limit?: string

  /**
   * The number of search results to skip, used for pagination. CTP allows a maximum offset of 10000. The default value is 0.  For more information, see the [official documentation](https://docs.commercetools.com/api/general-concepts#paging) about paging.
   * @type {string}
   * @memberof SparePartsApiFindSpareParts
   */
  readonly offset?: string
}

/**
 * Request parameters for findSparePartsByBrand operation in SparePartsApi.
 * @export
 * @interface SparePartsApiFindSparePartsByBrandRequest
 */
export interface SparePartsApiFindSparePartsByBrandRequest {
  /**
   * The locale of the spare part. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
   * @type {string}
   * @memberof SparePartsApiFindSparePartsByBrand
   */
  readonly locale: string

  /**
   * The brand of the spare part. The Product API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
   * @type {string}
   * @memberof SparePartsApiFindSparePartsByBrand
   */
  readonly brand: string

  /**
   *
   * @type {MultiValueMapStringString}
   * @memberof SparePartsApiFindSparePartsByBrand
   */
  readonly paramMap: MultiValueMapStringString

  /**
   * The text to search for. For example, entered by a user in a website\&#39;s search field.
   * @type {string}
   * @memberof SparePartsApiFindSparePartsByBrand
   */
  readonly text?: string

  /**
   * Boolean - Indicate whether to apply fuzzy search to the provided text. See the [official documentation](https://dev.commercetools.com/http-api-projects-products-search.html#fuzzy-search) to learn more. The default value is &#x60;false&#x60;.
   * @type {string}
   * @memberof SparePartsApiFindSparePartsByBrand
   */
  readonly fuzzy?: string

  /**
   * Integer - Set the desired fuzzy level when fuzzy search is enabled.  By default, CTP calculates and applies a value dynamically. The value you specify must not be higher than the one CTP wants to apply. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#fuzzy-search) to learn more.
   * @type {string}
   * @memberof SparePartsApiFindSparePartsByBrand
   */
  readonly fuzzyLevel?: string

  /**
   * The filters you want to apply to the search results after facets are calculated. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#filters) to learn which filters are available and how to use them.
   * @type {string}
   * @memberof SparePartsApiFindSparePartsByBrand
   */
  readonly filter?: string

  /**
   * The filters you want to apply to the search results before facets are calculated. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#filters) to learn which filters are available and how to use them.
   * @type {string}
   * @memberof SparePartsApiFindSparePartsByBrand
   */
  readonly filterQuery?: string

  /**
   * The filters you want to apply to all facets calculations. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#filters) to learn which filters are available and how to use them.
   * @type {string}
   * @memberof SparePartsApiFindSparePartsByBrand
   */
  readonly filterFacets?: string

  /**
   * Specify facets to retrieve a &#x60;facets&#x60; object along with your search results that contains data for use in faceted navigation. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#facets) to learn more about facets and how to use them.
   * @type {string}
   * @memberof SparePartsApiFindSparePartsByBrand
   */
  readonly facets?: string

  /**
   * The sort order in which you want to retrieve the search results. By default, the results are sorted by relevance. See the [official documentation](https://docs.commercetools.com/api/projects/products-search#sorting) to learn how to to achieve your desired sorting.
   * @type {string}
   * @memberof SparePartsApiFindSparePartsByBrand
   */
  readonly sort?: string

  /**
   * The number of search results you want to retrieve, used for pagination. CTP allows values between 1 and 500 and sets the default value to 20. To avoid performance issues, we recommend setting the value no higher than 30.  For more information, see the [official documentation](https://docs.commercetools.com/api/general-concepts#paging) about paging.
   * @type {string}
   * @memberof SparePartsApiFindSparePartsByBrand
   */
  readonly limit?: string

  /**
   * The number of search results to skip, used for pagination. CTP allows a maximum offset of 10000. The default value is 0.  For more information, see the [official documentation](https://docs.commercetools.com/api/general-concepts#paging) about paging.
   * @type {string}
   * @memberof SparePartsApiFindSparePartsByBrand
   */
  readonly offset?: string

  /**
   * Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
   * @type {'full' | 'fullSpareParts' | 'list' | 'search' | 'fullBIM' | 'fullDCL' | 'fullETIM' | 'fullETIM7' | 'fullETIM8'}
   * @memberof SparePartsApiFindSparePartsByBrand
   */
  readonly responseSize?:
    | "full"
    | "fullSpareParts"
    | "list"
    | "search"
    | "fullBIM"
    | "fullDCL"
    | "fullETIM"
    | "fullETIM7"
    | "fullETIM8"
}

/**
 * Request parameters for findSparePartsByBrandPost operation in SparePartsApi.
 * @export
 * @interface SparePartsApiFindSparePartsByBrandPostRequest
 */
export interface SparePartsApiFindSparePartsByBrandPostRequest {
  /**
   * The locale of the spare part. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
   * @type {string}
   * @memberof SparePartsApiFindSparePartsByBrandPost
   */
  readonly locale: string

  /**
   * The brand of the spare part. The Product API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
   * @type {string}
   * @memberof SparePartsApiFindSparePartsByBrandPost
   */
  readonly brand: string

  /**
   * Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
   * @type {'full' | 'fullSpareParts' | 'list' | 'search' | 'fullBIM' | 'fullDCL' | 'fullETIM' | 'fullETIM7' | 'fullETIM8'}
   * @memberof SparePartsApiFindSparePartsByBrandPost
   */
  readonly responseSize?:
    | "full"
    | "fullSpareParts"
    | "list"
    | "search"
    | "fullBIM"
    | "fullDCL"
    | "fullETIM"
    | "fullETIM7"
    | "fullETIM8"

  /**
   *
   * @type {boolean}
   * @memberof SparePartsApiFindSparePartsByBrandPost
   */
  readonly empty?: boolean
}

/**
 * Request parameters for findSparePartsPost operation in SparePartsApi.
 * @export
 * @interface SparePartsApiFindSparePartsPostRequest
 */
export interface SparePartsApiFindSparePartsPostRequest {
  /**
   * The locale of the spare part. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
   * @type {string}
   * @memberof SparePartsApiFindSparePartsPost
   */
  readonly locale: string

  /**
   * Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
   * @type {'full' | 'fullSpareParts' | 'list' | 'search' | 'fullBIM' | 'fullDCL' | 'fullETIM' | 'fullETIM7' | 'fullETIM8'}
   * @memberof SparePartsApiFindSparePartsPost
   */
  readonly responseSize?:
    | "full"
    | "fullSpareParts"
    | "list"
    | "search"
    | "fullBIM"
    | "fullDCL"
    | "fullETIM"
    | "fullETIM7"
    | "fullETIM8"

  /**
   *
   * @type {boolean}
   * @memberof SparePartsApiFindSparePartsPost
   */
  readonly empty?: boolean
}

/**
 * Request parameters for getSparePartByKey operation in SparePartsApi.
 * @export
 * @interface SparePartsApiGetSparePartByKeyRequest
 */
export interface SparePartsApiGetSparePartByKeyRequest {
  /**
   * The locale of the spare part. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
   * @type {string}
   * @memberof SparePartsApiGetSparePartByKey
   */
  readonly locale: string

  /**
   * The unique ID for the spare part (formerly called key).
   * @type {string}
   * @memberof SparePartsApiGetSparePartByKey
   */
  readonly key: string

  /**
   * Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
   * @type {'full' | 'fullSpareParts' | 'list' | 'search' | 'fullBIM' | 'fullDCL' | 'fullETIM' | 'fullETIM7' | 'fullETIM8'}
   * @memberof SparePartsApiGetSparePartByKey
   */
  readonly responseSize?:
    | "full"
    | "fullSpareParts"
    | "list"
    | "search"
    | "fullBIM"
    | "fullDCL"
    | "fullETIM"
    | "fullETIM7"
    | "fullETIM8"
}

/**
 * Request parameters for getSparePartsByKeyAndBrand operation in SparePartsApi.
 * @export
 * @interface SparePartsApiGetSparePartsByKeyAndBrandRequest
 */
export interface SparePartsApiGetSparePartsByKeyAndBrandRequest {
  /**
   * The locale of the spare part. The Product API supports all locales available in the Geberit catalog.  You can find a list of available locales in [this documentation](/locales).
   * @type {string}
   * @memberof SparePartsApiGetSparePartsByKeyAndBrand
   */
  readonly locale: string

  /**
   * The brand of the spare part. The Product API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
   * @type {string}
   * @memberof SparePartsApiGetSparePartsByKeyAndBrand
   */
  readonly brand: string

  /**
   * The unique ID for the spare part (formerly called key).
   * @type {string}
   * @memberof SparePartsApiGetSparePartsByKeyAndBrand
   */
  readonly key: string

  /**
   * Limit the endpoint\&#39;s response to a predefined set of data.  We have defined datasets for various use cases – the [ResponseSize documentation](/product-data-services/responsesize-documentation) tells you which parameter value you can use to retrieve which data.  The default value is &#x60;fullSpareParts&#x60;.
   * @type {'full' | 'fullSpareParts' | 'list' | 'search' | 'fullBIM' | 'fullDCL' | 'fullETIM' | 'fullETIM7' | 'fullETIM8'}
   * @memberof SparePartsApiGetSparePartsByKeyAndBrand
   */
  readonly responseSize?:
    | "full"
    | "fullSpareParts"
    | "list"
    | "search"
    | "fullBIM"
    | "fullDCL"
    | "fullETIM"
    | "fullETIM7"
    | "fullETIM8"
}

/**
 * SparePartsApi - object-oriented interface
 * @export
 * @class SparePartsApi
 * @extends {BaseAPI}
 */
export class SparePartsApi extends BaseAPI {
  /**
   * Use this endpoint to search for spare parts using [CTP\'s Product Projection Search](https://docs.commercetools.com/api/projects/products-search). You can use either the `POST` or the `GET` method for this endpoint and define various filters and facets to fine-tune search results.
   * @summary Search spare parts.
   * @param {SparePartsApiFindSparePartsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SparePartsApi
   */
  public findSpareParts(requestParameters: SparePartsApiFindSparePartsRequest, options?: AxiosRequestConfig) {
    return SparePartsApiFp(this.configuration)
      .findSpareParts(
        requestParameters.locale,
        requestParameters.responseSize,
        requestParameters.text,
        requestParameters.fuzzy,
        requestParameters.fuzzyLevel,
        requestParameters.filter,
        requestParameters.filterQuery,
        requestParameters.filterFacets,
        requestParameters.facets,
        requestParameters.sort,
        requestParameters.limit,
        requestParameters.offset,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Use this endpoint to search for branded spare parts using [CTP\'s Product Projection Search](https://docs.commercetools.com/api/projects/products-search). You can use either the `POST` or the `GET` method for this endpoint and define various filters and facets to fine-tune search results.
   * @summary Search branded spare parts.
   * @param {SparePartsApiFindSparePartsByBrandRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SparePartsApi
   */
  public findSparePartsByBrand(
    requestParameters: SparePartsApiFindSparePartsByBrandRequest,
    options?: AxiosRequestConfig
  ) {
    return SparePartsApiFp(this.configuration)
      .findSparePartsByBrand(
        requestParameters.locale,
        requestParameters.brand,
        requestParameters.paramMap,
        requestParameters.text,
        requestParameters.fuzzy,
        requestParameters.fuzzyLevel,
        requestParameters.filter,
        requestParameters.filterQuery,
        requestParameters.filterFacets,
        requestParameters.facets,
        requestParameters.sort,
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.responseSize,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Use this endpoint to search for branded spare parts using [CTP\'s Product Projection Search](https://docs.commercetools.com/api/projects/products-search). You can use either the `POST` or the `GET` method for this endpoint and define various filters and facets to fine-tune search results.
   * @summary Search branded spare parts. (POST)
   * @param {SparePartsApiFindSparePartsByBrandPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SparePartsApi
   */
  public findSparePartsByBrandPost(
    requestParameters: SparePartsApiFindSparePartsByBrandPostRequest,
    options?: AxiosRequestConfig
  ) {
    return SparePartsApiFp(this.configuration)
      .findSparePartsByBrandPost(
        requestParameters.locale,
        requestParameters.brand,
        requestParameters.responseSize,
        requestParameters.empty,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Use this endpoint to search for spare parts using [CTP\'s Product Projection Search](https://docs.commercetools.com/api/projects/products-search). You can use either `POST` or `GET` method for this endpoint and define various filters and facets to fine-tune search results.
   * @summary Search spare parts. (POST)
   * @param {SparePartsApiFindSparePartsPostRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SparePartsApi
   */
  public findSparePartsPost(requestParameters: SparePartsApiFindSparePartsPostRequest, options?: AxiosRequestConfig) {
    return SparePartsApiFp(this.configuration)
      .findSparePartsPost(requestParameters.locale, requestParameters.responseSize, requestParameters.empty, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify a spare part ID and locale to retrieve spare part data. This includes various linked assets, documents, articles, attributes, and much more. To limit the amount of data retrieved, you can use the optional query parameter `responseSize`.
   * @summary Get a spare part by ID.
   * @param {SparePartsApiGetSparePartByKeyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SparePartsApi
   */
  public getSparePartByKey(requestParameters: SparePartsApiGetSparePartByKeyRequest, options?: AxiosRequestConfig) {
    return SparePartsApiFp(this.configuration)
      .getSparePartByKey(requestParameters.locale, requestParameters.key, requestParameters.responseSize, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify a spare part ID, locale, and brand to retrieve spare part data. This includes various linked assets, documents, articles, attributes, and much more. To limit the amount of data retrieved, you can use the optional query parameter `responseSize`.
   * @summary Get a spare part by ID and brand.
   * @param {SparePartsApiGetSparePartsByKeyAndBrandRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SparePartsApi
   */
  public getSparePartsByKeyAndBrand(
    requestParameters: SparePartsApiGetSparePartsByKeyAndBrandRequest,
    options?: AxiosRequestConfig
  ) {
    return SparePartsApiFp(this.configuration)
      .getSparePartsByKeyAndBrand(
        requestParameters.locale,
        requestParameters.brand,
        requestParameters.key,
        requestParameters.responseSize,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * TenderTextApi - axios parameter creator
 * @export
 */
export const TenderTextApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Use the ID of an article\'s tender text to retrieve the relevant article data to include in your tender document.  > **Note:** This endpoint only provides data for the German locale `de-DE`!
     * @summary Get tender text by ID.
     * @param {'de-DE'} locale The article\&#39;s locale. Currently the only supported value is the German locale &#x60;de-DE&#x60;.
     * @param {string} id The ID of the article\&#39;s tender text.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTenderTextById: async (locale: "de-DE", id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getTenderTextById", "locale", locale)
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getTenderTextById", "id", id)
      const localVarPath = `/{locale}/tendertext/details/{id}`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * TenderTextApi - functional programming interface
 * @export
 */
export const TenderTextApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TenderTextApiAxiosParamCreator(configuration)
  return {
    /**
     * Use the ID of an article\'s tender text to retrieve the relevant article data to include in your tender document.  > **Note:** This endpoint only provides data for the German locale `de-DE`!
     * @summary Get tender text by ID.
     * @param {'de-DE'} locale The article\&#39;s locale. Currently the only supported value is the German locale &#x60;de-DE&#x60;.
     * @param {string} id The ID of the article\&#39;s tender text.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTenderTextById(
      locale: "de-DE",
      id: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenderTextProductData>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTenderTextById(locale, id, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * TenderTextApi - factory interface
 * @export
 */
export const TenderTextApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = TenderTextApiFp(configuration)
  return {
    /**
     * Use the ID of an article\'s tender text to retrieve the relevant article data to include in your tender document.  > **Note:** This endpoint only provides data for the German locale `de-DE`!
     * @summary Get tender text by ID.
     * @param {TenderTextApiGetTenderTextByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTenderTextById(
      requestParameters: TenderTextApiGetTenderTextByIdRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<TenderTextProductData> {
      return localVarFp
        .getTenderTextById(requestParameters.locale, requestParameters.id, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for getTenderTextById operation in TenderTextApi.
 * @export
 * @interface TenderTextApiGetTenderTextByIdRequest
 */
export interface TenderTextApiGetTenderTextByIdRequest {
  /**
   * The article\&#39;s locale. Currently the only supported value is the German locale &#x60;de-DE&#x60;.
   * @type {'de-DE'}
   * @memberof TenderTextApiGetTenderTextById
   */
  readonly locale: "de-DE"

  /**
   * The ID of the article\&#39;s tender text.
   * @type {string}
   * @memberof TenderTextApiGetTenderTextById
   */
  readonly id: string
}

/**
 * TenderTextApi - object-oriented interface
 * @export
 * @class TenderTextApi
 * @extends {BaseAPI}
 */
export class TenderTextApi extends BaseAPI {
  /**
   * Use the ID of an article\'s tender text to retrieve the relevant article data to include in your tender document.  > **Note:** This endpoint only provides data for the German locale `de-DE`!
   * @summary Get tender text by ID.
   * @param {TenderTextApiGetTenderTextByIdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TenderTextApi
   */
  public getTenderTextById(requestParameters: TenderTextApiGetTenderTextByIdRequest, options?: AxiosRequestConfig) {
    return TenderTextApiFp(this.configuration)
      .getTenderTextById(requestParameters.locale, requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
