import { resourceTypes } from "../constants/constants"
import { attributes } from "../constants/search-constants"
import { TileProduct } from "../types/global"
import { FhProduct } from "../types/search"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getProductTileAttributes(fhProduct: FhProduct): TileProduct {
  const name = fhProduct.attribute?.find((attr) => attr.name === attributes.NAME)
  const id = fhProduct.attribute?.find((attr) => attr.name === attributes.PRODUCT_ID)
  const isNew = fhProduct.attribute?.find((attr) => attr.name === attributes.IS_NEW)
  const isPhasedOut = fhProduct.attribute?.find((attr) => attr.name === attributes.PHASE_OUT_INDICATOR)
  const category = fhProduct.attribute?.find((attr) => attr.name === attributes.SYSTEMS_CATEGORY)
  const image = fhProduct.attribute?.find((attr) => attr.name === attributes.IMAGE)
  const articles = fhProduct.attribute?.find((attr) => attr.name === attributes.ALL_ARTICLES)
  const articlesCount = articles?.value?.[0].value.split(";")?.length ?? 0
  const articleNumber = fhProduct.attribute?.find((attr) => attr.name === attributes.ARTICLE_NUMBER)
  const series = fhProduct.attribute?.find((attr) => attr.name === attributes.SERIES)
  const availableFrom = fhProduct.attribute?.find((attr) => attr.name === attributes.MARKET_LAUNCH_DATE)

  return {
    name: name?.value?.[0]?.value ?? "",
    category: category?.value?.[0]?.value ?? "",
    id: id?.value?.[0]?.value ?? "",
    image: image?.value?.[0]?.value ?? "",
    isNew: isNew?.value?.[0]?.value === "1",
    isPhasedOut: isPhasedOut?.value?.[0]?.value === "1",
    articlesCount: articlesCount,
    articleNumber: articleNumber?.value?.[0]?.value ?? "",
    series: series?.value?.[0]?.value ?? "",
    availableFrom: availableFrom?.value?.[0]?.value ?? "",
  }
}

export const checkIsProduct = (itemId: string) => itemId.startsWith(resourceTypes.PRODUCT)
