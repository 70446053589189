export const searchDefaults = {
  UNIVERSE: "catalog01",
  STATUS_SELECTOR: "Live",
  BRAND_SELECTOR: "geberit",
  SYSTEM_FH: "roc374547",
  SERIE_FH: "src374549",
  SYSTEM_CTP: "ROC_374547",
  SERIE_CTP: "SRC_374549",
  START_PAGE: 1,
  INFINITE_SCROLL_PAGES: 1,
  TABS_VALUE_LENGTH: 3,
} as const

export const tabs = {
  PRO: "PRO",
  SPT: "SPT",
} as const

export const selectors = {
  TYPE_SELECTOR: "type_selector",
  BRAND_SELECTOR: "brand_selector",
  LEVEL_SELECTOR: "level_selector",
  STATUS_SELECTOR: "status_selector",
  CATEGORIES: "categories",
  SEARCH_TERM: "$s",
  NEW: "is_new",
  CROSS: "is_cross_selling_product",
  CHARACTERISTICS: "characteristics",
  APPLICATION_PURPOSES: "application_purposes",
} as const

//FH ranges
export const ranges = {
  RANGES: "ranges",
  RANGE: "range",
  IMPLICIT_RANGES: "implicit_ranges",
  IGNORED_RANGES: ["/!level_selector=ART"],
  ATTRIBUTE_ID_START_DELIMITER: ":",
  ATTRIBUTE_ID_END_DELIMITER: "~",
  ATTRIBUTE_VALUE_START_DELIMITER: "{",
  ATTRIBUTE_VALUE_END_DELIMITER: "}",
  ATTRIBUTE_MULTIPLE_VALUE_SEPARATOR: ",",
} as const

export const attributes = {
  //pro, spt image
  IMAGE: "_imageurl",
  //pro, spt name
  NAME: "name",
  //pro, spt id
  PRODUCT_ID: "productid",
  //product all articles
  ALL_ARTICLES: "all_articles",
  //pro, spt systems category
  SYSTEMS_CATEGORY: "systems_display_category",
  //spt manufacture from
  YEAR_MANUFACTURE_FROM: "year_manufacture_from",
  //spt manufacture until
  YEAR_MANUFACTURE_UNTIL: "year_manufacture_until",
  //if product has 'new' state / tag
  IS_NEW: "novelty_product",
  //product series
  SERIES: "series",
  //number of articles in SPT
  ARTICLES_COUNT: "main_articles_count",
  TECH_IMAGE: "standard_tech_imageurl",
  ARTICLE_IMAGE: "article_imageurl",
  ARTICLE_NUMBER: "articlenumber",
  //replacement info
  ARTICLE_REPLACEMENT_FOR: "replaced_articles",
  ARTICLE_REPLACED_BY: "replaced_by",
  //spt data
  SPT_DETAILS: "spare_part_table_details",
  SPT_MAIN_ARTICLES: "spt_main_articles",
  SPT_SPARE_PARTS: "spt_spare_parts",
  PHASE_OUT_INDICATOR: "phase_out_indicator",
  PHASE_OUT_INDICATOR_ARTICLE: "phase_out_indicator_article",
  MARKET_LAUNCH_DATE: "market_launch_date",
} as const

export const itemsPerPage = {
  CATALOG: 500,
  PRO: 32,
  SPT: 8,
  PARTIAL_RESULTS: 4,
  CAROUSEL: 5,
} as const

export const suppressors = {
  ITEMS: "items",
  FACETS: "facets",
} as const

export const errors = {
  NO_ITEMS_FOUND: "search_noresults",
} as const

export const queries = {
  META: "meta",
  SEARCH: "search",
} as const

export const breadcrumbs = {
  SEARCH: "search",
  CHARACTERISTICS: "characteristics",
  APPLICATION_PURPOSES: "application_purposes",
  NEW: "is_new",
  CATEGORIES: "categories",
  CROSS: "is_cross_selling_product",
} as const

export const filterConstants = {
  GLOBAL: "global",
  PRODUCTS: "products",
  SPARE_PART_TABLES: "spare_part_tables",
  DISABLE_SEARCH_BAR: "disable_search_bar",
  YES: "yes",
  VISUALISATION_TYPE: "visualisation_type",
  CHECKBOX_LIST: "checkbox_list",
  RADIO_BUTTON: "radio_button",
  COLOUR_TILES: "colour_tiles",
  TOGGLE: "toggle",
  SET: "set",
  LIST: "list",
  SEARCH_ENABLED_NUMBER: 7,
  FACET: "facet",
  LOW: "low",
  HIGH: "high",
  AND: "and",
  TRUNCATE_DESKTOP_FILTER_NAME_CHARACTERS_COUNT: 65,
} as const

export const productImageViews = {
  PRODUCT: "product",
  TECH: "tech",
} as const

export const searchResultStatus = {
  HAS_PARTIALS: "OR",
  EXACT: "EXACT",
  EXACT_LONG: "EXACT LONG",
  CORRECTED: "SPELL CHECK",
} as const

//prodcut state in default list
export const productListStatus = {
  NOT_ADDED: "not added",
  IS_BEING_ADDED: "is being added",
  ADDED: "added",
  NOT_ADDED_FAIL: "add fail",
} as const

export const fhParams = {
  LOCATION: "fh_location",
  VIEW: "fh_view",
  VIEW_SIZE: "fh_view_size",
  START_INDEX: "fh_start_index",
  SUPPRESS: "fh_suppress",
  SECOND_ID: "fh_secondid",
  SYSTEM_DISPLAY_RANKING: "system_display_ranking",
  SERIES_DISPLAY_RANKING: "series_display_ranking",
  PAGE: "page",
  PRODUCTS: "products",
} as const

export const fhPatterns = {
  SEPARATOR: `/${selectors.STATUS_SELECTOR}=${searchDefaults.STATUS_SELECTOR}/`,
  TYPE_SELECTOR: `${selectors.TYPE_SELECTOR}=`,
} as const

export const nonFilterAttributeTypes = new Set([
  "brand_selector",
  "status_selector",
  "categories",
  "characteristics",
  "application_purposes",
])

export const links = {
  REMOVE: "remove",
} as const

export const sparePartsAttributes = {
  fixedColumns: ["ATT_459152", "ATT_10670"],
  articleNumber: "ATT_10670",
  description: "ATT_850463",
  articleType: "ART",
  sptType: "SPT",
} as const

export const fhPages = {
  NOVELTIES: "novelties",
  CROSS: "cross_sell",
  MIX_AND_MATCH: "mix_match",
  SPARE_PARTS: "sparepart",
} as const

export const fhViews = {
  HOME: "home",
  DETAIL: "detail",
} as const

export const campaignTypes = {
  HOME_LAST_SEEN: "homepage_last_clicked",
  HOME_NOVELTIES: "homepage_novelties",
  PDP_COMBINABLE: "pdp_combinable",
  PDP_ACCESSORIES: "pdp_accessories",
  PDP_FURTHER_PRODUCTS: "pdp_furtherproduct",
  CATEGORY_NOVELTIES: "category_novelties",
} as const

export const NUMBER_OF_VISIBLE_CRUMBS = 1 as const
