/* Contains the information about the items we keep in browser storage */

export const lastSeenCookie = {
  KEY: "last_seen",
  DELIMITER: ";",
  MAX_AGE: 60 * 60 * 24 * 365, // 1 year
  MAX_ITEMS: 20,
} as const

export const gigyaAuthCookie = {
  KEY: "gigyaAuth",
} as const

export const infoMessageStorage = {
  KEY: "info_message",
} as const

export const myListCookie = {
  KEY: "myListId",
  MAX_AGE: 60 * 60 * 24 * 10, // 10 days by default, should be extended from the date of last list modification
} as const

export const cookieSolutionStorage = {
  KEY: "uc_settings",
} as const
