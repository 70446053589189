import { NextRouter } from "next/router"
import { pages, searchNavigationParams } from "../constants/constants"

/* eslint-disable @typescript-eslint/no-unused-vars */
export const redirectToPage = (router: NextRouter, page: string, id?: string, queryParamsUri = "", searchTerm = "") => {
  if (page === pages.SEARCH) return router.push(`/${pages.SEARCH}?${searchNavigationParams.SEARCH_TERM}=${searchTerm}`)

  return router.push(`/${page}/${id}${queryParamsUri}`)
}

export const getNewPathWithParam = (path: string, paramName: string, paramValue: string) => {
  let newPath = ""
  if (path.includes(paramName)) {
    const re = new RegExp(`${paramName}=.*`, "gi")
    newPath = path.replace(re, `${paramName}=${encodeURIComponent(paramValue)}`)
  } else {
    const param = `${paramName}=${encodeURIComponent(paramValue)}`
    newPath = path.includes("?") ? `${path}&${param}` : `${path}?${param}`
  }

  return newPath
}

/* Protect routes that start with "/user" ignoring ones that have "/lists/" and "/catalog" */
export const isSecureRoute = (pathname: string) =>
  pathname.startsWith(`/${pages.USER}`) && !pathname.includes(`/${pages.CREATE_CATALOG}/`)
