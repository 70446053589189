import { Link, LinkProps } from "@chakra-ui/react"
import { useBrandedTranslation } from "../../../../common/hooks/useBrandedTranslation"

const CompanyWebsiteLink = (props: LinkProps) => {
  const { t } = useBrandedTranslation()

  return (
    <Link
      px="6"
      py="2.5"
      minH={{ base: "11", md: "10" }}
      lineHeight={{ base: "5.5", md: "5" }}
      bg="gray.900"
      w="100%"
      textAlign="center"
      color="white"
      fontWeight="medium"
      fontSize="sm"
      href={t("company-website")}
      isExternal
      _hover={{ "@media (hover: hover) and (pointer: fine)": { bg: "gray.800" } }}
      _active={{ bg: "gray.300", color: "black" }}
      style={{ marginInlineStart: "0" }}
      {...props}
    >
      {t("nav-to")}
    </Link>
  )
}

export default CompanyWebsiteLink
