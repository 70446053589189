/* tslint:disable */
/* eslint-disable */
/**
 * Mylists Integration
 * The Lists API offers a range of RESTful endpoints for creating, managing, and deleting lists within the Online Catalog. All endpoints accept a `X-User-Id` parameter in the request header you can use to identify the current user.  Every list is associated with a particular brand, determined by the Online Catalog in which a user created the list.
 *
 * The version of the OpenAPI document: v3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export * from "./api"
export * from "./configuration"
