/* tslint:disable */
/* eslint-disable */
/**
 * Price api
 * Use the Price API to obtain article price data for a specific country and price group.<br><br>To successfully retrieve data, you must provide an activated subscription key with the relevant permissions. If you don\'t get the data you expect, [get in touch with us](mailto:developer@geberit.com) so we can adjust your subscription key\'s authorization scope.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { AxiosInstance, AxiosPromise, AxiosRequestConfig } from "axios"
import globalAxios from "axios"
import type { Configuration } from "./configuration"
// Some imports not used depending on template conditions
// @ts-ignore
import type { RequestArgs } from "./base"
import {
  assertParamExists,
  createRequestFunction,
  DUMMY_BASE_URL,
  setApiKeyToObject,
  setSearchParams,
  toPathString,
} from "./common"
// @ts-ignore
import { BaseAPI, BASE_PATH } from "./base"

/**
 * Contains an article\'s price data for a specific country, as recommended by the manufacturer.
 * @export
 * @interface Price
 */
export interface Price {
  /**
   * The article\'s number for which the provided price data applies.
   * @type {string}
   * @memberof Price
   */
  articleNumber?: string
  /**
   * The article\'s net price (excluding  VAT). The decimal place of the number is indicated with a decimal point `.` (radix point).
   * @type {string}
   * @memberof Price
   */
  netPrice?: string
  /**
   * The article\'s gross price (with VAT). The decimal place of the number is indicated with a decimal point `.` (radix point).
   * @type {string}
   * @memberof Price
   */
  grossPrice?: string
  /**
   * The applicable currency in the form of its internationally recognized three-letter acronym.
   * @type {string}
   * @memberof Price
   */
  currency?: string
}

/**
 * PriceApi - axios parameter creator
 * @export
 */
export const PriceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Export price data for a specific country from SAP to Redis and Azure File Share.
     * @summary Export price data from SAP.
     * @param {'CH' | 'AT' | 'NL' | 'BE' | 'GB' | 'FR' | 'DE' | 'DK' | 'false' | 'FI' | 'SE' | 'EE' | 'LT' | 'LV' | 'HU' | 'PL' | 'UA'} country The country for which you want to export price data. Specify the country as a two-letter code according to [ISO 3166-1 alpha-2](https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2). &lt;br&gt;Supported values are &#x60;AT&#x60;, &#x60;BE&#x60;, &#x60;CH&#x60;, &#x60;DE&#x60;, &#x60;DK&#x60;,  &#x60;EE&#x60;, &#x60;FI&#x60;, &#x60;FR&#x60;, &#x60;GB&#x60;, &#x60;LT&#x60;, &#x60;LV&#x60;, &#x60;NL&#x60;, &#x60;NO&#x60;, &#x60;SE&#x60;, &#x60;HU&#x60;, &#x60;PL&#x60;, &#x60;UA&#x60;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportPrices: async (
      country:
        | "CH"
        | "AT"
        | "NL"
        | "BE"
        | "GB"
        | "FR"
        | "DE"
        | "DK"
        | "false"
        | "FI"
        | "SE"
        | "EE"
        | "LT"
        | "LV"
        | "HU"
        | "PL"
        | "UA",
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'country' is not null or undefined
      assertParamExists("exportPrices", "country", country)
      const localVarPath = `/price/{country}/sap-import`.replace(`{${"country"}}`, encodeURIComponent(String(country)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Provide an article number and country code to retrieve the respective price data.<br><br>**Note:** Your subscription key\'s authorization scope determines the countries and price groups for which you can retrieve data. If you don\'t get the data you expect, [contact us](mailto:developer@geberit.com) to review and update your key\'s permissions.
     * @summary Get an article\'s price data.
     * @param {'CH' | 'AT' | 'NL' | 'BE' | 'GB' | 'FR' | 'DE' | 'DK' | 'false' | 'FI' | 'SE' | 'EE' | 'LT' | 'LV' | 'HU' | 'PL' | 'UA'} country The country for which you want to retrieve article price data. Specify the country as a two-letter code according to [ISO 3166-1 alpha-2](https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2).&lt;br&gt;Supported values are &#x60;AT&#x60;, &#x60;BE&#x60;, &#x60;CH&#x60;, &#x60;DE&#x60;, &#x60;DK&#x60;,  &#x60;EE&#x60;, &#x60;FI&#x60;, &#x60;FR&#x60;, &#x60;GB&#x60;, &#x60;LT&#x60;, &#x60;LV&#x60;, &#x60;NL&#x60;, &#x60;NO&#x60;, &#x60;SE&#x60;, &#x60;HU&#x60;, &#x60;PL&#x60;, &#x60;UA&#x60;
     * @param {string} articleNumber The article number for which you want to retrieve price data.
     * @param {'group1' | 'group2' | 'all'} [priceGroup] The price group for which you want to retrieve article price data.&lt;br&gt;Supported values are &#x60;group1&#x60;, &#x60;group2&#x60;, &#x60;all&#x60; &lt;br&gt;The default value is &#x60;group1&#x60;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPrice: async (
      country:
        | "CH"
        | "AT"
        | "NL"
        | "BE"
        | "GB"
        | "FR"
        | "DE"
        | "DK"
        | "false"
        | "FI"
        | "SE"
        | "EE"
        | "LT"
        | "LV"
        | "HU"
        | "PL"
        | "UA",
      articleNumber: string,
      priceGroup?: "group1" | "group2" | "all",
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'country' is not null or undefined
      assertParamExists("getPrice", "country", country)
      // verify required parameter 'articleNumber' is not null or undefined
      assertParamExists("getPrice", "articleNumber", articleNumber)
      const localVarPath = `/price/{country}/{articleNumber}`
        .replace(`{${"country"}}`, encodeURIComponent(String(country)))
        .replace(`{${"articleNumber"}}`, encodeURIComponent(String(articleNumber)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      if (priceGroup !== undefined) {
        localVarQueryParameter["priceGroup"] = priceGroup
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Provide one or more article numbers and a country code to retrieve the respective price data.<br><br>**Note:** Your subscription key\'s authorization scope determines the countries and price groups for which you can retrieve data. If you don\'t get the data you expect, [contact us](mailto:developer@geberit.com) to review and update your key\'s permissions.
     * @summary List one or more articles\' price data.
     * @param {'CH' | 'AT' | 'NL' | 'BE' | 'GB' | 'FR' | 'DE' | 'DK' | 'false' | 'FI' | 'SE' | 'EE' | 'LT' | 'LV' | 'HU' | 'PL' | 'UA'} country The country for which you want to retrieve article price data. Specify the country as a two-letter code according to [ISO 3166-1 alpha-2](https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2).&lt;br&gt;Supported values are &#x60;AT&#x60;, &#x60;BE&#x60;, &#x60;CH&#x60;, &#x60;DE&#x60;, &#x60;DK&#x60;,  &#x60;EE&#x60;, &#x60;FI&#x60;, &#x60;FR&#x60;, &#x60;GB&#x60;, &#x60;LT&#x60;, &#x60;LV&#x60;, &#x60;NL&#x60;, &#x60;NO&#x60;, &#x60;SE&#x60;, &#x60;HU&#x60;, &#x60;PL&#x60;, &#x60;UA&#x60;
     * @param {Array<string>} articleNumbers One or more article numbers for which you want to retrieve price data. Separate each number by a comma &#x60;,&#x60; without spaces. &lt;br&gt;&lt;br&gt;The endpoint doesn\&#39;t return an error if you specify invalid article numbers. Instead, it returns a &#x60;200 OK&#x60; response with prices for validly defined article numbers.
     * @param {'group1' | 'group2' | 'all'} [priceGroup] The price group for which you want to retrieve article price data.&lt;br&gt;Supported values are &#x60;group1&#x60;, &#x60;group2&#x60;, &#x60;all&#x60; &lt;br&gt;The default value is &#x60;group1&#x60;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPrices: async (
      country:
        | "CH"
        | "AT"
        | "NL"
        | "BE"
        | "GB"
        | "FR"
        | "DE"
        | "DK"
        | "false"
        | "FI"
        | "SE"
        | "EE"
        | "LT"
        | "LV"
        | "HU"
        | "PL"
        | "UA",
      articleNumbers: Array<string>,
      priceGroup?: "group1" | "group2" | "all",
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'country' is not null or undefined
      assertParamExists("getPrices", "country", country)
      // verify required parameter 'articleNumbers' is not null or undefined
      assertParamExists("getPrices", "articleNumbers", articleNumbers)
      const localVarPath = `/prices/{country}`.replace(`{${"country"}}`, encodeURIComponent(String(country)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      if (articleNumbers) {
        localVarQueryParameter["articleNumbers"] = articleNumbers
      }

      if (priceGroup !== undefined) {
        localVarQueryParameter["priceGroup"] = priceGroup
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * PriceApi - functional programming interface
 * @export
 */
export const PriceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PriceApiAxiosParamCreator(configuration)
  return {
    /**
     * Export price data for a specific country from SAP to Redis and Azure File Share.
     * @summary Export price data from SAP.
     * @param {'CH' | 'AT' | 'NL' | 'BE' | 'GB' | 'FR' | 'DE' | 'DK' | 'false' | 'FI' | 'SE' | 'EE' | 'LT' | 'LV' | 'HU' | 'PL' | 'UA'} country The country for which you want to export price data. Specify the country as a two-letter code according to [ISO 3166-1 alpha-2](https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2). &lt;br&gt;Supported values are &#x60;AT&#x60;, &#x60;BE&#x60;, &#x60;CH&#x60;, &#x60;DE&#x60;, &#x60;DK&#x60;,  &#x60;EE&#x60;, &#x60;FI&#x60;, &#x60;FR&#x60;, &#x60;GB&#x60;, &#x60;LT&#x60;, &#x60;LV&#x60;, &#x60;NL&#x60;, &#x60;NO&#x60;, &#x60;SE&#x60;, &#x60;HU&#x60;, &#x60;PL&#x60;, &#x60;UA&#x60;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportPrices(
      country:
        | "CH"
        | "AT"
        | "NL"
        | "BE"
        | "GB"
        | "FR"
        | "DE"
        | "DK"
        | "false"
        | "FI"
        | "SE"
        | "EE"
        | "LT"
        | "LV"
        | "HU"
        | "PL"
        | "UA",
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.exportPrices(country, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Provide an article number and country code to retrieve the respective price data.<br><br>**Note:** Your subscription key\'s authorization scope determines the countries and price groups for which you can retrieve data. If you don\'t get the data you expect, [contact us](mailto:developer@geberit.com) to review and update your key\'s permissions.
     * @summary Get an article\'s price data.
     * @param {'CH' | 'AT' | 'NL' | 'BE' | 'GB' | 'FR' | 'DE' | 'DK' | 'false' | 'FI' | 'SE' | 'EE' | 'LT' | 'LV' | 'HU' | 'PL' | 'UA'} country The country for which you want to retrieve article price data. Specify the country as a two-letter code according to [ISO 3166-1 alpha-2](https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2).&lt;br&gt;Supported values are &#x60;AT&#x60;, &#x60;BE&#x60;, &#x60;CH&#x60;, &#x60;DE&#x60;, &#x60;DK&#x60;,  &#x60;EE&#x60;, &#x60;FI&#x60;, &#x60;FR&#x60;, &#x60;GB&#x60;, &#x60;LT&#x60;, &#x60;LV&#x60;, &#x60;NL&#x60;, &#x60;NO&#x60;, &#x60;SE&#x60;, &#x60;HU&#x60;, &#x60;PL&#x60;, &#x60;UA&#x60;
     * @param {string} articleNumber The article number for which you want to retrieve price data.
     * @param {'group1' | 'group2' | 'all'} [priceGroup] The price group for which you want to retrieve article price data.&lt;br&gt;Supported values are &#x60;group1&#x60;, &#x60;group2&#x60;, &#x60;all&#x60; &lt;br&gt;The default value is &#x60;group1&#x60;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPrice(
      country:
        | "CH"
        | "AT"
        | "NL"
        | "BE"
        | "GB"
        | "FR"
        | "DE"
        | "DK"
        | "false"
        | "FI"
        | "SE"
        | "EE"
        | "LT"
        | "LV"
        | "HU"
        | "PL"
        | "UA",
      articleNumber: string,
      priceGroup?: "group1" | "group2" | "all",
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Price>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPrice(country, articleNumber, priceGroup, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Provide one or more article numbers and a country code to retrieve the respective price data.<br><br>**Note:** Your subscription key\'s authorization scope determines the countries and price groups for which you can retrieve data. If you don\'t get the data you expect, [contact us](mailto:developer@geberit.com) to review and update your key\'s permissions.
     * @summary List one or more articles\' price data.
     * @param {'CH' | 'AT' | 'NL' | 'BE' | 'GB' | 'FR' | 'DE' | 'DK' | 'false' | 'FI' | 'SE' | 'EE' | 'LT' | 'LV' | 'HU' | 'PL' | 'UA'} country The country for which you want to retrieve article price data. Specify the country as a two-letter code according to [ISO 3166-1 alpha-2](https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2).&lt;br&gt;Supported values are &#x60;AT&#x60;, &#x60;BE&#x60;, &#x60;CH&#x60;, &#x60;DE&#x60;, &#x60;DK&#x60;,  &#x60;EE&#x60;, &#x60;FI&#x60;, &#x60;FR&#x60;, &#x60;GB&#x60;, &#x60;LT&#x60;, &#x60;LV&#x60;, &#x60;NL&#x60;, &#x60;NO&#x60;, &#x60;SE&#x60;, &#x60;HU&#x60;, &#x60;PL&#x60;, &#x60;UA&#x60;
     * @param {Array<string>} articleNumbers One or more article numbers for which you want to retrieve price data. Separate each number by a comma &#x60;,&#x60; without spaces. &lt;br&gt;&lt;br&gt;The endpoint doesn\&#39;t return an error if you specify invalid article numbers. Instead, it returns a &#x60;200 OK&#x60; response with prices for validly defined article numbers.
     * @param {'group1' | 'group2' | 'all'} [priceGroup] The price group for which you want to retrieve article price data.&lt;br&gt;Supported values are &#x60;group1&#x60;, &#x60;group2&#x60;, &#x60;all&#x60; &lt;br&gt;The default value is &#x60;group1&#x60;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPrices(
      country:
        | "CH"
        | "AT"
        | "NL"
        | "BE"
        | "GB"
        | "FR"
        | "DE"
        | "DK"
        | "false"
        | "FI"
        | "SE"
        | "EE"
        | "LT"
        | "LV"
        | "HU"
        | "PL"
        | "UA",
      articleNumbers: Array<string>,
      priceGroup?: "group1" | "group2" | "all",
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Price>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPrices(country, articleNumbers, priceGroup, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * PriceApi - factory interface
 * @export
 */
export const PriceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = PriceApiFp(configuration)
  return {
    /**
     * Export price data for a specific country from SAP to Redis and Azure File Share.
     * @summary Export price data from SAP.
     * @param {PriceApiExportPricesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportPrices(requestParameters: PriceApiExportPricesRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
      return localVarFp.exportPrices(requestParameters.country, options).then((request) => request(axios, basePath))
    },
    /**
     * Provide an article number and country code to retrieve the respective price data.<br><br>**Note:** Your subscription key\'s authorization scope determines the countries and price groups for which you can retrieve data. If you don\'t get the data you expect, [contact us](mailto:developer@geberit.com) to review and update your key\'s permissions.
     * @summary Get an article\'s price data.
     * @param {PriceApiGetPriceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPrice(requestParameters: PriceApiGetPriceRequest, options?: AxiosRequestConfig): AxiosPromise<Price> {
      return localVarFp
        .getPrice(requestParameters.country, requestParameters.articleNumber, requestParameters.priceGroup, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Provide one or more article numbers and a country code to retrieve the respective price data.<br><br>**Note:** Your subscription key\'s authorization scope determines the countries and price groups for which you can retrieve data. If you don\'t get the data you expect, [contact us](mailto:developer@geberit.com) to review and update your key\'s permissions.
     * @summary List one or more articles\' price data.
     * @param {PriceApiGetPricesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPrices(requestParameters: PriceApiGetPricesRequest, options?: AxiosRequestConfig): AxiosPromise<Price> {
      return localVarFp
        .getPrices(requestParameters.country, requestParameters.articleNumbers, requestParameters.priceGroup, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for exportPrices operation in PriceApi.
 * @export
 * @interface PriceApiExportPricesRequest
 */
export interface PriceApiExportPricesRequest {
  /**
   * The country for which you want to export price data. Specify the country as a two-letter code according to [ISO 3166-1 alpha-2](https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2). &lt;br&gt;Supported values are &#x60;AT&#x60;, &#x60;BE&#x60;, &#x60;CH&#x60;, &#x60;DE&#x60;, &#x60;DK&#x60;,  &#x60;EE&#x60;, &#x60;FI&#x60;, &#x60;FR&#x60;, &#x60;GB&#x60;, &#x60;LT&#x60;, &#x60;LV&#x60;, &#x60;NL&#x60;, &#x60;NO&#x60;, &#x60;SE&#x60;, &#x60;HU&#x60;, &#x60;PL&#x60;, &#x60;UA&#x60;
   * @type {'CH' | 'AT' | 'NL' | 'BE' | 'GB' | 'FR' | 'DE' | 'DK' | 'false' | 'FI' | 'SE' | 'EE' | 'LT' | 'LV' | 'HU' | 'PL' | 'UA'}
   * @memberof PriceApiExportPrices
   */
  readonly country:
    | "CH"
    | "AT"
    | "NL"
    | "BE"
    | "GB"
    | "FR"
    | "DE"
    | "DK"
    | "false"
    | "FI"
    | "SE"
    | "EE"
    | "LT"
    | "LV"
    | "HU"
    | "PL"
    | "UA"
}

/**
 * Request parameters for getPrice operation in PriceApi.
 * @export
 * @interface PriceApiGetPriceRequest
 */
export interface PriceApiGetPriceRequest {
  /**
   * The country for which you want to retrieve article price data. Specify the country as a two-letter code according to [ISO 3166-1 alpha-2](https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2).&lt;br&gt;Supported values are &#x60;AT&#x60;, &#x60;BE&#x60;, &#x60;CH&#x60;, &#x60;DE&#x60;, &#x60;DK&#x60;,  &#x60;EE&#x60;, &#x60;FI&#x60;, &#x60;FR&#x60;, &#x60;GB&#x60;, &#x60;LT&#x60;, &#x60;LV&#x60;, &#x60;NL&#x60;, &#x60;NO&#x60;, &#x60;SE&#x60;, &#x60;HU&#x60;, &#x60;PL&#x60;, &#x60;UA&#x60;
   * @type {'CH' | 'AT' | 'NL' | 'BE' | 'GB' | 'FR' | 'DE' | 'DK' | 'false' | 'FI' | 'SE' | 'EE' | 'LT' | 'LV' | 'HU' | 'PL' | 'UA'}
   * @memberof PriceApiGetPrice
   */
  readonly country:
    | "CH"
    | "AT"
    | "NL"
    | "BE"
    | "GB"
    | "FR"
    | "DE"
    | "DK"
    | "false"
    | "FI"
    | "SE"
    | "EE"
    | "LT"
    | "LV"
    | "HU"
    | "PL"
    | "UA"

  /**
   * The article number for which you want to retrieve price data.
   * @type {string}
   * @memberof PriceApiGetPrice
   */
  readonly articleNumber: string

  /**
   * The price group for which you want to retrieve article price data.&lt;br&gt;Supported values are &#x60;group1&#x60;, &#x60;group2&#x60;, &#x60;all&#x60; &lt;br&gt;The default value is &#x60;group1&#x60;
   * @type {'group1' | 'group2' | 'all'}
   * @memberof PriceApiGetPrice
   */
  readonly priceGroup?: "group1" | "group2" | "all"
}

/**
 * Request parameters for getPrices operation in PriceApi.
 * @export
 * @interface PriceApiGetPricesRequest
 */
export interface PriceApiGetPricesRequest {
  /**
   * The country for which you want to retrieve article price data. Specify the country as a two-letter code according to [ISO 3166-1 alpha-2](https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2).&lt;br&gt;Supported values are &#x60;AT&#x60;, &#x60;BE&#x60;, &#x60;CH&#x60;, &#x60;DE&#x60;, &#x60;DK&#x60;,  &#x60;EE&#x60;, &#x60;FI&#x60;, &#x60;FR&#x60;, &#x60;GB&#x60;, &#x60;LT&#x60;, &#x60;LV&#x60;, &#x60;NL&#x60;, &#x60;NO&#x60;, &#x60;SE&#x60;, &#x60;HU&#x60;, &#x60;PL&#x60;, &#x60;UA&#x60;
   * @type {'CH' | 'AT' | 'NL' | 'BE' | 'GB' | 'FR' | 'DE' | 'DK' | 'false' | 'FI' | 'SE' | 'EE' | 'LT' | 'LV' | 'HU' | 'PL' | 'UA'}
   * @memberof PriceApiGetPrices
   */
  readonly country:
    | "CH"
    | "AT"
    | "NL"
    | "BE"
    | "GB"
    | "FR"
    | "DE"
    | "DK"
    | "false"
    | "FI"
    | "SE"
    | "EE"
    | "LT"
    | "LV"
    | "HU"
    | "PL"
    | "UA"

  /**
   * One or more article numbers for which you want to retrieve price data. Separate each number by a comma &#x60;,&#x60; without spaces. &lt;br&gt;&lt;br&gt;The endpoint doesn\&#39;t return an error if you specify invalid article numbers. Instead, it returns a &#x60;200 OK&#x60; response with prices for validly defined article numbers.
   * @type {Array<string>}
   * @memberof PriceApiGetPrices
   */
  readonly articleNumbers: Array<string>

  /**
   * The price group for which you want to retrieve article price data.&lt;br&gt;Supported values are &#x60;group1&#x60;, &#x60;group2&#x60;, &#x60;all&#x60; &lt;br&gt;The default value is &#x60;group1&#x60;
   * @type {'group1' | 'group2' | 'all'}
   * @memberof PriceApiGetPrices
   */
  readonly priceGroup?: "group1" | "group2" | "all"
}

/**
 * PriceApi - object-oriented interface
 * @export
 * @class PriceApi
 * @extends {BaseAPI}
 */
export class PriceApi extends BaseAPI {
  /**
   * Export price data for a specific country from SAP to Redis and Azure File Share.
   * @summary Export price data from SAP.
   * @param {PriceApiExportPricesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PriceApi
   */
  public exportPrices(requestParameters: PriceApiExportPricesRequest, options?: AxiosRequestConfig) {
    return PriceApiFp(this.configuration)
      .exportPrices(requestParameters.country, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Provide an article number and country code to retrieve the respective price data.<br><br>**Note:** Your subscription key\'s authorization scope determines the countries and price groups for which you can retrieve data. If you don\'t get the data you expect, [contact us](mailto:developer@geberit.com) to review and update your key\'s permissions.
   * @summary Get an article\'s price data.
   * @param {PriceApiGetPriceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PriceApi
   */
  public getPrice(requestParameters: PriceApiGetPriceRequest, options?: AxiosRequestConfig) {
    return PriceApiFp(this.configuration)
      .getPrice(requestParameters.country, requestParameters.articleNumber, requestParameters.priceGroup, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Provide one or more article numbers and a country code to retrieve the respective price data.<br><br>**Note:** Your subscription key\'s authorization scope determines the countries and price groups for which you can retrieve data. If you don\'t get the data you expect, [contact us](mailto:developer@geberit.com) to review and update your key\'s permissions.
   * @summary List one or more articles\' price data.
   * @param {PriceApiGetPricesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PriceApi
   */
  public getPrices(requestParameters: PriceApiGetPricesRequest, options?: AxiosRequestConfig) {
    return PriceApiFp(this.configuration)
      .getPrices(requestParameters.country, requestParameters.articleNumbers, requestParameters.priceGroup, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
