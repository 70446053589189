import { useEffect, useState } from "react"
import { SetterOrUpdater, useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil"
import { gigyaContexts } from "../constants/constants"
import { accountInfoState, gigyaIsReadyState } from "../store/authStore"
import { AccountInfoStateType } from "../types/accountInfoState"
import AccountDTO from "../types/gigya/account"
import AccountUpdateDTO from "../types/gigya/account-update"
import { ProfileSettingsType } from "../types/gigya/settings"
import { getAccountInfo, logout as gigyaLogout } from "../utils/gigya-utils"

type UseGigyaReturnTypes = {
  gigyaIsReady: boolean
  isLoading: boolean
  login: () => Promise<unknown> | undefined
  logout: () => Promise<unknown> | undefined
  showLoginPopup: () => Promise<AccountDTO> | void | undefined
  showProfileScreen: (containerId: string) => void | undefined
  getAccountInfo: () => Promise<AccountDTO> | undefined
  setAccountInfo: (settings: ProfileSettingsType) => Promise<AccountDTO> | void | undefined
}

type UseAuthReturnTypes = {
  accountInfo: AccountInfoStateType
  resetAccountInfo: () => void
  setAccountInfo: SetterOrUpdater<AccountInfoStateType>
}

export const useGigya = (): UseGigyaReturnTypes => {
  const [isLoading, setIsLoading] = useState(false)
  const gigyaIsReady = useRecoilValue(gigyaIsReadyState)
  const setRecoilAccountInfo = useSetRecoilState(accountInfoState)

  /**
   * If onAfterScreenLoad, onHide or onAfterSubmit callback is specified in showScreenSet,
   * all Gigya logic gets overwritten. Callbacks with "CiamClient" at the end are present in the library for custom logic.
   */
  useEffect(() => {
    if (!window.onAfterScreenLoadCiamClient || !window.onHideCiamClient || !window.onAfterSubmitCiamClient) {
      /* Fired after the Login popup is rendered */
      window.onAfterScreenLoadCiamClient = () => {
        setIsLoading(false)
      }
      /* Should be fired each time the Login popup is closed, but currently not working */
      window.onHideCiamClient = () => {
        setIsLoading(false)
      }
      /* Fired after each Gigya submit */
      window.onAfterSubmitCiamClient = async (eventObj: AccountUpdateDTO) => {
        /* Use this callback only for Profile screen edit, login is handled by onLoginCallback */
        if (eventObj.context === gigyaContexts.PROFILE) {
          /* Check if user has requested account deletion */
          if (eventObj?.data?.isDeletionRequested) {
            await logout()
            return
          }
          /* Manage the response and set all the necessary fields for our auth object */
          setRecoilAccountInfo({
            firstName: eventObj?.profile?.firstName ?? "",
            lastName: eventObj?.profile?.lastName ?? "",
            email: eventObj?.profile?.email ?? "",
            country: eventObj?.profile?.country ?? "",
            settings: eventObj?.response?.data?.conf_onlinecatalog
              ? JSON.parse(eventObj?.response?.data?.conf_onlinecatalog)
              : null,
            userId: eventObj?.response?.UID ?? "",
            loginAt: new Date(eventObj?.response?.lastLogin) ?? new Date(),
          })
        }
      }
    }
  }, [])

  /* If Gigya script is not loaded yet, return default object */
  if (!gigyaIsReady) {
    return {
      gigyaIsReady: false,
      isLoading: false,
      login: undefined,
      logout: undefined,
      showLoginPopup: undefined,
      showProfileScreen: undefined,
      getAccountInfo: undefined,
      setAccountInfo: undefined,
    }
  }

  /* This is currently not used.
    Login process happens via opening LOGIN context screen set in showLoginPopup() */
  const login = () => {
    return new Promise<unknown>((resolve) => {
      setIsLoading(true)
      window.gigya.accounts.login({
        callback: (data) => {
          setIsLoading(false)
          resolve(data)
        },
      })
    })
  }

  const logout = async () => {
    setIsLoading(true)
    await gigyaLogout()
    setIsLoading(false)
  }

  /* Function to load Gigya LOGIN screen set.
    The response from the login is handled with the global onLogin handler set in GigyaScript */
  const showLoginPopup = () => {
    /* If Login popup is not already loaded in the DOM, start the loader */
    if (!document.getElementById("__gigyaScreenSet_Geberit-RegistrationLogin")) setIsLoading(true)
    window.gigya.accounts.showScreenSet({
      screenSet: "Geberit-RegistrationLogin",
      startScreen: "geberit-login-nosc",
      context: gigyaContexts.LOGIN,
    })
  }

  /* Function to load Gigya PROFILE screen set.
    The response is handled in the onAfterSubmitCiamClient global window callback. */
  const showProfileScreen = (containerId: string) => {
    window.gigya.accounts.showScreenSet({
      screenSet: "Geberit-ProfileUpdate",
      mobileScreenSet: "Geberit-ProfileUpdate",
      startScreen: "geberit-profile-screen",
      containerID: containerId,
      context: gigyaContexts.PROFILE,
    })
  }

  /* Update user settings from Settings page */
  const setAccountInfo = (settings: ProfileSettingsType) => {
    return new Promise<AccountDTO>((resolve) => {
      setIsLoading(true)
      window.gigya.accounts.setAccountInfo({
        data: {
          conf_onlinecatalog: JSON.stringify(settings),
        },
        callback: (data) => {
          setIsLoading(false)
          resolve(data)
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any)
    })
  }

  return {
    gigyaIsReady,
    isLoading,
    login,
    logout,
    showLoginPopup,
    showProfileScreen,
    getAccountInfo,
    setAccountInfo,
  }
}

export const useAuth = (): UseAuthReturnTypes => {
  const [accountInfo, setAccountInfo] = useRecoilState(accountInfoState)
  const resetAccountInfo = useResetRecoilState(accountInfoState)

  return {
    accountInfo,
    setAccountInfo,
    resetAccountInfo,
  }
}
