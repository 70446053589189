import { PrismicPreview } from "@prismicio/next"
import { PrismicProvider } from "@prismicio/react"
import * as cache from "memory-cache"
import { appWithTranslation, UserConfig } from "next-i18next"
import App, { AppContext, AppInitialProps, AppProps } from "next/app"
import dynamic from "next/dynamic"
import { useMemo } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { RecoilRoot } from "recoil"
import "regenerator-runtime/runtime"
import "../config/axios" // Apply the custom axios keepAlive agents
import "../config/recoil"
import domainsConfig from "../domains.config"
import nextI18nextConfig from "../next-i18next.config"
import { linkResolver, repositoryName } from "../prismicio"
import { Category } from "../src/api-clients/product-api-v1"
import { categoryContentFilter, pages } from "../src/common/constants/constants"
import { getAllSystemCategories } from "../src/common/hooks/queries"
import logger from "../src/common/lib/logger"
import { EXCEPTION_TYPES, traceException } from "../src/common/lib/trace"
import { getStrippedSystemMenuData } from "../src/common/utils/category-utils"
import { getEnvByHost } from "../src/common/utils/configuration-utils"
import Layout from "../src/components/features/layout/Layout"
import CustomLink from "../src/components/shared/CustomLink"
import { ErrorFallback } from "../src/components/shared/ErrorFallback"

/* Load the progress bar only in the client */
const TopProgressBar = dynamic(() => import("../src/components/features/layout/TopProgressBar"), { ssr: false })

// This is used for rendering a better tree when an excetption is thrown
if (process.env.NODE_ENV === "development" && typeof window !== "undefined") {
  console.log("In development mode")
  window.onunhandledrejection = (event) => {
    console.warn(`UNHANDLED PROMISE REJECTION: ${event}`)
  }

  window.onerror = function (message, source, lineNumber, colno, error) {
    console.warn(`UNHANDLED ERROR: ${error}`)
  }
}

type CustomAppProps = {
  systemMenuData: Category
}

type CustomInitialProps = {
  locale: string
  brand: string
  host: string
  indexEnabled: boolean
}

export type CustomPageProps = AppInitialProps & CustomInitialProps

const CATEGORIES_CACHE_TTL = 3 * 60 * 60 * 1000 // 3 hours

const CustomApp = ({ Component, pageProps, systemMenuData }: AppProps & CustomPageProps & CustomAppProps) => {
  const { locale, brand, host } = pageProps
  const memoizedSystemMenuData = useMemo(() => systemMenuData, [systemMenuData])

  return (
    <PrismicProvider
      linkResolver={linkResolver}
      internalLinkComponent={({ href, children, ...props }) => (
        <CustomLink href={href}>
          <a {...props}>{children}</a>
        </CustomLink>
      )}
    >
      <PrismicPreview repositoryName={repositoryName}>
        <RecoilRoot>
          <Layout host={host} locale={locale} brand={brand} systemMenuData={memoizedSystemMenuData}>
            <ErrorBoundary FallbackComponent={() => <ErrorFallback brand={brand} />}>
              <TopProgressBar />
              <Component {...pageProps} />
            </ErrorBoundary>
          </Layout>
        </RecoilRoot>
      </PrismicPreview>
    </PrismicProvider>
  )
}

CustomApp.getInitialProps = async (context: AppContext): Promise<AppInitialProps & CustomAppProps> => {
  const initialProps = await App.getInitialProps(context)
  const { pageProps: initialPageProps } = initialProps

  const { router, ctx } = context
  // This is ServerRouter object
  const { query, locale, asPath } = router
  const brand = query?.brand as string

  const { req, res } = ctx

  const { host } = req.headers
  const indexEnabled = getEnvByHost(process.env.NEXT_PUBLIC_INDEXING_CONFIG, host)

  let systemMenuData: Category = {}

  try {
    const isSSR = typeof window === "undefined"
    const isPageRequest = !!brand && !locale.startsWith(domainsConfig.localePlaceholder)
    const cacheKey = `${locale}${brand}`

    /* Get the data needed for the SystemMenu component.
      Currently, this is the root category data with all sub-categories for products and spare parts. 
      Sometimes this getInitialProps might run in the client as well, this is why the additional check.
      https://nextjs.org/docs/pages/api-reference/functions/get-initial-props */

    if (isSSR && isPageRequest && router.isReady) {
      if (!cache.get(cacheKey)) {
        const systemMenuDataResult = await getAllSystemCategories(locale, brand, categoryContentFilter.ALL)
        cache.put(cacheKey, getStrippedSystemMenuData(systemMenuDataResult?.data), CATEGORIES_CACHE_TTL)
      }
      systemMenuData = cache.get(cacheKey)
    }
  } catch (e) {
    logger.error(`Error fetching initial categories data: ${e}`)
    traceException({
      type: `${EXCEPTION_TYPES.OC_ERROR_EXCEPTION} initial categories data`,
      message: `Error fetching initial categories data locale: ${locale}, brand: ${brand}, : ${e}`,
      stack: e.stack,
    })
  }

  /* Handle all paths that don't match anything in the router navigation and redirect them to the custom 404 page.
    Use 308 redirect status code for SEO purposes. The custom 404 page will rewrite the proper 404 code. */
  if (res?.statusCode === 404 && !asPath.endsWith(`/${pages.NOT_FOUND_PAGE}`)) {
    res.writeHead(308, { Location: `/${locale}/${pages.NOT_FOUND_PAGE}` })
    res.end()
  }

  const pageProps: CustomPageProps = { ...initialPageProps, locale, brand, host, indexEnabled }

  // By default, expose "brand", "url" and "indexEnabled" props to all pages
  // Provide the data needed for the SystemMenu navigation component in "systemMenuData" prop
  return { pageProps, systemMenuData }
}

export default appWithTranslation(CustomApp, nextI18nextConfig as UserConfig)
