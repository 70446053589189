/* tslint:disable */
/* eslint-disable */
/**
 * Mylists Integration
 * The Lists API offers a range of RESTful endpoints for creating, managing, and deleting lists within the Online Catalog. All endpoints accept a `X-User-Id` parameter in the request header you can use to identify the current user.  Every list is associated with a particular brand, determined by the Online Catalog in which a user created the list.
 *
 * The version of the OpenAPI document: v3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { AxiosInstance, AxiosPromise, AxiosRequestConfig } from "axios"
import globalAxios from "axios"
import type { Configuration } from "./configuration"
// Some imports not used depending on template conditions
// @ts-ignore
import type { RequestArgs } from "./base"
import {
  DUMMY_BASE_URL,
  assertParamExists,
  createRequestFunction,
  serializeDataIfNeeded,
  setApiKeyToObject,
  setSearchParams,
  toPathString,
} from "./common"
// @ts-ignore
import { BASE_PATH, BaseAPI, RequiredError } from "./base"

/**
 * Contains article data stored with a list. The order of articles corresponds to the order specified by the user in the Online Catalog.
 * @export
 * @interface Article
 */
export interface Article {
  /**
   * The article number (also called [SKU](/glossary)) of the article assigned to the list.
   * @type {string}
   * @memberof Article
   */
  id?: string
  /**
   * Specifies how many units of the article the user wants to store in the list.
   * @type {number}
   * @memberof Article
   */
  quantity?: number
  /**
   * The description of the article.
   * @type {string}
   * @memberof Article
   */
  description?: string
  /**
   * An `array` of `SecondaryId` objects. Contains any secondary IDs an article has in the list\'s locale.
   * @type {Array<SecondaryId>}
   * @memberof Article
   */
  secondaryIds?: Array<SecondaryId>
  /**
   * Indicates whether the article is available in the requested locale of the Online Catalog.
   * @type {boolean}
   * @memberof Article
   */
  available?: boolean
  /**
   * The current status of the article. Possible values are `NEW` and `PHASEOUT`. If no specific status is defined for the article, the API response does not contain this property.
   * @type {string}
   * @memberof Article
   */
  status?: ArticleStatusEnum
  /**
   *  The date the article becomes available for purchase. This property is present only if the `status` property of the same object is `NEW`.
   * @type {string}
   * @memberof Article
   */
  launchDate?: string
}

export const ArticleStatusEnum = {
  New: "NEW",
  Phaseout: "PHASEOUT",
} as const

export type ArticleStatusEnum = (typeof ArticleStatusEnum)[keyof typeof ArticleStatusEnum]

/**
 * Contains information about an error.
 * @export
 * @interface ErrorMessage
 */
export interface ErrorMessage {
  /**
   * The HTTP status code returned by the service.
   * @type {number}
   * @memberof ErrorMessage
   */
  statusCode?: number
  /**
   * A brief, human-readable description of the error.
   * @type {string}
   * @memberof ErrorMessage
   */
  message?: string
}
/**
 * Contains all list data ready to display in the Online Catalog.
 * @export
 * @interface MyList
 */
export interface MyList {
  /**
   * The unique identifier of the list.
   * @type {string}
   * @memberof MyList
   */
  id?: string
  /**
   * The brand associated with the list. Specifies in which Online Catalog a user created the list, for example, in a brand catalog such as Ido.
   * @type {string}
   * @memberof MyList
   */
  brand?: string
  /**
   * The unique identifier of the user who owns the list.
   * @type {string}
   * @memberof MyList
   */
  userId?: string
  /**
   * The name of the list as defined by the user.
   * @type {string}
   * @memberof MyList
   */
  name?: string
  /**
   * The date and time the user created the list.
   * @type {string}
   * @memberof MyList
   */
  createdAt?: string
  /**
   * The date and time the user last modified the list.
   * @type {string}
   * @memberof MyList
   */
  lastModifiedAt?: string
  /**
   * The amount of contained articles
   * @type {number}
   * @memberof MyList
   */
  totalArticles?: number
  /**
   * An `array` of `Product` objects. Contains product and article data stored in the list.
   * @type {Array<Product>}
   * @memberof MyList
   */
  products?: Array<Product>
  /**
   *  Indicates whether the list is the user\'s default list in the Online Catalog. If the user does not select a specific list to save data, products and articles are assigned to this default list.
   * @type {boolean}
   * @memberof MyList
   */
  isDefault?: boolean
}
/**
 * Contains all list data ready to display in the Online Catalog.
 * @export
 * @interface MyListMeta
 */
export interface MyListMeta {
  /**
   * The unique identifier of the list.
   * @type {string}
   * @memberof MyListMeta
   */
  id?: string
  /**
   * The brand associated with the list. Specifies in which Online Catalog a user created the list, for example, in a brand catalog such as Ido.
   * @type {string}
   * @memberof MyListMeta
   */
  brand?: string
  /**
   * The unique identifier of the user who owns the list.
   * @type {string}
   * @memberof MyListMeta
   */
  userId?: string
  /**
   * The name of the list as defined by the user.
   * @type {string}
   * @memberof MyListMeta
   */
  name?: string
  /**
   * The date and time the user created the list.
   * @type {string}
   * @memberof MyListMeta
   */
  createdAt?: string
  /**
   * The date and time the user last modified the list.
   * @type {string}
   * @memberof MyListMeta
   */
  lastModifiedAt?: string
  /**
   * The amount of contained articles
   * @type {number}
   * @memberof MyListMeta
   */
  totalArticles?: number
  /**
   *  Indicates whether the list is the user\'s default list in the Online Catalog. If the user does not select a specific list to save data, products and articles are assigned to this default list.
   * @type {boolean}
   * @memberof MyListMeta
   */
  isDefault?: boolean
}
/**
 * Contains a collection of `MyList` objects and the pagination data.
 * @export
 * @interface PagedMetaResult
 */
export interface PagedMetaResult {
  /**
   * The number of skipped list entries as specified in your request.
   * @type {number}
   * @memberof PagedMetaResult
   */
  offset?: number
  /**
   * The number of returned list entries.
   * @type {number}
   * @memberof PagedMetaResult
   */
  count?: number
  /**
   * The total number of list entries that match your request, regardless of pagination.
   * @type {number}
   * @memberof PagedMetaResult
   */
  total?: number
  /**
   * An `array` of `MyList` objects.
   * @type {Array<MyListMeta>}
   * @memberof PagedMetaResult
   */
  results?: Array<MyListMeta>
}
/**
 * Contains a collection of `MyList` objects and the pagination data.
 * @export
 * @interface PagedResult
 */
export interface PagedResult {
  /**
   * The number of skipped list entries as specified in your request.
   * @type {number}
   * @memberof PagedResult
   */
  offset?: number
  /**
   * The number of returned list entries.
   * @type {number}
   * @memberof PagedResult
   */
  count?: number
  /**
   * The total number of list entries that match your request, regardless of pagination.
   * @type {number}
   * @memberof PagedResult
   */
  total?: number
  /**
   * An `array` of `MyList` objects.
   * @type {Array<MyList>}
   * @memberof PagedResult
   */
  results?: Array<MyList>
}
/**
 * Contains product and article data stored with a list. The order of products corresponds to the order specified by the user in the Online Catalog.
 * @export
 * @interface Product
 */
export interface Product {
  /**
   * The unique identifier of the product assigned to the list.
   * @type {string}
   * @memberof Product
   */
  id?: string
  /**
   * The display name of the product.
   * @type {string}
   * @memberof Product
   */
  name?: string
  /**
   * The URL of the product thumbnail.
   * @type {string}
   * @memberof Product
   */
  imageUrl?: string
  /**
   * An `array` of `Article` objects. Contains article data stored in the list.
   * @type {Array<Article>}
   * @memberof Product
   */
  articles?: Array<Article>
}
/**
 * Contains secondary IDs an article has in the current list\'s locale. For example, a locale in which articles have secondary IDs is `de-CH`.
 * @export
 * @interface SecondaryId
 */
export interface SecondaryId {
  /**
   * The value of the secondary ID assigned to the article
   * @type {string}
   * @memberof SecondaryId
   */
  id?: string
  /**
   * The attribute ID linked to the secondary ID\'s display name.
   * @type {string}
   * @memberof SecondaryId
   */
  name?: string
}
/**
 * Contains article data to store with a new or existing list. The order of articles corresponds to the order specified by the user in the Online Catalog.
 * @export
 * @interface StoreArticle
 */
export interface StoreArticle {
  /**
   * The article number (also called [SKU](/glossary)) of the article added to the list.
   * @type {string}
   * @memberof StoreArticle
   */
  id?: string
  /**
   * Specifies how many units of the article to store in the list.
   * @type {number}
   * @memberof StoreArticle
   */
  quantity?: number
}
/**
 * Contains list data to create a new list or update an existing one.
 * @export
 * @interface StoreMyList
 */
export interface StoreMyList {
  /**
   * Indicates whether a list is the user\'s default list in the Online Catalog. If the user doesn\'t select a specific list to save data, products and articles are assigned to this default list.
   * @type {boolean}
   * @memberof StoreMyList
   */
  isDefault?: boolean
  /**
   * The name of the list as specified by the user.
   * @type {string}
   * @memberof StoreMyList
   */
  name?: string
  /**
   * An `array` of `StoreProduct` objects. Contains product and article data to store with the list.
   * @type {Array<StoreProduct>}
   * @memberof StoreMyList
   */
  products?: Array<StoreProduct>
}
/**
 * Contains product and article data to store with a new or existing list. The order of products corresponds to the order specified by the user in the Online Catalog.
 * @export
 * @interface StoreProduct
 */
export interface StoreProduct {
  /**
   * The unique identifier of the product added to the list.
   * @type {string}
   * @memberof StoreProduct
   */
  id?: string
  /**
   * An `array` of `StoreArticle` objects. Contains article data to store with the list.
   * @type {Array<StoreArticle>}
   * @memberof StoreProduct
   */
  articles?: Array<StoreArticle>
}

/**
 * ListApi - axios parameter creator
 * @export
 */
export const ListApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Specify a list ID, user ID, and brand to delete a specific list.  This endpoint permanently deletes the list, so use it with caution.
     * @summary Delete a list by ID.
     * @param {string} brand The brand associated with the list(s). The Lists API supports all brands available in the Online Catalog.  You can find a list of available brands in this [user guide](/locales).
     * @param {string} id The unique identifier of the list you want to delete.
     * @param {string} [xUserId] The user ID that you use to identify the current user.  Only the user who owns a list can delete it.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMyList: async (
      brand: string,
      id: string,
      xUserId?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("deleteMyList", "brand", brand)
      // verify required parameter 'id' is not null or undefined
      assertParamExists("deleteMyList", "id", id)
      const localVarPath = `/{brand}/mylist/{id}`
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      if (xUserId != null) {
        localVarHeaderParameter["X-User-Id"] = String(xUserId)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify a brand, user ID, and a list of list IDs you want to delete.  This endpoint permanently deletes the specified list entries, so use it with caution.
     * @summary Delete one or more lists.
     * @param {string} brand The brand associated with the list(s). The Lists API supports all brands available in the Online Catalog.  You can find a list of available brands in this [user guide](/locales).
     * @param {Array<string>} ids A list of unique identifiers of the lists you want to delete.
     * @param {string} [xUserId] The user ID that you use to identify the current user.  Only the user who owns a list can delete it.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMyLists: async (
      brand: string,
      ids: Array<string>,
      xUserId?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("deleteMyLists", "brand", brand)
      // verify required parameter 'ids' is not null or undefined
      assertParamExists("deleteMyLists", "ids", ids)
      const localVarPath = `/{brand}/mylist`.replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      if (ids) {
        localVarQueryParameter["ids"] = ids
      }

      if (xUserId != null) {
        localVarHeaderParameter["X-User-Id"] = String(xUserId)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify a list ID, locale, and brand to retrieve a specific list. This endpoint returns the details of the list, including its name, added products, and articles.  The order of products and articles in the JSON response corresponds to the order specified by the user in the Online Catalog.
     * @summary Get a list by ID.
     * @param {string} brand The brand associated with the list(s). The Lists API supports all brands available in the Online Catalog.  You can find a list of available brands in this [user guide](/locales).
     * @param {string} id The unique identifier of the list you want to retrieve.
     * @param {string} locale The list\&#39;s locale. The Lists API supports all locales available in the Online Catalog.  You can find a list of available locales in this [user guide](/locales).
     * @param {string} [xUserId] The user ID that you use to identify the current user.  This parameter is optional so that users can, for example, share a list with another user (read access), regardless of whether they are logged into the Online Catalog or anonymous.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMyList: async (
      brand: string,
      id: string,
      locale: string,
      xUserId?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("getMyList", "brand", brand)
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getMyList", "id", id)
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getMyList", "locale", locale)
      const localVarPath = `/{brand}/mylist/{id}`
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      if (locale !== undefined) {
        localVarQueryParameter["locale"] = locale
      }

      if (xUserId != null) {
        localVarHeaderParameter["X-User-Id"] = String(xUserId)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify a locale, brand, and user ID to retrieve a user\'s lists. This endpoint also supports pagination parameters that allow you to filter the returned data.    The order of products and articles in the JSON response corresponds to the order specified by the user in the Online Catalog.
     * @summary Get list(s) by user ID.
     * @param {string} brand The brand associated with the list(s). The Lists API supports all brands available in the Online Catalog.  You can find a list of available brands in this [user guide](/locales).
     * @param {string} locale The list\&#39;s locale. The Lists API supports all locales available in the Online Catalog.  You can find a list of available locales in this [user guide](/locales).
     * @param {string} xUserId The user ID that you use to identify the current user.  The endpoint requires this parameter to prevent read access to all stored lists.
     * @param {number} [offset] Format - int32. The number of lists to skip. Used for pagination. The default value is 0.
     * @param {number} [limit] Format - int32. The total number of lists to return. Used for pagination. The default value is 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMyLists: async (
      brand: string,
      locale: string,
      xUserId: string,
      offset?: number,
      limit?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("getMyLists", "brand", brand)
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getMyLists", "locale", locale)
      // verify required parameter 'xUserId' is not null or undefined
      assertParamExists("getMyLists", "xUserId", xUserId)
      const localVarPath = `/{brand}/mylist`.replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      if (locale !== undefined) {
        localVarQueryParameter["locale"] = locale
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit
      }

      if (xUserId != null) {
        localVarHeaderParameter["X-User-Id"] = String(xUserId)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify a locale, brand, and user ID to retrieve the metadata for a user\'s lists. This endpoint also supports pagination parameters that allow you to filter the returned data.  The response contains basic information about each list, such as its name, ID, and associated user ID, but does not include any products added to the list.
     * @summary Get list(s) metadata by user ID.
     * @param {string} brand The brand associated with the list(s). The Lists API supports all brands available in the Online Catalog.  You can find a list of available brands in this [user guide](/locales).
     * @param {string} xUserId The user ID that you use to identify the current user.  The endpoint requires this parameter to prevent read access to all stored lists.
     * @param {number} [offset] Format - int32. The number of lists to skip. Used for pagination. The default value is 0.
     * @param {number} [limit] Format - int32. The total number of lists to return. Used for pagination. The default value is 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMyListsMeta: async (
      brand: string,
      xUserId: string,
      offset?: number,
      limit?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("getMyListsMeta", "brand", brand)
      // verify required parameter 'xUserId' is not null or undefined
      assertParamExists("getMyListsMeta", "xUserId", xUserId)
      const localVarPath = `/{brand}/mylist-meta`.replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit
      }

      if (xUserId != null) {
        localVarHeaderParameter["X-User-Id"] = String(xUserId)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * To update an existing list, provide the list ID, brand, and patch data in the request body. This endpoint uses the [JSON Patch](https://www.rfc-editor.org/rfc/rfc6902) that allows you to specify operations to apply to the target list. For example, you can add a new article or update the quantity of an existing article in the list.  > **Note**: > Be sure to read the description of the request body and check the examples we\'ve provided to gain a better understanding of how to use this endpoint.  A successful response returns the updated list, including any changes made as a result of the PATCH request.
     * @summary Update a list using JSON Patch.
     * @param {string} brand The brand associated with the list(s). The Lists API supports all brands available in the Online Catalog.  You can find a list of available brands in this [user guide](/locales).
     * @param {string} id The unique identifier of the list you want to update.
     * @param {string} locale The list\&#39;s locale. The Lists API supports all locales available in the Online Catalog.  You can find a list of available locales in this [user guide](/locales).
     * @param {string} [xUserId] The user ID that you use to identify the current user.  Only the user who owns a list can update it.
     * @param {object} [body]   Define an array of patch operations (the JSON Patch document) to apply to the target list. Use the &#x60;StoreMyList&#x60; Schema as a reference to see which fields you can update and what structure to follow.    &gt; **Important:** We highly recommend that you read the [JSON Patch definition](https://www.rfc-editor.org/rfc/rfc6902) before using this endpoint. Understanding how JSON Patch works is essential to using this endpoint successfully. If you\&#39;re unsure, we recommend trying to accomplish your goal using the \&quot;Update a list\&quot; endpoint, which uses the PUT method. You can find this endpoint in this API.    &gt; **Important:** You can\&#39;t perform operations on articles with a &#x60;quantity&#x60; of 0 (zero). Instead, use the &#x60;add&#x60; operation to add the article with a &#x60;quantity&#x60; greater than 0 to the list first. Once the article has been successfully added, you can use the other operations to make any necessary changes or updates.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchMyList: async (
      brand: string,
      id: string,
      locale: string,
      xUserId?: string,
      body?: object,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("patchMyList", "brand", brand)
      // verify required parameter 'id' is not null or undefined
      assertParamExists("patchMyList", "id", id)
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("patchMyList", "locale", locale)
      const localVarPath = `/{brand}/mylist/{id}`
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      if (locale !== undefined) {
        localVarQueryParameter["locale"] = locale
      }

      if (xUserId != null) {
        localVarHeaderParameter["X-User-Id"] = String(xUserId)
      }

      localVarHeaderParameter["Content-Type"] = "application/json-patch+json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify a locale and brand to create a new list. This endpoint accepts a JSON object in the request body, which should contain the list data to store.   A successful response includes the newly created list, including its ID.
     * @summary Create a list.
     * @param {string} brand The brand associated with the list(s). The Lists API supports all brands available in the Online Catalog.  You can find a list of available brands in this [user guide](/locales).
     * @param {string} locale The list\&#39;s locale. The Lists API supports all locales available in the Online Catalog.  You can find a list of available locales in this [user guide](/locales).
     * @param {string} [xUserId] The user ID that you use to identify the current user.  This parameter is optional so anonymous users can also create a list in the Online Catalog.
     * @param {StoreMyList} [storeMyList] Define the new list data as JSON using the &#x60;StoreMyList&#x60; schema. Note that the order of products and articles in the JSON corresponds to the order of the list entries as specified by the user in the Online Catalog.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postMyList: async (
      brand: string,
      locale: string,
      xUserId?: string,
      storeMyList?: StoreMyList,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("postMyList", "brand", brand)
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("postMyList", "locale", locale)
      const localVarPath = `/{brand}/mylist`.replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      if (locale !== undefined) {
        localVarQueryParameter["locale"] = locale
      }

      if (xUserId != null) {
        localVarHeaderParameter["X-User-Id"] = String(xUserId)
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(storeMyList, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify a list ID, locale, brand, and user ID to update an existing list. This endpoint accepts a JSON object containing the list\'s updated data in the request body.  A successful response returns the updated list, including any changes made as a result of the PUT request.
     * @summary Update a list.
     * @param {string} brand The brand associated with the list(s). The Lists API supports all brands available in the Online Catalog.  You can find a list of available brands in this [user guide](/locales).
     * @param {string} id The unique identifier of the list you want to update.
     * @param {string} locale The list\&#39;s locale. The Lists API supports all locales available in the Online Catalog.  You can find a list of available locales in this [user guide](/locales).
     * @param {string} [xUserId] The user ID that you use to identify the current user.  Only the user who owns a list can update it.
     * @param {StoreMyList} [storeMyList] Define the updated list data as JSON using the &#x60;StoreMyList&#x60; schema. Make sure you supply all the data the list should contain, as this endpoint overwrites the currently stored list and removes any missing attributes. Also note that to update the order of products and articles in the list, you need to provide the data in the desired order in the JSON.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putMyList: async (
      brand: string,
      id: string,
      locale: string,
      xUserId?: string,
      storeMyList?: StoreMyList,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("putMyList", "brand", brand)
      // verify required parameter 'id' is not null or undefined
      assertParamExists("putMyList", "id", id)
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("putMyList", "locale", locale)
      const localVarPath = `/{brand}/mylist/{id}`
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      if (locale !== undefined) {
        localVarQueryParameter["locale"] = locale
      }

      if (xUserId != null) {
        localVarHeaderParameter["X-User-Id"] = String(xUserId)
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(storeMyList, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ListApi - functional programming interface
 * @export
 */
export const ListApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ListApiAxiosParamCreator(configuration)
  return {
    /**
     * Specify a list ID, user ID, and brand to delete a specific list.  This endpoint permanently deletes the list, so use it with caution.
     * @summary Delete a list by ID.
     * @param {string} brand The brand associated with the list(s). The Lists API supports all brands available in the Online Catalog.  You can find a list of available brands in this [user guide](/locales).
     * @param {string} id The unique identifier of the list you want to delete.
     * @param {string} [xUserId] The user ID that you use to identify the current user.  Only the user who owns a list can delete it.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteMyList(
      brand: string,
      id: string,
      xUserId?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMyList(brand, id, xUserId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify a brand, user ID, and a list of list IDs you want to delete.  This endpoint permanently deletes the specified list entries, so use it with caution.
     * @summary Delete one or more lists.
     * @param {string} brand The brand associated with the list(s). The Lists API supports all brands available in the Online Catalog.  You can find a list of available brands in this [user guide](/locales).
     * @param {Array<string>} ids A list of unique identifiers of the lists you want to delete.
     * @param {string} [xUserId] The user ID that you use to identify the current user.  Only the user who owns a list can delete it.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteMyLists(
      brand: string,
      ids: Array<string>,
      xUserId?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMyLists(brand, ids, xUserId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify a list ID, locale, and brand to retrieve a specific list. This endpoint returns the details of the list, including its name, added products, and articles.  The order of products and articles in the JSON response corresponds to the order specified by the user in the Online Catalog.
     * @summary Get a list by ID.
     * @param {string} brand The brand associated with the list(s). The Lists API supports all brands available in the Online Catalog.  You can find a list of available brands in this [user guide](/locales).
     * @param {string} id The unique identifier of the list you want to retrieve.
     * @param {string} locale The list\&#39;s locale. The Lists API supports all locales available in the Online Catalog.  You can find a list of available locales in this [user guide](/locales).
     * @param {string} [xUserId] The user ID that you use to identify the current user.  This parameter is optional so that users can, for example, share a list with another user (read access), regardless of whether they are logged into the Online Catalog or anonymous.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMyList(
      brand: string,
      id: string,
      locale: string,
      xUserId?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyList>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getMyList(brand, id, locale, xUserId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify a locale, brand, and user ID to retrieve a user\'s lists. This endpoint also supports pagination parameters that allow you to filter the returned data.    The order of products and articles in the JSON response corresponds to the order specified by the user in the Online Catalog.
     * @summary Get list(s) by user ID.
     * @param {string} brand The brand associated with the list(s). The Lists API supports all brands available in the Online Catalog.  You can find a list of available brands in this [user guide](/locales).
     * @param {string} locale The list\&#39;s locale. The Lists API supports all locales available in the Online Catalog.  You can find a list of available locales in this [user guide](/locales).
     * @param {string} xUserId The user ID that you use to identify the current user.  The endpoint requires this parameter to prevent read access to all stored lists.
     * @param {number} [offset] Format - int32. The number of lists to skip. Used for pagination. The default value is 0.
     * @param {number} [limit] Format - int32. The total number of lists to return. Used for pagination. The default value is 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMyLists(
      brand: string,
      locale: string,
      xUserId: string,
      offset?: number,
      limit?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResult>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getMyLists(
        brand,
        locale,
        xUserId,
        offset,
        limit,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify a locale, brand, and user ID to retrieve the metadata for a user\'s lists. This endpoint also supports pagination parameters that allow you to filter the returned data.  The response contains basic information about each list, such as its name, ID, and associated user ID, but does not include any products added to the list.
     * @summary Get list(s) metadata by user ID.
     * @param {string} brand The brand associated with the list(s). The Lists API supports all brands available in the Online Catalog.  You can find a list of available brands in this [user guide](/locales).
     * @param {string} xUserId The user ID that you use to identify the current user.  The endpoint requires this parameter to prevent read access to all stored lists.
     * @param {number} [offset] Format - int32. The number of lists to skip. Used for pagination. The default value is 0.
     * @param {number} [limit] Format - int32. The total number of lists to return. Used for pagination. The default value is 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMyListsMeta(
      brand: string,
      xUserId: string,
      offset?: number,
      limit?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedMetaResult>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getMyListsMeta(brand, xUserId, offset, limit, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * To update an existing list, provide the list ID, brand, and patch data in the request body. This endpoint uses the [JSON Patch](https://www.rfc-editor.org/rfc/rfc6902) that allows you to specify operations to apply to the target list. For example, you can add a new article or update the quantity of an existing article in the list.  > **Note**: > Be sure to read the description of the request body and check the examples we\'ve provided to gain a better understanding of how to use this endpoint.  A successful response returns the updated list, including any changes made as a result of the PATCH request.
     * @summary Update a list using JSON Patch.
     * @param {string} brand The brand associated with the list(s). The Lists API supports all brands available in the Online Catalog.  You can find a list of available brands in this [user guide](/locales).
     * @param {string} id The unique identifier of the list you want to update.
     * @param {string} locale The list\&#39;s locale. The Lists API supports all locales available in the Online Catalog.  You can find a list of available locales in this [user guide](/locales).
     * @param {string} [xUserId] The user ID that you use to identify the current user.  Only the user who owns a list can update it.
     * @param {object} [body]   Define an array of patch operations (the JSON Patch document) to apply to the target list. Use the &#x60;StoreMyList&#x60; Schema as a reference to see which fields you can update and what structure to follow.    &gt; **Important:** We highly recommend that you read the [JSON Patch definition](https://www.rfc-editor.org/rfc/rfc6902) before using this endpoint. Understanding how JSON Patch works is essential to using this endpoint successfully. If you\&#39;re unsure, we recommend trying to accomplish your goal using the \&quot;Update a list\&quot; endpoint, which uses the PUT method. You can find this endpoint in this API.    &gt; **Important:** You can\&#39;t perform operations on articles with a &#x60;quantity&#x60; of 0 (zero). Instead, use the &#x60;add&#x60; operation to add the article with a &#x60;quantity&#x60; greater than 0 to the list first. Once the article has been successfully added, you can use the other operations to make any necessary changes or updates.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchMyList(
      brand: string,
      id: string,
      locale: string,
      xUserId?: string,
      body?: object,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyList>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.patchMyList(brand, id, locale, xUserId, body, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify a locale and brand to create a new list. This endpoint accepts a JSON object in the request body, which should contain the list data to store.   A successful response includes the newly created list, including its ID.
     * @summary Create a list.
     * @param {string} brand The brand associated with the list(s). The Lists API supports all brands available in the Online Catalog.  You can find a list of available brands in this [user guide](/locales).
     * @param {string} locale The list\&#39;s locale. The Lists API supports all locales available in the Online Catalog.  You can find a list of available locales in this [user guide](/locales).
     * @param {string} [xUserId] The user ID that you use to identify the current user.  This parameter is optional so anonymous users can also create a list in the Online Catalog.
     * @param {StoreMyList} [storeMyList] Define the new list data as JSON using the &#x60;StoreMyList&#x60; schema. Note that the order of products and articles in the JSON corresponds to the order of the list entries as specified by the user in the Online Catalog.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postMyList(
      brand: string,
      locale: string,
      xUserId?: string,
      storeMyList?: StoreMyList,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyList>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postMyList(brand, locale, xUserId, storeMyList, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify a list ID, locale, brand, and user ID to update an existing list. This endpoint accepts a JSON object containing the list\'s updated data in the request body.  A successful response returns the updated list, including any changes made as a result of the PUT request.
     * @summary Update a list.
     * @param {string} brand The brand associated with the list(s). The Lists API supports all brands available in the Online Catalog.  You can find a list of available brands in this [user guide](/locales).
     * @param {string} id The unique identifier of the list you want to update.
     * @param {string} locale The list\&#39;s locale. The Lists API supports all locales available in the Online Catalog.  You can find a list of available locales in this [user guide](/locales).
     * @param {string} [xUserId] The user ID that you use to identify the current user.  Only the user who owns a list can update it.
     * @param {StoreMyList} [storeMyList] Define the updated list data as JSON using the &#x60;StoreMyList&#x60; schema. Make sure you supply all the data the list should contain, as this endpoint overwrites the currently stored list and removes any missing attributes. Also note that to update the order of products and articles in the list, you need to provide the data in the desired order in the JSON.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putMyList(
      brand: string,
      id: string,
      locale: string,
      xUserId?: string,
      storeMyList?: StoreMyList,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyList>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.putMyList(
        brand,
        id,
        locale,
        xUserId,
        storeMyList,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * ListApi - factory interface
 * @export
 */
export const ListApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ListApiFp(configuration)
  return {
    /**
     * Specify a list ID, user ID, and brand to delete a specific list.  This endpoint permanently deletes the list, so use it with caution.
     * @summary Delete a list by ID.
     * @param {ListApiDeleteMyListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMyList(requestParameters: ListApiDeleteMyListRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
      return localVarFp
        .deleteMyList(requestParameters.brand, requestParameters.id, requestParameters.xUserId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify a brand, user ID, and a list of list IDs you want to delete.  This endpoint permanently deletes the specified list entries, so use it with caution.
     * @summary Delete one or more lists.
     * @param {ListApiDeleteMyListsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMyLists(requestParameters: ListApiDeleteMyListsRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
      return localVarFp
        .deleteMyLists(requestParameters.brand, requestParameters.ids, requestParameters.xUserId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify a list ID, locale, and brand to retrieve a specific list. This endpoint returns the details of the list, including its name, added products, and articles.  The order of products and articles in the JSON response corresponds to the order specified by the user in the Online Catalog.
     * @summary Get a list by ID.
     * @param {ListApiGetMyListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMyList(requestParameters: ListApiGetMyListRequest, options?: AxiosRequestConfig): AxiosPromise<MyList> {
      return localVarFp
        .getMyList(
          requestParameters.brand,
          requestParameters.id,
          requestParameters.locale,
          requestParameters.xUserId,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify a locale, brand, and user ID to retrieve a user\'s lists. This endpoint also supports pagination parameters that allow you to filter the returned data.    The order of products and articles in the JSON response corresponds to the order specified by the user in the Online Catalog.
     * @summary Get list(s) by user ID.
     * @param {ListApiGetMyListsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMyLists(requestParameters: ListApiGetMyListsRequest, options?: AxiosRequestConfig): AxiosPromise<PagedResult> {
      return localVarFp
        .getMyLists(
          requestParameters.brand,
          requestParameters.locale,
          requestParameters.xUserId,
          requestParameters.offset,
          requestParameters.limit,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify a locale, brand, and user ID to retrieve the metadata for a user\'s lists. This endpoint also supports pagination parameters that allow you to filter the returned data.  The response contains basic information about each list, such as its name, ID, and associated user ID, but does not include any products added to the list.
     * @summary Get list(s) metadata by user ID.
     * @param {ListApiGetMyListsMetaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMyListsMeta(
      requestParameters: ListApiGetMyListsMetaRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<PagedMetaResult> {
      return localVarFp
        .getMyListsMeta(
          requestParameters.brand,
          requestParameters.xUserId,
          requestParameters.offset,
          requestParameters.limit,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * To update an existing list, provide the list ID, brand, and patch data in the request body. This endpoint uses the [JSON Patch](https://www.rfc-editor.org/rfc/rfc6902) that allows you to specify operations to apply to the target list. For example, you can add a new article or update the quantity of an existing article in the list.  > **Note**: > Be sure to read the description of the request body and check the examples we\'ve provided to gain a better understanding of how to use this endpoint.  A successful response returns the updated list, including any changes made as a result of the PATCH request.
     * @summary Update a list using JSON Patch.
     * @param {ListApiPatchMyListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchMyList(requestParameters: ListApiPatchMyListRequest, options?: AxiosRequestConfig): AxiosPromise<MyList> {
      return localVarFp
        .patchMyList(
          requestParameters.brand,
          requestParameters.id,
          requestParameters.locale,
          requestParameters.xUserId,
          requestParameters.body,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify a locale and brand to create a new list. This endpoint accepts a JSON object in the request body, which should contain the list data to store.   A successful response includes the newly created list, including its ID.
     * @summary Create a list.
     * @param {ListApiPostMyListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postMyList(requestParameters: ListApiPostMyListRequest, options?: AxiosRequestConfig): AxiosPromise<MyList> {
      return localVarFp
        .postMyList(
          requestParameters.brand,
          requestParameters.locale,
          requestParameters.xUserId,
          requestParameters.storeMyList,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify a list ID, locale, brand, and user ID to update an existing list. This endpoint accepts a JSON object containing the list\'s updated data in the request body.  A successful response returns the updated list, including any changes made as a result of the PUT request.
     * @summary Update a list.
     * @param {ListApiPutMyListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putMyList(requestParameters: ListApiPutMyListRequest, options?: AxiosRequestConfig): AxiosPromise<MyList> {
      return localVarFp
        .putMyList(
          requestParameters.brand,
          requestParameters.id,
          requestParameters.locale,
          requestParameters.xUserId,
          requestParameters.storeMyList,
          options
        )
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for deleteMyList operation in ListApi.
 * @export
 * @interface ListApiDeleteMyListRequest
 */
export interface ListApiDeleteMyListRequest {
  /**
   * The brand associated with the list(s). The Lists API supports all brands available in the Online Catalog.  You can find a list of available brands in this [user guide](/locales).
   * @type {string}
   * @memberof ListApiDeleteMyList
   */
  readonly brand: string

  /**
   * The unique identifier of the list you want to delete.
   * @type {string}
   * @memberof ListApiDeleteMyList
   */
  readonly id: string

  /**
   * The user ID that you use to identify the current user.  Only the user who owns a list can delete it.
   * @type {string}
   * @memberof ListApiDeleteMyList
   */
  readonly xUserId?: string
}

/**
 * Request parameters for deleteMyLists operation in ListApi.
 * @export
 * @interface ListApiDeleteMyListsRequest
 */
export interface ListApiDeleteMyListsRequest {
  /**
   * The brand associated with the list(s). The Lists API supports all brands available in the Online Catalog.  You can find a list of available brands in this [user guide](/locales).
   * @type {string}
   * @memberof ListApiDeleteMyLists
   */
  readonly brand: string

  /**
   * A list of unique identifiers of the lists you want to delete.
   * @type {Array<string>}
   * @memberof ListApiDeleteMyLists
   */
  readonly ids: Array<string>

  /**
   * The user ID that you use to identify the current user.  Only the user who owns a list can delete it.
   * @type {string}
   * @memberof ListApiDeleteMyLists
   */
  readonly xUserId?: string
}

/**
 * Request parameters for getMyList operation in ListApi.
 * @export
 * @interface ListApiGetMyListRequest
 */
export interface ListApiGetMyListRequest {
  /**
   * The brand associated with the list(s). The Lists API supports all brands available in the Online Catalog.  You can find a list of available brands in this [user guide](/locales).
   * @type {string}
   * @memberof ListApiGetMyList
   */
  readonly brand: string

  /**
   * The unique identifier of the list you want to retrieve.
   * @type {string}
   * @memberof ListApiGetMyList
   */
  readonly id: string

  /**
   * The list\&#39;s locale. The Lists API supports all locales available in the Online Catalog.  You can find a list of available locales in this [user guide](/locales).
   * @type {string}
   * @memberof ListApiGetMyList
   */
  readonly locale: string

  /**
   * The user ID that you use to identify the current user.  This parameter is optional so that users can, for example, share a list with another user (read access), regardless of whether they are logged into the Online Catalog or anonymous.
   * @type {string}
   * @memberof ListApiGetMyList
   */
  readonly xUserId?: string
}

/**
 * Request parameters for getMyLists operation in ListApi.
 * @export
 * @interface ListApiGetMyListsRequest
 */
export interface ListApiGetMyListsRequest {
  /**
   * The brand associated with the list(s). The Lists API supports all brands available in the Online Catalog.  You can find a list of available brands in this [user guide](/locales).
   * @type {string}
   * @memberof ListApiGetMyLists
   */
  readonly brand: string

  /**
   * The list\&#39;s locale. The Lists API supports all locales available in the Online Catalog.  You can find a list of available locales in this [user guide](/locales).
   * @type {string}
   * @memberof ListApiGetMyLists
   */
  readonly locale: string

  /**
   * The user ID that you use to identify the current user.  The endpoint requires this parameter to prevent read access to all stored lists.
   * @type {string}
   * @memberof ListApiGetMyLists
   */
  readonly xUserId: string

  /**
   * Format - int32. The number of lists to skip. Used for pagination. The default value is 0.
   * @type {number}
   * @memberof ListApiGetMyLists
   */
  readonly offset?: number

  /**
   * Format - int32. The total number of lists to return. Used for pagination. The default value is 10.
   * @type {number}
   * @memberof ListApiGetMyLists
   */
  readonly limit?: number
}

/**
 * Request parameters for getMyListsMeta operation in ListApi.
 * @export
 * @interface ListApiGetMyListsMetaRequest
 */
export interface ListApiGetMyListsMetaRequest {
  /**
   * The brand associated with the list(s). The Lists API supports all brands available in the Online Catalog.  You can find a list of available brands in this [user guide](/locales).
   * @type {string}
   * @memberof ListApiGetMyListsMeta
   */
  readonly brand: string

  /**
   * The user ID that you use to identify the current user.  The endpoint requires this parameter to prevent read access to all stored lists.
   * @type {string}
   * @memberof ListApiGetMyListsMeta
   */
  readonly xUserId: string

  /**
   * Format - int32. The number of lists to skip. Used for pagination. The default value is 0.
   * @type {number}
   * @memberof ListApiGetMyListsMeta
   */
  readonly offset?: number

  /**
   * Format - int32. The total number of lists to return. Used for pagination. The default value is 10.
   * @type {number}
   * @memberof ListApiGetMyListsMeta
   */
  readonly limit?: number
}

/**
 * Request parameters for patchMyList operation in ListApi.
 * @export
 * @interface ListApiPatchMyListRequest
 */
export interface ListApiPatchMyListRequest {
  /**
   * The brand associated with the list(s). The Lists API supports all brands available in the Online Catalog.  You can find a list of available brands in this [user guide](/locales).
   * @type {string}
   * @memberof ListApiPatchMyList
   */
  readonly brand: string

  /**
   * The unique identifier of the list you want to update.
   * @type {string}
   * @memberof ListApiPatchMyList
   */
  readonly id: string

  /**
   * The list\&#39;s locale. The Lists API supports all locales available in the Online Catalog.  You can find a list of available locales in this [user guide](/locales).
   * @type {string}
   * @memberof ListApiPatchMyList
   */
  readonly locale: string

  /**
   * The user ID that you use to identify the current user.  Only the user who owns a list can update it.
   * @type {string}
   * @memberof ListApiPatchMyList
   */
  readonly xUserId?: string

  /**
   *   Define an array of patch operations (the JSON Patch document) to apply to the target list. Use the &#x60;StoreMyList&#x60; Schema as a reference to see which fields you can update and what structure to follow.    &gt; **Important:** We highly recommend that you read the [JSON Patch definition](https://www.rfc-editor.org/rfc/rfc6902) before using this endpoint. Understanding how JSON Patch works is essential to using this endpoint successfully. If you\&#39;re unsure, we recommend trying to accomplish your goal using the \&quot;Update a list\&quot; endpoint, which uses the PUT method. You can find this endpoint in this API.    &gt; **Important:** You can\&#39;t perform operations on articles with a &#x60;quantity&#x60; of 0 (zero). Instead, use the &#x60;add&#x60; operation to add the article with a &#x60;quantity&#x60; greater than 0 to the list first. Once the article has been successfully added, you can use the other operations to make any necessary changes or updates.
   * @type {object}
   * @memberof ListApiPatchMyList
   */
  readonly body?: object
}

/**
 * Request parameters for postMyList operation in ListApi.
 * @export
 * @interface ListApiPostMyListRequest
 */
export interface ListApiPostMyListRequest {
  /**
   * The brand associated with the list(s). The Lists API supports all brands available in the Online Catalog.  You can find a list of available brands in this [user guide](/locales).
   * @type {string}
   * @memberof ListApiPostMyList
   */
  readonly brand: string

  /**
   * The list\&#39;s locale. The Lists API supports all locales available in the Online Catalog.  You can find a list of available locales in this [user guide](/locales).
   * @type {string}
   * @memberof ListApiPostMyList
   */
  readonly locale: string

  /**
   * The user ID that you use to identify the current user.  This parameter is optional so anonymous users can also create a list in the Online Catalog.
   * @type {string}
   * @memberof ListApiPostMyList
   */
  readonly xUserId?: string

  /**
   * Define the new list data as JSON using the &#x60;StoreMyList&#x60; schema. Note that the order of products and articles in the JSON corresponds to the order of the list entries as specified by the user in the Online Catalog.
   * @type {StoreMyList}
   * @memberof ListApiPostMyList
   */
  readonly storeMyList?: StoreMyList
}

/**
 * Request parameters for putMyList operation in ListApi.
 * @export
 * @interface ListApiPutMyListRequest
 */
export interface ListApiPutMyListRequest {
  /**
   * The brand associated with the list(s). The Lists API supports all brands available in the Online Catalog.  You can find a list of available brands in this [user guide](/locales).
   * @type {string}
   * @memberof ListApiPutMyList
   */
  readonly brand: string

  /**
   * The unique identifier of the list you want to update.
   * @type {string}
   * @memberof ListApiPutMyList
   */
  readonly id: string

  /**
   * The list\&#39;s locale. The Lists API supports all locales available in the Online Catalog.  You can find a list of available locales in this [user guide](/locales).
   * @type {string}
   * @memberof ListApiPutMyList
   */
  readonly locale: string

  /**
   * The user ID that you use to identify the current user.  Only the user who owns a list can update it.
   * @type {string}
   * @memberof ListApiPutMyList
   */
  readonly xUserId?: string

  /**
   * Define the updated list data as JSON using the &#x60;StoreMyList&#x60; schema. Make sure you supply all the data the list should contain, as this endpoint overwrites the currently stored list and removes any missing attributes. Also note that to update the order of products and articles in the list, you need to provide the data in the desired order in the JSON.
   * @type {StoreMyList}
   * @memberof ListApiPutMyList
   */
  readonly storeMyList?: StoreMyList
}

/**
 * ListApi - object-oriented interface
 * @export
 * @class ListApi
 * @extends {BaseAPI}
 */
export class ListApi extends BaseAPI {
  /**
   * Specify a list ID, user ID, and brand to delete a specific list.  This endpoint permanently deletes the list, so use it with caution.
   * @summary Delete a list by ID.
   * @param {ListApiDeleteMyListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ListApi
   */
  public deleteMyList(requestParameters: ListApiDeleteMyListRequest, options?: AxiosRequestConfig) {
    return ListApiFp(this.configuration)
      .deleteMyList(requestParameters.brand, requestParameters.id, requestParameters.xUserId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify a brand, user ID, and a list of list IDs you want to delete.  This endpoint permanently deletes the specified list entries, so use it with caution.
   * @summary Delete one or more lists.
   * @param {ListApiDeleteMyListsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ListApi
   */
  public deleteMyLists(requestParameters: ListApiDeleteMyListsRequest, options?: AxiosRequestConfig) {
    return ListApiFp(this.configuration)
      .deleteMyLists(requestParameters.brand, requestParameters.ids, requestParameters.xUserId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify a list ID, locale, and brand to retrieve a specific list. This endpoint returns the details of the list, including its name, added products, and articles.  The order of products and articles in the JSON response corresponds to the order specified by the user in the Online Catalog.
   * @summary Get a list by ID.
   * @param {ListApiGetMyListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ListApi
   */
  public getMyList(requestParameters: ListApiGetMyListRequest, options?: AxiosRequestConfig) {
    return ListApiFp(this.configuration)
      .getMyList(
        requestParameters.brand,
        requestParameters.id,
        requestParameters.locale,
        requestParameters.xUserId,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify a locale, brand, and user ID to retrieve a user\'s lists. This endpoint also supports pagination parameters that allow you to filter the returned data.    The order of products and articles in the JSON response corresponds to the order specified by the user in the Online Catalog.
   * @summary Get list(s) by user ID.
   * @param {ListApiGetMyListsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ListApi
   */
  public getMyLists(requestParameters: ListApiGetMyListsRequest, options?: AxiosRequestConfig) {
    return ListApiFp(this.configuration)
      .getMyLists(
        requestParameters.brand,
        requestParameters.locale,
        requestParameters.xUserId,
        requestParameters.offset,
        requestParameters.limit,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify a locale, brand, and user ID to retrieve the metadata for a user\'s lists. This endpoint also supports pagination parameters that allow you to filter the returned data.  The response contains basic information about each list, such as its name, ID, and associated user ID, but does not include any products added to the list.
   * @summary Get list(s) metadata by user ID.
   * @param {ListApiGetMyListsMetaRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ListApi
   */
  public getMyListsMeta(requestParameters: ListApiGetMyListsMetaRequest, options?: AxiosRequestConfig) {
    return ListApiFp(this.configuration)
      .getMyListsMeta(
        requestParameters.brand,
        requestParameters.xUserId,
        requestParameters.offset,
        requestParameters.limit,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * To update an existing list, provide the list ID, brand, and patch data in the request body. This endpoint uses the [JSON Patch](https://www.rfc-editor.org/rfc/rfc6902) that allows you to specify operations to apply to the target list. For example, you can add a new article or update the quantity of an existing article in the list.  > **Note**: > Be sure to read the description of the request body and check the examples we\'ve provided to gain a better understanding of how to use this endpoint.  A successful response returns the updated list, including any changes made as a result of the PATCH request.
   * @summary Update a list using JSON Patch.
   * @param {ListApiPatchMyListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ListApi
   */
  public patchMyList(requestParameters: ListApiPatchMyListRequest, options?: AxiosRequestConfig) {
    return ListApiFp(this.configuration)
      .patchMyList(
        requestParameters.brand,
        requestParameters.id,
        requestParameters.locale,
        requestParameters.xUserId,
        requestParameters.body,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify a locale and brand to create a new list. This endpoint accepts a JSON object in the request body, which should contain the list data to store.   A successful response includes the newly created list, including its ID.
   * @summary Create a list.
   * @param {ListApiPostMyListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ListApi
   */
  public postMyList(requestParameters: ListApiPostMyListRequest, options?: AxiosRequestConfig) {
    return ListApiFp(this.configuration)
      .postMyList(
        requestParameters.brand,
        requestParameters.locale,
        requestParameters.xUserId,
        requestParameters.storeMyList,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify a list ID, locale, brand, and user ID to update an existing list. This endpoint accepts a JSON object containing the list\'s updated data in the request body.  A successful response returns the updated list, including any changes made as a result of the PUT request.
   * @summary Update a list.
   * @param {ListApiPutMyListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ListApi
   */
  public putMyList(requestParameters: ListApiPutMyListRequest, options?: AxiosRequestConfig) {
    return ListApiFp(this.configuration)
      .putMyList(
        requestParameters.brand,
        requestParameters.id,
        requestParameters.locale,
        requestParameters.xUserId,
        requestParameters.storeMyList,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }
}
