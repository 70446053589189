/* eslint @typescript-eslint/no-var-requires: "off" */
const path = require("path")
const domainConfig = require("./domains.config")

const PATH_TO_TRANSLATION = "/translation-live"

const locales = Array.from(new Set(domainConfig.brandDomains.map((domain) => domain.locales).flat(1)))

const i18n = {
  defaultLocale: "placeholder-en-EU",
  localeDetection: false,
  locales: ["placeholder-en-EU", ...locales],
}

const next18nextConfig = {
  i18n,
  localeExtension: "yaml",
  localePath: path.resolve(`..${PATH_TO_TRANSLATION}`), //translations should be located in a 'sibling' directory
  compatibilityJSON: "v3",
  defaultNS: "main",
  supportedLngs: i18n.locales,
  ns: domainConfig.namespaces, // the namespaces needs to be listed here, to make sure they got preloaded
  reloadOnPrerender: false,
}

module.exports = next18nextConfig
