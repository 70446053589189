import { getCookie, setCookie } from "cookies-next"
import { mutate } from "swr"
import { MyList, StoreMyList } from "../../api-clients/mylist-api"
import { myListCookie } from "../constants/browser-storage"
import { ALLOWED_MYLIST_NAME_LENGTH, apiRoutes } from "../constants/constants"
import { fetchProduct, fetchProductByArticleNumber, getArticleAvailabilityForBrandAndLocale } from "../hooks/queries"
import { checkIsProduct } from "./product-utils"

/* Get all available and selected articles of a given My List */
export const getListArticles = (list: MyList): string[] => {
  const articles = []

  if (!!list?.products?.length) {
    list.products.forEach((product) => {
      if (!!product?.articles?.length) {
        product.articles.forEach((article) => {
          if (article.available && !!article.quantity) articles.push(article.id)
        })
      }
    })
  }

  return articles
}

/* Format the date comming from the myList API from yyyy-mm-dd to dd.mm.yyyy*/
export const formatApiDate = (
  date: Date,
  formatLocale: string | string[] = "de-CH",
  dateTimeFormatOptions: Intl.DateTimeFormatOptions = { day: "2-digit", month: "2-digit", year: "numeric" }
): string => new Intl.DateTimeFormat(formatLocale, dateTimeFormatOptions).format(date)

/* Refresh myListId cookie age if updated list id is the same as the stored list id */
export const refreshMyListIdCookie = (updatedListId: string) => {
  const cookieListId = getCookie(myListCookie.KEY)
  if (cookieListId && cookieListId === updatedListId) {
    setCookie(myListCookie.KEY, cookieListId, { maxAge: myListCookie.MAX_AGE })
  }
}

export const createDefaultList = async ({
  createList,
  isUserLoggedIn,
  anonymousList,
  defaultName,
}: {
  createList: (newList: StoreMyList) => void
  isUserLoggedIn: boolean
  anonymousList: MyList | null
  defaultName: string
}) => {
  let newList = {}
  /* Handle logic for logged-in user */
  if (isUserLoggedIn) {
    if (anonymousList) {
      /* If there is a stored anonymous list for the user,
       copy it as the first user list and set it as the default one. */
      newList = { ...anonymousList, isDefault: true }
    } else {
      /* If there is no anonymous list stored for the user, just create a new default one for him. */
      newList = { isDefault: true, name: defaultName }
    }
    /* Handle logic for not logged-in user */
  } else {
    /* If user os not logged in, just create a list with the default name.
      Anonymous users don't have a default list. */
    newList = { name: defaultName }
  }

  /* Create the prepared new list, the userId logic is handled inside the useUserLists hook */
  createList(newList)
}

/* Add article to a list, handle logic for existing product in the list */
export const addArticleToList = async ({
  itemId,
  listData,
  locale,
  brand,
}: {
  itemId: string
  listData: MyList
  locale: string
  brand: string
}) => {
  try {
    const productToAdd = await fetchProductByArticleNumber(locale, brand, itemId, "search")

    if (productToAdd) {
      let newProductsData = null
      let isArticleQuantityUpdated = false
      const { products } = listData
      /* If product already in the list, update the quanity of the article to 1 if it was zero 
          or do nothing if it's more than zero */
      const isExistingProduct = products.some((pro) => {
        if (pro.id === productToAdd.key) {
          pro.articles.some((art) => {
            if (art.id === itemId) {
              if (art.quantity === 0) {
                art.quantity = 1
                isArticleQuantityUpdated = true
              }
              return true
            }
            return false
          })
          return true
        }
        return false
      })

      if (isExistingProduct) {
        if (isArticleQuantityUpdated) newProductsData = products.slice()
      } else {
        /* Create a new StoreListProduct object and add it to the end of the products array */
        const storeProductToAdd = {
          id: productToAdd.key,
          articles: productToAdd.articles.articlesList.map((article) => {
            const quantity = article.key === itemId ? 1 : 0
            return { id: article.key, quantity }
          }),
        }
        newProductsData = [...products, storeProductToAdd]
      }

      /* If product list has been updated persist it and update the local state */
      if (newProductsData) {
        return Promise.resolve({ updatedListData: { ...listData, products: newProductsData }, isAdded: true })
      }
      return Promise.resolve({ updatedListData: listData, isAdded: false })
    }
  } catch {
    return Promise.resolve({ updatedListData: listData, isAdded: false })
  }
}

/* Add product to a list, handle logic for existing product in the list */
export const addProductToList = async ({
  itemId,
  listData,
  locale,
  brand,
}: {
  itemId: string
  listData: MyList
  locale: string
  brand: string
}) => {
  try {
    const productToAdd = await fetchProduct(locale, brand, itemId, "search")

    if (productToAdd) {
      let newProductsData = null
      let isArticleQuantityUpdated = false
      const { products } = listData
      /* If product already in the list, update the quanity of the article to 1 if it was zero 
          or do nothing if it's more than zero */
      const isExistingProduct = products.some((pro) => {
        if (pro.id === productToAdd.key) {
          pro.articles.forEach((art) => {
            if (art.quantity === 0) {
              art.quantity = 1
              isArticleQuantityUpdated = true
            }
          })
          return true
        }
        return false
      })

      if (isExistingProduct) {
        if (isArticleQuantityUpdated) newProductsData = products.slice()
      } else {
        /* Create a new StoreListProduct object and add it to the end of the products array */
        const storeProductToAdd = {
          id: productToAdd.key,
          articles: productToAdd.articles.articlesList.map((article) => {
            const quantity = 1
            return { id: article.key, quantity }
          }),
        }
        newProductsData = [...products, storeProductToAdd]
      }

      /* If product list has been updated persist it and update the local state */
      if (newProductsData) {
        return Promise.resolve({ updatedListData: { ...listData, products: newProductsData }, isAdded: true })
      }
      return Promise.resolve({ updatedListData: listData, isAdded: false })
    }
  } catch {
    return Promise.resolve({ updatedListData: listData, isAdded: false })
  }
}

export const addItemToList = async ({ itemId, listData, locale, brand, updateList }) => {
  const isProduct = checkIsProduct(itemId)

  const addToList = isProduct ? addProductToList : addArticleToList

  const { updatedListData, isAdded } = await addToList({
    itemId,
    listData,
    locale,
    brand,
  })

  if (isAdded) updateList(updatedListData)

  return isAdded
}

/* Check if an article is already in a list and has quantity bigger than 0 */
export const isArticleInList = (articleNumber: string, listData: MyList) => {
  const { products } = listData

  return products.some((pro) => {
    const { articles } = pro

    const availableArticles = articles?.filter((art) => art.available)

    return availableArticles.some((art) => art.id === articleNumber && art.quantity > 0)
  })
}

/* Check if a product is already in a list and every of its articles has quantity bigger than 0 */
export const isProductInList = (productId: string, listData: MyList) => {
  const { products } = listData

  return products.some((pro) => {
    if (pro.id === productId) {
      const { articles } = pro

      const availableArticles = articles?.filter((art) => art.available)

      return availableArticles.every((art) => art.quantity > 0)
    }

    return false
  })
}

/* Sort the default user list to be first and get max of 4 user lists to display */
export const sortUserLists = (userLists: MyList[], maxNumberOfUserLists = 100) => {
  const defaultUserList = userLists.find((list) => list.isDefault)
  const nonDefaultLists = userLists.filter((list) => !list.isDefault)

  return defaultUserList
    ? [defaultUserList, ...nonDefaultLists.slice(0, maxNumberOfUserLists - 1)]
    : nonDefaultLists.slice(0, maxNumberOfUserLists)
}

export const getCopyListName = (listName: string, copyLabel: string): string => {
  if (!listName) listName = ""

  const listNameLength = listName.length
  const formattedCopyLabel = ` (${copyLabel})`

  const newNameLength = formattedCopyLabel.length + listNameLength

  if (newNameLength < ALLOWED_MYLIST_NAME_LENGTH) {
    return `${listName}${formattedCopyLabel}`
  } else {
    return `${listName?.slice(0, listNameLength - (newNameLength - ALLOWED_MYLIST_NAME_LENGTH))}${formattedCopyLabel}`
  }
}

export const getIsArticleAvailable = async (locale: string, brand: string, articleNumber: string) => {
  try {
    const articleAvailabilityData = await getArticleAvailabilityForBrandAndLocale(locale, brand, articleNumber)
    return !!articleAvailabilityData?.assortments?.length ? true : false
  } catch (e) {
    return false
  }
}

/* Function to imperatively revalidate the cache for the user lists */
export const refreshUserLists = (updatedList: MyList, locale: string, brand: string, userId: string) =>
  mutate(
    `${apiRoutes.LISTS}?locale=${locale}&brand=${brand}&userId=${userId}`,
    (currentUserListsData) => {
      if (currentUserListsData) {
        const { results: lists } = currentUserListsData

        const updatedLists = lists.map((list) => {
          if (list.id === updatedList.id) return { ...list, ...updatedList }
          return list
        })

        return { ...currentUserListsData, results: updatedLists }
      }
    },
    { revalidate: false }
  )

export const refreshUserList = (updatedList: MyList, locale: string, brand: string, userId: string) =>
  mutate(
    `${apiRoutes.LISTS}/${updatedList.id}?locale=${locale}&brand=${brand}&userId=${userId}`,
    (currentUserListData) => {
      if (currentUserListData) {
        return { ...currentUserListData, ...updatedList }
      }
    },
    { revalidate: false }
  )

/* Wrapper for the SWR update trigger function with optimization options for the SWR cache management */
export const updateUserList = (
  updateListTrigger: (listData: MyList, options: unknown) => void,
  updateListData: MyList,
  locale: string,
  brand: string,
  userId: string
) => {
  updateListTrigger(updateListData, {
    /* Optimistically update the SWR cache with the data we pass to the API, before getting the response */
    optimisticData: updateListData,
    /* Rollback to previous cache state if we get an error from the update operation in the API */
    rollbackOnError: true,
    /* Populate the SWR cache for this url key with the response from the PUT API call */
    populateCache: (updatedList: MyList) => updatedList,
    /* Don't update the cache in the useGetList as it is already updated with the response from useUpdateList.
          Both SWR hooks share the same cache as they use the same url key. */
    revalidate: false,
    onSuccess: (updatedList: MyList) => {
      refreshMyListIdCookie(updatedList?.id)
      if (userId) refreshUserLists(updatedList, locale, brand, userId)
    },
  })
}
