import pino from "pino"

const pinoTransportOptions =
  process.env.NODE_ENV === "development"
    ? {
        transport: {
          targets: [
            {
              target: "pino-pretty",
              options: {
                colorize: true,
                translateTime: true,
              },
            },
          ],
        },
      }
    : {}

const logger =
  typeof window === "undefined"
    ? pino({
        level: "info", // Set the log level
        ...pinoTransportOptions,
      })
    : {
        // patch for client side logging
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        info: () => {},
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        error: () => {},
      }

export default logger
