import { Box, Button } from "@chakra-ui/react"
import dynamic from "next/dynamic"
import Head from "next/head"
import { useRouter } from "next/router"
import domainsConfig from "../../../domains.config"
import { brands } from "../../common/constants/constants"
import { useBrandedTranslation } from "../../common/hooks/useBrandedTranslation"
import Breadcrumbs from "../shared/Breadcrumbs/Breadcrumbs"
import ErrorView from "../shared/ErrorView"

const RichTextLabel = dynamic(() => import("../../components/shared/RichTextLabel"), {
  ssr: false,
})

type Props = {
  brand: string
}

export const ErrorFallback = ({ brand }: Props) => {
  const { t } = useBrandedTranslation()
  const router = useRouter()

  const crumbs = [{ name: t("page-error-breadcrumb"), href: "/" }]

  const mainTitleFontWeight = brands[brand ?? domainsConfig.defaultBrand]?.mainTitleFontWeight
  const subtitleFontWeight = brands[brand ?? domainsConfig.defaultBrand]?.subTitleFontWeight

  return (
    <>
      <Head>
        <title>{t("home-title")}</title>
      </Head>

      <Breadcrumbs crumbs={crumbs} />

      <ErrorView>
        <RichTextLabel
          i18nKey={"page-error_rtf"}
          styleProps={{
            sx: {
              h1: {
                fontSize: { base: "5xl", sm: "7xl", md: "8xl" },
                fontWeight: mainTitleFontWeight,
                lineHeight: 1.25,
                mb: 0,
                textTransform: "uppercase",
              },
              h2: {
                fontSize: { base: "5xl", sm: "7xl", md: "8xl" },
                fontWeight: subtitleFontWeight,
                lineHeight: 1.25,
                mb: 0,
                textTransform: "uppercase",
              },
              p: {
                mt: 10,
              },
              fontSize: "md",
            },
          }}
        />

        <Box>
          <Button px="6" mt="10" border="1px solid" variant="secondary" color="black" onClick={() => router.push("/")}>
            {t("pageNotFound-goBackHome")}
          </Button>
        </Box>
      </ErrorView>
    </>
  )
}
