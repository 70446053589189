/* This file contains the common colors shared between all brands.
  Brand specific colors are in colors-brand.ts */
const colors = {
  /* GRAY SCALE */
  gray: {
    25: "#FCFCFC", // CL02
    50: "#F7F7F7", // CL03
    100: "#F5F5F5", // CL04
    150: "#F1F1F1", // CL05
    200: "#E6E6E6", // CL06
    250: "#E3E3E3", // CL07
    300: "#D9D9D9", // CL08
    350: "#CCCCCC", // CL09
    400: "#B4B4B4", // CL10
    500: "#999999", // CL11
    600: "#707070", // CL12
    700: "#666666", // CL13
    800: "#5C5C5C", // CL14
    900: "#3D3D3D", // CL15
  },
  /* SIGNAL COLORS */
  success: "#008800", // CL20
  successTransparency10: "#0088001A", // CL20 with 10% transparency
  warning: "#F07800", // CL21
  warningTransparency10: "#F078001A", // CL21 with 10% transparency
  error: "#CE0000", // CL22
  errorTransparency10: "#CE00001A", // CL22 with 10% transparency

  /* These three colors are used exclusively by the article table */
  baseWhite: "#FFFFFF", // CL01
  black: "#000000", // CL16
  replacementInfo: "#EBF0F7",
}

export default colors
