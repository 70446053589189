import { Button, Menu, MenuButton, MenuItemOption, MenuList, MenuOptionGroup, Spacer, Text } from "@chakra-ui/react"
import { useRouter } from "next/router"
import { useMemo } from "react"
import { getAvailableLanguages } from "../../../../common/utils/locale-utils"
import { CCheckmark, CChevronDown, CChevronUp } from "../../../shared/Icons"

const LanguagesMenu = () => {
  const router = useRouter()

  // Get all available languages for the current domain.
  const availableLanguages = useMemo(
    () => getAvailableLanguages(router.locale, router.locales),
    [router.locale, router.locales]
  )

  const selectedLanguage = availableLanguages.find((lng) => lng.locale === router.locale)

  const handleLanguageChange = (value: string) => {
    router.push("/", null, { locale: value })
  }

  return availableLanguages?.length > 1 ? (
    <Menu gutter={0} autoSelect={false}>
      {({ isOpen }) => (
        <>
          <MenuButton
            as={Button}
            rightIcon={
              isOpen ? (
                <CChevronUp w="3" h="3" ml="5" mb="1" mr="1" />
              ) : (
                <CChevronDown w="3" h="3" ml="5" mb="1" mr="1" />
              )
            }
            fontWeight="normal"
            fontSize="xs"
            color={isOpen ? "black" : "gray.700"}
            variant="dropdown"
            w="12"
          >
            <Text casing="uppercase" ml="1">
              {selectedLanguage.languageCode}
            </Text>
          </MenuButton>
          <MenuList minWidth="60" boxShadow="xl">
            <MenuOptionGroup defaultValue={selectedLanguage.locale} type="radio" onChange={handleLanguageChange}>
              {availableLanguages.map((al) => (
                <MenuItemOption
                  key={al.locale}
                  value={al.locale}
                  icon={<CCheckmark mb="1" />}
                  iconSpacing="2.5"
                  color="black"
                  _checked={{ color: "brand.500" }}
                >
                  <Text casing="capitalize" fontSize="sm" fontWeight="medium" lineHeight="base">
                    {al.displayLanguage}
                  </Text>
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          </MenuList>
        </>
      )}
    </Menu>
  ) : (
    <Spacer />
  )
}

export default LanguagesMenu
