import { ComponentStyleConfig } from "@chakra-ui/react"

const Divider: ComponentStyleConfig = {
  baseStyle: {
    borderColor: "gray.300",
    opacity: "1",
  },
}

export default Divider
