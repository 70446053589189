/* tslint:disable */
/* eslint-disable */
/**
 * Services api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { AxiosInstance, AxiosPromise, AxiosRequestConfig } from "axios"
import globalAxios from "axios"
import type { Configuration } from "./configuration"
// Some imports not used depending on template conditions
// @ts-ignore
import type { RequestArgs } from "./base"
import {
  assertParamExists,
  createRequestFunction,
  DUMMY_BASE_URL,
  serializeDataIfNeeded,
  setApiKeyToObject,
  setSearchParams,
  toPathString,
} from "./common"
// @ts-ignore
import { BaseAPI, BASE_PATH } from "./base"

/**
 *
 * @export
 * @interface Catalog
 */
export interface Catalog {
  /**
   *
   * @type {string}
   * @memberof Catalog
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof Catalog
   */
  creator?: string
  /**
   *
   * @type {string}
   * @memberof Catalog
   */
  description?: string
  /**
   *
   * @type {string}
   * @memberof Catalog
   */
  wishlistName?: string
  /**
   *
   * @type {Array<string>}
   * @memberof Catalog
   */
  articleSkuList?: Array<string>
}
/**
 *
 * @export
 * @interface ExcelRequest
 */
export interface ExcelRequest {
  /**
   *
   * @type {string}
   * @memberof ExcelRequest
   */
  listName?: string
  /**
   *
   * @type {ExcelRequestList}
   * @memberof ExcelRequest
   */
  list?: ExcelRequestList
}
/**
 *
 * @export
 * @interface ExcelRequestList
 */
export interface ExcelRequestList {
  /**
   *
   * @type {string}
   * @memberof ExcelRequestList
   */
  article?: string
  /**
   *
   * @type {number}
   * @memberof ExcelRequestList
   */
  number?: number
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Create zip from for articles
     * @summary Create zip from for articles
     * @param {string} localization localization identifier. Ex. \&quot;en-GB\&quot;
     * @param {string} brand
     * @param {Catalog} [catalog] The body of the request encoded in application/json
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _0e283e5a238e23c64bded37a: async (
      localization: string,
      brand: string,
      catalog?: Catalog,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'localization' is not null or undefined
      assertParamExists("_0e283e5a238e23c64bded37a", "localization", localization)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("_0e283e5a238e23c64bded37a", "brand", brand)
      const localVarPath = `/{localization}/{brand}/articles/zip`
        .replace(`{${"localization"}}`, encodeURIComponent(String(localization)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(catalog, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Generate zip with product details.
     * @summary Generate zip for articles
     * @param {string} localization localization identifier. Ex. \&quot;en-GB\&quot;
     * @param {string} brand
     * @param {string} key The product key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _0e283e5a456d49376f86c33a: async (
      localization: string,
      brand: string,
      key: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'localization' is not null or undefined
      assertParamExists("_0e283e5a456d49376f86c33a", "localization", localization)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("_0e283e5a456d49376f86c33a", "brand", brand)
      // verify required parameter 'key' is not null or undefined
      assertParamExists("_0e283e5a456d49376f86c33a", "key", key)
      const localVarPath = `/{localization}/{brand}/articles/{key}/zip`
        .replace(`{${"localization"}}`, encodeURIComponent(String(localization)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
        .replace(`{${"key"}}`, encodeURIComponent(String(key)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Create pdf for sample catalog
     * @summary Create pdf for sample catalog
     * @param {string} localization
     * @param {string} brand
     * @param {object} [body] The body of the request encoded in application/json
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _0e283e5abf0a01857bacab52: async (
      localization: string,
      brand: string,
      body?: object,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'localization' is not null or undefined
      assertParamExists("_0e283e5abf0a01857bacab52", "localization", localization)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("_0e283e5abf0a01857bacab52", "brand", brand)
      const localVarPath = `/{localization}/{brand}/sample-catalog/pdf`
        .replace(`{${"localization"}}`, encodeURIComponent(String(localization)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Generate zip with product details.
     * @summary Generate zip for product
     * @param {string} localization localization identifier. Ex. \&quot;en-GB\&quot;
     * @param {string} brand
     * @param {string} key The product key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _0e283e5adbc44c0fe5a67adb: async (
      localization: string,
      brand: string,
      key: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'localization' is not null or undefined
      assertParamExists("_0e283e5adbc44c0fe5a67adb", "localization", localization)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("_0e283e5adbc44c0fe5a67adb", "brand", brand)
      // verify required parameter 'key' is not null or undefined
      assertParamExists("_0e283e5adbc44c0fe5a67adb", "key", key)
      const localVarPath = `/{localization}/{brand}/products/{key}/zip`
        .replace(`{${"localization"}}`, encodeURIComponent(String(localization)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
        .replace(`{${"key"}}`, encodeURIComponent(String(key)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Create zip from for articles
     * @summary Create zip from for articles
     * @param {string} localization localization identifier. Ex. \&quot;en-GB\&quot;
     * @param {Catalog} [catalog] The body of the request encoded in application/json
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _5e283e5a238e23c64bded37a: async (
      localization: string,
      catalog?: Catalog,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'localization' is not null or undefined
      assertParamExists("_5e283e5a238e23c64bded37a", "localization", localization)
      const localVarPath = `/{localization}/articles/zip`.replace(
        `{${"localization"}}`,
        encodeURIComponent(String(localization))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(catalog, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Generate zip with product details.
     * @summary Generate zip for articles
     * @param {string} localization localization identifier. Ex. \&quot;en-GB\&quot;
     * @param {string} key The product key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _5e283e5a456d49376f86c33a: async (
      localization: string,
      key: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'localization' is not null or undefined
      assertParamExists("_5e283e5a456d49376f86c33a", "localization", localization)
      // verify required parameter 'key' is not null or undefined
      assertParamExists("_5e283e5a456d49376f86c33a", "key", key)
      const localVarPath = `/{localization}/articles/{key}/zip`
        .replace(`{${"localization"}}`, encodeURIComponent(String(localization)))
        .replace(`{${"key"}}`, encodeURIComponent(String(key)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Generate pdf with product details.
     * @summary Generate pdf for spare parts
     * @param {string} localization localization identifier. Ex. \&quot;en-GB\&quot;
     * @param {string} key The product key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _5e283e5a5256d782e83003ec: async (
      localization: string,
      key: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'localization' is not null or undefined
      assertParamExists("_5e283e5a5256d782e83003ec", "localization", localization)
      // verify required parameter 'key' is not null or undefined
      assertParamExists("_5e283e5a5256d782e83003ec", "key", key)
      const localVarPath = `/{localization}/spare-parts/{key}/pdf`
        .replace(`{${"localization"}}`, encodeURIComponent(String(localization)))
        .replace(`{${"key"}}`, encodeURIComponent(String(key)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Generate pdf with product details.
     * @summary Generate pdf for product
     * @param {string} localization localization identifier. Ex. \&quot;en-GB\&quot;
     * @param {string} key The product key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _5e283e5a61e0fd72d5d78151: async (
      localization: string,
      key: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'localization' is not null or undefined
      assertParamExists("_5e283e5a61e0fd72d5d78151", "localization", localization)
      // verify required parameter 'key' is not null or undefined
      assertParamExists("_5e283e5a61e0fd72d5d78151", "key", key)
      const localVarPath = `/{localization}/products/{key}/pdf`
        .replace(`{${"localization"}}`, encodeURIComponent(String(localization)))
        .replace(`{${"key"}}`, encodeURIComponent(String(key)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Create excel from articles
     * @summary Create excel from articles
     * @param {string} localization
     * @param {ExcelRequest} [excelRequest] The body of the request encoded in application/json
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _5e283e5a8504d85d0c2e5abc: async (
      localization: string,
      excelRequest?: ExcelRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'localization' is not null or undefined
      assertParamExists("_5e283e5a8504d85d0c2e5abc", "localization", localization)
      const localVarPath = `/{localization}/articles/excel`.replace(
        `{${"localization"}}`,
        encodeURIComponent(String(localization))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(excelRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Generate zip by spare-parts
     * @summary Generate zip by spare-parts
     * @param {string} localization localization identifier. Ex. \&quot;en-GB\&quot;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _5e283e5aa085c20f899756ea: async (localization: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'localization' is not null or undefined
      assertParamExists("_5e283e5aa085c20f899756ea", "localization", localization)
      const localVarPath = `/{localization}/export/spare-parts`.replace(
        `{${"localization"}}`,
        encodeURIComponent(String(localization))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Create csv from for articles
     * @summary Create csv from for articles
     * @param {string} localization localization identifier. Ex. \&quot;en-GB\&quot;
     * @param {string} brand
     * @param {Catalog} [catalog] The body of the request encoded in application/json
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _5e283e5aa996b507be4504af: async (
      localization: string,
      brand: string,
      catalog?: Catalog,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'localization' is not null or undefined
      assertParamExists("_5e283e5aa996b507be4504af", "localization", localization)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("_5e283e5aa996b507be4504af", "brand", brand)
      const localVarPath = `/{localization}/{brand}/articles/csv`
        .replace(`{${"localization"}}`, encodeURIComponent(String(localization)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(catalog, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Export tendertexts
     * @summary Export tendertexts
     * @param {Catalog} [catalog] The body of the request encoded in application/json
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _5e283e5aba3c574f42264918: async (catalog?: Catalog, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/tendertext/export`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(catalog, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Create pdf from catalog
     * @summary Create pdf from catalog
     * @param {string} localization localization identifier. Ex. \&quot;en-GB\&quot;
     * @param {Catalog} [catalog] The body of the request encoded in application/json
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _5e283e5abb08e527a0202233: async (
      localization: string,
      catalog?: Catalog,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'localization' is not null or undefined
      assertParamExists("_5e283e5abb08e527a0202233", "localization", localization)
      const localVarPath = `/{localization}/catalog/pdf`.replace(
        `{${"localization"}}`,
        encodeURIComponent(String(localization))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(catalog, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Create pdf for sample catalog
     * @summary Create pdf for sample catalog
     * @param {string} localization
     * @param {object} [body] The body of the request encoded in application/json
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _5e283e5abf0a01857bacab52: async (
      localization: string,
      body?: object,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'localization' is not null or undefined
      assertParamExists("_5e283e5abf0a01857bacab52", "localization", localization)
      const localVarPath = `/{localization}/sample-catalog/pdf`.replace(
        `{${"localization"}}`,
        encodeURIComponent(String(localization))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Generate pdf with product details.
     * @summary Generate pdf for articles
     * @param {string} localization localization identifier. Ex. \&quot;en-GB\&quot;
     * @param {string} key The product key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _5e283e5ac850aefa89a4477d: async (
      localization: string,
      key: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'localization' is not null or undefined
      assertParamExists("_5e283e5ac850aefa89a4477d", "localization", localization)
      // verify required parameter 'key' is not null or undefined
      assertParamExists("_5e283e5ac850aefa89a4477d", "key", key)
      const localVarPath = `/{localization}/articles/{key}/pdf`
        .replace(`{${"localization"}}`, encodeURIComponent(String(localization)))
        .replace(`{${"key"}}`, encodeURIComponent(String(key)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Generate zip by products
     * @summary Generate zip by products
     * @param {string} localization localization identifier. Ex. \&quot;en-GB\&quot;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _5e283e5acdd6701a75cf0ed3: async (localization: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'localization' is not null or undefined
      assertParamExists("_5e283e5acdd6701a75cf0ed3", "localization", localization)
      const localVarPath = `/{localization}/export/products`.replace(
        `{${"localization"}}`,
        encodeURIComponent(String(localization))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Generate zip with product details.
     * @summary Generate zip for product
     * @param {string} localization localization identifier. Ex. \&quot;en-GB\&quot;
     * @param {string} key The product key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _5e283e5adbc44c0fe5a67adb: async (
      localization: string,
      key: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'localization' is not null or undefined
      assertParamExists("_5e283e5adbc44c0fe5a67adb", "localization", localization)
      // verify required parameter 'key' is not null or undefined
      assertParamExists("_5e283e5adbc44c0fe5a67adb", "key", key)
      const localVarPath = `/{localization}/products/{key}/zip`
        .replace(`{${"localization"}}`, encodeURIComponent(String(localization)))
        .replace(`{${"key"}}`, encodeURIComponent(String(key)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Create pdf for sample catalog by URL
     * @summary Create pdf for sample catalog by URL
     * @param {string} localization
     * @param {object} [body] The body of the request encoded in application/json
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _5faa8dd5bc404a5f288d9830: async (
      localization: string,
      body?: object,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'localization' is not null or undefined
      assertParamExists("_5faa8dd5bc404a5f288d9830", "localization", localization)
      const localVarPath = `/{localization}/sample-catalog/pdf/url`.replace(
        `{${"localization"}}`,
        encodeURIComponent(String(localization))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Generate pdf with product details.
     * @summary Generate pdf for spare parts
     * @param {string} localization localization identifier. Ex. \&quot;en-GB\&quot;
     * @param {string} brand
     * @param {string} key The product key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    be283e5a5256d782e83003ec: async (
      localization: string,
      brand: string,
      key: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'localization' is not null or undefined
      assertParamExists("be283e5a5256d782e83003ec", "localization", localization)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("be283e5a5256d782e83003ec", "brand", brand)
      // verify required parameter 'key' is not null or undefined
      assertParamExists("be283e5a5256d782e83003ec", "key", key)
      const localVarPath = `/{localization}/{brand}/spare-parts/{key}/pdf`
        .replace(`{${"localization"}}`, encodeURIComponent(String(localization)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
        .replace(`{${"key"}}`, encodeURIComponent(String(key)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Generate pdf with product details.
     * @summary Generate pdf for product
     * @param {string} localization localization identifier. Ex. \&quot;en-GB\&quot;
     * @param {string} brand
     * @param {string} key The product key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    be283e5a61e0fd72d5d78151: async (
      localization: string,
      brand: string,
      key: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'localization' is not null or undefined
      assertParamExists("be283e5a61e0fd72d5d78151", "localization", localization)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("be283e5a61e0fd72d5d78151", "brand", brand)
      // verify required parameter 'key' is not null or undefined
      assertParamExists("be283e5a61e0fd72d5d78151", "key", key)
      const localVarPath = `/{localization}/{brand}/products/{key}/pdf`
        .replace(`{${"localization"}}`, encodeURIComponent(String(localization)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
        .replace(`{${"key"}}`, encodeURIComponent(String(key)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Generate pdf with product details.
     * @summary Generate pdf for articles
     * @param {string} localization localization identifier. Ex. \&quot;en-GB\&quot;
     * @param {string} brand
     * @param {string} key The product key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    be283e5ac850aefa89a4477d: async (
      localization: string,
      brand: string,
      key: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'localization' is not null or undefined
      assertParamExists("be283e5ac850aefa89a4477d", "localization", localization)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("be283e5ac850aefa89a4477d", "brand", brand)
      // verify required parameter 'key' is not null or undefined
      assertParamExists("be283e5ac850aefa89a4477d", "key", key)
      const localVarPath = `/{localization}/{brand}/articles/{key}/pdf`
        .replace(`{${"localization"}}`, encodeURIComponent(String(localization)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
        .replace(`{${"key"}}`, encodeURIComponent(String(key)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Create csv from for articles
     * @summary Create csv from for articles
     * @param {string} localization localization identifier. Ex. \&quot;en-GB\&quot;
     * @param {Catalog} [catalog] The body of the request encoded in application/json
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ce283e5aa996b507be4504ac: async (
      localization: string,
      catalog?: Catalog,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'localization' is not null or undefined
      assertParamExists("ce283e5aa996b507be4504ac", "localization", localization)
      const localVarPath = `/{localization}/articles/csv`.replace(
        `{${"localization"}}`,
        encodeURIComponent(String(localization))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(catalog, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Create pdf from catalog
     * @summary Create pdf from catalog
     * @param {string} localization localization identifier. Ex. \&quot;en-GB\&quot;
     * @param {string} brand
     * @param {Catalog} [catalog] The body of the request encoded in application/json
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ce283e5abb08e527a0202233: async (
      localization: string,
      brand: string,
      catalog?: Catalog,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'localization' is not null or undefined
      assertParamExists("ce283e5abb08e527a0202233", "localization", localization)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("ce283e5abb08e527a0202233", "brand", brand)
      const localVarPath = `/{localization}/{brand}/catalog/pdf`
        .replace(`{${"localization"}}`, encodeURIComponent(String(localization)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(catalog, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Create pdf for sample catalog by URL
     * @summary Create pdf for sample catalog by URL
     * @param {string} localization
     * @param {string} brand
     * @param {object} [body] The body of the request encoded in application/json
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cfaa8dd5bc404a5f288d9830: async (
      localization: string,
      brand: string,
      body?: object,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'localization' is not null or undefined
      assertParamExists("cfaa8dd5bc404a5f288d9830", "localization", localization)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("cfaa8dd5bc404a5f288d9830", "brand", brand)
      const localVarPath = `/{localization}/{brand}/sample-catalog/pdf/url`
        .replace(`{${"localization"}}`, encodeURIComponent(String(localization)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Create excel from articles
     * @summary Create excel from articles
     * @param {string} localization
     * @param {string} brand
     * @param {ExcelRequest} [excelRequest] The body of the request encoded in application/json
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ee283e5a8504d85d0c2e5abe: async (
      localization: string,
      brand: string,
      excelRequest?: ExcelRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'localization' is not null or undefined
      assertParamExists("ee283e5a8504d85d0c2e5abe", "localization", localization)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("ee283e5a8504d85d0c2e5abe", "brand", brand)
      const localVarPath = `/{localization}/{brand}/articles/excel`
        .replace(`{${"localization"}}`, encodeURIComponent(String(localization)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(excelRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
  return {
    /**
     * Create zip from for articles
     * @summary Create zip from for articles
     * @param {string} localization localization identifier. Ex. \&quot;en-GB\&quot;
     * @param {string} brand
     * @param {Catalog} [catalog] The body of the request encoded in application/json
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async _0e283e5a238e23c64bded37a(
      localization: string,
      brand: string,
      catalog?: Catalog,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator._0e283e5a238e23c64bded37a(
        localization,
        brand,
        catalog,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Generate zip with product details.
     * @summary Generate zip for articles
     * @param {string} localization localization identifier. Ex. \&quot;en-GB\&quot;
     * @param {string} brand
     * @param {string} key The product key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async _0e283e5a456d49376f86c33a(
      localization: string,
      brand: string,
      key: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator._0e283e5a456d49376f86c33a(
        localization,
        brand,
        key,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Create pdf for sample catalog
     * @summary Create pdf for sample catalog
     * @param {string} localization
     * @param {string} brand
     * @param {object} [body] The body of the request encoded in application/json
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async _0e283e5abf0a01857bacab52(
      localization: string,
      brand: string,
      body?: object,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator._0e283e5abf0a01857bacab52(
        localization,
        brand,
        body,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Generate zip with product details.
     * @summary Generate zip for product
     * @param {string} localization localization identifier. Ex. \&quot;en-GB\&quot;
     * @param {string} brand
     * @param {string} key The product key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async _0e283e5adbc44c0fe5a67adb(
      localization: string,
      brand: string,
      key: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator._0e283e5adbc44c0fe5a67adb(
        localization,
        brand,
        key,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Create zip from for articles
     * @summary Create zip from for articles
     * @param {string} localization localization identifier. Ex. \&quot;en-GB\&quot;
     * @param {Catalog} [catalog] The body of the request encoded in application/json
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async _5e283e5a238e23c64bded37a(
      localization: string,
      catalog?: Catalog,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator._5e283e5a238e23c64bded37a(
        localization,
        catalog,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Generate zip with product details.
     * @summary Generate zip for articles
     * @param {string} localization localization identifier. Ex. \&quot;en-GB\&quot;
     * @param {string} key The product key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async _5e283e5a456d49376f86c33a(
      localization: string,
      key: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator._5e283e5a456d49376f86c33a(localization, key, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Generate pdf with product details.
     * @summary Generate pdf for spare parts
     * @param {string} localization localization identifier. Ex. \&quot;en-GB\&quot;
     * @param {string} key The product key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async _5e283e5a5256d782e83003ec(
      localization: string,
      key: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator._5e283e5a5256d782e83003ec(localization, key, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Generate pdf with product details.
     * @summary Generate pdf for product
     * @param {string} localization localization identifier. Ex. \&quot;en-GB\&quot;
     * @param {string} key The product key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async _5e283e5a61e0fd72d5d78151(
      localization: string,
      key: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator._5e283e5a61e0fd72d5d78151(localization, key, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Create excel from articles
     * @summary Create excel from articles
     * @param {string} localization
     * @param {ExcelRequest} [excelRequest] The body of the request encoded in application/json
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async _5e283e5a8504d85d0c2e5abc(
      localization: string,
      excelRequest?: ExcelRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator._5e283e5a8504d85d0c2e5abc(
        localization,
        excelRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Generate zip by spare-parts
     * @summary Generate zip by spare-parts
     * @param {string} localization localization identifier. Ex. \&quot;en-GB\&quot;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async _5e283e5aa085c20f899756ea(
      localization: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator._5e283e5aa085c20f899756ea(localization, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Create csv from for articles
     * @summary Create csv from for articles
     * @param {string} localization localization identifier. Ex. \&quot;en-GB\&quot;
     * @param {string} brand
     * @param {Catalog} [catalog] The body of the request encoded in application/json
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async _5e283e5aa996b507be4504af(
      localization: string,
      brand: string,
      catalog?: Catalog,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator._5e283e5aa996b507be4504af(
        localization,
        brand,
        catalog,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Export tendertexts
     * @summary Export tendertexts
     * @param {Catalog} [catalog] The body of the request encoded in application/json
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async _5e283e5aba3c574f42264918(
      catalog?: Catalog,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator._5e283e5aba3c574f42264918(catalog, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Create pdf from catalog
     * @summary Create pdf from catalog
     * @param {string} localization localization identifier. Ex. \&quot;en-GB\&quot;
     * @param {Catalog} [catalog] The body of the request encoded in application/json
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async _5e283e5abb08e527a0202233(
      localization: string,
      catalog?: Catalog,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator._5e283e5abb08e527a0202233(
        localization,
        catalog,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Create pdf for sample catalog
     * @summary Create pdf for sample catalog
     * @param {string} localization
     * @param {object} [body] The body of the request encoded in application/json
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async _5e283e5abf0a01857bacab52(
      localization: string,
      body?: object,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator._5e283e5abf0a01857bacab52(localization, body, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Generate pdf with product details.
     * @summary Generate pdf for articles
     * @param {string} localization localization identifier. Ex. \&quot;en-GB\&quot;
     * @param {string} key The product key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async _5e283e5ac850aefa89a4477d(
      localization: string,
      key: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator._5e283e5ac850aefa89a4477d(localization, key, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Generate zip by products
     * @summary Generate zip by products
     * @param {string} localization localization identifier. Ex. \&quot;en-GB\&quot;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async _5e283e5acdd6701a75cf0ed3(
      localization: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator._5e283e5acdd6701a75cf0ed3(localization, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Generate zip with product details.
     * @summary Generate zip for product
     * @param {string} localization localization identifier. Ex. \&quot;en-GB\&quot;
     * @param {string} key The product key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async _5e283e5adbc44c0fe5a67adb(
      localization: string,
      key: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator._5e283e5adbc44c0fe5a67adb(localization, key, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Create pdf for sample catalog by URL
     * @summary Create pdf for sample catalog by URL
     * @param {string} localization
     * @param {object} [body] The body of the request encoded in application/json
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async _5faa8dd5bc404a5f288d9830(
      localization: string,
      body?: object,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator._5faa8dd5bc404a5f288d9830(localization, body, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Generate pdf with product details.
     * @summary Generate pdf for spare parts
     * @param {string} localization localization identifier. Ex. \&quot;en-GB\&quot;
     * @param {string} brand
     * @param {string} key The product key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async be283e5a5256d782e83003ec(
      localization: string,
      brand: string,
      key: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.be283e5a5256d782e83003ec(
        localization,
        brand,
        key,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Generate pdf with product details.
     * @summary Generate pdf for product
     * @param {string} localization localization identifier. Ex. \&quot;en-GB\&quot;
     * @param {string} brand
     * @param {string} key The product key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async be283e5a61e0fd72d5d78151(
      localization: string,
      brand: string,
      key: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.be283e5a61e0fd72d5d78151(
        localization,
        brand,
        key,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Generate pdf with product details.
     * @summary Generate pdf for articles
     * @param {string} localization localization identifier. Ex. \&quot;en-GB\&quot;
     * @param {string} brand
     * @param {string} key The product key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async be283e5ac850aefa89a4477d(
      localization: string,
      brand: string,
      key: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.be283e5ac850aefa89a4477d(
        localization,
        brand,
        key,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Create csv from for articles
     * @summary Create csv from for articles
     * @param {string} localization localization identifier. Ex. \&quot;en-GB\&quot;
     * @param {Catalog} [catalog] The body of the request encoded in application/json
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async ce283e5aa996b507be4504ac(
      localization: string,
      catalog?: Catalog,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.ce283e5aa996b507be4504ac(localization, catalog, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Create pdf from catalog
     * @summary Create pdf from catalog
     * @param {string} localization localization identifier. Ex. \&quot;en-GB\&quot;
     * @param {string} brand
     * @param {Catalog} [catalog] The body of the request encoded in application/json
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async ce283e5abb08e527a0202233(
      localization: string,
      brand: string,
      catalog?: Catalog,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.ce283e5abb08e527a0202233(
        localization,
        brand,
        catalog,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Create pdf for sample catalog by URL
     * @summary Create pdf for sample catalog by URL
     * @param {string} localization
     * @param {string} brand
     * @param {object} [body] The body of the request encoded in application/json
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cfaa8dd5bc404a5f288d9830(
      localization: string,
      brand: string,
      body?: object,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.cfaa8dd5bc404a5f288d9830(
        localization,
        brand,
        body,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Create excel from articles
     * @summary Create excel from articles
     * @param {string} localization
     * @param {string} brand
     * @param {ExcelRequest} [excelRequest] The body of the request encoded in application/json
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async ee283e5a8504d85d0c2e5abe(
      localization: string,
      brand: string,
      excelRequest?: ExcelRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.ee283e5a8504d85d0c2e5abe(
        localization,
        brand,
        excelRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = DefaultApiFp(configuration)
  return {
    /**
     * Create zip from for articles
     * @summary Create zip from for articles
     * @param {DefaultApi0e283e5a238e23c64bded37aRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _0e283e5a238e23c64bded37a(
      requestParameters: DefaultApi0e283e5a238e23c64bded37aRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<void> {
      return localVarFp
        ._0e283e5a238e23c64bded37a(
          requestParameters.localization,
          requestParameters.brand,
          requestParameters.catalog,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Generate zip with product details.
     * @summary Generate zip for articles
     * @param {DefaultApi0e283e5a456d49376f86c33aRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _0e283e5a456d49376f86c33a(
      requestParameters: DefaultApi0e283e5a456d49376f86c33aRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<void> {
      return localVarFp
        ._0e283e5a456d49376f86c33a(
          requestParameters.localization,
          requestParameters.brand,
          requestParameters.key,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Create pdf for sample catalog
     * @summary Create pdf for sample catalog
     * @param {DefaultApi0e283e5abf0a01857bacab52Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _0e283e5abf0a01857bacab52(
      requestParameters: DefaultApi0e283e5abf0a01857bacab52Request,
      options?: AxiosRequestConfig
    ): AxiosPromise<void> {
      return localVarFp
        ._0e283e5abf0a01857bacab52(
          requestParameters.localization,
          requestParameters.brand,
          requestParameters.body,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Generate zip with product details.
     * @summary Generate zip for product
     * @param {DefaultApi0e283e5adbc44c0fe5a67adbRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _0e283e5adbc44c0fe5a67adb(
      requestParameters: DefaultApi0e283e5adbc44c0fe5a67adbRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<void> {
      return localVarFp
        ._0e283e5adbc44c0fe5a67adb(
          requestParameters.localization,
          requestParameters.brand,
          requestParameters.key,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Create zip from for articles
     * @summary Create zip from for articles
     * @param {DefaultApi5e283e5a238e23c64bded37aRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _5e283e5a238e23c64bded37a(
      requestParameters: DefaultApi5e283e5a238e23c64bded37aRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<void> {
      return localVarFp
        ._5e283e5a238e23c64bded37a(requestParameters.localization, requestParameters.catalog, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Generate zip with product details.
     * @summary Generate zip for articles
     * @param {DefaultApi5e283e5a456d49376f86c33aRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _5e283e5a456d49376f86c33a(
      requestParameters: DefaultApi5e283e5a456d49376f86c33aRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<void> {
      return localVarFp
        ._5e283e5a456d49376f86c33a(requestParameters.localization, requestParameters.key, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Generate pdf with product details.
     * @summary Generate pdf for spare parts
     * @param {DefaultApi5e283e5a5256d782e83003ecRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _5e283e5a5256d782e83003ec(
      requestParameters: DefaultApi5e283e5a5256d782e83003ecRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<void> {
      return localVarFp
        ._5e283e5a5256d782e83003ec(requestParameters.localization, requestParameters.key, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Generate pdf with product details.
     * @summary Generate pdf for product
     * @param {DefaultApi5e283e5a61e0fd72d5d78151Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _5e283e5a61e0fd72d5d78151(
      requestParameters: DefaultApi5e283e5a61e0fd72d5d78151Request,
      options?: AxiosRequestConfig
    ): AxiosPromise<void> {
      return localVarFp
        ._5e283e5a61e0fd72d5d78151(requestParameters.localization, requestParameters.key, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Create excel from articles
     * @summary Create excel from articles
     * @param {DefaultApi5e283e5a8504d85d0c2e5abcRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _5e283e5a8504d85d0c2e5abc(
      requestParameters: DefaultApi5e283e5a8504d85d0c2e5abcRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<void> {
      return localVarFp
        ._5e283e5a8504d85d0c2e5abc(requestParameters.localization, requestParameters.excelRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Generate zip by spare-parts
     * @summary Generate zip by spare-parts
     * @param {DefaultApi5e283e5aa085c20f899756eaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _5e283e5aa085c20f899756ea(
      requestParameters: DefaultApi5e283e5aa085c20f899756eaRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<void> {
      return localVarFp
        ._5e283e5aa085c20f899756ea(requestParameters.localization, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Create csv from for articles
     * @summary Create csv from for articles
     * @param {DefaultApi5e283e5aa996b507be4504afRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _5e283e5aa996b507be4504af(
      requestParameters: DefaultApi5e283e5aa996b507be4504afRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<void> {
      return localVarFp
        ._5e283e5aa996b507be4504af(
          requestParameters.localization,
          requestParameters.brand,
          requestParameters.catalog,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Export tendertexts
     * @summary Export tendertexts
     * @param {DefaultApi5e283e5aba3c574f42264918Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _5e283e5aba3c574f42264918(
      requestParameters: DefaultApi5e283e5aba3c574f42264918Request = {},
      options?: AxiosRequestConfig
    ): AxiosPromise<void> {
      return localVarFp
        ._5e283e5aba3c574f42264918(requestParameters.catalog, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Create pdf from catalog
     * @summary Create pdf from catalog
     * @param {DefaultApi5e283e5abb08e527a0202233Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _5e283e5abb08e527a0202233(
      requestParameters: DefaultApi5e283e5abb08e527a0202233Request,
      options?: AxiosRequestConfig
    ): AxiosPromise<void> {
      return localVarFp
        ._5e283e5abb08e527a0202233(requestParameters.localization, requestParameters.catalog, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Create pdf for sample catalog
     * @summary Create pdf for sample catalog
     * @param {DefaultApi5e283e5abf0a01857bacab52Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _5e283e5abf0a01857bacab52(
      requestParameters: DefaultApi5e283e5abf0a01857bacab52Request,
      options?: AxiosRequestConfig
    ): AxiosPromise<void> {
      return localVarFp
        ._5e283e5abf0a01857bacab52(requestParameters.localization, requestParameters.body, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Generate pdf with product details.
     * @summary Generate pdf for articles
     * @param {DefaultApi5e283e5ac850aefa89a4477dRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _5e283e5ac850aefa89a4477d(
      requestParameters: DefaultApi5e283e5ac850aefa89a4477dRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<void> {
      return localVarFp
        ._5e283e5ac850aefa89a4477d(requestParameters.localization, requestParameters.key, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Generate zip by products
     * @summary Generate zip by products
     * @param {DefaultApi5e283e5acdd6701a75cf0ed3Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _5e283e5acdd6701a75cf0ed3(
      requestParameters: DefaultApi5e283e5acdd6701a75cf0ed3Request,
      options?: AxiosRequestConfig
    ): AxiosPromise<void> {
      return localVarFp
        ._5e283e5acdd6701a75cf0ed3(requestParameters.localization, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Generate zip with product details.
     * @summary Generate zip for product
     * @param {DefaultApi5e283e5adbc44c0fe5a67adbRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _5e283e5adbc44c0fe5a67adb(
      requestParameters: DefaultApi5e283e5adbc44c0fe5a67adbRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<void> {
      return localVarFp
        ._5e283e5adbc44c0fe5a67adb(requestParameters.localization, requestParameters.key, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Create pdf for sample catalog by URL
     * @summary Create pdf for sample catalog by URL
     * @param {DefaultApi5faa8dd5bc404a5f288d9830Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _5faa8dd5bc404a5f288d9830(
      requestParameters: DefaultApi5faa8dd5bc404a5f288d9830Request,
      options?: AxiosRequestConfig
    ): AxiosPromise<void> {
      return localVarFp
        ._5faa8dd5bc404a5f288d9830(requestParameters.localization, requestParameters.body, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Generate pdf with product details.
     * @summary Generate pdf for spare parts
     * @param {DefaultApiBe283e5a5256d782e83003ecRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    be283e5a5256d782e83003ec(
      requestParameters: DefaultApiBe283e5a5256d782e83003ecRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<void> {
      return localVarFp
        .be283e5a5256d782e83003ec(
          requestParameters.localization,
          requestParameters.brand,
          requestParameters.key,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Generate pdf with product details.
     * @summary Generate pdf for product
     * @param {DefaultApiBe283e5a61e0fd72d5d78151Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    be283e5a61e0fd72d5d78151(
      requestParameters: DefaultApiBe283e5a61e0fd72d5d78151Request,
      options?: AxiosRequestConfig
    ): AxiosPromise<void> {
      return localVarFp
        .be283e5a61e0fd72d5d78151(
          requestParameters.localization,
          requestParameters.brand,
          requestParameters.key,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Generate pdf with product details.
     * @summary Generate pdf for articles
     * @param {DefaultApiBe283e5ac850aefa89a4477dRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    be283e5ac850aefa89a4477d(
      requestParameters: DefaultApiBe283e5ac850aefa89a4477dRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<void> {
      return localVarFp
        .be283e5ac850aefa89a4477d(
          requestParameters.localization,
          requestParameters.brand,
          requestParameters.key,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Create csv from for articles
     * @summary Create csv from for articles
     * @param {DefaultApiCe283e5aa996b507be4504acRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ce283e5aa996b507be4504ac(
      requestParameters: DefaultApiCe283e5aa996b507be4504acRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<void> {
      return localVarFp
        .ce283e5aa996b507be4504ac(requestParameters.localization, requestParameters.catalog, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Create pdf from catalog
     * @summary Create pdf from catalog
     * @param {DefaultApiCe283e5abb08e527a0202233Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ce283e5abb08e527a0202233(
      requestParameters: DefaultApiCe283e5abb08e527a0202233Request,
      options?: AxiosRequestConfig
    ): AxiosPromise<void> {
      return localVarFp
        .ce283e5abb08e527a0202233(
          requestParameters.localization,
          requestParameters.brand,
          requestParameters.catalog,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Create pdf for sample catalog by URL
     * @summary Create pdf for sample catalog by URL
     * @param {DefaultApiCfaa8dd5bc404a5f288d9830Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cfaa8dd5bc404a5f288d9830(
      requestParameters: DefaultApiCfaa8dd5bc404a5f288d9830Request,
      options?: AxiosRequestConfig
    ): AxiosPromise<void> {
      return localVarFp
        .cfaa8dd5bc404a5f288d9830(
          requestParameters.localization,
          requestParameters.brand,
          requestParameters.body,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Create excel from articles
     * @summary Create excel from articles
     * @param {DefaultApiEe283e5a8504d85d0c2e5abeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ee283e5a8504d85d0c2e5abe(
      requestParameters: DefaultApiEe283e5a8504d85d0c2e5abeRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<void> {
      return localVarFp
        .ee283e5a8504d85d0c2e5abe(
          requestParameters.localization,
          requestParameters.brand,
          requestParameters.excelRequest,
          options
        )
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for _0e283e5a238e23c64bded37a operation in DefaultApi.
 * @export
 * @interface DefaultApi0e283e5a238e23c64bded37aRequest
 */
export interface DefaultApi0e283e5a238e23c64bded37aRequest {
  /**
   * localization identifier. Ex. \&quot;en-GB\&quot;
   * @type {string}
   * @memberof DefaultApi0e283e5a238e23c64bded37a
   */
  readonly localization: string

  /**
   *
   * @type {string}
   * @memberof DefaultApi0e283e5a238e23c64bded37a
   */
  readonly brand: string

  /**
   * The body of the request encoded in application/json
   * @type {Catalog}
   * @memberof DefaultApi0e283e5a238e23c64bded37a
   */
  readonly catalog?: Catalog
}

/**
 * Request parameters for _0e283e5a456d49376f86c33a operation in DefaultApi.
 * @export
 * @interface DefaultApi0e283e5a456d49376f86c33aRequest
 */
export interface DefaultApi0e283e5a456d49376f86c33aRequest {
  /**
   * localization identifier. Ex. \&quot;en-GB\&quot;
   * @type {string}
   * @memberof DefaultApi0e283e5a456d49376f86c33a
   */
  readonly localization: string

  /**
   *
   * @type {string}
   * @memberof DefaultApi0e283e5a456d49376f86c33a
   */
  readonly brand: string

  /**
   * The product key
   * @type {string}
   * @memberof DefaultApi0e283e5a456d49376f86c33a
   */
  readonly key: string
}

/**
 * Request parameters for _0e283e5abf0a01857bacab52 operation in DefaultApi.
 * @export
 * @interface DefaultApi0e283e5abf0a01857bacab52Request
 */
export interface DefaultApi0e283e5abf0a01857bacab52Request {
  /**
   *
   * @type {string}
   * @memberof DefaultApi0e283e5abf0a01857bacab52
   */
  readonly localization: string

  /**
   *
   * @type {string}
   * @memberof DefaultApi0e283e5abf0a01857bacab52
   */
  readonly brand: string

  /**
   * The body of the request encoded in application/json
   * @type {object}
   * @memberof DefaultApi0e283e5abf0a01857bacab52
   */
  readonly body?: object
}

/**
 * Request parameters for _0e283e5adbc44c0fe5a67adb operation in DefaultApi.
 * @export
 * @interface DefaultApi0e283e5adbc44c0fe5a67adbRequest
 */
export interface DefaultApi0e283e5adbc44c0fe5a67adbRequest {
  /**
   * localization identifier. Ex. \&quot;en-GB\&quot;
   * @type {string}
   * @memberof DefaultApi0e283e5adbc44c0fe5a67adb
   */
  readonly localization: string

  /**
   *
   * @type {string}
   * @memberof DefaultApi0e283e5adbc44c0fe5a67adb
   */
  readonly brand: string

  /**
   * The product key
   * @type {string}
   * @memberof DefaultApi0e283e5adbc44c0fe5a67adb
   */
  readonly key: string
}

/**
 * Request parameters for _5e283e5a238e23c64bded37a operation in DefaultApi.
 * @export
 * @interface DefaultApi5e283e5a238e23c64bded37aRequest
 */
export interface DefaultApi5e283e5a238e23c64bded37aRequest {
  /**
   * localization identifier. Ex. \&quot;en-GB\&quot;
   * @type {string}
   * @memberof DefaultApi5e283e5a238e23c64bded37a
   */
  readonly localization: string

  /**
   * The body of the request encoded in application/json
   * @type {Catalog}
   * @memberof DefaultApi5e283e5a238e23c64bded37a
   */
  readonly catalog?: Catalog
}

/**
 * Request parameters for _5e283e5a456d49376f86c33a operation in DefaultApi.
 * @export
 * @interface DefaultApi5e283e5a456d49376f86c33aRequest
 */
export interface DefaultApi5e283e5a456d49376f86c33aRequest {
  /**
   * localization identifier. Ex. \&quot;en-GB\&quot;
   * @type {string}
   * @memberof DefaultApi5e283e5a456d49376f86c33a
   */
  readonly localization: string

  /**
   * The product key
   * @type {string}
   * @memberof DefaultApi5e283e5a456d49376f86c33a
   */
  readonly key: string
}

/**
 * Request parameters for _5e283e5a5256d782e83003ec operation in DefaultApi.
 * @export
 * @interface DefaultApi5e283e5a5256d782e83003ecRequest
 */
export interface DefaultApi5e283e5a5256d782e83003ecRequest {
  /**
   * localization identifier. Ex. \&quot;en-GB\&quot;
   * @type {string}
   * @memberof DefaultApi5e283e5a5256d782e83003ec
   */
  readonly localization: string

  /**
   * The product key
   * @type {string}
   * @memberof DefaultApi5e283e5a5256d782e83003ec
   */
  readonly key: string
}

/**
 * Request parameters for _5e283e5a61e0fd72d5d78151 operation in DefaultApi.
 * @export
 * @interface DefaultApi5e283e5a61e0fd72d5d78151Request
 */
export interface DefaultApi5e283e5a61e0fd72d5d78151Request {
  /**
   * localization identifier. Ex. \&quot;en-GB\&quot;
   * @type {string}
   * @memberof DefaultApi5e283e5a61e0fd72d5d78151
   */
  readonly localization: string

  /**
   * The product key
   * @type {string}
   * @memberof DefaultApi5e283e5a61e0fd72d5d78151
   */
  readonly key: string
}

/**
 * Request parameters for _5e283e5a8504d85d0c2e5abc operation in DefaultApi.
 * @export
 * @interface DefaultApi5e283e5a8504d85d0c2e5abcRequest
 */
export interface DefaultApi5e283e5a8504d85d0c2e5abcRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultApi5e283e5a8504d85d0c2e5abc
   */
  readonly localization: string

  /**
   * The body of the request encoded in application/json
   * @type {ExcelRequest}
   * @memberof DefaultApi5e283e5a8504d85d0c2e5abc
   */
  readonly excelRequest?: ExcelRequest
}

/**
 * Request parameters for _5e283e5aa085c20f899756ea operation in DefaultApi.
 * @export
 * @interface DefaultApi5e283e5aa085c20f899756eaRequest
 */
export interface DefaultApi5e283e5aa085c20f899756eaRequest {
  /**
   * localization identifier. Ex. \&quot;en-GB\&quot;
   * @type {string}
   * @memberof DefaultApi5e283e5aa085c20f899756ea
   */
  readonly localization: string
}

/**
 * Request parameters for _5e283e5aa996b507be4504af operation in DefaultApi.
 * @export
 * @interface DefaultApi5e283e5aa996b507be4504afRequest
 */
export interface DefaultApi5e283e5aa996b507be4504afRequest {
  /**
   * localization identifier. Ex. \&quot;en-GB\&quot;
   * @type {string}
   * @memberof DefaultApi5e283e5aa996b507be4504af
   */
  readonly localization: string

  /**
   *
   * @type {string}
   * @memberof DefaultApi5e283e5aa996b507be4504af
   */
  readonly brand: string

  /**
   * The body of the request encoded in application/json
   * @type {Catalog}
   * @memberof DefaultApi5e283e5aa996b507be4504af
   */
  readonly catalog?: Catalog
}

/**
 * Request parameters for _5e283e5aba3c574f42264918 operation in DefaultApi.
 * @export
 * @interface DefaultApi5e283e5aba3c574f42264918Request
 */
export interface DefaultApi5e283e5aba3c574f42264918Request {
  /**
   * The body of the request encoded in application/json
   * @type {Catalog}
   * @memberof DefaultApi5e283e5aba3c574f42264918
   */
  readonly catalog?: Catalog
}

/**
 * Request parameters for _5e283e5abb08e527a0202233 operation in DefaultApi.
 * @export
 * @interface DefaultApi5e283e5abb08e527a0202233Request
 */
export interface DefaultApi5e283e5abb08e527a0202233Request {
  /**
   * localization identifier. Ex. \&quot;en-GB\&quot;
   * @type {string}
   * @memberof DefaultApi5e283e5abb08e527a0202233
   */
  readonly localization: string

  /**
   * The body of the request encoded in application/json
   * @type {Catalog}
   * @memberof DefaultApi5e283e5abb08e527a0202233
   */
  readonly catalog?: Catalog
}

/**
 * Request parameters for _5e283e5abf0a01857bacab52 operation in DefaultApi.
 * @export
 * @interface DefaultApi5e283e5abf0a01857bacab52Request
 */
export interface DefaultApi5e283e5abf0a01857bacab52Request {
  /**
   *
   * @type {string}
   * @memberof DefaultApi5e283e5abf0a01857bacab52
   */
  readonly localization: string

  /**
   * The body of the request encoded in application/json
   * @type {object}
   * @memberof DefaultApi5e283e5abf0a01857bacab52
   */
  readonly body?: object
}

/**
 * Request parameters for _5e283e5ac850aefa89a4477d operation in DefaultApi.
 * @export
 * @interface DefaultApi5e283e5ac850aefa89a4477dRequest
 */
export interface DefaultApi5e283e5ac850aefa89a4477dRequest {
  /**
   * localization identifier. Ex. \&quot;en-GB\&quot;
   * @type {string}
   * @memberof DefaultApi5e283e5ac850aefa89a4477d
   */
  readonly localization: string

  /**
   * The product key
   * @type {string}
   * @memberof DefaultApi5e283e5ac850aefa89a4477d
   */
  readonly key: string
}

/**
 * Request parameters for _5e283e5acdd6701a75cf0ed3 operation in DefaultApi.
 * @export
 * @interface DefaultApi5e283e5acdd6701a75cf0ed3Request
 */
export interface DefaultApi5e283e5acdd6701a75cf0ed3Request {
  /**
   * localization identifier. Ex. \&quot;en-GB\&quot;
   * @type {string}
   * @memberof DefaultApi5e283e5acdd6701a75cf0ed3
   */
  readonly localization: string
}

/**
 * Request parameters for _5e283e5adbc44c0fe5a67adb operation in DefaultApi.
 * @export
 * @interface DefaultApi5e283e5adbc44c0fe5a67adbRequest
 */
export interface DefaultApi5e283e5adbc44c0fe5a67adbRequest {
  /**
   * localization identifier. Ex. \&quot;en-GB\&quot;
   * @type {string}
   * @memberof DefaultApi5e283e5adbc44c0fe5a67adb
   */
  readonly localization: string

  /**
   * The product key
   * @type {string}
   * @memberof DefaultApi5e283e5adbc44c0fe5a67adb
   */
  readonly key: string
}

/**
 * Request parameters for _5faa8dd5bc404a5f288d9830 operation in DefaultApi.
 * @export
 * @interface DefaultApi5faa8dd5bc404a5f288d9830Request
 */
export interface DefaultApi5faa8dd5bc404a5f288d9830Request {
  /**
   *
   * @type {string}
   * @memberof DefaultApi5faa8dd5bc404a5f288d9830
   */
  readonly localization: string

  /**
   * The body of the request encoded in application/json
   * @type {object}
   * @memberof DefaultApi5faa8dd5bc404a5f288d9830
   */
  readonly body?: object
}

/**
 * Request parameters for be283e5a5256d782e83003ec operation in DefaultApi.
 * @export
 * @interface DefaultApiBe283e5a5256d782e83003ecRequest
 */
export interface DefaultApiBe283e5a5256d782e83003ecRequest {
  /**
   * localization identifier. Ex. \&quot;en-GB\&quot;
   * @type {string}
   * @memberof DefaultApiBe283e5a5256d782e83003ec
   */
  readonly localization: string

  /**
   *
   * @type {string}
   * @memberof DefaultApiBe283e5a5256d782e83003ec
   */
  readonly brand: string

  /**
   * The product key
   * @type {string}
   * @memberof DefaultApiBe283e5a5256d782e83003ec
   */
  readonly key: string
}

/**
 * Request parameters for be283e5a61e0fd72d5d78151 operation in DefaultApi.
 * @export
 * @interface DefaultApiBe283e5a61e0fd72d5d78151Request
 */
export interface DefaultApiBe283e5a61e0fd72d5d78151Request {
  /**
   * localization identifier. Ex. \&quot;en-GB\&quot;
   * @type {string}
   * @memberof DefaultApiBe283e5a61e0fd72d5d78151
   */
  readonly localization: string

  /**
   *
   * @type {string}
   * @memberof DefaultApiBe283e5a61e0fd72d5d78151
   */
  readonly brand: string

  /**
   * The product key
   * @type {string}
   * @memberof DefaultApiBe283e5a61e0fd72d5d78151
   */
  readonly key: string
}

/**
 * Request parameters for be283e5ac850aefa89a4477d operation in DefaultApi.
 * @export
 * @interface DefaultApiBe283e5ac850aefa89a4477dRequest
 */
export interface DefaultApiBe283e5ac850aefa89a4477dRequest {
  /**
   * localization identifier. Ex. \&quot;en-GB\&quot;
   * @type {string}
   * @memberof DefaultApiBe283e5ac850aefa89a4477d
   */
  readonly localization: string

  /**
   *
   * @type {string}
   * @memberof DefaultApiBe283e5ac850aefa89a4477d
   */
  readonly brand: string

  /**
   * The product key
   * @type {string}
   * @memberof DefaultApiBe283e5ac850aefa89a4477d
   */
  readonly key: string
}

/**
 * Request parameters for ce283e5aa996b507be4504ac operation in DefaultApi.
 * @export
 * @interface DefaultApiCe283e5aa996b507be4504acRequest
 */
export interface DefaultApiCe283e5aa996b507be4504acRequest {
  /**
   * localization identifier. Ex. \&quot;en-GB\&quot;
   * @type {string}
   * @memberof DefaultApiCe283e5aa996b507be4504ac
   */
  readonly localization: string

  /**
   * The body of the request encoded in application/json
   * @type {Catalog}
   * @memberof DefaultApiCe283e5aa996b507be4504ac
   */
  readonly catalog?: Catalog
}

/**
 * Request parameters for ce283e5abb08e527a0202233 operation in DefaultApi.
 * @export
 * @interface DefaultApiCe283e5abb08e527a0202233Request
 */
export interface DefaultApiCe283e5abb08e527a0202233Request {
  /**
   * localization identifier. Ex. \&quot;en-GB\&quot;
   * @type {string}
   * @memberof DefaultApiCe283e5abb08e527a0202233
   */
  readonly localization: string

  /**
   *
   * @type {string}
   * @memberof DefaultApiCe283e5abb08e527a0202233
   */
  readonly brand: string

  /**
   * The body of the request encoded in application/json
   * @type {Catalog}
   * @memberof DefaultApiCe283e5abb08e527a0202233
   */
  readonly catalog?: Catalog
}

/**
 * Request parameters for cfaa8dd5bc404a5f288d9830 operation in DefaultApi.
 * @export
 * @interface DefaultApiCfaa8dd5bc404a5f288d9830Request
 */
export interface DefaultApiCfaa8dd5bc404a5f288d9830Request {
  /**
   *
   * @type {string}
   * @memberof DefaultApiCfaa8dd5bc404a5f288d9830
   */
  readonly localization: string

  /**
   *
   * @type {string}
   * @memberof DefaultApiCfaa8dd5bc404a5f288d9830
   */
  readonly brand: string

  /**
   * The body of the request encoded in application/json
   * @type {object}
   * @memberof DefaultApiCfaa8dd5bc404a5f288d9830
   */
  readonly body?: object
}

/**
 * Request parameters for ee283e5a8504d85d0c2e5abe operation in DefaultApi.
 * @export
 * @interface DefaultApiEe283e5a8504d85d0c2e5abeRequest
 */
export interface DefaultApiEe283e5a8504d85d0c2e5abeRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultApiEe283e5a8504d85d0c2e5abe
   */
  readonly localization: string

  /**
   *
   * @type {string}
   * @memberof DefaultApiEe283e5a8504d85d0c2e5abe
   */
  readonly brand: string

  /**
   * The body of the request encoded in application/json
   * @type {ExcelRequest}
   * @memberof DefaultApiEe283e5a8504d85d0c2e5abe
   */
  readonly excelRequest?: ExcelRequest
}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   * Create zip from for articles
   * @summary Create zip from for articles
   * @param {DefaultApi0e283e5a238e23c64bded37aRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public _0e283e5a238e23c64bded37a(
    requestParameters: DefaultApi0e283e5a238e23c64bded37aRequest,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      ._0e283e5a238e23c64bded37a(
        requestParameters.localization,
        requestParameters.brand,
        requestParameters.catalog,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Generate zip with product details.
   * @summary Generate zip for articles
   * @param {DefaultApi0e283e5a456d49376f86c33aRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public _0e283e5a456d49376f86c33a(
    requestParameters: DefaultApi0e283e5a456d49376f86c33aRequest,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      ._0e283e5a456d49376f86c33a(
        requestParameters.localization,
        requestParameters.brand,
        requestParameters.key,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Create pdf for sample catalog
   * @summary Create pdf for sample catalog
   * @param {DefaultApi0e283e5abf0a01857bacab52Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public _0e283e5abf0a01857bacab52(
    requestParameters: DefaultApi0e283e5abf0a01857bacab52Request,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      ._0e283e5abf0a01857bacab52(
        requestParameters.localization,
        requestParameters.brand,
        requestParameters.body,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Generate zip with product details.
   * @summary Generate zip for product
   * @param {DefaultApi0e283e5adbc44c0fe5a67adbRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public _0e283e5adbc44c0fe5a67adb(
    requestParameters: DefaultApi0e283e5adbc44c0fe5a67adbRequest,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      ._0e283e5adbc44c0fe5a67adb(
        requestParameters.localization,
        requestParameters.brand,
        requestParameters.key,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Create zip from for articles
   * @summary Create zip from for articles
   * @param {DefaultApi5e283e5a238e23c64bded37aRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public _5e283e5a238e23c64bded37a(
    requestParameters: DefaultApi5e283e5a238e23c64bded37aRequest,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      ._5e283e5a238e23c64bded37a(requestParameters.localization, requestParameters.catalog, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Generate zip with product details.
   * @summary Generate zip for articles
   * @param {DefaultApi5e283e5a456d49376f86c33aRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public _5e283e5a456d49376f86c33a(
    requestParameters: DefaultApi5e283e5a456d49376f86c33aRequest,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      ._5e283e5a456d49376f86c33a(requestParameters.localization, requestParameters.key, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Generate pdf with product details.
   * @summary Generate pdf for spare parts
   * @param {DefaultApi5e283e5a5256d782e83003ecRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public _5e283e5a5256d782e83003ec(
    requestParameters: DefaultApi5e283e5a5256d782e83003ecRequest,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      ._5e283e5a5256d782e83003ec(requestParameters.localization, requestParameters.key, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Generate pdf with product details.
   * @summary Generate pdf for product
   * @param {DefaultApi5e283e5a61e0fd72d5d78151Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public _5e283e5a61e0fd72d5d78151(
    requestParameters: DefaultApi5e283e5a61e0fd72d5d78151Request,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      ._5e283e5a61e0fd72d5d78151(requestParameters.localization, requestParameters.key, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Create excel from articles
   * @summary Create excel from articles
   * @param {DefaultApi5e283e5a8504d85d0c2e5abcRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public _5e283e5a8504d85d0c2e5abc(
    requestParameters: DefaultApi5e283e5a8504d85d0c2e5abcRequest,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      ._5e283e5a8504d85d0c2e5abc(requestParameters.localization, requestParameters.excelRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Generate zip by spare-parts
   * @summary Generate zip by spare-parts
   * @param {DefaultApi5e283e5aa085c20f899756eaRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public _5e283e5aa085c20f899756ea(
    requestParameters: DefaultApi5e283e5aa085c20f899756eaRequest,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      ._5e283e5aa085c20f899756ea(requestParameters.localization, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Create csv from for articles
   * @summary Create csv from for articles
   * @param {DefaultApi5e283e5aa996b507be4504afRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public _5e283e5aa996b507be4504af(
    requestParameters: DefaultApi5e283e5aa996b507be4504afRequest,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      ._5e283e5aa996b507be4504af(
        requestParameters.localization,
        requestParameters.brand,
        requestParameters.catalog,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Export tendertexts
   * @summary Export tendertexts
   * @param {DefaultApi5e283e5aba3c574f42264918Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public _5e283e5aba3c574f42264918(
    requestParameters: DefaultApi5e283e5aba3c574f42264918Request = {},
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      ._5e283e5aba3c574f42264918(requestParameters.catalog, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Create pdf from catalog
   * @summary Create pdf from catalog
   * @param {DefaultApi5e283e5abb08e527a0202233Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public _5e283e5abb08e527a0202233(
    requestParameters: DefaultApi5e283e5abb08e527a0202233Request,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      ._5e283e5abb08e527a0202233(requestParameters.localization, requestParameters.catalog, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Create pdf for sample catalog
   * @summary Create pdf for sample catalog
   * @param {DefaultApi5e283e5abf0a01857bacab52Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public _5e283e5abf0a01857bacab52(
    requestParameters: DefaultApi5e283e5abf0a01857bacab52Request,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      ._5e283e5abf0a01857bacab52(requestParameters.localization, requestParameters.body, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Generate pdf with product details.
   * @summary Generate pdf for articles
   * @param {DefaultApi5e283e5ac850aefa89a4477dRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public _5e283e5ac850aefa89a4477d(
    requestParameters: DefaultApi5e283e5ac850aefa89a4477dRequest,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      ._5e283e5ac850aefa89a4477d(requestParameters.localization, requestParameters.key, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Generate zip by products
   * @summary Generate zip by products
   * @param {DefaultApi5e283e5acdd6701a75cf0ed3Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public _5e283e5acdd6701a75cf0ed3(
    requestParameters: DefaultApi5e283e5acdd6701a75cf0ed3Request,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      ._5e283e5acdd6701a75cf0ed3(requestParameters.localization, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Generate zip with product details.
   * @summary Generate zip for product
   * @param {DefaultApi5e283e5adbc44c0fe5a67adbRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public _5e283e5adbc44c0fe5a67adb(
    requestParameters: DefaultApi5e283e5adbc44c0fe5a67adbRequest,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      ._5e283e5adbc44c0fe5a67adb(requestParameters.localization, requestParameters.key, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Create pdf for sample catalog by URL
   * @summary Create pdf for sample catalog by URL
   * @param {DefaultApi5faa8dd5bc404a5f288d9830Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public _5faa8dd5bc404a5f288d9830(
    requestParameters: DefaultApi5faa8dd5bc404a5f288d9830Request,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      ._5faa8dd5bc404a5f288d9830(requestParameters.localization, requestParameters.body, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Generate pdf with product details.
   * @summary Generate pdf for spare parts
   * @param {DefaultApiBe283e5a5256d782e83003ecRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public be283e5a5256d782e83003ec(
    requestParameters: DefaultApiBe283e5a5256d782e83003ecRequest,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .be283e5a5256d782e83003ec(requestParameters.localization, requestParameters.brand, requestParameters.key, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Generate pdf with product details.
   * @summary Generate pdf for product
   * @param {DefaultApiBe283e5a61e0fd72d5d78151Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public be283e5a61e0fd72d5d78151(
    requestParameters: DefaultApiBe283e5a61e0fd72d5d78151Request,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .be283e5a61e0fd72d5d78151(requestParameters.localization, requestParameters.brand, requestParameters.key, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Generate pdf with product details.
   * @summary Generate pdf for articles
   * @param {DefaultApiBe283e5ac850aefa89a4477dRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public be283e5ac850aefa89a4477d(
    requestParameters: DefaultApiBe283e5ac850aefa89a4477dRequest,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .be283e5ac850aefa89a4477d(requestParameters.localization, requestParameters.brand, requestParameters.key, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Create csv from for articles
   * @summary Create csv from for articles
   * @param {DefaultApiCe283e5aa996b507be4504acRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public ce283e5aa996b507be4504ac(
    requestParameters: DefaultApiCe283e5aa996b507be4504acRequest,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .ce283e5aa996b507be4504ac(requestParameters.localization, requestParameters.catalog, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Create pdf from catalog
   * @summary Create pdf from catalog
   * @param {DefaultApiCe283e5abb08e527a0202233Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public ce283e5abb08e527a0202233(
    requestParameters: DefaultApiCe283e5abb08e527a0202233Request,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .ce283e5abb08e527a0202233(
        requestParameters.localization,
        requestParameters.brand,
        requestParameters.catalog,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Create pdf for sample catalog by URL
   * @summary Create pdf for sample catalog by URL
   * @param {DefaultApiCfaa8dd5bc404a5f288d9830Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public cfaa8dd5bc404a5f288d9830(
    requestParameters: DefaultApiCfaa8dd5bc404a5f288d9830Request,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .cfaa8dd5bc404a5f288d9830(
        requestParameters.localization,
        requestParameters.brand,
        requestParameters.body,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Create excel from articles
   * @summary Create excel from articles
   * @param {DefaultApiEe283e5a8504d85d0c2e5abeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public ee283e5a8504d85d0c2e5abe(
    requestParameters: DefaultApiEe283e5a8504d85d0c2e5abeRequest,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .ee283e5a8504d85d0c2e5abe(
        requestParameters.localization,
        requestParameters.brand,
        requestParameters.excelRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }
}
