import { getCookie, setCookie } from "cookies-next"
import { useRouter } from "next/router"
import { useEffect } from "react"
import { useRecoilState } from "recoil"
import { mutate } from "swr"
import { MyList, PagedResult, StoreMyList } from "../../api-clients/mylist-api"
import { myListCookie } from "../constants/browser-storage"
import { apiRoutes } from "../constants/constants"
import { defaultListIdState } from "../store/defaultListIdStore"
import { fetchList, fetchUpdateList, useCreateList, useDeleteUserLists, useGetUserLists } from "./queries"
import { useAuth } from "./useGigya"

type UseUserListsReturnTypes = {
  userListsData: PagedResult
  createList: (newList: StoreMyList, onErrorFunction?: () => void) => void
  createdListData: MyList
  deleteLists: (ids: string) => void
  changeDefaultList: (listId: string) => void
  isLoading: boolean
  isUserLoggedIn: boolean
  error: unknown
}

const useUserLists = (initialUserListsData?: PagedResult): UseUserListsReturnTypes => {
  /* The id of the default list id for the user.
    If anonymous user, the defaultListId should be the one stored in the myListId cookie or empty string.
    If logged-in user, the defultListId is the one set as default in the list of users or if no default set,
      then it is the first one from the results list.
    If not lists at all for the user, then the defaultListId is the anonymous list id. 
    It is handled in the useEffect hook in this file. */
  const [defaultListId, setDefaultListId] = useRecoilState(defaultListIdState)

  const router = useRouter()
  const { locale, query } = router
  const brand = query.brand as string
  /* Get authentication data to determine is there a logged in user */
  const { accountInfo } = useAuth()
  const userId = accountInfo?.userId ?? ""
  /* Get stored in cookie myListId to get the anonymous user list */
  const myListIdCookie = (getCookie(myListCookie.KEY) as string) ?? ""

  /* Boolean to control when the list data is fetched from the API */
  const isUserLoggedIn = !!userId

  /* Function to imperatively revalidate the cache for a list */
  const refreshList = (list: MyList) =>
    mutate(
      `${apiRoutes.LISTS}/${list.id}?locale=${locale}&brand=${brand}&userId=${userId}`,
      (currentList) => {
        return { ...currentList, ...list }
      },
      { revalidate: false }
    )

  /* Function to imperatively revalidate the cache for a list */
  const refreshDefaultListInUserLists = (oldDefaultList: MyList, newDefaultList: MyList) =>
    mutate(
      `${apiRoutes.LISTS}?locale=${locale}&brand=${brand}&userId=${userId}`,
      (currentUserListsData: PagedResult) => {
        const { results: lists } = currentUserListsData

        const updatedLists = lists.map((list) => {
          if (list.id === oldDefaultList.id) return { ...list, isDefault: false }
          if (list.id === newDefaultList.id) return { ...list, isDefault: true }
          return list
        })

        return { ...currentUserListsData, results: updatedLists }
      },
      { revalidate: false }
    )

  /* SWR hook to get the list data, pass it the initial list data fetched on the server to pre-populate the cache */
  const {
    data: userListsData,
    error: getUserListsError,
    isLoading: isUserListsLoading,
    isValidating: isUserListsValidating,
  } = useGetUserLists(locale, brand, isUserLoggedIn, userId, initialUserListsData)

  /* SWR hook to create a new list for the user */
  const {
    data: createdListData,
    trigger: createUserListTrigger,
    error: createUserListError,
    isMutating: isUserListCreating,
  } = useCreateList(locale, brand, userId)

  /* SWR hook to delete the list, it is run only when the trigger function is called */
  const {
    trigger: deleteUserListsTrigger,
    error: deleteUserListsError,
    isMutating: isUserListsDeleting,
  } = useDeleteUserLists(locale, brand, userId)

  /* Wrapper for the SWR create trigger function with options after creation */
  const createList = (createListData: StoreMyList, onErrorFunction: () => void) => {
    createUserListTrigger(createListData, {
      /* Update the client side cache for user lists after create */
      revalidate: true,
      onSuccess: (createdList) => {
        if (!isUserLoggedIn) {
          const { id: newListId } = createdList
          setCookie(myListCookie.KEY, newListId, { maxAge: myListCookie.MAX_AGE })
          setDefaultListId(newListId)
        }
      },
      throwOnError: false,
      onError(err) {
        if (err.response.data.message.includes("Maximum lists allowed per user is:")) {
          onErrorFunction()
        }
        console.log(err)
      },
    })
  }

  const deleteLists = (ids: string) => {
    deleteUserListsTrigger(ids)
  }

  const changeDefaultList = async (listId: string) => {
    const { results: userLists } = userListsData

    const oldDefaultListMeta = userLists.find((list: MyList) => list.id === defaultListId)
    const newDefaultListMeta = userLists.find((list: MyList) => list.id === listId)

    /* Refresh the cache for the user lists (list of lists) */
    refreshDefaultListInUserLists(oldDefaultListMeta, newDefaultListMeta)
    /* Optimistic update of recoil local state for defaultListId */
    setDefaultListId(listId)

    try {
      const newDefaultList = await fetchUpdateList(
        locale,
        brand,
        newDefaultListMeta.id,
        [
          {
            op: "replace",
            path: "/isDefault",
            value: true,
          },
        ],
        userId
      )

      /* Refresh the cache for the new default list and the old default list */
      if (newDefaultList) {
        /* Fetch the old default list to update the cache */
        const oldDefaultList = await fetchList(locale, brand, oldDefaultListMeta.id, userId)

        // Refresh the list details page cache
        refreshList(newDefaultList)
        refreshList(oldDefaultList)
      }
    } catch (error) {
      console.log(`SET DEFAULT LIST WITH ID ${listId} FAILED: `, error)

      /* Revert refresh of the cache for the user lists (list of lists) */
      refreshDefaultListInUserLists(newDefaultListMeta, oldDefaultListMeta)

      /* Revert optimistic update of recoil local state for defaultListId */
      setDefaultListId(oldDefaultListMeta.id)
    }
  }

  const isLoading = isUserListsLoading || isUserListsValidating || isUserListCreating || isUserListsDeleting
  const error = getUserListsError || createUserListError || deleteUserListsError

  /* This effect handles the logic for the defaultListId for the user */
  useEffect(() => {
    if (isUserLoggedIn) {
      if (!!userListsData?.results?.length) {
        const defaultUserList = userListsData.results.find((list) => list.isDefault)
        setDefaultListId(defaultUserList?.id ?? userListsData?.results?.[0]?.id)
      } else {
        if (!defaultListId) setDefaultListId(myListIdCookie)
      }
    }

    if (!isUserLoggedIn) setDefaultListId(myListIdCookie)
  }, [isUserLoggedIn, myListIdCookie, userListsData?.results?.length])

  return {
    userListsData,
    createList,
    createdListData,
    deleteLists,
    changeDefaultList,
    isLoading,
    isUserLoggedIn,
    error,
  }
}

export default useUserLists
