import { Box } from "@chakra-ui/react"
import React, { Fragment, useCallback, useMemo } from "react"
import { Category } from "../../../../api-clients/product-api-v1"
import { categoryLevels, pages } from "../../../../common/constants/constants"
import { searchDefaults } from "../../../../common/constants/search-constants"
import { useBrandedTranslation } from "../../../../common/hooks/useBrandedTranslation"
import { hasSparePartsInTree } from "../../../../common/utils/category-utils"
import CustomLink from "../../../shared/CustomLink"

/* This is a component which generates all the available links in the system menu for SEO purposes.
  Links are placed in a hidden div to be available for robots indexing.
  It is fully memoized because it's expensive computational component. */

type Props = {
  systemMenuData: Category
}

const SystemCategoriesLinks = ({ systemMenuData }: Props) => {
  const { memoizedT } = useBrandedTranslation()

  /* Generate products and spare parts links to system category */
  const renderCategoryLinks = useCallback(
    (category: Category) => {
      /* CH1, CH2 and CH3 categories, without MAC */
      const isChapterCategory = !category?.id?.startsWith(categoryLevels.MASTER)
      /* CH2 and CH3 categories specifically */
      const isLowerChapterCategory = isChapterCategory && !category?.id?.startsWith(categoryLevels.ONE)

      const productsLink = `/${pages.SYSTEMS}/${category?.id}${isLowerChapterCategory ? `/${pages.PRODUCTS}` : ""}`
      const sparePartsLink = `/${pages.SYSTEMS}/${category?.id}/${pages.SPARE_PARTS}`
      const sparePartsLinkText = memoizedT("system-navigation-spare-parts-for", { categoryName: category?.name })

      return (
        <Fragment key={category?.id}>
          <CustomLink href={productsLink}>
            <a aria-label={`${category?.name} ${memoizedT("search-products")}`}>{category?.name}</a>
          </CustomLink>
          {isLowerChapterCategory && hasSparePartsInTree(category) && (
            <CustomLink href={sparePartsLink}>
              <a aria-label={sparePartsLinkText}>{sparePartsLinkText}</a>
            </CustomLink>
          )}
        </Fragment>
      )
    },
    [memoizedT]
  )

  /* Generate all system categories links below a passed root category */
  const renderAllCategoryLinks = useCallback(
    (category: Category) => {
      const categoryLinks = []

      /* Generate the category links section for the passed category, omit the root system category */
      if (category?.id !== searchDefaults.SYSTEM_CTP) categoryLinks.push(renderCategoryLinks(category))

      /* Generate category links for all sub-categories below the root one. */
      if (!!category?.subCategories?.length) {
        /* Generate the category links sections for the category sub-categories */
        category.subCategories.forEach((subCategory: Category) => {
          categoryLinks.push(renderAllCategoryLinks(subCategory))
        })
      }

      return categoryLinks
    },
    [renderCategoryLinks]
  )

  const allSystemCategoriesLinks = useMemo(
    () => renderAllCategoryLinks(systemMenuData),
    [renderAllCategoryLinks, systemMenuData]
  )

  return (
    <Box display="none" aria-label={`${memoizedT("nav-assortment")} ${memoizedT("cat-category")}`}>
      {allSystemCategoriesLinks}
    </Box>
  )
}

export default React.memo(SystemCategoriesLinks)
