// Kudos: https://github.com/chakra-ui/chakra-ui/issues/3144
const Table = {
  baseStyle: {
    th: {
      textTransform: "none",
    },
  },
  variants: {
    inverseStriped: {
      tr: {
        _even: {
          background: "gray.50",
        },
      },
    },
  },
}

export default Table
