import { IconButton, Menu, MenuButton, MenuItem, MenuList, Text } from "@chakra-ui/react"
import { BreadcrumbItemType } from "../../../../common/types/global"
import { CMore } from "../../../shared/Icons"
import CustomLink from "../../CustomLink"

type Props = {
  menuOptions: BreadcrumbItemType[]
}

const ShowHiddenMenu = ({ menuOptions }: Props) => {
  return (
    <Menu autoSelect={false} gutter={0}>
      <MenuButton
        as={IconButton}
        aria-label="Show hidden items"
        icon={<CMore boxSize="4" />}
        variant="secondary"
        color="black"
      />
      <MenuList maxW={{ base: "110", lg: "150" }} zIndex="breadcrumbs" boxShadow="md">
        {menuOptions?.map((option) => (
          <CustomLink key={option?.href} href={option?.href ?? "#"}>
            <MenuItem
              as="a"
              px="1.5"
              fontSize="sm"
              fontWeight="medium"
              wordBreak="break-all"
              onClick={option.clickHandler}
            >
              <Text noOfLines={1}>{option?.name}</Text>
            </MenuItem>
          </CustomLink>
        ))}
      </MenuList>
    </Menu>
  )
}

export default ShowHiddenMenu
