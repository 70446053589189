import { ComponentStyleConfig } from "@chakra-ui/react"

const Menu: ComponentStyleConfig = {
  baseStyle: {
    list: {
      borderRadius: "none",
      zIndex: "dropdown",
    },
    item: {
      h: { base: "11", sm: "10" },
      _hover: {
        "@media (hover: hover) and (pointer: fine)": { bgColor: "gray.200" },
        _active: {
          bgColor: "gray.900",
          color: "white",
        },
      },
    },
  },
}

export default Menu
