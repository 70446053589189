import { Flex } from "@chakra-ui/react"
import { ReactNode } from "react"
import CustomImage from "./CustomImage"

type Props = {
  children: ReactNode
}

const ErrorView = ({ children }: Props) => {
  const errorImgSrc = "/images/geberit_error_generic.svg"
  return (
    <Flex
      flexDirection={{ base: "column-reverse", md: "row" }}
      gap={{ base: "8", md: "18" }}
      alignItems="center"
      justifyContent="space-between"
    >
      <Flex
        flexDirection="column"
        mb={{ base: "4", sm: "6", md: "8" }}
        maxW={{ base: "100%", sm: "75%" }}
        w={{ base: "100%", sm: "75%" }}
      >
        {children}
      </Flex>
      <CustomImage
        src={errorImgSrc}
        w={{ base: "72", sm: "87.5", md: "125" }}
        h={{ base: "72", sm: "87.5", md: "125" }}
        alt="Error message image"
      />
    </Flex>
  )
}

export default ErrorView
