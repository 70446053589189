import { Collapse, Flex, Menu, MenuItemOption, MenuOptionGroup, Text } from "@chakra-ui/react"
import { useRouter } from "next/router"
import { useBrandedTranslation } from "../../../../../common/hooks/useBrandedTranslation"
import { LanguageMenuItemType } from "../../../../../common/types/domain"
import { CCheckmark } from "../../../../shared/Icons"

type Props = {
  isActive?: boolean
  availableLanguages: LanguageMenuItemType[]
  selectedLanguage: LanguageMenuItemType
  handleCloseMenu: () => void
}

const MobileLanguagesMenu = ({ isActive, availableLanguages, selectedLanguage, handleCloseMenu }: Props) => {
  const { t } = useBrandedTranslation()
  const router = useRouter()

  const handleLanguageChange = (value: string) => {
    router.push("/", null, { locale: value })
    handleCloseMenu()
  }

  return (
    <Collapse in={isActive} animateOpacity unmountOnExit>
      <Flex flexDirection="column">
        <Text my="2" color="gray.700" fontSize="sm" paddingY="1.5" borderBottom="1px solid" borderColor="gray.300">
          {t("acc-language-select")}
        </Text>
        <Menu>
          <MenuOptionGroup defaultValue={selectedLanguage?.locale} type="radio" onChange={handleLanguageChange}>
            {availableLanguages?.map((al) => (
              <MenuItemOption
                key={al?.locale}
                value={al?.locale}
                icon={<CCheckmark mb="1" />}
                iconSpacing="2.5"
                color="black"
                _checked={{ color: "brand.500" }}
              >
                <Text casing="capitalize" fontSize="sm" fontWeight="medium" lineHeight="base">
                  {al?.displayLanguage}
                </Text>
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        </Menu>
      </Flex>
    </Collapse>
  )
}

export default MobileLanguagesMenu
