import { useEffect } from "react"
import { useSetRecoilState } from "recoil"
import { localesWithoutGigya } from "../constants/constants"
import { gigyaIsEnabledState } from "../store/authStore"
import { localeState } from "../store/settingsStore"

/* Hook used to set all the global state we use in Recoil
  It is called in the Layout component which is wrapping every page */
export const useSetGlobalState = (locale: string) => {
  const setLocaleSettings = useSetRecoilState(localeState)
  const setGigyaIsEnabled = useSetRecoilState(gigyaIsEnabledState)

  useEffect(() => {
    setLocaleSettings(locale)
  }, [locale, setLocaleSettings])

  useEffect(() => {
    setGigyaIsEnabled(!localesWithoutGigya.includes(locale))
  }, [locale, setGigyaIsEnabled])
}
