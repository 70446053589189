import { Button, ButtonProps, Text } from "@chakra-ui/react"

type Props = ButtonProps & {
  label: string
  href?: string
}

const NavigationMenuButton = ({
  onClick,
  minH = { base: "11", md: "10" },
  width = "auto",
  leftIcon,
  rightIcon,
  label,
  pl = "2",
  pr = "2",
  pt = "2",
  pb = "2",
}: Props) => {
  return (
    <Button
      onClick={onClick}
      width={width}
      bgColor="transparent"
      h="auto"
      minH={minH}
      pl={pl}
      pr={pr}
      pt={pt}
      pb={pb}
      mt="0"
      leftIcon={leftIcon}
      rightIcon={rightIcon}
    >
      <Text as="span" whiteSpace="normal" fontSize="sm" fontWeight="medium" w="full" textAlign="left">
        {label}
      </Text>
    </Button>
  )
}

export default NavigationMenuButton
