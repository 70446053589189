import { ButtonProps } from "@chakra-ui/react"
import { BreadcrumbItemType } from "../../../common/types/global"
import BackButton from "../BackButton"

type Props = ButtonProps & {
  mobileCrumb: BreadcrumbItemType
  noOfLines?: number
}

const MobileCrumb = ({ mobileCrumb, noOfLines = 2, ...rest }: Props) => (
  <BackButton
    text={mobileCrumb?.name}
    onClick={mobileCrumb?.clickHandler}
    href={mobileCrumb?.href ?? "#"}
    noOfLines={noOfLines}
    {...rest}
  />
)

export default MobileCrumb
