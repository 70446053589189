/* tslint:disable */
/* eslint-disable */
/**
 * Product Suggest
 * Provides search-as-you-type functionality for search fields. Data returned by this API includes top product, article, spare parts tables and categories results as well as commonly used searches.<br><br><strong>Links</strong><ul><li><a href=\"https://geberit-projects.atlassian.net/wiki/spaces/SMA/pages/3690135726\" target=\"_new\">Introduction to Product Search Services</a></li><li><a href=\"https://geberit-projects.atlassian.net/wiki/spaces/SMA/pages/3650617345\" target=\"_new\">Additional Information about Suggest Front-end Integration</a></li><li><a href=\"https://geberit-projects.atlassian.net/wiki/spaces/SMA/pages/3691642943\" target=\"_new\">Suggest Example Requests (Postman)</a></li></ul>
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { AxiosInstance, AxiosPromise, AxiosRequestConfig } from "axios"
import globalAxios from "axios"
import type { Configuration } from "./configuration"
// Some imports not used depending on template conditions
// @ts-ignore
import type { RequestArgs } from "./base"
import {
  assertParamExists,
  createRequestFunction,
  DUMMY_BASE_URL,
  setApiKeyToObject,
  setSearchParams,
  toPathString,
} from "./common"
// @ts-ignore
import { BaseAPI, BASE_PATH } from "./base"

/**
 * The schema used for the responses when an error occurs.
 * @export
 * @interface SuggestErrorModel
 */
export interface SuggestErrorModel {
  /**
   * An error message.
   * @type {string}
   * @memberof SuggestErrorModel
   */
  message?: string
}
/**
 * The schema used for the responses of all successful requests.
 * @export
 * @interface SuggestModel
 */
export interface SuggestModel {
  /**
   * An array of suggestion groups. All groups will be present in every response, even if some groups have no suggestions.
   * @type {Array<SuggestionGroups>}
   * @memberof SuggestModel
   */
  suggestionGroups?: Array<SuggestionGroups>
}
/**
 * The schema for a single suggestion in the response.
 * @export
 * @interface Suggestion
 */
export interface Suggestion {
  /**
   * A search term suggested based on the current user input in the search field.<br><br>Valid for indexes: `0terms`
   * @type {string}
   * @memberof Suggestion
   */
  searchterm?: string
  /**
   * The number of search results for the suggested search term.<br><br>Valid for indexes: `0terms`, `4categories`
   * @type {number}
   * @memberof Suggestion
   */
  nrResults?: number
  /**
   * The Fredhopper ID for the suggested product, article or spare parts table.<br><br>Valid for indexes: `1products`, `2articleproducts`, `3spareparttables`
   * @type {string}
   * @memberof Suggestion
   */
  secondId?: string
  /**
   * A URL of a thumbnail image for the suggested product, article or spare parts table.<br><br>Valid for indexes: `1products`, `2articleproducts`, `3spareparttables`
   * @type {string}
   * @memberof Suggestion
   */
  _imageurl?: string
  /**
   * The name of the suggested product, article or spare parts table.<br><br>Valid for indexes: `1products`, `2articleproducts`, `3spareparttables`
   * @type {string}
   * @memberof Suggestion
   */
  name?: string
  /**
   * The name of the series of the suggested product. If the product is not in a series, this is empty.<br><br>Valid for indexes: `1products`
   * @type {string}
   * @memberof Suggestion
   */
  series?: string
  /**
   * The number of articles under the suggested product.<br><br>Valid for indexes: `1products`
   * @type {string}
   * @memberof Suggestion
   */
  amount_articles?: string
  /**
   * An indicator tag if the suggested product or article is a novelty for the current year.<br>- 1 if the suggested product is a novelty<br>- 0 if the suggested product is not a novelty<br><br>Valid for indexes: `1products`, `2articleproducts`
   * @type {string}
   * @memberof Suggestion
   */
  novelty_product?: string
  /**
   * An indicator tag if the suggested product or article is being phased out.<br>- 1 if the suggested product is being phased out<br>- 0 if the suggested product is not being phased out<br><br>Valid for indexes: `1products`, `2articleproducts`
   * @type {string}
   * @memberof Suggestion
   */
  phase_out_indicator?: string
  /**
   * The article number of the suggested article.<br><br>Valid for indexes: `2articleproducts`
   * @type {string}
   * @memberof Suggestion
   */
  articlenumber?: string
  /**
   * A list of articles which the suggested article replaces.<br><br>Valid for indexes: `2articleproducts`
   * @type {string}
   * @memberof Suggestion
   */
  replaced_articles?: string
  /**
   * A list of articles which replace the suggested article.<br><br>Valid for indexes: `2articleproducts`
   * @type {string}
   * @memberof Suggestion
   */
  replaced_by?: string
  /**
   * An indicator which shows if the replacement information should be suppressed for the suggested article.<br><br>Valid for indexes: `2articleproducts`
   * @type {string}
   * @memberof Suggestion
   */
  replacement_suppression?: string
  /**
   * The STEP ID of the suggested product or parent product for the suggested article.<br><br>Valid for indexes: `1products`, `2articleproducts`
   * @type {string}
   * @memberof Suggestion
   */
  productid?: string
  /**
   * A list of spare part articles in the suggested spare parts table.<br><br>Valid for indexes: `3spareparttables`
   * @type {string}
   * @memberof Suggestion
   */
  spt_spare_parts?: string
  /**
   * The number of spare part articles in the suggested spare parts table. This is equal to the number of items in spt_spare_parts.<br><br>Valid for indexes: `3spareparttables`
   * @type {string}
   * @memberof Suggestion
   */
  spt_spare_parts_counts?: string
  /**
   * The year the last main article for the suggested spare parts table was phased out in the current market. This also signifies the start of the spare parts warranty countdown for the spare parts table in this market.<br><br>Valid for indexes: `3spareparttables`
   * @type {string}
   * @memberof Suggestion
   */
  year_manufacture_until?: string
  /**
   * The year a main article for the suggested spare parts table was first introduced in the current market.<br><br>Valid for indexes: `3spareparttables`
   * @type {string}
   * @memberof Suggestion
   */
  year_manufacture_from?: string
  /**
   * The name of the suggested category prefixed by the name of its parent category, delimited by ` / `. The first five characters in the category names are a sorting hint and should be removed before displaying the names.<br><br>Valid for indexes: `4categories`
   * @type {string}
   * @memberof Suggestion
   */
  mlValue?: string
  /**
   * The location selector value that can be used for requesting the search results for the suggested category.<br><br>Valid for indexes: `4categories`
   * @type {string}
   * @memberof Suggestion
   */
  fhLocation?: string
  /**
   * The Fredhopper ID for the suggested category.<br><br>Valid for indexes: `4categories`
   * @type {string}
   * @memberof Suggestion
   */
  catid?: string
}
/**
 * The schema for each group of suggestions in the response.
 * @export
 * @interface SuggestionGroups
 */
export interface SuggestionGroups {
  /**
   * The name of the index used for the suggestion group. Each group contains a different type of suggestions.<br>- `0terms` contains suggestions of popular search queries performed by all users.<br>- `1products` contains product suggestions.<br>- `2articleproducts` contains article suggestions.<br>- `3spareparttables` contains spare parts table suggestions.<br>- `4categories` contains category suggestions.
   * @type {string}
   * @memberof SuggestionGroups
   */
  indexName?: string
  /**
   * Currently the same as the index name.
   * @type {string}
   * @memberof SuggestionGroups
   */
  indexTitle?: string
  /**
   * The suggestions for each group. They are capped at 3 suggestions per group. Empty if no suggestions were found for the group.
   * @type {Array<Suggestion>}
   * @memberof SuggestionGroups
   */
  suggestions?: Array<Suggestion>
}

/**
 * ProductSuggestApi - axios parameter creator
 * @export
 */
export const ProductSuggestApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get product search suggestions for a partially or completely entered search phrase and a specific locale. The suggestions will be divided into groups based on the types of suggested results and will be limited to a few suggestions for each group. The purpose of this endpoint is to provide search-as-you-type functionality for search fields.
     * @summary Get product search suggestions
     * @param {string} search The search phrase as the user is currently typing.&lt;br&gt;&lt;br&gt;Special characters must be Unicode encoded and the value must be 19 tokens (words) at most.&lt;br&gt;&lt;br&gt;There is a special value that can be passed, &#x60;defaultsearch&#x60;, which returns only results in the &#x60;0terms&#x60; suggestion group. This can be sent when the search field is active but too few characters have been entered to provide good results.&lt;br&gt;&lt;br&gt;&lt;strong&gt;Example:&lt;/strong&gt;&lt;br&gt;If the user types &#x60;betät&#x60; in the search bar, the parameter sent should be &#x60;&amp;search&#x3D;bet%C3%A4&#x60;
     * @param {string} scope A suggest scope is a locale and brand context of the suggest request and will affect the suggestions in all indicies. The scope is based on the Fredhopper user location also used in the product query endpoint.&lt;br&gt;&lt;br&gt;The scope pattern is: &#x60;&amp;scope&#x3D;//catalog01/[locale]/brand_selector&gt;{[brand ID]}&#x60;, where:&lt;br&gt;- catalog01: This is the ID of the regular Geberit product catalogue in Fredhopper. As there is currently only one catalogue, treat this as a hard-coded value.&lt;br&gt;- [locale]: The &lt;a href&#x3D;\&quot;/locales\&quot; target&#x3D;\&quot;_new\&quot;&gt;locale&lt;/a&gt; of the current market and language. Use an underscore as the separator, like &#x60;de_CH&#x60; or &#x60;en_GB&#x60;.&lt;br&gt;- [brand ID]: The brand of the catalog. The values should be normalised brand IDs used across the MBP, but in all lower case. E.g. &#x60;geberit&#x60; or &#x60;ifo0308&#x60;&lt;br&gt;&lt;br&gt;&lt;strong&gt; Example:&lt;/strong&gt;&lt;br&gt;In the Geberit online catalogs, the scope entry for a suggest query in German for market Switzerland and the brand Geberit is: &#x60;&amp;scope&#x3D;//catalog01/de_CH/brand_selector&gt;{geberit}&#x60;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSearchSuggest: async (search: string, scope: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'search' is not null or undefined
      assertParamExists("getSearchSuggest", "search", search)
      // verify required parameter 'scope' is not null or undefined
      assertParamExists("getSearchSuggest", "scope", scope)
      const localVarPath = `/json`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      if (search !== undefined) {
        localVarQueryParameter["search"] = search
      }

      if (scope !== undefined) {
        localVarQueryParameter["scope"] = scope
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ProductSuggestApi - functional programming interface
 * @export
 */
export const ProductSuggestApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ProductSuggestApiAxiosParamCreator(configuration)
  return {
    /**
     * Get product search suggestions for a partially or completely entered search phrase and a specific locale. The suggestions will be divided into groups based on the types of suggested results and will be limited to a few suggestions for each group. The purpose of this endpoint is to provide search-as-you-type functionality for search fields.
     * @summary Get product search suggestions
     * @param {string} search The search phrase as the user is currently typing.&lt;br&gt;&lt;br&gt;Special characters must be Unicode encoded and the value must be 19 tokens (words) at most.&lt;br&gt;&lt;br&gt;There is a special value that can be passed, &#x60;defaultsearch&#x60;, which returns only results in the &#x60;0terms&#x60; suggestion group. This can be sent when the search field is active but too few characters have been entered to provide good results.&lt;br&gt;&lt;br&gt;&lt;strong&gt;Example:&lt;/strong&gt;&lt;br&gt;If the user types &#x60;betät&#x60; in the search bar, the parameter sent should be &#x60;&amp;search&#x3D;bet%C3%A4&#x60;
     * @param {string} scope A suggest scope is a locale and brand context of the suggest request and will affect the suggestions in all indicies. The scope is based on the Fredhopper user location also used in the product query endpoint.&lt;br&gt;&lt;br&gt;The scope pattern is: &#x60;&amp;scope&#x3D;//catalog01/[locale]/brand_selector&gt;{[brand ID]}&#x60;, where:&lt;br&gt;- catalog01: This is the ID of the regular Geberit product catalogue in Fredhopper. As there is currently only one catalogue, treat this as a hard-coded value.&lt;br&gt;- [locale]: The &lt;a href&#x3D;\&quot;/locales\&quot; target&#x3D;\&quot;_new\&quot;&gt;locale&lt;/a&gt; of the current market and language. Use an underscore as the separator, like &#x60;de_CH&#x60; or &#x60;en_GB&#x60;.&lt;br&gt;- [brand ID]: The brand of the catalog. The values should be normalised brand IDs used across the MBP, but in all lower case. E.g. &#x60;geberit&#x60; or &#x60;ifo0308&#x60;&lt;br&gt;&lt;br&gt;&lt;strong&gt; Example:&lt;/strong&gt;&lt;br&gt;In the Geberit online catalogs, the scope entry for a suggest query in German for market Switzerland and the brand Geberit is: &#x60;&amp;scope&#x3D;//catalog01/de_CH/brand_selector&gt;{geberit}&#x60;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSearchSuggest(
      search: string,
      scope: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuggestModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSearchSuggest(search, scope, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * ProductSuggestApi - factory interface
 * @export
 */
export const ProductSuggestApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ProductSuggestApiFp(configuration)
  return {
    /**
     * Get product search suggestions for a partially or completely entered search phrase and a specific locale. The suggestions will be divided into groups based on the types of suggested results and will be limited to a few suggestions for each group. The purpose of this endpoint is to provide search-as-you-type functionality for search fields.
     * @summary Get product search suggestions
     * @param {ProductSuggestApiGetSearchSuggestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSearchSuggest(
      requestParameters: ProductSuggestApiGetSearchSuggestRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<SuggestModel> {
      return localVarFp
        .getSearchSuggest(requestParameters.search, requestParameters.scope, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for getSearchSuggest operation in ProductSuggestApi.
 * @export
 * @interface ProductSuggestApiGetSearchSuggestRequest
 */
export interface ProductSuggestApiGetSearchSuggestRequest {
  /**
   * The search phrase as the user is currently typing.&lt;br&gt;&lt;br&gt;Special characters must be Unicode encoded and the value must be 19 tokens (words) at most.&lt;br&gt;&lt;br&gt;There is a special value that can be passed, &#x60;defaultsearch&#x60;, which returns only results in the &#x60;0terms&#x60; suggestion group. This can be sent when the search field is active but too few characters have been entered to provide good results.&lt;br&gt;&lt;br&gt;&lt;strong&gt;Example:&lt;/strong&gt;&lt;br&gt;If the user types &#x60;betät&#x60; in the search bar, the parameter sent should be &#x60;&amp;search&#x3D;bet%C3%A4&#x60;
   * @type {string}
   * @memberof ProductSuggestApiGetSearchSuggest
   */
  readonly search: string

  /**
   * A suggest scope is a locale and brand context of the suggest request and will affect the suggestions in all indicies. The scope is based on the Fredhopper user location also used in the product query endpoint.&lt;br&gt;&lt;br&gt;The scope pattern is: &#x60;&amp;scope&#x3D;//catalog01/[locale]/brand_selector&gt;{[brand ID]}&#x60;, where:&lt;br&gt;- catalog01: This is the ID of the regular Geberit product catalogue in Fredhopper. As there is currently only one catalogue, treat this as a hard-coded value.&lt;br&gt;- [locale]: The &lt;a href&#x3D;\&quot;/locales\&quot; target&#x3D;\&quot;_new\&quot;&gt;locale&lt;/a&gt; of the current market and language. Use an underscore as the separator, like &#x60;de_CH&#x60; or &#x60;en_GB&#x60;.&lt;br&gt;- [brand ID]: The brand of the catalog. The values should be normalised brand IDs used across the MBP, but in all lower case. E.g. &#x60;geberit&#x60; or &#x60;ifo0308&#x60;&lt;br&gt;&lt;br&gt;&lt;strong&gt; Example:&lt;/strong&gt;&lt;br&gt;In the Geberit online catalogs, the scope entry for a suggest query in German for market Switzerland and the brand Geberit is: &#x60;&amp;scope&#x3D;//catalog01/de_CH/brand_selector&gt;{geberit}&#x60;
   * @type {string}
   * @memberof ProductSuggestApiGetSearchSuggest
   */
  readonly scope: string
}

/**
 * ProductSuggestApi - object-oriented interface
 * @export
 * @class ProductSuggestApi
 * @extends {BaseAPI}
 */
export class ProductSuggestApi extends BaseAPI {
  /**
   * Get product search suggestions for a partially or completely entered search phrase and a specific locale. The suggestions will be divided into groups based on the types of suggested results and will be limited to a few suggestions for each group. The purpose of this endpoint is to provide search-as-you-type functionality for search fields.
   * @summary Get product search suggestions
   * @param {ProductSuggestApiGetSearchSuggestRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductSuggestApi
   */
  public getSearchSuggest(requestParameters: ProductSuggestApiGetSearchSuggestRequest, options?: AxiosRequestConfig) {
    return ProductSuggestApiFp(this.configuration)
      .getSearchSuggest(requestParameters.search, requestParameters.scope, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
