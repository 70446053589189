import { emailRegex, prismicTeaserVisibleFor } from "../constants/constants"

/* Helper function to replace all occurencies of a symbol in a string */
export const replaceSymbol = (str: string, symbol: string, newSymbol: string): string => {
  return str ? str.replace(new RegExp(`\\${symbol}+`, "g"), newSymbol) : ""
}

export const handleDownloadClick = (e, href: string) => {
  e.preventDefault()
  window.open(href, "_blank")
}

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  })
}

/* Return the hash equivalent of a string
  https://www.geeksforgeeks.org/how-to-create-hash-from-string-in-javascript/ */
export const stringToHash = (valueString: string) => {
  let hash = 0

  if (typeof valueString !== "string" || !valueString.length) return hash.toString()

  for (let i = 0; i < valueString.length; i++) {
    const char = valueString.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash = hash & hash
  }

  return hash.toString()
}

/* Function to download file data once it has been fetched as a blob */
export const downloadFile = (fileData: Blob, fileName: string, fileType: string) => {
  const href = URL.createObjectURL(fileData)
  downloadLink(href, fileName, fileType)
}

/* Function to download a file from a given url. */
export const downloadLink = (href: string, fileName: string, fileType: string) => {
  /* Create "a" HTML element with href to file & click */
  const link = document.createElement("a")
  link.href = href
  link.setAttribute("download", `${fileName}.${fileType}`)
  document.body.appendChild(link)
  link.click()

  /* Clean up the dummy "a" element & remove ObjectURL */
  document.body.removeChild(link)
  URL.revokeObjectURL(href)
}

export function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const getFullURL = (baseURL: string, locale: string, path: string): URL => {
  const protocol =
    baseURL.split(":")[0] == "localhost" ? "http" : baseURL.split(":")[0] == "127.0.0.0" ? "http" : "https"
  const url = new URL(`${protocol}://${baseURL}/${locale}${path}`)
  return url
}

export const getBaseURLwithLocale = (baseURL: string, locale: string): URL => {
  const protocol =
    baseURL.split(":")[0] == "localhost" ? "http" : baseURL.split(":")[0] == "127.0.0.0" ? "http" : "https"
  const url = new URL(`${protocol}://${baseURL}/${locale}`)
  return url
}

export const isValidEmail = (email?: string): boolean => {
  return email ? emailRegex.test(email) : false
}

/* Formats a date to ISO 8601 format YYYY-MM-DD  */
export const formatDate = (date: Date): string => {
  const year = date.getFullYear()
  // Add 1 to month because Date months are zero indexed.
  const month = String(date.getMonth() + 1).padStart(2, "0")
  const day = String(date.getDate()).padStart(2, "0")
  return `${year}-${month}-${day}`
}

// Function to get the device type
// https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#mobile_tablet_or_desktop
export const getDeviceType = (defaultUserAgent = "") => {
  const userAgent =
    (typeof window !== "undefined" && navigator.userAgent.toLowerCase()) || defaultUserAgent?.toLowerCase()
  // If userAgent is not available, return default value
  if (!userAgent) return prismicTeaserVisibleFor.DEFAULT
  if (userAgent.includes("mobi")) {
    return prismicTeaserVisibleFor.MOBILE
  } else {
    return prismicTeaserVisibleFor.DESKTOP
  }
}

export const checkVisibilityOfTeaserCard = (teaser_visible_for: string, DEVICE_TYPE: string): boolean => {
  return (
    teaser_visible_for === prismicTeaserVisibleFor.DEFAULT ||
    teaser_visible_for === null || // Prismic will leave the default value as null if not changed manually
    teaser_visible_for === DEVICE_TYPE
  )
}
