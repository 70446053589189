import { Box, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from "@chakra-ui/react"
import { Category } from "../../../../api-clients/product-api-v1"
import { layout, systemMenu } from "../../../../common/constants/constants"
import { leanFadeInLeft } from "../../../../common/utils/animation-utils"
import { getCategoryDataById } from "../../../../common/utils/category-utils"
import { CChevronRight } from "../../../shared/Icons"
import CategoryLinksSection from "./CategoryLinksSection"

type Props = {
  selectedCategoryId: string
  macCategories: Category[]
  tabsSectionName: string
  handleTabChange: (masterChapterCategoryId: string) => void
  handleBackNavigation: () => void
  handleForwardNavigation: (nextCategoryId: string) => void
  handleClose: () => void
}

const CategoryTabsSection = ({
  selectedCategoryId,
  macCategories,
  tabsSectionName,
  handleTabChange,
  handleBackNavigation,
  handleForwardNavigation,
  handleClose,
}: Props) => {
  /* Find the selected category in the array with master chapter categories */
  const selectedCategory = getCategoryDataById(macCategories, selectedCategoryId)

  return (
    <Tabs
      variant="systemMenu"
      h={`calc(100vh - ${layout.DESKTOP_HEADER_HEIGHT})`}
      orientation="vertical"
      defaultIndex={-1}
    >
      {/* Left side */}
      <Box bgColor="gray.50">
        <TabList
          width={systemMenu.DESKTOP_TABS_WIDTH}
          pt="8"
          border="none"
          animation={`${leanFadeInLeft} 0.3s ease-out`}
        >
          <Text color="gray.700" mb="4" fontWeight="normal">
            {tabsSectionName}
          </Text>
          {macCategories.map((masterChapterCategory: Category) => (
            <Tab key={masterChapterCategory.id} onMouseDown={() => handleTabChange(masterChapterCategory.id)}>
              <Text textAlign="left" noOfLines={2}>
                {masterChapterCategory.name}
              </Text>
              <CChevronRight />
            </Tab>
          ))}
        </TabList>
      </Box>

      {/* Right side */}
      <TabPanels>
        {macCategories.map((masterChapterCategory: Category) => {
          return (
            <TabPanel
              key={masterChapterCategory.id}
              px="6"
              pt="8"
              height="100%"
              maxH={`calc(100% - ${layout.DESKTOP_HEADER_HEIGHT})`}
            >
              {selectedCategory && (
                <CategoryLinksSection
                  category={selectedCategory}
                  handleBackNavigation={handleBackNavigation}
                  handleForwardNavigation={handleForwardNavigation}
                  handleClose={handleClose}
                  key={selectedCategory.id}
                />
              )}
            </TabPanel>
          )
        })}
      </TabPanels>
    </Tabs>
  )
}

export default CategoryTabsSection
