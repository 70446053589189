import { Flex, MenuItem, Tag, Text } from "@chakra-ui/react"
import dynamic from "next/dynamic"
import { useRouter } from "next/router"
import { useEffect } from "react"
import { useRecoilValue } from "recoil"
import { StoreMyList } from "../../../../api-clients/mylist-api"
import { pages } from "../../../../common/constants/constants"
import { useBrandedTranslation } from "../../../../common/hooks/useBrandedTranslation"
import useList from "../../../../common/hooks/useList"
import useUserLists from "../../../../common/hooks/useUserLists"
import { defaultListIdState } from "../../../../common/store/defaultListIdStore"
import CustomLink from "../../../shared/CustomLink"
import LoadingIcon from "../../../shared/DownloadButton/LoadingIcon"

/* LoginButton depends on Gigya data, that's why it needs to be client-side rendered only */
const LoginButton = dynamic(() => import("./LoginButton"), { ssr: false })

type Props = {
  isOpen: boolean
  onClose: () => void
}

const MyListsLoggedOut = ({ isOpen, onClose }: Props) => {
  const { t } = useBrandedTranslation()
  const router = useRouter()
  const defaultListId = useRecoilValue(defaultListIdState)

  const { createList, createdListData, isLoading: isUserListsLoading, error: listsError } = useUserLists()

  /* Custom hook to get the list data */
  const { listData, isLoading: isListLoading } = useList(defaultListId)

  /* Show loader in place of list quantity if the user has a list and we wait for its data or we create the list before redirection */
  const isLoading = isListLoading || isUserListsLoading

  useEffect(() => {
    if (isOpen && createdListData) {
      const { id: newListId } = createdListData

      /* Redirect to the newly created list page */
      router.push(`/${pages.LIST}/${newListId}`)

      /* Close the My Lists menu */
      onClose()

      return
    }

    if (isOpen && listsError) {
      // TODO (nan) Handle the error with a Toast when the component is created
      console.log("CREATE MY LIST ERROR: ", listsError)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdListData])

  const handleCreateList = async (e: React.MouseEvent<HTMLButtonElement>) => {
    /* If no previously stored anonymous list id, create a new list */
    if (!defaultListId) {
      e.preventDefault()

      const newList: StoreMyList = { name: t("wishlist-default-name") }
      createList(newList)

      return
    }

    onClose()
  }

  return (
    <Flex flexDirection="column" rowGap={{ base: "0", md: "2" }}>
      <CustomLink href={`/${pages.LIST}/${defaultListId}`}>
        <MenuItem
          as="a"
          alignItems="center"
          justifyContent="space-between"
          color="black"
          onClick={handleCreateList}
          px={{ base: "2", md: "3" }}
          h={{ base: "11", md: "10" }}
        >
          <Text fontSize="sm" fontWeight="medium" noOfLines={1} wordBreak="break-all">
            {listData ? listData.name : t("wishlist-default-name")}
          </Text>
          {isOpen && isLoading ? (
            <LoadingIcon />
          ) : (
            <Tag borderRadius="full" bgColor="gray.200" h="6">
              <Text color="gray.900" fontSize="xs" fontWeight="medium">
                {listData?.totalArticles ?? 0}
              </Text>
            </Tag>
          )}
        </MenuItem>
      </CustomLink>
      <Flex
        mx={{ base: "0", md: "2" }}
        pt="4"
        flexDirection="column"
        borderTop={{ base: "none", md: "1px solid" }}
        borderColor={{ base: "initial", md: "gray.300" }}
      >
        <Text fontSize="sm" color="black" mb="2">
          {t("acc-lst-mobile-info")}
        </Text>
        <LoginButton isLink handler={onClose} />
      </Flex>
    </Flex>
  )
}

export default MyListsLoggedOut
