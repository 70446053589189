export const getEnvSpecificKey = (configString: string): string => {
  const hostname = typeof window !== "undefined" ? window.location.hostname : null
  return getEnvByHost(configString, hostname)
}

export const getEnvSpecificKeyByHostAndLocale = (
  configString: string | undefined,
  host: string,
  locale: string
): string => {
  if (!configString) return ""
  const domainWithLocale = `${host}/${locale}`
  return getEnvByHost(configString, domainWithLocale)
}

export const getEnvSpecificKeyByBrandAndLocale = (configString: string | undefined): string => {
  if (!configString) return ""
  const href = typeof window !== "undefined" ? window.location.href : null
  const regex = /([^/]+)\/([a-z]{2}-[A-Z]{2})/
  const matches = href?.match(regex)
  let extractedUrl = ""
  if (matches) {
    const domain = matches[1]
    const locale = matches[2]
    extractedUrl = `${domain}/${locale}`
  }
  return getEnvByHost(configString, extractedUrl)
}

export const getEnvByHost = (configString: string, hostname: string): string => {
  const config = JSON.parse(configString)
  return config.hasOwnProperty(hostname) ? config[hostname] : config.default
}
