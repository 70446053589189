import { ComponentStyleConfig } from "@chakra-ui/react"

const closeButton: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: "none",
    _hover: { "@media (hover: hover) and (pointer: fine)": { bgColor: "gray.200" } },
    _active: { bgColor: "gray.900", color: "white" },
  },
  sizes: {
    md: {
      fontSize: "sm",
    },
  },
}

export default closeButton
