import { ComponentStyleConfig } from "@chakra-ui/react"

const Link: ComponentStyleConfig = {
  variants: {
    navigationBar: {
      py: "9.5",
      _hover: { textDecoration: "none" },
    },
    tile: {
      _hover: { textDecoration: "none" },
    },
  },
}

export default Link
