/* This custom hook is used to handle the warnings for useLayoutEffect usage when doing SSR.
  Use only in components which need to support SSR. */
import { useEffect, useLayoutEffect } from "react"

const isSSR = typeof window === "undefined"

/* If server-side rendering replace useLayoutEffect with useFeect which does not fire a warning */
const useIsomorphicLayoutEffect = isSSR ? useEffect : useLayoutEffect

export default useIsomorphicLayoutEffect
