import { ComponentStyleConfig } from "@chakra-ui/react"
import { theme } from "@chakra-ui/theme"
import { StyleFunctionProps } from "@chakra-ui/theme-tools"

const Button: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: "none",
    cursor: "pointer",
  },
  variants: {
    solid: {
      color: "black",
      _hover: { "@media (hover: hover) and (pointer: fine)": { bgColor: "gray.200" } },
      _active: { bgColor: "gray.900", color: "white" },
    },
    primary: {
      minH: { base: "11", md: "10" },
      h: "fit-content",
      fontWeight: "medium",
      fontSize: "sm",
      bgColor: "brand.500",
      color: "white",
      _hover: {
        "@media (hover: hover) and (pointer: fine)": { bgColor: "brand.700" },
        _disabled: { color: "black", bgColor: "gray.300" },
      },
      _active: { bgColor: "brand.800" },
      _disabled: { color: "black", bgColor: "gray.300" },
    },
    secondary: {
      minH: { base: "11", md: "10" },
      h: "fit-content",
      fontWeight: "medium",
      fontSize: "sm",
      bgColor: "transparent",
      color: "brand.500",
      whiteSpace: "break-spaces",
      _hover: { "@media (hover: hover) and (pointer: fine)": { bgColor: "gray.200" } },
      _active: { bgColor: "gray.900", color: "white", _disabled: { color: "initial" } },
    },
    mobileMenu: {
      minH: "11",
      h: "fit-content",
      w: "100%",
      px: "3",
      fontWeight: "medium",
      fontSize: "sm",
      bgColor: "transparent",
      color: "black",
      justifyContent: "flex-start",
      _hover: { "@media (hover: hover) and (pointer: fine)": { bgColor: "gray.200" } },
      _active: { bgColor: "gray.900", color: "white" },
    },
    categoryBreadcrumb: {
      bgColor: "gray.200",
      _hover: { "@media (hover: hover) and (pointer: fine)": { bgColor: "gray.300" } },
      _active: { bgColor: "gray.900", color: "white" },
    },
    outline: {
      border: "1px solid",
      borderColor: "black",
      color: "black",
    },
    outlineRed: {
      color: "error",
      border: "solid 1px",
      borderColor: "error",
      bgColor: "transparent",
      _hover: { "@media (hover: hover) and (pointer: fine)": { bgColor: "gray.200" } },
      _active: { borderColor: "gray.900", bgColor: "gray.900", color: "white" },
    },
    outlineBlack: {
      color: "black",
      fontSize: "sm",
      fontWeight: "medium",
      border: "solid 1px",
      borderColor: "black",
      bgColor: "transparent",
      _hover: { "@media (hover: hover) and (pointer: fine)": { bgColor: "gray.200" } },
      _active: { borderColor: "gray.900", bgColor: "gray.900", color: "white" },
    },
    textLink: {
      _hover: { textDecoration: "underline" },
      minH: { base: "11", md: "10" },
      h: "fit-content",
      fontWeight: "medium",
      fontSize: "sm",
      bgColor: "transparent",
      whiteSpace: "break-spaces",
    },
    textLinkShort: {
      _hover: { textDecoration: "underline", color: "brand.700" },
      minH: { base: "11", md: "10" },
      h: "fit-content",
      fontWeight: "medium",
      fontSize: "sm",
      bgColor: "transparent",
      whiteSpace: "break-spaces",
      justifyContent: "left",
      padding: 2,
      color: "brand.500",
      maxWidth: "max-content",
    },
    grayer: {
      color: "black",
      bgColor: "gray.200",
      _hover: {
        "@media (hover: hover) and (pointer: fine)": { bgColor: "gray.300" },
        _disabled: { bgColor: "gray.200" },
      },
      _active: { bgColor: "gray.900", color: "white", _disabled: { color: "initial" } },
    },
    dropdown: (props: StyleFunctionProps) => ({
      ...theme.components.Button.variants.solid(props),
      color: "black",
      _hover: { "@media (hover: hover) and (pointer: fine)": { bgColor: "gray.200" } },
    }),
    navigationBar: {
      fontWeight: "normal",
      fontSize: "md",
      h: "25",
      border: "none",
      px: "0",
      py: "9.5",
      color: "black",
    },
    onboardingNavigation: {
      bgColor: "transparent",
      _hover: { bgColor: "transparent" },
      color: "white",
    },
  },
}

export default Button
