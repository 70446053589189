import { BreadcrumbItem, Text } from "@chakra-ui/react"

type Props = {
  label: string
}

const LastCrumb = ({ label }: Props) => {
  return (
    <BreadcrumbItem>
      <Text
        as="span"
        ml="1.5"
        fontWeight="normal"
        fontSize="sm"
        color="gray.600"
        lineHeight="5"
        noOfLines={1}
        aria-current="page"
      >
        {label}
      </Text>
    </BreadcrumbItem>
  )
}

export default LastCrumb
