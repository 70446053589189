import { Button } from "@chakra-ui/react"
import { useBrandedTranslation } from "../../../../common/hooks/useBrandedTranslation"
import CustomTooltip from "../../../shared/CustomTooltip"
import { CPlus } from "../../../shared/Icons"

type Props = {
  onClick: () => void
  isDisabled: boolean
  variant?: string
}

const CreateMyListButton = ({ onClick, isDisabled, variant = "primary" }: Props) => {
  const { t } = useBrandedTranslation()

  const label = isDisabled ? t("acc-myLists-limit-tooltip") : ""

  return (
    <CustomTooltip label={label} maxW="60">
      <Button
        leftIcon={<CPlus />}
        isDisabled={isDisabled}
        onClick={onClick}
        variant={variant}
        w={{ base: "100%", xs: "auto" }}
      >
        {t("acc-myLists-add")}
      </Button>
    </CustomTooltip>
  )
}

export default CreateMyListButton
