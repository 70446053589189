import { Box, Center, Container, Flex, Link, useDisclosure } from "@chakra-ui/react"
import { useRouter } from "next/router"
import { Category } from "../../../../api-clients/product-api-v1"
import { localesWithoutSeries, pages } from "../../../../common/constants/constants"
import { useBrandedTranslation } from "../../../../common/hooks/useBrandedTranslation"
import { leanInTop } from "../../../../common/utils/animation-utils"
import CustomLink from "../../../shared/CustomLink"
import NavigationTabWrapper from "../../../shared/NavigationTabWrapper"
import SuggestionsContainer from "../../suggestions/containers/SuggestionsContainer"
import SystemMenuContainer from "../../systems/SystemMenu/SystemMenuContainer"
import { CompanyLogo } from "./CompanyLogo"
import { HeaderTop } from "./HeaderTop"

type Props = {
  systemMenuData: Category
  systemCategoriesLinks: React.ReactNode
}

export const Header = ({ systemMenuData, systemCategoriesLinks }: Props) => {
  const { t } = useBrandedTranslation()
  const { locale, asPath } = useRouter()

  const hasSeries = !localesWithoutSeries.includes(locale)
  const { isOpen: isSystemMenuOpen, onOpen: onSystemMenuOpen, onClose: onSystemMenuClose } = useDisclosure()

  return (
    <Box
      as="header"
      boxShadow="lg"
      display={{ base: "none", md: "block" }}
      animation={`${leanInTop} 0.3s ease-out`}
      willChange="top"
    >
      <HeaderTop />
      <Container variant="main" h="25">
        <Flex align="center" justify="space-between">
          <Flex as="nav" gap="8" alignItems="center">
            <CustomLink href="/">
              <Link _hover={{ textDecoration: "none" }}>
                <Center>
                  <CompanyLogo />
                </Center>
              </Link>
            </CustomLink>
            <Flex gap="6">
              <NavigationTabWrapper isSelected={asPath.includes(`/${pages.SYSTEMS}`) || isSystemMenuOpen}>
                <SystemMenuContainer
                  isOpen={isSystemMenuOpen}
                  onOpen={onSystemMenuOpen}
                  onClose={onSystemMenuClose}
                  systemMenuData={systemMenuData}
                />
                {/* Inject all system categories links for SEO indexing purposes */}
                {systemCategoriesLinks}
              </NavigationTabWrapper>
              {/* Hide Series for Australia and India */}
              {hasSeries && (
                <NavigationTabWrapper isSelected={asPath.includes(`/${pages.SERIES}`) && !isSystemMenuOpen}>
                  <CustomLink href={`/${pages.SERIES}`}>
                    <Link variant="navigationBar">{t("nav-series")}</Link>
                  </CustomLink>
                </NavigationTabWrapper>
              )}
              <NavigationTabWrapper isSelected={asPath.includes(`/${pages.NOVELTIES}`) && !isSystemMenuOpen}>
                <CustomLink href={`/${pages.NOVELTIES}`}>
                  <Link variant="navigationBar">{t("nav-newReleases")}</Link>
                </CustomLink>
              </NavigationTabWrapper>
            </Flex>
          </Flex>
          <SuggestionsContainer />
        </Flex>
      </Container>
    </Box>
  )
}
