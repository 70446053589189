import { atom, selector } from "recoil"
import { extractCountryId, getFHLocaleFormat } from "../utils/locale-utils"

export const localeState = atom({
  key: "locale",
  default: "",
})

export const searchServiceLocaleState = selector({
  key: "searchServiceLocale",
  get: ({ get }) => {
    const locale = get(localeState)
    return getFHLocaleFormat(locale)
  },
})
export const countryIdState = selector({
  key: "countryId",
  get: ({ get }) => {
    const locale = get(localeState)
    return extractCountryId(locale)
  },
})
