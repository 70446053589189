import { ChakraProvider, Container, Flex, Hide, Show, ThemeProvider } from "@chakra-ui/react"
import dynamic from "next/dynamic"
import Head from "next/head"
import React, { useMemo } from "react"
import { useRecoilValue } from "recoil"
import { Category } from "../../../api-clients/product-api-v1"
import { useBrandedTranslation } from "../../../common/hooks/useBrandedTranslation"
import { useSetGlobalState } from "../../../common/hooks/useSetGlobalState"
import { gigyaIsEnabledState } from "../../../common/store/authStore"
import { getBrandedTheme } from "../../../theme/chakra-theme"
import styledComponentsTheme from "../../../theme/emotion-theme"
import SystemCategoriesLinks from "../systems/SystemMenu/SystemCategoriesLinks"
import { Footer } from "./Footer"
import { Header } from "./Header/Header"
import { HeaderMobile } from "./Header/HeaderMobile"

type Props = {
  host: string
  locale: string
  brand: string
  systemMenuData: Category
  children?: React.ReactNode
}

// Load Google Tag Manager, Usercentrics cookie solution and Gigya authentication scripts only on the client side
const Gtag = dynamic(() => import("../analytics/gtag"), {
  ssr: false,
})

const CookieSolution = dynamic(() => import("../cookie-solution/CookieSolution"), {
  ssr: false,
})

const LazyGigyaScript = dynamic(() => import("./GigyaScript"), {
  ssr: false,
})

const Layout = ({ locale, brand, systemMenuData, children }: Props) => {
  const gigyaIsEnabled = useRecoilValue(gigyaIsEnabledState)
  const { memoizedT } = useBrandedTranslation()
  const homeTitle = memoizedT("home-title")

  /* Set the global state in Recoil */
  useSetGlobalState(locale)

  const memoizedSystemCategoriesLinks = useMemo(
    () => <SystemCategoriesLinks systemMenuData={systemMenuData} />,
    [systemMenuData]
  )

  return (
    /* Provider for GDDS styles */
    <ThemeProvider theme={styledComponentsTheme}>
      {/* Provider for Chakra UI styles */}
      <ChakraProvider theme={getBrandedTheme(brand)}>
        {/* Insert general head tags shared by all the pages.
        There is a separate <Head /> component inside each page file for overrides. */}
        <Head>
          <link rel="icon" href={`/favicon/${brand}/favicon.ico`} />
          <link rel="apple-touch-icon" sizes="180x180" href={`/favicon/${brand}/apple-touch-icon-180x180.png`} />
          <link rel="icon" type="image/png" sizes="16x16" href={`/favicon/${brand}/favicon-16x16.png`} />
          <link rel="icon" type="image/png" sizes="32x32" href={`/favicon/${brand}/favicon-32x32.png`} />
          <meta name="og:title" content={homeTitle} />
          <meta name="application-name" content={homeTitle} />
        </Head>

        {/* Client-side only scripts */}
        <Gtag />
        <CookieSolution />
        {/* Disable Gigya for locales that don't support it */}
        {gigyaIsEnabled && <LazyGigyaScript />}

        <Flex direction="column" minHeight="100vh">
          {/* Mobile and Tablet header */}
          <Hide above="md">
            <HeaderMobile systemMenuData={systemMenuData} systemCategoriesLinks={memoizedSystemCategoriesLinks} />
          </Hide>
          {/* Desktop header */}
          <Show above="md">
            <Header systemMenuData={systemMenuData} systemCategoriesLinks={memoizedSystemCategoriesLinks} />
          </Show>
          {/* Main page content */}
          <Container variant="main" as="main" pt="4" id="mainContainer" flexGrow={1}>
            {children}
          </Container>
          {/* Footer */}
          <Footer />
        </Flex>
      </ChakraProvider>
    </ThemeProvider>
  )
}

export default Layout
