import { Box, BoxProps } from "@chakra-ui/react"
import sanitizeHtml from "sanitize-html"
import { useBrandedTranslation } from "../../common/hooks/useBrandedTranslation"

const sanitizerOptions = {
  allowedTags: sanitizeHtml.defaults.allowedTags.concat(["del", "ins", "img", "i", "svg", "path"]),
  allowedAttributes: {
    ...sanitizeHtml.defaults.allowedAttributes,
    "*": ["style", "alt", "class"],
    svg: ["viewBox", "focusable"],
    path: ["d", "fill-rule", "fill"],
  },
  allowedSchemesByTag: {
    img: ["data"],
  },
}

type Props = {
  i18nKey: string
  values?: object
  count?: string
  styleProps?: BoxProps
}

// How to use:
// If you face hydration issues, consider dynamic import
// import dynamic from "next/dynamic"
// const RichTextLabel = dynamic(() => import('../src/components/.../RichTextLabel'), {
//   ssr: false,
// })
// To avoid hydration issues on the client side
// https://nextjs.org/docs/advanced-features/dynamic-import

const RichTextLabel = ({ i18nKey, values, count, styleProps }: Props) => {
  const { t } = useBrandedTranslation()

  const label = t(i18nKey, values, count)
  const sanitizedLabel = sanitizeHtml(label, sanitizerOptions)

  const createMarkup = () => {
    return { __html: sanitizedLabel }
  }

  return <Box {...styleProps} dangerouslySetInnerHTML={createMarkup()}></Box>
}

export default RichTextLabel
