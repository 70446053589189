/* tslint:disable */
/* eslint-disable */
/**
 * Price api
 * Use the Price API to obtain article price data for a specific country and price group.<br><br>To successfully retrieve data, you must provide an activated subscription key with the relevant permissions. If you don\'t get the data you expect, [get in touch with us](mailto:developer@geberit.com) so we can adjust your subscription key\'s authorization scope.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export * from "./api"
export * from "./configuration"
