import { ComponentStyleConfig } from "@chakra-ui/react"

const Icon: ComponentStyleConfig = {
  baseStyle: {
    w: "5",
    h: "5",
  },
}

export default Icon
