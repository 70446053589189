import { Button, ButtonProps, Text } from "@chakra-ui/react"
import CustomLink from "./CustomLink"

type Props = ButtonProps & {
  label: string
  href?: string
}

const NavigationButtonLink = ({
  href,
  pt = "2",
  pb = "2",
  pl = "2",
  pr = "2",
  width,
  minH = { base: "11", md: "10" },
  onClick,
  leftIcon,
  rightIcon,
  label,
  _last,
}: Props) => {
  return (
    <CustomLink href={href}>
      <Button
        pt={pt}
        pb={pb}
        pl={pl}
        pr={pr}
        _last={_last}
        as="a"
        onClick={onClick}
        width={width}
        bgColor="transparent"
        h="auto"
        minH={minH}
        mt="0"
        leftIcon={leftIcon}
        rightIcon={rightIcon}
      >
        <Text as="span" whiteSpace="normal" fontSize="sm" fontWeight="medium" w="full" textAlign="left">
          {label}
        </Text>
      </Button>
    </CustomLink>
  )
}

export default NavigationButtonLink
