import { ComponentStyleConfig } from "@chakra-ui/react"

const Radio: ComponentStyleConfig = {
  baseStyle: {
    control: {
      height: "6", // works only when resetting defaultProps
      width: "6", // works only when resetting defaultProps
      border: "1px",
      borderColor: "gray.400",
      _hover: {
        "@media (hover: hover) and (pointer: fine)": {
          borderColor: "black",
          bg: "gray.200",
        },
      },
      _checked: {
        borderColor: "gray.900",
        bg: "white",
        color: "gray.900",
        _hover: {
          borderColor: "gray.900",
          bg: "white",
          color: "gray.900",
        },
      },
      _focusVisible: {
        borderRadius: "full",
        boxShadow: "none",
      },
    },
    label: {
      width: "100%",
      ml: "3",
      fontSize: "sm",
      fontWeight: "medium",
      color: "black",
    },
  },
  defaultProps: {
    // Reset props
    size: null,
    variant: null,
  },
}

export default Radio
