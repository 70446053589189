import * as prismic from "@prismicio/client"
import * as prismicNext from "@prismicio/next"
import sm from "./slicemachine.config.json"

/* The project's Prismic repository name. (what determines environment it will use) */
export const repositoryName = process.env.NEXT_PUBLIC_PRISMIC_ENVIRONMENT || sm.repositoryName

// Update the Link Resolver to match your project's route structure
export function linkResolver(doc) {
  switch (doc.type) {
    case "homepage":
      return "/"
    case "page":
      return `/${doc.uid}`
    default:
      return null
  }
}

/**
 * Creates a Prismic client for the project's repository. The client is used to
 * query content from the Prismic API.
 *
 * @param config - Configuration for the Prismic client.
 */
export const createClient = ({
  previewData,
  req,
}: // ...config

prismicNext.CreateClientConfig = {}) => {
  const client = prismic.createClient(repositoryName, {})

  prismicNext.enableAutoPreviews({ client, previewData, req })

  return client
}
