import { Box, SpaceProps } from "@chakra-ui/react"
import { useBrandedTranslation } from "../../../common/hooks/useBrandedTranslation"
import { ChakraNextIconButtonLink } from "../../shared/ChakraNextIconButtonLink"
import BackButton from "../BackButton"
import { CHome } from "../Icons"

type Props = SpaceProps

const HomepageCrumb = ({ mb }: Props) => {
  const { t } = useBrandedTranslation()
  return (
    <>
      {/* Mobile */}
      <Box display={{ base: "inline-flex", sm: "none" }}>
        <BackButton text={t("home")} href="/" mb="4" />
      </Box>
      {/* Tablet and Desktop */}
      <Box display={{ base: "none", sm: "inline-flex" }}>
        <ChakraNextIconButtonLink
          color="black"
          mb={mb}
          href="/"
          aria-label="Go to homepage"
          icon={<CHome boxSize="4" />}
        />
      </Box>
    </>
  )
}

export default HomepageCrumb
