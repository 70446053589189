import { Box, Flex } from "@chakra-ui/react"
import { Category } from "../../../../api-clients/product-api-v1"
import { categoryLevels, pages, systemMenu } from "../../../../common/constants/constants"
import { useBrandedTranslation } from "../../../../common/hooks/useBrandedTranslation"
import { leanFadeInLeft } from "../../../../common/utils/animation-utils"
import {
  getPictoImage,
  hasProductsInTree,
  hasSparePartsInTree,
  someHavePictos,
} from "../../../../common/utils/category-utils"
import BackButton from "../../../shared/BackButton"
import CustomImage from "../../../shared/CustomImage"
import { CChevronRight } from "../../../shared/Icons"
import NavigationButtonLink from "../../../shared/NavigationButtonLink"
import NavigationMenuButton from "../../../shared/NavigationMenuButton"
import FindSparePartsBtnLink from "./FindSparePartsBtnLink"

type Props = {
  category: Category
  handleBackNavigation: () => void
  handleForwardNavigation: (nextCategoryId: string) => void
  handleClose: () => void
}

const CategoryLinksSection = ({ category, handleBackNavigation, handleForwardNavigation, handleClose }: Props) => {
  const { t } = useBrandedTranslation()
  /* CH1, CH2 and CH3 categories, without MAC */
  const isChapterCategory = !category.id.startsWith(categoryLevels.MASTER)
  /* CH2 and CH3 categories specifically */
  const isLowerChapterCategory = isChapterCategory && !category.id.startsWith(categoryLevels.ONE)

  /* Show pictos only for MAC master chapter categories */
  const shouldShowPicto = !isChapterCategory && someHavePictos(category.subCategories)

  return (
    <Box animation={`${leanFadeInLeft} 0.3s ease-out`}>
      <Flex direction="column" alignItems="flex-start" gap="4">
        {isChapterCategory && <BackButton onClick={handleBackNavigation} text={t("mcat-back")} />}

        <Box borderBottom="1px solid" borderColor="gray.400" maxW="100" mb="2">
          <NavigationButtonLink
            width="100"
            label={category.name}
            href={`/${pages.SYSTEMS}/${category.id}${isLowerChapterCategory ? `/${pages.PRODUCTS}` : ""}`}
            onClick={handleClose}
          />
        </Box>
      </Flex>

      <Flex
        direction="column"
        overflowY="auto"
        w="105"
        overscrollBehavior="contain"
        /* The 382px is the sum of the heights for the Header,
          the category button, the padding and the text above 
          the system buttons and the spare parts button at 
          the bottom */
        maxH={`calc(100vh - ${systemMenu.DESKTOP_OFFSET})`}
      >
        {!!category.subCategories?.length &&
          category.subCategories.map((item: Category) => {
            /* Don't render Level 2 and Level 3 categories which have only spare parts */
            if (isChapterCategory && !hasProductsInTree(item)) return null

            const categoryPicto = getPictoImage(item?.images)
            const hasLargePadding = shouldShowPicto && !categoryPicto?.thumbnail
            const isSubcategoryLeaf = !item.subCategories?.length

            return isSubcategoryLeaf ? (
              /* Render link if it is the last category in the branch */
              <NavigationButtonLink
                key={item.id}
                href={`/${pages.SYSTEMS}/${item.id}/${pages.PRODUCTS}`}
                label={item.name}
                onClick={handleClose}
                minH={shouldShowPicto ? "14" : "10"}
                pl={hasLargePadding ? "14" : "2"}
                width="100"
                leftIcon={
                  shouldShowPicto &&
                  categoryPicto?.thumbnail && (
                    <CustomImage
                      src={categoryPicto?.thumbnail}
                      alt={`${item.name} picto image`}
                      w="10"
                      h="10"
                      fallback={<Box w="10" />}
                      loading="eager"
                    />
                  )
                }
                rightIcon={item.subCategories && <CChevronRight />}
              />
            ) : (
              /* Render navigation button if there are categories below */
              <NavigationMenuButton
                key={item.id}
                label={item.name}
                value={item.id}
                onClick={() => handleForwardNavigation(item.id)}
                minH={shouldShowPicto ? "14" : "10"}
                pl={hasLargePadding ? "14" : "2"}
                width="100"
                leftIcon={
                  shouldShowPicto &&
                  categoryPicto?.thumbnail && (
                    <CustomImage
                      src={categoryPicto?.thumbnail}
                      alt={`${item.name} picto image`}
                      w="10"
                      h="10"
                      fallback={<Box w="10" />}
                    />
                  )
                }
                rightIcon={item.subCategories && <CChevronRight />}
              />
            )
          })}
      </Flex>

      {isLowerChapterCategory && hasSparePartsInTree(category) && (
        <FindSparePartsBtnLink href={`/${pages.SYSTEMS}/${category.id}/${pages.SPARE_PARTS}`} onClick={handleClose} />
      )}
    </Box>
  )
}

export default CategoryLinksSection
