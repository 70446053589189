import { cookieSolutionStorage } from "../constants/browser-storage"
import { UsercentricsServices } from "../types/usercentrics"

export const getServiceId = (serviceName: string): string | null => {
  const serviceBaseInfo = window.UC_UI.getServicesBaseInfo().find((service) => service.name === serviceName)
  return serviceBaseInfo ? serviceBaseInfo.id : null
}

export const handleUcSecondLayerClick = (e) => {
  e.preventDefault()

  const classNames = e.target.className.split(" ")
  if (classNames.length && classNames.includes("uc-show-second-layer")) {
    showUcSecondLayer()
  }
}

export const showUcSecondLayer = () => window.UC_UI.showSecondLayer()

export const extractServicesFromStorage = (): UsercentricsServices => {
  const ucSettingsStorage = localStorage.getItem(cookieSolutionStorage.KEY)

  return ucSettingsStorage
    ? JSON.parse(ucSettingsStorage)?.services?.reduce((servObj, s) => {
        servObj[s.id] = s.status
        return servObj
      }, {})
    : {}
}
