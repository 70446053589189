import { Button, ButtonProps } from "@chakra-ui/react"
import { useBrandedTranslation } from "../../../../common/hooks/useBrandedTranslation"
import CustomLink from "../../../shared/CustomLink"
import { CService } from "../../../shared/Icons"

type Props = ButtonProps & {
  href: string
}

const FindSparePartsBtnLink = ({ href, ...rest }: Props) => {
  const { t } = useBrandedTranslation()

  return (
    <CustomLink href={href}>
      <Button as="a" leftIcon={<CService />} variant="primary" mt={{ base: "6", md: "8" }} {...rest}>
        {t("pdp-findSpareParts")}
      </Button>
    </CustomLink>
  )
}

export default FindSparePartsBtnLink
