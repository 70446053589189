/* tslint:disable */
/* eslint-disable */
/**
 * 3D object models provider
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { AxiosInstance, AxiosPromise, AxiosRequestConfig } from "axios"
import globalAxios from "axios"
import type { Configuration } from "./configuration"
// Some imports not used depending on template conditions
// @ts-ignore
import type { RequestArgs } from "./base"
import {
  createRequestFunction,
  DUMMY_BASE_URL,
  serializeDataIfNeeded,
  setApiKeyToObject,
  setSearchParams,
  toPathString,
} from "./common"
// @ts-ignore
import { BaseAPI, BASE_PATH } from "./base"

/**
 * DTO for generated obj model.
 * @export
 * @interface GeneratedObjModel
 */
export interface GeneratedObjModel {
  /**
   * Gets or sets prefix of URI for obj files.
   * @type {string}
   * @memberof GeneratedObjModel
   */
  prefix?: string | null
  /**
   * Gets or sets article number for generated obj model.
   * @type {string}
   * @memberof GeneratedObjModel
   */
  artNr?: string | null
  /**
   * Gets or sets suffix of URI for obj model file.
   * @type {string}
   * @memberof GeneratedObjModel
   */
  obj?: string | null
  /**
   * Gets or sets list of suffixes of URIs for mtls of obj model.
   * @type {Array<string>}
   * @memberof GeneratedObjModel
   */
  objMtls?: Array<string> | null
}

/**
 * GeneratedObjModelsApi - axios parameter creator
 * @export
 */
export const GeneratedObjModelsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Gets the \"heartbeat\" from the service.
     * @summary Gets the \"heartbeat\" from the service.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApiGeneratedobjmodelsSayhi: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/GeneratedObjModels/sayhi`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Allows to get list of generated obj models for provided list of articles.
     * @summary Allows to get list of generated obj models for provided list of articles.
     * @param {Array<string>} [requestBody] List of articles.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postApiGeneratedobjmodelsList: async (
      requestBody?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/GeneratedObjModels/list`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * GeneratedObjModelsApi - functional programming interface
 * @export
 */
export const GeneratedObjModelsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = GeneratedObjModelsApiAxiosParamCreator(configuration)
  return {
    /**
     * Gets the \"heartbeat\" from the service.
     * @summary Gets the \"heartbeat\" from the service.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getApiGeneratedobjmodelsSayhi(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getApiGeneratedobjmodelsSayhi(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Allows to get list of generated obj models for provided list of articles.
     * @summary Allows to get list of generated obj models for provided list of articles.
     * @param {Array<string>} [requestBody] List of articles.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postApiGeneratedobjmodelsList(
      requestBody?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GeneratedObjModel>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postApiGeneratedobjmodelsList(requestBody, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * GeneratedObjModelsApi - factory interface
 * @export
 */
export const GeneratedObjModelsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = GeneratedObjModelsApiFp(configuration)
  return {
    /**
     * Gets the \"heartbeat\" from the service.
     * @summary Gets the \"heartbeat\" from the service.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApiGeneratedobjmodelsSayhi(options?: AxiosRequestConfig): AxiosPromise<string> {
      return localVarFp.getApiGeneratedobjmodelsSayhi(options).then((request) => request(axios, basePath))
    },
    /**
     * Allows to get list of generated obj models for provided list of articles.
     * @summary Allows to get list of generated obj models for provided list of articles.
     * @param {GeneratedObjModelsApiPostApiGeneratedobjmodelsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postApiGeneratedobjmodelsList(
      requestParameters: GeneratedObjModelsApiPostApiGeneratedobjmodelsListRequest = {},
      options?: AxiosRequestConfig
    ): AxiosPromise<Array<GeneratedObjModel>> {
      return localVarFp
        .postApiGeneratedobjmodelsList(requestParameters.requestBody, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for postApiGeneratedobjmodelsList operation in GeneratedObjModelsApi.
 * @export
 * @interface GeneratedObjModelsApiPostApiGeneratedobjmodelsListRequest
 */
export interface GeneratedObjModelsApiPostApiGeneratedobjmodelsListRequest {
  /**
   * List of articles.
   * @type {Array<string>}
   * @memberof GeneratedObjModelsApiPostApiGeneratedobjmodelsList
   */
  readonly requestBody?: Array<string>
}

/**
 * GeneratedObjModelsApi - object-oriented interface
 * @export
 * @class GeneratedObjModelsApi
 * @extends {BaseAPI}
 */
export class GeneratedObjModelsApi extends BaseAPI {
  /**
   * Gets the \"heartbeat\" from the service.
   * @summary Gets the \"heartbeat\" from the service.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GeneratedObjModelsApi
   */
  public getApiGeneratedobjmodelsSayhi(options?: AxiosRequestConfig) {
    return GeneratedObjModelsApiFp(this.configuration)
      .getApiGeneratedobjmodelsSayhi(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Allows to get list of generated obj models for provided list of articles.
   * @summary Allows to get list of generated obj models for provided list of articles.
   * @param {GeneratedObjModelsApiPostApiGeneratedobjmodelsListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GeneratedObjModelsApi
   */
  public postApiGeneratedobjmodelsList(
    requestParameters: GeneratedObjModelsApiPostApiGeneratedobjmodelsListRequest = {},
    options?: AxiosRequestConfig
  ) {
    return GeneratedObjModelsApiFp(this.configuration)
      .postApiGeneratedobjmodelsList(requestParameters.requestBody, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
