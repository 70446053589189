import { Center } from "@chakra-ui/react"

type Props = {
  children?: React.ReactNode
  isSelected?: boolean
  isDisabled?: boolean
}

const NavigationTabWrapper = ({ children, isSelected = false, isDisabled = false }: Props) => {
  return (
    <Center
      position="relative"
      _before={{
        content: '""',
        position: "absolute",
        display: "block",
        bgColor: `${isSelected ? "brand.500" : "gray.400"}`,
        width: "100%",
        height: "1",
        bottom: "0",
        left: "0",
        transform: `scaleX(${isSelected ? 1 : 0})`,
        transition: "transform 0.2s ease",
      }}
      _hover={
        !isDisabled && {
          textDecoration: "none",
          _before: {
            transform: "scaleX(1)",
          },
        }
      }
      _active={
        !isSelected &&
        !isDisabled && {
          _before: {
            bgColor: "gray.900",
          },
        }
      }
    >
      {children}
    </Center>
  )
}

export default NavigationTabWrapper
