import axios from "axios"
import useSWR, { SWRResponse } from "swr"
import useSWRImmutable from "swr/immutable"
import useSWRMutation from "swr/mutation"
import { Article } from "../../api-clients/availability-api"
import { MyList, PagedResult } from "../../api-clients/mylist-api"
import { ProductModel } from "../../api-clients/product-api-v1"
import { ExcelRequest } from "../../api-clients/services-api-v1"
import { SuggestModel } from "../../api-clients/suggest-openapi"
import { apiRoutes, brands, categoryContentFilter, categoryTreeDepth, priceGroups } from "../constants/constants"
import { searchDefaults } from "../constants/search-constants"
import { ApiService } from "../lib/ApiService"
import { laggy } from "../lib/swr-utils"
import { ContactFromTypes } from "../types/contactFrom"
import { CreateCatalogTypes } from "../types/createCatalog"
import { CategoryContentFilterType, ProductDataRequest, TenderTextsRequestList } from "../types/global"
import { CountriesWithPricesType } from "../types/prices"
import { ProductResponseSizeType } from "../types/product"
import { downloadFile } from "../utils/common-utils"
import { refreshUserList, refreshUserLists } from "../utils/my-list-utils"
import { getScope } from "../utils/suggest-query-url"

// TODO (nan) Split this queries file into separate files by technology
/* Contains all the queries that we do from the frontend side */

/* Default SWR fetcher function */
export const fetcher = (url: string) => axios.get(url).then((res) => res.data)

/* SWR fetcher for POST requests */
export const postRequest = async (url: string, { arg: data }: { arg: MyList }) =>
  axios.post(url, data).then((res) => res.data)

/* SWR fetcher for PUT requests */
export const putRequest = (url: string, { arg: data }: { arg: MyList }) => axios.put(url, data).then((res) => res.data)

/* SWR fetcher for PATCH requests */
export const patchRequest = (url: string, { arg: data }: { arg: MyList }) =>
  axios.patch(url, data).then((res) => res.data)

/* SWR fetcher for DELETE requests */
export const deleteRequest = async (url: string) => axios.delete(url).then((res) => res.data)

/* SWR fetcher for DELETE requests with additional parameter */
export const deleteBulkRequest = async (url: string, { arg: ids }: { arg: string }) =>
  axios.delete(`${url}&ids=${ids}`).then((res) => res.data)

/* Query to get the stored in a cookie last seen items from FH */
export const useGetLastSeen = (locale: string, brand: string, products: string) =>
  useSWR(products ? `${apiRoutes.LATEST}?locale=${locale}&brand=${brand}&products=${products}` : null, fetcher, {
    revalidateOnFocus: false,
  })

/* Query to get the Novelties products for the homepage carousel */
export const useGetNovelties = (locale: string, brand: string) =>
  useSWRImmutable(`${apiRoutes.NOVELTIES}?locale=${locale}&brand=${brand}`, fetcher)

/* Query to get the suggestions */
export const useGetSuggestions = (
  searchTerm: string,
  searchServiceLocale: string,
  brand: string
): SWRResponse<SuggestModel> =>
  useSWRImmutable(`${apiRoutes.SUGGEST}?search=${searchTerm}&scope=${getScope(searchServiceLocale, brand)}`, fetcher, {
    use: [laggy],
  })

export const getAllSystemCategories = (
  locale: string,
  brand: string,
  contentFilter: CategoryContentFilterType = categoryContentFilter.ALL
) => {
  const { categoryApi: api } = ApiService()

  return api.findCategoriesByBrand({
    locale,
    brand,
    rootId: searchDefaults.SYSTEM_CTP,
    contentFilter: contentFilter,
  })
}

export const getSystemCategory = (
  locale: string,
  brand: string,
  systemId: string,
  depth: number = categoryTreeDepth.FOUR,
  contentFilter: CategoryContentFilterType = categoryContentFilter.ALL
) => {
  const { categoryApi: api } = ApiService()

  return api.findCategoriesByBrand({
    locale,
    brand,
    rootId: systemId,
    depth,
    contentFilter,
  })
}

export const getAllSeriesCategories = (locale: string, brand: string, depth: number) => {
  const { categoryApi: api } = ApiService()

  return api.findCategoriesByBrand({
    locale,
    brand,
    // Get the series root id by brand from constants instead of Prismic for performance
    rootId: brands[brand]?.seriesRootCategory,
    depth: depth,
  })
}

export const getSeries = (locale: string, brand: string, seriesId: string, depth: number = categoryTreeDepth.ZERO) => {
  const { categoryApi: api } = ApiService()

  return api.findCategoriesByBrand({
    locale,
    brand,
    rootId: seriesId,
    depth,
  })
}

export const sendEmail = async (body: ContactFromTypes) => {
  const res = await axios.post(apiRoutes.SUPPORT_EMAIL, body)
  return res.data
}

export const getProductDownloadZipUrl = async (
  locale: string,
  brand: string,
  product: string,
  options: string,
  name: string
) => {
  const res = await axios.get(
    `${apiRoutes.SERVICES}/download-zip/product/${product}?locale=${locale}&brand=${brand}&options=${options}&name=${name}`
  )
  return res.data
}

export const getCatalogDownloadPdfUrl = async (
  locale: string,
  brand: string,
  listId: string,
  body: CreateCatalogTypes
) => {
  const res = await axios.post(`${apiRoutes.SERVICES}/catalog-pdf/${listId}?locale=${locale}&brand=${brand}`, body)
  return res.data
}

export const getArticleDownloadZipUrl = async (
  locale: string,
  brand: string,
  articleCode: string,
  options: string,
  name: string
) => {
  const res = await axios.get(
    `${apiRoutes.SERVICES}/download-zip/article/${articleCode}?locale=${locale}&brand=${brand}&options=${options}&name=${name}`
  )
  return res.data
}

export const getProductPdfData = async (locale: string, brand: string, product: string) => {
  const res = await axios.get(
    `${apiRoutes.SERVICES}/download-pdp-pdf/product/${product}?locale=${locale}&brand=${brand}`,
    {
      responseType: "blob",
    }
  )
  return res.data
}

export const getArticlePdfData = async (locale: string, brand: string, article: string) => {
  const res = await axios.get(
    `${apiRoutes.SERVICES}/download-pdp-pdf/article/${article}?locale=${locale}&brand=${brand}`,
    {
      responseType: "blob",
    }
  )
  return res.data
}

export const getSpdpPdfData = async (locale: string, brand: string, product: string) => {
  const res = await axios.get(`${apiRoutes.SERVICES}/download-spdp-pdf/${product}?locale=${locale}&brand=${brand}`, {
    responseType: "blob",
  })
  return res.data
}

export const getDownloadMyListXlsxUrl = async (locale: string, brand: string, body: ExcelRequest) => {
  const res = await axios.post(`${apiRoutes.SERVICES}/download-list-xlsx?locale=${locale}&brand=${brand}`, body, {
    responseType: "blob",
  })

  return res.data
}

export const getDownloadMyListTenderTextsUrl = async (locale: string, brand: string, body: TenderTextsRequestList) => {
  const res = await axios.post(
    `${apiRoutes.SERVICES}/download-list-tender-texts?locale=${locale}&brand=${brand}`,
    body,
    {
      responseType: "blob",
    }
  )

  return res.data
}

export const downloadMyListProductData = async (locale: string, brand: string, body: ProductDataRequest) => {
  const queryParams = `locale=${locale}&brand=${brand}`
  const res = await axios.post(`${apiRoutes.SERVICES}/download-list-zip?${queryParams}`, body)

  return res.data
}

export class getDownloadFile {
  public getSelectedDownloadFile = (file: string | Blob, fileName: string, fileType?: string): Promise<void> => {
    /* If we have a download url, fetch the data from it */
    if (typeof file === "string") {
      return axios({
        url: file, //your url
        method: "GET",
        responseType: "blob", // important
      }).then((response) => downloadFile(response.data, fileName, fileType))
    } else {
      /* If we already have the file data as blob, just download it */
      downloadFile(file, fileName, fileType)
    }

    return
  }
}

export const useModelViewer3d = (articleCodesList: string) => {
  const { data, error } = useSWRImmutable(`${apiRoutes.MODELS}?articles=${articleCodesList}`, fetcher)

  return { isLoading: !data && !error, error, data }
}

export const getProductByArticleNumber = async (locale: string, brand: string, articleNumber: string) => {
  const { articleApi: api } = ApiService()

  return api.getProductByArticleBrand({
    locale,
    brand,
    sku: articleNumber,
  })
}

export const getProduct = async (locale: string, brand: string, productId: string) => {
  const { productApi: api } = ApiService()

  return api.getProductByKeyAndBrand({
    locale,
    brand,
    key: productId,
  })
}

export const fetchProductByArticleNumber = async (
  locale: string,
  brand: string,
  articleNumber: string,
  responseSize?: ProductResponseSizeType
) => {
  const res = await axios.get<ProductModel>(
    `${apiRoutes.PRODUCT}/article/${articleNumber}?locale=${locale}&brand=${brand}${
      responseSize ? `&responseSize=${responseSize}` : ""
    }`
  )
  return res.data
}

export const fetchProduct = async (
  locale: string,
  brand: string,
  productId: string,
  responseSize?: ProductResponseSizeType
) => {
  const res = await axios.get<ProductModel>(
    `${apiRoutes.PRODUCT}/${productId}?locale=${locale}&brand=${brand}${
      responseSize ? `&responseSize=${responseSize}` : ""
    }`
  )
  return res.data
}

export const getPriceData = async (country: CountriesWithPricesType, articleNumbers: string[]) => {
  const { priceApi: api } = ApiService()

  return api.getPrices({
    country,
    articleNumbers,
    priceGroup: priceGroups.GROUP1,
  })
}

export const getSparePart = async (locale: string, brand: string, sparePartId: string) => {
  const { sparePartsApi: api } = ApiService()

  return api.getSparePartsByKeyAndBrand({
    locale,
    brand,
    key: sparePartId,
  })
}

/* Query to get the tender text from Product API */
export const useGetTenderText = (locale: string, tenderTextId: string) =>
  useSWRImmutable(`${apiRoutes.TENDER_TEXT}/${tenderTextId}?locale=${locale}`, fetcher)

export const getRemoteConfig = async () => {
  const res = await axios.get(apiRoutes.REMOTE_CONFIG)
  return res.data
}

/* Mutation SWR hook to edit a list with MyList API */
export const useUpdateList = (locale: string, brand: string, listId: string, userId = "") =>
  useSWRMutation(`${apiRoutes.LISTS}/${listId}?locale=${locale}&brand=${brand}&userId=${userId}`, putRequest)

/* Mutation SWR hook to edit a list with MyList API from list of lists page */
export const usePatchUpdateList = (locale: string, brand: string, listId: string, userId = "") =>
  useSWRMutation(`${apiRoutes.LISTS}/${listId}?locale=${locale}&brand=${brand}&userId=${userId}`, patchRequest)

export const fetchUpdateList = async (locale: string, brand: string, listId: string, list, userId = "") => {
  const res = await axios.patch(`${apiRoutes.LISTS}/${listId}?locale=${locale}&brand=${brand}&userId=${userId}`, list)

  refreshUserLists(res.data, locale, brand, userId)
  refreshUserList(res.data, locale, brand, userId)

  return res.data
}

export const fetchList = async (locale: string, brand: string, listId: string, userId = "") => {
  const listData = await axios.get(`${apiRoutes.LISTS}/${listId}?locale=${locale}&brand=${brand}&userId=${userId}`)
  return listData.data
}

/* Mutation SWR hook to delete a list with MyList API */
export const useDeleteList = (locale: string, brand: string, listId: string, userId = "") =>
  useSWRMutation(`${apiRoutes.LISTS}/${listId}?locale=${locale}&brand=${brand}&userId=${userId}`, deleteRequest)

/* Query to get a list from MyList API, used for populating the SWR client side cache, supports live cache update */
export const useGetList = (
  locale: string,
  brand: string,
  listId: string,
  isFetch = true,
  userId = "",
  initialListData: MyList = null
): SWRResponse<MyList, unknown> =>
  useSWR(isFetch ? `${apiRoutes.LISTS}/${listId}?locale=${locale}&brand=${brand}&userId=${userId}` : null, fetcher, {
    fallbackData: initialListData,
    keepPreviousData: true,
  })

/* Query to get all lists for a user from MyList API, used for populating the SWR client side cache, supports live cache update */
export const useGetUserLists = (
  locale: string,
  brand: string,
  isFetch = true,
  userId: string,
  initialUserListsData: PagedResult = null
): SWRResponse<PagedResult, unknown> =>
  useSWR(isFetch ? `${apiRoutes.LISTS}?locale=${locale}&brand=${brand}&userId=${userId}` : null, fetcher, {
    fallbackData: initialUserListsData,
    keepPreviousData: true,
    revalidateOnFocus: false,
  })

/* Mutation SWR hook to create a list from MyList API */
export const useCreateList = (locale: string, brand: string, userId = "") =>
  useSWRMutation(`${apiRoutes.LISTS}?locale=${locale}&brand=${brand}&userId=${userId}`, postRequest)

/* Mutation SWR hook to batch delete user lists with MyList API. Lists are passed as comma separated string of list ids */
export const useDeleteUserLists = (locale: string, brand: string, userId: string) =>
  useSWRMutation(`${apiRoutes.LISTS}?locale=${locale}&brand=${brand}&userId=${userId}`, deleteBulkRequest)

/* Used to get the list by id server-to-server */
export const getMyList = async (locale: string, brand: string, listId: string, userId = "") => {
  const { myListApi: api } = ApiService()

  return api.getMyList({
    locale,
    brand,
    id: listId,
    xUserId: userId,
  })
}

/* Used to get the user lists server-to-server */
export const getMyLists = async (locale: string, brand: string, userId = "") => {
  const { myListApi: api } = ApiService()

  const OFFSET = 0
  const LIMIT = 100 // Max number of lists supported at the moment

  return api.getMyLists({
    locale,
    brand,
    xUserId: userId,
    offset: OFFSET,
    limit: LIMIT,
  })
}

export const getArticleAvailabilityForBrandAndLocale = async (locale: string, brand: string, articleNumber: string) => {
  const res = await axios.get<Article>(
    `${apiRoutes.AVAILABILITY}/article/${articleNumber}?locale=${locale}&brand=${brand}`
  )
  return res.data
}

export const getBimProductDownloadUrl = async (productId: string, locale: string) => {
  try {
    const res = await fetcher(`${apiRoutes.BIM}?locale=${locale}&productId=${productId}`)
    return res
  } catch (err) {
    return null
  }
}

export const checkResourceExistence = async (url: string) => {
  try {
    const res = await axios.get(`${apiRoutes.CHECK_RESOURCE}?url=${url}`)

    return res ? true : false
  } catch (err) {
    if (err.response.status !== 404) {
      console.log("ERROR CHECKING RESOURCE EXISTENCE STATUS: ", err)
    }
    return false
  }
}

export const getProductsAvailabilityForBrandOrLocale = async (
  brand: string,
  fields: string,
  offset: number,
  limit: number
) => {
  const { productAvailabilityApi: api } = ApiService()

  return api.getProductsAvailabilityForBrandOrLocale({
    assortmentOrBrand: brand,
    fields,
    offset,
    limit,
  })
}

export const getSparePartsAvailabilityForBrandOrLocale = async (brand: string, offset: number, limit: number) => {
  const { sparePartsAvailabilityApi: api } = ApiService()

  return api.getSparePartsAvailabilityWithBrandOrLocale({
    assortmentOrBrand: brand,
    offset,
    limit,
  })
}
