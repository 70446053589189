import { useRouter } from "next/router"
import React, { useEffect, useState } from "react"
import { useRecoilValue } from "recoil"
import { pages } from "../../../../common/constants/constants"
import { searchField, suggestDefaults } from "../../../../common/constants/suggest-constants"
import { useGetSuggestions } from "../../../../common/hooks/queries"
import { useBrandedTranslation } from "../../../../common/hooks/useBrandedTranslation"
import { onboardingSearchTermState, onboardingShouldOpenSearchState } from "../../../../common/store/onboardingStore"
import { searchServiceLocaleState } from "../../../../common/store/settingsStore"
import { redirectToPage } from "../../../../common/utils/router-utils"
import { getModifiedQueryParam } from "../../../../common/utils/search-term-utils"
import SearchField from "../SearchField/SearchField"

type Props = {
  onToggleSearchField?: () => void
}

const SuggestionsContainer = ({ onToggleSearchField }: Props) => {
  const { t } = useBrandedTranslation()
  const router = useRouter()
  const { query } = router
  const [isFocused, setIsFocused] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")

  const searchServiceLocale = useRecoilValue(searchServiceLocaleState)
  const brand = query?.brand as string

  // Read the store values for the userOnboarding values
  const shouldOpenSearch = useRecoilValue(onboardingShouldOpenSearchState)
  const onboardingSearchTerm = useRecoilValue(onboardingSearchTermState)

  // Update local state when the userOnboarding state changes
  useEffect(() => {
    setIsFocused(shouldOpenSearch)
    setSearchTerm(onboardingSearchTerm)
  }, [shouldOpenSearch])

  /* If the passed search term is 0 or 1 symbols, send the default search term to get the default suggetions */
  const searchTermToSend =
    searchTerm?.length >= suggestDefaults.MIN_TERM_LENGTH ? searchTerm : suggestDefaults.DEFAULT_SEARCH_TERM

  const { data: suggestionsData } = useGetSuggestions(searchTermToSend, searchServiceLocale, brand)
  const suggestionGroups = suggestionsData?.suggestionGroups ?? []

  const handleFocusField = () => {
    setIsFocused(true)
  }

  const handleBlurField: React.FocusEventHandler<HTMLInputElement> = (e: React.FocusEvent<HTMLInputElement>) => {
    //Reset the search field placeholder
    e.target.placeholder = t(searchField.PLACEHOLDER)
    setIsFocused(false)

    if (onToggleSearchField) onToggleSearchField()
  }

  const handleSelectSuggestion = (suggestion: string) => {
    resetSearchField()
    redirectToPage(router, pages.SEARCH, null, null, getModifiedQueryParam(suggestion))

    if (onToggleSearchField) onToggleSearchField()
  }

  const handleUpdateSuggestions = (val: string) => {
    setSearchTerm(val)
  }

  const resetSearchField = () => {
    setIsFocused(false)
    setSearchTerm("")
  }

  return (
    <SearchField
      suggestionGroups={suggestionGroups}
      handleUpdateSuggestions={handleUpdateSuggestions}
      handleSelectSuggestion={handleSelectSuggestion}
      handleFocusField={handleFocusField}
      handleBlurField={handleBlurField}
      resetSearchField={resetSearchField}
      isFocused={isFocused}
    />
  )
}

export default SuggestionsContainer
