import { Button, ButtonProps, Text, useDimensions } from "@chakra-ui/react"
import { useRef } from "react"
import CustomLink from "./CustomLink"

type Props = ButtonProps & { href?: string; label?: string; target?: "_blank" | "_self" }

export function ChakraNextLinkButton({
  href,
  px,
  width,
  height,
  target = "_self",
  leftIcon,
  rightIcon,
  iconSpacing,
  mb,
  mt,
  pl,
  border,
  color = "gray.900",
  onClick,
  label,
  noOfLines,
  variant = "secondary",
  "aria-label": ariaLabel,
}: Props) {
  const DEFAULT_BUTTON_HEIGHT = 40 // Default height of button for the secondary variant used here
  const textRef = useRef(null)
  const textDimensions = useDimensions(textRef, true)
  const isMultiline = textDimensions?.borderBox?.height > DEFAULT_BUTTON_HEIGHT / 2 // Should be more than height of text span when only on one line

  return (
    <CustomLink href={href}>
      <Button
        as="a"
        variant={variant}
        px={px}
        width={width}
        height={height}
        target={target}
        leftIcon={leftIcon}
        rightIcon={rightIcon}
        iconSpacing={iconSpacing}
        mb={mb}
        mt={mt}
        pl={pl}
        border={border}
        color={color}
        onClick={onClick}
        aria-label={ariaLabel}
        textAlign="left"
        py="1"
        sx={{ "& > span": { alignSelf: `${isMultiline ? "start" : "center"}` } }}
      >
        <Text as="span" noOfLines={noOfLines} ref={textRef}>
          {label}
        </Text>
      </Button>
    </CustomLink>
  )
}
