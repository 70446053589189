import { Button, Menu, MenuButton, MenuList, Text } from "@chakra-ui/react"
import { useBrandedTranslation } from "../../../../common/hooks/useBrandedTranslation"
import { useAuth } from "../../../../common/hooks/useGigya"
import useModal from "../../../../common/hooks/useModal"
import useUserLists from "../../../../common/hooks/useUserLists"
import { CLists } from "../../../shared/Icons"
import InputModal from "../../my-account/MyList/MyListDetails/InputModal/InputModal"
import MyListsLoggedIn from "./MyListsLoggedIn"
import MyListsLoggedOut from "./MyListsLoggedOut"

const MyListsButton = () => {
  const { t } = useBrandedTranslation()
  const { accountInfo } = useAuth()
  const isUserLoggedIn = !!accountInfo?.userId

  /* Custom hook to get the user lists data */
  const { createList } = useUserLists()

  const {
    Modal: CreateList,
    isOpen: isCreateListModalOpen,
    onOpen: onOpenCreateListModal,
    onClose: onCloseCreateListModal,
  } = useModal()

  const handleCreateCustomList = (name: string) => {
    createList({ name, isDefault: true })
  }

  return (
    <>
      <Menu
        gutter={0}
        autoSelect={false}
        closeOnSelect={false}
        flip={false}
        placement="bottom-end"
        isLazy // Render the menu items onOpen of filter for faster SRP initial load time
      >
        {({ isOpen, onClose }) => (
          <>
            <MenuButton
              as={Button}
              leftIcon={<CLists />}
              textAlign="left"
              iconSpacing="0"
              px="2"
              variant="dropdown"
              color="gray.900"
              aria-label="Open My Lists"
            >
              <Text as="span" fontSize="xs" fontWeight="normal" ml="2" mt="1">
                {t("header-logged-myList")}
              </Text>
            </MenuButton>
            <MenuList w="86" py="2" boxShadow="xl">
              {isUserLoggedIn ? (
                <MyListsLoggedIn isOpen={isOpen} onClose={onClose} onOpenCreateListModal={onOpenCreateListModal} />
              ) : (
                <MyListsLoggedOut isOpen={isOpen} onClose={onClose} />
              )}
            </MenuList>
          </>
        )}
      </Menu>

      <InputModal
        isOpen={isCreateListModalOpen}
        Modal={CreateList}
        title={t("acc-myLists-add")}
        initialValue={t("wishlist-default-name")}
        onClose={onCloseCreateListModal}
        handleConfirm={handleCreateCustomList}
      />
    </>
  )
}

export default MyListsButton
