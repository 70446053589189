import { keyframes } from "@chakra-ui/react"
import { CLoading } from "../Icons"

const rotationAnimation = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`

const LoadingIcon = () => {
  return <CLoading animation={`${rotationAnimation} infinite 500ms`} />
}

export default LoadingIcon
