// Kudos: https://github.com/chakra-ui/chakra-ui/issues/3144
const Tabs = {
  variants: {
    systemMenu: {
      tab: {
        justifyContent: "space-between",
        borderLeft: "none",
        fontSize: "sm",
        fontWeight: "medium",
        mx: "2",
        px: "2",
        py: "1",
        h: "10",
        border: "1px solid transparent",
        _hover: { "@media (hover: hover) and (pointer: fine)": { bgColor: "gray.200" } },
        _active: {
          bgColor: "gray.300",
        },
        _selected: {
          color: "brand.500",
          borderBottom: "1px solid var(--chakra-colors-brand-500)",
        },
      },
    },
  },
}

export default Tabs
