import { Box, IconButton } from "@chakra-ui/react"
import dynamic from "next/dynamic"
import { useRecoilState, useSetRecoilState } from "recoil"
import { useBrandedTranslation } from "../../../common/hooks/useBrandedTranslation"
import useModal from "../../../common/hooks/useModal"
import {
  onboardingIsRunningState,
  onboardingShouldRunState,
  onboardingStepIndexState,
} from "../../../common/store/onboardingStore"
import CustomTooltip from "../../shared/CustomTooltip"
import { CHelp } from "../../shared/Icons"
import EndOnboardingModal from "./EndOnboardingModal"
import OnboardingModal from "./OnboardingModal"
const UserOnboarding = dynamic(() => import("./UserOnboarding"), { ssr: false })

const UserOnboardingWrapper = () => {
  const { t } = useBrandedTranslation()

  const setOnboardingIsRunning = useSetRecoilState(onboardingIsRunningState)
  const [onboardingShouldRun, setOnboardingShouldRun] = useRecoilState(onboardingShouldRunState)
  const setOnboardingStepIndex = useSetRecoilState(onboardingStepIndexState)
  const { Modal: Onboarding, onOpen: onOpenOnboardingModal, onClose: onCloseOnboardingModal } = useModal()
  const { Modal: EndOnboarding, onOpen: onOpenEndOnboardingModal, onClose: onCloseEndOnboardingModal } = useModal()

  const goBack = () => {
    setOnboardingIsRunning(true)
    setOnboardingShouldRun(true)
    onCloseEndOnboardingModal()
  }

  const endTutorial = () => {
    setOnboardingStepIndex(0)
    setOnboardingShouldRun(false)
    setOnboardingIsRunning(false)
    onCloseEndOnboardingModal()
  }

  return (
    <Box>
      <CustomTooltip label={t("help-menu-tutorial")}>
        <IconButton
          className="tutorial-button" // GTM Event: Tutorial button click
          aria-label="open help menu"
          icon={<CHelp className="tutorial-button" />}
          bg="transparent"
          color="gray.900"
          onClick={onOpenOnboardingModal}
        />
      </CustomTooltip>

      {onboardingShouldRun && <UserOnboarding onOpenEndOnboardingModal={onOpenEndOnboardingModal} />}

      <OnboardingModal
        Modal={Onboarding}
        onClose={onCloseOnboardingModal}
        handleConfirm={() => {
          setOnboardingIsRunning(true)
          setOnboardingShouldRun(true)
        }}
      />

      <EndOnboardingModal Modal={EndOnboarding} onClose={endTutorial} handleConfirm={goBack} />
    </Box>
  )
}

export default UserOnboardingWrapper
