import { Box, Icon, Input, InputGroup, InputRightElement } from "@chakra-ui/react"
import { debounce } from "lodash"
import dynamic from "next/dynamic"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useRecoilValue } from "recoil"
import { SuggestionGroups } from "../../../../api-clients/suggest-openapi"
import { keys } from "../../../../common/constants/constants"
import { searchField, suggestDefaults } from "../../../../common/constants/suggest-constants"
import { useBrandedTranslation } from "../../../../common/hooks/useBrandedTranslation"
import { onboardingSearchTermState, onboardingShouldOpenSearchState } from "../../../../common/store/onboardingStore"
import { CClose, CSearch } from "../../../shared/Icons"

const SuggestionsMenu = dynamic(() => import("../SuggestionsMenu"), {
  ssr: false,
})

type Props = {
  suggestionGroups: Array<SuggestionGroups>
  handleUpdateSuggestions: (val: string) => void
  handleSelectSuggestion: (suggestion: string) => void
  handleFocusField: () => void
  handleBlurField: React.FocusEventHandler<HTMLInputElement>
  resetSearchField: () => void
  isFocused: boolean
}

const SearchField = ({
  suggestionGroups,
  handleUpdateSuggestions,
  handleSelectSuggestion,
  handleFocusField,
  handleBlurField,
  resetSearchField,
  isFocused,
}: Props) => {
  const { t } = useBrandedTranslation()
  const [value, setValue] = useState("")
  const searchFieldRef = useRef(null)

  const { PLACEHOLDER, ID, FORM_ID } = searchField

  // Read the store values for the userOnboarding values
  const shouldOpenSearch = useRecoilValue(onboardingShouldOpenSearchState)
  const onboardingSearchTerm = useRecoilValue(onboardingSearchTermState)

  // Update local state when the userOnboarding state changes
  useEffect(() => {
    setValue(onboardingSearchTerm)
  }, [shouldOpenSearch])

  useEffect(() => {
    const searchFieldEl = document.getElementById(ID)
    // Attach keydown event listener only to the main search field
    searchFieldEl?.addEventListener("keydown", onSearchFieldKeydown)
    return () => searchFieldEl?.removeEventListener("keydown", onSearchFieldKeydown)
  })

  /* Leave the search field on pressing the escape key by requirement
    Submit the form on pressing the enter key (fixes issue on mobile not submitting) */
  const onSearchFieldKeydown = (e: KeyboardEvent) => {
    // If ENTER, prevent the default behaviour and fire the form submit event manually
    if (e.key === keys.ENTER) {
      e.preventDefault()
      const target = e.target as HTMLInputElement
      // Don't allow empty value to be submitted for the search field
      if (!target.value.trim()) {
        target.placeholder = t("header-searchPlaceholder-validation")
        handleUpdateValue("")
        return
      }
      target.form.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }))
    }

    // If ESC, fire the search field blur event
    if (e.key === keys.ESCAPE) {
      e.preventDefault()
      searchFieldRef?.current?.blur()
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateSuggestionsFunc = useCallback(
    debounce(handleUpdateSuggestions, suggestDefaults.SUGGEST_QUERY_TIMEOUT),
    []
  )

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Reset the search field placeholder
    e.target.placeholder = t(PLACEHOLDER)
    const val = e.target.value
    setValue(val)
    debouncedUpdateSuggestionsFunc(val)
  }

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (
    e: React.FormEvent<HTMLFormElement>,
    suggestion?: string
  ) => {
    e.preventDefault()
    // This will be uncommented once the suggestions are adapted and the POs decide to bring it back
    // if (checkForExactMatch(suggestionGroups, currentLocale, value)) {
    //   return
    // }
    handleSelectSuggestion(suggestion ?? value)
    handleResetSearchField()
  }

  const handleUpdateValue = (val: string) => {
    setValue(val)
    handleUpdateSuggestions(val)
    searchFieldRef.current.focus()
  }

  const handleSearchIcon = () => {
    handleFocusField()
    searchFieldRef.current.focus()
  }

  const preventBlur: React.MouseEventHandler<SVGElement> = (e: React.MouseEvent<SVGElement>) => {
    e.preventDefault()
  }

  const handleResetSearchField = () => {
    resetSearchField()
    setValue("")
    searchFieldRef.current.blur()
  }

  return (
    <Box as="section" position="relative">
      <form onSubmit={handleSubmit} name={FORM_ID} id={FORM_ID} autoComplete="off" spellCheck="false" noValidate>
        <Box width={{ base: "100%", md: "53ch" }}>
          <InputGroup
            _hover={!isFocused && { bg: "gray.50", boxShadow: "0px 1px 0px 0px" }}
            boxShadow={isFocused && "0px 1px 0px 0px"}
          >
            <InputRightElement
              // eslint-disable-next-line react/no-children-prop
              children={
                <Icon
                  as={value ? CClose : CSearch}
                  w="5"
                  h="5"
                  mt="2.5"
                  color={value ? "black" : "gray.600"}
                  onMouseDown={preventBlur}
                  onClick={value ? () => handleUpdateValue("") : handleSearchIcon}
                  cursor="pointer"
                />
              }
            />
            <Input
              pl="2"
              h="12"
              fontSize="md"
              variant="flushed"
              placeholder={t(PLACEHOLDER)}
              _focusVisible={{}}
              name={ID}
              id={ID}
              form={FORM_ID}
              onChange={handleChange}
              onFocus={handleFocusField}
              onBlur={handleBlurField}
              value={value}
              ref={searchFieldRef}
            />
          </InputGroup>
        </Box>
      </form>
      <SuggestionsMenu
        suggestionGroups={suggestionGroups}
        handleSubmit={handleSubmit}
        handleUpdateValue={handleUpdateValue}
        handleResetSearchField={handleResetSearchField}
        preventBlur={preventBlur}
        searchTerm={value}
        isFocused={isFocused}
      />
    </Box>
  )
}

export default SearchField
