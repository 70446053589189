export const keys = {
  ENTER: "Enter",
  ESCAPE: "Escape",
  LEFT_ARROW: "Left arrow",
  RIGHT_ARROW: "Right arrow",
} as const

export const pdpQueryParams = {
  ARTICLES: "art",
  //we don't use 'q' here as it will cause a fetch to FH on the pdp
  //which is not needed
  SEARCH_TERM: "searchTerm",
  // Comma separated list of accessories associated to an article
  ACCESSORIES: "accessories",
} as const

export const searchNavigationParams = {
  SEARCH_TERM: "q",
  CHARACTERISTICS: "characteristics",
  APPLICATION_PURPOSES: "application_purposes",
  ALL_SPARE_PARTS: "spare_parts_all",
  CROSS: "cross",
  NEW: "is_new",
  SERIES: "series",
  SYSTEMS: "system",
  MIX_AND_MATCH: "mix_and_match",
} as const

export const FH_URL_PARAMS = "urlParams"

export const truncateTextInMiddleConstants = {
  DEFAULT_SEPARATOR: "...",
  DEFAULT_LENGTH: 100,
  FILTER_BOX_SELECT_SM: 50,
  FILTER_BOX_SELECT_MD: 75,
  FILTER_BOX_SELECT_DESKTOP: 80, // 768px plus
} as const

export const encodedSymbols = {
  LEFT_CURLY_BRACKET: "%7b",
  RIGHT_CURLY_BRACKET: "%7d",
  SLASH: "%2f",
  GREATER_THAN: "%3e",
  LESS_THAN: "%3c",
} as const

export const pages = {
  PRODUCTS: "products",
  PRODUCT: "product",
  SPARE_PART: "spare-part",
  SPARE_PARTS: "spare-parts",
  NOT_FOUND_PAGE: "404",
  HOME_PAGE: "home",
  SEARCH: "products/search",
  SERIES: "series",
  SYSTEMS: "systems",
  NOVELTIES: "products/novelties",
  USER: "user",
  LISTS: "user/lists",
  LIST: "list",
  SETTINGS: "user/settings",
  GEBERIT_ID: "user/profile",
  CREATE_CATALOG: "user/catalog",
  CREATE_SERIES_CATALOG: "series/catalog",
} as const

export const urlPatterns = {
  PRODUCTS_DIRECTORY: "/products",
  SPARE_PARTS_DIRECTORY: "/spare-parts",
} as const

export const fhAnalyticsUserActions = {
  PAGEVIEW: "pageview",
  PARTIALS_PAGEVIEW: "partialspageview",
  FACETCLICK: "facetclick",
  PROCLICK: "proclick",
  SPTCLICK: "sptclick",
  ARTCLICK: "artclick",
  SUGG_PROCLICK: "suggestionsproclick",
  SUGG_SPTCLICK: "suggestionssptclick",
  SUGG_ARTCLICK: "suggestionsarticlick",
} as const

export const fhAnalyticsActions = {
  VIEW: "view",
  CLICK: "click",
} as const

export const FH_ANALYTICS_DEFAULT_KEY = "defaultKey"

export const FH_EMPTY_VALUE = "empty_value"

export const DEFAULT_BRAND_VALUE = "geberit"
export const DEFAULT_BRAND_ROOT_CATEGORY = "BRC_378836" as const

export const PDP_REACT_PORTAL = "pdp-react-portal" as const
export const SEARCH_FIELD_CONTAINER_ID = "site-search-field" as const
export const SEARCH_RESULTS_CONTAINER_ID = "site-search-results" as const
export const USER_ONBOARDING_CONTAINER_ID = "site-user-onboarding" as const
export const PAGE_NOT_FOUND_PORTAL = "page-not-found-react-portal" as const

export const brands = {
  geberit: {
    name: "Geberit",
    value: "geberit",
    seriesRootCategory: "BRC_378836",
    mainTitleFontWeight: "bold",
    subTitleFontWeight: "light",
    userOnboarding: {
      "en-AU": {
        searchTerm: "cister",
        placeholderArticle: "367.045.16.1",
      },
      "en-US": {
        searchTerm: "Sigm",
        placeholderArticle: "115.788.11.2",
      },
      "ru-KZ": {
        searchTerm: "Раковины",
        placeholderArticle: "367.045.16.1",
      },
      defaultValues: {
        searchTerm: "monolith",
        placeholderArticle: "367.045.16.1",
      },
    },
  },
  ido: {
    name: "IDO",
    value: "ido",
    seriesRootCategory: "BRC_482714",
    mainTitleFontWeight: "light",
    subTitleFontWeight: "light",
    userOnboarding: {
      defaultValues: {
        searchTerm: "seven",
        placeholderArticle: "501.992.00.2",
      },
    },
  },
  porsgrund: {
    name: "Porsgrund",
    value: "porsgrund",
    seriesRootCategory: "BRC_547797",
    mainTitleFontWeight: "light",
    subTitleFontWeight: "light",
    userOnboarding: {
      defaultValues: {
        searchTerm: "glow",
        placeholderArticle: "7821501301",
      },
    },
  },
  ifo: {
    name: "Ifö",
    value: "ifo",
    seriesRootCategory: "BRC_482710",
    mainTitleFontWeight: "light",
    subTitleFontWeight: "light",
    userOnboarding: {
      defaultValues: {
        searchTerm: "spira",
        placeholderArticle: "501.992.00.1",
      },
    },
  },
  kolo: {
    name: "Koło",
    value: "kolo",
    seriesRootCategory: "BRC_452296",
    mainTitleFontWeight: "bold",
    subTitleFontWeight: "bold",
    userOnboarding: {
      defaultValues: {
        searchTerm: "rekor",
        placeholderArticle: "5360000",
      },
    },
  },
  twyford: {
    name: "Twyford",
    value: "twyford",
    seriesRootCategory: "BRC_452466",
    mainTitleFontWeight: "light",
    subTitleFontWeight: "bold",
    userOnboarding: {
      defaultValues: {
        searchTerm: "alcon",
        placeholderArticle: "AR1798WH",
      },
    },
  },
  main: {
    name: "Geberit",
    value: "geberit",
    seriesRootCategory: "BRC_378836",
    mainTitleFontWeight: "bold",
    subTitleFontWeight: "light",
    userOnboarding: {
      "en-AU": {
        searchTerm: "cister",
        placeholderArticle: "367.045.16.1",
      },
      "en-US": {
        searchTerm: "Sigm",
        placeholderArticle: "115.788.11.2",
      },
      "ru-KZ": {
        searchTerm: "Раковины",
        placeholderArticle: "367.045.16.1",
      },
      defaultValues: {
        searchTerm: "monolith",
        placeholderArticle: "367.045.16.1",
      },
    },
  },
} as const

export const apiRoutes = {
  SUGGEST: "/api/suggest",
  SEARCH: "/api/search",
  LATEST: "/api/latest",
  SUPPORT_EMAIL: "/api/email/support",
  SERVICES: "/api/services",
  PDF: "/api/pdf",
  NOVELTIES: "/api/novelties",
  MODELS: "/api/models",
  TENDER_TEXT: "/api/tender-text",
  REMOTE_CONFIG: "/api/remote-config",
  LISTS: "/api/lists",
  AVAILABILITY: "/api/availability",
  PRODUCT: "/api/product",
  CHECK_RESOURCE: "/api/check-resource",
  ROBOTS: "/api/robots",
  SITEMAP: "/api/sitemap",
  BIM: "/api/bim",
  HEALTH: "/api/health",
} as const

export const categoryTreeDepth = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
} as const

export const categoryContentFilter = {
  ALL: "all",
  PRODUCTS: "products",
  SPAREPARTS: "spareparts",
} as const

export const categoryLevels = {
  MASTER: "MAC",
  ONE: "CH1",
  TWO: "CH2",
  THREE: "CH3",
} as const

export const seriesLevels = {
  MASTER: "BRC",
  ONE: "SC1",
  TWO: "SC2",
  THREE: "SC3",
} as const

export const stateIndicator = {
  NEW: "NEW",
  PHASEOUT: "PHASEOUT",
} as const

export const zipFileDownloadOptions = {
  CAD_DRAWINGS: "CAD_DRAWINGS",
  PHOTOS_DRAWINGS: "PHOTOS_DRAWINGS",
  DOCUMENTS: "DOCUMENTS",
  OVERVIEW_TABLES: "OVERVIEW_TABLES",
  CSV: "CSV",
  DATA_SHEET: "DATA_SHEET",
  TENDER_TEXTS: "TENDER_TEXTS",
  DXF: "DXF",
  DWG: "DWG",
  DXF_3D: "DXF_3D",
  DWG_3D: "DWG_3D",
  IGES_3D: "IGES_3D",
  STEP_3D: "STEP_3D",
  SAT_3D: "SAT_3D",
  OBJ: "OBJ",
} as const

export const QR_CODE_REF_LINK = "Print"

export const priceGroups = {
  ALL: "all",
  GROUP1: "group1",
  GROUP2: "group2",
} as const

export const countriesWithPrices = {
  FALSE: "false",
  AT: "AT",
  BE: "BE",
  CH: "CH",
  DE: "DE",
  DK: "DK",
  EE: "EE",
  FI: "FI",
  FR: "FR",
  GB: "GB",
  LT: "LT",
  LV: "LV",
  NL: "NL",
  NO: "NO",
  SE: "SE",
  HU: "HU",
  PL: "PL",
  UA: "UA",
} as const

export const SMTP_SSL_PORT = 465

export const fileTypes = {
  PDF: "pdf",
  ZIP: "zip",
  XLSX: "xlsx",
  TENDER_TEXT: "x81",
  BIM: "rfa",
} as const

export const prismicSliceTypes = {
  SYSTEM_CARD: "system_cards",
  TEASER_CARD: "teaser_cards",
  INFO_MESSAGE: "info_message",
} as const

export const mobileMenuSectionNames = {
  MAIN_MENU_SECTION: "mainMenuSection",
  SYSTEM_MENU: "systemMenu",
  MY_LISTS_MENU: "myListsMenu",
  LANGUAGE_MENU: "languageMenu",
} as const

export const layout = {
  MOBILE_HEADER_HEIGHT: "60px",
  DESKTOP_HEADER_HEIGHT: "142px",
  ANCHOR_NAVIGATION_HEIGHT: 48, //px
  DESKTOP_CONTAINER_WIDTH: "1272px",
  DESKTOP_CONTAINER_PADDING: "24px",
} as const

export const cookieSolutionServices = {
  YOUTUBE_VIDEO: "BJz7qNsdj-7",
  GEBERIT_COMFORT: "ib80fedyQ2ENjd",
} as const

export const user = {
  PROFILE_CONTAINER_ID: "geberit-profile-container",
} as const

export const sparePartsTableHeaderCodes = {
  SPARE_PART_POSITION: "ATT_459152", //#
  ARTICLE_NUMBER: "ATT_10670", //article number
  SECONDARY_ARTICLE_NUMBER_1: "ATT_11287", //secondary article number
  SECONDARY_ARTICLE_NUMBER_2: "ATT_11288", //secondary article number
  SECONDARY_ARTICLE_NUMBER_3: "ATT_11289", //secondary article number
  SECONDARY_ARTICLE_NUMBER_4: "ATT_245544", //secondary article number
  SECONDARY_ARTICLE_NUMBER_5: "ATT_460985", //secondary article number
  DESCRIPTION: "ATT_850463", //description
  REPLACEMENT_FOR: "ATT_389760", //replacement for
  REPLACED_BY: "ATT_389738", //replaced by
  NOTES: "ATT_459153", //notes
}

// Attributes needed for filling in the schema.org microdata for PDP
export const requiredAttrCodesForMicrodata = {
  MATERIAL: "ATT_11001", // Product material designation
  COLOUR: "ATT_459792", // Product colour / surface
}

export const myListUserSettings = {
  EXPAND_ALL_PRODUCTS: "expandAllProducts",
  SHOW_ONLY_ADDED_ARTICLES: "showOnlyAddedArticles",
}

export const myListTableHeaderCodes = {
  ID: "id",
  SECONDARY_ARTICLE_NUMBER_1: "ATT_11287", //secondary article number
  SECONDARY_ARTICLE_NUMBER_2: "ATT_11288", //secondary article number
  SECONDARY_ARTICLE_NUMBER_3: "ATT_11289", //secondary article number
  SECONDARY_ARTICLE_NUMBER_4: "ATT_245544", //secondary article number
  SECONDARY_ARTICLE_NUMBER_5: "ATT_460985", //secondary article number
  DESCRIPTION: "description",
  STATUS: "status",
  QUANTITY: "quantity",
}

export const CATALOG_FILENAME_PREFIX = "GeberitMyCatalog"

export const resourceTypes = {
  PRODUCT: "PRO_",
  SPARE_PART: "SPT_",
}

export const myListColumnNames = {
  ID: "id",
  NAME: "name",
  IS_DEFAULT: "isDefault",
  DATE: "date",
  ARTICLE_COUNT: "articleCount",
  MENU: "menu",
} as const

export const AUTH_TTL = 30 * 24 * 60 * 60 // 30 days

export const ALLOWED_MYLIST_NAME_LENGTH = 40

export const gigyaContexts = {
  LOGIN: "LOGIN",
  PROFILE: "PROFILE",
}

export const prismicTypes = {
  HOMEPAGE: "homepage_config",
  GENERAL: "general_config",
} as const

export const PATH_TO_PRISMIC_BACKUP = "/prismic-backup"

export const PATH_TO_SITEMAPS = "/sitemaps"

export const localesWithoutSeries = ["en-IN", "en-AU", "en-US"]

export const localesWithoutGigya = ["ru-KZ"]

export const PDP_SECTION_IDS = {
  product: "product",
  article: "article",
  technicalData: "technicalData",
  applicationPurposes: "applicationPurposes",
  characteristics: "characteristics",
  delivery: "delivery",
  apps: "apps",
  accessories: "accessories",
  combineWith: "combineWith",
  documents: "documents",
  videos: "videos",
  similiarProducts: "similiarProducts",
  spareParts: "spareParts",
}

export const SPDP_SECTION_IDS = {
  sparePartOverview: "sparePartOverview",
  mainArticles: "mainArticles",
  characteristics: "characteristics",
  relatedSpareParts: "relatedSpareParts",
}

export const systemMenu = {
  DESKTOP_OFFSET: "382px",
  DESKTOP_TABS_WIDTH: "400px",
} as const

export const sitemapTypes = {
  PRODUCTS: "products",
  SPARE_PARTS: "spare-parts",
} as const

export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

export const MAXIMUM_ALLOWED_LISTS = 100 as const
export const MAXIMUM_ALLOWED_ARTICLES_INLISTS = 200 as const

export const homepageConfigKeys = [
  "title",
  "subtitle",
  "show_system_categories",
  "show_all_categories",
  "show_series",
  "show_last_seen",
  "show_novelties",
  "show_teaser_card",
  "show_info_message",
  "slices",
] as const

export const generalConfigKeys = [
  "price",
  "netprice",
  "grossprice",
  "rounding",
  "decimal_point",
  "thousand_separator",
  "show_contact_form",
  "support_email",
] as const

export const prismicTeaserVisibleFor = {
  DESKTOP: "Desktop",
  MOBILE: "Mobile",
  DEFAULT: "Desktop and mobile",
} as const
