const Tag = {
  baseStyle: {
    container: {
      borderRadius: "none",
      color: "white",
    },
  },
  variants: {
    indicatorNew: {
      container: {
        textTransform: "uppercase",
        background: "warning",
      },
    },
    indicatorPhasingOut: {
      container: {
        background: "warning",
        fontSize: "xs",
        height: "6",
      },
    },
    series: {
      container: {
        background: "brand.800",
      },
    },
  },
  sizes: {
    xs: {
      container: {
        minH: "1rem",
        minW: "1rem",
        maxW: "max-content",
        fontSize: "2xs",
        px: "0.5",
        py: "px",
      },
    },
    md: {
      container: {
        fontSize: "xs",
      },
    },
  },
}

export default Tag
