import { AktivGroteskGeberit } from "../../styles/fonts"

/* This is the old styled-components theme which is used only for backwards compatibility
  with the reused styled-components powered components, mainly the article-table feature.
  The valid style options for the application and the Chakra UI powered components are inside
  the chakra-theme.ts file and the /chakra folder with overrides for the Chakra theme */

export const breakpoints = {
  //taken from Bootstrap
  xs: 0,
  sm: 576,
  md: 768, // This is used by the article table, it's equal to "sm" breakpoint in Chakra UI config
  lg: 992,
  xl: 1200,
}

const theme = {
  /* These colors will be removed completely, colors are coming from the Ckara UI theme config */
  colors: {
    //colors are ordered alphabetically and values letters are capital
    baseEmerald: "#4BA5A0",
    baseOrange: "#D18B3C",
    baseWhite: "#FFFFFF",
    blue1Percent: "#F4F9FF",
    blueGrayMedium: "#84909D",
    black: "#000000",
    gray1Percent: "#FAFAFA",
    gray3Percent: "#F7F7F7",
    gray5Percent: "#F1F1F1",
    gray6Percent: "#f0f0f0",
    gray9Percent: "#D6D6D6",
    gray10Percent: "#E6E6E6",
    gray15Percent: "#D9D9D9",
    gray38Percent: "#9D9D9C",
    gray45Percent: "#999999",
    gray53Percent: "#888888",
    gray60Percent: "#666666",
    gray80Percent: "#333333",
    gray90Percent: "#3D3D3D",
    oldDesigns_gray2Percent: "#FAFAFA",
    oldDesigns_pageNotFoundDarkerBlue: "#004673",
    oldDesigns_linksHover: "#23527C",
    orange6Percent: "#F07800",
    orange2Percent: "#FAF3EB",
    warning: "#F078001A",
    replacementInfo: "#EBF0F7",
  },
  fontFamily: {
    geberitBd: AktivGroteskGeberit.style.fontFamily,
    geberitBdItalic: AktivGroteskGeberit.style.fontFamily,
    geberitItalic: AktivGroteskGeberit.style.fontFamily,
    geberitLight: AktivGroteskGeberit.style.fontFamily,
    geberitLightItalic: AktivGroteskGeberit.style.fontFamily,
    geberitMedium: AktivGroteskGeberit.style.fontFamily,
    geberitMediumItalic: AktivGroteskGeberit.style.fontFamily,
    geberitRg: AktivGroteskGeberit.style.fontFamily,
  },
  sizes: {
    base: 4,
    articleTableImageWidth: 48,
    articleTableImageHeight: 48,
    articleMobileImageWidth: 48,
    articleMobileImageHeight: 48,
    boxSizeSmall: 0, //TBD
    boxSizeMedium: 180,
    boxSizeLarge: 280,
    iconWidth: 16,
    iconHeight: 16,
    gapXXXSmall: 1,
    gapXXSmall: 2,
    gapXSmall: 4,
    gapSmall: 8,
    gapMedium: 12,
    gapLarge: 16,
    gapXLarge: 32,
  },
  breakpoints,
  mediaQueries: {
    xs: `@media (min-width: ${breakpoints.xs}px)`,
    sm: `@media (min-width: ${breakpoints.sm}px)`,
    md: `@media (min-width: ${breakpoints.md}px)`,
    lg: `@media (min-width: ${breakpoints.lg}px)`,
    xl: `@media (min-width: ${breakpoints.xl}px)`,
    mdDown: `@media (max-width: ${breakpoints.md}px)`,
  },
  zIndex: {
    sliderArrows: 2,
    filterDropdownBox: 6,
    bgOverlay: 10,
    mobileOverlay: 11,
    searchHighlightedArticle: 3,
    pdpUnderArticleTableHeader: 1,
    pdpArticleTableThreeDotsMenu: 2,
    pdpArticleTableHeader: 3,
    pdpFilterContainer: 4,
    tooltipText: 1,
    sparePartTableScrollbars: 1,
    helpMenu: 12,
    tutorialSpotlight: 12,
    tutorialContent: 13,
    tutorialBullet: 14,
    customModal: 2000,
  },
  opacity: {
    overlay: 0.24,
    shadow: 0.16,
  },
}

export default theme
