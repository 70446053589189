import { IconButton, IconButtonProps } from "@chakra-ui/react"
import { LinkProps } from "next/link"
import CustomLink from "./CustomLink"

type Props = IconButtonProps & LinkProps & { target?: "_blank" | "_self" }

export function ChakraNextIconButtonLink({
  w,
  h,
  color,
  mb,
  "aria-label": ariaLabel,
  icon,
  href,
  target = "_self",
  variant = "secondary",
  onClick,
}: Props) {
  return (
    <CustomLink href={href}>
      <IconButton
        mb={mb}
        as="a"
        w={w}
        h={h}
        onClick={onClick}
        variant={variant}
        color={color}
        icon={icon}
        aria-label={ariaLabel}
        target={target}
      />
    </CustomLink>
  )
}
