import { replaceSymbol } from "./common-utils"

/* A helper which handles special characters for the needs of Fredhopper
Encode everything that is not a number, a letter of the english alphabet or a white space */
export const specialCharactersToUnicode = (str: string): string => {
  return str?.replaceAll(/[^A-Za-z0-9\s]/g, (ch) => {
    return "\\u" + ("000" + ch.charCodeAt(0).toString(16)).slice(-4)
  })
}

/* Replicate Fredhopper stemming algorithm behavior
by replacing special characters with whitespace
and trim the excessive whitespaces */
export const stemSpecialCharacters = (str: string): string => {
  if (!str && str !== "0") return ""
  /* Replace all stemmed by Fredhopper characters with an empty space */
  const stemmed = str.replaceAll(/[-~`!#$%^&*()+={}|[\]:;"'<>,?/\\»«]/g, " ")
  /* Trim leading, trailing and inner whitespaces if more than one at a time */
  const trimmed = replaceSymbol(stemmed.trim(), " ", " ")

  return trimmed
}

//The 'q' paramater in the url needs plus signs
//instead of empty spaces for Google Analytics
export const getModifiedQueryParam = (param: string) => {
  //Trim leading and trailing whitespaces
  let trimmedQueryParam = param.trim()
  //Trim inner whitespaces if more than one at a time
  trimmedQueryParam = replaceSymbol(trimmedQueryParam, " ", " ")

  return replaceSymbol(trimmedQueryParam, " ", "+")
}
