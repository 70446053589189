import { createIcon } from "@chakra-ui/react"

export const C3dData = createIcon({
  displayName: "3dData",
  viewBox: "0 0 16 16",

  path: (
    <path
      d="M4.44 10.19c-.02.25-.08.48-.17.69-.09.21-.22.39-.38.55-.16.15-.36.27-.6.36-.24.09-.51.13-.82.13-.35 0-.66-.06-.93-.18s-.49-.28-.67-.49-.32-.47-.41-.77c-.09-.3-.14-.63-.14-1s.05-.72.15-1.01.24-.55.43-.77c.19-.21.41-.38.68-.49.26-.11.56-.17.9-.17.29 0 .55.04.77.11.22.07.4.18.55.32s.27.31.36.5c.09.2.16.41.2.66l-.96.12c-.05-.27-.14-.49-.28-.64-.14-.16-.35-.23-.65-.23-.37 0-.66.14-.85.42-.19.28-.29.67-.29 1.19s.1.91.31 1.19c.21.28.48.42.83.42.3 0 .52-.08.66-.23.14-.15.23-.37.26-.66h1.04Zm2.14-3.04h.92l1.74 4.64H8.2l-.37-1.07H6.21l-.37 1.07h-1l1.74-4.64Zm1 2.77-.57-1.66-.56 1.66h1.12ZM10 7.15h1.74c.71 0 1.26.19 1.66.58s.59.96.59 1.72c0 .39-.05.72-.17 1.01-.11.29-.27.54-.48.73-.21.2-.46.35-.76.45s-.64.15-1.01.15H10V7.16Zm1.59 3.82c.25 0 .47-.04.64-.11.18-.07.32-.17.43-.31.11-.13.19-.29.24-.48.05-.18.08-.39.08-.62s-.02-.43-.07-.61-.12-.34-.22-.46c-.1-.13-.23-.22-.39-.29-.16-.07-.35-.1-.58-.1h-.77v2.99h.62ZM11.99 0H4v6h1V1h6v4h4v10H5v-2H4v3h12V4l-4-4Zm0 4V1.41L14.58 4h-2.59Z"
      fill="currentColor"
    />
  ),
})

export const C3dViewer = createIcon({
  displayName: "3dViewer",
  viewBox: "0 0 16 16",

  path: (
    <path
      d="M14 0v4h-4V3h2.24C11.1 2.03 9.62 1.43 8 1.43 4.38 1.43 1.43 4.38 1.43 8c0 .79.15 1.54.4 2.24l-.94.34C.59 9.77.43 8.9.43 7.99.43 3.83 3.83.43 8 .43c1.92 0 3.67.72 5 1.91V0h1ZM3 16v-2.34c1.33 1.18 3.08 1.91 5 1.91 4.17 0 7.57-3.4 7.57-7.57 0-.91-.17-1.78-.46-2.59l-.94.34c.26.7.4 1.46.4 2.24 0 3.62-2.95 6.57-6.57 6.57-1.62 0-3.09-.6-4.24-1.57H6v-1H2v4h1Zm.98-7.1c0 .18.04.36.1.54.06.18.15.35.28.5.13.15.3.27.52.36s.48.14.81.14c.29 0 .53-.04.73-.11.2-.07.37-.17.5-.3.13-.12.23-.28.29-.46.06-.18.09-.37.09-.58s-.05-.43-.16-.61-.29-.34-.55-.45c.21-.11.36-.25.46-.42.1-.17.15-.37.15-.59 0-.17-.03-.33-.09-.48s-.16-.28-.29-.39-.29-.19-.48-.26-.41-.09-.67-.09-.49.03-.68.1c-.19.07-.35.16-.48.27-.13.12-.24.26-.31.42s-.13.33-.17.52l.87.12c.06-.24.15-.41.27-.51.13-.1.29-.14.5-.14.12 0 .21.01.29.04s.14.07.19.12.08.11.1.17c.02.07.03.14.03.21 0 .39-.19.58-.58.58h-.51v.8h.45c.26 0 .44.05.55.15.11.1.17.26.17.48s-.07.39-.2.48c-.13.1-.3.15-.51.15-.23 0-.41-.06-.53-.17-.12-.11-.19-.31-.21-.59h-.93Zm4.31 1.42h1.57c.38 0 .72-.05 1.01-.15s.55-.25.76-.45.37-.44.48-.73.17-.63.17-1.01c0-.76-.2-1.33-.59-1.72-.4-.39-.95-.58-1.66-.58H8.29v4.64Zm.97-.83V6.5h.77c.23 0 .42.04.58.1.16.07.29.17.39.29.1.13.17.28.22.46.04.18.07.39.07.61s-.03.44-.08.62-.13.34-.24.48-.25.24-.43.31-.39.11-.64.11h-.62Z"
      fill="currentColor"
    />
  ),
})

export const CAddToList = createIcon({
  displayName: "AddToList",
  viewBox: "0 0 16 16",

  path: (
    <path
      d="M9 0H1v16h8v-1H2V1h6v4h4v2h1V4L9 0Zm0 4V1.41L11.59 4H9Zm7 9h-3v3h-1v-3H9v-1h3V9h1v3h3v1ZM4 9h6v1H4V9Zm0 2h3v1H4v-1Zm0-4h6v1H4V7Z"
      fill="currentColor"
    />
  ),
})

export const CAddedToList = createIcon({
  displayName: "AddedToList",
  viewBox: "0 0 16 16",

  path: (
    <path
      d="M11.49,15.09l-2.34-2.37,.71-.7,1.63,1.65,3.66-3.66,.71,.71-4.37,4.37Zm1.51-11.09v3h-1v-2h-4V1H2V15h7v1H1V0H9l4,4Zm-1.41,0l-2.59-2.59v2.59h2.59Zm-7.59,6h6v-1H4v1Zm0,2h3v-1h-3v1Zm0-4h6v-1H4v1Z"
      fill="currentColor"
    />
  ),
})

export const CArrowAsc = createIcon({
  displayName: "ArrowAsc",
  viewBox: "0 0 16 16",

  path: <path fill="currentColor" d="M7 4v1h3.293L2.99 12.303l.707.708L11 5.707V9H12V4z" fillRule="evenodd" />,
})

export const CArrowDesc = createIcon({
  displayName: "ArrowDesc",
  viewBox: "0 0 16 16",

  path: <path fill="currentColor" d="M12 8.01h-1v3.294L3.697 4l-.708.707 7.304 7.304H7v1h5z" fillRule="evenodd" />,
})

export const CArrowLeft = createIcon({
  displayName: "ArrowLeft",
  viewBox: "0 0 20 20",

  path: (
    <path
      d="M7.354 2l.707.707L1.903 9H20v1H1.925l6.136 6-.707.707L0 9.5 7.354 2z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
})

export const CArrowRight = createIcon({
  displayName: "ArrowRight",
  /* trim white space around path,
     matters when not vertically centered 
     (e.g application purposes multiline) */
  viewBox: "0 3 20 14",

  path: (
    <path
      d="M12.646 17.007l-.707-.707 6.158-6.293H0v-1h18.075l-6.136-6 .707-.707L20 9.507l-7.354 7.5z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
})

export const CArrowTopLeft = createIcon({
  displayName: "ArrowTopLeft",
  viewBox: "0 0 16 16",

  path: <path d="M11.78 3v1H4.71l9.96 9.96-.71.71L4 4.71v7.07H3V3h8.78Z" fill="currentColor" />,
})

export const CArrowTopRight = createIcon({
  displayName: "ArrowTopRight",
  viewBox: "0 0 16 16",

  path: <path d="M13 11.78h-1V4.71l-9.96 9.96-.71-.71L11.29 4H4.22V3H13v8.78Z" fill="currentColor" />,
})

export const CArrowBottomLeft = createIcon({
  displayName: "ArrowBottomLeft",
  viewBox: "0 0 16 16",

  path: <path d="M2 5.22h1v7.07l9.96-9.96.71.71L3.71 13h7.07v1H2V5.22Z" fill="currentColor" />,
})

export const CArrowBottomRight = createIcon({
  displayName: "ArrowBottomRight",
  viewBox: "0 0 16 16",

  path: <path d="M5.22 14v-1h7.07L2.33 3.04l.71-.71L13 12.29V5.22h1V14H5.22Z" fill="currentColor" />,
})

export const CAttachment = createIcon({
  displayName: "Attachment",
  viewBox: "0 0 16 16",

  path: (
    <path
      d="M7 5a1 1 0 012 0v8.5A1.5 1.5 0 017.5 15h-1A1.5 1.5 0 015 13.5V3a2 2 0 012-2h2a2 2 0 012 2v10h1V3a3 3 0 00-3-3H7a3 3 0 00-3 3v10.5A2.5 2.5 0 006.5 16h1a2.5 2.5 0 002.5-2.5V5a2 2 0 10-4 0v7h1V5z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
})

export const CCatalog = createIcon({
  displayName: "Catalog",
  viewBox: "0 0 16 16",

  path: (
    <path
      d="M14.8 2.1C14.37 1.78 12.66 1 11 1c-1.4 0-2.82.55-3.5.92C6.82 1.55 5.4 1 4 1 2.34 1 .63 1.78.2 2.1l-.2.15V14.5l.8-.6c.24-.18 1.72-.9 3.2-.9s2.96.72 3.2.9l.3.23.3-.23c.24-.18 1.72-.9 3.2-.9s2.96.72 3.2.9l.8.6V2.25l-.2-.15ZM1 12.67V2.78C1.5 2.51 2.75 2 4 2s2.5.51 3 .78v9.9c-.76-.33-1.88-.68-3-.68s-2.24.35-3 .67Zm13 0c-.76-.33-1.89-.67-3-.67s-2.24.35-3 .68v-9.9C8.5 2.51 9.74 2 11 2s2.5.51 3 .78v9.89ZM6 6H2V5h4v1Zm0 2H2V7h4v1Zm0 2H2V9h4v1Zm7-4H9V5h4v1Zm0 2H9V7h4v1Zm0 2H9V9h4v1Z"
      fill="currentColor"
    />
  ),
})

export const CCalendar = createIcon({
  displayName: "Calendar",
  viewBox: "0 0 20 20",

  path: (
    <path
      d="M5 0v2h10V0h1v2h4v17H0V2h4V0h1zm14 8H1v10h18V8zM4 3H1v4h18V3h-3v2h-1V3H5v2H4V3z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
})

export const CCancel = createIcon({
  displayName: "Cancel",
  viewBox: "0 0 20 20",

  path: (
    <path
      d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0zm6.702 3.993L3.497 16.222A9 9 0 0016.703 3.994zM10 1a9 9 0 00-7.154 14.461L15.995 3.287A8.966 8.966 0 0010 1z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
})

export const CCart = createIcon({
  displayName: "Cart",
  viewBox: "0 0 20 20",

  path: (
    <path
      d="M8 17a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm8 0a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm-8 1a.5.5 0 100 1 .5.5 0 000-1zm8 0a.5.5 0 100 1 .5.5 0 000-1zM3.415 0l.749 4h15.402l-1.125 9H5.852l.563 3H17v1H5.585L2.584 1H0V0h3.415zm15.018 5H4.352l1.313 7h11.893l.875-7z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
})

export const CCategories = createIcon({
  displayName: "Categories",
  viewBox: "0 0 16 16",

  path: (
    <path
      d="M15 1v5h-5V1h5M6 1v5H1V1h5m9 9v5h-5v-5h5m-9 0v5H1v-5h5M16 0H9v7h7V0ZM7 0H0v7h7V0Zm9 9H9v7h7V9ZM7 9H0v7h7V9Z"
      fill="currentColor"
    />
  ),
})

export const CCheckmark = createIcon({
  displayName: "Checkmark",
  viewBox: "0 0 20 20",

  path: (
    <path fill="currentColor" d="M17.646 3.646l.708.708L6 16.707l-4.354-4.353.708-.708L6 15.293z" fillRule="evenodd" />
  ),
})

export const CCheck = createIcon({
  displayName: "Check",
  viewBox: "0 0 24 24",

  path: (
    <path fill="currentColor" d="M21.646 3.646l.708.708L7 19.707l-5.354-5.353.708-.708L7 18.293z" fillRule="evenodd" />
  ),
})
export const CChevronDownFilled = createIcon({
  displayName: "ChevronDownFilled",
  viewBox: "0 0 16 16",

  path: <path fill="currentColor" d="M8 12 3.67 6h8.66L8 12Z" />,
})

export const CChevronUpFilled = createIcon({
  displayName: "ChevronUpFilled",
  viewBox: "0 0 16 16",

  path: <path fill="currentColor" d="m8 4 4.33 6H3.67L8 4Z" />,
})

export const CChevronDown = createIcon({
  displayName: "ChevronDown",
  viewBox: "0 0 20 20",

  path: <path d="M.707 5.293L0 6l10 10L20 5.707 19.293 5 10 14.586z" fill="currentColor" fillRule="evenodd" />,
})

export const CChevronLeft = createIcon({
  displayName: "ChevronLeft",
  viewBox: "0 0 20 20",

  path: <path d="M14.707 19.293L14 20 4 10 14.293 0 15 .707 5.414 10z" fill="currentColor" fillRule="evenodd" />,
})

export const CChevronRightFlat = createIcon({
  displayName: "ChevronRightFlat",
  viewBox: "0 0 16 16",

  path: <path fill="currentColor" d="M6.832 2L6 2.554l3.769 5.652L6 13.859l.832.555 4.138-6.208z" fillRule="evenodd" />,
})

export const CChevronRight = createIcon({
  displayName: "ChevronRight",
  viewBox: "0 0 20 20",

  path: <path d="M4.293 19.293L5 20l10-10L4.707 0 4 .707 13.586 10z" fill="currentColor" fillRule="evenodd" />,
})

export const CChevronUp = createIcon({
  displayName: "ChevronUp",
  viewBox: "0 0 20 20",

  path: <path d="M19.293 15.707L20 15 10 5 0 15.293.707 16 10 6.414z" fill="currentColor" fillRule="evenodd" />,
})

export const CClock = createIcon({
  displayName: "Clock",
  viewBox: "0 0 20 20",

  path: (
    <g fill="currentColor" fillRule="evenodd">
      <path d="M10 1a9 9 0 00-9 9 9 9 0 009 9 9 9 0 009-9 9 9 0 00-9-9m10 9c0 5.523-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0s10 4.477 10 10" />
      <path d="M10 10.293V3H9v7.707l3.602 3.601.707-.708z" />
    </g>
  ),
})

export const CClose = createIcon({
  displayName: "Close",
  viewBox: "0 0 20 20",

  path: (
    <path
      fill="currentColor"
      d="M17.142 16.435l-6.363-6.363 6.363-6.365-.706-.706-6.365 6.363L3.707 3 3 3.707l6.365 6.365L3 16.435l.707.707 6.364-6.364 6.364 6.364z"
      fillRule="evenodd"
    />
  ),
})

export const CCopy = createIcon({
  displayName: "Copy",
  viewBox: "0 0 20 20",

  path: (
    <path d="M20 6v14H6V6h14zm-1 1H7v12h12V7zm-5-7v5h-1V1H1v12h4v1H0V0h14z" fill="currentColor" fillRule="evenodd" />
  ),
})

export const CDocument = createIcon({
  displayName: "Document",
  viewBox: "0 0 16 16",

  path: (
    <path
      d="M10 0H2v16h12V4l-4-4Zm0 1.41L12.59 4H10V1.41ZM3 15V1h6v4h4v10H3Zm2-6h6v1H5V9Zm0 2h3v1H5v-1Zm0-4h6v1H5V7Z"
      fill="currentColor"
    />
  ),
})

export const CDoubleChevronDownFlat = createIcon({
  displayName: "DoubleChevronDownFlat",
  viewBox: "0 0 20 20",

  path: (
    <path
      d="M17.859 8.604l.554.832-8.206 6.138L2 9.436l.554-.832 7.653 5.768 7.652-5.768zm0-3.604l.554.832-8.206 6.138L2 5.832 2.554 5l7.653 5.768L17.859 5z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
})

export const CDoubleChevronDown = createIcon({
  displayName: "DoubleChevronDown",
  viewBox: "0 0 20 20",

  path: (
    <path
      d="M17.646 8.646l.708.708L10 17.707 1.646 9.354l.708-.708L10 16.293l7.646-7.647zm0-6l.708.708L10 11.707 1.646 3.354l.708-.708 7.645 7.647 7.647-7.647z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
})

export const CDownload = createIcon({
  displayName: "Download",
  viewBox: "0 0 20 20",

  path: (
    <path
      d="M0 20h19v-1H0v1zM9 0v13.329l-3.328-3.328-.708.707L9.5 15.244l4.537-4.536-.708-.707L10 13.329V0H9z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
})

export const CDragDrop = createIcon({
  displayName: "DragDrop",
  viewBox: "0 0 16 16",

  path: (
    <path
      d="M10 2c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1ZM6 2c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1Zm4 5c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1ZM6 7c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1Zm4 5c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1Zm-4 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1Z"
      fillRule="evenodd"
      fill="currentColor"
    />
  ),
})

export const CEdit = createIcon({
  displayName: "Edit",
  viewBox: "0 0 20 20",

  path: (
    <path
      d="M11 4l-1 1H1v14h14v-9l1-1v11H0V4h11zm5.879-3l2.12 2.121-7.07 7.072-3.622 1.5 1.5-3.622L16.88 1zm0 1.415l-6.224 6.222-.5 1.208 1.208-.5 6.222-6.224-.706-.706z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
})

export const CEnter = createIcon({
  displayName: "Enter",
  viewBox: "0 0 16 16",

  path: <path d="M16 2v7H1.91l5 5-.71.71L0 8.5l6.21-6.21.71.71-5.01 5H15V2h1Z" fill="currentColor" />,
})

export const CEnvelope = createIcon({
  displayName: "Envelope",
  viewBox: "0 0 20 20",

  path: (
    <path
      d="M0 17h20V3H0v14zM1 4h18l-9 4.902L1 4zm18 1.221V16H1V5.221l9 4.901 9-4.901z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
})

export const CFilter = createIcon({
  displayName: "Filter",
  viewBox: "0 0 16 16",

  path: (
    <path
      d="M6 15.62V9.66L.53 2h14.94L10 9.66v4.16l-4 1.8ZM2.47 3 7 9.34v4.74l2-.9V9.34L13.53 3H2.47Z"
      fill="currentColor"
    />
  ),
})

export const CGeberitId = createIcon({
  displayName: "GeberitId",
  viewBox: "0 0 20 20",

  path: (
    <path
      d="M6 10c1.105 0 2 .784 2 1.75V17H0v-5.25C0 10.784.895 10 2 10h4zm0 1H2c-.513 0-.936.322-.993.736L1 11.833V16h6v-4.167c0-.427-.386-.78-.883-.827L6 11zM4 3a3 3 0 110 6 3 3 0 010-6zm0 1a2 2 0 100 4 2 2 0 000-4zm7 4h6v1h-6V8zm0 3h9v1h-9v-1zm0 2h2v1h-2v-1zm3 0h6v1h-6v-1zm-3 2h9v1h-9v-1z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
})

export const CHelp = createIcon({
  displayName: "Help",
  viewBox: "0 0 20 20",

  path: (
    <path
      d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0zm0 1a9 9 0 100 18 9 9 0 000-18zm1 12v2H9v-2h2zm-.75-8c1.757 0 3.033 1.178 3.033 2.8 0 1.336-.783 1.977-1.158 2.286l-.106.088-.985.89c-.04.036-.084.137-.084.31V12H9.083v-.626c0-.688.25-1.291.701-1.696l1.157-1.036c.296-.243.476-.39.476-.842 0-.689-.63-.933-1.167-.933-.543 0-1.167.158-1.167 1.398H7.217C7.217 6.28 8.407 5 10.25 5z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
})

export const CHome = createIcon({
  displayName: "Home",
  viewBox: "0 0 16 16",

  path: (
    <path
      d="M12 7.378l1 .857v6.423H3V8.235l1-.857v6.28h8v-6.28zM8 0l8 6.857-.651.76L8 1.317l-7.349 6.3L0 6.857 8 0z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
})

export const CImage = createIcon({
  displayName: "Image",
  viewBox: "0 0 16 16",

  path: (
    <path
      d="M0 0v16h16V0H0Zm15 1v5.12l-2.86-2.86-5.22 5.19-2.88-2.88L1 8.67V1h14ZM1 15v-4.9l3.05-3.11 4.27 4.27.71-.71-1.39-1.39 4.51-4.49 2.86 2.86v7.46H1Z"
      fill="currentColor"
    />
  ),
})

export const CImport = createIcon({
  displayName: "Import",
  viewBox: "0 0 20 20",

  path: (
    <g fill="currentColor" fillRule="evenodd">
      <path d="M9 0v13.329l-3.328-3.328-.708.707L9.5 15.244l4.537-4.536-.708-.707L10 13.329V0z" />
      <path d="M6.5 5v1H1v13h17V6h-5.5V5H19v15H0V5z" />
    </g>
  ),
})

export const CInfo = createIcon({
  displayName: "Info",
  viewBox: "0 0 20 20",

  path: (
    <path
      d="M10 0c5.514 0 10 4.486 10 10s-4.486 10-10 10S0 15.514 0 10 4.486 0 10 0zm0 1c-4.962 0-9 4.038-9 9s4.038 9 9 9 9-4.038 9-9-4.038-9-9-9zm1 8v7H9V9h2zm0-4v2H9V5h2z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
})

export const CKatalog = createIcon({
  displayName: "Katalog",
  viewBox: "0 0 20 20",

  path: (
    <path
      d="M18 16.674c-.756-.327-2.888-.674-4-.674-1.116 0-3.244.349-4 .677V2.782C10.499 2.514 12.745 2 14 2c1.249 0 3.495.511 4 .779v13.895zM1 2.779C1.505 2.511 3.751 2 5 2c1.255 0 3.501.514 4 .782v13.895C8.244 16.349 6.116 16 5 16c-1.112 0-3.244.347-4 .674V2.779zm17.801-.678C18.369 1.777 15.664 1 14 1c-1.397 0-3.823.547-4.5.915C8.823 1.547 6.397 1 5 1 3.336 1 .631 1.777.199 2.101L0 2.25v16.251l.801-.601c.243-.183 2.72-.9 4.199-.9 1.479 0 3.956.717 4.199.9l.301.225.301-.225c.243-.183 2.72-.9 4.199-.9 1.479 0 3.956.717 4.199.9l.801.601V2.25l-.199-.149z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
})

export const CLeistungsVerz = createIcon({
  displayName: "LeistungsVerz",
  viewBox: "0 0 16 16",

  path: (
    <g fill="currentColor" fillRule="evenodd">
      <path d="M9 0H1v16h5v-1H2V1h6v4h4v4h1V4L9 0Zm0 4V1.41L11.59 4H9ZM4 9h3v1H4V9Zm0-2h6v1H4V7Zm7 4c1.78 0 3.4 1.42 3.89 2.07-.5.61-2.11 1.93-3.89 1.93s-3.4-1.38-3.89-2c.5-.62 2.11-2 3.89-2m0-1c-2.65 0-5 2.4-5 3s2.35 3 5 3 5-2.32 5-2.92S13.65 10 11 10Z" />
      <circle cx="11" cy="13" r="1" />
    </g>
  ),
})

export const CLink = createIcon({
  displayName: "Link",
  viewBox: "0 0 16 16",

  path: (
    <path
      d="m8.46 4.96-.7.71L10.09 8H0v1h10.09l-2.33 2.33.7.71L12 8.5 8.46 4.96ZM2 16h14V1H2v5h1V2h12v13H3v-4H2v5Z"
      fill="currentColor"
    />
  ),
})

export const CLists = createIcon({
  displayName: "Lists",
  viewBox: "0 0 16 16",

  path: (
    <path
      d="M2 1v15h12V1h-3V0H5v1H2Zm8 0v2H6V1h4Zm1 3V2h2v13H3V2h2v2h6ZM7 9h4v1H7V9Zm0 3h4v1H7v-1Zm0-6h4v1H7V6ZM5 9h1v1H5V9Zm0 3h1v1H5v-1Zm0-6h1v1H5V6Z"
      fill="currentColor"
    />
  ),
})

export const CLogout = createIcon({
  displayName: "Logout",
  viewBox: "0 0 20 20",

  path: (
    <path
      d="M17 0v20H3v-4h1l-.001 3.001h12v-18h-12L4 4H3V0h14zM5.536 5l.707.708-3.328 3.328h10.329v1H2.915l3.328 3.328-.707.708L1 9.537 5.536 5z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
})

export const CMenu = createIcon({
  displayName: "Menu",
  viewBox: "0 0 20 20",

  path: <path d="M0 4h20v1H0V4zm0 6h20v1H0v-1zm0 6h20v1H0v-1z" fill="currentColor" fillRule="evenodd" />,
})

export const CMinus = createIcon({
  displayName: "Minus",
  viewBox: "0 0 16 16",

  path: <path fill="currentColor" d="M0 7h16v1H0z" fillRule="evenodd" />,
})

export const CMore = createIcon({
  displayName: "More",
  viewBox: "0 0 20 20",

  path: (
    <g fill="none" fillRule="evenodd">
      <path d="M1 12h4V8H1v4zm7-.001h4V8.001H8v3.998zM15 12h4V8h-4v4z" fill="currentColor" />
    </g>
  ),
})

export const CMoreSmall = createIcon({
  displayName: "MoreSmall",
  viewBox: "0 0 16 16",

  path: (
    <path
      d="M8 7c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1Zm4 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1ZM4 7c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1Z"
      fillRule="evenodd"
      fill="currentColor"
    />
  ),
})

export const CPartialMatchCheck = createIcon({
  displayName: "PartialMatchCheck",
  viewBox: "0 0 16 16",

  path: (
    <path
      d="M8 0C3.59 0 0 3.59 0 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8ZM6 12 3 9l.71-.71L6 10.58l6.29-6.29L13 5l-7 7Z"
      fill="currentColor"
    />
  ),
})

export const CPartialMatch = createIcon({
  displayName: "PartialMatch",
  viewBox: "0 0 16 16",

  path: (
    <g fill="none" fillRule="evenodd">
      <circle fill="#3D3D3D" cx="4" cy="4" r="4" />
      <path fill="#FFF" fillRule="nonzero" d="M6.646 1.646l.708.708L3 6.707.646 4.354l.708-.708L3 5.293z" />
    </g>
  ),
})

export const CPdf = createIcon({
  displayName: "Pdf",
  viewBox: "0 0 20 20",

  path: (
    <g fill="currentColor" fillRule="nonzero">
      <path d="M12 0H4v8h1V1h6v4h4v14H5v-4H4v5h12V4l-4-4zm0 4V1.41L14.59 4H12zM0 9h1.917c.411 0 .75.043 1.02.13.267.086.48.2.638.343.158.142.268.308.331.497s.094.386.094.592c0 .219-.031.43-.094.63s-.173.377-.33.529a1.663 1.663 0 01-.632.36c-.264.089-.596.133-.997.133h-.842V14H0V9zm1.91 2.346c.195 0 .357-.019.485-.056a.801.801 0 00.304-.154.495.495 0 00.154-.242 1.122 1.122 0 00-.003-.62.482.482 0 00-.158-.224.793.793 0 00-.305-.14 2.002 2.002 0 00-.485-.049h-.797v1.485h.805z" />
      <path d="M5 9h1.74c.712 0 1.267.208 1.664.623.397.416.596 1.032.596 1.849 0 .415-.055.78-.166 1.092-.11.313-.27.577-.479.792a1.971 1.971 0 01-.762.483A3 3 0 016.577 14H5V9zm1.596 4.11c.252 0 .467-.038.645-.115.178-.077.323-.188.433-.333.111-.144.192-.316.245-.514.052-.199.078-.422.078-.669 0-.243-.023-.463-.069-.662a1.338 1.338 0 00-.218-.5c-.1-.136-.229-.24-.388-.316a1.362 1.362 0 00-.583-.112h-.768v3.222h.625zM10 9h3v.896h-2.047v1.177h1.925v.896h-1.925V14H10z" />
    </g>
  ),
})

export const CPdfArticleTable = createIcon({
  displayName: "CPdfArticleTable",
  viewBox: "0 0 16 16",

  path: (
    <g fill="currentColor" fillRule="nonzero">
      <text style={{ fontSize: "6.5px", fontFamily: "inherit", fontWeight: 700 }} transform="translate(0 11.8)">
        <tspan style={{ letterSpacing: "0.02em" }}>P</tspan>
        <tspan style={{ letterSpacing: "0.03em" }} x="4.31" y="0">
          D
        </tspan>
        <tspan x="9.28" y="0">
          F
        </tspan>
      </text>
      <path d="M12,0H4V6H5V1h6V5h4V15H5V13H4v3H16V4Zm0,4V1.41L14.59,4Z" />
    </g>
  ),
})

export const CPen = createIcon({
  displayName: "Pen",
  viewBox: "0 0 20 20",

  path: (
    <path
      d="M7.025 17.01l-5.303 1.768 1.768-5.303 12.02-12.02 3.536 3.535-12.02 12.02zm-.791-.79L4.28 14.264l-.976 2.931 2.93-.977zM17.632 4.99L15.51 2.868 4.904 13.475l2.121 2.121L17.632 4.99z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
})

export const CPlay = createIcon({
  displayName: "Play",
  viewBox: "0 0 32 32",

  path: <path fill="currentColor" d="M6.977 5.644l18.063 9.91L6.977 26.286z" fillRule="evenodd" />,
})

export const CPlus = createIcon({
  displayName: "Plus",
  viewBox: "0 0 20 20",

  path: <path fill="currentColor" d="M9 0v9H0v1h9v9h1v-9h9V9h-9V0z" fillRule="evenodd" />,
})

export const CPresets = createIcon({
  displayName: "Presets",
  viewBox: "0 0 20 20",

  path: (
    <path
      d="M15 3v1h4v1h-4v1h-2V5H1V4h12V3h2zM7 8v1h12v1H7v1H5v-1H1V9h4V8h2zm6 5v1h6v1h-6v1h-2v-1H1v-1h10v-1h2z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
})

export const CProductImages = createIcon({
  displayName: "ProductImages",
  viewBox: "0 0 16 16",

  path: (
    <path
      d="M16 4v12H4v-1h11V4h1Zm-2 10H0V0h14v14ZM1 1v6.34l2.5-2.55 2.49 2.49 4.51-4.49 2.5 2.5V1H1Zm12 12V6.71l-2.5-2.5-3.8 3.78 1.16 1.16-.71.71-3.64-3.64-2.5 2.55v4.24H13Z"
      fill="currentColor"
    />
  ),
})

export const CProfile = createIcon({
  displayName: "Profile",
  viewBox: "0 0 20 20",

  path: (
    <path
      d="M12 10a5 5 0 015 5v5h-1v-5a4 4 0 00-3.8-3.995L12 11H8a4 4 0 00-3.995 3.8L4 15v5H3v-5a5 5 0 015-5h4zM10 0a5 5 0 110 10 5 5 0 010-10zm0 1a4 4 0 100 8 4 4 0 000-8z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
})

export const CRelation = createIcon({
  displayName: "Relation",
  viewBox: "0 0 16 16",

  path: (
    <path
      d="M10.91 6.01 9.14 4.24l.71-.71 1.06 1.06 2.95-2.95.71.71L10.92 6Zm3.65 4.35-.71-.71-2.95 2.95-1.06-1.06-.71.71 1.77 1.77 3.65-3.65Zm-8 0-.71-.71L2.9 12.6l-1.06-1.06-.71.71 1.77 1.77 3.65-3.65ZM6 1H1v5h5V1m1-1v7H0V0h7Z"
      fill="currentColor"
    />
  ),
})

export const CSearch = createIcon({
  displayName: "Search",
  viewBox: "0 0 20 20",

  path: (
    <path
      d="M7.5 0a7.5 7.5 0 015.645 12.438L19.707 19l-.707.707-6.562-6.562A7.5 7.5 0 117.5 0zm0 1a6.5 6.5 0 100 13 6.5 6.5 0 000-13z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
})

export const CService = createIcon({
  displayName: "Service",
  viewBox: "0 0 20 20",

  path: (
    <path
      d="M7.5 0a7.5 7.5 0 017.107 9.901l4.747 4.745-.708.708-4.457-4.458a7.533 7.533 0 01-3.293 3.293l4.458 4.457-.708.708-4.745-4.747A7.5 7.5 0 01.516 4.76l4.191 4.19L8.95 4.708 4.76.517A7.48 7.48 0 017.5 0zm0 1c-.206 0-.41.01-.613.028l-.182.021 3.659 3.658-5.657 5.657L1.05 6.705l-.02.182c-.01.101-.017.203-.022.305L1 7.5A6.5 6.5 0 107.5 1z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
})

export const CSettings = createIcon({
  displayName: "Settings",
  viewBox: "0 0 20 20",

  path: (
    <path
      d="M12.5 0v2.271c.415.134.813.303 1.195.499l1.609-1.609 3.535 3.535-1.609 1.609c.196.383.365.78.499 1.195H20v5h-2.271a7.975 7.975 0 01-.499 1.195l1.609 1.609-3.535 3.535-1.609-1.609c-.383.196-.78.365-1.195.499V20h-5v-2.271a7.975 7.975 0 01-1.195-.499l-1.609 1.609-3.535-3.535 1.609-1.609a7.975 7.975 0 01-.499-1.195H0v-5h2.271c.134-.415.303-.813.499-1.195L1.161 4.696l3.535-3.535L6.305 2.77c.383-.196.78-.365 1.195-.499V0h5zm-1.214 1H8.714v2.05a7.044 7.044 0 00-2.72 1.126l-1.45-1.45-1.817 1.819 1.449 1.449a7.044 7.044 0 00-1.125 2.72H1v2.572h2.05a7.044 7.044 0 001.126 2.72l-1.45 1.45 1.819 1.817 1.449-1.449a7.044 7.044 0 002.72 1.125V19h2.572v-2.05a7.044 7.044 0 002.72-1.126l1.45 1.45 1.817-1.819-1.449-1.449a7.044 7.044 0 001.125-2.72H19V8.714h-2.05a7.044 7.044 0 00-1.126-2.72l1.45-1.45-1.819-1.817-1.449 1.449a7.044 7.044 0 00-2.72-1.125V1zM10 5a5 5 0 110 10 5 5 0 010-10zm0 1a4 4 0 100 8 4 4 0 000-8z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
})

export const CShare = createIcon({
  displayName: "Share",
  viewBox: "0 0 20 20",

  path: (
    <path
      d="M15 17c0-1.103.897-2 2-2s2 .897 2 2-.897 2-2 2-2-.897-2-2zM1 10c0-1.103.897-2 2-2s2 .897 2 2-.897 2-2 2-2-.897-2-2zm18-7c0 1.103-.897 2-2 2s-2-.897-2-2 .897-2 2-2 2 .897 2 2zm-4.413 12.234l-8.731-4.365C5.941 10.592 6 10.304 6 10c0-.304-.059-.592-.144-.869l8.731-4.365A2.99 2.99 0 0017 6c1.654 0 3-1.346 3-3s-1.346-3-3-3-3 1.346-3 3c0 .304.059.592.143.869l-8.73 4.365A2.99 2.99 0 003 7c-1.654 0-3 1.346-3 3s1.346 3 3 3a2.99 2.99 0 002.413-1.234l8.731 4.365A2.946 2.946 0 0014 17c0 1.654 1.346 3 3 3s3-1.346 3-3-1.346-3-3-3a2.99 2.99 0 00-2.413 1.234z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
})

export const CTechnicalDrawing = createIcon({
  displayName: "TechnicalDrawing",
  viewBox: "0 0 16 16",

  path: (
    <path
      d="M16 0v16H0v-6h10V0h6Zm-3 7h-2v1h2v1h-2v2H9v2H8v-2H7v2H6v-2H5v2H4v-2H3v2H2v-2H1v4h14V1h-4v1h2v1h-2v1h2v1h-2v1h2v1Z"
      fillRule="evenodd"
      fill="currentColor"
    />
  ),
})

export const CTrash = createIcon({
  displayName: "Trash",
  viewBox: "0 0 20 20",

  path: (
    <path
      d="M12 0v2h6v3h-1.001v15h-14V5H2V2h6V0h4zm3.999 5h-12v14h12V5zm-10 2v10H5V7h1zm9 0v10h-1V7h1zM9 7v10H8V7h1zm3 0v10h-1V7h1zm5-3.999h-14v1h14v-1zM11 1H9v1h2V1z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
})

export const CUserLoggedOff = createIcon({
  displayName: "UserLoggedOff",
  viewBox: "0 0 20 20",

  path: (
    <path
      d="M10.603 10c1.335 0 2.532.577 3.398 1.498-.423.136-.627.067-.991.312a3.46 3.46 0 00-1.981-.802L10.802 11H6.001c-1.926 0-3.878 1.818-3.995 3.813L2.001 15v5.003L1 20v-5.002c0-2.68 2.04-4.876 4.588-4.993L5.802 10h4.801zM15 12c2.205 0 4 1.793 4 4s-1.795 4-4 4-4-1.793-4-4 1.795-4 4-4zm0 1c-1.655 0-3 1.345-3 3 0 1.654 1.345 3 3 3 1.654 0 3-1.346 3-3 0-1.655-1.346-3-3-3zM8 0a5 5 0 110 10A5 5 0 018 0zm0 1a4 4 0 100 8 4 4 0 000-8z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
})

export const CView = createIcon({
  displayName: "View",
  viewBox: "0 0 20 20",

  path: (
    <path
      d="M10 4c4.31 0 10 4.812 10 6 0 1.188-5.69 6-10 6S0 11.188 0 10c0-1.188 5.69-6 10-6zm-.23 1.005C5.936 5.178 1.125 9.403 1 10c.128.549 5.138 5 9 5l-.229-.005A5.006 5.006 0 015 10c0-2.68 2.12-4.874 4.77-4.995zm.459 0A5.006 5.006 0 0115 10a5.006 5.006 0 01-4.773 4.995C13.997 14.83 18.792 10.67 19 10c-.212-.683-5.197-5-9-5zM10 6c-2.205 0-4 1.795-4 4s1.795 4 4 4 4-1.795 4-4-1.795-4-4-4z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
})

export const CWarning = createIcon({
  displayName: "Warning",
  viewBox: "0 0 24 24",

  path: <path d="M12 0L0 24h24L12 0zm-1 16h2V9h-2v7zm0 5h2v-3h-2v3z" fill="currentColor" fillRule="evenodd" />,
})

export const CWhatsApp = createIcon({
  displayName: "WhatsApp",
  viewBox: "0 0 16 16",

  path: (
    <path
      d="M15 7.94c0 3.83-3.18 6.94-7 6.94-1.16 0-2.3-.29-3.32-.84L1 15.01l.98-3.59c-.61-1.05-.92-2.25-.92-3.47C1.06 4.11 4.17 1 8 1c1.85 0 3.6.72 4.91 2.03 1.31 1.31 2.1 3.05 2.1 4.91Zm-1.17 0c0-1.54-.67-2.99-1.75-4.08C10.99 2.77 9.55 2.17 8 2.17c-3.18 0-5.77 2.59-5.77 5.77 0 1.09.3 2.15.88 3.07l.14.22-.58 2.13 2.18-.57.21.12c.88.53 1.9.8 2.94.8 3.18 0 5.83-2.59 5.83-5.77ZM11.49 9.6c.04.07.04.42-.1.83s-.83.78-1.17.83c-.55.08-.98.04-2.08-.43-1.74-.75-2.88-2.5-2.96-2.62-.08-.12-.71-.94-.71-1.79s.45-1.27.61-1.45c.16-.17.35-.22.46-.22h.33c.11 0 .25-.04.39.3.14.35.49 1.2.53 1.29.04.09.07.19.02.3-.33.66-.69.64-.51.95.67 1.15 1.34 1.55 2.36 2.06.17.09.28.07.38-.04.1-.12.43-.51.55-.68.12-.18.23-.15.39-.09s1.01.47 1.18.56c.17.08.29.13.33.21Z"
      fill="currentColor"
    />
  ),
})

export const CXlsx = createIcon({
  displayName: "Xlsx",
  viewBox: "0 0 20 20",

  path: (
    <g fill="currentColor" fillRule="nonzero">
      <defs>
        <path d="M11 6H0v11h11V6zm-1 1v9H1V7h9z" id="a" />
      </defs>
      <path d="M13 0H5v6.8h1V1h6v4h4v14H6v-3H5v4h12V4l-4-4zm0 4V1.41L15.59 4H13z" />
      <path d="M4.853 11.367L3.126 9h1.265l1.094 1.597L6.601 9h1.19l-1.696 2.395L8 14H6.735l-1.287-1.82L4.213 14H3z" />
      <use xlinkHref="#a" />
    </g>
  ),
})

export const CZoomIn = createIcon({
  displayName: "CZoomIn",
  viewBox: "0 0 20 20",

  path: (
    <path
      d="M7.5 0a7.5 7.5 0 015.645 12.438L19.707 19l-.707.707-6.562-6.562A7.5 7.5 0 117.5 0zm0 1a6.5 6.5 0 100 13 6.5 6.5 0 000-13zM8 3v4h4v1H8v4H7V8H3V7h4V3h1z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
})

export const CLoading = createIcon({
  displayName: "CLoading",
  viewBox: "0 0 16 16",

  path: <path d="M8,0A8,8,0,1,1,0,8H1A7,7,0,1,0,8,1Z" fill="currentColor" fillRule="evenodd" />,
})

// Embedded icons used in the labels for the User onboarding labels.
export const CustomHelpIcon = `<?xml version="1.0" encoding="UTF-8"?>
<svg width="20px" height="20px" viewBox="0 0 20 20">
<path
d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0zm0 1a9 9 0 100 18 9 9 0 000-18zm1 12v2H9v-2h2zm-.75-8c1.757 0 3.033 1.178 3.033 2.8 0 1.336-.783 1.977-1.158 2.286l-.106.088-.985.89c-.04.036-.084.137-.084.31V12H9.083v-.626c0-.688.25-1.291.701-1.696l1.157-1.036c.296-.243.476-.39.476-.842 0-.689-.63-.933-1.167-.933-.543 0-1.167.158-1.167 1.398H7.217C7.217 6.28 8.407 5 10.25 5z"
fill="currentColor"
fillRule="evenodd"
/>
</svg>`

export const CustomListIcon = `<?xml version="1.0" encoding="UTF-8"?>
<svg width="20px" height="20px" viewBox="0 0 20 20">
<path d="M20 6v14H6V6h14zm-1 1H7v12h12V7zm-5-7v5h-1V1H1v12h4v1H0V0h14z" fill="currentColor" fillRule="evenodd" />
</svg>`

export const CustomClearIcon = `<?xml version="1.0" encoding="UTF-8"?>
<svg width="20px" height="20px" viewBox="0 0 20 20">
<path
fill="currentColor"
d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0zm6.702 3.993L3.497 16.222A9 9 0 0016.703 3.994zM10 1a9 9 0 00-7.154 14.461L15.995 3.287A8.966 8.966 0 0010 1z"
/>
</svg>`

export const CustomXlsxIcon = `<?xml version="1.0" encoding="UTF-8"?>
<svg width="20px" height="20px" viewBox="0 0 20 20">
<defs>
  <path fill="currentColor" d="M11 6H0v11h11V6zm-1 1v9H1V7h9z" />
</defs>
<path fill="currentColor" d="M13 0H5v6.8h1V1h6v4h4v14H6v-3H5v4h12V4l-4-4zm0 4V1.41L15.59 4H13z" />
<path fill="currentColor" d="M4.853 11.367L3.126 9h1.265l1.094 1.597L6.601 9h1.19l-1.696 2.395L8 14H6.735l-1.287-1.82L4.213 14H3z" />
</svg>`

export const CustomBookIcon = `<?xml version="1.0" encoding="UTF-8"?>
<svg width="20px" height="20px" viewBox="0 0 20 20">
<path
d="M18 16.674c-.756-.327-2.888-.674-4-.674-1.116 0-3.244.349-4 .677V2.782C10.499 2.514 12.745 2 14 2c1.249 0 3.495.511 4 .779v13.895zM1 2.779C1.505 2.511 3.751 2 5 2c1.255 0 3.501.514 4 .782v13.895C8.244 16.349 6.116 16 5 16c-1.112 0-3.244.347-4 .674V2.779zm17.801-.678C18.369 1.777 15.664 1 14 1c-1.397 0-3.823.547-4.5.915C8.823 1.547 6.397 1 5 1 3.336 1 .631 1.777.199 2.101L0 2.25v16.251l.801-.601c.243-.183 2.72-.9 4.199-.9 1.479 0 3.956.717 4.199.9l.301.225.301-.225c.243-.183 2.72-.9 4.199-.9 1.479 0 3.956.717 4.199.9l.801.601V2.25l-.199-.149z"
fill="currentColor"
fillRule="evenodd"
/>
</svg>`

export const CustomShareIcon = `<?xml version="1.0" encoding="UTF-8"?>
<svg width="20px" height="20px" viewBox="0 0 20 20">
<path
d="M15 17c0-1.103.897-2 2-2s2 .897 2 2-.897 2-2 2-2-.897-2-2zM1 10c0-1.103.897-2 2-2s2 .897 2 2-.897 2-2 2-2-.897-2-2zm18-7c0 1.103-.897 2-2 2s-2-.897-2-2 .897-2 2-2 2 .897 2 2zm-4.413 12.234l-8.731-4.365C5.941 10.592 6 10.304 6 10c0-.304-.059-.592-.144-.869l8.731-4.365A2.99 2.99 0 0017 6c1.654 0 3-1.346 3-3s-1.346-3-3-3-3 1.346-3 3c0 .304.059.592.143.869l-8.73 4.365A2.99 2.99 0 003 7c-1.654 0-3 1.346-3 3s1.346 3 3 3a2.99 2.99 0 002.413-1.234l8.731 4.365A2.946 2.946 0 0014 17c0 1.654 1.346 3 3 3s3-1.346 3-3-1.346-3-3-3a2.99 2.99 0 00-2.413 1.234z"
fill="currentColor"
fillRule="evenodd"
/>
</svg>`

export const CustomMassImportIcon = `<?xml version="1.0" encoding="UTF-8"?>
<svg width="20px" height="20px" viewBox="0 0 20 20">
<g>
<path fill="currentColor" fillRule="evenodd" d="M9 0v13.329l-3.328-3.328-.708.707L9.5 15.244l4.537-4.536-.708-.707L10 13.329V0z" />
<path fill="currentColor" fillRule="evenodd" d="M6.5 5v1H1v13h17V6h-5.5V5H19v15H0V5z" />
</g>
</svg>`

export const CustomPenIcon = `<?xml version="1.0" encoding="UTF-8"?>
<svg width="20px" height="20px" viewBox="0 0 20 20">
<path
d="M7.025 17.01l-5.303 1.768 1.768-5.303 12.02-12.02 3.536 3.535-12.02 12.02zm-.791-.79L4.28 14.264l-.976 2.931 2.93-.977zM17.632 4.99L15.51 2.868 4.904 13.475l2.121 2.121L17.632 4.99z"
fill="currentColor"
fillRule="evenodd"
/>
</svg>`

export const CustomTrashIcon = `<?xml version="1.0" encoding="UTF-8"?>
<svg width="20px" height="20px" viewBox="0 0 20 20">
<path
d="M12 0v2h6v3h-1.001v15h-14V5H2V2h6V0h4zm3.999 5h-12v14h12V5zm-10 2v10H5V7h1zm9 0v10h-1V7h1zM9 7v10H8V7h1zm3 0v10h-1V7h1zm5-3.999h-14v1h14v-1zM11 1H9v1h2V1z"
fill="currentColor"
fillRule="evenodd"
/>
</svg>`

export const CustomDownloadIcon = `<?xml version="1.0" encoding="UTF-8"?>
<svg width="20px" height="20px" viewBox="0 0 20 20">
<path
d="M0 20h19v-1H0v1zM9 0v13.329l-3.328-3.328-.708.707L9.5 15.244l4.537-4.536-.708-.707L10 13.329V0H9z"
fill="currentColor"
fillRule="evenodd"
/>
</svg>`

export const CustomTenderTextIcon = `<?xml version="1.0" encoding="UTF-8"?>
<svg width="20px" height="20px" viewBox="0 0 20 20">
<g>
<path fill="currentColor" fillRule="evenodd" d="M9 0H1v16h5v-1H2V1h6v4h4v4h1V4L9 0Zm0 4V1.41L11.59 4H9ZM4 9h3v1H4V9Zm0-2h6v1H4V7Zm7 4c1.78 0 3.4 1.42 3.89 2.07-.5.61-2.11 1.93-3.89 1.93s-3.4-1.38-3.89-2c.5-.62 2.11-2 3.89-2m0-1c-2.65 0-5 2.4-5 3s2.35 3 5 3 5-2.32 5-2.92S13.65 10 11 10Z" />
<circle fill="currentColor" fillRule="evenodd" cx="11" cy="13" r="1" />
</g>
</svg>`
