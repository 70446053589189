import { getCookie } from "cookies-next"
import { useRouter } from "next/router"
import { MyList } from "../../api-clients/mylist-api"
import { myListCookie } from "../constants/browser-storage"
import { refreshMyListIdCookie, refreshUserLists, updateUserList } from "../utils/my-list-utils"
import { fetchUpdateList, useDeleteList, useGetList, useUpdateList } from "./queries"
import { useAuth } from "./useGigya"

type UseListReturnTypes = {
  listData: MyList
  updateList: (updateListData: MyList) => void
  patchUpdateList: (updateListData) => void
  deleteList: () => void
  isLoading: boolean
  isOwn: boolean
  isAnonymous: boolean
  error: unknown
}

const useList = (listId: string, initialListData?: MyList): UseListReturnTypes => {
  const router = useRouter()
  const { locale, query } = router
  const brand = query.brand as string
  /* Get authentication data to determine is there a logged in user */
  const { accountInfo } = useAuth()
  const userId = accountInfo?.userId ?? ""
  /* Get stored in cookie myListId to get the anonymous user list */
  const myListIdCookie = (getCookie(myListCookie.KEY) as string) ?? ""

  /* Boolean to control when the list data is fetched from the API */
  const isFetch = !!listId

  /* SWR hook to get the list data, pass it the initial list data fetched on the server to pre-populate the cache */
  const {
    data: listData,
    error: getListError,
    isLoading: isListLoading,
    isValidating: isListValidating,
  } = useGetList(locale, brand, listId, isFetch, userId, initialListData)

  /* SWR hook to update the list data, it is run only when the trigger function is called */
  const {
    trigger: updateListTrigger,
    error: updateListError,
    isMutating: isListUpdating,
  } = useUpdateList(locale, brand, listId, userId)

  const patchUpdateList = async (updateListData: MyList) => {
    try {
      fetchUpdateList(
        locale as string,
        brand,
        listId,
        [
          {
            op: "add",
            path: "/products/-",
            value: updateListData.products?.at(-1),
          },
        ],
        userId
      ).then((res) => {
        refreshMyListIdCookie(res?.id)
        if (userId) refreshUserLists(res, locale, brand, userId)
      })
    } catch (error) {}
  }

  /* SWR hook to delete the list, it is run only when the trigger function is called */
  const {
    trigger: deleteListTrigger,
    error: deleteListError,
    isMutating: isListDeleting,
  } = useDeleteList(locale, brand, listId, userId)

  const updateList = (updateListData: MyList) =>
    updateUserList(updateListTrigger, updateListData, locale, brand, userId)

  const deleteList = () => {
    deleteListTrigger()
  }

  const isLoading = isListLoading || isListValidating || isListUpdating || isListDeleting
  const isOwn = userId ? listData?.userId === userId : listData?.id === myListIdCookie
  const isAnonymous = !listData?.userId
  const error = getListError || updateListError || deleteListError

  return {
    listData,
    updateList,
    patchUpdateList,
    deleteList,
    isLoading,
    isOwn,
    isAnonymous,
    error,
  }
}

export default useList
