import { atom } from "recoil"

// // Boolean to open/close the myList option menu
// export const onboardingMyListMenuOpenState = atom<boolean>({
//   key: "onboardingMyListMenuOpen",
//   default: false,
// })

// Boolean to show if the page is ready to continue the user onboarding
export const onboardingIsPageLoadedState = atom<boolean>({
  key: "onboardingIsPageLoaded",
  default: false,
})

// Boolean to show if the user onboarding should start again after page load
export const onboardingShouldRunState = atom<boolean>({
  key: "onboardingShould",
  default: false,
})

// Boolean to show if the user onboarding is in progress
export const onboardingIsRunningState = atom<boolean>({
  key: "onboardingIsRunning",
  default: false,
})

// Onboarding step (number)
export const onboardingStepIndexState = atom<number>({
  key: "onboardingStepIndex",
  default: 0,
})

// Toggle the focus for the search field
export const onboardingShouldOpenSearchState = atom<boolean>({
  key: "onboardingShouldOpenSearch",
  default: false,
})

// Search term to be inputed in the search field
export const onboardingSearchTermState = atom<string>({
  key: "onboardingSearchTermState",
  default: "",
})

// Suggestions step index
export const suggestionsStepIndexState = atom<number>({
  key: "suggestionsStepIndex",
  default: 0,
})
