const space = {
  px: "1px",
  0.5: "0.125rem",
  1: "0.25rem",
  1.5: "0.375rem",
  2: "0.5rem",
  2.5: "0.625rem",
  3: "0.75rem",
  3.5: "0.875rem",
  4: "1rem",
  4.5: "1.125rem",
  5: "1.25rem",
  6: "1.5rem",
  7: "1.75rem",
  7.5: "1.875rem",
  8: "2rem",
  9: "2.25rem",
  9.5: "2.375rem",
  10: "2.5rem",
  10.5: "2.625rem",
  11: "2.75rem",
  12: "3rem",
  12.5: "3.125rem",
  13: "3.25rem",
  13.5: "3.375rem",
  14: "3.5rem",
  14.5: "3.625rem",
  15: "3.75rem",
  16: "4rem",
  17: "4.25rem",
  18: "4.5rem",
  19: "4.75rem",
  20: "5rem",
  23: "5.75rem",
  24: "6rem",
  25: "6.25rem",
  26: "6.5rem",
  28: "7rem",
  29: "7.25rem",
  30: "7.5rem",
  30.5: "7.625rem",
  31: "7.75rem",
  32: "8rem",
  33.5: "8.375rem",
  34: "8.5rem",
  35: "8.75rem",
  35.5: "8.875rem",
  36: "9rem",
  37: "9.25rem",
  40: "10rem",
  40.5: "10.125rem",
  41: "10.25rem",
  44: "11rem",
  45: "11.25rem",
  46: "11.5rem",
  47: "11.75rem",
  48: "12rem",
  49: "12.25rem",
  50: "12.5rem",
  51: "12.75rem",
  51.5: "12.875rem",
  52: "13rem",
  52.5: "13.125rem",
  53: "13.25rem",
  54: "13.50rem",
  55: "13.75rem",
  56: "14rem",
  59: "14.75rem",
  60: "15rem",
  61: "15.25rem",
  64: "16rem",
  66: "16.5rem",
  68: "17rem",
  70: "17.5rem",
  72: "18rem",
  75: "18.75rem",
  76.5: "19.125rem",
  78: "19.5rem",
  80: "20rem",
  82: "20.50rem",
  85: "21.25rem",
  86: "21.50rem",
  87: "21.75rem",
  87.5: "21.875rem",
  88: "22rem",
  96: "24rem",
  98: "24.5rem",
  100: "25rem",
  101: "25.25rem",
  103: "25.75rem",
  104: "26rem",
  104.5: "26.125rem",
  105: "26.25rem",
  108: "27rem",
  110: "27.5rem",
  113: "28.25rem",
  114: "28.5rem",
  118: "29.5rem",
  120.5: "30.125rem",
  123: "30.75rem",
  124: "31rem",
  125: "31.25rem",
  128: "32rem",
  131.5: "32.875rem",
  132: "33rem",
  134: "33.5rem",
  140: "35rem",
  150: "37.5rem",
  161.5: "40.375rem",
  178: "44.5rem",
  202: "50.5rem",
}

export default space
