import { extendTheme } from "@chakra-ui/react"
import { AktivGroteskGeberit } from "../../styles/fonts"
import Button from "./chakra/components/button"
import Checkbox from "./chakra/components/checkbox"
import CloseButton from "./chakra/components/close-button"
import Container from "./chakra/components/container"
import Divider from "./chakra/components/divider"
import Heading from "./chakra/components/heading"
import Icon from "./chakra/components/icon"
import Input from "./chakra/components/input"
import Link from "./chakra/components/link"
import Menu from "./chakra/components/menu"
import Radio from "./chakra/components/radio"
import Select from "./chakra/components/select"
import Switch from "./chakra/components/switch"
import Table from "./chakra/components/table"
import Tabs from "./chakra/components/tabs"
import Tag from "./chakra/components/tag"
import Textarea from "./chakra/components/textarea"
import borderWidths from "./chakra/foundation/borderWidths"
import breakpoints from "./chakra/foundation/breakpoints"
import colors from "./chakra/foundation/colors"
import brandColors from "./chakra/foundation/colors-brand"
import fontSizes from "./chakra/foundation/font-sizes"
import lineHeights from "./chakra/foundation/line-heights"
import shadows from "./chakra/foundation/shadows"
import sizes from "./chakra/foundation/sizes"
import space from "./chakra/foundation/space"
import zIndices from "./chakra/foundation/zIndices"

// https://chakra-ui.com/docs/theming/customize-theme#scaling-out-your-project
const overrides = {
  styles: {
    // https://chakra-ui.com/docs/styled-system/global-styles
    global: {
      ":root": {
        "--chakra-opacity-disabled": "0.4",
      },

      body: {
        /* Set the default fontColor for all elements */
        color: "black",

        /* Offset the underline for all elements to match GDDS designs, value is in px */
        textUnderlineOffset: 4,
      },

      /* Special utility class for the Google ReCaptcha badge */
      ".grecaptcha-badge": {
        opacity: 0,
        zIndex: -100,
      },

      /* Gigya style fixes */
      ".gigya-style-modern div.gigya-screen-dialog-close>a>img": {
        display: "inline-block",
        width: "30px !important",
        height: "30px !important",
      },
      ".gigya-screen": {
        paddingTop: "0px !important",
      },
      ".gigya-screen.geberit-header-uppercase-bold": {
        height: "auto !important",
      },
    },
  },
  zIndices,
  colors,
  fontSizes,
  lineHeights,
  breakpoints,
  space,
  borderWidths,
  sizes,
  shadows,
  fonts: {
    heading: AktivGroteskGeberit.style.fontFamily,
    body: AktivGroteskGeberit.style.fontFamily,
  },
  components: {
    Button,
    CloseButton,
    Container,
    Divider,
    Textarea,
    Heading,
    Input,
    Link,
    Menu,
    Select,
    Table,
    Tabs,
    Tag,
    Checkbox,
    Radio,
    Switch,
    Icon,
  },
}

export const getBrandedTheme = (brand: string) => {
  return extendTheme({ ...overrides, colors: { ...overrides.colors, brand: brandColors[brand] } })
}
