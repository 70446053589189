/* tslint:disable */
/* eslint-disable */
/**
 * BIM Product Data Service
 * An API for Geberit BIM Product Data which delivers the product specific information which will be used in Geberit BIM Plug-in. It is used to query the category structure, products under certain category, product specific information json, versions of products, uri to download a specific product.
 *
 * The version of the OpenAPI document: v1
 * Contact: info@ekkodale.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export * from "./api"
export * from "./configuration"
