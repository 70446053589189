/* tslint:disable */
/* eslint-disable */
/**
 * PDF Request
 * Use the PDF Request API to obtain product data sheets (PDF) as well as catalogs and sample catalogs (PDF) for the Geberit online catalog. While this API is the consumer-facing service for acquiring the mentioned PDFs, our PDF HTML API and PDF Generation API are used to generate the actual PDFs and their content.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export * from "./api"
export * from "./configuration"
