/* tslint:disable */
/* eslint-disable */
/**
 * Product availability
 * Use the Availability API to check the availability of products, articles, categories, and spare parts tables. You can also specify an [assortment](/glossary), brand, and availability group to customize your availability check.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { AxiosInstance, AxiosPromise, AxiosRequestConfig } from "axios"
import globalAxios from "axios"
import type { Configuration } from "./configuration"
// Some imports not used depending on template conditions
// @ts-ignore
import type { RequestArgs } from "./base"
import { DUMMY_BASE_URL, assertParamExists, createRequestFunction, setSearchParams, toPathString } from "./common"
// @ts-ignore
import { BASE_PATH, BaseAPI, COLLECTION_FORMATS, RequiredError } from "./base"

/**
 * Contains information about an article\'s availability.
 * @export
 * @interface Article
 */
export interface Article {
  /**
   * The object id of the requested object.
   * @type {string}
   * @memberof Article
   */
  id?: string
  /**
   * The category key, spare parts table key, product key or article number of the requested object.
   * @type {string}
   * @memberof Article
   */
  key?: string
  /**
   * An `array` of `Assortment` objects.
   * @type {Array<Assortment>}
   * @memberof Article
   */
  assortments?: Array<Assortment>
}
/**
 * Contains information about an object\'s availability for a specific assortment. Can also contain the applied availability rules.
 * @export
 * @interface Assortment
 */
export interface Assortment {
  /**
   * The assortment\'s locale.
   * @type {string}
   * @memberof Assortment
   */
  localization?: string
  /**
   * The specified availability start date.
   * @type {string}
   * @memberof Assortment
   */
  from?: string
  /**
   * The indicator whether an object has a special state. Can be `NEW` or `PHASE_OUT`. Note that depending on your availability group, for example, you may never receive objects with the `PHASE_OUT` tag.
   * @type {string}
   * @memberof Assortment
   */
  indicator?: string
  /**
   * A list of all applied availability rules and their results.
   * @type {Array<string>}
   * @memberof Assortment
   */
  rules?: Array<string>
}
/**
 * An `array` of `AssortmentsForBrand` objects.
 * @export
 * @interface AssortmentsForBrand
 */
export interface AssortmentsForBrand {
  /**
   * The name of the brand
   * @type {string}
   * @memberof AssortmentsForBrand
   */
  name?: string
  /**
   * An `array` of `Assortment` objects.
   * @type {Array<Assortment>}
   * @memberof AssortmentsForBrand
   */
  assortments?: Array<Assortment>
}
/**
 * Contains a list of the locales supported by our services.
 * @export
 * @interface Catalogs
 */
export interface Catalogs {
  /**
   * An `array` of `Language` objects.
   * @type {Array<Language>}
   * @memberof Catalogs
   */
  assortments?: Array<Language>
}
/**
 * Contains information about the availability of a category. If the category is a direct parent of products and spare parts, the object also includes information on the availability of these child objects.
 * @export
 * @interface Category
 */
export interface Category {
  /**
   * The object id of the requested object.
   * @type {string}
   * @memberof Category
   */
  id?: string
  /**
   * The category key, spare parts table key, product key or article number of the requested object.
   * @type {string}
   * @memberof Category
   */
  key?: string
  /**
   * An `array` of `Assortment` objects.
   * @type {Array<Assortment>}
   * @memberof Category
   */
  assortments?: Array<Assortment>
  /**
   * An `array` of `Product` objects. Contains information about the availability of the category\'s products.
   * @type {Array<Product>}
   * @memberof Category
   */
  products?: Array<Product>
  /**
   * An `array` of `sparePartsTable` objects. Contains information about the availability of the category\'s spare parts tables.
   * @type {Array<SparePartsTable>}
   * @memberof Category
   */
  spareParts?: Array<SparePartsTable>
}
/**
 * Contains information about an error.
 * @export
 * @interface ErrorMessage
 */
export interface ErrorMessage {
  /**
   * A brief, human-readable description of the error.
   * @type {string}
   * @memberof ErrorMessage
   */
  message?: string
  /**
   * The HTTP status code returned by the service.
   * @type {number}
   * @memberof ErrorMessage
   */
  status?: number
}
/**
 * Contains the query rules for checking whether an attribute exists.
 * @export
 * @interface ExistsQueryFilter
 */
export interface ExistsQueryFilter extends QueryFilter {
  /**
   * The name of the attribute to which the query rule applies.
   * @type {string}
   * @memberof ExistsQueryFilter
   */
  attributeName?: string
  /**
   * The query rule to apply.
   * @type {string}
   * @memberof ExistsQueryFilter
   */
  queryRule?: ExistsQueryFilterQueryRuleEnum
}

export const ExistsQueryFilterQueryRuleEnum = {
  IsIn: "IsIn",
  IsNotIn: "IsNotIn",
  Range: "Range",
  Exists: "Exists",
} as const

export type ExistsQueryFilterQueryRuleEnum =
  (typeof ExistsQueryFilterQueryRuleEnum)[keyof typeof ExistsQueryFilterQueryRuleEnum]

/**
 *
 * @export
 * @interface ExistsQueryFilterAllOf
 */
export interface ExistsQueryFilterAllOf {
  /**
   * The name of the attribute to which the query rule applies.
   * @type {string}
   * @memberof ExistsQueryFilterAllOf
   */
  attributeName?: string
  /**
   * The query rule to apply.
   * @type {string}
   * @memberof ExistsQueryFilterAllOf
   */
  queryRule?: ExistsQueryFilterAllOfQueryRuleEnum
}

export const ExistsQueryFilterAllOfQueryRuleEnum = {
  IsIn: "IsIn",
  IsNotIn: "IsNotIn",
  Range: "Range",
  Exists: "Exists",
} as const

export type ExistsQueryFilterAllOfQueryRuleEnum =
  (typeof ExistsQueryFilterAllOfQueryRuleEnum)[keyof typeof ExistsQueryFilterAllOfQueryRuleEnum]

/**
 * Contains information about an article\'s availability across all locales and brand.
 * @export
 * @interface GlobalArticle
 */
export interface GlobalArticle {
  /**
   * The object id of the requested object.
   * @type {string}
   * @memberof GlobalArticle
   */
  id?: string
  /**
   * The category key, spare parts table key, product key or article number of the requested object.
   * @type {string}
   * @memberof GlobalArticle
   */
  key?: string
  /**
   * An `array` of `AssortmentsForBrand` objects.
   * @type {Array<AssortmentsForBrand>}
   * @memberof GlobalArticle
   */
  brands?: Array<AssortmentsForBrand>
}
/**
 * Contains the query rules for checking whether an attribute is part of a specified value list.
 * @export
 * @interface IsInQueryFilter
 */
export interface IsInQueryFilter extends QueryFilter {
  /**
   * The expected value that goes with the query rule.
   * @type {Array<string>}
   * @memberof IsInQueryFilter
   */
  values?: Array<string>
}

/**
 *
 * @export
 * @interface IsInQueryFilterAllOf
 */
export interface IsInQueryFilterAllOf {
  /**
   * The expected value that goes with the query rule.
   * @type {Array<string>}
   * @memberof IsInQueryFilterAllOf
   */
  values?: Array<string>
}
/**
 * Contains the query rule for checking whether an attribute is not part of a specified value list.
 * @export
 * @interface IsNotInQueryFilter
 */
export interface IsNotInQueryFilter extends QueryFilter {
  /**
   * The query rule to apply.
   * @type {string}
   * @memberof IsNotInQueryFilter
   */
  queryRule?: IsNotInQueryFilterQueryRuleEnum
  /**
   * The expected value that goes with the query rule.
   * @type {Array<string>}
   * @memberof IsNotInQueryFilter
   */
  values?: Array<string>
}

export const IsNotInQueryFilterQueryRuleEnum = {
  IsIn: "IsIn",
  IsNotIn: "IsNotIn",
  Range: "Range",
  Exists: "Exists",
} as const

export type IsNotInQueryFilterQueryRuleEnum =
  (typeof IsNotInQueryFilterQueryRuleEnum)[keyof typeof IsNotInQueryFilterQueryRuleEnum]

/**
 *
 * @export
 * @interface IsNotInQueryFilterAllOf
 */
export interface IsNotInQueryFilterAllOf {
  /**
   * The query rule to apply.
   * @type {string}
   * @memberof IsNotInQueryFilterAllOf
   */
  queryRule?: IsNotInQueryFilterAllOfQueryRuleEnum
  /**
   * The expected value that goes with the query rule.
   * @type {Array<string>}
   * @memberof IsNotInQueryFilterAllOf
   */
  values?: Array<string>
}

export const IsNotInQueryFilterAllOfQueryRuleEnum = {
  IsIn: "IsIn",
  IsNotIn: "IsNotIn",
  Range: "Range",
  Exists: "Exists",
} as const

export type IsNotInQueryFilterAllOfQueryRuleEnum =
  (typeof IsNotInQueryFilterAllOfQueryRuleEnum)[keyof typeof IsNotInQueryFilterAllOfQueryRuleEnum]

/**
 * Contains a list of locales.
 * @export
 * @interface Language
 */
export interface Language {
  /**
   * The locale.
   * @type {string}
   * @memberof Language
   */
  localization?: string
}
/**
 * Contains a collection of `Product` objects and the pagination data.
 * @export
 * @interface PagedProducts
 */
export interface PagedProducts {
  /**
   * The total number of objects that match your request, regardless of pagination.
   * @type {number}
   * @memberof PagedProducts
   */
  total?: number
  /**
   * The number of returned objects.
   * @type {number}
   * @memberof PagedProducts
   */
  count?: number
  /**
   * The number of skipped objects as specified in your request.
   * @type {number}
   * @memberof PagedProducts
   */
  offset?: number
  /**
   * An array of the requested objects.
   * @type {Array<Product>}
   * @memberof PagedProducts
   */
  results?: Array<Product>
}
/**
 * Contains a collection of `SparePartsTable` objects and the pagination data.
 * @export
 * @interface PagedSparePartsTables
 */
export interface PagedSparePartsTables {
  /**
   * The total number of objects that match your request, regardless of pagination.
   * @type {number}
   * @memberof PagedSparePartsTables
   */
  total?: number
  /**
   * The number of returned objects.
   * @type {number}
   * @memberof PagedSparePartsTables
   */
  count?: number
  /**
   * The number of skipped objects as specified in your request.
   * @type {number}
   * @memberof PagedSparePartsTables
   */
  offset?: number
  /**
   * An array of the requested objects.
   * @type {Array<SparePartsTable>}
   * @memberof PagedSparePartsTables
   */
  results?: Array<SparePartsTable>
}
/**
 * Contains information about the availability of a product and its articles.
 * @export
 * @interface Product
 */
export interface Product {
  /**
   * The object id of the requested object.
   * @type {string}
   * @memberof Product
   */
  id?: string
  /**
   * The category key, spare parts table key, product key or article number of the requested object.
   * @type {string}
   * @memberof Product
   */
  key?: string
  /**
   * An `array` of `Assortment` objects.
   * @type {Array<Assortment>}
   * @memberof Product
   */
  assortments?: Array<Assortment>
  /**
   * An `array` of `Article` objects. Contains information about the availability of the product\'s articles.
   * @type {Array<Article>}
   * @memberof Product
   */
  articles?: Array<Article>
}
/**
 * Contains the requested availability rules.
 * @export
 * @interface QueryBuilderResponse
 */
export interface QueryBuilderResponse {
  /**
   * The assortment for which you requested the availability rules.
   * @type {string}
   * @memberof QueryBuilderResponse
   */
  assortment?: string
  /**
   * An `array` of `QueryFilter` objects. Contains query rules for product.
   * @type {Array<QueryBuilderResponseProductFiltersInner>}
   * @memberof QueryBuilderResponse
   */
  productFilters?: Array<QueryBuilderResponseProductFiltersInner>
  /**
   * An `array` of `QueryFilter` objects. Contains query rules for master variant.
   * @type {Array<QueryBuilderResponseProductFiltersInner>}
   * @memberof QueryBuilderResponse
   */
  masterVariantFilters?: Array<QueryBuilderResponseProductFiltersInner>
  /**
   * An `array` of `QueryFilter` objects. Contains query rules for variants.
   * @type {Array<QueryBuilderResponseProductFiltersInner>}
   * @memberof QueryBuilderResponse
   */
  variantsFilters?: Array<QueryBuilderResponseProductFiltersInner>
}
/**
 * @type QueryBuilderResponseProductFiltersInner
 * @export
 */
export type QueryBuilderResponseProductFiltersInner =
  | ExistsQueryFilter
  | IsInQueryFilter
  | IsNotInQueryFilter
  | RangeQueryFilter

/**
 * Contains the query rules.
 * @export
 * @interface QueryFilter
 */
export interface QueryFilter {
  /**
   * The name of the attribute to which the query rule applies.
   * @type {string}
   * @memberof QueryFilter
   */
  attributeName?: string
  /**
   * The query rule to apply.
   * @type {string}
   * @memberof QueryFilter
   */
  queryRule: QueryFilterQueryRuleEnum
}

export const QueryFilterQueryRuleEnum = {
  IsIn: "IsIn",
  IsNotIn: "IsNotIn",
  Range: "Range",
  Exists: "Exists",
} as const

export type QueryFilterQueryRuleEnum = (typeof QueryFilterQueryRuleEnum)[keyof typeof QueryFilterQueryRuleEnum]

/**
 * Contains the query rule for checking whether an attribute is within a specified range.
 * @export
 * @interface RangeQueryFilter
 */
export interface RangeQueryFilter extends QueryFilter {
  /**
   * The name of the attribute to which the query rule applies.
   * @type {string}
   * @memberof RangeQueryFilter
   */
  attributeName?: string
  /**
   * The query rule to apply.
   * @type {string}
   * @memberof RangeQueryFilter
   */
  queryRule?: RangeQueryFilterQueryRuleEnum
  /**
   * Range starting date.
   * @type {string}
   * @memberof RangeQueryFilter
   */
  rangeFrom?: string
  /**
   * Range end date.
   * @type {string}
   * @memberof RangeQueryFilter
   */
  rangeTo?: string
}

export const RangeQueryFilterQueryRuleEnum = {
  IsIn: "IsIn",
  IsNotIn: "IsNotIn",
  Range: "Range",
  Exists: "Exists",
} as const

export type RangeQueryFilterQueryRuleEnum =
  (typeof RangeQueryFilterQueryRuleEnum)[keyof typeof RangeQueryFilterQueryRuleEnum]

/**
 *
 * @export
 * @interface RangeQueryFilterAllOf
 */
export interface RangeQueryFilterAllOf {
  /**
   * The name of the attribute to which the query rule applies.
   * @type {string}
   * @memberof RangeQueryFilterAllOf
   */
  attributeName?: string
  /**
   * The query rule to apply.
   * @type {string}
   * @memberof RangeQueryFilterAllOf
   */
  queryRule?: RangeQueryFilterAllOfQueryRuleEnum
  /**
   * Range starting date.
   * @type {string}
   * @memberof RangeQueryFilterAllOf
   */
  rangeFrom?: string
  /**
   * Range end date.
   * @type {string}
   * @memberof RangeQueryFilterAllOf
   */
  rangeTo?: string
}

export const RangeQueryFilterAllOfQueryRuleEnum = {
  IsIn: "IsIn",
  IsNotIn: "IsNotIn",
  Range: "Range",
  Exists: "Exists",
} as const

export type RangeQueryFilterAllOfQueryRuleEnum =
  (typeof RangeQueryFilterAllOfQueryRuleEnum)[keyof typeof RangeQueryFilterAllOfQueryRuleEnum]

/**
 * Contains information about the availability of a spare parts table, its individual spare parts articles, and the articles to which the spare parts table is assigned.
 * @export
 * @interface SparePartsTable
 */
export interface SparePartsTable {
  /**
   * The object id of the requested object.
   * @type {string}
   * @memberof SparePartsTable
   */
  id?: string
  /**
   * The category key, spare parts table key, product key or article number of the requested object.
   * @type {string}
   * @memberof SparePartsTable
   */
  key?: string
  /**
   * An `array` of `Assortment` objects.
   * @type {Array<Assortment>}
   * @memberof SparePartsTable
   */
  assortments?: Array<Assortment>
  /**
   * An `array` of `Article` objects. Contains information about the availability of the articles to which the spare parts table is assigned.
   * @type {Array<Article>}
   * @memberof SparePartsTable
   */
  mainArticles?: Array<Article>
  /**
   * An `array` of `Article` objects. Contains information about the availability of the individual spare parts table articles.
   * @type {Array<Article>}
   * @memberof SparePartsTable
   */
  sparePartArticles?: Array<Article>
}

/**
 * ArticleAvailabilityApi - axios parameter creator
 * @export
 */
export const ArticleAvailabilityApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Use an article\'s number to retrieve a list of assortments in which the given article is available.
     * @summary Get article availability for brand Geberit.
     * @param {string} sku The number of the article whose availability you want to check.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getArticleAvailability: async (
      sku: string,
      xAvailabilityGroupKey?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'sku' is not null or undefined
      assertParamExists("getArticleAvailability", "sku", sku)
      const localVarPath = `/articles/{sku}`.replace(`{${"sku"}}`, encodeURIComponent(String(sku)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (xAvailabilityGroupKey != null) {
        localVarHeaderParameter["X-Availability-Group-Key"] = String(xAvailabilityGroupKey)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Use an article\'s number to retrieve a list of assortments in which the given article is available.
     * @summary Get article availability across all locales and brands.
     * @param {string} sku The number of the article whose availability you want to check.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getArticleAvailabilityAnyBrand: async (
      sku: string,
      xAvailabilityGroupKey?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'sku' is not null or undefined
      assertParamExists("getArticleAvailabilityAnyBrand", "sku", sku)
      const localVarPath = `/articles/{sku}/any`.replace(`{${"sku"}}`, encodeURIComponent(String(sku)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (xAvailabilityGroupKey != null) {
        localVarHeaderParameter["X-Availability-Group-Key"] = String(xAvailabilityGroupKey)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Use an article shortcode and a brand to retrieve availability information of the given article.
     * @summary Get article availability for shortcode and brand.
     * @param {string} brand The brand for which you want to check a article\&#39;s availability. The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {string} artcode The article\&#39;s shortcode whose availability you want to check.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getArticleAvailabilityByShorCodeAndBrand: async (
      brand: string,
      artcode: string,
      xAvailabilityGroupKey?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("getArticleAvailabilityByShorCodeAndBrand", "brand", brand)
      // verify required parameter 'artcode' is not null or undefined
      assertParamExists("getArticleAvailabilityByShorCodeAndBrand", "artcode", artcode)
      const localVarPath = `/{brand}/articlecode/{artcode}`
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
        .replace(`{${"artcode"}}`, encodeURIComponent(String(artcode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (xAvailabilityGroupKey != null) {
        localVarHeaderParameter["X-Availability-Group-Key"] = String(xAvailabilityGroupKey)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Use an article\'s shortcode to retrieve a list of assortments in which the given article is available.
     * @summary Get article availability for shortcode and brand Geberit.
     * @param {string} artcode The article\&#39;s shortcode whose availability you want to check.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getArticleAvailabilityByShortCode: async (
      artcode: string,
      xAvailabilityGroupKey?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'artcode' is not null or undefined
      assertParamExists("getArticleAvailabilityByShortCode", "artcode", artcode)
      const localVarPath = `/articlecode/{artcode}`.replace(`{${"artcode"}}`, encodeURIComponent(String(artcode)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (xAvailabilityGroupKey != null) {
        localVarHeaderParameter["X-Availability-Group-Key"] = String(xAvailabilityGroupKey)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Use an article number, assortment, and brand to retrieve availability information of the given article.
     * @summary Get article availability for assortment and brand.
     * @param {string} assortment The assortment for which you want to check an article\&#39;s availability. You can get a list of supported assortments using the &#x60;/catalogs&#x60; endpoint.
     * @param {string} brand The brand for which you want to check an article\&#39;s availability. The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {string} sku The number of the article whose availability you want to check.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getArticleAvailabilityForBrandAndLocale: async (
      assortment: string,
      brand: string,
      sku: string,
      xAvailabilityGroupKey?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'assortment' is not null or undefined
      assertParamExists("getArticleAvailabilityForBrandAndLocale", "assortment", assortment)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("getArticleAvailabilityForBrandAndLocale", "brand", brand)
      // verify required parameter 'sku' is not null or undefined
      assertParamExists("getArticleAvailabilityForBrandAndLocale", "sku", sku)
      const localVarPath = `/{assortment}/{brand}/articles/{sku}`
        .replace(`{${"assortment"}}`, encodeURIComponent(String(assortment)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
        .replace(`{${"sku"}}`, encodeURIComponent(String(sku)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (xAvailabilityGroupKey != null) {
        localVarHeaderParameter["X-Availability-Group-Key"] = String(xAvailabilityGroupKey)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Use an article number and an assortment (or brand) to retrieve availability information of the given article.  Note the following: - If you define an assortment, the request will be made for the default brand `geberit`. - If you define a brand, the request will return availability information for all assortments.
     * @summary Get article availability for assortment or brand.
     * @param {string} assortmentOrBrand The brand or assortment for which you want to check a article\&#39;s availability.  The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {string} sku The number of the article whose availability you want to check.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getArticleAvailabilityWithOneFilter: async (
      assortmentOrBrand: string,
      sku: string,
      xAvailabilityGroupKey?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'assortmentOrBrand' is not null or undefined
      assertParamExists("getArticleAvailabilityWithOneFilter", "assortmentOrBrand", assortmentOrBrand)
      // verify required parameter 'sku' is not null or undefined
      assertParamExists("getArticleAvailabilityWithOneFilter", "sku", sku)
      const localVarPath = `/{assortmentOrBrand}/articles/{sku}`
        .replace(`{${"assortmentOrBrand"}}`, encodeURIComponent(String(assortmentOrBrand)))
        .replace(`{${"sku"}}`, encodeURIComponent(String(sku)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (xAvailabilityGroupKey != null) {
        localVarHeaderParameter["X-Availability-Group-Key"] = String(xAvailabilityGroupKey)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Use an article number to retrieve a list of all assortments and applied availability logic rules that determine why the article is available or not.
     * @summary Get article availability and the applied availability rules for brand Geberit.
     * @param {string} sku The number of the article whose availability you want to check.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getArticleAvailabilityWithRules: async (
      sku: string,
      xAvailabilityGroupKey?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'sku' is not null or undefined
      assertParamExists("getArticleAvailabilityWithRules", "sku", sku)
      const localVarPath = `/articles/{sku}/rules`.replace(`{${"sku"}}`, encodeURIComponent(String(sku)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (xAvailabilityGroupKey != null) {
        localVarHeaderParameter["X-Availability-Group-Key"] = String(xAvailabilityGroupKey)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Use an article number and brand to retrieve a list of all assortments and applied availability logic rules that determine why the article is available or not.
     * @summary Get article availability and the applied availability rules for a brand.
     * @param {string} brand The brand for which you want to check an article\&#39;s availability. The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {string} sku The number of the article whose availability you want to check.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getArticleAvailabilityWithRulesForBrand: async (
      brand: string,
      sku: string,
      xAvailabilityGroupKey?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("getArticleAvailabilityWithRulesForBrand", "brand", brand)
      // verify required parameter 'sku' is not null or undefined
      assertParamExists("getArticleAvailabilityWithRulesForBrand", "sku", sku)
      const localVarPath = `/{brand}/articles/{sku}/rules`
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
        .replace(`{${"sku"}}`, encodeURIComponent(String(sku)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (xAvailabilityGroupKey != null) {
        localVarHeaderParameter["X-Availability-Group-Key"] = String(xAvailabilityGroupKey)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ArticleAvailabilityApi - functional programming interface
 * @export
 */
export const ArticleAvailabilityApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ArticleAvailabilityApiAxiosParamCreator(configuration)
  return {
    /**
     * Use an article\'s number to retrieve a list of assortments in which the given article is available.
     * @summary Get article availability for brand Geberit.
     * @param {string} sku The number of the article whose availability you want to check.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getArticleAvailability(
      sku: string,
      xAvailabilityGroupKey?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Article>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getArticleAvailability(
        sku,
        xAvailabilityGroupKey,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Use an article\'s number to retrieve a list of assortments in which the given article is available.
     * @summary Get article availability across all locales and brands.
     * @param {string} sku The number of the article whose availability you want to check.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getArticleAvailabilityAnyBrand(
      sku: string,
      xAvailabilityGroupKey?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GlobalArticle>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getArticleAvailabilityAnyBrand(
        sku,
        xAvailabilityGroupKey,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Use an article shortcode and a brand to retrieve availability information of the given article.
     * @summary Get article availability for shortcode and brand.
     * @param {string} brand The brand for which you want to check a article\&#39;s availability. The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {string} artcode The article\&#39;s shortcode whose availability you want to check.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getArticleAvailabilityByShorCodeAndBrand(
      brand: string,
      artcode: string,
      xAvailabilityGroupKey?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Article>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getArticleAvailabilityByShorCodeAndBrand(
        brand,
        artcode,
        xAvailabilityGroupKey,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Use an article\'s shortcode to retrieve a list of assortments in which the given article is available.
     * @summary Get article availability for shortcode and brand Geberit.
     * @param {string} artcode The article\&#39;s shortcode whose availability you want to check.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getArticleAvailabilityByShortCode(
      artcode: string,
      xAvailabilityGroupKey?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Article>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getArticleAvailabilityByShortCode(
        artcode,
        xAvailabilityGroupKey,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Use an article number, assortment, and brand to retrieve availability information of the given article.
     * @summary Get article availability for assortment and brand.
     * @param {string} assortment The assortment for which you want to check an article\&#39;s availability. You can get a list of supported assortments using the &#x60;/catalogs&#x60; endpoint.
     * @param {string} brand The brand for which you want to check an article\&#39;s availability. The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {string} sku The number of the article whose availability you want to check.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getArticleAvailabilityForBrandAndLocale(
      assortment: string,
      brand: string,
      sku: string,
      xAvailabilityGroupKey?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Article>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getArticleAvailabilityForBrandAndLocale(
        assortment,
        brand,
        sku,
        xAvailabilityGroupKey,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Use an article number and an assortment (or brand) to retrieve availability information of the given article.  Note the following: - If you define an assortment, the request will be made for the default brand `geberit`. - If you define a brand, the request will return availability information for all assortments.
     * @summary Get article availability for assortment or brand.
     * @param {string} assortmentOrBrand The brand or assortment for which you want to check a article\&#39;s availability.  The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {string} sku The number of the article whose availability you want to check.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getArticleAvailabilityWithOneFilter(
      assortmentOrBrand: string,
      sku: string,
      xAvailabilityGroupKey?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Article>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getArticleAvailabilityWithOneFilter(
        assortmentOrBrand,
        sku,
        xAvailabilityGroupKey,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Use an article number to retrieve a list of all assortments and applied availability logic rules that determine why the article is available or not.
     * @summary Get article availability and the applied availability rules for brand Geberit.
     * @param {string} sku The number of the article whose availability you want to check.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getArticleAvailabilityWithRules(
      sku: string,
      xAvailabilityGroupKey?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Article>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getArticleAvailabilityWithRules(
        sku,
        xAvailabilityGroupKey,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Use an article number and brand to retrieve a list of all assortments and applied availability logic rules that determine why the article is available or not.
     * @summary Get article availability and the applied availability rules for a brand.
     * @param {string} brand The brand for which you want to check an article\&#39;s availability. The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {string} sku The number of the article whose availability you want to check.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getArticleAvailabilityWithRulesForBrand(
      brand: string,
      sku: string,
      xAvailabilityGroupKey?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Article>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getArticleAvailabilityWithRulesForBrand(
        brand,
        sku,
        xAvailabilityGroupKey,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * ArticleAvailabilityApi - factory interface
 * @export
 */
export const ArticleAvailabilityApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ArticleAvailabilityApiFp(configuration)
  return {
    /**
     * Use an article\'s number to retrieve a list of assortments in which the given article is available.
     * @summary Get article availability for brand Geberit.
     * @param {ArticleAvailabilityApiGetArticleAvailabilityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getArticleAvailability(
      requestParameters: ArticleAvailabilityApiGetArticleAvailabilityRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<Article> {
      return localVarFp
        .getArticleAvailability(requestParameters.sku, requestParameters.xAvailabilityGroupKey, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Use an article\'s number to retrieve a list of assortments in which the given article is available.
     * @summary Get article availability across all locales and brands.
     * @param {ArticleAvailabilityApiGetArticleAvailabilityAnyBrandRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getArticleAvailabilityAnyBrand(
      requestParameters: ArticleAvailabilityApiGetArticleAvailabilityAnyBrandRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<GlobalArticle> {
      return localVarFp
        .getArticleAvailabilityAnyBrand(requestParameters.sku, requestParameters.xAvailabilityGroupKey, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Use an article shortcode and a brand to retrieve availability information of the given article.
     * @summary Get article availability for shortcode and brand.
     * @param {ArticleAvailabilityApiGetArticleAvailabilityByShorCodeAndBrandRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getArticleAvailabilityByShorCodeAndBrand(
      requestParameters: ArticleAvailabilityApiGetArticleAvailabilityByShorCodeAndBrandRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<Article> {
      return localVarFp
        .getArticleAvailabilityByShorCodeAndBrand(
          requestParameters.brand,
          requestParameters.artcode,
          requestParameters.xAvailabilityGroupKey,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Use an article\'s shortcode to retrieve a list of assortments in which the given article is available.
     * @summary Get article availability for shortcode and brand Geberit.
     * @param {ArticleAvailabilityApiGetArticleAvailabilityByShortCodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getArticleAvailabilityByShortCode(
      requestParameters: ArticleAvailabilityApiGetArticleAvailabilityByShortCodeRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<Article> {
      return localVarFp
        .getArticleAvailabilityByShortCode(requestParameters.artcode, requestParameters.xAvailabilityGroupKey, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Use an article number, assortment, and brand to retrieve availability information of the given article.
     * @summary Get article availability for assortment and brand.
     * @param {ArticleAvailabilityApiGetArticleAvailabilityForBrandAndLocaleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getArticleAvailabilityForBrandAndLocale(
      requestParameters: ArticleAvailabilityApiGetArticleAvailabilityForBrandAndLocaleRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<Article> {
      return localVarFp
        .getArticleAvailabilityForBrandAndLocale(
          requestParameters.assortment,
          requestParameters.brand,
          requestParameters.sku,
          requestParameters.xAvailabilityGroupKey,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Use an article number and an assortment (or brand) to retrieve availability information of the given article.  Note the following: - If you define an assortment, the request will be made for the default brand `geberit`. - If you define a brand, the request will return availability information for all assortments.
     * @summary Get article availability for assortment or brand.
     * @param {ArticleAvailabilityApiGetArticleAvailabilityWithOneFilterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getArticleAvailabilityWithOneFilter(
      requestParameters: ArticleAvailabilityApiGetArticleAvailabilityWithOneFilterRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<Article> {
      return localVarFp
        .getArticleAvailabilityWithOneFilter(
          requestParameters.assortmentOrBrand,
          requestParameters.sku,
          requestParameters.xAvailabilityGroupKey,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Use an article number to retrieve a list of all assortments and applied availability logic rules that determine why the article is available or not.
     * @summary Get article availability and the applied availability rules for brand Geberit.
     * @param {ArticleAvailabilityApiGetArticleAvailabilityWithRulesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getArticleAvailabilityWithRules(
      requestParameters: ArticleAvailabilityApiGetArticleAvailabilityWithRulesRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<Article> {
      return localVarFp
        .getArticleAvailabilityWithRules(requestParameters.sku, requestParameters.xAvailabilityGroupKey, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Use an article number and brand to retrieve a list of all assortments and applied availability logic rules that determine why the article is available or not.
     * @summary Get article availability and the applied availability rules for a brand.
     * @param {ArticleAvailabilityApiGetArticleAvailabilityWithRulesForBrandRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getArticleAvailabilityWithRulesForBrand(
      requestParameters: ArticleAvailabilityApiGetArticleAvailabilityWithRulesForBrandRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<Article> {
      return localVarFp
        .getArticleAvailabilityWithRulesForBrand(
          requestParameters.brand,
          requestParameters.sku,
          requestParameters.xAvailabilityGroupKey,
          options
        )
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for getArticleAvailability operation in ArticleAvailabilityApi.
 * @export
 * @interface ArticleAvailabilityApiGetArticleAvailabilityRequest
 */
export interface ArticleAvailabilityApiGetArticleAvailabilityRequest {
  /**
   * The number of the article whose availability you want to check.
   * @type {string}
   * @memberof ArticleAvailabilityApiGetArticleAvailability
   */
  readonly sku: string

  /**
   * The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
   * @type {string}
   * @memberof ArticleAvailabilityApiGetArticleAvailability
   */
  readonly xAvailabilityGroupKey?: string
}

/**
 * Request parameters for getArticleAvailabilityAnyBrand operation in ArticleAvailabilityApi.
 * @export
 * @interface ArticleAvailabilityApiGetArticleAvailabilityAnyBrandRequest
 */
export interface ArticleAvailabilityApiGetArticleAvailabilityAnyBrandRequest {
  /**
   * The number of the article whose availability you want to check.
   * @type {string}
   * @memberof ArticleAvailabilityApiGetArticleAvailabilityAnyBrand
   */
  readonly sku: string

  /**
   * The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
   * @type {string}
   * @memberof ArticleAvailabilityApiGetArticleAvailabilityAnyBrand
   */
  readonly xAvailabilityGroupKey?: string
}

/**
 * Request parameters for getArticleAvailabilityByShorCodeAndBrand operation in ArticleAvailabilityApi.
 * @export
 * @interface ArticleAvailabilityApiGetArticleAvailabilityByShorCodeAndBrandRequest
 */
export interface ArticleAvailabilityApiGetArticleAvailabilityByShorCodeAndBrandRequest {
  /**
   * The brand for which you want to check a article\&#39;s availability. The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
   * @type {string}
   * @memberof ArticleAvailabilityApiGetArticleAvailabilityByShorCodeAndBrand
   */
  readonly brand: string

  /**
   * The article\&#39;s shortcode whose availability you want to check.
   * @type {string}
   * @memberof ArticleAvailabilityApiGetArticleAvailabilityByShorCodeAndBrand
   */
  readonly artcode: string

  /**
   * The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
   * @type {string}
   * @memberof ArticleAvailabilityApiGetArticleAvailabilityByShorCodeAndBrand
   */
  readonly xAvailabilityGroupKey?: string
}

/**
 * Request parameters for getArticleAvailabilityByShortCode operation in ArticleAvailabilityApi.
 * @export
 * @interface ArticleAvailabilityApiGetArticleAvailabilityByShortCodeRequest
 */
export interface ArticleAvailabilityApiGetArticleAvailabilityByShortCodeRequest {
  /**
   * The article\&#39;s shortcode whose availability you want to check.
   * @type {string}
   * @memberof ArticleAvailabilityApiGetArticleAvailabilityByShortCode
   */
  readonly artcode: string

  /**
   * The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
   * @type {string}
   * @memberof ArticleAvailabilityApiGetArticleAvailabilityByShortCode
   */
  readonly xAvailabilityGroupKey?: string
}

/**
 * Request parameters for getArticleAvailabilityForBrandAndLocale operation in ArticleAvailabilityApi.
 * @export
 * @interface ArticleAvailabilityApiGetArticleAvailabilityForBrandAndLocaleRequest
 */
export interface ArticleAvailabilityApiGetArticleAvailabilityForBrandAndLocaleRequest {
  /**
   * The assortment for which you want to check an article\&#39;s availability. You can get a list of supported assortments using the &#x60;/catalogs&#x60; endpoint.
   * @type {string}
   * @memberof ArticleAvailabilityApiGetArticleAvailabilityForBrandAndLocale
   */
  readonly assortment: string

  /**
   * The brand for which you want to check an article\&#39;s availability. The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
   * @type {string}
   * @memberof ArticleAvailabilityApiGetArticleAvailabilityForBrandAndLocale
   */
  readonly brand: string

  /**
   * The number of the article whose availability you want to check.
   * @type {string}
   * @memberof ArticleAvailabilityApiGetArticleAvailabilityForBrandAndLocale
   */
  readonly sku: string

  /**
   * The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
   * @type {string}
   * @memberof ArticleAvailabilityApiGetArticleAvailabilityForBrandAndLocale
   */
  readonly xAvailabilityGroupKey?: string
}

/**
 * Request parameters for getArticleAvailabilityWithOneFilter operation in ArticleAvailabilityApi.
 * @export
 * @interface ArticleAvailabilityApiGetArticleAvailabilityWithOneFilterRequest
 */
export interface ArticleAvailabilityApiGetArticleAvailabilityWithOneFilterRequest {
  /**
   * The brand or assortment for which you want to check a article\&#39;s availability.  The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
   * @type {string}
   * @memberof ArticleAvailabilityApiGetArticleAvailabilityWithOneFilter
   */
  readonly assortmentOrBrand: string

  /**
   * The number of the article whose availability you want to check.
   * @type {string}
   * @memberof ArticleAvailabilityApiGetArticleAvailabilityWithOneFilter
   */
  readonly sku: string

  /**
   * The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
   * @type {string}
   * @memberof ArticleAvailabilityApiGetArticleAvailabilityWithOneFilter
   */
  readonly xAvailabilityGroupKey?: string
}

/**
 * Request parameters for getArticleAvailabilityWithRules operation in ArticleAvailabilityApi.
 * @export
 * @interface ArticleAvailabilityApiGetArticleAvailabilityWithRulesRequest
 */
export interface ArticleAvailabilityApiGetArticleAvailabilityWithRulesRequest {
  /**
   * The number of the article whose availability you want to check.
   * @type {string}
   * @memberof ArticleAvailabilityApiGetArticleAvailabilityWithRules
   */
  readonly sku: string

  /**
   * The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
   * @type {string}
   * @memberof ArticleAvailabilityApiGetArticleAvailabilityWithRules
   */
  readonly xAvailabilityGroupKey?: string
}

/**
 * Request parameters for getArticleAvailabilityWithRulesForBrand operation in ArticleAvailabilityApi.
 * @export
 * @interface ArticleAvailabilityApiGetArticleAvailabilityWithRulesForBrandRequest
 */
export interface ArticleAvailabilityApiGetArticleAvailabilityWithRulesForBrandRequest {
  /**
   * The brand for which you want to check an article\&#39;s availability. The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
   * @type {string}
   * @memberof ArticleAvailabilityApiGetArticleAvailabilityWithRulesForBrand
   */
  readonly brand: string

  /**
   * The number of the article whose availability you want to check.
   * @type {string}
   * @memberof ArticleAvailabilityApiGetArticleAvailabilityWithRulesForBrand
   */
  readonly sku: string

  /**
   * The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
   * @type {string}
   * @memberof ArticleAvailabilityApiGetArticleAvailabilityWithRulesForBrand
   */
  readonly xAvailabilityGroupKey?: string
}

/**
 * ArticleAvailabilityApi - object-oriented interface
 * @export
 * @class ArticleAvailabilityApi
 * @extends {BaseAPI}
 */
export class ArticleAvailabilityApi extends BaseAPI {
  /**
   * Use an article\'s number to retrieve a list of assortments in which the given article is available.
   * @summary Get article availability for brand Geberit.
   * @param {ArticleAvailabilityApiGetArticleAvailabilityRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArticleAvailabilityApi
   */
  public getArticleAvailability(
    requestParameters: ArticleAvailabilityApiGetArticleAvailabilityRequest,
    options?: AxiosRequestConfig
  ) {
    return ArticleAvailabilityApiFp(this.configuration)
      .getArticleAvailability(requestParameters.sku, requestParameters.xAvailabilityGroupKey, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Use an article\'s number to retrieve a list of assortments in which the given article is available.
   * @summary Get article availability across all locales and brands.
   * @param {ArticleAvailabilityApiGetArticleAvailabilityAnyBrandRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArticleAvailabilityApi
   */
  public getArticleAvailabilityAnyBrand(
    requestParameters: ArticleAvailabilityApiGetArticleAvailabilityAnyBrandRequest,
    options?: AxiosRequestConfig
  ) {
    return ArticleAvailabilityApiFp(this.configuration)
      .getArticleAvailabilityAnyBrand(requestParameters.sku, requestParameters.xAvailabilityGroupKey, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Use an article shortcode and a brand to retrieve availability information of the given article.
   * @summary Get article availability for shortcode and brand.
   * @param {ArticleAvailabilityApiGetArticleAvailabilityByShorCodeAndBrandRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArticleAvailabilityApi
   */
  public getArticleAvailabilityByShorCodeAndBrand(
    requestParameters: ArticleAvailabilityApiGetArticleAvailabilityByShorCodeAndBrandRequest,
    options?: AxiosRequestConfig
  ) {
    return ArticleAvailabilityApiFp(this.configuration)
      .getArticleAvailabilityByShorCodeAndBrand(
        requestParameters.brand,
        requestParameters.artcode,
        requestParameters.xAvailabilityGroupKey,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Use an article\'s shortcode to retrieve a list of assortments in which the given article is available.
   * @summary Get article availability for shortcode and brand Geberit.
   * @param {ArticleAvailabilityApiGetArticleAvailabilityByShortCodeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArticleAvailabilityApi
   */
  public getArticleAvailabilityByShortCode(
    requestParameters: ArticleAvailabilityApiGetArticleAvailabilityByShortCodeRequest,
    options?: AxiosRequestConfig
  ) {
    return ArticleAvailabilityApiFp(this.configuration)
      .getArticleAvailabilityByShortCode(requestParameters.artcode, requestParameters.xAvailabilityGroupKey, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Use an article number, assortment, and brand to retrieve availability information of the given article.
   * @summary Get article availability for assortment and brand.
   * @param {ArticleAvailabilityApiGetArticleAvailabilityForBrandAndLocaleRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArticleAvailabilityApi
   */
  public getArticleAvailabilityForBrandAndLocale(
    requestParameters: ArticleAvailabilityApiGetArticleAvailabilityForBrandAndLocaleRequest,
    options?: AxiosRequestConfig
  ) {
    return ArticleAvailabilityApiFp(this.configuration)
      .getArticleAvailabilityForBrandAndLocale(
        requestParameters.assortment,
        requestParameters.brand,
        requestParameters.sku,
        requestParameters.xAvailabilityGroupKey,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Use an article number and an assortment (or brand) to retrieve availability information of the given article.  Note the following: - If you define an assortment, the request will be made for the default brand `geberit`. - If you define a brand, the request will return availability information for all assortments.
   * @summary Get article availability for assortment or brand.
   * @param {ArticleAvailabilityApiGetArticleAvailabilityWithOneFilterRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArticleAvailabilityApi
   */
  public getArticleAvailabilityWithOneFilter(
    requestParameters: ArticleAvailabilityApiGetArticleAvailabilityWithOneFilterRequest,
    options?: AxiosRequestConfig
  ) {
    return ArticleAvailabilityApiFp(this.configuration)
      .getArticleAvailabilityWithOneFilter(
        requestParameters.assortmentOrBrand,
        requestParameters.sku,
        requestParameters.xAvailabilityGroupKey,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Use an article number to retrieve a list of all assortments and applied availability logic rules that determine why the article is available or not.
   * @summary Get article availability and the applied availability rules for brand Geberit.
   * @param {ArticleAvailabilityApiGetArticleAvailabilityWithRulesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArticleAvailabilityApi
   */
  public getArticleAvailabilityWithRules(
    requestParameters: ArticleAvailabilityApiGetArticleAvailabilityWithRulesRequest,
    options?: AxiosRequestConfig
  ) {
    return ArticleAvailabilityApiFp(this.configuration)
      .getArticleAvailabilityWithRules(requestParameters.sku, requestParameters.xAvailabilityGroupKey, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Use an article number and brand to retrieve a list of all assortments and applied availability logic rules that determine why the article is available or not.
   * @summary Get article availability and the applied availability rules for a brand.
   * @param {ArticleAvailabilityApiGetArticleAvailabilityWithRulesForBrandRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArticleAvailabilityApi
   */
  public getArticleAvailabilityWithRulesForBrand(
    requestParameters: ArticleAvailabilityApiGetArticleAvailabilityWithRulesForBrandRequest,
    options?: AxiosRequestConfig
  ) {
    return ArticleAvailabilityApiFp(this.configuration)
      .getArticleAvailabilityWithRulesForBrand(
        requestParameters.brand,
        requestParameters.sku,
        requestParameters.xAvailabilityGroupKey,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * AvailabilityQueryBuilderApi - axios parameter creator
 * @export
 */
export const AvailabilityQueryBuilderApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Retrieve the availability rules you need to apply when querying CTP for product availability for a specific assortment and availability group.
     * @summary Get availability rules for querying products.
     * @param {string} assortment The assortment for which you want to retrieve the availability rules. You can get a list of supported assortments using the &#x60;/catalogs&#x60; endpoint.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductAvailabilityRules: async (
      assortment: string,
      xAvailabilityGroupKey?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'assortment' is not null or undefined
      assertParamExists("getProductAvailabilityRules", "assortment", assortment)
      const localVarPath = `/{assortment}/product/query`.replace(
        `{${"assortment"}}`,
        encodeURIComponent(String(assortment))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (xAvailabilityGroupKey != null) {
        localVarHeaderParameter["X-Availability-Group-Key"] = String(xAvailabilityGroupKey)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Retrieve the availability rules you need to apply when querying CTP for spare parts availability for a specific assortment and availability group.
     * @summary Get availability rules for querying spare parts.
     * @param {string} assortment The assortment for which you want to retrieve the availability rules. You can get a list of supported assortments using the &#x60;/catalogs&#x60; endpoint.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSparePartAvailabilityRules: async (
      assortment: string,
      xAvailabilityGroupKey?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'assortment' is not null or undefined
      assertParamExists("getSparePartAvailabilityRules", "assortment", assortment)
      const localVarPath = `/{assortment}/spare-part/query`.replace(
        `{${"assortment"}}`,
        encodeURIComponent(String(assortment))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (xAvailabilityGroupKey != null) {
        localVarHeaderParameter["X-Availability-Group-Key"] = String(xAvailabilityGroupKey)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * AvailabilityQueryBuilderApi - functional programming interface
 * @export
 */
export const AvailabilityQueryBuilderApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AvailabilityQueryBuilderApiAxiosParamCreator(configuration)
  return {
    /**
     * Retrieve the availability rules you need to apply when querying CTP for product availability for a specific assortment and availability group.
     * @summary Get availability rules for querying products.
     * @param {string} assortment The assortment for which you want to retrieve the availability rules. You can get a list of supported assortments using the &#x60;/catalogs&#x60; endpoint.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProductAvailabilityRules(
      assortment: string,
      xAvailabilityGroupKey?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueryBuilderResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProductAvailabilityRules(
        assortment,
        xAvailabilityGroupKey,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Retrieve the availability rules you need to apply when querying CTP for spare parts availability for a specific assortment and availability group.
     * @summary Get availability rules for querying spare parts.
     * @param {string} assortment The assortment for which you want to retrieve the availability rules. You can get a list of supported assortments using the &#x60;/catalogs&#x60; endpoint.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSparePartAvailabilityRules(
      assortment: string,
      xAvailabilityGroupKey?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueryBuilderResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSparePartAvailabilityRules(
        assortment,
        xAvailabilityGroupKey,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * AvailabilityQueryBuilderApi - factory interface
 * @export
 */
export const AvailabilityQueryBuilderApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AvailabilityQueryBuilderApiFp(configuration)
  return {
    /**
     * Retrieve the availability rules you need to apply when querying CTP for product availability for a specific assortment and availability group.
     * @summary Get availability rules for querying products.
     * @param {AvailabilityQueryBuilderApiGetProductAvailabilityRulesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductAvailabilityRules(
      requestParameters: AvailabilityQueryBuilderApiGetProductAvailabilityRulesRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<QueryBuilderResponse> {
      return localVarFp
        .getProductAvailabilityRules(requestParameters.assortment, requestParameters.xAvailabilityGroupKey, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Retrieve the availability rules you need to apply when querying CTP for spare parts availability for a specific assortment and availability group.
     * @summary Get availability rules for querying spare parts.
     * @param {AvailabilityQueryBuilderApiGetSparePartAvailabilityRulesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSparePartAvailabilityRules(
      requestParameters: AvailabilityQueryBuilderApiGetSparePartAvailabilityRulesRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<QueryBuilderResponse> {
      return localVarFp
        .getSparePartAvailabilityRules(requestParameters.assortment, requestParameters.xAvailabilityGroupKey, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for getProductAvailabilityRules operation in AvailabilityQueryBuilderApi.
 * @export
 * @interface AvailabilityQueryBuilderApiGetProductAvailabilityRulesRequest
 */
export interface AvailabilityQueryBuilderApiGetProductAvailabilityRulesRequest {
  /**
   * The assortment for which you want to retrieve the availability rules. You can get a list of supported assortments using the &#x60;/catalogs&#x60; endpoint.
   * @type {string}
   * @memberof AvailabilityQueryBuilderApiGetProductAvailabilityRules
   */
  readonly assortment: string

  /**
   * The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
   * @type {string}
   * @memberof AvailabilityQueryBuilderApiGetProductAvailabilityRules
   */
  readonly xAvailabilityGroupKey?: string
}

/**
 * Request parameters for getSparePartAvailabilityRules operation in AvailabilityQueryBuilderApi.
 * @export
 * @interface AvailabilityQueryBuilderApiGetSparePartAvailabilityRulesRequest
 */
export interface AvailabilityQueryBuilderApiGetSparePartAvailabilityRulesRequest {
  /**
   * The assortment for which you want to retrieve the availability rules. You can get a list of supported assortments using the &#x60;/catalogs&#x60; endpoint.
   * @type {string}
   * @memberof AvailabilityQueryBuilderApiGetSparePartAvailabilityRules
   */
  readonly assortment: string

  /**
   * The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
   * @type {string}
   * @memberof AvailabilityQueryBuilderApiGetSparePartAvailabilityRules
   */
  readonly xAvailabilityGroupKey?: string
}

/**
 * AvailabilityQueryBuilderApi - object-oriented interface
 * @export
 * @class AvailabilityQueryBuilderApi
 * @extends {BaseAPI}
 */
export class AvailabilityQueryBuilderApi extends BaseAPI {
  /**
   * Retrieve the availability rules you need to apply when querying CTP for product availability for a specific assortment and availability group.
   * @summary Get availability rules for querying products.
   * @param {AvailabilityQueryBuilderApiGetProductAvailabilityRulesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AvailabilityQueryBuilderApi
   */
  public getProductAvailabilityRules(
    requestParameters: AvailabilityQueryBuilderApiGetProductAvailabilityRulesRequest,
    options?: AxiosRequestConfig
  ) {
    return AvailabilityQueryBuilderApiFp(this.configuration)
      .getProductAvailabilityRules(requestParameters.assortment, requestParameters.xAvailabilityGroupKey, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Retrieve the availability rules you need to apply when querying CTP for spare parts availability for a specific assortment and availability group.
   * @summary Get availability rules for querying spare parts.
   * @param {AvailabilityQueryBuilderApiGetSparePartAvailabilityRulesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AvailabilityQueryBuilderApi
   */
  public getSparePartAvailabilityRules(
    requestParameters: AvailabilityQueryBuilderApiGetSparePartAvailabilityRulesRequest,
    options?: AxiosRequestConfig
  ) {
    return AvailabilityQueryBuilderApiFp(this.configuration)
      .getSparePartAvailabilityRules(requestParameters.assortment, requestParameters.xAvailabilityGroupKey, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * CategoryAvailabilityApi - axios parameter creator
 * @export
 */
export const CategoryAvailabilityApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Use a category\'s key to retrieve a list of assortments in which the given category is available for the default brand `geberit`. You will also receive the assortments in which the category\'s products and spare parts are available.
     * @summary Get category availability for brand Geberit.
     * @param {string} key The key of the category whose availability you want to check.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCategoryAvailability: async (
      key: string,
      xAvailabilityGroupKey?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'key' is not null or undefined
      assertParamExists("getCategoryAvailability", "key", key)
      const localVarPath = `/categories/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(key)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (xAvailabilityGroupKey != null) {
        localVarHeaderParameter["X-Availability-Group-Key"] = String(xAvailabilityGroupKey)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Use a category key, assortment, and brand to retrieve availability information of the given category. This endpoint also provides availability information of the category\'s products and spare parts.
     * @summary Get category availability for assortment or brand.
     * @param {string} assortment The assortment for which you want to check a category\&#39;s availability. You can get a list of supported assortments using the &#x60;/catalogs&#x60; endpoint.
     * @param {string} brand The brand for which you want to check a category\&#39;s availability. The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {string} key The key of the category whose availability you want to check.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCategoryAvailabilityForBrandAndLocale: async (
      assortment: string,
      brand: string,
      key: string,
      xAvailabilityGroupKey?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'assortment' is not null or undefined
      assertParamExists("getCategoryAvailabilityForBrandAndLocale", "assortment", assortment)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("getCategoryAvailabilityForBrandAndLocale", "brand", brand)
      // verify required parameter 'key' is not null or undefined
      assertParamExists("getCategoryAvailabilityForBrandAndLocale", "key", key)
      const localVarPath = `/{assortment}/{brand}/categories/{key}`
        .replace(`{${"assortment"}}`, encodeURIComponent(String(assortment)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
        .replace(`{${"key"}}`, encodeURIComponent(String(key)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (xAvailabilityGroupKey != null) {
        localVarHeaderParameter["X-Availability-Group-Key"] = String(xAvailabilityGroupKey)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Use a category key and an assortment (or brand) to retrieve availability information of the given category.  Note the following: - If you define an assortment, the request will be made for the default brand `geberit`. - If you define a brand, the request will return availability information for all assortments.  This endpoint also provides availability information of the category\'s products and spare parts.
     * @summary Get category availability for assortment or brand.
     * @param {string} assortmentOrBrand The brand or assortment for which you want to check a category\&#39;s availability.  The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {string} key The key of the category whose availability you want to check.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCategoryAvailabilityForBrandOrLocale: async (
      assortmentOrBrand: string,
      key: string,
      xAvailabilityGroupKey?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'assortmentOrBrand' is not null or undefined
      assertParamExists("getCategoryAvailabilityForBrandOrLocale", "assortmentOrBrand", assortmentOrBrand)
      // verify required parameter 'key' is not null or undefined
      assertParamExists("getCategoryAvailabilityForBrandOrLocale", "key", key)
      const localVarPath = `/{assortmentOrBrand}/categories/{key}`
        .replace(`{${"assortmentOrBrand"}}`, encodeURIComponent(String(assortmentOrBrand)))
        .replace(`{${"key"}}`, encodeURIComponent(String(key)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (xAvailabilityGroupKey != null) {
        localVarHeaderParameter["X-Availability-Group-Key"] = String(xAvailabilityGroupKey)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Use a category key to retrieve a list of all assortments and applied availability logic rules that determine why the category is available or not. The availability rules are calculated for brand `geberit`.
     * @summary Get the applied availability rules for category.
     * @param {string} key The key of the category whose availability you want to check. This is the category STEP id.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCategoryAvailabilityRules: async (
      key: string,
      xAvailabilityGroupKey?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'key' is not null or undefined
      assertParamExists("getCategoryAvailabilityRules", "key", key)
      const localVarPath = `/categories/{key}/rules`.replace(`{${"key"}}`, encodeURIComponent(String(key)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (xAvailabilityGroupKey != null) {
        localVarHeaderParameter["X-Availability-Group-Key"] = String(xAvailabilityGroupKey)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Use a category sku and brand to retrieve a list of all assortments and applied availability logic rules that determine why the category is available or not.
     * @summary Get the applied availability rules for category and brand.
     * @param {string} brand The brand for which you want to check a category\&#39;s availability. The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {string} key The key of the category whose availability you want to check. This is the category STEP ID.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCategoryAvailabilityRulesForBrand: async (
      brand: string,
      key: string,
      xAvailabilityGroupKey?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("getCategoryAvailabilityRulesForBrand", "brand", brand)
      // verify required parameter 'key' is not null or undefined
      assertParamExists("getCategoryAvailabilityRulesForBrand", "key", key)
      const localVarPath = `/{brand}/categories/{key}/rules`
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
        .replace(`{${"key"}}`, encodeURIComponent(String(key)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (xAvailabilityGroupKey != null) {
        localVarHeaderParameter["X-Availability-Group-Key"] = String(xAvailabilityGroupKey)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * CategoryAvailabilityApi - functional programming interface
 * @export
 */
export const CategoryAvailabilityApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CategoryAvailabilityApiAxiosParamCreator(configuration)
  return {
    /**
     * Use a category\'s key to retrieve a list of assortments in which the given category is available for the default brand `geberit`. You will also receive the assortments in which the category\'s products and spare parts are available.
     * @summary Get category availability for brand Geberit.
     * @param {string} key The key of the category whose availability you want to check.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCategoryAvailability(
      key: string,
      xAvailabilityGroupKey?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCategoryAvailability(
        key,
        xAvailabilityGroupKey,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Use a category key, assortment, and brand to retrieve availability information of the given category. This endpoint also provides availability information of the category\'s products and spare parts.
     * @summary Get category availability for assortment or brand.
     * @param {string} assortment The assortment for which you want to check a category\&#39;s availability. You can get a list of supported assortments using the &#x60;/catalogs&#x60; endpoint.
     * @param {string} brand The brand for which you want to check a category\&#39;s availability. The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {string} key The key of the category whose availability you want to check.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCategoryAvailabilityForBrandAndLocale(
      assortment: string,
      brand: string,
      key: string,
      xAvailabilityGroupKey?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCategoryAvailabilityForBrandAndLocale(
        assortment,
        brand,
        key,
        xAvailabilityGroupKey,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Use a category key and an assortment (or brand) to retrieve availability information of the given category.  Note the following: - If you define an assortment, the request will be made for the default brand `geberit`. - If you define a brand, the request will return availability information for all assortments.  This endpoint also provides availability information of the category\'s products and spare parts.
     * @summary Get category availability for assortment or brand.
     * @param {string} assortmentOrBrand The brand or assortment for which you want to check a category\&#39;s availability.  The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {string} key The key of the category whose availability you want to check.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCategoryAvailabilityForBrandOrLocale(
      assortmentOrBrand: string,
      key: string,
      xAvailabilityGroupKey?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCategoryAvailabilityForBrandOrLocale(
        assortmentOrBrand,
        key,
        xAvailabilityGroupKey,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Use a category key to retrieve a list of all assortments and applied availability logic rules that determine why the category is available or not. The availability rules are calculated for brand `geberit`.
     * @summary Get the applied availability rules for category.
     * @param {string} key The key of the category whose availability you want to check. This is the category STEP id.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCategoryAvailabilityRules(
      key: string,
      xAvailabilityGroupKey?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCategoryAvailabilityRules(
        key,
        xAvailabilityGroupKey,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Use a category sku and brand to retrieve a list of all assortments and applied availability logic rules that determine why the category is available or not.
     * @summary Get the applied availability rules for category and brand.
     * @param {string} brand The brand for which you want to check a category\&#39;s availability. The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {string} key The key of the category whose availability you want to check. This is the category STEP ID.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCategoryAvailabilityRulesForBrand(
      brand: string,
      key: string,
      xAvailabilityGroupKey?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCategoryAvailabilityRulesForBrand(
        brand,
        key,
        xAvailabilityGroupKey,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * CategoryAvailabilityApi - factory interface
 * @export
 */
export const CategoryAvailabilityApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = CategoryAvailabilityApiFp(configuration)
  return {
    /**
     * Use a category\'s key to retrieve a list of assortments in which the given category is available for the default brand `geberit`. You will also receive the assortments in which the category\'s products and spare parts are available.
     * @summary Get category availability for brand Geberit.
     * @param {CategoryAvailabilityApiGetCategoryAvailabilityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCategoryAvailability(
      requestParameters: CategoryAvailabilityApiGetCategoryAvailabilityRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<Category> {
      return localVarFp
        .getCategoryAvailability(requestParameters.key, requestParameters.xAvailabilityGroupKey, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Use a category key, assortment, and brand to retrieve availability information of the given category. This endpoint also provides availability information of the category\'s products and spare parts.
     * @summary Get category availability for assortment or brand.
     * @param {CategoryAvailabilityApiGetCategoryAvailabilityForBrandAndLocaleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCategoryAvailabilityForBrandAndLocale(
      requestParameters: CategoryAvailabilityApiGetCategoryAvailabilityForBrandAndLocaleRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<Category> {
      return localVarFp
        .getCategoryAvailabilityForBrandAndLocale(
          requestParameters.assortment,
          requestParameters.brand,
          requestParameters.key,
          requestParameters.xAvailabilityGroupKey,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Use a category key and an assortment (or brand) to retrieve availability information of the given category.  Note the following: - If you define an assortment, the request will be made for the default brand `geberit`. - If you define a brand, the request will return availability information for all assortments.  This endpoint also provides availability information of the category\'s products and spare parts.
     * @summary Get category availability for assortment or brand.
     * @param {CategoryAvailabilityApiGetCategoryAvailabilityForBrandOrLocaleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCategoryAvailabilityForBrandOrLocale(
      requestParameters: CategoryAvailabilityApiGetCategoryAvailabilityForBrandOrLocaleRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<Category> {
      return localVarFp
        .getCategoryAvailabilityForBrandOrLocale(
          requestParameters.assortmentOrBrand,
          requestParameters.key,
          requestParameters.xAvailabilityGroupKey,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Use a category key to retrieve a list of all assortments and applied availability logic rules that determine why the category is available or not. The availability rules are calculated for brand `geberit`.
     * @summary Get the applied availability rules for category.
     * @param {CategoryAvailabilityApiGetCategoryAvailabilityRulesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCategoryAvailabilityRules(
      requestParameters: CategoryAvailabilityApiGetCategoryAvailabilityRulesRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<Category> {
      return localVarFp
        .getCategoryAvailabilityRules(requestParameters.key, requestParameters.xAvailabilityGroupKey, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Use a category sku and brand to retrieve a list of all assortments and applied availability logic rules that determine why the category is available or not.
     * @summary Get the applied availability rules for category and brand.
     * @param {CategoryAvailabilityApiGetCategoryAvailabilityRulesForBrandRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCategoryAvailabilityRulesForBrand(
      requestParameters: CategoryAvailabilityApiGetCategoryAvailabilityRulesForBrandRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<Category> {
      return localVarFp
        .getCategoryAvailabilityRulesForBrand(
          requestParameters.brand,
          requestParameters.key,
          requestParameters.xAvailabilityGroupKey,
          options
        )
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for getCategoryAvailability operation in CategoryAvailabilityApi.
 * @export
 * @interface CategoryAvailabilityApiGetCategoryAvailabilityRequest
 */
export interface CategoryAvailabilityApiGetCategoryAvailabilityRequest {
  /**
   * The key of the category whose availability you want to check.
   * @type {string}
   * @memberof CategoryAvailabilityApiGetCategoryAvailability
   */
  readonly key: string

  /**
   * The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
   * @type {string}
   * @memberof CategoryAvailabilityApiGetCategoryAvailability
   */
  readonly xAvailabilityGroupKey?: string
}

/**
 * Request parameters for getCategoryAvailabilityForBrandAndLocale operation in CategoryAvailabilityApi.
 * @export
 * @interface CategoryAvailabilityApiGetCategoryAvailabilityForBrandAndLocaleRequest
 */
export interface CategoryAvailabilityApiGetCategoryAvailabilityForBrandAndLocaleRequest {
  /**
   * The assortment for which you want to check a category\&#39;s availability. You can get a list of supported assortments using the &#x60;/catalogs&#x60; endpoint.
   * @type {string}
   * @memberof CategoryAvailabilityApiGetCategoryAvailabilityForBrandAndLocale
   */
  readonly assortment: string

  /**
   * The brand for which you want to check a category\&#39;s availability. The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
   * @type {string}
   * @memberof CategoryAvailabilityApiGetCategoryAvailabilityForBrandAndLocale
   */
  readonly brand: string

  /**
   * The key of the category whose availability you want to check.
   * @type {string}
   * @memberof CategoryAvailabilityApiGetCategoryAvailabilityForBrandAndLocale
   */
  readonly key: string

  /**
   * The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
   * @type {string}
   * @memberof CategoryAvailabilityApiGetCategoryAvailabilityForBrandAndLocale
   */
  readonly xAvailabilityGroupKey?: string
}

/**
 * Request parameters for getCategoryAvailabilityForBrandOrLocale operation in CategoryAvailabilityApi.
 * @export
 * @interface CategoryAvailabilityApiGetCategoryAvailabilityForBrandOrLocaleRequest
 */
export interface CategoryAvailabilityApiGetCategoryAvailabilityForBrandOrLocaleRequest {
  /**
   * The brand or assortment for which you want to check a category\&#39;s availability.  The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
   * @type {string}
   * @memberof CategoryAvailabilityApiGetCategoryAvailabilityForBrandOrLocale
   */
  readonly assortmentOrBrand: string

  /**
   * The key of the category whose availability you want to check.
   * @type {string}
   * @memberof CategoryAvailabilityApiGetCategoryAvailabilityForBrandOrLocale
   */
  readonly key: string

  /**
   * The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
   * @type {string}
   * @memberof CategoryAvailabilityApiGetCategoryAvailabilityForBrandOrLocale
   */
  readonly xAvailabilityGroupKey?: string
}

/**
 * Request parameters for getCategoryAvailabilityRules operation in CategoryAvailabilityApi.
 * @export
 * @interface CategoryAvailabilityApiGetCategoryAvailabilityRulesRequest
 */
export interface CategoryAvailabilityApiGetCategoryAvailabilityRulesRequest {
  /**
   * The key of the category whose availability you want to check. This is the category STEP id.
   * @type {string}
   * @memberof CategoryAvailabilityApiGetCategoryAvailabilityRules
   */
  readonly key: string

  /**
   * The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
   * @type {string}
   * @memberof CategoryAvailabilityApiGetCategoryAvailabilityRules
   */
  readonly xAvailabilityGroupKey?: string
}

/**
 * Request parameters for getCategoryAvailabilityRulesForBrand operation in CategoryAvailabilityApi.
 * @export
 * @interface CategoryAvailabilityApiGetCategoryAvailabilityRulesForBrandRequest
 */
export interface CategoryAvailabilityApiGetCategoryAvailabilityRulesForBrandRequest {
  /**
   * The brand for which you want to check a category\&#39;s availability. The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
   * @type {string}
   * @memberof CategoryAvailabilityApiGetCategoryAvailabilityRulesForBrand
   */
  readonly brand: string

  /**
   * The key of the category whose availability you want to check. This is the category STEP ID.
   * @type {string}
   * @memberof CategoryAvailabilityApiGetCategoryAvailabilityRulesForBrand
   */
  readonly key: string

  /**
   * The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
   * @type {string}
   * @memberof CategoryAvailabilityApiGetCategoryAvailabilityRulesForBrand
   */
  readonly xAvailabilityGroupKey?: string
}

/**
 * CategoryAvailabilityApi - object-oriented interface
 * @export
 * @class CategoryAvailabilityApi
 * @extends {BaseAPI}
 */
export class CategoryAvailabilityApi extends BaseAPI {
  /**
   * Use a category\'s key to retrieve a list of assortments in which the given category is available for the default brand `geberit`. You will also receive the assortments in which the category\'s products and spare parts are available.
   * @summary Get category availability for brand Geberit.
   * @param {CategoryAvailabilityApiGetCategoryAvailabilityRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoryAvailabilityApi
   */
  public getCategoryAvailability(
    requestParameters: CategoryAvailabilityApiGetCategoryAvailabilityRequest,
    options?: AxiosRequestConfig
  ) {
    return CategoryAvailabilityApiFp(this.configuration)
      .getCategoryAvailability(requestParameters.key, requestParameters.xAvailabilityGroupKey, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Use a category key, assortment, and brand to retrieve availability information of the given category. This endpoint also provides availability information of the category\'s products and spare parts.
   * @summary Get category availability for assortment or brand.
   * @param {CategoryAvailabilityApiGetCategoryAvailabilityForBrandAndLocaleRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoryAvailabilityApi
   */
  public getCategoryAvailabilityForBrandAndLocale(
    requestParameters: CategoryAvailabilityApiGetCategoryAvailabilityForBrandAndLocaleRequest,
    options?: AxiosRequestConfig
  ) {
    return CategoryAvailabilityApiFp(this.configuration)
      .getCategoryAvailabilityForBrandAndLocale(
        requestParameters.assortment,
        requestParameters.brand,
        requestParameters.key,
        requestParameters.xAvailabilityGroupKey,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Use a category key and an assortment (or brand) to retrieve availability information of the given category.  Note the following: - If you define an assortment, the request will be made for the default brand `geberit`. - If you define a brand, the request will return availability information for all assortments.  This endpoint also provides availability information of the category\'s products and spare parts.
   * @summary Get category availability for assortment or brand.
   * @param {CategoryAvailabilityApiGetCategoryAvailabilityForBrandOrLocaleRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoryAvailabilityApi
   */
  public getCategoryAvailabilityForBrandOrLocale(
    requestParameters: CategoryAvailabilityApiGetCategoryAvailabilityForBrandOrLocaleRequest,
    options?: AxiosRequestConfig
  ) {
    return CategoryAvailabilityApiFp(this.configuration)
      .getCategoryAvailabilityForBrandOrLocale(
        requestParameters.assortmentOrBrand,
        requestParameters.key,
        requestParameters.xAvailabilityGroupKey,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Use a category key to retrieve a list of all assortments and applied availability logic rules that determine why the category is available or not. The availability rules are calculated for brand `geberit`.
   * @summary Get the applied availability rules for category.
   * @param {CategoryAvailabilityApiGetCategoryAvailabilityRulesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoryAvailabilityApi
   */
  public getCategoryAvailabilityRules(
    requestParameters: CategoryAvailabilityApiGetCategoryAvailabilityRulesRequest,
    options?: AxiosRequestConfig
  ) {
    return CategoryAvailabilityApiFp(this.configuration)
      .getCategoryAvailabilityRules(requestParameters.key, requestParameters.xAvailabilityGroupKey, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Use a category sku and brand to retrieve a list of all assortments and applied availability logic rules that determine why the category is available or not.
   * @summary Get the applied availability rules for category and brand.
   * @param {CategoryAvailabilityApiGetCategoryAvailabilityRulesForBrandRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoryAvailabilityApi
   */
  public getCategoryAvailabilityRulesForBrand(
    requestParameters: CategoryAvailabilityApiGetCategoryAvailabilityRulesForBrandRequest,
    options?: AxiosRequestConfig
  ) {
    return CategoryAvailabilityApiFp(this.configuration)
      .getCategoryAvailabilityRulesForBrand(
        requestParameters.brand,
        requestParameters.key,
        requestParameters.xAvailabilityGroupKey,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * LocalesApi - axios parameter creator
 * @export
 */
export const LocalesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get a list of the locales supported by our services. You can also find a list of all locales [in this documentation](/locales), but note that this list is manually maintained and therefore may not be up to date.
     * @summary Get all available locales.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatalogsAvailability: async (
      xAvailabilityGroupKey?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/catalogs`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (xAvailabilityGroupKey != null) {
        localVarHeaderParameter["X-Availability-Group-Key"] = String(xAvailabilityGroupKey)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * LocalesApi - functional programming interface
 * @export
 */
export const LocalesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = LocalesApiAxiosParamCreator(configuration)
  return {
    /**
     * Get a list of the locales supported by our services. You can also find a list of all locales [in this documentation](/locales), but note that this list is manually maintained and therefore may not be up to date.
     * @summary Get all available locales.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatalogsAvailability(
      xAvailabilityGroupKey?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Catalogs>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatalogsAvailability(xAvailabilityGroupKey, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * LocalesApi - factory interface
 * @export
 */
export const LocalesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = LocalesApiFp(configuration)
  return {
    /**
     * Get a list of the locales supported by our services. You can also find a list of all locales [in this documentation](/locales), but note that this list is manually maintained and therefore may not be up to date.
     * @summary Get all available locales.
     * @param {LocalesApiGetCatalogsAvailabilityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatalogsAvailability(
      requestParameters: LocalesApiGetCatalogsAvailabilityRequest = {},
      options?: AxiosRequestConfig
    ): AxiosPromise<Catalogs> {
      return localVarFp
        .getCatalogsAvailability(requestParameters.xAvailabilityGroupKey, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for getCatalogsAvailability operation in LocalesApi.
 * @export
 * @interface LocalesApiGetCatalogsAvailabilityRequest
 */
export interface LocalesApiGetCatalogsAvailabilityRequest {
  /**
   * The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
   * @type {string}
   * @memberof LocalesApiGetCatalogsAvailability
   */
  readonly xAvailabilityGroupKey?: string
}

/**
 * LocalesApi - object-oriented interface
 * @export
 * @class LocalesApi
 * @extends {BaseAPI}
 */
export class LocalesApi extends BaseAPI {
  /**
   * Get a list of the locales supported by our services. You can also find a list of all locales [in this documentation](/locales), but note that this list is manually maintained and therefore may not be up to date.
   * @summary Get all available locales.
   * @param {LocalesApiGetCatalogsAvailabilityRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LocalesApi
   */
  public getCatalogsAvailability(
    requestParameters: LocalesApiGetCatalogsAvailabilityRequest = {},
    options?: AxiosRequestConfig
  ) {
    return LocalesApiFp(this.configuration)
      .getCatalogsAvailability(requestParameters.xAvailabilityGroupKey, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * ProductAvailabilityApi - axios parameter creator
 * @export
 */
export const ProductAvailabilityApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Use a product\'s key to retrieve a list of assortments in which the given product and its articles are available.
     * @summary Get product availability for brand Geberit.
     * @param {string} key The key of the product whose availability you want to check.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {string} [fields] The response fields you want to retrieve. Separate the JSON response fields you want to receive with a comma &#x60;,&#x60; and use parentheses &#x60;()&#x60; to access nested fields in arrays and objects.&lt;br/&gt;The endpoint doesn\&#39;t return an error for invalidly defined fields. Instead, it only returns data for the fields that you have defined correctly.&lt;br /&gt;&lt;br /&gt;Be careful not to include spaces, as this will invalidate the field in question!
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductAvailability: async (
      key: string,
      xAvailabilityGroupKey?: string,
      fields?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'key' is not null or undefined
      assertParamExists("getProductAvailability", "key", key)
      const localVarPath = `/products/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(key)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (fields !== undefined) {
        localVarQueryParameter["fields"] = fields
      }

      if (xAvailabilityGroupKey != null) {
        localVarHeaderParameter["X-Availability-Group-Key"] = String(xAvailabilityGroupKey)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Use a product key, assortment, and brand to retrieve availability information of the given product and its articles.
     * @summary Get product availability for assortment and brand.
     * @param {string} assortment The assortment for which you want to check a product\&#39;s availability. You can get a list of supported assortments using the &#x60;/catalogs&#x60; endpoint.
     * @param {string} brand The brand for which you want to check a product\&#39;s availability. The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {string} key The key of the product whose availability you want to check.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {string} [fields] The response fields you want to retrieve. Separate the JSON response fields you want to receive with a comma &#x60;,&#x60; and use parentheses &#x60;()&#x60; to access nested fields in arrays and objects.&lt;br/&gt;The endpoint doesn\&#39;t return an error for invalidly defined fields. Instead, it only returns data for the fields that you have defined correctly.&lt;br /&gt;&lt;br /&gt;Be careful not to include spaces, as this will invalidate the field in question!
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductAvailabilityForBrandAndLocale: async (
      assortment: string,
      brand: string,
      key: string,
      xAvailabilityGroupKey?: string,
      fields?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'assortment' is not null or undefined
      assertParamExists("getProductAvailabilityForBrandAndLocale", "assortment", assortment)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("getProductAvailabilityForBrandAndLocale", "brand", brand)
      // verify required parameter 'key' is not null or undefined
      assertParamExists("getProductAvailabilityForBrandAndLocale", "key", key)
      const localVarPath = `/{assortment}/{brand}/products/{key}`
        .replace(`{${"assortment"}}`, encodeURIComponent(String(assortment)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
        .replace(`{${"key"}}`, encodeURIComponent(String(key)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (fields !== undefined) {
        localVarQueryParameter["fields"] = fields
      }

      if (xAvailabilityGroupKey != null) {
        localVarHeaderParameter["X-Availability-Group-Key"] = String(xAvailabilityGroupKey)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Use a product key and an assortment (or brand) to retrieve availability information of the given product and its articles.  Note the following: - If you define an assortment, the request will be made for the default brand `geberit`. - If you define a brand, the request will return availability information for all assortments.
     * @summary Get product availability for assortment or brand.
     * @param {string} assortmentOrBrand The brand or assortment for which you want to check a product\&#39;s availability.  The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {string} key The key of the product whose availability you want to check.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {string} [fields] The response fields you want to retrieve. Separate the JSON response fields you want to receive with a comma &#x60;,&#x60; and use parentheses &#x60;()&#x60; to access nested fields in arrays and objects.&lt;br/&gt;The endpoint doesn\&#39;t return an error for invalidly defined fields. Instead, it only returns data for the fields that you have defined correctly.&lt;br /&gt;&lt;br /&gt;Be careful not to include spaces, as this will invalidate the field in question!
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductAvailabilityForBrandOrLocale: async (
      assortmentOrBrand: string,
      key: string,
      xAvailabilityGroupKey?: string,
      fields?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'assortmentOrBrand' is not null or undefined
      assertParamExists("getProductAvailabilityForBrandOrLocale", "assortmentOrBrand", assortmentOrBrand)
      // verify required parameter 'key' is not null or undefined
      assertParamExists("getProductAvailabilityForBrandOrLocale", "key", key)
      const localVarPath = `/{assortmentOrBrand}/products/{key}`
        .replace(`{${"assortmentOrBrand"}}`, encodeURIComponent(String(assortmentOrBrand)))
        .replace(`{${"key"}}`, encodeURIComponent(String(key)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (fields !== undefined) {
        localVarQueryParameter["fields"] = fields
      }

      if (xAvailabilityGroupKey != null) {
        localVarHeaderParameter["X-Availability-Group-Key"] = String(xAvailabilityGroupKey)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Use a product key or its database ID to retrieve a list of all assortments and applied availability logic rules that determine why the product and its articles are available or not.
     * @summary Get product availability and the applied availability rules for brand Geberit.
     * @param {string} identifier The product key or database ID whose availability you want to check.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {string} [fields] The response fields you want to retrieve. Separate the JSON response fields you want to receive with a comma &#x60;,&#x60; and use parentheses &#x60;()&#x60; to access nested fields in arrays and objects. &lt;br&gt;The endpoint doesn\&#39;t return an error for invalidly defined fields. Instead, it only returns data for the fields that you have defined correctly. &lt;br&gt;&lt;br&gt;Be careful not to include spaces, as this will invalidate the field in question!
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductAvailabilityWithRules: async (
      identifier: string,
      xAvailabilityGroupKey?: string,
      fields?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'identifier' is not null or undefined
      assertParamExists("getProductAvailabilityWithRules", "identifier", identifier)
      const localVarPath = `/products/{identifier}/rules`.replace(
        `{${"identifier"}}`,
        encodeURIComponent(String(identifier))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (fields !== undefined) {
        localVarQueryParameter["fields"] = fields
      }

      if (xAvailabilityGroupKey != null) {
        localVarHeaderParameter["X-Availability-Group-Key"] = String(xAvailabilityGroupKey)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Use a product key (or its database ID) and brand to retrieve a list of all assortments and applied availability logic rules that determine why the product and its articles are available or not.
     * @summary Get product availability and the applied availability rules for a brand.
     * @param {string} brand The brand for which you want to check a product\&#39;s availability. The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {string} identifier The product key or database ID whose availability you want to check.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {string} [fields] The response fields you want to retrieve. Separate the JSON response fields you want to receive with a comma &#x60;,&#x60; and use parentheses &#x60;()&#x60; to access nested fields in arrays and objects. &lt;br&gt;The endpoint doesn\&#39;t return an error for invalidly defined fields. Instead, it only returns data for the fields that you have defined correctly. &lt;br&gt;&lt;br&gt;Be careful not to include spaces, as this will invalidate the field in question!
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductAvailabilityWithRulesForBrand: async (
      brand: string,
      identifier: string,
      xAvailabilityGroupKey?: string,
      fields?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("getProductAvailabilityWithRulesForBrand", "brand", brand)
      // verify required parameter 'identifier' is not null or undefined
      assertParamExists("getProductAvailabilityWithRulesForBrand", "identifier", identifier)
      const localVarPath = `/{brand}/products/{identifier}/rules`
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
        .replace(`{${"identifier"}}`, encodeURIComponent(String(identifier)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (fields !== undefined) {
        localVarQueryParameter["fields"] = fields
      }

      if (xAvailabilityGroupKey != null) {
        localVarHeaderParameter["X-Availability-Group-Key"] = String(xAvailabilityGroupKey)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get a list of assortments in which one or more products and their articles are available. You can filter the results by defining product keys, article numbers ([Stock Keeping Units, or SKUs](/glossary)), and Bluetooth Low Energy device ID.
     * @summary Get the availability of one or more products for brand Geberit.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {Array<string>} [ids] A list of the products\&#39; database ids whose availability you want to check. Separate each value by a comma &#x60;,&#x60; without spaces.
     * @param {Array<string>} [keys] A list of the products\&#39; keys whose availability you want to check. Separate each value by a comma &#x60;,&#x60; without spaces.
     * @param {Array<string>} [skus] A list of article numbers belonging to products whose availability you want to check. Separate each value by a comma &#x60;,&#x60; without spaces.
     * @param {string} [bleDevice] The HEX-value of the Bluetooth Low Energy device of the products whose availability you want to check.
     * @param {number} [offset] The number of items to skip. Used for pagination. The default value is 0.
     * @param {number} [limit] The total number of items to return. Used for pagination. The default value is 20. The maximum value is 500.
     * @param {string} [fields] The response fields you want to retrieve. Separate the JSON response fields you want to receive with a comma &#x60;,&#x60; and use parentheses &#x60;()&#x60; to access nested fields in arrays and objects. &lt;br&gt;The endpoint doesn\&#39;t return an error for invalidly defined fields. Instead, it only returns data for the fields that you have defined correctly. &lt;br&gt;&lt;br&gt;Be careful not to include spaces, as this will invalidate the field in question!
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductsAvailability: async (
      xAvailabilityGroupKey?: string,
      ids?: Array<string>,
      keys?: Array<string>,
      skus?: Array<string>,
      bleDevice?: string,
      offset?: number,
      limit?: number,
      fields?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/products`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (ids) {
        localVarQueryParameter["ids"] = ids.join(COLLECTION_FORMATS.csv)
      }

      if (keys) {
        localVarQueryParameter["keys"] = keys.join(COLLECTION_FORMATS.csv)
      }

      if (skus) {
        localVarQueryParameter["skus"] = skus.join(COLLECTION_FORMATS.csv)
      }

      if (bleDevice !== undefined) {
        localVarQueryParameter["bleDevice"] = bleDevice
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit
      }

      if (fields !== undefined) {
        localVarQueryParameter["fields"] = fields
      }

      if (xAvailabilityGroupKey != null) {
        localVarHeaderParameter["X-Availability-Group-Key"] = String(xAvailabilityGroupKey)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify an assortment and a brand for which you want to retrieve availability information of all products and their articles. You can filter the results by defining product keys, article numbers ([Stock Keeping Units, or SKUs](/glossary)), and Bluetooth Low Energy device ID.
     * @summary Get the availability of one or more products for assortment and brand.
     * @param {string} assortment The assortment for which you want to check product availability. You can get a list of supported assortments using the &#x60;/catalogs&#x60; endpoint.
     * @param {string} brand The brand for which you want to check product availability. The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {Array<string>} [ids] A list of the products\&#39; database ids whose availability you want to check. Separate each value by a comma &#x60;,&#x60; without spaces.
     * @param {Array<string>} [keys] A list of the products\&#39; keys whose availability you want to check. Separate each value by a comma &#x60;,&#x60; without spaces.
     * @param {Array<string>} [skus] A list of article numbers belonging to products whose availability you want to check. Separate each value by a comma &#x60;,&#x60; without spaces.
     * @param {string} [bleDevice] The HEX-value of the Bluetooth Low Energy device of the products whose availability you want to check.
     * @param {number} [offset] The number of items to skip. Used for pagination. The default value is 0.
     * @param {number} [limit] The total number of items to return. Used for pagination. The default value is 20. The maximum value is 500.
     * @param {string} [fields] The response fields you want to retrieve. Separate the JSON response fields you want to receive with a comma &#x60;,&#x60; and use parentheses &#x60;()&#x60; to access nested fields in arrays and objects. &lt;br&gt;The endpoint doesn\&#39;t return an error for invalidly defined fields. Instead, it only returns data for the fields that you have defined correctly. &lt;br&gt;&lt;br&gt;Be careful not to include spaces, as this will invalidate the field in question!
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductsAvailabilityForBrandAndLocale: async (
      assortment: string,
      brand: string,
      xAvailabilityGroupKey?: string,
      ids?: Array<string>,
      keys?: Array<string>,
      skus?: Array<string>,
      bleDevice?: string,
      offset?: number,
      limit?: number,
      fields?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'assortment' is not null or undefined
      assertParamExists("getProductsAvailabilityForBrandAndLocale", "assortment", assortment)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("getProductsAvailabilityForBrandAndLocale", "brand", brand)
      const localVarPath = `/{assortment}/{brand}/products`
        .replace(`{${"assortment"}}`, encodeURIComponent(String(assortment)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (ids) {
        localVarQueryParameter["ids"] = ids.join(COLLECTION_FORMATS.csv)
      }

      if (keys) {
        localVarQueryParameter["keys"] = keys.join(COLLECTION_FORMATS.csv)
      }

      if (skus) {
        localVarQueryParameter["skus"] = skus.join(COLLECTION_FORMATS.csv)
      }

      if (bleDevice !== undefined) {
        localVarQueryParameter["bleDevice"] = bleDevice
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit
      }

      if (fields !== undefined) {
        localVarQueryParameter["fields"] = fields
      }

      if (xAvailabilityGroupKey != null) {
        localVarHeaderParameter["X-Availability-Group-Key"] = String(xAvailabilityGroupKey)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify an assortment (or brand) for which you want to retrieve availability information of all products and their articles. You can filter the results by defining product keys, article numbers ([Stock Keeping Units, or SKUs](/glossary)), and Bluetooth Low Energy device ID.  Note the following: - If you define an assortment, the request will be made for the default brand `geberit`. - If you define a brand, the request will return availability information for all assortments.
     * @summary Get the availability of one or more products for assortment or brand.
     * @param {string} assortmentOrBrand The brand or assortment for which you want to check product availability.  The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {Array<string>} [ids] A list of the products\&#39; database ids whose availability you want to check. Separate each value by a comma &#x60;,&#x60; without spaces.
     * @param {Array<string>} [keys] A list of the products\&#39; keys whose availability you want to check. Separate each value by a comma &#x60;,&#x60; without spaces.
     * @param {Array<string>} [skus] A list of article numbers belonging to products whose availability you want to check. Separate each value by a comma &#x60;,&#x60; without spaces.
     * @param {string} [bleDevice] The HEX-value of the Bluetooth Low Energy device of the products whose availability you want to check.
     * @param {number} [offset] The number of items to skip. Used for pagination. The default value is 0.
     * @param {number} [limit] The total number of items to return. Used for pagination. The default value is 20. The maximum value is 500.
     * @param {string} [fields] The response fields you want to retrieve. Separate the JSON response fields you want to receive with a comma &#x60;,&#x60; and use parentheses &#x60;()&#x60; to access nested fields in arrays and objects. &lt;br&gt;The endpoint doesn\&#39;t return an error for invalidly defined fields. Instead, it only returns data for the fields that you have defined correctly. &lt;br&gt;&lt;br&gt;Be careful not to include spaces, as this will invalidate the field in question!
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductsAvailabilityForBrandOrLocale: async (
      assortmentOrBrand: string,
      xAvailabilityGroupKey?: string,
      ids?: Array<string>,
      keys?: Array<string>,
      skus?: Array<string>,
      bleDevice?: string,
      offset?: number,
      limit?: number,
      fields?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'assortmentOrBrand' is not null or undefined
      assertParamExists("getProductsAvailabilityForBrandOrLocale", "assortmentOrBrand", assortmentOrBrand)
      const localVarPath = `/{assortmentOrBrand}/products`.replace(
        `{${"assortmentOrBrand"}}`,
        encodeURIComponent(String(assortmentOrBrand))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (ids) {
        localVarQueryParameter["ids"] = ids.join(COLLECTION_FORMATS.csv)
      }

      if (keys) {
        localVarQueryParameter["keys"] = keys.join(COLLECTION_FORMATS.csv)
      }

      if (skus) {
        localVarQueryParameter["skus"] = skus.join(COLLECTION_FORMATS.csv)
      }

      if (bleDevice !== undefined) {
        localVarQueryParameter["bleDevice"] = bleDevice
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit
      }

      if (fields !== undefined) {
        localVarQueryParameter["fields"] = fields
      }

      if (xAvailabilityGroupKey != null) {
        localVarHeaderParameter["X-Availability-Group-Key"] = String(xAvailabilityGroupKey)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ProductAvailabilityApi - functional programming interface
 * @export
 */
export const ProductAvailabilityApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ProductAvailabilityApiAxiosParamCreator(configuration)
  return {
    /**
     * Use a product\'s key to retrieve a list of assortments in which the given product and its articles are available.
     * @summary Get product availability for brand Geberit.
     * @param {string} key The key of the product whose availability you want to check.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {string} [fields] The response fields you want to retrieve. Separate the JSON response fields you want to receive with a comma &#x60;,&#x60; and use parentheses &#x60;()&#x60; to access nested fields in arrays and objects.&lt;br/&gt;The endpoint doesn\&#39;t return an error for invalidly defined fields. Instead, it only returns data for the fields that you have defined correctly.&lt;br /&gt;&lt;br /&gt;Be careful not to include spaces, as this will invalidate the field in question!
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProductAvailability(
      key: string,
      xAvailabilityGroupKey?: string,
      fields?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProductAvailability(
        key,
        xAvailabilityGroupKey,
        fields,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Use a product key, assortment, and brand to retrieve availability information of the given product and its articles.
     * @summary Get product availability for assortment and brand.
     * @param {string} assortment The assortment for which you want to check a product\&#39;s availability. You can get a list of supported assortments using the &#x60;/catalogs&#x60; endpoint.
     * @param {string} brand The brand for which you want to check a product\&#39;s availability. The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {string} key The key of the product whose availability you want to check.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {string} [fields] The response fields you want to retrieve. Separate the JSON response fields you want to receive with a comma &#x60;,&#x60; and use parentheses &#x60;()&#x60; to access nested fields in arrays and objects.&lt;br/&gt;The endpoint doesn\&#39;t return an error for invalidly defined fields. Instead, it only returns data for the fields that you have defined correctly.&lt;br /&gt;&lt;br /&gt;Be careful not to include spaces, as this will invalidate the field in question!
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProductAvailabilityForBrandAndLocale(
      assortment: string,
      brand: string,
      key: string,
      xAvailabilityGroupKey?: string,
      fields?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProductAvailabilityForBrandAndLocale(
        assortment,
        brand,
        key,
        xAvailabilityGroupKey,
        fields,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Use a product key and an assortment (or brand) to retrieve availability information of the given product and its articles.  Note the following: - If you define an assortment, the request will be made for the default brand `geberit`. - If you define a brand, the request will return availability information for all assortments.
     * @summary Get product availability for assortment or brand.
     * @param {string} assortmentOrBrand The brand or assortment for which you want to check a product\&#39;s availability.  The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {string} key The key of the product whose availability you want to check.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {string} [fields] The response fields you want to retrieve. Separate the JSON response fields you want to receive with a comma &#x60;,&#x60; and use parentheses &#x60;()&#x60; to access nested fields in arrays and objects.&lt;br/&gt;The endpoint doesn\&#39;t return an error for invalidly defined fields. Instead, it only returns data for the fields that you have defined correctly.&lt;br /&gt;&lt;br /&gt;Be careful not to include spaces, as this will invalidate the field in question!
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProductAvailabilityForBrandOrLocale(
      assortmentOrBrand: string,
      key: string,
      xAvailabilityGroupKey?: string,
      fields?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProductAvailabilityForBrandOrLocale(
        assortmentOrBrand,
        key,
        xAvailabilityGroupKey,
        fields,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Use a product key or its database ID to retrieve a list of all assortments and applied availability logic rules that determine why the product and its articles are available or not.
     * @summary Get product availability and the applied availability rules for brand Geberit.
     * @param {string} identifier The product key or database ID whose availability you want to check.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {string} [fields] The response fields you want to retrieve. Separate the JSON response fields you want to receive with a comma &#x60;,&#x60; and use parentheses &#x60;()&#x60; to access nested fields in arrays and objects. &lt;br&gt;The endpoint doesn\&#39;t return an error for invalidly defined fields. Instead, it only returns data for the fields that you have defined correctly. &lt;br&gt;&lt;br&gt;Be careful not to include spaces, as this will invalidate the field in question!
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProductAvailabilityWithRules(
      identifier: string,
      xAvailabilityGroupKey?: string,
      fields?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProductAvailabilityWithRules(
        identifier,
        xAvailabilityGroupKey,
        fields,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Use a product key (or its database ID) and brand to retrieve a list of all assortments and applied availability logic rules that determine why the product and its articles are available or not.
     * @summary Get product availability and the applied availability rules for a brand.
     * @param {string} brand The brand for which you want to check a product\&#39;s availability. The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {string} identifier The product key or database ID whose availability you want to check.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {string} [fields] The response fields you want to retrieve. Separate the JSON response fields you want to receive with a comma &#x60;,&#x60; and use parentheses &#x60;()&#x60; to access nested fields in arrays and objects. &lt;br&gt;The endpoint doesn\&#39;t return an error for invalidly defined fields. Instead, it only returns data for the fields that you have defined correctly. &lt;br&gt;&lt;br&gt;Be careful not to include spaces, as this will invalidate the field in question!
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProductAvailabilityWithRulesForBrand(
      brand: string,
      identifier: string,
      xAvailabilityGroupKey?: string,
      fields?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProductAvailabilityWithRulesForBrand(
        brand,
        identifier,
        xAvailabilityGroupKey,
        fields,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get a list of assortments in which one or more products and their articles are available. You can filter the results by defining product keys, article numbers ([Stock Keeping Units, or SKUs](/glossary)), and Bluetooth Low Energy device ID.
     * @summary Get the availability of one or more products for brand Geberit.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {Array<string>} [ids] A list of the products\&#39; database ids whose availability you want to check. Separate each value by a comma &#x60;,&#x60; without spaces.
     * @param {Array<string>} [keys] A list of the products\&#39; keys whose availability you want to check. Separate each value by a comma &#x60;,&#x60; without spaces.
     * @param {Array<string>} [skus] A list of article numbers belonging to products whose availability you want to check. Separate each value by a comma &#x60;,&#x60; without spaces.
     * @param {string} [bleDevice] The HEX-value of the Bluetooth Low Energy device of the products whose availability you want to check.
     * @param {number} [offset] The number of items to skip. Used for pagination. The default value is 0.
     * @param {number} [limit] The total number of items to return. Used for pagination. The default value is 20. The maximum value is 500.
     * @param {string} [fields] The response fields you want to retrieve. Separate the JSON response fields you want to receive with a comma &#x60;,&#x60; and use parentheses &#x60;()&#x60; to access nested fields in arrays and objects. &lt;br&gt;The endpoint doesn\&#39;t return an error for invalidly defined fields. Instead, it only returns data for the fields that you have defined correctly. &lt;br&gt;&lt;br&gt;Be careful not to include spaces, as this will invalidate the field in question!
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProductsAvailability(
      xAvailabilityGroupKey?: string,
      ids?: Array<string>,
      keys?: Array<string>,
      skus?: Array<string>,
      bleDevice?: string,
      offset?: number,
      limit?: number,
      fields?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedProducts>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProductsAvailability(
        xAvailabilityGroupKey,
        ids,
        keys,
        skus,
        bleDevice,
        offset,
        limit,
        fields,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify an assortment and a brand for which you want to retrieve availability information of all products and their articles. You can filter the results by defining product keys, article numbers ([Stock Keeping Units, or SKUs](/glossary)), and Bluetooth Low Energy device ID.
     * @summary Get the availability of one or more products for assortment and brand.
     * @param {string} assortment The assortment for which you want to check product availability. You can get a list of supported assortments using the &#x60;/catalogs&#x60; endpoint.
     * @param {string} brand The brand for which you want to check product availability. The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {Array<string>} [ids] A list of the products\&#39; database ids whose availability you want to check. Separate each value by a comma &#x60;,&#x60; without spaces.
     * @param {Array<string>} [keys] A list of the products\&#39; keys whose availability you want to check. Separate each value by a comma &#x60;,&#x60; without spaces.
     * @param {Array<string>} [skus] A list of article numbers belonging to products whose availability you want to check. Separate each value by a comma &#x60;,&#x60; without spaces.
     * @param {string} [bleDevice] The HEX-value of the Bluetooth Low Energy device of the products whose availability you want to check.
     * @param {number} [offset] The number of items to skip. Used for pagination. The default value is 0.
     * @param {number} [limit] The total number of items to return. Used for pagination. The default value is 20. The maximum value is 500.
     * @param {string} [fields] The response fields you want to retrieve. Separate the JSON response fields you want to receive with a comma &#x60;,&#x60; and use parentheses &#x60;()&#x60; to access nested fields in arrays and objects. &lt;br&gt;The endpoint doesn\&#39;t return an error for invalidly defined fields. Instead, it only returns data for the fields that you have defined correctly. &lt;br&gt;&lt;br&gt;Be careful not to include spaces, as this will invalidate the field in question!
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProductsAvailabilityForBrandAndLocale(
      assortment: string,
      brand: string,
      xAvailabilityGroupKey?: string,
      ids?: Array<string>,
      keys?: Array<string>,
      skus?: Array<string>,
      bleDevice?: string,
      offset?: number,
      limit?: number,
      fields?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedProducts>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProductsAvailabilityForBrandAndLocale(
        assortment,
        brand,
        xAvailabilityGroupKey,
        ids,
        keys,
        skus,
        bleDevice,
        offset,
        limit,
        fields,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify an assortment (or brand) for which you want to retrieve availability information of all products and their articles. You can filter the results by defining product keys, article numbers ([Stock Keeping Units, or SKUs](/glossary)), and Bluetooth Low Energy device ID.  Note the following: - If you define an assortment, the request will be made for the default brand `geberit`. - If you define a brand, the request will return availability information for all assortments.
     * @summary Get the availability of one or more products for assortment or brand.
     * @param {string} assortmentOrBrand The brand or assortment for which you want to check product availability.  The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {Array<string>} [ids] A list of the products\&#39; database ids whose availability you want to check. Separate each value by a comma &#x60;,&#x60; without spaces.
     * @param {Array<string>} [keys] A list of the products\&#39; keys whose availability you want to check. Separate each value by a comma &#x60;,&#x60; without spaces.
     * @param {Array<string>} [skus] A list of article numbers belonging to products whose availability you want to check. Separate each value by a comma &#x60;,&#x60; without spaces.
     * @param {string} [bleDevice] The HEX-value of the Bluetooth Low Energy device of the products whose availability you want to check.
     * @param {number} [offset] The number of items to skip. Used for pagination. The default value is 0.
     * @param {number} [limit] The total number of items to return. Used for pagination. The default value is 20. The maximum value is 500.
     * @param {string} [fields] The response fields you want to retrieve. Separate the JSON response fields you want to receive with a comma &#x60;,&#x60; and use parentheses &#x60;()&#x60; to access nested fields in arrays and objects. &lt;br&gt;The endpoint doesn\&#39;t return an error for invalidly defined fields. Instead, it only returns data for the fields that you have defined correctly. &lt;br&gt;&lt;br&gt;Be careful not to include spaces, as this will invalidate the field in question!
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProductsAvailabilityForBrandOrLocale(
      assortmentOrBrand: string,
      xAvailabilityGroupKey?: string,
      ids?: Array<string>,
      keys?: Array<string>,
      skus?: Array<string>,
      bleDevice?: string,
      offset?: number,
      limit?: number,
      fields?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedProducts>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProductsAvailabilityForBrandOrLocale(
        assortmentOrBrand,
        xAvailabilityGroupKey,
        ids,
        keys,
        skus,
        bleDevice,
        offset,
        limit,
        fields,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * ProductAvailabilityApi - factory interface
 * @export
 */
export const ProductAvailabilityApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ProductAvailabilityApiFp(configuration)
  return {
    /**
     * Use a product\'s key to retrieve a list of assortments in which the given product and its articles are available.
     * @summary Get product availability for brand Geberit.
     * @param {ProductAvailabilityApiGetProductAvailabilityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductAvailability(
      requestParameters: ProductAvailabilityApiGetProductAvailabilityRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<Product> {
      return localVarFp
        .getProductAvailability(
          requestParameters.key,
          requestParameters.xAvailabilityGroupKey,
          requestParameters.fields,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Use a product key, assortment, and brand to retrieve availability information of the given product and its articles.
     * @summary Get product availability for assortment and brand.
     * @param {ProductAvailabilityApiGetProductAvailabilityForBrandAndLocaleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductAvailabilityForBrandAndLocale(
      requestParameters: ProductAvailabilityApiGetProductAvailabilityForBrandAndLocaleRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<Product> {
      return localVarFp
        .getProductAvailabilityForBrandAndLocale(
          requestParameters.assortment,
          requestParameters.brand,
          requestParameters.key,
          requestParameters.xAvailabilityGroupKey,
          requestParameters.fields,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Use a product key and an assortment (or brand) to retrieve availability information of the given product and its articles.  Note the following: - If you define an assortment, the request will be made for the default brand `geberit`. - If you define a brand, the request will return availability information for all assortments.
     * @summary Get product availability for assortment or brand.
     * @param {ProductAvailabilityApiGetProductAvailabilityForBrandOrLocaleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductAvailabilityForBrandOrLocale(
      requestParameters: ProductAvailabilityApiGetProductAvailabilityForBrandOrLocaleRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<Product> {
      return localVarFp
        .getProductAvailabilityForBrandOrLocale(
          requestParameters.assortmentOrBrand,
          requestParameters.key,
          requestParameters.xAvailabilityGroupKey,
          requestParameters.fields,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Use a product key or its database ID to retrieve a list of all assortments and applied availability logic rules that determine why the product and its articles are available or not.
     * @summary Get product availability and the applied availability rules for brand Geberit.
     * @param {ProductAvailabilityApiGetProductAvailabilityWithRulesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductAvailabilityWithRules(
      requestParameters: ProductAvailabilityApiGetProductAvailabilityWithRulesRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<Product> {
      return localVarFp
        .getProductAvailabilityWithRules(
          requestParameters.identifier,
          requestParameters.xAvailabilityGroupKey,
          requestParameters.fields,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Use a product key (or its database ID) and brand to retrieve a list of all assortments and applied availability logic rules that determine why the product and its articles are available or not.
     * @summary Get product availability and the applied availability rules for a brand.
     * @param {ProductAvailabilityApiGetProductAvailabilityWithRulesForBrandRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductAvailabilityWithRulesForBrand(
      requestParameters: ProductAvailabilityApiGetProductAvailabilityWithRulesForBrandRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<Product> {
      return localVarFp
        .getProductAvailabilityWithRulesForBrand(
          requestParameters.brand,
          requestParameters.identifier,
          requestParameters.xAvailabilityGroupKey,
          requestParameters.fields,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Get a list of assortments in which one or more products and their articles are available. You can filter the results by defining product keys, article numbers ([Stock Keeping Units, or SKUs](/glossary)), and Bluetooth Low Energy device ID.
     * @summary Get the availability of one or more products for brand Geberit.
     * @param {ProductAvailabilityApiGetProductsAvailabilityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductsAvailability(
      requestParameters: ProductAvailabilityApiGetProductsAvailabilityRequest = {},
      options?: AxiosRequestConfig
    ): AxiosPromise<PagedProducts> {
      return localVarFp
        .getProductsAvailability(
          requestParameters.xAvailabilityGroupKey,
          requestParameters.ids,
          requestParameters.keys,
          requestParameters.skus,
          requestParameters.bleDevice,
          requestParameters.offset,
          requestParameters.limit,
          requestParameters.fields,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify an assortment and a brand for which you want to retrieve availability information of all products and their articles. You can filter the results by defining product keys, article numbers ([Stock Keeping Units, or SKUs](/glossary)), and Bluetooth Low Energy device ID.
     * @summary Get the availability of one or more products for assortment and brand.
     * @param {ProductAvailabilityApiGetProductsAvailabilityForBrandAndLocaleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductsAvailabilityForBrandAndLocale(
      requestParameters: ProductAvailabilityApiGetProductsAvailabilityForBrandAndLocaleRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<PagedProducts> {
      return localVarFp
        .getProductsAvailabilityForBrandAndLocale(
          requestParameters.assortment,
          requestParameters.brand,
          requestParameters.xAvailabilityGroupKey,
          requestParameters.ids,
          requestParameters.keys,
          requestParameters.skus,
          requestParameters.bleDevice,
          requestParameters.offset,
          requestParameters.limit,
          requestParameters.fields,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify an assortment (or brand) for which you want to retrieve availability information of all products and their articles. You can filter the results by defining product keys, article numbers ([Stock Keeping Units, or SKUs](/glossary)), and Bluetooth Low Energy device ID.  Note the following: - If you define an assortment, the request will be made for the default brand `geberit`. - If you define a brand, the request will return availability information for all assortments.
     * @summary Get the availability of one or more products for assortment or brand.
     * @param {ProductAvailabilityApiGetProductsAvailabilityForBrandOrLocaleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductsAvailabilityForBrandOrLocale(
      requestParameters: ProductAvailabilityApiGetProductsAvailabilityForBrandOrLocaleRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<PagedProducts> {
      return localVarFp
        .getProductsAvailabilityForBrandOrLocale(
          requestParameters.assortmentOrBrand,
          requestParameters.xAvailabilityGroupKey,
          requestParameters.ids,
          requestParameters.keys,
          requestParameters.skus,
          requestParameters.bleDevice,
          requestParameters.offset,
          requestParameters.limit,
          requestParameters.fields,
          options
        )
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for getProductAvailability operation in ProductAvailabilityApi.
 * @export
 * @interface ProductAvailabilityApiGetProductAvailabilityRequest
 */
export interface ProductAvailabilityApiGetProductAvailabilityRequest {
  /**
   * The key of the product whose availability you want to check.
   * @type {string}
   * @memberof ProductAvailabilityApiGetProductAvailability
   */
  readonly key: string

  /**
   * The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
   * @type {string}
   * @memberof ProductAvailabilityApiGetProductAvailability
   */
  readonly xAvailabilityGroupKey?: string

  /**
   * The response fields you want to retrieve. Separate the JSON response fields you want to receive with a comma &#x60;,&#x60; and use parentheses &#x60;()&#x60; to access nested fields in arrays and objects.&lt;br/&gt;The endpoint doesn\&#39;t return an error for invalidly defined fields. Instead, it only returns data for the fields that you have defined correctly.&lt;br /&gt;&lt;br /&gt;Be careful not to include spaces, as this will invalidate the field in question!
   * @type {string}
   * @memberof ProductAvailabilityApiGetProductAvailability
   */
  readonly fields?: string
}

/**
 * Request parameters for getProductAvailabilityForBrandAndLocale operation in ProductAvailabilityApi.
 * @export
 * @interface ProductAvailabilityApiGetProductAvailabilityForBrandAndLocaleRequest
 */
export interface ProductAvailabilityApiGetProductAvailabilityForBrandAndLocaleRequest {
  /**
   * The assortment for which you want to check a product\&#39;s availability. You can get a list of supported assortments using the &#x60;/catalogs&#x60; endpoint.
   * @type {string}
   * @memberof ProductAvailabilityApiGetProductAvailabilityForBrandAndLocale
   */
  readonly assortment: string

  /**
   * The brand for which you want to check a product\&#39;s availability. The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
   * @type {string}
   * @memberof ProductAvailabilityApiGetProductAvailabilityForBrandAndLocale
   */
  readonly brand: string

  /**
   * The key of the product whose availability you want to check.
   * @type {string}
   * @memberof ProductAvailabilityApiGetProductAvailabilityForBrandAndLocale
   */
  readonly key: string

  /**
   * The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
   * @type {string}
   * @memberof ProductAvailabilityApiGetProductAvailabilityForBrandAndLocale
   */
  readonly xAvailabilityGroupKey?: string

  /**
   * The response fields you want to retrieve. Separate the JSON response fields you want to receive with a comma &#x60;,&#x60; and use parentheses &#x60;()&#x60; to access nested fields in arrays and objects.&lt;br/&gt;The endpoint doesn\&#39;t return an error for invalidly defined fields. Instead, it only returns data for the fields that you have defined correctly.&lt;br /&gt;&lt;br /&gt;Be careful not to include spaces, as this will invalidate the field in question!
   * @type {string}
   * @memberof ProductAvailabilityApiGetProductAvailabilityForBrandAndLocale
   */
  readonly fields?: string
}

/**
 * Request parameters for getProductAvailabilityForBrandOrLocale operation in ProductAvailabilityApi.
 * @export
 * @interface ProductAvailabilityApiGetProductAvailabilityForBrandOrLocaleRequest
 */
export interface ProductAvailabilityApiGetProductAvailabilityForBrandOrLocaleRequest {
  /**
   * The brand or assortment for which you want to check a product\&#39;s availability.  The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
   * @type {string}
   * @memberof ProductAvailabilityApiGetProductAvailabilityForBrandOrLocale
   */
  readonly assortmentOrBrand: string

  /**
   * The key of the product whose availability you want to check.
   * @type {string}
   * @memberof ProductAvailabilityApiGetProductAvailabilityForBrandOrLocale
   */
  readonly key: string

  /**
   * The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
   * @type {string}
   * @memberof ProductAvailabilityApiGetProductAvailabilityForBrandOrLocale
   */
  readonly xAvailabilityGroupKey?: string

  /**
   * The response fields you want to retrieve. Separate the JSON response fields you want to receive with a comma &#x60;,&#x60; and use parentheses &#x60;()&#x60; to access nested fields in arrays and objects.&lt;br/&gt;The endpoint doesn\&#39;t return an error for invalidly defined fields. Instead, it only returns data for the fields that you have defined correctly.&lt;br /&gt;&lt;br /&gt;Be careful not to include spaces, as this will invalidate the field in question!
   * @type {string}
   * @memberof ProductAvailabilityApiGetProductAvailabilityForBrandOrLocale
   */
  readonly fields?: string
}

/**
 * Request parameters for getProductAvailabilityWithRules operation in ProductAvailabilityApi.
 * @export
 * @interface ProductAvailabilityApiGetProductAvailabilityWithRulesRequest
 */
export interface ProductAvailabilityApiGetProductAvailabilityWithRulesRequest {
  /**
   * The product key or database ID whose availability you want to check.
   * @type {string}
   * @memberof ProductAvailabilityApiGetProductAvailabilityWithRules
   */
  readonly identifier: string

  /**
   * The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
   * @type {string}
   * @memberof ProductAvailabilityApiGetProductAvailabilityWithRules
   */
  readonly xAvailabilityGroupKey?: string

  /**
   * The response fields you want to retrieve. Separate the JSON response fields you want to receive with a comma &#x60;,&#x60; and use parentheses &#x60;()&#x60; to access nested fields in arrays and objects. &lt;br&gt;The endpoint doesn\&#39;t return an error for invalidly defined fields. Instead, it only returns data for the fields that you have defined correctly. &lt;br&gt;&lt;br&gt;Be careful not to include spaces, as this will invalidate the field in question!
   * @type {string}
   * @memberof ProductAvailabilityApiGetProductAvailabilityWithRules
   */
  readonly fields?: string
}

/**
 * Request parameters for getProductAvailabilityWithRulesForBrand operation in ProductAvailabilityApi.
 * @export
 * @interface ProductAvailabilityApiGetProductAvailabilityWithRulesForBrandRequest
 */
export interface ProductAvailabilityApiGetProductAvailabilityWithRulesForBrandRequest {
  /**
   * The brand for which you want to check a product\&#39;s availability. The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
   * @type {string}
   * @memberof ProductAvailabilityApiGetProductAvailabilityWithRulesForBrand
   */
  readonly brand: string

  /**
   * The product key or database ID whose availability you want to check.
   * @type {string}
   * @memberof ProductAvailabilityApiGetProductAvailabilityWithRulesForBrand
   */
  readonly identifier: string

  /**
   * The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
   * @type {string}
   * @memberof ProductAvailabilityApiGetProductAvailabilityWithRulesForBrand
   */
  readonly xAvailabilityGroupKey?: string

  /**
   * The response fields you want to retrieve. Separate the JSON response fields you want to receive with a comma &#x60;,&#x60; and use parentheses &#x60;()&#x60; to access nested fields in arrays and objects. &lt;br&gt;The endpoint doesn\&#39;t return an error for invalidly defined fields. Instead, it only returns data for the fields that you have defined correctly. &lt;br&gt;&lt;br&gt;Be careful not to include spaces, as this will invalidate the field in question!
   * @type {string}
   * @memberof ProductAvailabilityApiGetProductAvailabilityWithRulesForBrand
   */
  readonly fields?: string
}

/**
 * Request parameters for getProductsAvailability operation in ProductAvailabilityApi.
 * @export
 * @interface ProductAvailabilityApiGetProductsAvailabilityRequest
 */
export interface ProductAvailabilityApiGetProductsAvailabilityRequest {
  /**
   * The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
   * @type {string}
   * @memberof ProductAvailabilityApiGetProductsAvailability
   */
  readonly xAvailabilityGroupKey?: string

  /**
   * A list of the products\&#39; database ids whose availability you want to check. Separate each value by a comma &#x60;,&#x60; without spaces.
   * @type {Array<string>}
   * @memberof ProductAvailabilityApiGetProductsAvailability
   */
  readonly ids?: Array<string>

  /**
   * A list of the products\&#39; keys whose availability you want to check. Separate each value by a comma &#x60;,&#x60; without spaces.
   * @type {Array<string>}
   * @memberof ProductAvailabilityApiGetProductsAvailability
   */
  readonly keys?: Array<string>

  /**
   * A list of article numbers belonging to products whose availability you want to check. Separate each value by a comma &#x60;,&#x60; without spaces.
   * @type {Array<string>}
   * @memberof ProductAvailabilityApiGetProductsAvailability
   */
  readonly skus?: Array<string>

  /**
   * The HEX-value of the Bluetooth Low Energy device of the products whose availability you want to check.
   * @type {string}
   * @memberof ProductAvailabilityApiGetProductsAvailability
   */
  readonly bleDevice?: string

  /**
   * The number of items to skip. Used for pagination. The default value is 0.
   * @type {number}
   * @memberof ProductAvailabilityApiGetProductsAvailability
   */
  readonly offset?: number

  /**
   * The total number of items to return. Used for pagination. The default value is 20. The maximum value is 500.
   * @type {number}
   * @memberof ProductAvailabilityApiGetProductsAvailability
   */
  readonly limit?: number

  /**
   * The response fields you want to retrieve. Separate the JSON response fields you want to receive with a comma &#x60;,&#x60; and use parentheses &#x60;()&#x60; to access nested fields in arrays and objects. &lt;br&gt;The endpoint doesn\&#39;t return an error for invalidly defined fields. Instead, it only returns data for the fields that you have defined correctly. &lt;br&gt;&lt;br&gt;Be careful not to include spaces, as this will invalidate the field in question!
   * @type {string}
   * @memberof ProductAvailabilityApiGetProductsAvailability
   */
  readonly fields?: string
}

/**
 * Request parameters for getProductsAvailabilityForBrandAndLocale operation in ProductAvailabilityApi.
 * @export
 * @interface ProductAvailabilityApiGetProductsAvailabilityForBrandAndLocaleRequest
 */
export interface ProductAvailabilityApiGetProductsAvailabilityForBrandAndLocaleRequest {
  /**
   * The assortment for which you want to check product availability. You can get a list of supported assortments using the &#x60;/catalogs&#x60; endpoint.
   * @type {string}
   * @memberof ProductAvailabilityApiGetProductsAvailabilityForBrandAndLocale
   */
  readonly assortment: string

  /**
   * The brand for which you want to check product availability. The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
   * @type {string}
   * @memberof ProductAvailabilityApiGetProductsAvailabilityForBrandAndLocale
   */
  readonly brand: string

  /**
   * The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
   * @type {string}
   * @memberof ProductAvailabilityApiGetProductsAvailabilityForBrandAndLocale
   */
  readonly xAvailabilityGroupKey?: string

  /**
   * A list of the products\&#39; database ids whose availability you want to check. Separate each value by a comma &#x60;,&#x60; without spaces.
   * @type {Array<string>}
   * @memberof ProductAvailabilityApiGetProductsAvailabilityForBrandAndLocale
   */
  readonly ids?: Array<string>

  /**
   * A list of the products\&#39; keys whose availability you want to check. Separate each value by a comma &#x60;,&#x60; without spaces.
   * @type {Array<string>}
   * @memberof ProductAvailabilityApiGetProductsAvailabilityForBrandAndLocale
   */
  readonly keys?: Array<string>

  /**
   * A list of article numbers belonging to products whose availability you want to check. Separate each value by a comma &#x60;,&#x60; without spaces.
   * @type {Array<string>}
   * @memberof ProductAvailabilityApiGetProductsAvailabilityForBrandAndLocale
   */
  readonly skus?: Array<string>

  /**
   * The HEX-value of the Bluetooth Low Energy device of the products whose availability you want to check.
   * @type {string}
   * @memberof ProductAvailabilityApiGetProductsAvailabilityForBrandAndLocale
   */
  readonly bleDevice?: string

  /**
   * The number of items to skip. Used for pagination. The default value is 0.
   * @type {number}
   * @memberof ProductAvailabilityApiGetProductsAvailabilityForBrandAndLocale
   */
  readonly offset?: number

  /**
   * The total number of items to return. Used for pagination. The default value is 20. The maximum value is 500.
   * @type {number}
   * @memberof ProductAvailabilityApiGetProductsAvailabilityForBrandAndLocale
   */
  readonly limit?: number

  /**
   * The response fields you want to retrieve. Separate the JSON response fields you want to receive with a comma &#x60;,&#x60; and use parentheses &#x60;()&#x60; to access nested fields in arrays and objects. &lt;br&gt;The endpoint doesn\&#39;t return an error for invalidly defined fields. Instead, it only returns data for the fields that you have defined correctly. &lt;br&gt;&lt;br&gt;Be careful not to include spaces, as this will invalidate the field in question!
   * @type {string}
   * @memberof ProductAvailabilityApiGetProductsAvailabilityForBrandAndLocale
   */
  readonly fields?: string
}

/**
 * Request parameters for getProductsAvailabilityForBrandOrLocale operation in ProductAvailabilityApi.
 * @export
 * @interface ProductAvailabilityApiGetProductsAvailabilityForBrandOrLocaleRequest
 */
export interface ProductAvailabilityApiGetProductsAvailabilityForBrandOrLocaleRequest {
  /**
   * The brand or assortment for which you want to check product availability.  The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
   * @type {string}
   * @memberof ProductAvailabilityApiGetProductsAvailabilityForBrandOrLocale
   */
  readonly assortmentOrBrand: string

  /**
   * The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
   * @type {string}
   * @memberof ProductAvailabilityApiGetProductsAvailabilityForBrandOrLocale
   */
  readonly xAvailabilityGroupKey?: string

  /**
   * A list of the products\&#39; database ids whose availability you want to check. Separate each value by a comma &#x60;,&#x60; without spaces.
   * @type {Array<string>}
   * @memberof ProductAvailabilityApiGetProductsAvailabilityForBrandOrLocale
   */
  readonly ids?: Array<string>

  /**
   * A list of the products\&#39; keys whose availability you want to check. Separate each value by a comma &#x60;,&#x60; without spaces.
   * @type {Array<string>}
   * @memberof ProductAvailabilityApiGetProductsAvailabilityForBrandOrLocale
   */
  readonly keys?: Array<string>

  /**
   * A list of article numbers belonging to products whose availability you want to check. Separate each value by a comma &#x60;,&#x60; without spaces.
   * @type {Array<string>}
   * @memberof ProductAvailabilityApiGetProductsAvailabilityForBrandOrLocale
   */
  readonly skus?: Array<string>

  /**
   * The HEX-value of the Bluetooth Low Energy device of the products whose availability you want to check.
   * @type {string}
   * @memberof ProductAvailabilityApiGetProductsAvailabilityForBrandOrLocale
   */
  readonly bleDevice?: string

  /**
   * The number of items to skip. Used for pagination. The default value is 0.
   * @type {number}
   * @memberof ProductAvailabilityApiGetProductsAvailabilityForBrandOrLocale
   */
  readonly offset?: number

  /**
   * The total number of items to return. Used for pagination. The default value is 20. The maximum value is 500.
   * @type {number}
   * @memberof ProductAvailabilityApiGetProductsAvailabilityForBrandOrLocale
   */
  readonly limit?: number

  /**
   * The response fields you want to retrieve. Separate the JSON response fields you want to receive with a comma &#x60;,&#x60; and use parentheses &#x60;()&#x60; to access nested fields in arrays and objects. &lt;br&gt;The endpoint doesn\&#39;t return an error for invalidly defined fields. Instead, it only returns data for the fields that you have defined correctly. &lt;br&gt;&lt;br&gt;Be careful not to include spaces, as this will invalidate the field in question!
   * @type {string}
   * @memberof ProductAvailabilityApiGetProductsAvailabilityForBrandOrLocale
   */
  readonly fields?: string
}

/**
 * ProductAvailabilityApi - object-oriented interface
 * @export
 * @class ProductAvailabilityApi
 * @extends {BaseAPI}
 */
export class ProductAvailabilityApi extends BaseAPI {
  /**
   * Use a product\'s key to retrieve a list of assortments in which the given product and its articles are available.
   * @summary Get product availability for brand Geberit.
   * @param {ProductAvailabilityApiGetProductAvailabilityRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductAvailabilityApi
   */
  public getProductAvailability(
    requestParameters: ProductAvailabilityApiGetProductAvailabilityRequest,
    options?: AxiosRequestConfig
  ) {
    return ProductAvailabilityApiFp(this.configuration)
      .getProductAvailability(
        requestParameters.key,
        requestParameters.xAvailabilityGroupKey,
        requestParameters.fields,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Use a product key, assortment, and brand to retrieve availability information of the given product and its articles.
   * @summary Get product availability for assortment and brand.
   * @param {ProductAvailabilityApiGetProductAvailabilityForBrandAndLocaleRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductAvailabilityApi
   */
  public getProductAvailabilityForBrandAndLocale(
    requestParameters: ProductAvailabilityApiGetProductAvailabilityForBrandAndLocaleRequest,
    options?: AxiosRequestConfig
  ) {
    return ProductAvailabilityApiFp(this.configuration)
      .getProductAvailabilityForBrandAndLocale(
        requestParameters.assortment,
        requestParameters.brand,
        requestParameters.key,
        requestParameters.xAvailabilityGroupKey,
        requestParameters.fields,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Use a product key and an assortment (or brand) to retrieve availability information of the given product and its articles.  Note the following: - If you define an assortment, the request will be made for the default brand `geberit`. - If you define a brand, the request will return availability information for all assortments.
   * @summary Get product availability for assortment or brand.
   * @param {ProductAvailabilityApiGetProductAvailabilityForBrandOrLocaleRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductAvailabilityApi
   */
  public getProductAvailabilityForBrandOrLocale(
    requestParameters: ProductAvailabilityApiGetProductAvailabilityForBrandOrLocaleRequest,
    options?: AxiosRequestConfig
  ) {
    return ProductAvailabilityApiFp(this.configuration)
      .getProductAvailabilityForBrandOrLocale(
        requestParameters.assortmentOrBrand,
        requestParameters.key,
        requestParameters.xAvailabilityGroupKey,
        requestParameters.fields,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Use a product key or its database ID to retrieve a list of all assortments and applied availability logic rules that determine why the product and its articles are available or not.
   * @summary Get product availability and the applied availability rules for brand Geberit.
   * @param {ProductAvailabilityApiGetProductAvailabilityWithRulesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductAvailabilityApi
   */
  public getProductAvailabilityWithRules(
    requestParameters: ProductAvailabilityApiGetProductAvailabilityWithRulesRequest,
    options?: AxiosRequestConfig
  ) {
    return ProductAvailabilityApiFp(this.configuration)
      .getProductAvailabilityWithRules(
        requestParameters.identifier,
        requestParameters.xAvailabilityGroupKey,
        requestParameters.fields,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Use a product key (or its database ID) and brand to retrieve a list of all assortments and applied availability logic rules that determine why the product and its articles are available or not.
   * @summary Get product availability and the applied availability rules for a brand.
   * @param {ProductAvailabilityApiGetProductAvailabilityWithRulesForBrandRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductAvailabilityApi
   */
  public getProductAvailabilityWithRulesForBrand(
    requestParameters: ProductAvailabilityApiGetProductAvailabilityWithRulesForBrandRequest,
    options?: AxiosRequestConfig
  ) {
    return ProductAvailabilityApiFp(this.configuration)
      .getProductAvailabilityWithRulesForBrand(
        requestParameters.brand,
        requestParameters.identifier,
        requestParameters.xAvailabilityGroupKey,
        requestParameters.fields,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get a list of assortments in which one or more products and their articles are available. You can filter the results by defining product keys, article numbers ([Stock Keeping Units, or SKUs](/glossary)), and Bluetooth Low Energy device ID.
   * @summary Get the availability of one or more products for brand Geberit.
   * @param {ProductAvailabilityApiGetProductsAvailabilityRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductAvailabilityApi
   */
  public getProductsAvailability(
    requestParameters: ProductAvailabilityApiGetProductsAvailabilityRequest = {},
    options?: AxiosRequestConfig
  ) {
    return ProductAvailabilityApiFp(this.configuration)
      .getProductsAvailability(
        requestParameters.xAvailabilityGroupKey,
        requestParameters.ids,
        requestParameters.keys,
        requestParameters.skus,
        requestParameters.bleDevice,
        requestParameters.offset,
        requestParameters.limit,
        requestParameters.fields,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify an assortment and a brand for which you want to retrieve availability information of all products and their articles. You can filter the results by defining product keys, article numbers ([Stock Keeping Units, or SKUs](/glossary)), and Bluetooth Low Energy device ID.
   * @summary Get the availability of one or more products for assortment and brand.
   * @param {ProductAvailabilityApiGetProductsAvailabilityForBrandAndLocaleRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductAvailabilityApi
   */
  public getProductsAvailabilityForBrandAndLocale(
    requestParameters: ProductAvailabilityApiGetProductsAvailabilityForBrandAndLocaleRequest,
    options?: AxiosRequestConfig
  ) {
    return ProductAvailabilityApiFp(this.configuration)
      .getProductsAvailabilityForBrandAndLocale(
        requestParameters.assortment,
        requestParameters.brand,
        requestParameters.xAvailabilityGroupKey,
        requestParameters.ids,
        requestParameters.keys,
        requestParameters.skus,
        requestParameters.bleDevice,
        requestParameters.offset,
        requestParameters.limit,
        requestParameters.fields,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify an assortment (or brand) for which you want to retrieve availability information of all products and their articles. You can filter the results by defining product keys, article numbers ([Stock Keeping Units, or SKUs](/glossary)), and Bluetooth Low Energy device ID.  Note the following: - If you define an assortment, the request will be made for the default brand `geberit`. - If you define a brand, the request will return availability information for all assortments.
   * @summary Get the availability of one or more products for assortment or brand.
   * @param {ProductAvailabilityApiGetProductsAvailabilityForBrandOrLocaleRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductAvailabilityApi
   */
  public getProductsAvailabilityForBrandOrLocale(
    requestParameters: ProductAvailabilityApiGetProductsAvailabilityForBrandOrLocaleRequest,
    options?: AxiosRequestConfig
  ) {
    return ProductAvailabilityApiFp(this.configuration)
      .getProductsAvailabilityForBrandOrLocale(
        requestParameters.assortmentOrBrand,
        requestParameters.xAvailabilityGroupKey,
        requestParameters.ids,
        requestParameters.keys,
        requestParameters.skus,
        requestParameters.bleDevice,
        requestParameters.offset,
        requestParameters.limit,
        requestParameters.fields,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * SparePartsTableAvailabilityApi - axios parameter creator
 * @export
 */
export const SparePartsTableAvailabilityApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Use a spare parts table key or its database ID to retrieve a list of all assortments and applied availability logic rules that determine why the spare parts table is available or not.
     * @summary Get spare parts table availability and the applied availability rules for brand Geberit.
     * @param {string} identifier The spare parts table key or database ID whose availability you want to check.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSPTAvailabilityWithRules: async (
      identifier: string,
      xAvailabilityGroupKey?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'identifier' is not null or undefined
      assertParamExists("getSPTAvailabilityWithRules", "identifier", identifier)
      const localVarPath = `/spare-parts/{identifier}/rules`.replace(
        `{${"identifier"}}`,
        encodeURIComponent(String(identifier))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (xAvailabilityGroupKey != null) {
        localVarHeaderParameter["X-Availability-Group-Key"] = String(xAvailabilityGroupKey)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Use a spare parts table key (or its database ID) and brand to retrieve a list of all assortments and applied availability logic rules that determine why the spare parts table is available or not.
     * @summary Get spare parts table availability and the applied availability rules for a brand.
     * @param {string} brand The brand for which you want to check a spare parts table\&#39;s availability. The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {string} identifier The spare parts table key or database ID whose availability you want to check.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSPTAvailabilityWithRulesWithBrand: async (
      brand: string,
      identifier: string,
      xAvailabilityGroupKey?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("getSPTAvailabilityWithRulesWithBrand", "brand", brand)
      // verify required parameter 'identifier' is not null or undefined
      assertParamExists("getSPTAvailabilityWithRulesWithBrand", "identifier", identifier)
      const localVarPath = `/{brand}/spare-parts/{identifier}/rules`
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
        .replace(`{${"identifier"}}`, encodeURIComponent(String(identifier)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (xAvailabilityGroupKey != null) {
        localVarHeaderParameter["X-Availability-Group-Key"] = String(xAvailabilityGroupKey)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Use a spare parts table\'s key to retrieve a list of assortments in which the given spare parts table, its individual spare parts articles, and the articles to which the spare parts table is assigned (main articles) are available.
     * @summary Get spare parts table availability for brand Geberit.
     * @param {string} key The key of the spare parts table whose availability you want to check.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSparePartAvailability: async (
      key: string,
      xAvailabilityGroupKey?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'key' is not null or undefined
      assertParamExists("getSparePartAvailability", "key", key)
      const localVarPath = `/spare-parts/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(key)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (xAvailabilityGroupKey != null) {
        localVarHeaderParameter["X-Availability-Group-Key"] = String(xAvailabilityGroupKey)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Use a spare parts table key and an assortment (or brand) to retrieve availability information of the given spare parts table, its individual spare parts articles, and the articles to which the spare parts table is assigned (main articles).  Note the following: - If you define an assortment, the request will be made for the default brand `geberit`. - If you define a brand, the request will return availability information for all assortments.
     * @summary Get spare parts table availability for assortment or brand.
     * @param {string} assortmentOrBrand The brand or assortment for which you want to check a category\&#39;s availability.  The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {string} key The key of the spare parts table whose availability you want to check.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSparePartAvailabilityWithBrandOrLocale: async (
      assortmentOrBrand: string,
      key: string,
      xAvailabilityGroupKey?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'assortmentOrBrand' is not null or undefined
      assertParamExists("getSparePartAvailabilityWithBrandOrLocale", "assortmentOrBrand", assortmentOrBrand)
      // verify required parameter 'key' is not null or undefined
      assertParamExists("getSparePartAvailabilityWithBrandOrLocale", "key", key)
      const localVarPath = `/{assortmentOrBrand}/spare-parts/{key}`
        .replace(`{${"assortmentOrBrand"}}`, encodeURIComponent(String(assortmentOrBrand)))
        .replace(`{${"key"}}`, encodeURIComponent(String(key)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (xAvailabilityGroupKey != null) {
        localVarHeaderParameter["X-Availability-Group-Key"] = String(xAvailabilityGroupKey)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Use a spare parts table key, assortment, and brand to retrieve availability information of the given spare parts table, its individual spare parts articles, and the articles to which the spare parts table is assigned (main articles).
     * @summary Get spare parts table availability for assortment and brand.
     * @param {string} assortment The assortment for which you want to check a spare parts table\&#39;s availability. You can get a list of supported assortments using the &#x60;/catalogs&#x60; endpoint.
     * @param {string} brand The brand for which you want to check a spare parts table\&#39;s availability. The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {string} key The key of the spare parts table whose availability you want to check.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSparePartAvailabilityWithLocaleAndBrand: async (
      assortment: string,
      brand: string,
      key: string,
      xAvailabilityGroupKey?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'assortment' is not null or undefined
      assertParamExists("getSparePartAvailabilityWithLocaleAndBrand", "assortment", assortment)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("getSparePartAvailabilityWithLocaleAndBrand", "brand", brand)
      // verify required parameter 'key' is not null or undefined
      assertParamExists("getSparePartAvailabilityWithLocaleAndBrand", "key", key)
      const localVarPath = `/{assortment}/{brand}/spare-parts/{key}`
        .replace(`{${"assortment"}}`, encodeURIComponent(String(assortment)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
        .replace(`{${"key"}}`, encodeURIComponent(String(key)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (xAvailabilityGroupKey != null) {
        localVarHeaderParameter["X-Availability-Group-Key"] = String(xAvailabilityGroupKey)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Get a list of assortments in which one or more spare parts tables, their individual spare parts articles, and the articles to which the spare parts tables are assigned (main articles) are available.
     * @summary Get the availability of one or more spare parts tables for brand Geberit.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {Array<string>} [ids] A list of the  spare part tables\&#39; database ids whose availability you want to check. Separate each value by a comma &#x60;,&#x60; without spaces.
     * @param {Array<string>} [keys] A list of the spare part tables\&#39; keys whose availability you want to check. Separate each value by a comma &#x60;,&#x60; without spaces.
     * @param {number} [offset] The number of items to skip. Used for pagination. The default value is 0.
     * @param {number} [limit] The total number of items to return. Used for pagination. The default value is 20. The maximum value is 500.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSparePartsAvailability: async (
      xAvailabilityGroupKey?: string,
      ids?: Array<string>,
      keys?: Array<string>,
      offset?: number,
      limit?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/spare-parts`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (ids) {
        localVarQueryParameter["ids"] = ids.join(COLLECTION_FORMATS.csv)
      }

      if (keys) {
        localVarQueryParameter["keys"] = keys.join(COLLECTION_FORMATS.csv)
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit
      }

      if (xAvailabilityGroupKey != null) {
        localVarHeaderParameter["X-Availability-Group-Key"] = String(xAvailabilityGroupKey)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify an assortment (or brand) for which you want to retrieve availability information of all spare parts tables, their individual spare parts articles, and the articles to which the spare parts tables are assigned (main articles). Filter your results by defining the desired spare parts tables\' keys or their respective database IDs.  Note the following: - If you define an assortment, the request will be made for the default brand `geberit`. - If you define a brand, the request will return availability information for all assortments.
     * @summary Get the availability of one or more spare parts tables for assortment or brand.
     * @param {string} assortmentOrBrand The brand or assortment for which you want to check a category\&#39;s availability.  The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {Array<string>} [ids] A list of the  spare part tables\&#39; database ids whose availability you want to check. Separate each value by a comma &#x60;,&#x60; without spaces.
     * @param {Array<string>} [keys] A list of the spare part tables\&#39; keys whose availability you want to check. Separate each value by a comma &#x60;,&#x60; without spaces.
     * @param {number} [offset] The number of items to skip. Used for pagination. The default value is 0.
     * @param {number} [limit] The total number of items to return. Used for pagination. The default value is 20. The maximum value is 500.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSparePartsAvailabilityWithBrandOrLocale: async (
      assortmentOrBrand: string,
      xAvailabilityGroupKey?: string,
      ids?: Array<string>,
      keys?: Array<string>,
      offset?: number,
      limit?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'assortmentOrBrand' is not null or undefined
      assertParamExists("getSparePartsAvailabilityWithBrandOrLocale", "assortmentOrBrand", assortmentOrBrand)
      const localVarPath = `/{assortmentOrBrand}/spare-parts`.replace(
        `{${"assortmentOrBrand"}}`,
        encodeURIComponent(String(assortmentOrBrand))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (ids) {
        localVarQueryParameter["ids"] = ids.join(COLLECTION_FORMATS.csv)
      }

      if (keys) {
        localVarQueryParameter["keys"] = keys.join(COLLECTION_FORMATS.csv)
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit
      }

      if (xAvailabilityGroupKey != null) {
        localVarHeaderParameter["X-Availability-Group-Key"] = String(xAvailabilityGroupKey)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify an assortment and a brand for which you want to retrieve availability information of all spare parts tables, their individual spare parts articles, and the articles to which the spare parts tables are assigned (main articles). Filter your results by defining the desired spare parts tables\' keys or their respective database IDs.
     * @summary Get the availability of one or more spare parts tables for assortment and brand.
     * @param {string} assortment The assortment for which you want to check the spare parts tables availability. You can get a list of supported assortments using the &#x60;/catalogs&#x60; endpoint.
     * @param {string} brand The brand for which you want to check the spare parts tables availability. The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {Array<string>} [ids] A list of the  spare part tables\&#39; database IDs whose availability you want to check. Separate each value by a comma &#x60;,&#x60; without spaces.
     * @param {Array<string>} [keys] A list of the spare part tables\&#39; keys whose availability you want to check. Separate each value by a comma &#x60;,&#x60; without spaces.
     * @param {number} [offset] The number of items to skip. Used for pagination. The default value is 0.
     * @param {number} [limit] The total number of items to return. Used for pagination. The default value is 20. The maximum value is 500.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSparePartsAvailabilityWithLocaleAndBrand: async (
      assortment: string,
      brand: string,
      xAvailabilityGroupKey?: string,
      ids?: Array<string>,
      keys?: Array<string>,
      offset?: number,
      limit?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'assortment' is not null or undefined
      assertParamExists("getSparePartsAvailabilityWithLocaleAndBrand", "assortment", assortment)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("getSparePartsAvailabilityWithLocaleAndBrand", "brand", brand)
      const localVarPath = `/{assortment}/{brand}/spare-parts`
        .replace(`{${"assortment"}}`, encodeURIComponent(String(assortment)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (ids) {
        localVarQueryParameter["ids"] = ids.join(COLLECTION_FORMATS.csv)
      }

      if (keys) {
        localVarQueryParameter["keys"] = keys.join(COLLECTION_FORMATS.csv)
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit
      }

      if (xAvailabilityGroupKey != null) {
        localVarHeaderParameter["X-Availability-Group-Key"] = String(xAvailabilityGroupKey)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * SparePartsTableAvailabilityApi - functional programming interface
 * @export
 */
export const SparePartsTableAvailabilityApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SparePartsTableAvailabilityApiAxiosParamCreator(configuration)
  return {
    /**
     * Use a spare parts table key or its database ID to retrieve a list of all assortments and applied availability logic rules that determine why the spare parts table is available or not.
     * @summary Get spare parts table availability and the applied availability rules for brand Geberit.
     * @param {string} identifier The spare parts table key or database ID whose availability you want to check.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSPTAvailabilityWithRules(
      identifier: string,
      xAvailabilityGroupKey?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SparePartsTable>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSPTAvailabilityWithRules(
        identifier,
        xAvailabilityGroupKey,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Use a spare parts table key (or its database ID) and brand to retrieve a list of all assortments and applied availability logic rules that determine why the spare parts table is available or not.
     * @summary Get spare parts table availability and the applied availability rules for a brand.
     * @param {string} brand The brand for which you want to check a spare parts table\&#39;s availability. The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {string} identifier The spare parts table key or database ID whose availability you want to check.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSPTAvailabilityWithRulesWithBrand(
      brand: string,
      identifier: string,
      xAvailabilityGroupKey?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SparePartsTable>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSPTAvailabilityWithRulesWithBrand(
        brand,
        identifier,
        xAvailabilityGroupKey,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Use a spare parts table\'s key to retrieve a list of assortments in which the given spare parts table, its individual spare parts articles, and the articles to which the spare parts table is assigned (main articles) are available.
     * @summary Get spare parts table availability for brand Geberit.
     * @param {string} key The key of the spare parts table whose availability you want to check.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSparePartAvailability(
      key: string,
      xAvailabilityGroupKey?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SparePartsTable>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSparePartAvailability(
        key,
        xAvailabilityGroupKey,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Use a spare parts table key and an assortment (or brand) to retrieve availability information of the given spare parts table, its individual spare parts articles, and the articles to which the spare parts table is assigned (main articles).  Note the following: - If you define an assortment, the request will be made for the default brand `geberit`. - If you define a brand, the request will return availability information for all assortments.
     * @summary Get spare parts table availability for assortment or brand.
     * @param {string} assortmentOrBrand The brand or assortment for which you want to check a category\&#39;s availability.  The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {string} key The key of the spare parts table whose availability you want to check.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSparePartAvailabilityWithBrandOrLocale(
      assortmentOrBrand: string,
      key: string,
      xAvailabilityGroupKey?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SparePartsTable>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSparePartAvailabilityWithBrandOrLocale(
        assortmentOrBrand,
        key,
        xAvailabilityGroupKey,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Use a spare parts table key, assortment, and brand to retrieve availability information of the given spare parts table, its individual spare parts articles, and the articles to which the spare parts table is assigned (main articles).
     * @summary Get spare parts table availability for assortment and brand.
     * @param {string} assortment The assortment for which you want to check a spare parts table\&#39;s availability. You can get a list of supported assortments using the &#x60;/catalogs&#x60; endpoint.
     * @param {string} brand The brand for which you want to check a spare parts table\&#39;s availability. The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {string} key The key of the spare parts table whose availability you want to check.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSparePartAvailabilityWithLocaleAndBrand(
      assortment: string,
      brand: string,
      key: string,
      xAvailabilityGroupKey?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SparePartsTable>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSparePartAvailabilityWithLocaleAndBrand(
        assortment,
        brand,
        key,
        xAvailabilityGroupKey,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Get a list of assortments in which one or more spare parts tables, their individual spare parts articles, and the articles to which the spare parts tables are assigned (main articles) are available.
     * @summary Get the availability of one or more spare parts tables for brand Geberit.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {Array<string>} [ids] A list of the  spare part tables\&#39; database ids whose availability you want to check. Separate each value by a comma &#x60;,&#x60; without spaces.
     * @param {Array<string>} [keys] A list of the spare part tables\&#39; keys whose availability you want to check. Separate each value by a comma &#x60;,&#x60; without spaces.
     * @param {number} [offset] The number of items to skip. Used for pagination. The default value is 0.
     * @param {number} [limit] The total number of items to return. Used for pagination. The default value is 20. The maximum value is 500.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSparePartsAvailability(
      xAvailabilityGroupKey?: string,
      ids?: Array<string>,
      keys?: Array<string>,
      offset?: number,
      limit?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedSparePartsTables>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSparePartsAvailability(
        xAvailabilityGroupKey,
        ids,
        keys,
        offset,
        limit,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify an assortment (or brand) for which you want to retrieve availability information of all spare parts tables, their individual spare parts articles, and the articles to which the spare parts tables are assigned (main articles). Filter your results by defining the desired spare parts tables\' keys or their respective database IDs.  Note the following: - If you define an assortment, the request will be made for the default brand `geberit`. - If you define a brand, the request will return availability information for all assortments.
     * @summary Get the availability of one or more spare parts tables for assortment or brand.
     * @param {string} assortmentOrBrand The brand or assortment for which you want to check a category\&#39;s availability.  The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {Array<string>} [ids] A list of the  spare part tables\&#39; database ids whose availability you want to check. Separate each value by a comma &#x60;,&#x60; without spaces.
     * @param {Array<string>} [keys] A list of the spare part tables\&#39; keys whose availability you want to check. Separate each value by a comma &#x60;,&#x60; without spaces.
     * @param {number} [offset] The number of items to skip. Used for pagination. The default value is 0.
     * @param {number} [limit] The total number of items to return. Used for pagination. The default value is 20. The maximum value is 500.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSparePartsAvailabilityWithBrandOrLocale(
      assortmentOrBrand: string,
      xAvailabilityGroupKey?: string,
      ids?: Array<string>,
      keys?: Array<string>,
      offset?: number,
      limit?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedSparePartsTables>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSparePartsAvailabilityWithBrandOrLocale(
        assortmentOrBrand,
        xAvailabilityGroupKey,
        ids,
        keys,
        offset,
        limit,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify an assortment and a brand for which you want to retrieve availability information of all spare parts tables, their individual spare parts articles, and the articles to which the spare parts tables are assigned (main articles). Filter your results by defining the desired spare parts tables\' keys or their respective database IDs.
     * @summary Get the availability of one or more spare parts tables for assortment and brand.
     * @param {string} assortment The assortment for which you want to check the spare parts tables availability. You can get a list of supported assortments using the &#x60;/catalogs&#x60; endpoint.
     * @param {string} brand The brand for which you want to check the spare parts tables availability. The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
     * @param {string} [xAvailabilityGroupKey] The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
     * @param {Array<string>} [ids] A list of the  spare part tables\&#39; database IDs whose availability you want to check. Separate each value by a comma &#x60;,&#x60; without spaces.
     * @param {Array<string>} [keys] A list of the spare part tables\&#39; keys whose availability you want to check. Separate each value by a comma &#x60;,&#x60; without spaces.
     * @param {number} [offset] The number of items to skip. Used for pagination. The default value is 0.
     * @param {number} [limit] The total number of items to return. Used for pagination. The default value is 20. The maximum value is 500.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSparePartsAvailabilityWithLocaleAndBrand(
      assortment: string,
      brand: string,
      xAvailabilityGroupKey?: string,
      ids?: Array<string>,
      keys?: Array<string>,
      offset?: number,
      limit?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedSparePartsTables>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSparePartsAvailabilityWithLocaleAndBrand(
        assortment,
        brand,
        xAvailabilityGroupKey,
        ids,
        keys,
        offset,
        limit,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * SparePartsTableAvailabilityApi - factory interface
 * @export
 */
export const SparePartsTableAvailabilityApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = SparePartsTableAvailabilityApiFp(configuration)
  return {
    /**
     * Use a spare parts table key or its database ID to retrieve a list of all assortments and applied availability logic rules that determine why the spare parts table is available or not.
     * @summary Get spare parts table availability and the applied availability rules for brand Geberit.
     * @param {SparePartsTableAvailabilityApiGetSPTAvailabilityWithRulesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSPTAvailabilityWithRules(
      requestParameters: SparePartsTableAvailabilityApiGetSPTAvailabilityWithRulesRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<SparePartsTable> {
      return localVarFp
        .getSPTAvailabilityWithRules(requestParameters.identifier, requestParameters.xAvailabilityGroupKey, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Use a spare parts table key (or its database ID) and brand to retrieve a list of all assortments and applied availability logic rules that determine why the spare parts table is available or not.
     * @summary Get spare parts table availability and the applied availability rules for a brand.
     * @param {SparePartsTableAvailabilityApiGetSPTAvailabilityWithRulesWithBrandRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSPTAvailabilityWithRulesWithBrand(
      requestParameters: SparePartsTableAvailabilityApiGetSPTAvailabilityWithRulesWithBrandRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<SparePartsTable> {
      return localVarFp
        .getSPTAvailabilityWithRulesWithBrand(
          requestParameters.brand,
          requestParameters.identifier,
          requestParameters.xAvailabilityGroupKey,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Use a spare parts table\'s key to retrieve a list of assortments in which the given spare parts table, its individual spare parts articles, and the articles to which the spare parts table is assigned (main articles) are available.
     * @summary Get spare parts table availability for brand Geberit.
     * @param {SparePartsTableAvailabilityApiGetSparePartAvailabilityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSparePartAvailability(
      requestParameters: SparePartsTableAvailabilityApiGetSparePartAvailabilityRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<SparePartsTable> {
      return localVarFp
        .getSparePartAvailability(requestParameters.key, requestParameters.xAvailabilityGroupKey, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Use a spare parts table key and an assortment (or brand) to retrieve availability information of the given spare parts table, its individual spare parts articles, and the articles to which the spare parts table is assigned (main articles).  Note the following: - If you define an assortment, the request will be made for the default brand `geberit`. - If you define a brand, the request will return availability information for all assortments.
     * @summary Get spare parts table availability for assortment or brand.
     * @param {SparePartsTableAvailabilityApiGetSparePartAvailabilityWithBrandOrLocaleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSparePartAvailabilityWithBrandOrLocale(
      requestParameters: SparePartsTableAvailabilityApiGetSparePartAvailabilityWithBrandOrLocaleRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<SparePartsTable> {
      return localVarFp
        .getSparePartAvailabilityWithBrandOrLocale(
          requestParameters.assortmentOrBrand,
          requestParameters.key,
          requestParameters.xAvailabilityGroupKey,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Use a spare parts table key, assortment, and brand to retrieve availability information of the given spare parts table, its individual spare parts articles, and the articles to which the spare parts table is assigned (main articles).
     * @summary Get spare parts table availability for assortment and brand.
     * @param {SparePartsTableAvailabilityApiGetSparePartAvailabilityWithLocaleAndBrandRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSparePartAvailabilityWithLocaleAndBrand(
      requestParameters: SparePartsTableAvailabilityApiGetSparePartAvailabilityWithLocaleAndBrandRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<SparePartsTable> {
      return localVarFp
        .getSparePartAvailabilityWithLocaleAndBrand(
          requestParameters.assortment,
          requestParameters.brand,
          requestParameters.key,
          requestParameters.xAvailabilityGroupKey,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Get a list of assortments in which one or more spare parts tables, their individual spare parts articles, and the articles to which the spare parts tables are assigned (main articles) are available.
     * @summary Get the availability of one or more spare parts tables for brand Geberit.
     * @param {SparePartsTableAvailabilityApiGetSparePartsAvailabilityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSparePartsAvailability(
      requestParameters: SparePartsTableAvailabilityApiGetSparePartsAvailabilityRequest = {},
      options?: AxiosRequestConfig
    ): AxiosPromise<PagedSparePartsTables> {
      return localVarFp
        .getSparePartsAvailability(
          requestParameters.xAvailabilityGroupKey,
          requestParameters.ids,
          requestParameters.keys,
          requestParameters.offset,
          requestParameters.limit,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify an assortment (or brand) for which you want to retrieve availability information of all spare parts tables, their individual spare parts articles, and the articles to which the spare parts tables are assigned (main articles). Filter your results by defining the desired spare parts tables\' keys or their respective database IDs.  Note the following: - If you define an assortment, the request will be made for the default brand `geberit`. - If you define a brand, the request will return availability information for all assortments.
     * @summary Get the availability of one or more spare parts tables for assortment or brand.
     * @param {SparePartsTableAvailabilityApiGetSparePartsAvailabilityWithBrandOrLocaleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSparePartsAvailabilityWithBrandOrLocale(
      requestParameters: SparePartsTableAvailabilityApiGetSparePartsAvailabilityWithBrandOrLocaleRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<PagedSparePartsTables> {
      return localVarFp
        .getSparePartsAvailabilityWithBrandOrLocale(
          requestParameters.assortmentOrBrand,
          requestParameters.xAvailabilityGroupKey,
          requestParameters.ids,
          requestParameters.keys,
          requestParameters.offset,
          requestParameters.limit,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify an assortment and a brand for which you want to retrieve availability information of all spare parts tables, their individual spare parts articles, and the articles to which the spare parts tables are assigned (main articles). Filter your results by defining the desired spare parts tables\' keys or their respective database IDs.
     * @summary Get the availability of one or more spare parts tables for assortment and brand.
     * @param {SparePartsTableAvailabilityApiGetSparePartsAvailabilityWithLocaleAndBrandRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSparePartsAvailabilityWithLocaleAndBrand(
      requestParameters: SparePartsTableAvailabilityApiGetSparePartsAvailabilityWithLocaleAndBrandRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<PagedSparePartsTables> {
      return localVarFp
        .getSparePartsAvailabilityWithLocaleAndBrand(
          requestParameters.assortment,
          requestParameters.brand,
          requestParameters.xAvailabilityGroupKey,
          requestParameters.ids,
          requestParameters.keys,
          requestParameters.offset,
          requestParameters.limit,
          options
        )
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * Request parameters for getSPTAvailabilityWithRules operation in SparePartsTableAvailabilityApi.
 * @export
 * @interface SparePartsTableAvailabilityApiGetSPTAvailabilityWithRulesRequest
 */
export interface SparePartsTableAvailabilityApiGetSPTAvailabilityWithRulesRequest {
  /**
   * The spare parts table key or database ID whose availability you want to check.
   * @type {string}
   * @memberof SparePartsTableAvailabilityApiGetSPTAvailabilityWithRules
   */
  readonly identifier: string

  /**
   * The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
   * @type {string}
   * @memberof SparePartsTableAvailabilityApiGetSPTAvailabilityWithRules
   */
  readonly xAvailabilityGroupKey?: string
}

/**
 * Request parameters for getSPTAvailabilityWithRulesWithBrand operation in SparePartsTableAvailabilityApi.
 * @export
 * @interface SparePartsTableAvailabilityApiGetSPTAvailabilityWithRulesWithBrandRequest
 */
export interface SparePartsTableAvailabilityApiGetSPTAvailabilityWithRulesWithBrandRequest {
  /**
   * The brand for which you want to check a spare parts table\&#39;s availability. The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
   * @type {string}
   * @memberof SparePartsTableAvailabilityApiGetSPTAvailabilityWithRulesWithBrand
   */
  readonly brand: string

  /**
   * The spare parts table key or database ID whose availability you want to check.
   * @type {string}
   * @memberof SparePartsTableAvailabilityApiGetSPTAvailabilityWithRulesWithBrand
   */
  readonly identifier: string

  /**
   * The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
   * @type {string}
   * @memberof SparePartsTableAvailabilityApiGetSPTAvailabilityWithRulesWithBrand
   */
  readonly xAvailabilityGroupKey?: string
}

/**
 * Request parameters for getSparePartAvailability operation in SparePartsTableAvailabilityApi.
 * @export
 * @interface SparePartsTableAvailabilityApiGetSparePartAvailabilityRequest
 */
export interface SparePartsTableAvailabilityApiGetSparePartAvailabilityRequest {
  /**
   * The key of the spare parts table whose availability you want to check.
   * @type {string}
   * @memberof SparePartsTableAvailabilityApiGetSparePartAvailability
   */
  readonly key: string

  /**
   * The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
   * @type {string}
   * @memberof SparePartsTableAvailabilityApiGetSparePartAvailability
   */
  readonly xAvailabilityGroupKey?: string
}

/**
 * Request parameters for getSparePartAvailabilityWithBrandOrLocale operation in SparePartsTableAvailabilityApi.
 * @export
 * @interface SparePartsTableAvailabilityApiGetSparePartAvailabilityWithBrandOrLocaleRequest
 */
export interface SparePartsTableAvailabilityApiGetSparePartAvailabilityWithBrandOrLocaleRequest {
  /**
   * The brand or assortment for which you want to check a category\&#39;s availability.  The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
   * @type {string}
   * @memberof SparePartsTableAvailabilityApiGetSparePartAvailabilityWithBrandOrLocale
   */
  readonly assortmentOrBrand: string

  /**
   * The key of the spare parts table whose availability you want to check.
   * @type {string}
   * @memberof SparePartsTableAvailabilityApiGetSparePartAvailabilityWithBrandOrLocale
   */
  readonly key: string

  /**
   * The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
   * @type {string}
   * @memberof SparePartsTableAvailabilityApiGetSparePartAvailabilityWithBrandOrLocale
   */
  readonly xAvailabilityGroupKey?: string
}

/**
 * Request parameters for getSparePartAvailabilityWithLocaleAndBrand operation in SparePartsTableAvailabilityApi.
 * @export
 * @interface SparePartsTableAvailabilityApiGetSparePartAvailabilityWithLocaleAndBrandRequest
 */
export interface SparePartsTableAvailabilityApiGetSparePartAvailabilityWithLocaleAndBrandRequest {
  /**
   * The assortment for which you want to check a spare parts table\&#39;s availability. You can get a list of supported assortments using the &#x60;/catalogs&#x60; endpoint.
   * @type {string}
   * @memberof SparePartsTableAvailabilityApiGetSparePartAvailabilityWithLocaleAndBrand
   */
  readonly assortment: string

  /**
   * The brand for which you want to check a spare parts table\&#39;s availability. The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
   * @type {string}
   * @memberof SparePartsTableAvailabilityApiGetSparePartAvailabilityWithLocaleAndBrand
   */
  readonly brand: string

  /**
   * The key of the spare parts table whose availability you want to check.
   * @type {string}
   * @memberof SparePartsTableAvailabilityApiGetSparePartAvailabilityWithLocaleAndBrand
   */
  readonly key: string

  /**
   * The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
   * @type {string}
   * @memberof SparePartsTableAvailabilityApiGetSparePartAvailabilityWithLocaleAndBrand
   */
  readonly xAvailabilityGroupKey?: string
}

/**
 * Request parameters for getSparePartsAvailability operation in SparePartsTableAvailabilityApi.
 * @export
 * @interface SparePartsTableAvailabilityApiGetSparePartsAvailabilityRequest
 */
export interface SparePartsTableAvailabilityApiGetSparePartsAvailabilityRequest {
  /**
   * The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
   * @type {string}
   * @memberof SparePartsTableAvailabilityApiGetSparePartsAvailability
   */
  readonly xAvailabilityGroupKey?: string

  /**
   * A list of the  spare part tables\&#39; database ids whose availability you want to check. Separate each value by a comma &#x60;,&#x60; without spaces.
   * @type {Array<string>}
   * @memberof SparePartsTableAvailabilityApiGetSparePartsAvailability
   */
  readonly ids?: Array<string>

  /**
   * A list of the spare part tables\&#39; keys whose availability you want to check. Separate each value by a comma &#x60;,&#x60; without spaces.
   * @type {Array<string>}
   * @memberof SparePartsTableAvailabilityApiGetSparePartsAvailability
   */
  readonly keys?: Array<string>

  /**
   * The number of items to skip. Used for pagination. The default value is 0.
   * @type {number}
   * @memberof SparePartsTableAvailabilityApiGetSparePartsAvailability
   */
  readonly offset?: number

  /**
   * The total number of items to return. Used for pagination. The default value is 20. The maximum value is 500.
   * @type {number}
   * @memberof SparePartsTableAvailabilityApiGetSparePartsAvailability
   */
  readonly limit?: number
}

/**
 * Request parameters for getSparePartsAvailabilityWithBrandOrLocale operation in SparePartsTableAvailabilityApi.
 * @export
 * @interface SparePartsTableAvailabilityApiGetSparePartsAvailabilityWithBrandOrLocaleRequest
 */
export interface SparePartsTableAvailabilityApiGetSparePartsAvailabilityWithBrandOrLocaleRequest {
  /**
   * The brand or assortment for which you want to check a category\&#39;s availability.  The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
   * @type {string}
   * @memberof SparePartsTableAvailabilityApiGetSparePartsAvailabilityWithBrandOrLocale
   */
  readonly assortmentOrBrand: string

  /**
   * The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
   * @type {string}
   * @memberof SparePartsTableAvailabilityApiGetSparePartsAvailabilityWithBrandOrLocale
   */
  readonly xAvailabilityGroupKey?: string

  /**
   * A list of the  spare part tables\&#39; database ids whose availability you want to check. Separate each value by a comma &#x60;,&#x60; without spaces.
   * @type {Array<string>}
   * @memberof SparePartsTableAvailabilityApiGetSparePartsAvailabilityWithBrandOrLocale
   */
  readonly ids?: Array<string>

  /**
   * A list of the spare part tables\&#39; keys whose availability you want to check. Separate each value by a comma &#x60;,&#x60; without spaces.
   * @type {Array<string>}
   * @memberof SparePartsTableAvailabilityApiGetSparePartsAvailabilityWithBrandOrLocale
   */
  readonly keys?: Array<string>

  /**
   * The number of items to skip. Used for pagination. The default value is 0.
   * @type {number}
   * @memberof SparePartsTableAvailabilityApiGetSparePartsAvailabilityWithBrandOrLocale
   */
  readonly offset?: number

  /**
   * The total number of items to return. Used for pagination. The default value is 20. The maximum value is 500.
   * @type {number}
   * @memberof SparePartsTableAvailabilityApiGetSparePartsAvailabilityWithBrandOrLocale
   */
  readonly limit?: number
}

/**
 * Request parameters for getSparePartsAvailabilityWithLocaleAndBrand operation in SparePartsTableAvailabilityApi.
 * @export
 * @interface SparePartsTableAvailabilityApiGetSparePartsAvailabilityWithLocaleAndBrandRequest
 */
export interface SparePartsTableAvailabilityApiGetSparePartsAvailabilityWithLocaleAndBrandRequest {
  /**
   * The assortment for which you want to check the spare parts tables availability. You can get a list of supported assortments using the &#x60;/catalogs&#x60; endpoint.
   * @type {string}
   * @memberof SparePartsTableAvailabilityApiGetSparePartsAvailabilityWithLocaleAndBrand
   */
  readonly assortment: string

  /**
   * The brand for which you want to check the spare parts tables availability. The Availability API supports all available brands. You can find a list of available brands in [this documentation](/locales). This parameter is not case sensitive.
   * @type {string}
   * @memberof SparePartsTableAvailabilityApiGetSparePartsAvailabilityWithLocaleAndBrand
   */
  readonly brand: string

  /**
   * The availability group for which you want to receive the requested data. The default value is &#x60;default&#x60;.
   * @type {string}
   * @memberof SparePartsTableAvailabilityApiGetSparePartsAvailabilityWithLocaleAndBrand
   */
  readonly xAvailabilityGroupKey?: string

  /**
   * A list of the  spare part tables\&#39; database IDs whose availability you want to check. Separate each value by a comma &#x60;,&#x60; without spaces.
   * @type {Array<string>}
   * @memberof SparePartsTableAvailabilityApiGetSparePartsAvailabilityWithLocaleAndBrand
   */
  readonly ids?: Array<string>

  /**
   * A list of the spare part tables\&#39; keys whose availability you want to check. Separate each value by a comma &#x60;,&#x60; without spaces.
   * @type {Array<string>}
   * @memberof SparePartsTableAvailabilityApiGetSparePartsAvailabilityWithLocaleAndBrand
   */
  readonly keys?: Array<string>

  /**
   * The number of items to skip. Used for pagination. The default value is 0.
   * @type {number}
   * @memberof SparePartsTableAvailabilityApiGetSparePartsAvailabilityWithLocaleAndBrand
   */
  readonly offset?: number

  /**
   * The total number of items to return. Used for pagination. The default value is 20. The maximum value is 500.
   * @type {number}
   * @memberof SparePartsTableAvailabilityApiGetSparePartsAvailabilityWithLocaleAndBrand
   */
  readonly limit?: number
}

/**
 * SparePartsTableAvailabilityApi - object-oriented interface
 * @export
 * @class SparePartsTableAvailabilityApi
 * @extends {BaseAPI}
 */
export class SparePartsTableAvailabilityApi extends BaseAPI {
  /**
   * Use a spare parts table key or its database ID to retrieve a list of all assortments and applied availability logic rules that determine why the spare parts table is available or not.
   * @summary Get spare parts table availability and the applied availability rules for brand Geberit.
   * @param {SparePartsTableAvailabilityApiGetSPTAvailabilityWithRulesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SparePartsTableAvailabilityApi
   */
  public getSPTAvailabilityWithRules(
    requestParameters: SparePartsTableAvailabilityApiGetSPTAvailabilityWithRulesRequest,
    options?: AxiosRequestConfig
  ) {
    return SparePartsTableAvailabilityApiFp(this.configuration)
      .getSPTAvailabilityWithRules(requestParameters.identifier, requestParameters.xAvailabilityGroupKey, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Use a spare parts table key (or its database ID) and brand to retrieve a list of all assortments and applied availability logic rules that determine why the spare parts table is available or not.
   * @summary Get spare parts table availability and the applied availability rules for a brand.
   * @param {SparePartsTableAvailabilityApiGetSPTAvailabilityWithRulesWithBrandRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SparePartsTableAvailabilityApi
   */
  public getSPTAvailabilityWithRulesWithBrand(
    requestParameters: SparePartsTableAvailabilityApiGetSPTAvailabilityWithRulesWithBrandRequest,
    options?: AxiosRequestConfig
  ) {
    return SparePartsTableAvailabilityApiFp(this.configuration)
      .getSPTAvailabilityWithRulesWithBrand(
        requestParameters.brand,
        requestParameters.identifier,
        requestParameters.xAvailabilityGroupKey,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Use a spare parts table\'s key to retrieve a list of assortments in which the given spare parts table, its individual spare parts articles, and the articles to which the spare parts table is assigned (main articles) are available.
   * @summary Get spare parts table availability for brand Geberit.
   * @param {SparePartsTableAvailabilityApiGetSparePartAvailabilityRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SparePartsTableAvailabilityApi
   */
  public getSparePartAvailability(
    requestParameters: SparePartsTableAvailabilityApiGetSparePartAvailabilityRequest,
    options?: AxiosRequestConfig
  ) {
    return SparePartsTableAvailabilityApiFp(this.configuration)
      .getSparePartAvailability(requestParameters.key, requestParameters.xAvailabilityGroupKey, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Use a spare parts table key and an assortment (or brand) to retrieve availability information of the given spare parts table, its individual spare parts articles, and the articles to which the spare parts table is assigned (main articles).  Note the following: - If you define an assortment, the request will be made for the default brand `geberit`. - If you define a brand, the request will return availability information for all assortments.
   * @summary Get spare parts table availability for assortment or brand.
   * @param {SparePartsTableAvailabilityApiGetSparePartAvailabilityWithBrandOrLocaleRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SparePartsTableAvailabilityApi
   */
  public getSparePartAvailabilityWithBrandOrLocale(
    requestParameters: SparePartsTableAvailabilityApiGetSparePartAvailabilityWithBrandOrLocaleRequest,
    options?: AxiosRequestConfig
  ) {
    return SparePartsTableAvailabilityApiFp(this.configuration)
      .getSparePartAvailabilityWithBrandOrLocale(
        requestParameters.assortmentOrBrand,
        requestParameters.key,
        requestParameters.xAvailabilityGroupKey,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Use a spare parts table key, assortment, and brand to retrieve availability information of the given spare parts table, its individual spare parts articles, and the articles to which the spare parts table is assigned (main articles).
   * @summary Get spare parts table availability for assortment and brand.
   * @param {SparePartsTableAvailabilityApiGetSparePartAvailabilityWithLocaleAndBrandRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SparePartsTableAvailabilityApi
   */
  public getSparePartAvailabilityWithLocaleAndBrand(
    requestParameters: SparePartsTableAvailabilityApiGetSparePartAvailabilityWithLocaleAndBrandRequest,
    options?: AxiosRequestConfig
  ) {
    return SparePartsTableAvailabilityApiFp(this.configuration)
      .getSparePartAvailabilityWithLocaleAndBrand(
        requestParameters.assortment,
        requestParameters.brand,
        requestParameters.key,
        requestParameters.xAvailabilityGroupKey,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Get a list of assortments in which one or more spare parts tables, their individual spare parts articles, and the articles to which the spare parts tables are assigned (main articles) are available.
   * @summary Get the availability of one or more spare parts tables for brand Geberit.
   * @param {SparePartsTableAvailabilityApiGetSparePartsAvailabilityRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SparePartsTableAvailabilityApi
   */
  public getSparePartsAvailability(
    requestParameters: SparePartsTableAvailabilityApiGetSparePartsAvailabilityRequest = {},
    options?: AxiosRequestConfig
  ) {
    return SparePartsTableAvailabilityApiFp(this.configuration)
      .getSparePartsAvailability(
        requestParameters.xAvailabilityGroupKey,
        requestParameters.ids,
        requestParameters.keys,
        requestParameters.offset,
        requestParameters.limit,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify an assortment (or brand) for which you want to retrieve availability information of all spare parts tables, their individual spare parts articles, and the articles to which the spare parts tables are assigned (main articles). Filter your results by defining the desired spare parts tables\' keys or their respective database IDs.  Note the following: - If you define an assortment, the request will be made for the default brand `geberit`. - If you define a brand, the request will return availability information for all assortments.
   * @summary Get the availability of one or more spare parts tables for assortment or brand.
   * @param {SparePartsTableAvailabilityApiGetSparePartsAvailabilityWithBrandOrLocaleRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SparePartsTableAvailabilityApi
   */
  public getSparePartsAvailabilityWithBrandOrLocale(
    requestParameters: SparePartsTableAvailabilityApiGetSparePartsAvailabilityWithBrandOrLocaleRequest,
    options?: AxiosRequestConfig
  ) {
    return SparePartsTableAvailabilityApiFp(this.configuration)
      .getSparePartsAvailabilityWithBrandOrLocale(
        requestParameters.assortmentOrBrand,
        requestParameters.xAvailabilityGroupKey,
        requestParameters.ids,
        requestParameters.keys,
        requestParameters.offset,
        requestParameters.limit,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify an assortment and a brand for which you want to retrieve availability information of all spare parts tables, their individual spare parts articles, and the articles to which the spare parts tables are assigned (main articles). Filter your results by defining the desired spare parts tables\' keys or their respective database IDs.
   * @summary Get the availability of one or more spare parts tables for assortment and brand.
   * @param {SparePartsTableAvailabilityApiGetSparePartsAvailabilityWithLocaleAndBrandRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SparePartsTableAvailabilityApi
   */
  public getSparePartsAvailabilityWithLocaleAndBrand(
    requestParameters: SparePartsTableAvailabilityApiGetSparePartsAvailabilityWithLocaleAndBrandRequest,
    options?: AxiosRequestConfig
  ) {
    return SparePartsTableAvailabilityApiFp(this.configuration)
      .getSparePartsAvailabilityWithLocaleAndBrand(
        requestParameters.assortment,
        requestParameters.brand,
        requestParameters.xAvailabilityGroupKey,
        requestParameters.ids,
        requestParameters.keys,
        requestParameters.offset,
        requestParameters.limit,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * SystemFunctionsApi - axios parameter creator
 * @export
 */
export const SystemFunctionsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Refresh all Availability API caches. Please note that you need an activated subscription key with the relevant permission to perform this action.
     * @summary Refresh caches.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshCache: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/system/updateData`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * SystemFunctionsApi - functional programming interface
 * @export
 */
export const SystemFunctionsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SystemFunctionsApiAxiosParamCreator(configuration)
  return {
    /**
     * Refresh all Availability API caches. Please note that you need an activated subscription key with the relevant permission to perform this action.
     * @summary Refresh caches.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async refreshCache(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.refreshCache(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * SystemFunctionsApi - factory interface
 * @export
 */
export const SystemFunctionsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = SystemFunctionsApiFp(configuration)
  return {
    /**
     * Refresh all Availability API caches. Please note that you need an activated subscription key with the relevant permission to perform this action.
     * @summary Refresh caches.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshCache(options?: AxiosRequestConfig): AxiosPromise<void> {
      return localVarFp.refreshCache(options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * SystemFunctionsApi - object-oriented interface
 * @export
 * @class SystemFunctionsApi
 * @extends {BaseAPI}
 */
export class SystemFunctionsApi extends BaseAPI {
  /**
   * Refresh all Availability API caches. Please note that you need an activated subscription key with the relevant permission to perform this action.
   * @summary Refresh caches.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemFunctionsApi
   */
  public refreshCache(options?: AxiosRequestConfig) {
    return SystemFunctionsApiFp(this.configuration)
      .refreshCache(options)
      .then((request) => request(this.axios, this.basePath))
  }
}
