import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
} from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { ALLOWED_MYLIST_NAME_LENGTH } from "../../../../../../common/constants/constants"
import { useBrandedTranslation } from "../../../../../../common/hooks/useBrandedTranslation"
import { CustomModalPropTypes } from "../../../../../../common/types/useModal"
import antiXss from "../../../../../../common/utils/anti-xss"

type Props = {
  isOpen: boolean
  Modal: (arg0: CustomModalPropTypes) => JSX.Element
  title: string
  bodyText?: string
  initialValue: string
  onClose: () => void
  handleConfirm: (arg: string) => void
}

const InputModal = ({ isOpen, Modal, title, bodyText = "", initialValue, onClose, handleConfirm }: Props) => {
  const { t } = useBrandedTranslation()
  const [textValue, setTextValue] = useState(initialValue)

  const handleChange = (e) => {
    if (e.target.value.length <= ALLOWED_MYLIST_NAME_LENGTH) setTextValue(e.target.value)
  }

  useEffect(() => {
    if (!isOpen) setTextValue(initialValue)
  }, [isOpen])

  const isError = !textValue

  return (
    <Modal modalContentStyleProps={{ mx: { base: "4", sm: "0" }, minW: { base: "auto", sm: "140" } }}>
      <ModalHeader mt="8" px={{ base: "4", sm: "8" }} fontWeight="medium" fontSize={{ base: "lg", xs: "xl" }}>
        {title}
      </ModalHeader>

      <ModalBody py="0" px={{ base: "4", sm: "8" }}>
        {bodyText && (
          <Flex>
            <Text fontSize="sm">{bodyText}</Text>
          </Flex>
        )}

        <FormControl isInvalid={isError}>
          <FormLabel color="gray.600" fontSize="2xs" mb="0" fontWeight="normal">
            {t("lst-list-name")}
          </FormLabel>
          <Input variant="form" value={textValue} mb={isError ? "0" : "6"} onChange={handleChange} />
          <FormErrorMessage>{t("pdp-contactForm-error")}</FormErrorMessage>
        </FormControl>
      </ModalBody>

      <ModalFooter
        gap="6"
        px={{ base: "4", sm: "8" }}
        py={{ base: "10", sm: "8" }}
        pt={{ base: "0", sm: "8" }}
        flexDirection={{ base: "column-reverse", sm: "row" }}
      >
        <Button
          variant="secondary"
          color="black"
          border="1px solid"
          borderColor="black"
          onClick={onClose}
          w={{ base: "100%", sm: "auto" }}
        >
          {t("popup-cancel")}
        </Button>

        <Button
          isDisabled={isError}
          variant="primary"
          onClick={() => {
            const sanitizedText = antiXss(textValue)
            if (!sanitizedText) {
              return setTextValue("")
            }
            handleConfirm(sanitizedText as string)
            onClose()
          }}
          w={{ base: "100%", sm: "auto" }}
        >
          {t("acc-submitBtn")}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default InputModal
